<template>
	<!--			@submit.prevent="onSubmit"-->
	<div class="mx-auto w-full md:w-[34em]">
		<div class="mx-auto h-[254px] p-4 outer-card bg-white dark:bg-gr-darkest lg:gap-8">

			<div class="grid grid-cols-3 text-gray-500 dark:text-gray-200 mb-2">
				<div class="col-span-2">{{ $t('yourTranscription') }}</div>
				<div class="flex flex-row-reverse gap-3 text-right items-center">
					<SettingsNodePopover></SettingsNodePopover>
					<button v-show="allowChangeLanguage" class="tooltip disabled:opacity-75" disabled
									data-tip="change Language - available soon">
						<LanguageIcon class="h-5 w-5"></LanguageIcon>
					</button>
					<button v-show="allowCopyText && lastRunResult" @click="copyContent(lastRunResult ?? '')" class="tooltip"
									data-tip="copy Text">
						<DocumentDuplicateIcon class="h-5 w-5"></DocumentDuplicateIcon>
					</button>
					<button v-show="allowDownloadPdf" class="tooltip disabled:opacity-75" @click="downloadPdf"
									data-tip="download PDF - available soon">
						<ArrowDownTrayIcon class="h-5 w-5"></ArrowDownTrayIcon>
					</button>
				</div>
			</div>
			<textarea :placeholder="placeholderText" readonly :value="selectedFlowProcess ? lastRunResult : ''"
								:disabled="!isTextAreaVisible()"
								:class="['h-[85%] mb-2 bg-transparent w-full rounded-md outline-none dark:text-gray-400', !isTextAreaVisible() ? 'disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none' : '']"></textarea>
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref, watch} from 'vue';
import {DocumentDuplicateIcon, ArrowDownTrayIcon, LanguageIcon} from '@heroicons/vue/24/outline';
import SettingsNodePopover from '@/components/workflow/nodes/SettingsNodePopover.vue';
import useFlow from '@/composable/greeve/useFlow';
import {
	FlowProcessDownloadAndShow,
} from '@/greeve/flow/process/type/download_and_show/flow_process_download_and_show.type';
import {AbstractFlow} from '@/greeve/flow/abstract_flow.type';
import {FlowState} from '@/greeve/flow/flow.interface';
import {
	FlowQueueProcessDefaultDownloadAndShow,
} from '@/greeve/flow/queue/process/type/default/subType/flow_queue_process_default_download_and_show.type';
import useSystem from '@/composable/core/useSystem';
import {getCurrentDateTime} from '@/greeve/core/date';
import useCustomStore from '@/composable/custom/useCustomStore';

export default defineComponent({
	name: 'TextViewNode',
	components: {
		SettingsNodePopover,
		DocumentDuplicateIcon, ArrowDownTrayIcon, LanguageIcon,
	},
	props: {
		flowId: {type: Number, required: false},
		flowProcessUuid: {type: String, required: false},
		maxRows: {
			type: Number,
			default: 1,
		},
		autoGrow: {
			type: Boolean,
			default: true,
		},
		allowChangeLanguage: {
			type: Boolean,
			default: true,
		},
		allowCopyText: {
			type: Boolean,
			default: true,
		},
		allowDownloadPdf: {
			type: Boolean,
			default: true,
		},
		placeholderText: {
			type: String,
			default: 'Empty Text..."',
		},
	},
	emits: [
		'error',
		'nodeChanged',
	],
	setup(props) {
		const {flows, initFlows} = useFlow();
		const {copyContent, downloadPdfByContent} = useSystem();
		const {setIsGlobalLoading} = useCustomStore();
		const selectedFlowProcess = ref<FlowProcessDownloadAndShow | any | undefined>();
		const selectedFlow = ref<AbstractFlow | undefined>();
		const lastRunResult = ref<string | undefined>();
		const isInitializing = ref(false);

		//TODO check if flow is running or finished else disable textarea

		async function initSelectedFlowProcess() {
			if (isInitializing.value) {
				return;
			}

			isInitializing.value = true;
			if (flows.value && props.flowId && flows.value.getItemById(props.flowId)) {
				selectedFlow.value = flows.value.getItemById(props.flowId);
				if (selectedFlow.value && selectedFlow.value.flow_processes && props.flowProcessUuid) {
					selectedFlowProcess.value = selectedFlow.value.flow_processes.getItemByUuId(props.flowProcessUuid);
					lastRunResult.value = getLastRunResult();
				}
				isInitializing.value = false;
			} else {
				initFlows(true).then(() => {
					isInitializing.value = false;
					if (flows.value && props.flowId && flows.value.getItemById(props.flowId)) {
						selectedFlow.value = flows.value.getItemById(props.flowId);
						if (selectedFlow.value && selectedFlow.value.flow_processes && props.flowProcessUuid) {
							selectedFlowProcess.value = selectedFlow.value.flow_processes.getItemByUuId(props.flowProcessUuid);
							lastRunResult.value = getLastRunResult();
						}
					} else {
						//TODO ERROR MESSAGE!
						console.log('ERROR');
					}
				});
			}
		}

		async function downloadPdf() {
			if (!lastRunResult.value) {
				//TODO show error message!
				console.error("EMPTY result!");
				return false;
			}
			setIsGlobalLoading(true);
			const timeoutId = setTimeout(() => {
				setIsGlobalLoading(false);
			}, 30000)
			let name = selectedFlow.value?.getTranslatedName()?.substring(0, 10);
			if (!name) {
				name = lastRunResult.value.substring(0, 10);
			}
			name += getCurrentDateTime();
			try {
				await downloadPdfByContent(name, lastRunResult.value);
			} finally {
				clearTimeout(timeoutId);
				setIsGlobalLoading(false);
			}
		}

		function isTextAreaVisible(): boolean {
			let result = true;
			if (selectedFlow.value && selectedFlow.value.state !== FlowState.ACTIVE) {
				return false;
			}

			if (selectedFlowProcess.value && !lastRunResult.value) {
				return false;
			}

			return result;
		}

		function getLastRunResult(): string {
			if (selectedFlowProcess.value && selectedFlow.value && selectedFlow.value?.flow_queues) {
				const newestQueueProcess = selectedFlow.value.flow_queues.getNewestProcess_by_Id(selectedFlowProcess.value.id);
				if (!newestQueueProcess) {
					return '';
				}
				if (newestQueueProcess instanceof FlowQueueProcessDefaultDownloadAndShow) {
					return newestQueueProcess.getResultForView();
				}
			}
			return '';
		}

		// watch(() => selectedFlow.value, (newSelectedFlow, oldSelectedFlow) => {
		// 	console.log('ViewNode selectedFlow changed', newSelectedFlow);
		// });

		// watch(() => selectedFlow.value?.flow_queues, (newSelectedFlowQueues, oldSelectedFlowQueues) => {
		// 	console.log('QUEUE of selectedFlow changed', newSelectedFlowQueues, oldSelectedFlowQueues);
		// });

		watch(() => flows.value, (newFlows, oldFlows) => {
			if (selectedFlow.value?.uuid && newFlows !== oldFlows) {
				const newSelectedFlow = newFlows.getItemByUuId(selectedFlow.value.uuid);
				if (newSelectedFlow && newSelectedFlow !== selectedFlow.value) {
					selectedFlow.value = newSelectedFlow;
					initSelectedFlowProcess();
				}
			}
		});

		onMounted(() => {
			initSelectedFlowProcess();
		});

		return {
			selectedFlowProcess,
			isTextAreaVisible,
			copyContent,
			lastRunResult,
			downloadPdf,
		};
	},
});
</script>

<style lang="scss">
</style>
