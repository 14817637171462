<template>
	<div :class="contactCss" id="contact-container">
		<div class="">
			<div class="md:flex"><img loading="lazy"
																src="https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/images/landing/3d/officeLuggage.png"
																class="h-28 mr-8 mt-0" alt="Office Luggage Image">
				<div><h2 :class="contactHeaderCss" v-html="contactHeader"/>
					<p :class="contactHeaderInfoCss" v-html="contactHeaderInfo"/>
				</div>
			</div>
			<div id="contact-alert-message" class="col-span-2">
				<div v-show="successMessage">
					<div class="rounded-md bg-green-50 p-4 mt-6">
						<div class="flex">
							<div class="flex-shrink-0">
								<CheckCircleIcon class="h-5 w-5 text-green-400" aria-hidden="true"/>
							</div>
							<div class="ml-3">
								<p class="text-sm font-medium text-green-800">{{ t('inquiry.FormSuccess') }}</p>
							</div>
							<div class="ml-auto pl-3">
								<div class="-mx-1.5 -my-1.5">
									<button type="button"
													class="inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600">
										<span class="sr-only">{{ t('inquiry.FormClose') }}</span>
										<XMarkIcon class="h-5 w-5" aria-hidden="true"/>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div v-show="errorMessage">
					<div class="rounded-md bg-red-50 p-4 mt-6">
						<div class="flex">
							<div class="flex-shrink-0">
								<XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true"/>
							</div>
							<div class="ml-3">
								<h3 class="text-sm font-medium text-red-800">{{ errorMessage }}</h3>
							</div>
						</div>
					</div>
				</div>
			</div>

			<form @submit.prevent="sendContactForm" id="contact-form-container"
						class="mt-9 grid grid-cols-1 gap-y-6 sm:grid-cols-12 sm:gap-x-8">

				<div class="col-span-12 sm:col-span-6">
					<label class="pl-1 block text-sm font-medium text-black dark:text-black"
								 for="first-name">{{ t('contact.FormFirstName') }} *</label>
					<div class="mt-1">
						<input id="first-name" autocomplete="given-name" v-model="contactData.firstName"
									 required
									 class="px-4  text-black dark:text-black bg-tc-bglightest h-12 block w-full shadow-sm sm:text-sm focus:ring-tc-blue focus:border-tc-blue border-gray-300 rounded-2xl"
									 name="first-name"
									 type="text"/>
					</div>
				</div>
				<div class="col-span-12 sm:col-span-6">
					<label class="pl-1 block text-sm font-medium  text-black dark:text-black"
								 for="last-name">{{ t('contact.FormLastName') }} *</label>
					<div class="mt-1">
						<input id="last-name" autocomplete="family-name" v-model="contactData.lastName" required
									 class="px-4  text-black dark:text-black bg-tc-bglightest h-12 block w-full shadow-sm sm:text-sm focus:ring-tc-blue focus:border-tc-blue border-gray-300 rounded-2xl"
									 name="last-name"
									 type="text"/>
					</div>
				</div>
				<div class="col-span-12">
					<label class="pl-1 block text-sm font-medium  text-black dark:text-black"
								 for="email">{{ t('contact.FormMail') }} *</label>
					<div class="mt-1">
						<input id="email" autocomplete="email" v-model="contactData.email" required
									 class="px-4  text-black dark:text-black bg-tc-bglightest h-12 block w-full shadow-sm sm:text-sm focus:ring-tc-blue focus:border-tc-blue border-gray-300 rounded-2xl"
									 name="email"
									 type="email"/>
					</div>
				</div>
				<div class="col-span-12">
					<div class="flex justify-between">
						<label
								class="pl-1  text-black dark:text-black block text-sm font-medium">{{
								t('company.FormMessage')
							}} *</label>
					</div>
					<div class="mt-1">
                <textarea id="how-can-we-help" aria-describedby="how-can-we-help-description"
													v-model="contactData.data.message" required
													class="p-4  text-black dark:text-black bg-tc-bglightest block w-full shadow-sm sm:text-sm focus:ring-tc-blue focus:border-tc-blue border-gray-300 rounded-2xl"
													name="how-can-we-help"
													rows="4"/>
					</div>
				</div>
				<div class="text-right col-span-12 pb-4">
					<button
							class="w-full bg-gr-primary text-white tracking-widest font-bold inline-flex justify-center py-3 px-4 border border-transparent shadow-sm text-sm rounded-2xl bg-tc-green hover:bg-tc-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-tc-blue"
							type="submit" v-html="contactSubmitButton">
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue';
import 'aos/dist/aos.css';
import useTranslation from '@/composable/translation/useTranslation';
import {CheckCircleIcon, XMarkIcon, XCircleIcon} from '@heroicons/vue/20/solid';
import useCustomStore from '@/composable/custom/useCustomStore';
import useContact from '@/composable/greeve/useContact';
import useSystem from '@/composable/core/useSystem';

export const enum ContactTypes {
	CONTACT_JOB = 'job',
	CONTACT_DEFAULT = 'default',
	CONTACT_ENTERPRISE = 'enterprise',
}

export interface CONTACT_FORM_INTERFACE {
	type: ContactTypes;
	email: string;
	agreePrivacy: boolean;
	name?: string;
	firstName?: string;
	lastName?: string;
	language?: string;
	data?: {
		company?: string,
		phone?: string,
		help?: string,
		subject?: string,
		message?: string,
		howDidYouHearAboutUs?: string,
	};
}

export default defineComponent({
	name: 'InquiryComponent',
	components: {CheckCircleIcon, XMarkIcon, XCircleIcon},
	props: {
		contactType: {
			type: Object as () => ContactTypes,
			default: ContactTypes.CONTACT_DEFAULT,
		},
		contactCss: {
			type: String,
			default: 'mx-4 nav rounded-2xl p-4 lg:pt-20 nav lg:px-20',
		},
		contactHeader: {
			type: String,
			default: '',
		},
		contactHeaderCss: {
			type: String,
			default: 'mt-4 text-black dark:text-black text-3xl font-extrabold tracking-tight sm:text-4xl',
		},
		contactHeaderInfo: {
			type: String,
			default: '',
		},
		contactHeaderInfoCss: {
			type: String,
			default: 'text-black dark:text-black mt-4 text-lg sm:mt-3',
		},
		showCompany: {
			type: Boolean,
			default: false,
		},
		showHowDidYouHearAboutUs: {
			type: Boolean,
			default: false,
		},
		contactSubmitButton: {
			type: String,
			default: '',
		},
	},
	methods: {},
	setup(props) {
		const {t} = useTranslation();
		const {getLanguageLocale} = useCustomStore();
		const {sendContact} = useContact();
		const {scrollToElement} = useSystem();

		const contactData = ref<CONTACT_FORM_INTERFACE>({
			'type': props.contactType,
			'email': '',
			'name': '',
			'firstName': '',
			'lastName': '',
			'agreePrivacy': false,
			'language': getLanguageLocale.value,
			'data': {
				'company': '',
				'phone': '',
				'help': '',
				'subject': '',
				'message': '',
				'howDidYouHearAboutUs': '',
			},
		});
		const successMessage = ref('');
		const errorMessage = ref('');

		function scrollToAlertMessage() {
			scrollToElement('contact-alert-message');
		}

		function resetContactData() {
			contactData.value = {
				'type': props.contactType,
				'email': '',
				'name': '',
				'firstName': '',
				'lastName': '',
				'agreePrivacy': false,
				'language': getLanguageLocale.value,
				'data': {
					'company': '',
					'phone': '',
					'help': '',
					'subject': '',
					'message': '',
					'howDidYouHearAboutUs': '',
				},
			};
		}

		function sendContactForm() {
			successMessage.value = '';
			errorMessage.value = '';
			contactData.value.language = getLanguageLocale.value;
			if (!contactData.value.name || contactData.value.name.length === 0) {
				contactData.value.name = contactData.value.firstName + ' ' + contactData.value.lastName;
			}
			sendContact(contactData.value).then(() => {
						successMessage.value = t('inquiry.FormSuccess');
						resetContactData();
						scrollToAlertMessage();
					},
					error => {
						console.log(error);
						errorMessage.value = t('inquiry.FormError');
						scrollToAlertMessage();
					});
		}

		const primaryFeatures = [
			{
				name: 'Push to deploy.',
				description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit aute id magna.',
				icon: CheckCircleIcon,
			},
			{
				name: 'SSL certificates.',
				description: 'Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.',
				icon: CheckCircleIcon,
			},
			{
				name: 'Database backups.',
				description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus.',
				icon: CheckCircleIcon,
			},
		];
		const secondaryFeatures = [
			{
				name: 'Push to deploy',
				description:
						'Commodo nec sagittis tortor mauris sed. Turpis tortor quis scelerisque diam id accumsan nullam tempus. Pulvinar etiam lacus volutpat eu. Phasellus praesent ligula sit faucibus.',
				href: '#',
				icon: CheckCircleIcon,
			},
			{
				name: 'SSL certificates',
				description:
						'Pellentesque enim a commodo malesuada turpis eleifend risus. Facilisis donec placerat sapien consequat tempor fermentum nibh.',
				href: '#',
				icon: CheckCircleIcon,
			},
			{
				name: 'Simple queues',
				description:
						'Pellentesque sit elit congue ante nec amet. Dolor aenean curabitur viverra suspendisse iaculis eget. Nec mollis placerat ultricies euismod ut condimentum.',
				href: '#',
				icon: CheckCircleIcon,
			},
		];

		return {t, contactData, secondaryFeatures, primaryFeatures, successMessage, errorMessage, sendContactForm};
	},
});

</script>
<style>
</style>
