import {
	QuotaTransactionInterface, QuotaTransactionState, QuotaTransactionType,
} from '@/greeve/user/quota/transaction/quota_transaction.interface';
import i18n from '@/i18n';

export class QuotaTransaction implements QuotaTransactionInterface {
	id: number;
	uuid: string;
	quota_id: number;
	parent_id?: number;
	type: QuotaTransactionType;
	sub_type?: any;
	state: QuotaTransactionState;
	reference?: string;
	amount: number;
	delta?: number;
	subject?: string;
	description?: string;
	data?: any;
	created_at?: Date;
	updated_at?: Date;

	constructor(
			id: number, uuid: string, quota_id: number, type: QuotaTransactionType,
			state: QuotaTransactionState, amount: number, delta?: number, sub_type?: any, parent_id?: number, reference?: string,
			subject?: string, description?: string,	data: any = null, created_at?: Date, updated_at?: Date) {
		this.id = id;
		this.uuid = uuid;
		this.parent_id = parent_id;
		this.quota_id = quota_id;
		this.type = type;
		this.sub_type = sub_type;
		this.state = state;
		this.reference = reference;
		this.amount = amount;
		this.delta = delta;
		this.data = data;
		this.created_at = created_at;
		this.updated_at = updated_at;
	}

	getTransactionName(): string
	{
		try {
			return i18n.global.t('shop.quota.transaction.'+this.type+'.name');
		} catch (e) {
			console.error(e);
			return '';
		}
	}

	formatAmount(amount: number): string
	{
		if (Math.abs(amount) < 1) {
			return amount.toFixed(5);
		} else {
			return amount.toFixed(2);
		}
	}

	getTransactionDetailName(): string
	{
		try {
			return i18n.global.t('shop.quota.transaction.'+this.type+'.detail', {amount: this.formatAmount(Number(this.amount))});
		} catch (e) {
			console.error(e);
			return '';
		}
	}

	getTransactionInfo(): string
	{
		try {
			return i18n.global.t('shop.quota.transaction.'+this.sub_type+'.info');
		} catch (e) {
			console.error(e);
			return '';
		}
	}
}