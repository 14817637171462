<template>
	<ion-page>
		<ion-content :fullscreen="true">
			<HeaderLogo>
				<div  class="rounded-3xl px-3 font-comfortaa py-0 bg-black dark:bg-gray-400 font-bold text-white dark:text-black mt-0 float-right text-tc-bg text-xs sm:text-base sm:mr-1.5 -mt-1 sm:-mt-2 ">
					{{ $t('beta') }}
				</div>
			</HeaderLogo>  			<div class="bg-tc-bg">
				<div class="mx-4 md:mx-7">
					<div>
						<div class="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:py-40 lg:px-8">
					<FaqSection></FaqSection>
				</div>
				</div>
				</div>
			</div>
			<FooterBar></FooterBar>
		</ion-content>
	</ion-page>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {IonPage, IonContent} from '@ionic/vue';
import FooterBar from "@/components/positions/FooterBar.vue";
import useTranslation from "@/composable/translation/useTranslation";
import HeaderLogo from "@/components/branding/HeaderLogo.vue";
import useFaq from "@/composable/faq/useFaq";
import FaqSection from "@/components/sections/FaqSection.vue";

export default defineComponent({
	name: 'FaqPage',
	components: {
		FaqSection,
		HeaderLogo,
		FooterBar, IonContent, IonPage,
	},
	setup() {
		const {t} = useTranslation();
		const {faqs} = useFaq();
		return {t, faqs}
	}
});

</script>
<style lang="scss">
</style>
