// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {AxiosError, AxiosResponse} from 'axios';
import {ResponseError} from '@/greeve/api/api.interfaces';
import GreeveApiClient from '@/greeve/api/client';
import {FlowList} from '@/greeve/flow/flow_list.type';
import {FlowFactory} from '@/greeve/flow/flow_factory';
import {
    FlowQueueJournalList,
} from '@/greeve/flow/queue/journal/flow_queue_journal_list.type';
import {
    FlowQueueJournalFactory,
} from '@/greeve/flow/queue/journal/flow_queue_journal_factory.type';
import {AbstractFlow} from '@/greeve/flow/abstract_flow.type';
import {
    FlowProcessDependencyList,
} from '@/greeve/flow/process/dependency/flow_process_dependency_list.type';
import {
    FlowProcessDependencyFactory,
} from '@/greeve/flow/process/dependency/flow_process_dependency_factory';
import {
    AbstractFlowProcess,
} from '@/greeve/flow/process/abstract_flow_process.type';
import {FlowProcessFactory} from '@/greeve/flow/process/flow_process_factory';
import {FlowQueueList} from '@/greeve/flow/queue/flow_queue_list.type';
import {FlowQueueFactory} from '@/greeve/flow/queue/flow_queue_factory';
import {AbstractFlowQueue} from '@/greeve/flow/queue/abstract_flow_queue.type';
import { ProgressEventCallback } from '../core/progressEventCallback';

interface RunFlowResponse {
    uuid?: string,
    flow_uuid?: string,
}

const GreeveApiFlow = {
    //TODO return type add FlowTemplateList[]
    getFlowTemplates: async function (): Promise<FlowList|undefined> {
        try {
            const response = await GreeveApiClient.get('/flow/template');
            if (response) {
                const responseData = response.data.data.data;
                if (responseData) {
                    const flowFactory = new FlowFactory();
                    return flowFactory.createFlowListByResponse(responseData);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    getUserFlows: async function (): Promise<FlowList|undefined> {
        try {
            const response = await GreeveApiClient.get('/user/flow');
            if (response) {
                console.log(response)
                const responseData = response.data.data.data;
                if (responseData) {
                    const flowFactory = new FlowFactory();
                    return flowFactory.createFlowListByResponse(responseData);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    getUserFlow_by_Uuid: async function (flowUuid: string): Promise<AbstractFlow|undefined> {
        try {
            const response = await GreeveApiClient.get('/user/flow/uuid/' + flowUuid);
            if (response) {
                const responseData = response.data.data.data;
                if (responseData) {
                    const flowFactory = new FlowFactory();
                    return flowFactory.createFlowByResponse(responseData);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    getFlowProcessDependency_List: async function (flowUuid: string): Promise<FlowProcessDependencyList|undefined> {
        try {
            const response = await GreeveApiClient.get('/user/flow_dependency?flow_uuid='+flowUuid);
            if (response) {
                const responseData = response.data.data;
                if (responseData) {
                    const flowProcessDependencyFactory = new FlowProcessDependencyFactory();
                    return flowProcessDependencyFactory.createFlowProcessDependencyListByResponse(responseData);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    getFlowQueue_by_QueueUuid: async function (queueUuid: string): Promise<AbstractFlowQueue|undefined> {
        try {
            const response = await GreeveApiClient.get('/user/flow_queue/queue/' + queueUuid);
            if (response) {
                const responseData = response.data.data.data;
                if (responseData) {
                    const flowQueueFactory = new FlowQueueFactory();
                    return flowQueueFactory.createFlowQueueByResponse(responseData);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },


    getFlowQueues_by_FlowUuid: async function (flowUuid: string): Promise<FlowQueueList|undefined> {
        try {
            const response = await GreeveApiClient.get('/user/flow_queue/' + flowUuid);
            if (response) {
                const responseData = response.data.data.data;
                if (responseData) {
                    const flowQueueFactory = new FlowQueueFactory();
                    return flowQueueFactory.createFlowQueueListByResponse(responseData);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    getFlowQueueJournals: async function (flowUuid: string): Promise<FlowQueueJournalList|undefined> {
        try {
            const response = await GreeveApiClient.get('/user/flow/queue/journal/'+flowUuid);
            if (response) {
                const responseData = response.data.data.data;
                if (responseData) {
                    const flowQueueJournalFactory = new FlowQueueJournalFactory();
                    return flowQueueJournalFactory.createFlowQueueJournalListByResponse(responseData);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    getEstimatedFlowQuotaAmount: async function (flowUuid: string): Promise<number|undefined> {
        try {
            const response = await GreeveApiClient.post('/user/flow/getEstimatedQuotaAmount', {uuid: flowUuid});
            if (response) {
                const responseData = response.data.data;
                if (responseData) {
                    return Number(responseData.amount);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    getEstimatedFlowProcessQuotaAmount: async function (flowProcessUuid: string): Promise<number|undefined> {
        try {
            const response = await GreeveApiClient.post('/user/flow_process/getEstimatedQuotaAmount', {uuid: flowProcessUuid});
            if (response) {
                const responseData = response.data.data.data;
                if (responseData) {
                    return Number(responseData.amount);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    isFlowQueueRunning: async function (flowQueueUuid: string): Promise<boolean|undefined> {
        try {
            const response = await GreeveApiClient.get('/user/flow/queue/state/'+flowQueueUuid);
            if (response) {
                return response.data.data;
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    deleteUserFlow_by_Uuid: async function (flowUuid: string): Promise<boolean|undefined> {
        try {
            const response = await GreeveApiClient.delete('/user/flow/' + flowUuid);
            return !!response;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    deleteUserFlowProcess_by_Uuid: async function (flowProcessUuid: string): Promise<boolean|undefined> {
        try {
            const response = await GreeveApiClient.delete('/user/flow_process/' + flowProcessUuid);
            return !!response;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },


    convertFlowTemplate_to_Flow: async function (flowUuid: string): Promise<AbstractFlow|undefined> {
        try {
            const response = await GreeveApiClient.post('/user/flow/template/convert', {uuid: flowUuid});
            if (response) {
                const responseData = response.data.data.data;
                if (responseData) {
                    const flowFactory = new FlowFactory();
                    return flowFactory.createFlowByResponse(responseData);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },


    runFlow: async function (flowUuid: string): Promise<RunFlowResponse|undefined> {
        try {
            const response = await GreeveApiClient.post('/user/flow/run', {flow_uuid: flowUuid});
            if (response) {
                const responseData = response.data.data;
                if (responseData) {
                    return responseData;
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    updateFlowInfo: async function (flowUuid: string, flowName: string, flowDescription: string): Promise<AbstractFlow|undefined> {
        try {
            const data = {
                'uuid': flowUuid,
                'name': flowName,
                'description': flowDescription,
            }
            const response = await GreeveApiClient.post('/user/flow/updateInfo', data);
            if (response) {
                const responseData = response.data.data.data;
                if (responseData) {
                    const flowFactory = new FlowFactory();
                    return flowFactory.createFlowByResponse(responseData);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    updateFlow: async function (flowUuid: string, formData: FormData, onUploadProgress?: ProgressEventCallback): Promise<AbstractFlow|undefined> {
        try {
            formData.append('uuid', flowUuid);

            const config: {[key: string]: any} = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            };
            if (onUploadProgress) {
                config.onUploadProgress = (progressEvent: ProgressEvent) => onUploadProgress(progressEvent);
            }
            const response = await GreeveApiClient.post('/user/flow', formData, config);
            if (response) {
                const responseData = response.data.data.data;
                if (responseData) {
                    const flowFactory = new FlowFactory();
                    return flowFactory.createFlowByResponse(responseData);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    updateFlowProcess: async function (flowProcess: AbstractFlowProcess, formData: FormData, onUploadProgress?: ProgressEventCallback): Promise<AbstractFlowProcess|undefined> {
        try {
            formData.append('uuid', flowProcess.uuid);
            if (flowProcess.name) {
                formData.append('name', flowProcess.name);
            }
            if (flowProcess.description) {
                formData.append('description', flowProcess.description);
            }

            const config: {[key: string]: any} = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            };
            if (onUploadProgress) {
                config.onUploadProgress = (progressEvent: ProgressEvent) => onUploadProgress(progressEvent);
            }
            const response = await GreeveApiClient.post('/user/flow_process', formData, config);
            if (response) {
                const responseData = response.data.data.data;
                if (responseData) {
                    const flowProcessFactory = new FlowProcessFactory();
                    return flowProcessFactory.createFlowProcessByResponse(responseData);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    uploadAudioFiles: async function (flowProcessUuid: string, files: File[], onUploadProgress?: ProgressEventCallback): Promise<AbstractFlowProcess|undefined> {
        // Check if the input is an array of File objects
        if (!Array.isArray(files) || files.some(file => !(file instanceof File))) {
            throw new Error('The provided object is not an array of Files.');
        }

        // Create FormData object
        const formData = new FormData();
        formData.append('uuid', flowProcessUuid);
        // Append each file to the FormData object
        files.forEach((file, index) => {
            formData.append(`audio_file_${index}`, file);
        });

        // Define Axios configuration
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: onUploadProgress ? (progressEvent: ProgressEvent) => onUploadProgress(progressEvent) : null,
        };

        try {
            // Send the POST request with the FormData object and the configuration
            const response: AxiosResponse = await GreeveApiClient.post('/user/flow_process', formData, config);

            if (response) {
                const responseData = response.data.data.data;
                if (responseData) {
                    const flowProcessFactory = new FlowProcessFactory();
                    return flowProcessFactory.createFlowProcessByResponse(responseData);
                }
            }
        } catch (error: any) {
            // Handle and re-throw the error
            if (error.response) {
                throw new Error(`Request failed with status code ${error.response.status}`);
            } else if (error.request) {
                throw new Error('No response was received');
            } else {
                throw new Error(`Error: ${error.message}`);
            }
        }
    },

    uploadFiles: async function (flowProcessId: string, files: File[], onUploadProgress?: ProgressEventCallback): Promise<any|undefined> {
        // Check if the input is an array of File objects
        if (!Array.isArray(files) || files.some(file => !(file instanceof File))) {
            throw new Error('The provided object is not an array of Files.');
        }

        // Create FormData object
        const formData = new FormData();
        formData.append('flowProcessId', flowProcessId);
        // Append each file to the FormData object
        files.forEach((file, index) => {
            formData.append(`file${index}`, file);
        });

        // Define Axios configuration
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: onUploadProgress ? (progressEvent: ProgressEvent) => onUploadProgress(progressEvent) : null,
        };

        try {
            // Send the POST request with the FormData object and the configuration
            const response: AxiosResponse = await GreeveApiClient.post('/flow/uploadFiles', formData, config);

            // Return the response data
            return response.data;
        } catch (error: any) {
            // Handle and re-throw the error
            if (error.response) {
                throw new Error(`Request failed with status code ${error.response.status}`);
            } else if (error.request) {
                throw new Error('No response was received');
            } else {
                throw new Error(`Error: ${error.message}`);
            }
        }
    },

    deleteFlowMediaFile: async function (flowMediaId: string): Promise<any|undefined> {
        const params:any = {
            'id': flowMediaId,
        };
        try {
            // Send the POST request with the FormData object and the configuration
            const response: AxiosResponse = await GreeveApiClient.post('/flow/deleteFile', params);

            // Return the response data
            return response.data;
        } catch (error: any) {
            // Handle and re-throw the error
            if (error.response) {
                throw new Error(`Request failed with status code ${error.response.status}`);
            } else if (error.request) {
                throw new Error('No response was received');
            } else {
                throw new Error(`Error: ${error.message}`);
            }
        }
    },
}

export {GreeveApiFlow};
