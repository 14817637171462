<!--suppress ALL -->
<template>
	<div v-if="fixedLoadingAnimation" class="fixed w-full left-0 top-0 z-10">
		<div class="loader-line"></div>
	</div>
	<div v-else :class="positionCss">
		<div class="loader-line"></div>
	</div>
</template>

<script>
export default {
	name: "LoadingLineAnimation",
	props: {
		positionCss: {
			type: String,
			default: 'top-0 sticky absolute',
		},
		fixedLoadingAnimation: {type: Boolean, default: false},
	}
}
</script>

<style lang="scss">


.loader-line {
  //width: 5000px; //?? why
  height: 3px;
  overflow: hidden;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.loader-line:before {
  content: "";
  position: absolute;
  left: -50%;
  height: 3px;
  width: 40%;
  background: linear-gradient(90deg, rgba(253,214,34,1) 0%, rgba(227,6,19,1) 34%, rgba(239,125,0,1) 100%);
  -webkit-animation: lineAnim 1.5s linear infinite;
  -moz-animation: lineAnim 1.5s linear infinite;
  animation: lineAnim 1.5s linear infinite;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

@keyframes lineAnim {
  0% {
    left: -40%;
  }
  50% {
    left: 0%;
    width: 80%;
  }
  100% {
    left: 100%;
    width: 100%;
  }
}
</style>
