import {MediaFactory} from '@/greeve/media/media_factory.type';

export default function useMediaFactory() {

	let mediaFactory: MediaFactory | null = null;
	const getMediaFactory = (): MediaFactory => {
		if (!mediaFactory) {
			mediaFactory = new MediaFactory();
		}
		return mediaFactory;
	}

	return {
		getMediaFactory,
	}
}