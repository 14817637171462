<template>
	<div class="">
		<div class="w-full">
			<div class="relative">
				<div>
					<label class="ml-6 bg-transparent h-5 block text-left text-sm font-medium text-gray-400"
						   for="password">{{ inputPlaceholder }}</label>
					<div class="">
						<div class="outer-card rounded-xl overflow-hidden">
							<ion-input id="password" aria-describedby="email-error" aria-invalid="true"
									   :placeholder="inputPlaceholder"
									   autocomplete="current-password"
									   v-model="passwordState"
									   :required="requiredField"
									   :type="!showPassword ? inputType : 'text'"
									   name="password"
									   :class="[errorMessage.length > 0 ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500' : '']"/>
							<div v-show="errorMessage.length > 0"
								 class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
								<ExclamationCircleIcon aria-hidden="true" class="h-5 w-5 text-red-500"/>
							</div>
						</div>
						<div class="absolute z-50 inset-y-0 cursor-pointer text-gray-400 right-0 pr-6 pt-5 flex items-center text-sm leading-5">
							<svg :class="{'block h-5 w-5': !showPassword, 'hidden': showPassword }" fill="none"
								 stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
								 @click="showPassword = !showPassword">
								<path d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
									  stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
							</svg>

							<svg :class="{'block h-5 w-5': showPassword, 'hidden':!showPassword }" fill="none"
								 stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
								 @click="showPassword = !showPassword">
								<path d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" stroke-linecap="round"
									  stroke-linejoin="round" stroke-width="2"/>
								<path d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
									  stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
							</svg>
						</div>
					</div>
					<p v-show="errorMessage.length > 0" id="password-error" class="mt-2 text-sm text-red-600">
						{{ errorMessage }}</p>
				</div>
			</div>
			<div class="flex w-full pt-5 px-1" v-show="showPasswordStrengthoMeter === true">
				<span :class="currentPassword.length > 5 ? 'bg-green-700' : 'bg-gray-200 dark:bg-gr-darker'"
			  class="h-2 w-1/3 rounded"></span>
				<span :class="currentPassword.length > 5 && currentPassword.match(/[\w]+/) ? 'bg-green-700' : 'bg-gray-200 dark:bg-gr-darker'"
					  class="h-2 w-1/3 rounded mx-3"></span>
				<span :class="currentPassword.length > 5 && currentPassword.match(/[!@#$%^&*(),.?:{}|<>]/) ? 'bg-green-700' : 'bg-gray-200 dark:bg-gr-darker'"
					  class="h-2 w-1/3 rounded"></span>
			</div>
		</div>
	</div>

</template>

<script lang="ts">
import {computed, defineComponent, ref} from 'vue';
import {useVModel} from "@/composable/input/useVModel";
import {ExclamationCircleIcon} from '@heroicons/vue/20/solid'
import {IonInput} from '@ionic/vue';

export default defineComponent({
	name: 'InputPassword',
	components: {ExclamationCircleIcon, IonInput},
	emits: ['update:password'],
	props: {
		// password: String,
		password: {
			type: String,
			required: true,
			default: ""
		},
		inputType: {
			type: String,
			required: false,
			default: "password"
		},
		inputPlaceholder: {
			type: String,
			required: true,
			default: "Password"
		},
		requiredField: {
			type: Boolean,
			required: false,
			default: true
		},
		showPasswordStrengthoMeter: {
			type: Boolean,
			required: false,
			default: true
		},
		errorMessage: {
			type: String,
			default: ""
		},
	},
	setup(props) {

		const currentPassword = computed(() => props.password);
		const showPassword = ref(false);
		const passwordScore = ref(0);

		return {
			passwordState: useVModel(props, 'password'),
			currentPassword,
			showPassword,
			passwordScore,
			// passwordScore: 0,
			// password: '',
			// chars: {
			// 	lower: 'abcdefghijklmnopqrstuvwxyz',
			// 	upper: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
			// 	numeric: '0123456789',
			// 	symbols: '!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~'
			// },
			// charsLength: 12,
			// checkStrength: function() {
			// 	if(!currentPassword) return passwordScore.value = 0;
			// 	passwordScore.value = zxcvbn(this.password).score + 1;
			// },
		};
	},
})
</script>

<style lang="scss" scoped>
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus, {
	border: none !important;
	-webkit-text-fill-color: currentColor !important;
	-webkit-box-shadow: none !important;
	transition: background-color 5000s ease-in-out 0s;
	color: currentColor;
	background-color: transparent;
}

.input-password-new {
    align-items: flex-end;
    padding: 8px 25px;
}

.input-password {
    align-items: flex-end;
    border-radius: 25px;
    display: flex;
    padding: 8px 25px;
}

.bp-password {
    background-color: transparent;
    border: 1px;
    flex-shrink: 1;
    height: 35px;
    letter-spacing: 1.78px;
    padding: 0;
    resize: none;
    text-align: left;
}

.bp-password::placeholder {
    color: #70707099;
}

.bp-password:focus {
    @apply outline-none
}
</style>
