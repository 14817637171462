import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "relative isolate flex flex-none items-center gap-x-6 overflow-hidden bg-gray-50 px-6 py-2.5 sm:px-3.5 sm:before:flex-1 min-h-12"
}
const _hoisted_2 = { class: "flex flex-wrap items-center gap-x-4 gap-y-2" }
const _hoisted_3 = { class: "text-sm leading-6 text-gray-900" }
const _hoisted_4 = { class: "font-semibold" }
const _hoisted_5 = { class: "flex flex-1 justify-end" }
const _hoisted_6 = {
  type: "button",
  class: "-m-3 p-3 focus-visible:outline-offset-[-4px]"
}
const _hoisted_7 = { class: "sr-only" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_XMarkIcon = _resolveComponent("XMarkIcon")!

  return (_ctx.isOpen)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _cache[3] || (_cache[3] = _createElementVNode("div", {
          class: "absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl",
          "aria-hidden": "true"
        }, [
          _createElementVNode("div", {
            class: "aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30",
            style: {"clip-path":"polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)"}
          })
        ], -1)),
        _cache[4] || (_cache[4] = _createElementVNode("div", {
          class: "absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl",
          "aria-hidden": "true"
        }, [
          _createElementVNode("div", {
            class: "aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30",
            style: {"clip-path":"polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)"}
          })
        ], -1)),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, [
            _createElementVNode("strong", _hoisted_4, _toDisplayString(_ctx.title), 1),
            _cache[1] || (_cache[1] = _createElementVNode("svg", {
              viewBox: "0 0 2 2",
              class: "mx-2 inline h-0.5 w-0.5 fill-current",
              "aria-hidden": "true"
            }, [
              _createElementVNode("circle", {
                cx: "1",
                cy: "1",
                r: "1"
              })
            ], -1)),
            _createTextVNode(_toDisplayString(_ctx.description), 1)
          ]),
          _createElementVNode("a", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.redirect && _ctx.redirect(...args))),
            class: "cursor-pointer flex-none rounded-full bg-gray-900 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
          }, [
            _createTextVNode(_toDisplayString(_ctx.actionTitle) + " ", 1),
            _cache[2] || (_cache[2] = _createElementVNode("span", { "aria-hidden": "true" }, "→", -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("button", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('dismiss')), 1),
            _createVNode(_component_XMarkIcon, {
              class: "h-5 w-5 text-gray-900",
              onClick: _ctx.closeModal,
              "aria-hidden": "true"
            }, null, 8, ["onClick"])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}