import {Product} from "@/greeve/shop/product/product.type";
import {GreeveProductTypeInterface} from "@/greeve/shop/product/product.interface";
import {ProductList} from "@/greeve/shop/product/product_list.type";
import {Price} from "@/greeve/shop/product/price.type";
import {GreeveProductPriceBillingScheme, GreeveProductPriceType} from "@/greeve/shop/product/price.interface";
import {PriceList} from "@/greeve/shop/product/price_list.type";
import {CurrencyCode} from "@/greeve/core/country/currency.type";

export class ProductFactory {
	/**
	 *
	 * @param products
	 */
	createProductListByGroupedResponse(products: Record<string, any>): ProductList {
		const productList = new ProductList();

		if (products) {
			const oneTimeProducts: Record<string, any> = products[GreeveProductPriceType.ONE_TIME_PRICE];
			const recurringProducts: Record<string, any> = products[GreeveProductPriceType.RECURRING_PRICE];

			//item has price and product as key
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			for (const [group, items] of Object.entries(oneTimeProducts)) {
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				for (const [ key, item] of Object.entries(items)) {
					const productAndPriceItem: Record<string, any>|any = item;
					productList.push(this.createProductAndPriceByResponse(productAndPriceItem));
				}
			}
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			for (const [subType, items] of Object.entries(recurringProducts)) {
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				for (const [ key, item] of Object.entries(items)) {
					const productAndPriceItem: Record<string, any>|any = item;
					productList.push(this.createProductAndPriceByResponse(productAndPriceItem));
				}
			}
		}

		return productList;
	}

	/**
	 *
	 * @param products
	 */
	createProductListByResponse(products: Record<string, any>): ProductList {
		const productList = new ProductList();

		if (products) {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			for (const [key, item] of Object.entries(products)) {
				productList.push(this.createProductByResponse(item));
			}
		}

		return productList;
	}

	/**
	 *
	 * @param item
	 */
	createProductAndPriceByResponse(item: Record<string, any>): Product {
		const productData = item.product;
		const priceData = item.price;

		const price = this.createPriceByResponse(priceData);
		delete productData['price'];
		delete productData['default_price'];

		productData.price = price;

		return this.createProductByResponse(productData);
	}

	/**
	 *
	 * @param item
	 */
	createProductByResponse(item: Record<string, any>): Product {
		const type = item.type;
		const productId = item.product_id ? item.product_id : item.id;
		const priceId = item.price_id;
		let price;
		if (item.price instanceof Price) {
			price = item.price;
		} else if (item.default_price) {
			if (item.default_price instanceof Object) {
				price = this.createPriceByResponse(item.default_price);
			} else {
				price = item.default_price;
			}
		} else if(item.price) {
			price = this.createPriceByResponse(item.price);
		}
		const description = item.description;
		const taxCode = item.tax_code;
		const attributes = item.attributes;
		const images = item.images;
		const unitLabel = item.unit_label;
		const productReference = item.product_reference ?? undefined;
		const productGroup = item.product_group ?? undefined;
		const data = item.data;
		const created_at = item.created_at ? new Date(item.created_at) : (item.createdAt ? new Date(item.createdAt) : new Date());
		const updated_at = item.updated_at ? new Date(item.updated_at) : (item.updatedAt ? new Date(item.updatedAt) : new Date());

		return new Product(type, productId, priceId, price, description, taxCode, attributes, images, unitLabel, productReference, productGroup, data, created_at, updated_at);
	}

	createEmptyProduct(): Product {
		return new Product(GreeveProductTypeInterface.DEFAULT, '', '', this.createEmptyPrice(), '', '');
	}


	/**
	 *
	 * @param prices
	 */
	createPriceListByResponse(prices: Record<string, any>): PriceList {
		const priceList = new PriceList();

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		for (const [key, item] of Object.entries(prices)) {
			priceList.push(this.createPriceByResponse(item));
		}

		return priceList;
	}

	createPriceByResponse(item: Record<string, any>): Price {
		const priceId = item.price_id ? item.price_id : item.id;
		const type = item.type;
		const subType = item.sub_type ?? (item.subType ? item.subType : undefined);
		const active = item.active;
		const billing_scheme = item.billing_scheme;
		const amount = item.amount;
		const tax_amount = item.tax_amount;
		const currency = item.currency;
		const unit_amount = item.unit_amount;
		const unit_amount_decimal = item.unit_amount_decimal;
		const object = item.object ?? undefined;
		const custom_unit_amount = item.custom_unit_amount ?? undefined;
		const recurring = item.recurring ?? undefined;
		const tax_behavior = item.tax_behavior ?? undefined;
		const tiers_mode = item.tiers_mode ?? undefined;
		const transform_quantity = item.transform_quantity ?? undefined;
		const metadata = item.metadata ?? undefined;
		const data = item.data ?? undefined;

		return new Price(priceId, type, active, billing_scheme, amount, tax_amount, currency, unit_amount, unit_amount_decimal, object, custom_unit_amount, recurring, tax_behavior, tiers_mode, transform_quantity, metadata,subType, data);
	}

	createEmptyPrice(): Price {
		//TODO get currency by locale
		return new Price('', GreeveProductPriceType.ONE_TIME_PRICE, true, GreeveProductPriceBillingScheme.PER_UNIT, 0, 0, CurrencyCode.EUR, 0, '0')
	}
}