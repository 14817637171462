const system = {
	namespaced: true,
	state: () => ({
		internetConnection: true,
		appVersion: null as string|null,
		appActiveState: false as boolean,
		backgroundTrackingActive: false as boolean,
		developerMode: false as boolean,
	}),
	mutations: {
		setInternetConnection(state: any, value: boolean) {
			state.internetConnection = value;
		},
		appVersion(state: any, value: string) {
			state.appVersion = value;
		},
		appActiveState(state: any, value: boolean) {
			state.appActiveState = value;
		},
		backgroundTrackingActive(state: any, value: boolean) {
			state.backgroundTrackingActive = value;
		},
		setDeveloperMode(state:any, value: boolean) {
			state.developerMode = value;
		}
	},
	actions: {},
	getters: {
		getInternetConnection: (state: any) => {
			return state.internetConnection;
		},
		appVersion: (state: any) => {
			return state.appVersion;
		},
		appActiveState: (state: any) => {
			return state.appActiveState;
		},
		backgroundTrackingActive: (state: any) => {
			return state.backgroundTrackingActive;
		},
		hasDeveloperMode: (state: any) => {
			return state.developerMode;
		},
	}
}

export default system;