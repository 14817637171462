<template>
	<div class="mx-4 md:mx-auto max-w-7xl ">

		<div class="mx-auto grid max-w-md grid-cols-1 gap-8 lg:max-w-4xl lg:grid-cols-2 lg:gap-8">
			<div v-for="product in products" :key="product.product_id"
					 class="flex w-full flex-col md:mr-4 p-4 mb-4 rounded-2xl outer-card bg-gr-baser dark:bg-gr-dark">
				<div v-for="item in ServiceLinks" :key="item.name">
					<router-link :to="item.href"
											 class="bg-gray-100 dark:bg-gr-darker flex flex-col justify-between rounded-2xl m-2 p-6 hover:bg-gray-100 dark:hover:bg-gr-dark">
						<div class="flex h-full flex-col">
							<div class="flex flex-row">

									<span
											class="inline-flex h-10 w-10 items-center justify-center rounded-xl shadow-md bg-black text-white sm:h-12 sm:w-12">
                            <component :is="item.icon" class="h-6 w-6" aria-hidden="true"/>
                          </span>
							</div>
							<div class="ml-0 flex md:flex-1 flex-col justify-between mt-4">
								<div>
									<p class="text-base font-medium text-gray-900 dark:text-gray-200 font-semibold truncate"
										 :id="product.product_id">
										{{ product.description }} {{ item.name }}</p>
									<p class="mt-1 text-sm text-gray-500 dark:text-gray-400 line-clamp-2">
										{{ product.description }} {{ item.description }}</p>
								</div>
							</div>
							<!--								<h1>TODO TEST DEFINE FEATURE BY META TAGS OR STRIPE CONTENT</h1>-->
							<!--										<ul role="list" class="mt-4 space-y-4">-->
							<!--											<li v-for="line in item.feature" :key="line" class="flex items-start">-->
							<!--												<div class="inline-flex items-center font-medium">-->
							<!--													<CheckCircleIcon class="h-4 w-4" aria-hidden="true" />-->
							<!--													<p class="ml-2 text-base leading-6">flexible pricing</p>-->
							<!--													&lt;!&ndash;<p class="ml-2 text-base leading-6">{{ item.feature }}test</p>&ndash;&gt;-->
							<!--												</div>-->
							<!--											</li>-->
							<!--										</ul>-->
						</div>
					</router-link>
				</div>
				<div class="px-2 item-center flex-auto flex space-x-4">
					<!--									TODO all product price listing ( API CHANGE !!! ) -->
					<!--									<scroll-picker-->
					<!--											:options="options"-->
					<!--											v-model="selections"-->
					<!--											:wheel-speed="wheelSpeed"-->
					<!--									/>-->
					<Listbox class="w-full pb-4" as="div" v-model="selected">
						<div class="relative mt-2">
							<ListboxButton
									class="relative w-full cursor-default rounded-xl py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 sm:text-sm sm:leading-6">
        <span class="inline-flex w-full truncate">
          <span class="truncate">{{ selected.name }}</span>
          <span class="ml-2 truncate text-gray-500">{{ selected.points }}</span>
        </span>
								<span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true"/>
        </span>
							</ListboxButton>
							<transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
													leave-to-class="opacity-0">
								<ListboxOptions
										class="absolute z-10 mt-1.5 max-h-60 w-full overflow-auto rounded-xl bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
									<ListboxOption as="template" v-for="piece in piece" :key="piece.points" :value="piece"
																 v-slot="{ active, selected }">
										<li :class="[active ? 'bg-gray-200' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
											<div class="flex">
												<span :class="[selected ? 'font-semibold' : 'font-normal', 'truncate']">{{ piece.name }}</span>
												<span :class="[active ? 'text-gray-700' : 'text-gray-500', 'ml-2 truncate']">{{
														piece.points
													}}</span>
											</div>
											<span v-if="selected"
														:class="[active ? 'text-gray-900' : 'text-gray-900', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                <CheckIcon class="h-5 w-5" aria-hidden="true"/>
              </span>
										</li>
									</ListboxOption>
								</ListboxOptions>
							</transition>
						</div>
					</Listbox>
					<!--									<div class="w-1/2 px-1 py-1 flex items-baseline text-2xl font-bold tracking-tight text-gr-darker">-->
					<!--										{{ product.price?.unit_amount / 100 }}<span class="pl-1 text-lg font-semibold leading-8 tracking-normal text-gray-500">{{ product.price?.currency.toUpperCase() }}</span>-->
					<!--									</div>-->
				</div>
				<div class="flex space-x-4 mb-3 mx-2 text-sm font-medium">
					<div class="flex-auto flex space-x-4">
						<router-link to="/pricing" class="h-10 w-full px-6 font-semibold rounded-xl bg-gr-primary text-white">
							<button class="h-10 w-full px-6" type="submit">
								{{ $t('useTemplate') }}
							</button>
						</router-link>
						<!--										<router-link to="/shop/plans" class="h-10 w-full px-6 font-semibold rounded-xl bg-gr-primary text-white"><button class="h-10 w-full px-6" type="submit">-->
						<!--											View Plans-->
						<!--										</button></router-link>-->
						<button @click="addProductToCart_by_Product(product)" :aria-describedby="product.product_id"
										class="h-10 w-full px-6 font-semibold rounded-xl bg-black text-white" type="button">
											<span v-if="addToCartLoading" class=" mx-auto flex align-center justify-center content-center">
													<svg class="animate-spin mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none"
															 viewBox="0 0 24 24">
													<circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
																	stroke-width="4"></circle>
													<path class="opacity-75" fill="currentColor"
																d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
													</svg>
												<!--													Product added-->
												</span>
							<span v-else>{{ $t('addToCart') }}</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent, onBeforeMount, onMounted, ref, Transition} from 'vue';
import useShop from '@/composable/greeve/useShop';
import useAuth from '@/composable/auth/useAuth';
import {CheckCircleIcon} from '@heroicons/vue/24/outline';
import {Product} from '@/greeve/shop/product/product.type';
import {MicrophoneIcon, CheckIcon, ChevronDownIcon} from '@heroicons/vue/20/solid';
import {Listbox, ListboxButton, ListboxOption, ListboxOptions} from '@headlessui/vue';

export default defineComponent({
	name: 'GreeveShopProduct',
	components: {
		CheckCircleIcon,
		CheckIcon,
		ChevronDownIcon,
		Listbox,
		ListboxButton,
		ListboxOption,
		ListboxOptions,
		Transition,
	},
	props: {},
	emits: [],
	setup() {
		const {isAuthenticated} = useAuth();
		const isLoading = ref(false);
		const addToCartLoading = ref(false);
		const {initProducts, products, addProductToCart} = useShop();

		function addProductToCart_by_Product(product: Product) {
			addToCartLoading.value = true;
			addProductToCart(product.product_id);
			setTimeout(() => {
				addToCartLoading.value = false;
			}, 200);
			//TODO add product to cart;
		}

		onBeforeMount(() => {
			//
		});

		onMounted(() => {
			//TODO if products and created is older than 1 days reload
			initProducts(true).then(() => {
				isLoading.value = false;
			});
		});

		const ServiceLinks = [
			{
				name: 'Greeve Whisper Sync',
				icon: MicrophoneIcon,
				href: '/services/detail',
				description: 'Voice Interviews generated into Text, consider any dialect.',
				feature: {
					1: 'different languages',
					2: 'Student Help',
					3: 'easy Use',
				},
			},
		];

		const piece = [
			{id: 1, name: '5 min', points: '2 Credits'},
			{id: 2, name: '10 min', points: '4 Credits'},
			{id: 3, name: '40 min', points: '14 Credits'},
			{id: 4, name: '90 min', points: '30 Credits'},
		];

		const selected = ref(piece[1]);

		return {
			// ...toRefs(state),
			piece, selected,
			isAuthenticated,
			isLoading,
			addToCartLoading,
			products,
			addProductToCart_by_Product, ServiceLinks,
		};
	},
});
</script>

<style scoped>


</style>
