import {CustomerFactory} from "@/greeve/shop/customer/customer_factory.type";
import {ProductFactory} from "@/greeve/shop/product/product_factory.type";
import {CheckoutSessionFactory} from "@/greeve/shop/checkout/session/checkout_session_factory.type";
import {OrderFactory} from "@/greeve/shop/order/order_factory.type";
import {OrderItemFactory} from "@/greeve/shop/order/orderItem/order_item_factory.type";
import {JournalFactory} from "@/greeve/shop/order/journal/journal_factory.type";
import {JournalLineFactory} from "@/greeve/shop/order/journal/journalLine/journal_line_factory.type";
import {PaymentItemFactory} from "@/greeve/shop/order/paymentItem/payment_item_factory.type";

export default function useOrderFactory() {

	let orderFactory: OrderFactory | null = null;
	const getOrderFactory = (): OrderFactory => {
		if (!orderFactory) {
			orderFactory = new OrderFactory();
		}
		return orderFactory;
	}

	let orderItemFactory: OrderItemFactory | null = null;

	const getOrderItemFactory = (): OrderItemFactory => {
		if (!orderItemFactory) {
			orderItemFactory = new OrderItemFactory();
		}
		return orderItemFactory;
	}

	let journalFactory: JournalFactory | null = null;
	const getJournalFactory = (): JournalFactory => {
		if (!journalFactory) {
			journalFactory = new JournalFactory();
		}
		return journalFactory;
	}

	let journalLineFactory: JournalLineFactory | null = null;

	const getJournalLineFactory = (): JournalLineFactory => {
		if (!journalLineFactory) {
			journalLineFactory = new JournalLineFactory();
		}
		return journalLineFactory;
	}

	let paymentItemFactory: PaymentItemFactory | null = null;

	const getPaymentItemFactory = (): PaymentItemFactory => {
		if (!paymentItemFactory) {
			paymentItemFactory = new PaymentItemFactory();
		}
		return paymentItemFactory;
	}

	let customerFactory: CustomerFactory | null = null;

	const getCustomerFactory = (): CustomerFactory => {
		if (!customerFactory) {
			customerFactory = new CustomerFactory();
		}
		return customerFactory;
	}

	let productFactory: ProductFactory | null = null;

	const getProductFactory = (): ProductFactory => {
		if (!productFactory) {
			productFactory = new ProductFactory();
		}
		return productFactory;
	}

	let checkoutSessionFactory: CheckoutSessionFactory | null = null;

	const getCheckoutSessionFactory = (): CheckoutSessionFactory => {
		if (!checkoutSessionFactory) {
			checkoutSessionFactory = new CheckoutSessionFactory();
		}
		return checkoutSessionFactory;
	}

	return {
		getOrderFactory,
		getOrderItemFactory,
		getJournalFactory,
		getJournalLineFactory,
		getPaymentItemFactory,
		getCustomerFactory,
		getProductFactory,
		getCheckoutSessionFactory,
	}
}