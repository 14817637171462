import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({'rounded-xl flex': _ctx.centerButton, buttonAlignment: _ctx.buttonAlignment})
  }, [
    _createElementVNode("a", {
      class: _normalizeClass(['rounded-xl cursor-pointer dark:text-gray-400 link-button font-medium ' + _ctx.buttonWidth, _ctx.buttonXPadding, _ctx.buttonYPadding, _ctx.buttonHover, _ctx.buttonColor, _ctx.textColor, _ctx.buttonWidth, _ctx.customCss, _ctx.textAlignment]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.redirectToLink && _ctx.redirectToLink(...args)))
    }, _toDisplayString(_ctx.buttonText), 3)
  ], 2))
}