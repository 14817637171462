import {Currency} from "@/greeve/core/country/currency.type";
import useCurrencyFactory from "@/composable/core/useCurrencyFactory";
import {
	PaymentItemInterface,
	PaymentItemState,
	PaymentItemType
} from "@/greeve/shop/order/paymentItem/payment_item.interface";

export class PaymentItem implements PaymentItemInterface {
	id: number;
	order_id: number;
	parent_id?: number | null;
	type: PaymentItemType;
	state: PaymentItemState;
	currency_code: string;
	tax_code: string;
	reference?: string | null;
	external_reference?: string | null;
	net_amount: number;
	total_amount: number;
	tax_amount: number;
	item_quantity: number;
	grand_total: number;
	subject?: string | null;
	request?: any;
	response?: any;
	data?: any;
	created_at?: Date;
	updated_at?: Date;


	constructor(id: number, order_id: number, type: PaymentItemType, state: PaymentItemState, currency_code: string, tax_code: string, net_amount: number, grand_total: number, total_amount: number, tax_amount: number, item_quantity: number, parent_id?: number|null, reference?: string | null, external_reference?: string | null, subject?: string | null, request?: any, response?: any, data: any = null, created_at?: Date, updated_at?: Date) {
		this.id = id;
		this.parent_id = parent_id;
		this.order_id = order_id;
		this.type = type;
		this.state = state;
		this.currency_code = currency_code;
		this.tax_amount = tax_amount;
		this.tax_code = tax_code;
		this.net_amount = net_amount;
		this.item_quantity = item_quantity;
		this.grand_total = grand_total;
		this.total_amount = total_amount;
		this.reference = reference;
		this.external_reference = external_reference;
		this.subject = subject;
		this.request = request;
		this.response = response;
		this.data = data;
		this.created_at = created_at;
		this.updated_at = updated_at;
	}

	getCurrencyObject(): Currency {
		return useCurrencyFactory().getCurrencyFactory().createCurrencyByCode(this.currency_code);
	}
}