<template>
	<section aria-labelledby="contact-info-heading">
		<h2 id="contact-info-heading" class="text-lg font-semibold text-gray-900 dark:text-white">{{
				$t('contactInformation')
			}}</h2>

		<loading-line-animation position-css="top-0 absolute w-full" v-show="isLoading"></loading-line-animation>

		<div class="mt-6">
			<stripe-address @onChangeAddress="updateContactAddress" @onInitialized="stopLoading"></stripe-address>
		</div>
	</section>

	<div class="pt-6">
		<div class="-mt-3 p-3">
			<div class="relative flex items-start">
				<div class="flex h-6 items-center">
					<input id="saveAddress" v-model="saveAddress" aria-describedby="saveAddress-description" name="saveAddress"
								 type="checkbox" class="h-4 w-4 rounded border-gray-300 text-primary focus:ring-gr-primary"/>
				</div>
				<div class="ml-3 text-sm leading-6">
					<label for="saveAddress" class="font-medium text-gray-900 dark:text-gray-200">{{ $t('saveAddress') }}</label>
					{{ ' ' }}
					<span id="saveAddress-description" class="text-gray-500"><span class="sr-only">{{ $t('saveAddress') }}</span>{{
							$t('forFutureUse')
						}}</span>
				</div>
			</div>
		</div>
		<div class="py-2" v-if="isAddressValid">
			<default-button button-color="bg-gr-primary" :button-text="$t('shop.checkout.order_information.step')"
											button-type="button" @click-button="onSuccess()"></default-button>
		</div>
		<div class="py-2" v-else>
			<default-button button-color="bg-gr-primary" :button-disabled="true"
											:button-text="$t('shop.checkout.contact_information.fill_data')" button-type="button"></default-button>
		</div>
		<p class="mt-2 text-center text-sm text-gray-500">{{ $t('youWontBeChargedUntilTheLastStep') }}</p>
	</div>
</template>

<script lang="ts">
import {ref, onMounted, defineComponent} from 'vue';
import StripeAddress from '@/components/shop/checkout/process/stripe/StripeAddress.vue';
import useShop from '@/composable/greeve/useShop';
import useAlert from '@/composable/core/useAlert';
import DefaultButton from '@/components/core/DefaultButton.vue';
import LoadingLineAnimation from '@/components/animations/LoadingLineAnimation.vue';

export default defineComponent({
	name: 'ContactInformationProcess',
	components: {LoadingLineAnimation, DefaultButton, StripeAddress},
	props: {},
	emits: ['onSuccess', 'onError'],
	setup(props, {emit}) {
		const {updateCustomerAddressByStripeAddress, cart} = useShop();
		const {addError, clearErrorAlert} = useAlert();
		const address = ref();
		const saveAddress = ref(false);
		const isAddressValid = ref(false);
		const isLoading = ref(true);

		function updateContactAddress(addressData: any) {
			if (addressData) {
				address.value = addressData;
			}
			validateAddress();
		}

		function stopLoading() {
			isLoading.value = false;
		}

		function validateAddress(): void {
			if (address.value && address.value.line1.length > 0) {
				isAddressValid.value = true;
			} else {
				isAddressValid.value = false;
			}
		}

		function onSuccess() {
			clearErrorAlert();
			if (isAddressValid.value) {
				try {
					updateCustomerAddressByStripeAddress(address.value, saveAddress.value);
					goToNextStep();
				} catch (error: any) {
					onError(error);
				}
			} else {
				const error = {
					id: '',
					title: 'Error',
					message: 'Invalid Contact Informations',
					active: true,
				};
				addError(error);
			}
		}

		function goToNextStep() {
			emit('onSuccess');
		}

		function onError(message = '', inlineError = false) {
			emit('onError', message, null, false, inlineError);
		}

		onMounted(() => {
			isLoading.value = true;
			if (cart.value && cart.value.customer?.address) {
				saveAddress.value = cart.value.customer.address.getSaveAddressOption();
			}
		});

		return {
			address, updateContactAddress, onSuccess, onError, isAddressValid, saveAddress, goToNextStep, stopLoading, isLoading
		};
	},
});
</script>

<style scoped>

</style>
