import {computed, ComputedRef, onBeforeMount, ref} from "vue";
import store from "@/store";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {AxiosError} from "axios";
import {BlogList} from '@/greeve/blog/blog_list.type';
import {GreeveApiBlog} from '@/greeve/api/blog';

export default function useBlog() {
	const blogs: ComputedRef<BlogList|undefined> = computed(() => store.getters['blog/getBlogs']);
	const isLoading = ref(false);

	async function loadBlogs() {
		// eslint-disable-next-line no-useless-catch
		try {
			return await GreeveApiBlog.getBlogs();
		} catch (error: any | AxiosError) {
			throw error;
		}
	}

	async function getBlogBySlug(slug: string, force = false) {
		// eslint-disable-next-line no-useless-catch
		try {
			if (!force && blogs.value) {
				const blogBySlug = blogs.value?.getBlogBySlug(slug);
				if (blogBySlug) {
					return blogBySlug;
				}
			}
			return await GreeveApiBlog.getBlogBySlug(slug);
		} catch (error: any | AxiosError) {
			throw error;
		}
	}

	async function initBlogs(forceRefresh = false) {
		if (!blogs.value || blogs.value.length < 1 || (forceRefresh && !isLoading.value)) {
			isLoading.value = true;
			return loadBlogs().then((blogList) => {
				store.commit('blog/setBlogs', blogList);
				return blogList;
			})
		}
	}

	onBeforeMount(() => {
		initBlogs(false).then(() => {
			//	console.log("challenges loaded");
		});
	});

	return {
		blogs,
		loadBlogs,
		getBlogBySlug,
		initBlogs,
	}
}
