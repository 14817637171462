import useCartFactory from "@/composable/greeve/useCartFactory";
import {Order} from "@/greeve/shop/order/order.type";
import {OrderItemList} from "@/greeve/shop/order/orderItem/order_item_list.type";
import {OrderList} from "@/greeve/shop/order/order_list.type";
import useOrderFactory from "@/composable/greeve/useOrderFactory";

export class OrderFactory {
	/**
	 *
	 * @param orderResponse
	 */
	createOrderListByResponse(orderResponse: Record<string, any>): OrderList {
		const orderList = new OrderList();

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		for (const [key, item] of Object.entries(orderResponse)) {
			orderList.push(this.createOrderByResponse(item));
		}

		return orderList;
	}

	/**
	 *
	 * @param item
	 */
	createOrderByResponse(item: Record<string, any>): Order {
		const id = item.id;
		const uuid = item.uuid;
		const type = item.type;
		const order_number = item.order_number;
		const state = item.state;
		const tax_code = item.tax_code;
		const currency_code = item.currency_code;
		const country_code = item.country_code;
		const transaction = item.transaction;
		const reference = item.reference;
		const external_reference = item.external_reference;
		const net_amount = item.net_amount;
		const total_amount = item.total_amount;
		const tax_amount = item.tax_amount;
		const item_quantity = item.item_quantity;
		const grand_total = item.grand_total;
		const exchange_rate = item.exchange_rate;
		const userAddress = item.userAddress;

		let customer;
		if (item.customer && item.customer.length > 0) {
			customer = useCartFactory().getCustomerFactory().createCustomerByResponse(item.customer);
		}
		let paymentItemList;
		if (item.payment_items && item.payment_items.length > 0) {
			paymentItemList = useOrderFactory().getPaymentItemFactory().createPaymentItemListByResponse(item.payment_items);
		} else if (item.paymentItems && item.paymentItems.length > 0) {
			paymentItemList = useOrderFactory().getPaymentItemFactory().createPaymentItemListByResponse(item.paymentItems);
		}
		let orderItems: OrderItemList|null = null;
		if (item.order_items && item.order_items.length > 0) {
			orderItems = useOrderFactory().getOrderItemFactory().createOrderItemListListByResponse(item.order_items);
		} else if (item.orderItems && item.orderItems.length > 0) {
			orderItems = useOrderFactory().getOrderItemFactory().createOrderItemListListByResponse(item.orderItems);
		}
		let journals;
		if (item.journals && item.journals.length > 0) {
			journals = useOrderFactory().getJournalFactory().createJournalListByResponse(item.journals);
		}
		const data = item.data;
		const created_at = item.created_at ? new Date(item.created_at) : (item.createdAt ? new Date(item.createdAt) : new Date());
		const updated_at = item.updated_at ? new Date(item.updated_at) : (item.updatedAt ? new Date(item.updatedAt) : new Date());

		return new Order(id, uuid, type, state, tax_code, country_code, currency_code, order_number, net_amount, total_amount, tax_amount, item_quantity, grand_total, exchange_rate, transaction, reference, external_reference, orderItems, paymentItemList, customer, journals, userAddress, data, created_at, updated_at)
	}
}