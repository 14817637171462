import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["aria-label"]
const _hoisted_2 = ["aria-current", "onClick"]
const _hoisted_3 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("nav", {
      "aria-label": _ctx.$t('tabs'),
      class: _normalizeClass(_ctx.wrapper)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentTabs, (tab) => {
        return (_openBlock(), _createElementBlock("a", {
          key: tab.name,
          "aria-current": tab.current ? 'page' : undefined,
          class: _normalizeClass(tab.current ? _ctx.styleInactive : _ctx.styleActive),
          style: _normalizeStyle(_ctx.customStyle),
          onClick: ($event: any) => (_ctx.triggerTab(tab))
        }, [
          _createElementVNode("span", _hoisted_3, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(tab.icon), {
              class: _normalizeClass([tab.current ? 'text-gr-primary' : 'text-gray-400 group-hover:text-gray-500', '-ml-0.5 mr-2 h-5 w-5']),
              "aria-hidden": "true"
            }, null, 8, ["class"])),
            _createTextVNode(" " + _toDisplayString(tab.name), 1)
          ])
        ], 14, _hoisted_2))
      }), 128))
    ], 10, _hoisted_1)
  ]))
}