import {PaginationMetaInterface} from "@/greeve/core/pagination_meta.interface";
import {
	AbstractFlowQueueProcess
} from '@/greeve/flow/queue/process/abstract_flow_queue_process.type';

export class FlowQueueProcessList extends Array<AbstractFlowQueueProcess> {

	public paginationMetaData?: PaginationMetaInterface;

	constructor(items: Array<AbstractFlowQueueProcess> = []) {
		super();
		this.push(...items);
	}

	add(items: AbstractFlowQueueProcess) {
		this.push(items);
	}

	remove(index: number) {
		this.splice(index, 1);
	}

	removeAll() {
		const itemCount = this.length;
		this.splice(itemCount, 1);
	}

	getItem(index: number): AbstractFlowQueueProcess {
		return this[index];
	}

	getItemById(item_id: number): AbstractFlowQueueProcess | undefined {
		return this.find(({id}) => id === item_id);
	}

	getItemByFlowProcessId(flowProcessId: number): AbstractFlowQueueProcess | undefined {
		return this.find(({flow_process_id}) => flow_process_id === flowProcessId);
	}

	getItemByUuId(item_uuid: string): AbstractFlowQueueProcess | undefined {
		return this.find(({uuid}) => uuid === item_uuid);
	}

	enhanceMetaData(metaData: any) {
		this.paginationMetaData = metaData;
	}
}