import {PaginationMetaInterface} from "@/greeve/core/pagination_meta.interface";
import {Product} from "@/greeve/shop/product/product.type";
import {GreeveProductPriceSubType, GreeveProductPriceType} from "@/greeve/shop/product/price.interface";

export class ProductList extends Array<Product> {

	public paginationMetaData?: PaginationMetaInterface;

	constructor(items: Array<Product> = []) {
		super();
		this.push(...items);
	}

	add(items: Product) {
		this.push(items);
	}

	remove(index: number) {
		this.splice(index, 1);
	}

	removeAll() {
		const itemCount = this.length;
		this.splice(itemCount, 1);
	}

	getProduct(index: number, clone = true): Product {
		if (clone) {
			const productList: ProductList|any[] = [...this];
			return productList[index];
		} else {
			return this[index];
		}
	}

	getProduct_by_Id(prodId: string, clone = true): Product | undefined {
		if (clone) {
			const productList: ProductList|any[] = [...this];
			return productList.find(({product_id}) => product_id === prodId);
		} else {
			return this.find(({product_id}) => product_id === prodId);
		}
	}

	getProduct_by_ProductId_And_PriceId(prodId: string, priceId: string, clone = true): Product | undefined {
		if (clone) {
			const productList: ProductList|Product[] = [...this];
			return productList.find((product) => {
				let found = true;
				if (prodId) {
					found = product.product_id === prodId;
				}
				if (!found) {
					return found;
				}
				if (priceId) {
					found = product.price.price_id === priceId;
				}
				if (!found) {
					return found;
				}
				return product;
			});
		} else {
			return this.find((product) => {
				let found = true;
				if (prodId) {
					found = product.product_id === prodId;
				}
				if (!found) {
					return found;
				}
				if (priceId) {
					found = product.price.price_id === priceId;
				}
				if (!found) {
					return found;
				}
				return product;
			});
		}
	}

	getProduct_by_PriceId(priceId: string, clone = true): Product | undefined {
		if (clone) {
			const productList: ProductList|Product[] = [...this];
			return productList.find((product) => {
				let found = true;
				if (priceId) {
					found = product.price.price_id === priceId;
				}
				if (!found) {
					return found;
				}
				return product;
			});
		} else {
			return this.find((product) => {
				let found = true;
				if (priceId) {
					found = product.price.price_id === priceId;
				}
				if (!found) {
					return found;
				}
				return product;
			});
		}
	}

	getProduct_by_Id_And_PriceType(prodId: string, priceType?: GreeveProductPriceType, subType?: GreeveProductPriceSubType, clone = true): Product | undefined {
		if (clone) {
			const productList: ProductList|Product[] = [...this];
			return productList.find((product) => {
				let found = true;
				if (prodId) {
					found = product.product_id === prodId;
				}
				if (!found) {
					return found;
				}
				if (priceType) {
					found = product.price.type === priceType;
				}
				if (!found) {
					return found;
				}
				if (subType) {
					found = product.price.sub_type === subType;
				}
				if (!found) {
					return found;
				}
				return product;
			});
		} else {
			return this.find((product) => {
				let found = true;
				if (prodId) {
					found = product.product_id === prodId;
				}
				if (!found) {
					return found;
				}
				if (priceType) {
					found = product.price.type === priceType;
				}
				if (!found) {
					return found;
				}
				if (subType) {
					found = product.price.sub_type === subType;
				}
				if (!found) {
					return found;
				}
				return product;
			});
		}
	}

	getProduct_by_Reference(productReference: string, clone = true): Product | undefined {
		if (clone) {
			const productList: ProductList|any[] = [...this];
			return productList.find(({product_reference}) => product_reference === productReference);
		} else {
			return this.find(({product_reference}) => product_reference === productReference);
		}
	}

	enhanceMetaData(metaData: any) {
		this.paginationMetaData = metaData;
	}

	getProductListByReference(productReference: string): ProductList|Product[]
	{
		const productList: ProductList|any[] = [...this];
		return productList.filter((product) => {
			return product.product_reference === productReference;
		});
	}

	getProductListByGroup(productGroup: string): ProductList|Product[]
	{
		const productList: ProductList|any[] = [...this];
		return productList.filter((product) => {
			return product.product_group === productGroup;
		});
	}

	getOneTimeProductList(): ProductList|Product[]
	{
		const productList: ProductList|any[] = [...this];
		return productList.filter((product) => {
			return product.price.type === GreeveProductPriceType.ONE_TIME_PRICE;
		});
	}
	getRecurringProductList(): ProductList|Product[]
	{
		const productList: ProductList|any[] = [...this];
		return productList.filter((product) => {
			return product.price.type === GreeveProductPriceType.RECURRING_PRICE;
		});
	}
	getProductListByPriceType(priceType: GreeveProductPriceType, subType?: GreeveProductPriceSubType, productReference?: string): ProductList|Product[]
	{
		const productList: ProductList|any[] = [...this];
		return productList.filter((product) => {
			let found = true;
			if (productReference) {
				found = product.product_reference === productReference;
			}
			if (!found) {
				return found;
			}
			if (priceType) {
				found = product.price.type === priceType;
			}
			if (!found) {
				return found;
			}
			if (subType) {
				found = product.price.sub_type === subType;
			}
			if (!found) {
				return found;
			}
			return product;
		});
	}
	getProductListByPriceTypeReferenceList(priceType: GreeveProductPriceType, subType?: GreeveProductPriceSubType, productReferenceList?: Array<string>): ProductList|Product[]
	{
		const productList: ProductList|any[] = [...this];
		return productList.filter((product) => {
			let found = true;
			if (productReferenceList) {
				found = productReferenceList.includes(product.product_reference);
			}
			if (!found) {
				return found;
			}
			if (priceType) {
				found = product.price.type === priceType;
			}
			if (!found) {
				return found;
			}
			if (subType) {
				found = product.price.sub_type === subType;
			}
			if (!found) {
				return found;
			}
			return product;
		});
	}
}