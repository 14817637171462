<template>
	<router-link v-if="isAuthenticated && (!cart.items.length || cart.items.length < 0)" to="/profile/credits" class="ml-4 flow-root text-sm lg:relative lg:ml-8">
		<div class="group truncate flex items-center p-2 px-4 py-2 bg-gray-100 hover:bg-gray-200 dark:hover:bg-gr-dark dark:bg-gr-darker rounded-xl outline-none">
			<span class="text-sm font-bold text-gray-500 dark:text-gray-400  group-hover:text-gray-800 dark:group-hover:text-gray-400">
			{{ $t('credits3', {getAvailableCreditAmount: getQuotas?.getAvailableCreditAmount()}) }}</span>
			<span class="sr-only">{{ $t('itemsInCartViewBag') }}</span>
		</div>
	</router-link>
	<router-link v-else-if="isAuthenticated && cart.items.length > 0" to="/shop" class="ml-4 flow-root text-sm lg:relative lg:ml-8">
		<div class="group truncate flex items-center p-2 px-4 py-2 bg-gray-100 hover:bg-gray-200 dark:hover:bg-gr-dark dark:bg-gr-darker rounded-xl outline-none">
			<span class="text-sm font-bold text-gray-500 dark:text-gray-400  group-hover:text-gray-800 dark:group-hover:text-gray-400">
			{{ $t('credits3', {getAvailableCreditAmount: getQuotas?.getAvailableCreditAmount()}) }}</span>
			<span class="sr-only">{{ $t('itemsInCartViewBag') }}</span>
		</div>
	</router-link>
	<router-link v-else to="/pricing" class="ml-4 flow-root text-sm lg:relative lg:ml-8">
		<div class="group truncate flex items-center p-2 px-4 py-2 bg-gray-100 hover:bg-gray-200 dark:hover:bg-gr-dark dark:bg-gr-darker rounded-xl outline-none">
			<span class="text-sm font-bold text-gray-500 dark:text-gray-400  group-hover:text-gray-800 dark:group-hover:text-gray-400">
			{{ $t('credits3', {getAvailableCreditAmount: getQuotas?.getAvailableCreditAmount()}) }}</span>
			<span class="sr-only">{{ $t('itemsInCartViewBag') }}</span>
		</div>
	</router-link>
<!--	<Popover class="ml-4 flow-root text-sm lg:relative lg:ml-8" v-slot="{ open }">-->
<!--		<PopoverButton-->
<!--				class="group truncate flex items-center p-2 px-4 py-1.5 bg-gray-100 hover:bg-gray-200 dark:hover:bg-gr-dark dark:bg-gr-darker rounded-xl outline-none">-->
<!--			<span-->
<!--					class="text-sm font-bold text-gray-500 dark:text-gray-400  group-hover:text-gray-800 dark:group-hover:text-gray-400">-->
<!--			{{-->
<!--					$t('credits3', {getAvailableCreditAmount: getQuotas.getAvailableCreditAmount()})-->
<!--				}}</span>-->
<!--			<span class="sr-only">{{ $t('itemsInCartViewBag') }}</span>-->
<!--		</PopoverButton>-->
<!--		<div v-if="open">-->
<!--			<transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0"-->
<!--									enter-to-class="opacity-100" leave-active-class="transition ease-in duration-150"-->
<!--									leave-from-class="opacity-100" leave-to-class="opacity-0">-->
<!--				<PopoverPanel v-slot="{ close }" static-->
<!--											class="absolute inset-x-0 top-16 mt-px bg-white dark:bg-gr-dark shadow-lg p-6 lg:top-full lg:left-auto lg:right-0 lg:mt-3 lg:w-80 rounded-xl">-->
<!--					<div class="mb-2">-->
<!--						<h3 class="mx-1 text-sm mb-0 font-semibold leading-6 dark:text-gray-500 ">-->
<!--							{{ $t('available') }}</h3>-->
<!--						<div class="rounded-xl bg-gray-100 dark:bg-gr-darker py-6 my-1">-->
<!--							<div class="text-3xl text-gr-primary font-comfortaa font-bold rounded-xl text-center"-->
<!--									 v-if="getQuotas">{{-->
<!--									$t('credits3', {getAvailableCreditAmount: getQuotas.getAvailableCreditAmount()})-->
<!--								}}-->
<!--							</div>-->
<!--						</div>-->
<!--						<div class="mx-1 mt-4 flex items-center"><h3-->
<!--								class="basis-1/2 text-sm mb-2 font-semibold leading-6 dark:text-gray-500 ">-->
<!--							{{ $t('activePlan') }}</h3>-->
<!--							<div class="basis-1/2 mb-2">-->
<!--								<router-link to="/profile?item=plan_billing">-->
<!--									<cog-icon class="h-4 w-4 float-right  dark:text-gray-500"></cog-icon>-->
<!--								</router-link>-->
<!--							</div>-->
<!--						</div>-->

<!--						<div v-if="getSubscriptions?.getActiveSubscription()">-->
<!--							<div-->
<!--									class="font-medium grid grid-cols-3 text-gray-500 dark:text-gray-400 bg-gray-200  dark:bg-gr-darker shadow-sm focus-visible:outline-black rounded-xl py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">-->
<!--								<p>{{ getSubscriptions?.getActiveSubscription().getProduct().getProductName() }}</p>-->
<!--								<p>-->
<!--								<span>{{-->
<!--										getSubscriptions?.getActiveSubscription().getPrice()?.getFormattedAmount(true)-->
<!--									}}/<span-->
<!--											v-if="getSubscriptions?.getActiveSubscription()?.getPrice()?.sub_type === getRecurringMonthlySubType">{{-->
<!--											$t('mo')-->
<!--										}}</span><span-->
<!--											v-else-if="getSubscriptions?.getActiveSubscription()?.getPrice()?.sub_type === getRecurringYearlySubType">{{-->
<!--											$t('yr')-->
<!--										}}</span></span>-->
<!--								</p>-->
<!--								<p>{{-->
<!--										$t('credits2', {-->
<!--											getProductCreditAmount: getSubscriptions?.getActiveSubscription()?.-->
<!--											getProduct().-->
<!--											getProductCreditAmount(),-->
<!--										})-->
<!--									}}</p>-->
<!--							</div>-->
<!--						</div>-->
<!--						<div v-else>-->
<!--							<div-->
<!--									class="font-medium grid grid-cols-1 text-gray-500 dark:text-gray-400 bg-gray-200  dark:bg-gr-darker shadow-sm focus-visible:outline-black rounded-xl py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">-->
<!--								<p>{{ $t('noActiveSubscription2') }}</p>-->
<!--							</div>-->
<!--						</div>-->

<!--					</div>-->
<!--					<h3 class="mx-1 text-sm mt-4 mb-0 font-semibold leading-6 dark:text-gray-500 ">-->
<!--						{{ $t('buyAdditional') }}</h3>-->
<!--					<div class="flex flex-col gap-4 sm:flex-row sm:items-end sm:justify-between lg:flex-col lg:items-stretch">-->
<!--						<ListSelectDropdown :list="getOneTimeProducts()" :model="selected"-->
<!--																:default-value="getDefaultSelectedOneTimeProduct()"-->
<!--																value-key="product_reference"-->
<!--																additionalItemDescription="getProductCreditAmount()" name-key="getProductName()"-->
<!--																@change-selected-value="updateSelectedItem"></ListSelectDropdown>-->
<!--						<button @click="addSelectedOneTimeToCart(close)" aria-describedby="tier-scale"-->
<!--										class="bg-gr-primary hover:bg-gr-primary2 w-1/2 shadow-sm focus-visible:outline-black rounded-xl py-1.5 px-3 text-center text-sm font-semibold leading-6 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">-->
<!--							{{ $t('buyNow') }}-->
<!--						</button>-->
<!--					</div>-->
<!--					<div>-->
<!--					</div>-->
<!--				</PopoverPanel>-->
<!--			</transition>-->
<!--		</div>-->
<!--	</Popover>-->
</template>

<script lang="ts">
import {ref, onMounted, watch, defineComponent} from 'vue';
import useShop from '@/composable/greeve/useShop';
import * as icons from 'ionicons/icons';
import {CartInterface} from '@/greeve/shop/cart/cart.interface';
import useAuth from '@/composable/auth/useAuth';
import {ProductList} from '@/greeve/shop/product/product_list.type';
import {Product} from '@/greeve/shop/product/product.type';
import {GreeveProductReference} from '@/greeve/shop/product/product.interface';
import {useRouter} from 'vue-router';
import useUser from '@/composable/greeve/useUser';

export default defineComponent({
	name: 'CreditsNavCart',
	components: {},
	props: {
		checkoutLink: {
			type: String,
			default: '',
		},
		viewShoppingBagLink: {
			type: String,
			default: '',
		},
	},
	emits: [],
	setup() {
		const {isAuthenticated} = useAuth();
		const router = useRouter();
		const {
			products,
			cart,
			removeCartItem_by_Id,
			addProductToCart,
			getRecurringYearlySubType,
			getRecurringMonthlySubType,
		} = useShop();
		const {initQuotas, getQuotas, getSubscriptions, initSubscriptions} = useUser();
		const {logout} = useAuth();
		const oneTimeProductList: ProductList | any = ref();
		const showCartItemCountChangedAnimation = ref(false);

		watch(() => cart.value, (newValue: CartInterface, oldValue: CartInterface | undefined) => {
			if (!oldValue || newValue.items?.length !== oldValue.items?.length) {
				showCartItemCountChangedAnimation.value = true;
				setTimeout(() => {
					showCartItemCountChangedAnimation.value = false;
				}, 1500);
			}
		});

		onMounted(() => {
			//
		});

		const tiers = [
			{
				name: 'Growth',
				id: 'tier-growth',
				href: '/shop',
				featured: false,
				description: 'Convenient features to take your business to the next level.',
				price: {monthly: '€49', annually: '€470'},
				mainFeatures: [
					'200 Credits',
					'Access to prebuild Flows',
					'extendable Credits',
					'Mutli-accounts',
					'API Access',
					'20.000 API calls'],
			},
		];

		//TODO API REQUEST - User Profile/Account
		//TODO getActiveSubscription or none
		//TODO getAvaillableCredits

		const selected: Product | any = ref();

		function getDefaultSelectedOneTimeProduct(): string {
			if (selected.value) {
				return selected.value.product_reference;
			}
			return GreeveProductReference.STRIPE_PRODUCT_ONE_TIME_CREDIT_10;
		}

		function addSelectedOneTimeToCart(close: any) {
			if (selected.value) {
				addProductToCart(selected.value.product_id);
			}
			router.push('shop').then(() => {
				close();
			});
		}

		function getOneTimeProducts(force = false) {
			if (!oneTimeProductList.value || force) {
				oneTimeProductList.value = products.value.getOneTimeProductList();
				selected.value = oneTimeProductList.value[1];
			}
			return oneTimeProductList.value;
		}

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		function updateSelectedItem(selectedProduct: Product, key: any, value: any) {
			selected.value = products.value.getProduct_by_Reference(key);
		}

		async function initProductsForView() {
			getOneTimeProducts();
		}

		onMounted(() => {
			try {
				initProductsForView();
				initQuotas(true); //TODO remove true if interval exists
				initSubscriptions();
			} catch (e) {
				console.error(e);
			}
		});

		return {
			isAuthenticated,
			tiers,
			selected,
			cart,
			removeCartItem_by_Id, addProductToCart,
			icons,
			showCartItemCountChangedAnimation,
			logout,
			getOneTimeProducts,
			addSelectedOneTimeToCart,
			updateSelectedItem,
			getDefaultSelectedOneTimeProduct,
			getQuotas,
			getSubscriptions,
			getRecurringYearlySubType, getRecurringMonthlySubType,
		};
	},
});
</script>

<style scoped>

</style>
