import {GreeveApiContact} from '@/greeve/api/contact';

export default function useContact() {
	async function sendContact(payload: any) {
		if (payload) {
			return await GreeveApiContact.sendContact(payload);
		}
		return false;
	}

	async function sentNotifyFeature(payload: any) {
		if (payload) {
			return await GreeveApiContact.sendNotifyFeature(payload);
		}
		return false;
	}

	return {
		sendContact,
		sentNotifyFeature,
	}
}
