import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pointer-events-none fixed inset-x-0 bottom-0 sm:flex sm:justify-center sm:px-6 sm:pb-12 lg:px-8" }
const _hoisted_2 = { class: "text-sm leading-6 text-white" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "sr-only" }

import {ref} from 'vue';
import { XMarkIcon } from '@heroicons/vue/20/solid';

export default /*@__PURE__*/_defineComponent({
  __name: 'FloatingBottomBanner',
  props: {
	title: {
		type: String,
		required: true
	},
	description: {
		type: String,
		required: false
	},
	color: {
		type: String,
		default: 'bg-gray-900',
		required: false
	},
},
  setup(__props) {

const isVisible = ref(true);



const hideBanner = () => {
	isVisible.value = false;
}

return (_ctx: any,_cache: any) => {
  return (isVisible.value)
    ? (_openBlock(), _createBlock(_Transition, {
        key: 0,
        name: "fade",
        mode: "out-in"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", {
              class: _normalizeClass(['banner-shadow pointer-events-auto flex items-center justify-between gap-x-6 px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5', __props.color])
            }, [
              _createElementVNode("p", _hoisted_2, [
                (__props.title.length > 0)
                  ? (_openBlock(), _createElementBlock("strong", {
                      key: 0,
                      class: "font-semibold",
                      innerHTML: __props.title
                    }, null, 8, _hoisted_3))
                  : _createCommentVNode("", true),
                (__props.description && __props.description.length > 0)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 1,
                      innerHTML: __props.description
                    }, null, 8, _hoisted_4))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("button", {
                type: "button",
                class: "-m-1.5 flex-none p-1.5",
                onClick: hideBanner
              }, [
                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('close')), 1),
                _createVNode(_unref(XMarkIcon), {
                  class: "h-5 w-5 text-white",
                  "aria-hidden": "true"
                })
              ])
            ], 2)
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})