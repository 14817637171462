import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "space-y-2 sm:space-y-4" }
const _hoisted_2 = { class: "sm:overflow-hidden rounded-xl nav backdrop-filter backdrop-blur-3xl" }
const _hoisted_3 = { class: "space-y-6 py-6 px-4 sm:p-6 rounded-t-xl nav" }
const _hoisted_4 = { class: "text-lg font-medium leading-6 text-gray-900 dark:text-gray-500" }
const _hoisted_5 = { class: "mt-1 text-sm text-gray-600" }
const _hoisted_6 = { class: "grid grid-cols-6 gap-6" }
const _hoisted_7 = { class: "col-span-6 sm:col-span-3" }
const _hoisted_8 = {
  for: "first-name",
  class: "block text-sm font-medium text-gray-700 dark:text-gray-600"
}
const _hoisted_9 = { class: "col-span-6 sm:col-span-3" }
const _hoisted_10 = {
  for: "last-name",
  class: "block text-sm font-medium text-gray-700 dark:text-gray-600"
}
const _hoisted_11 = { class: "grid grid-cols-6 gap-6" }
const _hoisted_12 = { class: "col-span-6 sm:col-span-3" }
const _hoisted_13 = {
  for: "email",
  class: "block text-sm font-medium text-gray-700 dark:text-gray-600"
}
const _hoisted_14 = { class: "px-4 py-3 text-right rounded-b-xl nav sm:px-6" }
const _hoisted_15 = {
  type: "submit",
  class: "inline-flex justify-center rounded-xl bg-black dark:bg-gr-darker py-1.5 px-10 text-sm font-semibold text-white shadow-sm focus:outline-none"
}
const _hoisted_16 = {
  class: "sm:overflow-hidden rounded-xl nav backdrop-filter backdrop-blur-3xl",
  id: "language"
}
const _hoisted_17 = { class: "space-y-6 py-6 px-4 sm:p-6 rounded-t-xl nav" }
const _hoisted_18 = { class: "text-lg font-medium leading-6 text-gray-900 dark:text-gray-500" }
const _hoisted_19 = { class: "mt-1 text-sm text-gray-600" }
const _hoisted_20 = { class: "grid grid-cols-12" }
const _hoisted_21 = { class: "col-span-12" }
const _hoisted_22 = { class: "sm:overflow-hidden rounded-xl nav backdrop-filter backdrop-blur-3xl" }
const _hoisted_23 = { class: "space-y-6 py-6 px-4 sm:p-6" }
const _hoisted_24 = { class: "text-lg font-medium leading-6 text-gray-900 dark:text-gray-500" }
const _hoisted_25 = { class: "mt-1 text-sm text-gray-500" }
const _hoisted_26 = { class: "mt-4 space-y-4" }
const _hoisted_27 = { class: "lg:flex justify-between" }
const _hoisted_28 = { class: "items-start text-sm" }
const _hoisted_29 = {
  for: "downloadData",
  class: "font-medium text-gray-700 dark:text-gray-600"
}
const _hoisted_30 = { class: "text-gray-500" }
const _hoisted_31 = { class: "items-end mt-4 lg:mt-0 w-64 self-center" }
const _hoisted_32 = ["disabled"]
const _hoisted_33 = {
  key: 0,
  class: "text-gr-primary text-center flex"
}
const _hoisted_34 = { key: 1 }
const _hoisted_35 = {
  key: 0,
  class: "lg:flex justify-between"
}
const _hoisted_36 = { class: "items-start text-sm" }
const _hoisted_37 = {
  for: "downloadData",
  class: "font-medium text-gray-700 dark:text-gray-600"
}
const _hoisted_38 = { class: "text-gray-500" }
const _hoisted_39 = {
  key: 0,
  class: "items-end mt-4 lg:mt-0 w-64 self-center"
}
const _hoisted_40 = {
  key: 1,
  class: "lg:flex justify-between"
}
const _hoisted_41 = { class: "items-start text-sm" }
const _hoisted_42 = {
  for: "downloadData",
  class: "font-medium text-gray-700 dark:text-gray-600"
}
const _hoisted_43 = { class: "text-gray-500" }
const _hoisted_44 = {
  id: "oauth_profiles",
  class: "min-h-48"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LanguageSelector = _resolveComponent("LanguageSelector")!
  const _component_ConfirmDialog = _resolveComponent("ConfirmDialog")!
  const _component_OAuthList = _resolveComponent("OAuthList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.updateUserInformation && _ctx.updateUserInformation(...args)), ["prevent"])),
      id: "profile"
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", null, [
            _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.$t('personalInformation')), 1),
            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('useAPermanentAddressWhereYouCanRecieveMail')), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('firstName')), 1),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                name: "first-name",
                id: "first-name",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.userInformation.firstName) = $event)),
                autocomplete: "given-name",
                class: "mt-1 block w-full rounded-xl border-2 border-gray-200 dark:border-gr-darker py-2 px-3 shadow-sm focus:outline-none sm:text-sm"
              }, null, 512), [
                [_vModelText, _ctx.userInformation.firstName]
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t('lastName')), 1),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                name: "last-name",
                id: "last-name",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.userInformation.lastName) = $event)),
                autocomplete: "family-name",
                class: "mt-1 block w-full rounded-xl border-2 border-gray-200 dark:border-gr-darker py-2 px-3 shadow-sm focus:outline-none sm:text-sm"
              }, null, 512), [
                [_vModelText, _ctx.userInformation.lastName]
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t('emailTs')), 1),
              _withDirectives(_createElementVNode("input", {
                type: "email",
                name: "email",
                id: "email",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.userInformation.email) = $event)),
                autocomplete: "email",
                readonly: "",
                disabled: "",
                class: "mt-1 block w-full rounded-xl border-2 border-gray-200 dark:border-gr-darker py-2 px-3 shadow-sm focus:outline-none sm:text-sm disabled:opacity-50"
              }, null, 512), [
                [_vModelText, _ctx.userInformation.email]
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("button", _hoisted_15, _toDisplayString(_ctx.$t('save')), 1)
        ])
      ])
    ], 32),
    _createElementVNode("div", _hoisted_16, [
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("div", null, [
          _createElementVNode("h3", _hoisted_18, _toDisplayString(_ctx.$t('language.title')), 1),
          _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.$t('language.description')), 1)
        ]),
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("div", _hoisted_21, [
            _createVNode(_component_LanguageSelector, {
              "show-full-name": true,
              onOnChangeLanguage: _ctx.updateUserLanguage
            }, null, 8, ["onOnChangeLanguage"])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_22, [
        _createElementVNode("div", _hoisted_23, [
          _createElementVNode("div", null, [
            _createElementVNode("h3", _hoisted_24, _toDisplayString(_ctx.$t('shop.checkout.login.title')), 1),
            _createElementVNode("p", _hoisted_25, _toDisplayString(_ctx.$t('downloadYourLinkedDataDeleteYourAccountOrCancelYou')), 1)
          ]),
          _createElementVNode("fieldset", null, [
            _createElementVNode("div", _hoisted_26, [
              _createElementVNode("div", _hoisted_27, [
                _createElementVNode("div", _hoisted_28, [
                  _createElementVNode("label", _hoisted_29, _toDisplayString(_ctx.$t('downloadData2')), 1),
                  _createElementVNode("p", _hoisted_30, _toDisplayString(_ctx.$t('downloadAllYourLinkedDataAsJsonFile')), 1)
                ]),
                _createElementVNode("div", _hoisted_31, [
                  _createElementVNode("button", {
                    id: "downloadData",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.downloadUserData())),
                    class: _normalizeClass(['w-full inline-flex justify-center rounded-xl bg-gray-200 dark:bg-gr-darker py-1.5 px-10 text-sm font-semibold text-gray-500 shadow-sm focus:outline-none']),
                    disabled: _ctx.isDownloadingUserData
                  }, [
                    (_ctx.isDownloadingUserData)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                          _cache[8] || (_cache[8] = _createElementVNode("span", null, [
                            _createElementVNode("svg", {
                              class: "animate-spin mr-2 h-5 w-5",
                              xmlns: "http://www.w3.org/2000/svg",
                              fill: "none",
                              viewBox: "0 0 24 24"
                            }, [
                              _createElementVNode("circle", {
                                class: "opacity-25",
                                cx: "12",
                                cy: "12",
                                r: "10",
                                stroke: "currentColor",
                                "stroke-width": "4"
                              }),
                              _createElementVNode("path", {
                                class: "opacity-75",
                                fill: "currentColor",
                                d: "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              })
                            ])
                          ], -1)),
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t('processing')), 1)
                        ]))
                      : (_openBlock(), _createElementBlock("span", _hoisted_34, _toDisplayString(_ctx.$t('downloadData')), 1))
                  ], 8, _hoisted_32)
                ])
              ]),
              (_ctx.getSubscriptions?.getActiveSubscription())
                ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                    _createVNode(_component_ConfirmDialog, {
                      title: _ctx.$t('subscription.cancel.title'),
                      description: _ctx.$t('subscription.cancel.description'),
                      "cancel-button-name": _ctx.$t('subscription.cancel.cancel_button'),
                      "confirm-button-name": _ctx.$t('subscription.cancel.confirm_button'),
                      onConfirm: _cache[5] || (_cache[5] = ($event: any) => (_ctx.cancelActiveSubscription(_ctx.getSubscriptions.getActiveSubscription()?.name))),
                      onCancelConfirm: _cache[6] || (_cache[6] = ($event: any) => (_ctx.showCancelSubscriptionModal = false)),
                      visible: _ctx.showCancelSubscriptionModal
                    }, null, 8, ["title", "description", "cancel-button-name", "confirm-button-name", "visible"]),
                    _createElementVNode("div", _hoisted_36, [
                      _createElementVNode("label", _hoisted_37, _toDisplayString(_ctx.$t('subscription.cancel.confirm_button')), 1),
                      _createElementVNode("p", _hoisted_38, _toDisplayString(_ctx.$t('cancelYourActiveSubscriptionOneTimePurchasesOfCour', {
												getProductName: _ctx.getSubscriptions?.getActiveSubscription().
												getProduct()?.
												getProductName(),
											})), 1)
                    ]),
                    (_ctx.getSubscriptions?.getActiveSubscription())
                      ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
                          _createElementVNode("button", {
                            id: "cancelSubscription",
                            onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.showCancelSubscriptionModal = true)),
                            class: "w-full inline-flex justify-center rounded-xl bg-gray-200 dark:bg-gr-darker py-1.5 px-10 text-sm font-semibold text-gray-500 shadow-sm focus:outline-none"
                          }, _toDisplayString(_ctx.$t('subscription.cancel.confirm_button')), 1)
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_40, [
                    _createElementVNode("div", _hoisted_41, [
                      _createElementVNode("label", _hoisted_42, _toDisplayString(_ctx.$t('noActiveSubscription')), 1),
                      _createElementVNode("p", _hoisted_43, _toDisplayString(_ctx.$t('youAreOneTheFreePlan')), 1)
                    ])
                  ])),
              _createElementVNode("div", _hoisted_44, [
                _createVNode(_component_OAuthList)
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}