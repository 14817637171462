<template>
	<div>
		<Menu as="div" class="relative inline-block w-full">
			<div>
				<MenuButton class="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
					{{ $t('oauth.add') }}
					<PlusIcon class="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
				</MenuButton>
			</div>

			<transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
				<MenuItems class="absolute justify-center z-10 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none w-full">
					<div class="py-1">
						<MenuItem>
							<MetaOAuthComponent key="fb-business" :business="true" :o-auth-type="OAuthType.OAUTH_TYPE_META"></MetaOAuthComponent>
						</MenuItem>
<!--						<MenuItem>-->
<!--							<MetaOAuthComponent key="fb-consumer" :business="false" :o-auth-type="OAuthType.OAUTH_TYPE_META"></MetaOAuthComponent>-->
<!--						</MenuItem>-->
						<MenuItem>
							<MetaOAuthComponent key="ig-business" :business="true" :o-auth-type="OAuthType.OAUTH_TYPE_META_INSTAGRAM"></MetaOAuthComponent>
						</MenuItem>
						<MenuItem>
							<MetaOAuthComponent key="wa-business" :business="true" :o-auth-type="OAuthType.OAUTH_TYPE_META_WHATSAPP"></MetaOAuthComponent>
						</MenuItem>
						<MenuItem>
							<GoogleLoginComponent key="go-login"></GoogleLoginComponent>
						</MenuItem>
					</div>
				</MenuItems>
			</transition>
		</Menu>

		<ul role="list" class="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8 pt-4" v-if="getOAuths && getOAuths.length > 0">
			<li v-for="oauth in getOAuths" :key="oauth.id" class="overflow-hidden rounded-xl border border-gray-200">
				<div class="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
					<img :src="oauth.getImageUrl()" :alt="oauth.getName()" class="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10" />
					<div class="text-sm font-medium leading-6 text-gray-900">{{ oauth.getName() }}</div>
					<Menu as="div" class="relative ml-auto">
						<MenuButton class="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
<!--							<span class="sr-only">Open options</span>-->
							<EllipsisHorizontalIcon class="h-5 w-5" aria-hidden="true" />
						</MenuButton>
						<transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
							<MenuItems class="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
								<MenuItem v-slot="{ active }">
									<a @click="deleteOAuthToProfile(oauth.uuid)" :class="[active ? 'bg-gray-50' : '', 'block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer']"
									>{{ $t('oauth.delete') }}<span class="sr-only">, {{oauth.getName() }}</span></a
									>
								</MenuItem>
							</MenuItems>
						</transition>
					</Menu>
				</div>
				<dl class="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
					<div class="flex justify-between gap-x-4 py-3">
						<dt class="text-gray-500">{{ $t('oauth.expiry_date') }}</dt>
						<dd class="text-gray-700" v-if="oauth.expiry_date">
							<time :datetime="oauth.expiry_date">{{ oauth.getExpiryDateFormatted() }}</time>
						</dd>
					</div>
					<div class="flex justify-between gap-x-4 py-3">
						<dt class="text-gray-500">{{ $t('oauth.permissions') }}</dt>
						<dd class="flex items-start gap-x-2">
							<div class="font-medium text-gray-900 dark:text-gray-200">{{ oauth.getScopesFormatted() }}</div>
						</dd>
					</div>
				</dl>
			</li>
		</ul>
	</div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from 'vue';
import useUser from '@/composable/greeve/useUser';
import useAlert from '@/composable/core/useAlert';
import useSystem from '@/composable/core/useSystem';
import {Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/vue';
import {EllipsisHorizontalIcon, PlusIcon} from '@heroicons/vue/20/solid';
import MetaOAuthComponent from '@/components/auth/MetaOAuthComponent.vue';
import useCustomStore from '@/composable/custom/useCustomStore';
import GoogleLoginComponent from '@/components/auth/GoogleLoginComponent.vue';

export default defineComponent({
	name: 'OAuthList',
	components: {
		GoogleLoginComponent,
		MetaOAuthComponent, EllipsisHorizontalIcon, Menu, MenuButton, MenuItem, MenuItems, PlusIcon},
	props: {},
	emits: [],
	setup() {
		const {getProfile, getSubscriptions, getOAuths, deleteOAuth, initOAuth} = useUser();
		const {addSuccess} = useAlert();
		const { setIsGlobalLoading } = useCustomStore();
		const {isFeatureFlagActive} = useSystem();

		const OAuthType = {
			OAUTH_TYPE_META: 'meta',
			OAUTH_TYPE_META_INSTAGRAM: 'meta_instagram',
			OAUTH_TYPE_META_WHATSAPP: 'meta_whatsapp',
			OAUTH_TYPE_GOOGLE: 'google',
		}

		const isDownloadingUserData = ref(false);
		const isLoading = ref(false);

		function deleteOAuthToProfile(uuid: string) {
			isLoading.value = true;
			setIsGlobalLoading(true);
			deleteOAuth(uuid).then(() => {
				const success = {
					id: "",
					title: "OAuth Account removed!",
					message: "",
					active: true,
				}
				addSuccess(success);
				isLoading.value = false;
				setIsGlobalLoading(false);
			});
		}

		// async function updateUserInformation() {
		// 	const userInformationData: UpdateUserDataName = {
		// 		first_name: String(userInformation.value.firstName),
		// 		last_name: String(userInformation.value.lastName),
		// 	};
		// 	try {
		// 		resetAllAlerts();
		// 		if (getProfile.value.first_name !== userInformationData.first_name || getProfile.value.last_name !==
		// 				userInformationData.last_name) {
		// 			await GreeveApiProfile.updateName(userInformationData);
		// 		}
		// 		const success = {
		// 			id: '',
		// 			title: 'Personal Information updated!',
		// 			message: 'Your First and Lastname successfully updated',
		// 			active: true,
		// 		};
		// 		addSuccess(success);
		// 	} catch (e) {
		// 		console.log(e);
		// 		addError({title: t('auth.auth_error_failed_title'), message: t('auth.failed'), active: true});
		// 	}
		// }

		onMounted(() => {
			//TODO load oauth list via api
			initOAuth();
		});

		return {
			getProfile,
			getSubscriptions,
			isDownloadingUserData,
			isFeatureFlagActive,
			deleteOAuthToProfile,
			getOAuths,
			OAuthType,
		};
	},
});
</script>

<style scoped>

</style>
