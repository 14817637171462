import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "fixed inset-0 z-10 w-screen overflow-y-auto" }
const _hoisted_2 = { class: "flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4" }
const _hoisted_3 = { class: "relative flex w-full items-center overflow-hidden bg-white px-4 pb-8 pt-14 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8" }
const _hoisted_4 = { class: "sr-only" }
const _hoisted_5 = {
  key: 0,
  class: "sm:col-span-4 lg:col-span-5"
}
const _hoisted_6 = { class: "aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-100" }
const _hoisted_7 = ["src", "alt"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = {
  "aria-labelledby": "information-heading",
  class: "mt-3"
}
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = {
  key: 1,
  class: "mt-6"
}
const _hoisted_12 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TransitionChild = _resolveComponent("TransitionChild")!
  const _component_XMarkIcon = _resolveComponent("XMarkIcon")!
  const _component_DialogPanel = _resolveComponent("DialogPanel")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_TransitionRoot = _resolveComponent("TransitionRoot")!

  return (_openBlock(), _createBlock(_component_TransitionRoot, {
    as: "template",
    show: _ctx.open
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Dialog, {
        as: "div",
        class: "relative z-10",
        onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.cancelDialogOutsideClick()))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_TransitionChild, {
            as: "template",
            enter: "ease-out duration-300",
            "enter-from": "opacity-0",
            "enter-to": "opacity-100",
            leave: "ease-in duration-200",
            "leave-from": "opacity-100",
            "leave-to": "opacity-0"
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createElementVNode("div", { class: "fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block" }, null, -1)
            ])),
            _: 1
          }),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_TransitionChild, {
                as: "template",
                enter: "ease-out duration-300",
                "enter-from": "opacity-0 translate-y-4 md:translate-y-0 md:scale-95",
                "enter-to": "opacity-100 translate-y-0 md:scale-100",
                leave: "ease-in duration-200",
                "leave-from": "opacity-100 translate-y-0 md:scale-100",
                "leave-to": "opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DialogPanel, { class: "flex w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("button", {
                          type: "button",
                          class: "absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-6 sm:top-8 md:right-6 md:top-6 lg:right-8 lg:top-8",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.cancelDialog()))
                        }, [
                          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('close')), 1),
                          _createVNode(_component_XMarkIcon, {
                            class: "h-6 w-6",
                            "aria-hidden": "true"
                          })
                        ]),
                        _createElementVNode("div", {
                          class: _normalizeClass(['grid w-full grid-cols-1 items-start', _ctx.image.length > 0 ? 'gap-x-6 gap-y-8 sm:grid-cols-12 lg:gap-x-8' : 'gap-y-8 grid-cols-1'])
                        }, [
                          (_ctx.image.length > 0)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                _createElementVNode("div", _hoisted_6, [
                                  _createElementVNode("img", {
                                    src: _ctx.image,
                                    alt: _ctx.image,
                                    class: "object-cover object-center"
                                  }, null, 8, _hoisted_7)
                                ])
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", {
                            class: _normalizeClass(['', _ctx.image.length > 0 ? 'sm:col-span-8 lg:col-span-7' : ''])
                          }, [
                            _createElementVNode("h2", {
                              class: "text-2xl font-bold text-gray-900 sm:pr-12",
                              innerHTML: _ctx.dialogTitle
                            }, null, 8, _hoisted_8),
                            _createElementVNode("section", _hoisted_9, [
                              (_ctx.dialogSubTitle)
                                ? (_openBlock(), _createElementBlock("p", {
                                    key: 0,
                                    class: "text-lg text-gray-700",
                                    innerHTML: _ctx.dialogSubTitle
                                  }, null, 8, _hoisted_10))
                                : _createCommentVNode("", true),
                              (_ctx.dialogDescription)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                    _createElementVNode("p", {
                                      class: "text-sm text-gray-700",
                                      innerHTML: _ctx.dialogDescription
                                    }, null, 8, _hoisted_12)
                                  ]))
                                : _createCommentVNode("", true)
                            ])
                          ], 2)
                        ], 2)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["show"]))
}