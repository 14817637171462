import {nanoid} from 'nanoid';
import {CustomerInterface} from "@/greeve/shop/customer/customer.interface";
import {CustomerAddress} from "@/greeve/shop/customer/customer_address.type";

export class Customer implements CustomerInterface {
	id: string;
	stripe_id?: string;
	email: string;
	address: CustomerAddress;
	data?: any;
	created_at?: Date;
	updated_at?: Date;


	constructor(email: string, address: CustomerAddress, stripe_id?: string, created_at?: Date, updated_at?: Date, data: any = null) {
		this.id = nanoid();
		this.email = email;
		this.address = address;
		this.stripe_id = stripe_id;
		this.data = data;
		this.created_at = created_at;
		this.updated_at = updated_at;
	}
}