import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 pt-4 gap-4" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "flex h-full flex-col" }
const _hoisted_4 = {
  key: 0,
  class: "flex flex-row"
}
const _hoisted_5 = { class: "inline-flex h-10 w-10 items-center justify-center rounded-xl shadow-md bg-black text-white sm:h-12 sm:w-12" }
const _hoisted_6 = { class: "ml-0 flex md:flex-1 flex-col justify-between mt-4" }
const _hoisted_7 = ["id"]
const _hoisted_8 = { class: "mt-1 text-sm text-gray-500 dark:text-gray-400 line-clamp-3" }
const _hoisted_9 = { class: "mt-2 pt-2 text-xs text-gray-400 dark:text-gray-500 border-t-2 border-gr-darker" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.flowTemplatesForView, (flowTemplate) => {
      return (_openBlock(), _createElementBlock("div", {
        key: flowTemplate.uuid,
        class: _normalizeClass('rounded-2xl p-4 col-span-1' + ' ' + _ctx.background)
      }, [
        _createElementVNode("a", {
          class: "cursor-pointer",
          onClick: ($event: any) => (_ctx.convertTemplateToFlow(flowTemplate))
        }, [
          _createElementVNode("div", _hoisted_3, [
            (flowTemplate.getFlowIcon())
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("span", _hoisted_5, [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(flowTemplate.getFlowIcon()), {
                      class: "h-6 w-6",
                      "aria-hidden": "true"
                    }))
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", null, [
                _createElementVNode("p", {
                  class: "text-base font-medium text-gray-900 dark:text-gray-200 font-semibold truncate",
                  id: flowTemplate.uuid
                }, _toDisplayString(flowTemplate.getTranslatedName()), 9, _hoisted_7),
                _createElementVNode("p", _hoisted_8, _toDisplayString(flowTemplate.getTranslatedDescription()), 1),
                _createElementVNode("p", _hoisted_9, _toDisplayString(flowTemplate.getFlowPriceInformation()), 1)
              ])
            ])
          ])
        ], 8, _hoisted_2)
      ], 2))
    }), 128))
  ]))
}