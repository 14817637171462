import {
	SearchItemDefault
} from '@/greeve/search/item/type/search_item_default.type';
import useSystem from '@/composable/core/useSystem';

export class SearchItemCompletion extends SearchItemDefault{
	public getContent()
	{
		let content: string|object = '';
		if (this.response && this.response.content) {
			content = this.response.content;
		} else if (useSystem().isJson(this.response)) {
			content = JSON.parse(this.response)?.content || '';
		} else {
			content = this.response;
		}
		if (typeof content !== "string") {
			content = content.toString();
		}
		if (content) {
			const sanitizedContent = this.sanitizeExceptCodeTags(content);
			if (!sanitizedContent) {
				return sanitizedContent;
			}
			this.getMarkdownParser().cssNamespace = 'completion-result';
			return this.getMarkdownParser().markdownToHtml(sanitizedContent);
		}
		return super.getContent();
	}


	public setContent(content: any)
	{
		if (!this.response) {
			this.response = {};
		}
		this.response.content = content;
	}


	public addContent(content: any)
	{
		if (this.response && this.response.content) {
			this.response.content += content;
		} else {
			this.setContent(content);
		}
	}
}