<template>
	<Popover class="ml-1 sm:ml-4 flow-root text-sm lg:relative lg:ml-8">
		<PopoverButton class="group flex items-center p-2 px-2 py-1.5 bg-gray-100 hover:bg-gray-200 dark:hover:bg-gr-dark dark:bg-gr-darker rounded-xl outline-none">
			<ShoppingBagIcon :class="['h-4 w-4 flex-shrink-0 text-gray-500 group-hover:text-gray-600', showCartItemCountChangedAnimation ? 'animate-bounce' : '']" aria-hidden="true"/>
			<span class="ml-2 text-sm font-medium text-gray-700 dark:text-gray-500 group-hover:text-gray-600">{{cart.items && cart.items.length > 0 ? cart.items.length : 0}}</span>
			<span class="sr-only">{{ $t('itemsInCartViewBag') }}</span>
		</PopoverButton>
		<transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0"
					enter-to-class="opacity-100" leave-active-class="transition ease-in duration-150"
					leave-from-class="opacity-100" leave-to-class="opacity-0">
			<PopoverPanel
					:class="'mt-px bg-white dark:bg-gr-darker pb-6 shadow-lg sm:px-2 rounded-xl' + ' ' + popoverPosition + ' ' + popoverSize">
				<h2 class="sr-only">{{ $t('shoppingCart') }}</h2>

				<div class="mx-auto max-w-2xl px-4" v-if="cart.items && cart.items.length > 0">
					<ul role="list" class="divide-y divide-gray-200 dark:divide-gray-500">
						<li v-for="cartItem in cart.items" :key="cartItem.id" class="flex items-center py-6">
							<img loading="lazy" :src="cartItem.getProduct().getDefaultImage()"
								 :alt="cartItem.getProduct().getDefaultImage()"
								 class="h-10 w-10 flex-none rounded-md border bg-white border-gray-200 dark:border-gr-dark"/>
							<div class="ml-4 flex-auto">
								<h3 class="text-left font-medium text-xs text-gray-900 dark:text-gray-400">
									<a :href="cartItem.getProduct().detailLink">{{
											cartItem.getProduct().description
										}}</a>
								</h3>
							</div>
							<div class="ml-4 flex w-full">
								<div class="ion-activatable flex bg-gray-200 dark:bg-gr-dark py-1 rounded-lg mr-2">
									<button class="mx-2 grid content-center" @click="removeCartItem_by_Id(cartItem.id)">
										<ion-icon v-if="true" class="w-4" slot="icon-only" :icon="icons.trashOutline"></ion-icon>
										<ion-icon v-else slot="icon-only" :icon="icons.removeOutline"></ion-icon>
										<ion-ripple-effect></ion-ripple-effect>
									</button>
									<input class="bg-gray-50 dark:bg-gr-darker py-2 h-5 w-7 text-center outline-none text-gray-900 dark:text-gray-400">
									<button class="px-2 py-0 grid content-center" @click="addProductToCart(cartItem.id)">
										<ion-icon class="w-4" slot="icon-only" :icon="icons.addOutline"></ion-icon>
									</button>
								</div>
							</div>
						</li>
					</ul>

					<p class="mt-6 text-center">
						<router-link :to="checkoutLink">
							<a class="cursor-pointer w-full rounded-xl border border-transparent py-1.5 px-6 text-sm font-medium shadow-sm focus:outline-none bg-black dark:bg-gr-dark font-semibold text-white dark:text-white">{{ $t('checkout') }}</a>
						</router-link>
					</p>
				</div>
				<div v-else>
					<p class="mt-6 text-center">
						{{ $t('thereIsNothingInYourBasket') }}
					</p>
					<p class="mt-6 mx-auto text-center">
						<router-link to="/services"
									 class="align-center rounded-xl border border-transparent bg-gr-primary hover:bg-gr-primary3 py-1 px-6 text-base font-medium text-white transition duration-300 ease-in-out">
							{{ $t('viewServices') }}
						</router-link>
					</p>
				</div>
			</PopoverPanel>
		</transition>
	</Popover>
</template>

<script lang="ts">
import {ref, onMounted, watch, defineComponent} from 'vue';
import {ShoppingBagIcon} from '@heroicons/vue/24/outline'
import {Popover, PopoverButton, PopoverPanel} from '@headlessui/vue'
import useShop from "@/composable/greeve/useShop";
import * as icons from "ionicons/icons";
import {IonIcon, IonRippleEffect} from '@ionic/vue';
import {CartInterface} from "@/greeve/shop/cart/cart.interface";
import useAuth from "@/composable/auth/useAuth";

export default defineComponent({
	name: "CheckoutNavCart",
	components: {ShoppingBagIcon, Popover, PopoverButton, PopoverPanel, IonIcon, IonRippleEffect},
	props: {
		checkoutLink: {
			type: String,
			default: ''
		},
		viewShoppingBagLink: {
			type: String,
			default: ''
		},
		popoverPosition: {
			type: String,
			default: 'absolute inset-x-0 left-auto top-14 lg:top-10 right-12 lg:right-1 -mr-1.5'
		},
		popoverSize: {
			type: String,
			default: 'w-80'
		},
	},
	emits: [],
	setup() {
		const {cart, removeCartItem_by_Id, addProductToCart} = useShop();
		const {logout} = useAuth();
		const showCartItemCountChangedAnimation = ref(false);

		watch(() => cart.value, (newValue: CartInterface, oldValue: CartInterface|undefined) => {
			if (!oldValue || newValue.items?.length !== oldValue.items?.length) {
				showCartItemCountChangedAnimation.value = true;
				setTimeout( () => {
					showCartItemCountChangedAnimation.value = false;
				}, 1500)
			}
		})

		onMounted(() => {
			//
		})

		return {
			cart,
			removeCartItem_by_Id,addProductToCart,
			icons,
			showCartItemCountChangedAnimation,
			logout
		};
	}
})
</script>

<style scoped>

</style>
