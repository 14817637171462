import {
	AbstractFlowProcess
} from '@/greeve/flow/process/abstract_flow_process.type';
import {h} from 'vue';
import TextViewNode from '@/components/workflow/nodes/TextViewNode.vue';

export class FlowProcessDownloadAndShow extends AbstractFlowProcess{
	getVueFlowTemplate(): any {
		return h(TextViewNode, { flowId: this.flow_id, flowProcessUuid: this.uuid });
	}
}