import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex w-full pt-5 px-1"
}




export default /*@__PURE__*/_defineComponent({
  __name: 'PasswordStrenghtOmeter',
  props: {
	currentPassword: {
		type: String,
		required: true
	},
	showPasswordStrengthoMeter: {
		type: Boolean,
		default: true
	},
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (__props.showPasswordStrengthoMeter)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("span", {
          class: _normalizeClass([__props.currentPassword.length > 5 ? 'bg-green-700' : 'bg-gray-200 dark:bg-gr-darker', "h-2 w-1/3 rounded"])
        }, null, 2),
        _createElementVNode("span", {
          class: _normalizeClass([__props.currentPassword.length > 5 && __props.currentPassword.match(/[\w]+/) ? 'bg-green-700' : 'bg-gray-200 dark:bg-gr-darker', "h-2 w-1/3 rounded mx-3"])
        }, null, 2),
        _createElementVNode("span", {
          class: _normalizeClass([__props.currentPassword.length > 5 && __props.currentPassword.match(/[!@#$%^&*(),.?:{}|<>]/) ? 'bg-green-700' : 'bg-gray-200 dark:bg-gr-darker', "h-2 w-1/3 rounded"])
        }, null, 2)
      ]))
    : _createCommentVNode("", true)
}
}

})