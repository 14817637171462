import {MicrophoneIcon, VideoCameraIcon} from '@heroicons/vue/20/solid';
import {AbstractFlow} from '@/greeve/flow/abstract_flow.type';
import {FlowSubType} from '@/greeve/flow/flow.interface';

export class FlowWhisper extends AbstractFlow {
	getFlowIcon(): any
	{
		if (this.sub_type === FlowSubType.WHISPER_VIDEO_TEMPLATE) {
			return VideoCameraIcon;
		}
		return MicrophoneIcon;
	}
}