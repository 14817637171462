<template>
	<a v-if="!isLoginButton" @click="signIn" class="cursor-pointer hover:bg-gray-100 hover:text-gray-900 text-gray-700 block px-4 py-2 text-sm flex"><img :src="getImageUrlByType()" :alt="'Login with ' + getNameByType()" class="h-5 pr-2"/><span>{{getNameByType()}}</span></a>
	<div v-else class="flex">
		<a @click="signIn" class="w-full px-6 py-2 lg:py-1.5 cursor-pointer font-semibold text-gray-900 bg-white border-2 border-gray-500 rounded-xl shadow-2xl outline-none focus:outline-none">
			<svg xmlns="http://www.w3.org/2000/svg" class="inline w-4 h-4 mr-3 text-gray-900 fill-current" viewBox="0 0 48 48" width="48px" height="48px">
				<path fill="#fbc02d" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12 s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20 s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"></path>
				<path fill="#e53935" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039 l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"></path>
				<path fill="#4caf50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36 c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"></path>
				<path fill="#1565c0" d="M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571 c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"></path>
			</svg>{{ t("login.google") }}</a>
	</div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from 'vue'
import useUser from "@/composable/greeve/useUser";
import useToastMessage from "@/composable/core/useToastMessage";
import {loadingController} from "@ionic/vue";
import useSystem from "@/composable/core/useSystem";
import {GoogleAuth} from "@codetrix-studio/capacitor-google-auth";
import {AuthenticationError} from "@/greeve/api/auth";
import useTranslation from "@/composable/translation/useTranslation";
import {ApiOAuthInterface} from '@/greeve/user/oauth/api_oauth.interface';
import useAlert from '@/composable/core/useAlert';
import {OAuthType} from '@/greeve/user/oauth/oauth.interface';

export default defineComponent({
	name: 'GoogleLoginComponent',
	props: {
		provider: {
			type: String,
			default: "google"
		},
		redirectAfterSubmit: {
			type: Boolean,
			default: false
		},
		isLoginButton: {
			type: Boolean,
			default: false
		}
	},
	emits: ['loginSuccess', 'loginError'],
	setup(props, {emit}) {
		const {t} = useTranslation();
		const {addOAuth} = useUser();
		const {openToast} = useToastMessage();
		const {isNativePlatform} = useSystem();
		const loadingTimeout = ref(50000);
		const {addSuccess} = useAlert();
		const loading = ref();

		async function presentLoading() {
			loading.value = await loadingController
				.create({
					cssClass: '',
					//TODO add Translations
					message: 'Bitte warten... du wirst eingeloggt!',
					duration: loadingTimeout.value,
				});

			await loading.value.present();

			setTimeout(function () {
				loading.value.dismiss()
			}, loadingTimeout.value);
		}

		function getImageUrlByType(): string
		{
			return 'https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/images/socialmedia/google.png';
		}

		function getNameByType(): string
		{
			return 'Google';
		}

		function getScopes() {
			return [
				'email',
				'https://www.googleapis.com/auth/spreadsheets',
				'https://www.googleapis.com/auth/drive',
				'https://www.googleapis.com/auth/calendar',
				'https://mail.google.com'];
		}

		async function initializeGoogle() {
			if (!isNativePlatform()) {
				GoogleAuth.initialize({
					clientId: '69235563995-ueslt7oft0bdarilpluehh0l9f7198l4.apps.googleusercontent.com',
					scopes: getScopes(),
					grantOfflineAccess: true,
				});
			}
		}

		onMounted(() => {
			initializeGoogle();
		})

		async function handleGoogleLoginError(error: any) {
			try {
				if (error instanceof AuthenticationError) {
					if (error.message.includes('Provider Data Error')) {
						await openToast(t("login.UserNotAllowed"), 'danger', 'top', true, 10000, undefined, true);
					} else {
						await openToast(t("login.error"), 'danger', 'top', true, 5000, undefined, true);
					}
				} else {
					if (error.error == "popup_closed_by_user") {
						await openToast(t("login.error"), 'warning', 'top', true, 5000, undefined, true);
					} else {
						await openToast(t("login.error"), 'danger', 'top', true, 5000, undefined, true);
					}
				}
			} catch (error) {
				await openToast(t("login.errorLogin"), 'danger', 'top', true, 5000, undefined, true);
			} finally {
				emit('loginError');
			}
		}

		async function signIn(): Promise<void> {
			try {
				const response = await GoogleAuth.signIn();
				await presentLoading();
				console.log("RESS", response)
				if (response) {
					const data = {
						isBusiness: false,
						authResponse: response,
						scopes: getScopes(),
					};

					const oauthData: ApiOAuthInterface = {
						data: data,
						name: response.givenName + ' ' + response.familyName,
						reference: response.id,
						refresh_token: response.authentication.refreshToken?.length === 0 && response.serverAuthCode ? response.serverAuthCode : response.authentication.refreshToken,
						token: response.authentication.accessToken,
						type: OAuthType.OAUTH_TYPE_GOOGLE,
					};

					addNewOAuthToProfile(oauthData).then(() => {
						setTimeout(() => {
							loading.value.dismiss();
							clearTimeout(loadingTimeout.value);
						}, 1000)
					})

					// const authToken = await GreeveApiAuth.signIn(result);
					// if (authToken) {
					// 	setName(result.name);
					// 	setGivenName(result.givenName);
					// 	setEmail(result.email);
					// 	setProfileImage(result.imageUrl);
					// 	if (props.redirectAfterSubmit) {
					// 		await router.push('/profile');
					// 	}
					// 	emit('loginSuccess');
					// }
				} else {
					await openToast(t("login.errorLogin"), 'danger', 'top', true, 5000);
				}
			} catch (error) {
				console.log(error);
				await handleGoogleLoginError(error);
			}
		}

		async function addNewOAuthToProfile(data: ApiOAuthInterface)
		{
			addOAuth(data).then(() => {
				const success = {
					id: "",
					title: "New OAuth Account successfully connected!",
					message: "",
					active: true,
				}
				addSuccess(success);
			});
		}

		return {t, signIn, getImageUrlByType, getNameByType}
	},
})
</script>

<style scoped>
.google-login {
    padding-top: 0px;
    object-fit: cover;
}
</style>
