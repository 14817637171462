import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vShow as _vShow, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "space-y-4" }
const _hoisted_2 = { class: "sm:overflow-hidden rounded-xl nav backdrop-filter backdrop-blur-3xl" }
const _hoisted_3 = { class: "nav rounded-t-xl space-y-6 py-6 px-4 sm:p-6" }
const _hoisted_4 = { class: "text-lg font-medium leading-6 text-gray-900 dark:text-gray-500" }
const _hoisted_5 = { class: "mt-1 text-sm text-gray-600" }
const _hoisted_6 = { class: "grid grid-cols-6 gap-6" }
const _hoisted_7 = { class: "col-span-6 sm:col-span-4" }
const _hoisted_8 = {
  for: "current_password",
  class: "block text-sm font-medium text-gray-700 dark:text-gray-600"
}
const _hoisted_9 = { class: "col-span-6 sm:col-span-4" }
const _hoisted_10 = {
  for: "password",
  class: "block text-sm font-medium text-gray-700 dark:text-gray-600"
}
const _hoisted_11 = { class: "col-span-6 sm:col-span-4" }
const _hoisted_12 = {
  for: "password_confirmation",
  class: "block text-sm font-medium text-gray-700 dark:text-gray-600"
}
const _hoisted_13 = {
  key: 0,
  class: "col-span-6 sm:col-span-4"
}
const _hoisted_14 = { class: "flex items-center py-1" }
const _hoisted_15 = {
  class: "w-4 h-4",
  fill: "none",
  viewBox: "0 0 24 24",
  stroke: "currentColor"
}
const _hoisted_16 = {
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "2",
  d: "M5 13l4 4L19 7"
}
const _hoisted_17 = {
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "2",
  d: "M6 18L18 6M6 6l12 12"
}
const _hoisted_18 = ["innerHTML"]
const _hoisted_19 = { class: "flex items-center py-1" }
const _hoisted_20 = {
  class: "w-4 h-4",
  fill: "none",
  viewBox: "0 0 24 24",
  stroke: "currentColor"
}
const _hoisted_21 = {
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "2",
  d: "M5 13l4 4L19 7"
}
const _hoisted_22 = {
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "2",
  d: "M6 18L18 6M6 6l12 12"
}
const _hoisted_23 = ["innerHTML"]
const _hoisted_24 = { class: "px-4 py-3 text-right nav rounded-b-xl sm:px-6" }
const _hoisted_25 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.updateUserPassword && _ctx.updateUserPassword(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", null, [
            _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.$t('updatePassword')), 1),
            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('ensureYourAccountIsUsingALongRandomPasswordToStayS')), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('currentPassword')), 1),
              _withDirectives(_createElementVNode("input", {
                id: "current_password",
                type: "password",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.userPassword.current_password) = $event)),
                class: "mt-1 block w-full rounded-xl border-2 border-gray-200 dark:border-gr-darker py-2 px-3 shadow-sm focus:outline-none sm:text-sm",
                ref: "current_password",
                autocomplete: "current-password"
              }, null, 512), [
                [_vModelText, _ctx.userPassword.current_password]
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t('newPassword')), 1),
              _withDirectives(_createElementVNode("input", {
                id: "password",
                type: "password",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.userPassword.password) = $event)),
                class: "mt-1 block w-full rounded-xl border-2 border-gray-200 dark:border-gr-darker py-2 px-3 shadow-sm focus:outline-none sm:text-sm",
                ref: "password",
                autocomplete: "new-password"
              }, null, 512), [
                [_vModelText, _ctx.userPassword.password]
              ])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t('confirmPassword')), 1),
              _withDirectives(_createElementVNode("input", {
                id: "password_confirmation",
                type: "password",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.userPassword.password_confirmation) = $event)),
                class: "mt-1 block w-full rounded-xl border-2 border-gray-200 dark:border-gr-darker py-2 px-3 shadow-sm focus:outline-none sm:text-sm",
                autocomplete: "new-password"
              }, null, 512), [
                [_vModelText, _ctx.userPassword.password_confirmation]
              ])
            ]),
            (_ctx.userPassword.password.length > 0 || _ctx.userPassword.password_confirmation.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  _createElementVNode("ul", null, [
                    _createElementVNode("li", _hoisted_14, [
                      _createElementVNode("div", {
                        class: _normalizeClass([{'bg-green-200 text-green-700': _ctx.userPassword.password == _ctx.userPassword.password_confirmation && _ctx.userPassword.password.length > 0, 'bg-red-200 text-red-700':_ctx.userPassword.password != _ctx.userPassword.password_confirmation || _ctx.userPassword.password.length == 0}, "rounded-full p-1 fill-current"])
                      }, [
                        (_openBlock(), _createElementBlock("svg", _hoisted_15, [
                          _withDirectives(_createElementVNode("path", _hoisted_16, null, 512), [
                            [_vShow, _ctx.userPassword.password == _ctx.userPassword.password_confirmation && _ctx.userPassword.password.length > 0]
                          ]),
                          _withDirectives(_createElementVNode("path", _hoisted_17, null, 512), [
                            [_vShow, _ctx.userPassword.password != _ctx.userPassword.password_confirmation || _ctx.userPassword.password.length == 0]
                          ])
                        ]))
                      ], 2),
                      _createElementVNode("span", {
                        class: _normalizeClass([{'text-green-700': _ctx.userPassword.password == _ctx.userPassword.password_confirmation && _ctx.userPassword.password.length > 0, 'text-red-700':_ctx.userPassword.password != _ctx.userPassword.password_confirmation || _ctx.userPassword.password.length == 0}, "font-medium text-sm ml-3"]),
                        innerHTML: _ctx.userPassword.password == _ctx.userPassword.password_confirmation && _ctx.userPassword.password.length > 0 ? 'Passwords match' : 'Passwords do not match' 
                      }, null, 10, _hoisted_18)
                    ]),
                    _createElementVNode("li", _hoisted_19, [
                      _createElementVNode("div", {
                        class: _normalizeClass([{'bg-green-200 text-green-700': _ctx.userPassword.password.length > 7, 'bg-red-200 text-red-700':_ctx.userPassword.password.length < 7 }, "rounded-full p-1 fill-current"])
                      }, [
                        (_openBlock(), _createElementBlock("svg", _hoisted_20, [
                          _withDirectives(_createElementVNode("path", _hoisted_21, null, 512), [
                            [_vShow, _ctx.userPassword.password.length > 7]
                          ]),
                          _withDirectives(_createElementVNode("path", _hoisted_22, null, 512), [
                            [_vShow, _ctx.userPassword.password.length < 7]
                          ])
                        ]))
                      ], 2),
                      _createElementVNode("span", {
                        class: _normalizeClass([{'text-green-700': _ctx.userPassword.password.length > 7, 'text-red-700':_ctx.userPassword.password.length < 7 }, "font-medium text-sm ml-3"]),
                        innerHTML: _ctx.userPassword.password.length > 7 ? 'The minimum length is reached' : 'At least 8 characters required' 
                      }, null, 10, _hoisted_23)
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_24, [
          _createElementVNode("button", {
            type: "submit",
            disabled: !_ctx.isPasswordValid(),
            class: _normalizeClass(['inline-flex justify-center rounded-xl bg-black dark:bg-gr-darker py-1.5 px-10 text-sm font-semibold text-white shadow-sm focus:outline-none', !_ctx.isPasswordValid() ? 'disabled:opacity-50' : ''])
          }, _toDisplayString(_ctx.$t('save')), 11, _hoisted_25)
        ])
      ])
    ], 32)
  ]))
}