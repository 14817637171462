import {nanoid} from 'nanoid';
import {
	DiscountItemList,
} from '@/greeve/shop/cart/discount/discount_item_list.type';
import {
	AbstractDiscountItem,
} from '@/greeve/shop/cart/discount/abstract_discount_item.type';
import {
	PromoDiscountItem,
} from '@/greeve/shop/cart/discount/promo/promo_discount_item.type';
import {DiscountObject} from '@/greeve/shop/cart/discount/discount.interface';
import {
	CouponDiscountItem,
} from '@/greeve/shop/cart/discount/coupon/coupon_discount_item.type';

export class DiscountItemFactory {
	/**
	 *
	 * @param discountItems
	 */
	createDiscountItemListListByResponse(discountItems: Record<string, any>): DiscountItemList {
		const discountItemList = new DiscountItemList();

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		for (const [key, item] of Object.entries(discountItems)) {
			discountItemList.push(this.createDiscountItemByResponse(item));
		}

		return discountItemList;
	}

	/**
	 *
	 * @param item
	 */
	createDiscountItemByResponse(item: Record<string, any>): AbstractDiscountItem {
		const id = item.id;
		const coupon = item.coupon;
		const name = item.name ?? undefined;
		const percentOff = item.percentOff ?? undefined;
		const promotionCode = item.promotionCode ?? undefined;
		const checkoutSession = item.checkoutSession ?? undefined;
		const currency = item.currency ?? undefined;
		const customer = item.customer ?? undefined;
		const end = item.end ?? undefined;
		const invoice = item.invoice ?? undefined;
		const invoiceItem = item.invoiceItem ?? undefined;
		const start = item.start ?? undefined;
		const restrictions = item.restrictions ?? undefined;
		const metaData = item.metaData ?? undefined;

		let discountItem;

		const object = (item.object ? item.object : DiscountObject.PROMO_CODE);
		switch (object) {
			case DiscountObject.PROMO_CODE:
				discountItem = new PromoDiscountItem(id, coupon, name, percentOff,
						promotionCode, checkoutSession, currency, customer, end, invoice,
						invoiceItem, start, restrictions, metaData);
				break;
			case DiscountObject.COUPON:
				discountItem = new CouponDiscountItem(id, coupon, name, percentOff,
						promotionCode, checkoutSession, currency, customer, end, invoice,
						invoiceItem, start, restrictions, metaData);
				break;
			default:
				throw new Error('Invalid Discount Item: ' + JSON.stringify(item));
		}

		return discountItem;
	}

	createEmptyPromoDiscountItem(): AbstractDiscountItem {
		return new PromoDiscountItem(nanoid(), {}, 'NEW-PROMO-CODE');
	}

	createEmptyDiscountItemList(): DiscountItemList {
		return new DiscountItemList([]);
	}
}