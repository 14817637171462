import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "GreevePricingPlans",
  class: "mt-12 mb-56"
}
const _hoisted_2 = { class: "max-w-7xl lg:mx-auto" }
const _hoisted_3 = { class: "max-w-4xl mx-8 lg:mx-auto mb-12 mx-auto font-comfortaa text-stroke text-6xl sm:text-7xl font-bold" }
const _hoisted_4 = { class: "" }
const _hoisted_5 = { class: "text-gr-primary" }
const _hoisted_6 = { style: {"-webkit-transform":"translate3d(0px, 0px, 0px)"} }
const _hoisted_7 = { class: "text-white box max-w-7xl mx-4 mx-auto" }
const _hoisted_8 = { class: "relative" }
const _hoisted_9 = { class: "isolate overflow-hidden" }
const _hoisted_10 = { class: "flow-root" }
const _hoisted_11 = { class: "mx-auto max-w-7xl px-0 lg:px-8" }
const _hoisted_12 = { class: "relative z-10" }
const _hoisted_13 = { class: "flex justify-center" }
const _hoisted_14 = { class: "relative mx-auto mt-10 grid max-w-md grid-cols-1 gap-y-8 lg:mx-0 lg:-mb-14 lg:max-w-none lg:grid-cols-3" }
const _hoisted_15 = { class: "p-8 lg:pt-12 xl:p-10 xl:pt-14" }
const _hoisted_16 = ["id"]
const _hoisted_17 = { class: "flex flex-col gap-6 sm:flex-row sm:items-end sm:justify-between lg:flex-col lg:items-stretch" }
const _hoisted_18 = { class: "mt-2 flex items-center gap-x-4" }
const _hoisted_19 = { class: "text-sm leading-5" }
const _hoisted_20 = ["onClick", "aria-describedby"]
const _hoisted_21 = { class: "mt-8 flow-root sm:mt-10" }
const _hoisted_22 = { class: "relative mx-auto rounded-xl max-w-2xl lg:max-w-7xl bg-gray-50 dark:bg-gr-darkester mt-12 lg:mt-0 lg:pt-14" }
const _hoisted_23 = { class: "mx-auto py-6 px-6 lg:px-8" }
const _hoisted_24 = {
  "aria-labelledby": "mobile-comparison-heading",
  class: "lg:hidden"
}
const _hoisted_25 = { class: "mx-auto max-w-2xl space-y-16" }
const _hoisted_26 = { class: "mt-1 text-sm leading-6 text-gray-600" }
const _hoisted_27 = { class: "mt-10 space-y-10" }
const _hoisted_28 = { class: "text-sm font-semibold leading-6 text-gray-900 dark:text-gray-400" }
const _hoisted_29 = { class: "relative mt-6" }
const _hoisted_30 = { class: "divide-y divide-gray-200 dark:divide-gr-darkester text-sm leading-6" }
const _hoisted_31 = { class: "pr-4 text-gray-600 dark:text-gray-400" }
const _hoisted_32 = { class: "flex items-center justify-end sm:justify-center sm:px-4" }
const _hoisted_33 = { class: "sr-only" }
const _hoisted_34 = {
  "aria-labelledby": "comparison-heading",
  class: "hidden lg:block"
}
const _hoisted_35 = {
  id: "comparison-heading",
  class: "sr-only"
}
const _hoisted_36 = { class: "grid grid-cols-4 gap-x-8 border-t border-gray-900/10 before:block" }
const _hoisted_37 = { class: "mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400" }
const _hoisted_38 = { class: "-mt-6 space-y-16" }
const _hoisted_39 = { class: "text-sm font-semibold leading-6 text-gray-900 dark:text-gray-500" }
const _hoisted_40 = { class: "relative -mx-8 mt-10" }
const _hoisted_41 = { class: "relative w-full border-separate border-spacing-x-8" }
const _hoisted_42 = { class: "text-left" }
const _hoisted_43 = { scope: "col" }
const _hoisted_44 = { class: "sr-only" }
const _hoisted_45 = { class: "sr-only" }
const _hoisted_46 = {
  scope: "row",
  class: "w-1/4 py-3 pr-4 text-left text-sm font-normal leading-6 text-gray-900 dark:text-gray-400"
}
const _hoisted_47 = {
  key: 0,
  class: "absolute inset-x-8 mt-3 h-px bg-gray-200 dark:bg-gr-darkester"
}
const _hoisted_48 = { class: "relative h-full w-full py-3" }
const _hoisted_49 = { class: "sr-only" }
const _hoisted_50 = {
  class: "pointer-events-none absolute inset-y-0 inset-x-8 grid grid-cols-4 gap-x-8 before:block",
  "aria-hidden": "true"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RadioGroupLabel = _resolveComponent("RadioGroupLabel")!
  const _component_RadioGroupOption = _resolveComponent("RadioGroupOption")!
  const _component_RadioGroup = _resolveComponent("RadioGroup")!
  const _component_CheckIcon = _resolveComponent("CheckIcon")!
  const _component_XMarkIcon = _resolveComponent("XMarkIcon")!
  const _component_FooterBar = _resolveComponent("FooterBar")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("main", {
            style: _normalizeStyle({ paddingTop: _ctx.headerHeight + 'px' })
          }, [
            (_ctx.recurringProductList)
              ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('be')), 1),
                      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('pro')), 1),
                      _createTextVNode(_toDisplayString(_ctx.$t('doPro')), 1)
                    ]),
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("figure", _hoisted_8, [
                          _createElementVNode("div", _hoisted_9, [
                            _createElementVNode("div", _hoisted_10, [
                              _createElementVNode("div", _hoisted_11, [
                                _createElementVNode("div", _hoisted_12, [
                                  _createElementVNode("div", _hoisted_13, [
                                    _createVNode(_component_RadioGroup, {
                                      modelValue: _ctx.frequency,
                                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.frequency) = $event)),
                                      class: "grid grid-cols-2 gap-x-1 rounded-xl nav p-1 text-center text-xs font-medium text-gray-500 dark:text-gray-400"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_RadioGroupLabel, { class: "sr-only" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.$t('paymentFrequency')), 1)
                                          ]),
                                          _: 1
                                        }),
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.frequencies, (option) => {
                                          return (_openBlock(), _createBlock(_component_RadioGroupOption, {
                                            as: "template",
                                            key: option.value,
                                            value: option
                                          }, {
                                            default: _withCtx(({ checked }) => [
                                              _createElementVNode("div", {
                                                class: _normalizeClass([checked ? 'bg-gray-100 dark:bg-gr-darker text-gray-500 dark:text-gray-400 ' : '', 'cursor-pointer rounded-lg py-1 px-2.5'])
                                              }, [
                                                _createElementVNode("span", null, _toDisplayString(option.label), 1)
                                              ], 2)
                                            ]),
                                            _: 2
                                          }, 1032, ["value"]))
                                        }), 128))
                                      ]),
                                      _: 1
                                    }, 8, ["modelValue"])
                                  ])
                                ]),
                                _createElementVNode("div", _hoisted_14, [
                                  _cache[1] || (_cache[1] = _createElementVNode("div", {
                                    class: "hidden lg:absolute lg:inset-x-px lg:bottom-0 lg:top-4 lg:block lg:rounded-t-2xl lg:bg-black lg:ring-1 lg:ring-white/10",
                                    "aria-hidden": "true"
                                  }, null, -1)),
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getRecurringProducts(), (recurringProduct) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      key: recurringProduct.id,
                                      class: _normalizeClass([_ctx.isProductFeatured(recurringProduct) ? 'z-10 bg-white shadow-xl ring-1 ring-gray-900/10' : 'bg-gray-800/80 ring-1 ring-white/10 dark:ring-black/10 lg:bg-transparent lg:pb-14 lg:ring-0', 'relative rounded-2xl'])
                                    }, [
                                      _createElementVNode("div", _hoisted_15, [
                                        _createElementVNode("h3", {
                                          id: recurringProduct.id,
                                          class: _normalizeClass([_ctx.isProductFeatured(recurringProduct) ? 'text-gray-900' : 'text-white', 'text-sm font-semibold leading-6'])
                                        }, _toDisplayString(recurringProduct.getProductName()), 11, _hoisted_16),
                                        _createElementVNode("div", _hoisted_17, [
                                          _createElementVNode("div", _hoisted_18, [
                                            _createElementVNode("p", {
                                              class: _normalizeClass([_ctx.isProductFeatured(recurringProduct) ? 'text-gray-900' : 'text-white', 'text-4xl font-bold tracking-tight'])
                                            }, _toDisplayString(recurringProduct.price?.getFormattedAmount(false,
																					false)), 3),
                                            _createElementVNode("div", _hoisted_19, [
                                              _createElementVNode("p", {
                                                class: _normalizeClass(_ctx.isProductFeatured(recurringProduct) ? 'text-gray-900' : 'text-white')
                                              }, _toDisplayString(_ctx.$t('eur')), 3),
                                              _createElementVNode("p", {
                                                class: _normalizeClass(_ctx.isProductFeatured(recurringProduct) ? 'text-gray-500' : 'text-gray-400')
                                              }, _toDisplayString(_ctx.$t('shop.product.' + _ctx.frequency.value)), 3)
                                            ])
                                          ]),
                                          _createElementVNode("button", {
                                            onClick: ($event: any) => (_ctx.buyThisPlan(recurringProduct)),
                                            "aria-describedby": recurringProduct.id,
                                            class: _normalizeClass([_ctx.isProductFeatured(recurringProduct) ? 'bg-gr-primary hover:bg-gr-primary2 shadow-sm focus-visible:outline-black' : 'bg-white/10 hover:bg-white/20 focus-visible:outline-white', 'rounded-xl py-2 px-3 text-center text-sm font-semibold leading-6 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'])
                                          }, _toDisplayString(_ctx.$t('buyThisPlan')), 11, _hoisted_20)
                                        ]),
                                        _createElementVNode("div", _hoisted_21, [
                                          _createElementVNode("ul", {
                                            role: "list",
                                            class: _normalizeClass([_ctx.isProductFeatured(recurringProduct) ? 'divide-gray-900/5 border-gray-900/5 text-gray-600' : 'divide-white/5 border-white/5 text-white', '-my-2 divide-y border-t text-sm leading-6 lg:border-t-0'])
                                          }, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(recurringProduct.getProductFeatures(), (mainFeature) => {
                                              return (_openBlock(), _createElementBlock("li", {
                                                key: mainFeature,
                                                class: "flex gap-x-3 py-2"
                                              }, [
                                                _createVNode(_component_CheckIcon, {
                                                  class: _normalizeClass([_ctx.isProductFeatured(recurringProduct) ? 'text-black' : 'text-gray-500', 'h-6 w-5 flex-none']),
                                                  "aria-hidden": "true"
                                                }, null, 8, ["class"]),
                                                _createTextVNode(" " + _toDisplayString(typeof mainFeature === 'string' ? mainFeature : (mainFeature.source
																					? mainFeature.source
																					: _ctx.$t('shop.product.' + recurringProduct.price.type + '.' +
																							recurringProduct.product_reference + '.features.' + mainFeature.name))), 1)
                                              ]))
                                            }), 128))
                                          ], 2)
                                        ])
                                      ])
                                    ], 2))
                                  }), 128))
                                ])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_22, [
                              _createElementVNode("div", _hoisted_23, [
                                _createElementVNode("section", _hoisted_24, [
                                  _cache[3] || (_cache[3] = _createElementVNode("h2", {
                                    id: "mobile-comparison-heading",
                                    class: "sr-only"
                                  }, "Feature comparison", -1)),
                                  _createElementVNode("div", _hoisted_25, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getRecurringProducts(), (recurringProduct) => {
                                      return (_openBlock(), _createElementBlock("div", {
                                        key: recurringProduct.id,
                                        class: "border-t border-gray-900/10"
                                      }, [
                                        _createElementVNode("div", {
                                          class: _normalizeClass([_ctx.isProductFeatured(recurringProduct) ? 'border-black dark:border-gray-400' : 'border-transparent', '-mt-px w-72 border-t-2 pt-10 md:w-80'])
                                        }, [
                                          _createElementVNode("h3", {
                                            class: _normalizeClass([_ctx.isProductFeatured(recurringProduct) ? 'text-black dark:text-gray-400' : 'text-gray-900 dark:text-gray-400', 'text-sm font-semibold leading-6'])
                                          }, _toDisplayString(recurringProduct.getProductName()), 3),
                                          _createElementVNode("p", _hoisted_26, _toDisplayString(recurringProduct.getProductDescription()), 1)
                                        ], 2),
                                        _createElementVNode("div", _hoisted_27, [
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sections, (section) => {
                                            return (_openBlock(), _createElementBlock("div", {
                                              key: section.name
                                            }, [
                                              _createElementVNode("h4", _hoisted_28, _toDisplayString(section.name), 1),
                                              _createElementVNode("div", _hoisted_29, [
                                                _cache[2] || (_cache[2] = _createElementVNode("div", {
                                                  "aria-hidden": "true",
                                                  class: "absolute inset-y-0 right-0 hidden w-1/2 rounded-lg bg-white dark:bg-gr-dark shadow-sm sm:block"
                                                }, null, -1)),
                                                _createElementVNode("div", {
                                                  class: _normalizeClass([_ctx.isProductFeatured(recurringProduct) ? 'ring-2 ring-black dark:ring-gray-400' : 'ring-1 ring-gray-900/10', 'relative rounded-lg bg-white dark:bg-gr-darker shadow-sm sm:rounded-none sm:bg-transparent sm:shadow-none sm:ring-0'])
                                                }, [
                                                  _createElementVNode("dl", _hoisted_30, [
                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(section.features, (feature) => {
                                                      return (_openBlock(), _createElementBlock("div", {
                                                        key: feature.reference,
                                                        class: "flex items-center justify-between py-3 px-4 sm:grid sm:grid-cols-2 sm:px-0"
                                                      }, [
                                                        _createElementVNode("dt", _hoisted_31, _toDisplayString(recurringProduct?.getProductFeatureByReference(feature.reference)), 1),
                                                        _createElementVNode("dd", _hoisted_32, [
                                                          (typeof feature.tiers[recurringProduct.product_reference] === 'string')
                                                            ? (_openBlock(), _createElementBlock("span", {
                                                                key: 0,
                                                                class: _normalizeClass(_ctx.isProductFeatured(recurringProduct) ? 'font-semibold text-black dark:text-gray-400' : 'text-gray-900 dark:text-gray-500')
                                                              }, _toDisplayString(feature.tiers[recurringProduct.product_reference]), 3))
                                                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                                                (feature.tiers[recurringProduct.product_reference] === true)
                                                                  ? (_openBlock(), _createBlock(_component_CheckIcon, {
                                                                      key: 0,
                                                                      class: "mx-auto h-5 w-5 text-black dark:text-gray-400",
                                                                      "aria-hidden": "true"
                                                                    }))
                                                                  : (_openBlock(), _createBlock(_component_XMarkIcon, {
                                                                      key: 1,
                                                                      class: "mx-auto h-5 w-5 text-gray-400 dark:text-gray-500",
                                                                      "aria-hidden": "true"
                                                                    })),
                                                                _createElementVNode("span", _hoisted_33, _toDisplayString(_ctx.$t('key', {
																										expr: feature.tiers[recurringProduct.product_reference] ===
																										true ? 'Yes' : 'No',
																									})), 1)
                                                              ], 64))
                                                        ])
                                                      ]))
                                                    }), 128))
                                                  ])
                                                ], 2),
                                                _createElementVNode("div", {
                                                  "aria-hidden": "true",
                                                  class: _normalizeClass([_ctx.isProductFeatured(recurringProduct) ? 'ring-2 ring-black dark:ring-gray-400' : 'ring-1 ring-gray-900/10', 'pointer-events-none absolute inset-y-0 right-0 hidden w-1/2 rounded-lg sm:block'])
                                                }, null, 2)
                                              ])
                                            ]))
                                          }), 128))
                                        ])
                                      ]))
                                    }), 128))
                                  ])
                                ]),
                                _createElementVNode("section", _hoisted_34, [
                                  _createElementVNode("h2", _hoisted_35, _toDisplayString(_ctx.$t('featureComparison')), 1),
                                  _createElementVNode("div", _hoisted_36, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getRecurringProducts(), (recurringProduct) => {
                                      return (_openBlock(), _createElementBlock("div", {
                                        key: recurringProduct.id,
                                        "aria-hidden": "true",
                                        class: "-mt-px"
                                      }, [
                                        _createElementVNode("div", {
                                          class: _normalizeClass([_ctx.isProductFeatured(recurringProduct) ? 'border-black dark:border-gray-400' : 'border-transparent', 'border-t-2 pt-10'])
                                        }, [
                                          _createElementVNode("p", {
                                            class: _normalizeClass([_ctx.isProductFeatured(recurringProduct) ? 'text-black dark:text-gray-400' : 'text-gray-900 dark:text-gray-500', 'text-sm font-semibold leading-6'])
                                          }, _toDisplayString(recurringProduct.getProductName()), 3),
                                          _createElementVNode("p", _hoisted_37, _toDisplayString(recurringProduct.getProductDescription()), 1)
                                        ], 2)
                                      ]))
                                    }), 128))
                                  ]),
                                  _createElementVNode("div", _hoisted_38, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sections, (section) => {
                                      return (_openBlock(), _createElementBlock("div", {
                                        key: section.name
                                      }, [
                                        _createElementVNode("h3", _hoisted_39, _toDisplayString(section.name), 1),
                                        _createElementVNode("div", _hoisted_40, [
                                          _cache[4] || (_cache[4] = _createElementVNode("div", {
                                            class: "absolute inset-y-0 inset-x-8 grid grid-cols-4 gap-x-8 before:block",
                                            "aria-hidden": "true"
                                          }, [
                                            _createElementVNode("div", { class: "h-full w-full rounded-lg bg-white dark:bg-gr-dark shadow-sm" }),
                                            _createElementVNode("div", { class: "h-full w-full rounded-lg bg-white dark:bg-gr-dark shadow-sm" }),
                                            _createElementVNode("div", { class: "h-full w-full rounded-lg bg-white dark:bg-gr-dark shadow-sm" })
                                          ], -1)),
                                          _createElementVNode("table", _hoisted_41, [
                                            _createElementVNode("thead", null, [
                                              _createElementVNode("tr", _hoisted_42, [
                                                _createElementVNode("th", _hoisted_43, [
                                                  _createElementVNode("span", _hoisted_44, _toDisplayString(_ctx.$t('feature')), 1)
                                                ]),
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getRecurringProducts(), (recurringProduct) => {
                                                  return (_openBlock(), _createElementBlock("th", {
                                                    key: recurringProduct.id,
                                                    scope: "col"
                                                  }, [
                                                    _createElementVNode("span", _hoisted_45, _toDisplayString(_ctx.$t('tier', {getProductName: recurringProduct.getProductName()})), 1)
                                                  ]))
                                                }), 128))
                                              ])
                                            ]),
                                            _createElementVNode("tbody", null, [
                                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(section.features, (feature, featureIdx) => {
                                                return (_openBlock(), _createElementBlock("tr", {
                                                  key: feature.reference
                                                }, [
                                                  _createElementVNode("th", _hoisted_46, [
                                                    _createTextVNode(_toDisplayString(feature.name) + " ", 1),
                                                    (featureIdx !== section.features.length - 1)
                                                      ? (_openBlock(), _createElementBlock("div", _hoisted_47))
                                                      : _createCommentVNode("", true)
                                                  ]),
                                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getRecurringProducts(), (recurringProduct) => {
                                                    return (_openBlock(), _createElementBlock("td", {
                                                      key: recurringProduct.id,
                                                      class: "relative w-1/4 px-4 py-0 text-center"
                                                    }, [
                                                      _createElementVNode("span", _hoisted_48, [
                                                        (typeof feature.tiers[recurringProduct.product_reference] === 'string')
                                                          ? (_openBlock(), _createElementBlock("span", {
                                                              key: 0,
                                                              class: _normalizeClass([_ctx.isProductFeatured(recurringProduct) ? 'font-semibold text-black dark:text-gray-400' : 'text-gray-900 dark:text-gray-500', 'text-sm leading-6 dark:text-gray-500'])
                                                            }, _toDisplayString(feature.tiers[recurringProduct.product_reference]), 3))
                                                          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                                              (feature.tiers[recurringProduct.product_reference] === true)
                                                                ? (_openBlock(), _createBlock(_component_CheckIcon, {
                                                                    key: 0,
                                                                    class: "mx-auto h-5 w-5 text-black dark:text-gray-500",
                                                                    "aria-hidden": "true"
                                                                  }))
                                                                : (_openBlock(), _createBlock(_component_XMarkIcon, {
                                                                    key: 1,
                                                                    class: "mx-auto h-5 w-5 text-gray-400 dark:text-gray-500",
                                                                    "aria-hidden": "true"
                                                                  })),
                                                              _createElementVNode("span", _hoisted_49, _toDisplayString(_ctx.$t('key', {expr: feature.tiers[recurringProduct.product_reference] === true ? 'Yes' : 'No'})), 1)
                                                            ], 64))
                                                      ])
                                                    ]))
                                                  }), 128))
                                                ]))
                                              }), 128))
                                            ])
                                          ]),
                                          _createElementVNode("div", _hoisted_50, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getRecurringProducts(), (recurringProduct) => {
                                              return (_openBlock(), _createElementBlock("div", {
                                                key: recurringProduct.id,
                                                class: _normalizeClass([_ctx.isProductFeatured(recurringProduct) ? 'ring-2 ring-black dark:ring-gray-500' : 'ring-1 ring-gray-900/10', 'rounded-lg'])
                                              }, null, 2))
                                            }), 128))
                                          ])
                                        ])
                                      ]))
                                    }), 128))
                                  ])
                                ])
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_FooterBar, { class: "mt-12" })
          ], 4)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}