import {
	DiscountItemInterface, DiscountObject, DiscountType,
} from '@/greeve/shop/cart/discount/discount.interface';

export abstract class AbstractDiscountItem implements DiscountItemInterface {
	id: string;
	object: DiscountObject;
	coupon: any;
	name?: string;
	percentOff?: number;
	promotionCode?: string;
	checkoutSession?: string;
	currency?: string;
	customer?: any;
	end?: string;
	invoice?: string;
	invoiceItem?: string;
	start?: number;
	restrictions?: any;
	metaData?: any;

	abstract getObject(): DiscountObject;

	constructor(
			id: string, coupon: any,
			name?: string | undefined, percentOff?: number | undefined,
			promotionCode?: string | undefined, checkoutSession?: string | undefined,
			currency?: string | undefined, customer?: any | undefined,
			end?: string | undefined, invoice?: string | undefined,
			invoiceItem?: string | undefined, start?: number | undefined,
			restrictions?: any | undefined, metaData?: any | undefined) {
		this.id = id;
		this.object = this.getObject();
		this.coupon = coupon;
		this.name = name;
		this.percentOff = percentOff;
		this.promotionCode = promotionCode;
		this.currency = currency;
		this.customer = customer;
		this.end = end;
		this.invoice = invoice;
		this.invoiceItem = invoiceItem;
		this.start = start;
		this.restrictions = restrictions;
		this.metaData = metaData;
	}

	getType(): string {
		return DiscountType.DISCOUNT;
	}

	getDiscountName(): string {
		if (this.promotionCode) {
			return this.promotionCode;
		} else if (this.coupon && this.coupon.name) {
			return this.coupon.name;
		}
		return '';
	}

	getDiscountPercent(): number {
		if (this.percentOff) {
			return this.percentOff;
		} else if (this.coupon.percent_off) {
			return this.coupon.percent_off;
		}
		return 0;
	}
}