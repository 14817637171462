import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full relative z-10 sticky p-0 nav rounded-xl h-full"
}
const _hoisted_2 = { class: "md:mb-6 align-middle rounded-2xl p-[1px] text-md dark:text-gray-400 flex h-full w-full justify-center items-center" }
const _hoisted_3 = { class: "flex select-none" }
const _hoisted_4 = { class: "w-full rounded-xl py-4 md:py-12 md:pb-24 text-center items-center" }
const _hoisted_5 = { class: "md:mt-10 md:mb-6" }
const _hoisted_6 = { class: "text-lg font-extrabold leading-6 md:mb-ß" }
const _hoisted_7 = { class: "mt-1 text-sm md:text-base line-clamp-3" }
const _hoisted_8 = { class: "flex mt-2 gap-2.5 justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.open)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.$t('paywall.title')), 1),
                _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('paywall.description')), 1)
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("button", {
                  type: "button",
                  class: "rounded-xl w-32 dark:bg-white bg-black px-4 py-2 text-sm font-semibold text-white dark:text-black shadow-sm sm:ml-3 outline-none",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.confirm()))
                }, _toDisplayString(_ctx.$t('paywall.button_login')), 1),
                _createElementVNode("button", {
                  type: "button",
                  class: "rounded-xl w-32 dark:bg-white bg-black px-4 py-2 text-sm font-semibold text-white dark:text-black shadow-sm sm:ml-3 outline-none",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.register()))
                }, _toDisplayString(_ctx.$t('paywall.button_register')), 1)
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}