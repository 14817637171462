import {CheckoutSessionInterface} from "@/greeve/shop/checkout/session/checkout_session.interface";
import moment from "moment";

export class CheckoutSession implements CheckoutSessionInterface {
	allow_promotion_code: boolean;
	amount_subtotal: number;
	amount_total: number;
	cancel_url: string;
	created: Date;
	currency: string;
	customer: string;
	expires: Date;
	status: string;
	subscription?: string|null;
	success_url: string;
	total_amount: number;
	total_details: { amount_discount: number; amount_shipping: number; amount_tax: number };
	url: string;
	data?: any;


	constructor(url: string, cancel_url: string, success_url: string, status: string, total_amount: number, total_details: any, amount_total: number, amount_subtotal: number, currency: string, allow_promotion_code: boolean, created: Date, expires: Date, customer: string, subscription: string|null = null, data: any = null) {
		this.url = url;
		this.cancel_url = cancel_url;
		this.success_url = success_url;
		this.status = status;
		this.total_amount = total_amount;
		this.total_details = total_details;
		this.amount_total = amount_total;
		this.amount_subtotal = amount_subtotal;
		this.currency = currency;
		this.customer = customer;
		this.created = created;
		this.expires = expires;
		this.allow_promotion_code = allow_promotion_code;
		this.subscription = subscription;
		this.data = data;
	}

	public isSessionExpired(): boolean
	{
		if (this.expires) {
			const expiresDate = moment(this.expires);
			return expiresDate.isBefore();
		}
		return true;
	}
}