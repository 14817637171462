<template>
	<div class="flex flex-col sm:flex-row sm:items-center sm:justify-between mt-2">
		<div class="mb-2 sm:mb-0">
			<p class="text-sm text-gray-700">
				{{ $t('pagination.showing') }}
				{{ ' ' }}
				<span class="font-medium">{{ paginationMeta.from }}</span>
				{{ ' ' }}
				{{ $t('pagination.to') }}
				{{ ' ' }}
				<span class="font-medium">{{ paginationMeta.to }}</span>
				{{ ' ' }}
				{{ $t('pagination.of') }}
				{{ ' ' }}
				<span class="font-medium">{{ paginationMeta.total }}</span>
				{{ ' ' }}
				{{ $t('pagination.results') }}
			</p>
		</div>
		<div class="flex justify-center sm:justify-end mt-2 sm:mt-0">
			<nav class="flex space-x-2" aria-label="Pagination">
				<a @click.prevent="prevPage" :class="prevPageClass" class="cursor-pointer inline-flex items-center justify-center w-8 h-8 text-gray-400 rounded-full ring-1 ring-gray-300 hover:bg-gray-50 hover:text-gr-dark focus:z-20 focus:outline-offset-0">
					<span class="sr-only">{{$t('pagination.previous')}}</span>
					<ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
				</a>
				<a v-for="page in visiblePages" :key="page" href="#" @click.prevent="goToPage(page)" :class="pageClass(page)" :aria-current="currentPage === page ? 'page' : null" class="relative inline-flex items-center px-4 py-2 text-sm font-semibold focus:z-20 focus-visible:outline focus-visible:outline-2 hover:bg-gray-200 focus-visible:outline-offset-2 focus-visible:outline-gr-primary">
					{{ page }}
				</a>
				<a @click.prevent="nextPage" :class="nextPageClass" class="cursor-pointer inline-flex items-center justify-center w-8 h-8 text-gray-400 rounded-full ring-1 ring-gray-300 hover:bg-gray-50 hover:text-gr-dark focus:z-20 focus:outline-offset-0">
					<span class="sr-only">{{$t('pagination.next')}}</span>
					<ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
				</a>
			</nav>
		</div>
	</div>
</template>

<script setup lang="ts">
import { ref, computed, defineProps, defineEmits, watch } from 'vue';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/20/solid';
import { PaginationMetaInterface } from '@/greeve/core/pagination_meta.interface';

const props = defineProps({
	paginationMeta: {
		type: Object as () => PaginationMetaInterface,
		required: true
	}
});

const emits = defineEmits(['page-change']);

const currentPage = ref(props.paginationMeta.current_page);
const visiblePages = ref<Array<number>>([]);
const paginationMeta = ref<PaginationMetaInterface>(props.paginationMeta);

const prevPageClass = computed(() => ({
	'z-10 bg-gr-primary text-white': currentPage.value > 1,
	'text-gray-400': currentPage.value === 1,
}));

const nextPageClass = computed(() => ({
	'z-10 bg-gr-primary text-white': currentPage.value < paginationMeta.value.last_page,
	'text-gray-400': currentPage.value === paginationMeta.value.last_page,
}));

const pageClass = (page: number) => ({
	'z-10 bg-gr-primary text-white': currentPage.value === page,
	'text-gray-400': currentPage.value !== page,
});

const prevPage = () => {
	if (currentPage.value > 1) {
		currentPage.value--;
		updateVisiblePages();
		emits('page-change', currentPage.value);
	}
};

const nextPage = () => {
	if (currentPage.value < paginationMeta.value.last_page) {
		currentPage.value++;
		updateVisiblePages();
		emits('page-change', currentPage.value);
	}
};

const goToPage = (page: number) => {
	if (page >= 1 && page <= paginationMeta.value.last_page) {
		currentPage.value = page;
		updateVisiblePages();
		emits('page-change', currentPage.value);
	}
};

const updateVisiblePages = () => {
	const totalPages = paginationMeta.value.last_page;
	const currentPageValue = currentPage.value;
	let startPage = Math.max(1, currentPageValue - 2);
	let endPage = Math.min(totalPages, startPage + 4);

	if (totalPages > 5) {
		if (endPage <= 5) {
			endPage = 5;
		} else if (endPage === totalPages) {
			startPage = endPage - 4;
		}
	}

	visiblePages.value = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
};

watch(() => props.paginationMeta, (newVal) => {
	currentPage.value = newVal.current_page;
	paginationMeta.value = newVal;
	updateVisiblePages();
});

updateVisiblePages();

</script>
