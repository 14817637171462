<template>
	<button :class="['items-center shadow-2xl justify-center h-12 lg:h-10 ', centerButton, flexButton + 'cursor-pointer rounded-xl' +' rounded-xl' + 'focus:outline-none focus:shadow-outline ' + ' ' + buttonXMargin + ' ' + buttonXPadding + ' ' + buttonYPadding + ' ' + buttonHover + ' ' + buttonColor + ' ' + textColor + ' ' + buttonWidth + ' ' + customCss, buttonDisabled ? 'disabled:opacity-50' : '']"
			:type="buttonType" @click="clickButton" :disabled="buttonDisabled">{{ buttonText }}
	</button>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
	name: 'DefaultButton',
	components: {},
	props: {
		buttonType: {
			type: String,
			default: "submit"
		},
		buttonText: {
			type: String,
			default: "E-Mail"
		},
		buttonWidth: {
			type: String,
			default: "w-full",
		},
		flexButton: {
			type: String,
			default: "flex ",
		},
		centerButton: {
			type: Boolean,
			default: true,
		},
		buttonColor: {
			type: String,
			default: 'bg-black dark:bg-gr-darker',
		},
		textColor: {
			type: String,
			default: 'text-white',
		},
		buttonHover: {
			type: String,
			default: '',
		},
		buttonXPadding: {
			type: String,
			default: 'px-16',
		},
		buttonXMargin: {
			type: String,
			default: 'mx-auto lg:mx-0',
		},
		buttonYPadding: {
			type: String,
			default: 'py-4',
		},
		buttonDisabled: {
			type: Boolean,
			default: false,
		},
		customCss: {
			type: String,
			default: "",
		},

	},
	emits: ['clickButton'],
	setup(props, {emit}) {

		const clickButton = () => {
			emit('clickButton');
		};

		return {
			clickButton
		};
	}
})
</script>

<style scoped>
.default-btn:focus {
    @apply outline-none
}

.btn-main-color {
    align-items: flex-end;
    background-color: var(--west-side);
}

.text-button1 {
    letter-spacing: 1.78px;
    text-align: center;
    white-space: nowrap;
}
</style>
