import {
	FlowQueueJournalList
} from '@/greeve/flow/queue/journal/flow_queue_journal_list.type';
import {
	FlowQueueProcessList
} from '@/greeve/flow/queue/process/flow_queue_process_list.type';

export const enum FlowQueueState {
	CANCELLED = 'cancelled',
	DELETED = 'deleted',
	DONE = 'done',
	NEW = 'new',
	ERROR = 'error',
	IN_PROGRESS = 'in_progress',
}

export const enum FlowQueueType {
	DEFAULT = 'default',
}

export interface FlowQueueInterface {
	id: number,
	uuid: string,
	flowId: number,
	type: FlowQueueType,
	state: FlowQueueState,
	userId?: number|null,
	next_execution?: Date,
	flow_queue_processes?: FlowQueueProcessList|null,
	flow_queue_journals?: FlowQueueJournalList|null,
	flow_quota_amount?: number|null,
	parent_id?: number|null,
	reference?: string|null,
	name?: string|null,
	description?: string|null,
	result?: any,
	data?: any,
	created_at?: Date,
	updated_at?: Date,
}