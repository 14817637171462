import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "relative mx-auto px-4" }
const _hoisted_2 = { class: "mx-auto mt-2 grid max-w-2xl grid-cols-1 gap-y-20 gap-x-8 lg:mx-0 lg:max-w-none lg:grid-cols-3" }
const _hoisted_3 = { class: "relative w-full" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "max-w-xl" }
const _hoisted_6 = { class: "mt-4 flex items-center gap-x-4 text-xs" }
const _hoisted_7 = ["datetime"]
const _hoisted_8 = { class: "group relative" }
const _hoisted_9 = { class: "mt-3 text-lg font-semibold leading-6 text-gray-900 dark:text-gray-500 group-hover:text-gray-600" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { class: "relative mt-6 flex items-center gap-x-4" }
const _hoisted_12 = ["src"]
const _hoisted_13 = { class: "text-sm leading-6" }
const _hoisted_14 = { class: "font-semibold text-gray-900 dark:text-gray-500" }
const _hoisted_15 = { class: "text-gray-600" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _cache[3] || (_cache[3] = _createElementVNode("h1", null, "Greeve Blog", -1)),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.blogs, (post) => {
        return (_openBlock(), _createElementBlock("article", {
          key: post.id,
          class: "outer-card nav p-4 flex flex-col items-start justify-between"
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_router_link, {
              to: post.link
            }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  src: post.header_banner,
                  alt: "",
                  class: "aspect-[16/9] w-full rounded-xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                }, null, 8, _hoisted_4),
                _cache[0] || (_cache[0] = _createElementVNode("div", { class: "absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" }, null, -1))
              ]),
              _: 2
            }, 1032, ["to"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              (post.created_at)
                ? (_openBlock(), _createElementBlock("time", {
                    key: 0,
                    datetime: post.formattedDate,
                    class: "text-gray-500"
                  }, _toDisplayString(post.formattedDate), 9, _hoisted_7))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(post.categories, (category) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "relative rounded-full bg-gray-50 py-1.5 px-3 font-medium text-gray-600",
                  key: category
                }, _toDisplayString(category), 1))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("h3", _hoisted_9, [
                _createVNode(_component_router_link, {
                  to: post.link
                }, {
                  default: _withCtx(() => [
                    _cache[1] || (_cache[1] = _createElementVNode("span", { class: "absolute inset-0" }, null, -1)),
                    _createTextVNode(" " + _toDisplayString(post.title), 1)
                  ]),
                  _: 2
                }, 1032, ["to"])
              ]),
              _createElementVNode("p", {
                class: "mt-5 text-sm leading-6 text-gray-600 line-clamp-3",
                innerHTML: post.content
              }, null, 8, _hoisted_10)
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("img", {
                src: post.author,
                alt: "",
                class: "h-10 w-10 rounded-full bg-gray-100"
              }, null, 8, _hoisted_12),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("p", _hoisted_14, [
                  _cache[2] || (_cache[2] = _createElementVNode("span", { class: "absolute inset-0" }, null, -1)),
                  _createTextVNode(" " + _toDisplayString(post.author), 1)
                ]),
                _createElementVNode("p", _hoisted_15, _toDisplayString(post.author), 1)
              ])
            ])
          ])
        ]))
      }), 128))
    ])
  ]))
}