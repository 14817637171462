import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "ml-2 text-sm font-medium text-gray-700 dark:text-gray-500 group-hover:text-gray-600" }
const _hoisted_2 = { class: "sr-only" }
const _hoisted_3 = { class: "sr-only" }
const _hoisted_4 = {
  key: 0,
  class: "mx-auto max-w-2xl px-4"
}
const _hoisted_5 = {
  role: "list",
  class: "divide-y divide-gray-200 dark:divide-gray-500"
}
const _hoisted_6 = ["src", "alt"]
const _hoisted_7 = { class: "ml-4 flex-auto" }
const _hoisted_8 = { class: "text-left font-medium text-xs text-gray-900 dark:text-gray-400" }
const _hoisted_9 = ["href"]
const _hoisted_10 = { class: "ml-4 flex w-full" }
const _hoisted_11 = { class: "ion-activatable flex bg-gray-200 dark:bg-gr-dark py-1 rounded-lg mr-2" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "mt-6 text-center" }
const _hoisted_15 = { class: "cursor-pointer w-full rounded-xl border border-transparent py-1.5 px-6 text-sm font-medium shadow-sm focus:outline-none bg-black dark:bg-gr-dark font-semibold text-white dark:text-white" }
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { class: "mt-6 text-center" }
const _hoisted_18 = { class: "mt-6 mx-auto text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ShoppingBagIcon = _resolveComponent("ShoppingBagIcon")!
  const _component_PopoverButton = _resolveComponent("PopoverButton")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_ripple_effect = _resolveComponent("ion-ripple-effect")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_PopoverPanel = _resolveComponent("PopoverPanel")!
  const _component_Popover = _resolveComponent("Popover")!

  return (_openBlock(), _createBlock(_component_Popover, { class: "ml-1 sm:ml-4 flow-root text-sm lg:relative lg:ml-8" }, {
    default: _withCtx(() => [
      _createVNode(_component_PopoverButton, { class: "group flex items-center p-2 px-2 py-1.5 bg-gray-100 hover:bg-gray-200 dark:hover:bg-gr-dark dark:bg-gr-darker rounded-xl outline-none" }, {
        default: _withCtx(() => [
          _createVNode(_component_ShoppingBagIcon, {
            class: _normalizeClass(['h-4 w-4 flex-shrink-0 text-gray-500 group-hover:text-gray-600', _ctx.showCartItemCountChangedAnimation ? 'animate-bounce' : '']),
            "aria-hidden": "true"
          }, null, 8, ["class"]),
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.cart.items && _ctx.cart.items.length > 0 ? _ctx.cart.items.length : 0), 1),
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('itemsInCartViewBag')), 1)
        ]),
        _: 1
      }),
      _createVNode(_Transition, {
        "enter-active-class": "transition ease-out duration-200",
        "enter-from-class": "opacity-0",
        "enter-to-class": "opacity-100",
        "leave-active-class": "transition ease-in duration-150",
        "leave-from-class": "opacity-100",
        "leave-to-class": "opacity-0"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_PopoverPanel, {
            class: _normalizeClass('mt-px bg-white dark:bg-gr-darker pb-6 shadow-lg sm:px-2 rounded-xl' + ' ' + _ctx.popoverPosition + ' ' + _ctx.popoverSize)
          }, {
            default: _withCtx(() => [
              _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t('shoppingCart')), 1),
              (_ctx.cart.items && _ctx.cart.items.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createElementVNode("ul", _hoisted_5, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cart.items, (cartItem) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: cartItem.id,
                          class: "flex items-center py-6"
                        }, [
                          _createElementVNode("img", {
                            loading: "lazy",
                            src: cartItem.getProduct().getDefaultImage(),
                            alt: cartItem.getProduct().getDefaultImage(),
                            class: "h-10 w-10 flex-none rounded-md border bg-white border-gray-200 dark:border-gr-dark"
                          }, null, 8, _hoisted_6),
                          _createElementVNode("div", _hoisted_7, [
                            _createElementVNode("h3", _hoisted_8, [
                              _createElementVNode("a", {
                                href: cartItem.getProduct().detailLink
                              }, _toDisplayString(cartItem.getProduct().description), 9, _hoisted_9)
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_10, [
                            _createElementVNode("div", _hoisted_11, [
                              _createElementVNode("button", {
                                class: "mx-2 grid content-center",
                                onClick: ($event: any) => (_ctx.removeCartItem_by_Id(cartItem.id))
                              }, [
                                true
                                  ? (_openBlock(), _createBlock(_component_ion_icon, {
                                      key: 0,
                                      class: "w-4",
                                      slot: "icon-only",
                                      icon: _ctx.icons.trashOutline
                                    }, null, 8, ["icon"]))
                                  : (_openBlock(), _createBlock(_component_ion_icon, {
                                      key: 1,
                                      slot: "icon-only",
                                      icon: _ctx.icons.removeOutline
                                    }, null, 8, ["icon"])),
                                _createVNode(_component_ion_ripple_effect)
                              ], 8, _hoisted_12),
                              _cache[0] || (_cache[0] = _createElementVNode("input", { class: "bg-gray-50 dark:bg-gr-darker py-2 h-5 w-7 text-center outline-none text-gray-900 dark:text-gray-400" }, null, -1)),
                              _createElementVNode("button", {
                                class: "px-2 py-0 grid content-center",
                                onClick: ($event: any) => (_ctx.addProductToCart(cartItem.id))
                              }, [
                                _createVNode(_component_ion_icon, {
                                  class: "w-4",
                                  slot: "icon-only",
                                  icon: _ctx.icons.addOutline
                                }, null, 8, ["icon"])
                              ], 8, _hoisted_13)
                            ])
                          ])
                        ]))
                      }), 128))
                    ]),
                    _createElementVNode("p", _hoisted_14, [
                      _createVNode(_component_router_link, { to: _ctx.checkoutLink }, {
                        default: _withCtx(() => [
                          _createElementVNode("a", _hoisted_15, _toDisplayString(_ctx.$t('checkout')), 1)
                        ]),
                        _: 1
                      }, 8, ["to"])
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_16, [
                    _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.$t('thereIsNothingInYourBasket')), 1),
                    _createElementVNode("p", _hoisted_18, [
                      _createVNode(_component_router_link, {
                        to: "/services",
                        class: "align-center rounded-xl border border-transparent bg-gr-primary hover:bg-gr-primary3 py-1 px-6 text-base font-medium text-white transition duration-300 ease-in-out"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('viewServices')), 1)
                        ]),
                        _: 1
                      })
                    ])
                  ]))
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}