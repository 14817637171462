import {PaginationMetaInterface} from "@/greeve/core/pagination_meta.interface";
import {
	FlowQueueJournal
} from '@/greeve/flow/queue/journal/flow_queue_journal.type';

export class FlowQueueJournalList extends Array<FlowQueueJournal> {

	public paginationMetaData?: PaginationMetaInterface;

	constructor(items: Array<FlowQueueJournal> = []) {
		super();
		this.push(...items);
	}

	add(items: FlowQueueJournal) {
		this.push(items);
	}

	remove(index: number) {
		this.splice(index, 1);
	}

	removeAll() {
		const itemCount = this.length;
		this.splice(itemCount, 1);
	}

	getItem(index: number): FlowQueueJournal {
		return this[index];
	}

	getItemById(item_id: number): FlowQueueJournal | undefined {
		return this.find(({id}) => id === item_id);
	}

	enhanceMetaData(metaData: any) {
		this.paginationMetaData = metaData;
	}
}