import {PaginationMetaInterface} from '@/greeve/core/pagination_meta.interface';
import {AbstractOAuth} from '@/greeve/user/oauth/abstract_oauth.type';

export class OAuthList extends Array<AbstractOAuth> {

	public paginationMetaData?: PaginationMetaInterface;

	constructor(items: Array<AbstractOAuth> = []) {
		super();
		this.push(...items);
	}

	add(items: AbstractOAuth) {
		this.push(items);
	}

	remove(index: number) {
		this.splice(index, 1);
	}

	removeAll() {
		const itemCount = this.length;
		this.splice(itemCount, 1);
	}

	hasData(): boolean {
		return this.length > 0;
	}

	enhanceMetaData(metaData: any) {
		this.paginationMetaData = metaData;
	}
}