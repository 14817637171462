import {
	SubscriptionInterface,
} from '@/greeve/shop/subscription/subscription.interface';
import {
	SubscriptionItemList,
} from '@/greeve/shop/subscription/subscriptionItem/subscription_item_list.type';
import {Product} from '@/greeve/shop/product/product.type';
import {Price} from '@/greeve/shop/product/price.type';
import useShop from '@/composable/greeve/useShop';

export class Subscription implements SubscriptionInterface {
	id: number;
	user_id: number;
	name: string;
	stripe_id: string;
	stripe_status: string;
	stripe_price: string;
	quantity: number;
	trial_ends_at?: Date;
	items?: SubscriptionItemList;
	attributes?: Record<string, any>;
	ends_at?: Date;
	data?: any;
	created_at?: Date;
	updated_at?: Date;

	private product?: Product;
	private price?: Price;

	constructor(
			id: number, user_id: number, name: string, stripe_id: string,
			stripe_status: string, stripe_price: string, quantity: number,
			trial_ends_at?: Date, items?: SubscriptionItemList, attributes?: any,
			ends_at?: Date, data: any = null, created_at?: Date, updated_at?: Date) {
		this.id = id;
		this.user_id = user_id;
		this.name = name;
		this.stripe_id = stripe_id;
		this.stripe_status = stripe_status;
		this.stripe_price = stripe_price;
		this.quantity = quantity;
		this.trial_ends_at = trial_ends_at;
		this.items = items;
		this.attributes = attributes;
		this.ends_at = ends_at;
		this.data = data;
		this.created_at = created_at;
		this.updated_at = updated_at;
	}

	getProduct(): Product | undefined {
		if (this.product) {
			return this.product;
		}
		if (this.stripe_price) {
			//TODO fix vuex mutation
			this.product = useShop().getProduct_by_PriceId(this.stripe_price);
		}
		return this.product;
	}

	getPrice(): Price | undefined {
		if (this.price) {
			return this.price;
		}
		const price = this.getProduct()?.price;
		if (!price) {
			this.price = useShop().getPrice_by_PriceId(this.stripe_price);
		}
		return this.price;
	}
}