import {
	SubscriptionItemList,
} from '@/greeve/shop/subscription/subscriptionItem/subscription_item_list.type';
import {
	SubscriptionItem,
} from '@/greeve/shop/subscription/subscriptionItem/subscription_item.type';

export class SubscriptionItemFactory {

	/* eslint-disable */

	/**
	 *
	 * @param subscriptionItems
	 */
	createSubscriptionItemListListByResponse(subscriptionItems: Record<string, any>): SubscriptionItemList {
		const subscriptionItemList = new SubscriptionItemList();

		for (const [key, item] of Object.entries(subscriptionItems)) {
			subscriptionItemList.push(this.createSubscriptionItemByResponse(item));
		}

		return subscriptionItemList;
	}

	/**
	 *
	 * @param item
	 */
	createSubscriptionItemByResponse(item: Record<string, any>): SubscriptionItem {
		const id = item.id;
		const subscription_id = item.subscription_id;
		const stripe_id = item.stripe_id;
		const stripe_product = item.stripe_product;
		const stripe_price = item.stripe_price;
		const quantity = item.quantity;
		const data = item.data;
		const created_at = item.created_at ? new Date(item.created_at) : (item.createdAt ? new Date(item.createdAt) : new Date());
		const updated_at = item.updated_at ? new Date(item.updated_at) : (item.updatedAt ? new Date(item.updatedAt) : new Date());

		return new SubscriptionItem(id, subscription_id, stripe_id, stripe_product,
				stripe_price, quantity, data, created_at, updated_at);
	}
}