// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {AxiosError} from "axios";
import {GreeveApiGetResponse, ResponseError} from "@/greeve/api/api.interfaces";
import GreeveApiClient from "@/greeve/api/client";
import {SearchResult} from "@/greeve/search/search_result.type";
import {ProductFactory} from "@/greeve/shop/product/product_factory.type";
import {ProductList} from "@/greeve/shop/product/product_list.type";
import {PriceList} from "@/greeve/shop/product/price_list.type";
import {Cart} from "@/greeve/shop/cart/cart.type";
import {CheckoutSessionFactory} from "@/greeve/shop/checkout/session/checkout_session_factory.type";
import {CheckoutSession} from "@/greeve/shop/checkout/session/checkout_session.type";
import {
    PromoDiscountItem
} from '@/greeve/shop/cart/discount/promo/promo_discount_item.type';
import {
    DiscountItemFactory
} from '@/greeve/shop/cart/discount/discount_item_factory.type';

interface GreeveGetResponse extends GreeveApiGetResponse {
    data: {type: string, result: any};
    status: any;
}

class GreeveResponseError extends ResponseError {
}

const GreeveApiShop = {
    getSetupIntent: async function (): Promise<any|undefined> {
        try {
            const response = await GreeveApiClient.get('/shop/getSetupIntent');
            if (response) {
                const responseData = response.data;
                if (responseData) {
                    return responseData;
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    loadPaymentMethods: async function (): Promise<string|undefined> {
        try {
            const response = await GreeveApiClient.get('/shop/getPaymentMethods');
            console.log(response);
            if (response) {
                const responseData = response.data.data;
                if (responseData) {
                    //TODO save to paymentMethods in Storage
                    return responseData;
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    savePaymentMethod: async function (paymentMethod: string): Promise<any|undefined> {
        try {
            const params:any = {
                'payment_method': paymentMethod,
            };
            const response = await GreeveApiClient.post('/shop/savePaymentMethod', params);
            if (response) {
                const responseData = response.data.data;
                if (responseData) {
                    // this.loadPaymentMethods(); //TODO save to storage
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    deletePaymentMethod: async function (paymentMethodId: string): Promise<SearchResult|undefined> {
        try {
            const params:any = {
                'id': paymentMethodId,
            };
            const response = await GreeveApiClient.post('/shop/removePaymentMethod', params);
            if (response) {
                const responseData = response.data.data;
                if (responseData) {
                    // this.loadPaymentMethods(); //TODO save to storage
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    updateSubscription: async function (plan: string, paymentMethodId: number|string): Promise<boolean|undefined> {
        try {
            const params:any = {
                'plan': plan,
                'payment': paymentMethodId,
            };
            const response = await GreeveApiClient.put('/shop/subscription', params);
            if (response) {
                const responseData = response.data.data;
                if (responseData) {
                    //TODO Well done, subscribed
                    return true;
                }
            }
            return false;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    getAllProducts: async function (): Promise<ProductList|undefined> {
        try {
            const response = await GreeveApiClient.get('/shop/getAllProducts');
            if (response) {
                const responseData = response.data.data;
                if (responseData) {
                    const productFactory = new ProductFactory();
                    return productFactory.createProductListByGroupedResponse(responseData);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    getProducts: async function (): Promise<ProductList|undefined> {
        try {
            const response = await GreeveApiClient.get('/shop/getProducts');
            if (response) {
                const responseData = response.data.data.data;
                if (responseData) {
                    const productFactory = new ProductFactory();
                    return productFactory.createProductListByResponse(responseData);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    getPrices: async function (): Promise<PriceList|undefined> {
        try {
            const response = await GreeveApiClient.get('/shop/getPrices');
            if (response) {
                const responseData = response.data.data.data;
                if (responseData) {
                    const productFactory = new ProductFactory();
                    return productFactory.createPriceListByResponse(responseData);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    getCheckoutSessionByCart: async function (cart: Cart): Promise<CheckoutSession|undefined> {
        try {
            const params:any = {
                'cart': cart,
            };
            const response = await GreeveApiClient.post('/shop/getCheckoutSessionByCart', params);
            if (response) {
                const responseData = response.data.data;
                if (responseData) {
                    //TODO create checkoutSession Object with link and additional data
                    const checkoutSessionFactory = new CheckoutSessionFactory();
                    return checkoutSessionFactory.createCheckoutSessionByResponse(responseData);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                if (error.response.data.error) {
                    throw new ResponseError(
                        error.response.data.error.code,
                        error.response.data.error.message
                    );
                }  else {
                    throw new ResponseError(
                        error.response.status,
                        error.response.error.message
                    );
                }
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    getPromotionDiscountItem: async function (promotionCode: string): Promise<PromoDiscountItem|undefined> {
        try {
            const params:any = {
                'promotionCode': promotionCode,
            };
            const response = await GreeveApiClient.post('/shop/getPromotionDiscount', params);
            if (response) {
                const responseData = response.data.data;
                if (responseData) {
                    const discountItemFactory = new DiscountItemFactory();
                    return discountItemFactory.createDiscountItemByResponse(responseData);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },
}

export {GreeveApiShop, GreeveGetResponse, GreeveResponseError};
