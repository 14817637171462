import { computed, getCurrentInstance } from 'vue';

export const useVModel: any = (props: any, propName: any) => {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const vm = getCurrentInstance().proxy;

	return computed({
		get() {
			return props[propName];
		},
		set(value) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			vm.$emit(`update:${propName}`, value);
		},
	});
};