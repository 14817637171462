import {SearchItemFactory} from '@/greeve/search/item/search_item_factory.type';
import {
	SearchGroupFactory
} from '@/greeve/search/group/search_group_factory.type';

export default function useAssistantFactory() {

	let searchItemFactory: SearchItemFactory | null = null;
	const getSearchItemFactory = (): SearchItemFactory => {
		if (!searchItemFactory) {
			searchItemFactory = new SearchItemFactory();
		}
		return searchItemFactory;
	}

	let searchGroupFactory: SearchGroupFactory | null = null;
	const getSearchGroupFactory = (): SearchGroupFactory => {
		if (!searchGroupFactory) {
			searchGroupFactory = new SearchGroupFactory();
		}
		return searchGroupFactory;
	}

	return {
		getSearchItemFactory,
		getSearchGroupFactory
	}
}