import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "pt-2 pb-2 absolute w-full" }
const _hoisted_2 = {
  key: 0,
  class: "relative w-full h-full"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "mt-2 text-sm text-gray-600 p-4 pt-1 absolute" }
const _hoisted_6 = {
  controls: "",
  class: "w-full h-full absolute"
}
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  key: 1,
  class: "flex flex-col items-center justify-center pt-5 pb-6"
}
const _hoisted_9 = { class: "text-xs text-gray-500 dark:text-gray-400 p-2" }
const _hoisted_10 = ["accept"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_line_blur_animation = _resolveComponent("loading-line-blur-animation")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex items-center justify-center w-full", [_ctx.isDragging ? 'border-blue-500 border-dashed animate-pulse' : '']]),
    onDragover: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"])),
    onDragenter: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleDragEnter && _ctx.handleDragEnter(...args)), ["prevent"])),
    onDragleave: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleDragLeave && _ctx.handleDragLeave(...args)), ["prevent"])),
    onDrop: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleDrop && _ctx.handleDrop(...args)), ["prevent"]))
  }, [
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _withDirectives(_createVNode(_component_loading_line_blur_animation, { class: "absolute h-full w-full" }, null, 512), [
        [_vShow, _ctx.isLoading]
      ])
    ], 512), [
      [_vShow, _ctx.isLoading]
    ]),
    _createElementVNode("label", {
      for: "dropzone-file",
      class: _normalizeClass([_ctx.isLoading ? 'cursor-not-allowed opacity-25' : '', 'flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600'])
    }, [
      (_ctx.previewUrl)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_ctx.isImage(_ctx.selectedFile.type))
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: _ctx.previewUrl,
                  alt: "Preview",
                  class: "object-cover w-full h-full rounded-lg"
                }, null, 8, _hoisted_3))
              : (_ctx.isDocument(_ctx.selectedFile.type))
                ? (_openBlock(), _createElementBlock("embed", {
                    key: 1,
                    src: _ctx.previewUrl,
                    type: "application/pdf",
                    class: "object-cover w-full h-full rounded-lg"
                  }, null, 8, _hoisted_4))
                : (_ctx.isAudio(_ctx.selectedFile.type))
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.selectedFile.name), 1),
                      _createElementVNode("audio", _hoisted_6, [
                        _createElementVNode("source", { src: _ctx.previewUrl }, null, 8, _hoisted_7),
                        _cache[7] || (_cache[7] = _createTextVNode(" Your browser does not support the audio element. "))
                      ])
                    ], 64))
                  : _createCommentVNode("", true),
            _createElementVNode("button", {
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.removeFile && _ctx.removeFile(...args))),
              class: "absolute top-0 right-0 m-2 p-1 bg-red-500 text-white rounded-full hover:bg-red-600 focus:outline-none"
            }, _cache[8] || (_cache[8] = [
              _createElementVNode("svg", {
                xmlns: "http://www.w3.org/2000/svg",
                class: "w-4 h-4",
                fill: "none",
                viewBox: "0 0 24 24",
                stroke: "currentColor"
              }, [
                _createElementVNode("path", {
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  "stroke-width": "2",
                  d: "M6 18L18 6M6 6l12 12"
                })
              ], -1)
            ]))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _cache[9] || (_cache[9] = _createElementVNode("svg", {
              class: "w-8 h-8 mb-4 text-gray-500 dark:text-gray-400",
              "aria-hidden": "true",
              xmlns: "http://www.w3.org/2000/svg",
              fill: "none",
              viewBox: "0 0 20 16"
            }, [
              _createElementVNode("path", {
                stroke: "currentColor",
                "stroke-linecap": "round",
                "stroke-linejoin": "round",
                "stroke-width": "2",
                d: "M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
              })
            ], -1)),
            _cache[10] || (_cache[10] = _createElementVNode("p", { class: "mb-2 text-sm text-gray-500 dark:text-gray-400" }, [
              _createElementVNode("span", { class: "font-semibold" }, "Click to upload"),
              _createTextVNode(" or drag and drop")
            ], -1)),
            _createElementVNode("p", _hoisted_9, "Allowed types: " + _toDisplayString(_ctx.allowedTypes.join(', ')), 1)
          ])),
      _createElementVNode("input", {
        id: "dropzone-file",
        type: "file",
        class: "hidden",
        onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleFileChange && _ctx.handleFileChange(...args))),
        accept: _ctx.uploadInputAcceptList
      }, null, 40, _hoisted_10)
    ], 2),
    (_ctx.previewUrl && _ctx.showUploadButton)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.uploadFileToServer && _ctx.uploadFileToServer(...args))),
          class: "mt-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        }, " Upload "))
      : _createCommentVNode("", true)
  ], 34))
}