<template>
	<div v-show="isVisible" :style="containerStyle" :class="{ 'front': isFront }" ref="container"></div>
</template>

<script>
import {defineComponent, ref, watchEffect} from 'vue';
import lottie from 'lottie-web'

export default defineComponent({
	props: {
		animationData: {
			type: Object,
			required: true
		},
		loop: {
			type: Boolean,
			default: false
		},
		autoplay: {
			type: Boolean,
			default: true
		},
		fullscreen: {
			type: Boolean,
			default: false
		},
		fixed: {
			type: Boolean,
			default: true
		},
		zIndex: {
			type: [Number, String],
			default: 'auto'
		},
		width: {
			type: [String, Number],
			default: '100%'
		},
		height: {
			type: [String, Number],
			default: '100%'
		},
		startTime: {
			type: Number,
			default: 0
		},
		speed: {
			type: Number,
			default: 1
		},
		soundEnabled: {
			type: Boolean,
			default: true
		},
		soundSrc: {
			type: String,
			default: ''
		},
		soundVolume: {
			type: Number,
			default: 1
		}
	},
	emits: ['onComplete'],
	setup(props, {emit}) {
		const container = ref(null)
		let animInstance = null
		const isVisible = ref(true)
		const isFront = props.zIndex !== 'auto' && !isNaN(parseInt(props.zIndex))
		const sound = ref();

		const containerStyle = ref({
			width: props.fullscreen ? '100%' : props.width,
			height: props.fullscreen ? '100%' : props.height,
			position: props.fixed ? 'fixed' : 'relative',
			top: props.fixed ? 0 : 'auto',
			left: props.fixed ? 0 : 'auto',
			zIndex: props.zIndex
		})

		watchEffect(() => {
			if (container.value) {
				const startDelay = props.startTime
				const animationDuration = props.animationData.op / 1000 // Convert duration to seconds
				// eslint-disable-next-line
				const totalStartTime = startDelay + animationDuration;

				setTimeout(() => {
					animInstance = lottie.loadAnimation({
						container: container.value,
						renderer: 'svg',
						loop: props.loop,
						autoplay: props.autoplay,
						animationData: props.animationData,
						audio: props.soundSrc ? { src: props.soundSrc, volume: props.soundVolume } : null
					})

					animInstance.setSpeed(props.speed)

					animInstance.addEventListener('complete', () => {
						emit('onComplete');
						if (!props.loop) {
							isVisible.value = false;
							if (sound.value) {
								sound.value.pause();
								sound.value = null;
							}
						}
					})

					if (props.soundSrc && props.soundEnabled) {
						sound.value = new Audio(props.soundSrc)
						sound.value.play()
					}
				}, startDelay)
			}
		})

		const resizeHandler = () => {
			if (props.fullscreen) {
				animInstance.resize()
			}
		}

		window.addEventListener('resize', resizeHandler)

		return {
			container,
			isVisible,
		  	isFront,
			containerStyle,
		}
	},
	beforeUnmount() {
		window.removeEventListener('resize', this.resizeHandler)
	}
})
</script>

<style scoped>
.front {
    z-index: 9999;
}
</style>
