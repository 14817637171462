// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {AxiosError} from "axios/index";
import GreeveApiClient from "./client";
import useUser from "@/composable/greeve/useUser";
import useUserFactory from "@/composable/greeve/useUserFactory";
import {ResponseError} from "@/greeve/api/api.interfaces";
import {UserAddressList} from "@/greeve/user/address/user_address_list.type";
import {
	SubscriptionList
} from '@/greeve/shop/subscription/subscription_list.type';
import {
	SubscriptionFactory
} from '@/greeve/shop/subscription/subscription_factory.type';
import {UserAddress} from '@/greeve/user/address/user_address.type';
import {QuotaList} from '@/greeve/user/quota/quota_list.type';
import {QuotaFactory} from '@/greeve/user/quota/quota_factory.type';
import {
	QuotaTransactionFactory
} from '@/greeve/user/quota/transaction/quota_transaction_factory.type';
import {
	QuotaTransactionList
} from '@/greeve/user/quota/transaction/quota_transaction_list.type';
import useSystem from '@/composable/core/useSystem';
import {GreeveCoreOptionInterface} from '@/greeve/core/option.interface';

class AuthenticationError extends Error {
	errorCode: any;

	constructor(errorCode: any, message: string | undefined) {
		super(message);
		this.name = this.constructor.name;
		if (message != null) {
			this.message = message;
		}
		this.errorCode = errorCode;
	}
}

class BasicAuthError extends Error {
	errorCode: any;
	errorFields: any;

	constructor(errorCode: any, errorFields: any | undefined, message: string | undefined) {
		super(message);
		this.name = this.constructor.name;
		if (message != null) {
			this.message = message;
		}
		if (errorFields != null) {
			this.errorFields = errorFields;
		}
		this.errorCode = errorCode;
	}
}

export interface UpdateUserDataName {
	first_name: string,
	last_name: string
}

export interface UpdateUserPassword {
	current_password: string,
	password: string,
	password_confirmation: string
}

const GreeveApiProfile = {
	updateName: async function (userData: UpdateUserDataName) {
		try {
			await GreeveApiClient.post('/user/profile/change/name', userData);
			useUser().updateProfileName(userData);
		} catch (error) {
			console.log('response sign error', error)
			this.catchError(error);
		}
	},

	updatePassword: async function (userData: UpdateUserPassword) {
		try {
			console.log(userData);
			const response = await GreeveApiClient.post('/user/profile/change/password', userData);
			console.log("RESPONSE", response)
		} catch (error) {
			console.log('response sign error', error)
			this.catchError(error);
		}
	},

	updateLanguage: async function (language: string) {
		try {
			const params:any = {
				'language': language,
			};
			await GreeveApiClient.post('/user/profile/change/language', params);
			useUser().updateProfileLanguage(language);
		} catch (error) {
			console.log('response sign error', error)
			this.catchError(error);
		}
	},

	downloadUserData: async function () {
		try {
			const response = await GreeveApiClient.get('/user/profile/data/download');
			if (response) {
				console.log(response)
				return response.data.data;
			}
			return null;
		} catch (error) {
			console.log('response sign error', error)
			this.catchError(error);
		}
	},

	getUserAddresses: async function (): Promise<UserAddressList | undefined> {
		try {
			const params: any = {};
			const response = await GreeveApiClient.get('/user/address', params);
			if (response) {
				const responseData = response.data.data;
				if (responseData) {
					return useUserFactory().getUserAddressFactory().createUserAddressListByResponse(responseData);
				}
			}
			return undefined;
		} catch (error: AxiosError | any) {
			if (error.response) {
				throw new ResponseError(
					error.response.status,
					error.response.error.message
				);
			} else if (error.request) {
				console.log(error.request);
			} else {
				console.log('Error', error.message);
			}
		}
	},

	getSubscriptions: async function (): Promise<SubscriptionList|undefined> {
		try {
			const response = await GreeveApiClient.get('/shop/subscription');
			if (response) {
				const responseData = response.data.data;
				if (responseData) {
					const subscriptionFactory = new SubscriptionFactory();
					return subscriptionFactory.createSubscriptionListByResponse(responseData);
				}
			}
			return undefined;
		} catch (error: AxiosError|any) {
			if (error.response) {
				throw new ResponseError(
						error.response.status,
						error.response.error.message
				);
			} else if (error.request) {
				console.log(error.request);
			} else {
				console.log('Error', error.message);
			}
		}
	},

	//TODO check or use subscription id
	cancelSubscriptions: async function (subscriptionName: string) {
		try {
			const response = await GreeveApiClient.post('/shop/subscription/cancel', {"subscription": subscriptionName});
			console.log("RESPONSE", response)
		} catch (error) {
			console.log('response sign error', error)
			this.catchError(error);
		}
	},


	pushUserProfileOptions: async function (options: Array<GreeveCoreOptionInterface>) {
		try {
			const params:any = {
				options: { ...options},
			};
			return GreeveApiClient.post('/user/profile/option', params).then((response) => {
				return response.data.data;
			})
		} catch (error) {
			console.log('response sign error', error)
			this.catchError(error);
		}
	},

	getBillingAddress: async function (): Promise<UserAddress|undefined> {
		try {
			const response = await GreeveApiClient.get('/user/profile/customer/billing_address');
			if (response) {
				const responseData = response.data.data;
				if (responseData) {
					return useUserFactory().getUserAddressFactory().createUserAddressByResponse(responseData);
				}
			}
			return undefined;
		} catch (error: AxiosError|any) {
			if (error.response) {
				throw new ResponseError(
						error.response.status,
						error.response.error.message
				);
			} else if (error.request) {
				console.log(error.request);
			} else {
				console.log('Error', error.message);
			}
		}
	},

	getBillingPortalUrl: async function (): Promise<string|undefined> {
		try {
			const response = await GreeveApiClient.get('/user/profile/customer/billing_portal');
			if (response) {
				const responseData = response.data.data;
				if (responseData) {
					return responseData;
				}
			}
			return undefined;
		} catch (error: AxiosError|any) {
			if (error.response) {
				throw new ResponseError(
						error.response.status,
						error.response.error.message
				);
			} else if (error.request) {
				console.log(error.request);
			} else {
				console.log('Error', error.message);
			}
		}
	},

	updateBillingAddress: async function (billingAddress: UserAddress) {
		try {
			const params:any = {
				'billing_address': billingAddress,
			};
			console.log(params);
			const response = await GreeveApiClient.post('/user/profile/customer/billing_address', params);
			console.log("RESPONSE", response)
		} catch (error) {
			console.log('response sign error', error)
			this.catchError(error);
		}
	},

	getQuotas: async function (): Promise<QuotaList|undefined> {
		try {
			const response = await GreeveApiClient.get('/user/quota');
			if (response) {
				const responseData = response.data.data;
				if (responseData) {
					const quotaFactory = new QuotaFactory();
					return quotaFactory.createQuotaListByResponse(responseData);
				}
			}
			return undefined;
		} catch (error: AxiosError|any) {
			if (error.response) {
				throw new ResponseError(
						error.response.status,
						error.response.error.message
				);
			} else if (error.request) {
				console.log(error.request);
			} else {
				console.log('Error', error.message);
			}
		}
	},

	getQuotaTransactions: async function (quota_uuid: string, page: number): Promise<QuotaTransactionList|undefined> {
		try {
			const path = useSystem().buildRoute('/user/transaction/'+quota_uuid, {'perPage': 10, 'page': page})
			const response = await GreeveApiClient.get(path);
			if (response) {
				const responseData = response.data.data;
				if (responseData) {
					const quotaTransactionFactory = new QuotaTransactionFactory();
					return quotaTransactionFactory.createQuotaTransactionListByResponse(responseData);
				}
			}
			return undefined;
		} catch (error: AxiosError|any) {
			if (error.response) {
				throw new ResponseError(
						error.response.status,
						error.response.error.message
				);
			} else if (error.request) {
				console.log(error.request);
			} else {
				console.log('Error', error.message);
			}
		}
	},

	catchError: function (error: any) {
		let status;
		let description;

		if (error.response === undefined) {
			status = error.message;
			description = error.message;
		} else {
			status = error.response.status;
			description = error.response.data.error_description ?? error.message;
		}

		throw new AuthenticationError(status, description);
	}
}

export {GreeveApiProfile, AuthenticationError, BasicAuthError};
