import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "mx-auto max-w-7xl pb-12" }
const _hoisted_2 = { class: "flex items-baseline justify-between bg-gray-50 dark:bg-gr-dark m-4 mb-0 rounded-xl border-gray-200 px-6 py-2 lg:py-6" }
const _hoisted_3 = { class: "" }
const _hoisted_4 = ["aria-label"]
const _hoisted_5 = {
  role: "list",
  class: "flex items-center space-x-2 md:space-x-4"
}
const _hoisted_6 = { class: "sr-only" }
const _hoisted_7 = { class: "flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeIcon = _resolveComponent("HomeIcon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ChevronRightIcon = _resolveComponent("ChevronRightIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, [
        _createElementVNode("nav", {
          class: "flex",
          "aria-label": _ctx.$t('breadcrumb')
        }, [
          _createElementVNode("ol", _hoisted_5, [
            _createElementVNode("li", null, [
              _createElementVNode("div", null, [
                _createVNode(_component_router_link, {
                  to: "/",
                  class: "text-gray-400 hover:text-gray-500"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_HomeIcon, {
                      class: "h-5 w-5 flex-shrink-0",
                      "aria-hidden": "true"
                    }),
                    _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('home')), 1)
                  ]),
                  _: 1
                })
              ])
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.pages.slice($props.from, $props.till), (page) => {
              return (_openBlock(), _createElementBlock("li", {
                key: page.name
              }, [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_ChevronRightIcon, {
                    class: "h-5 w-5 flex-shrink-0 text-gray-400",
                    "aria-hidden": "true"
                  }),
                  _createVNode(_component_router_link, {
                    to: page.href,
                    class: "ml-2 md:ml-4 text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-gray-700",
                    "aria-current": page.current ? 'page' : undefined
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(page.name), 1)
                    ]),
                    _: 2
                  }, 1032, ["to", "aria-current"])
                ])
              ]))
            }), 128))
          ])
        ], 8, _hoisted_4)
      ])
    ])
  ]))
}