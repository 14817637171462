import {OrderList} from "@/greeve/shop/order/order_list.type";
import {Order} from "@/greeve/shop/order/order.type";
import useOrderFactory from "@/composable/greeve/useOrderFactory";
const {getOrderFactory} = useOrderFactory();

/* eslint-disable @typescript-eslint/no-unused-vars */

const order = {
	namespaced: true,
	state: () => ({
		orders: OrderList,
		lastOrder: Order,
	}),
	mutations: {
		setOrders(state: any, orderList: OrderList) {
			state.orders = orderList;
		},
		setLastOrder(state: any, order: Order) {
			state.lastOrder = order;
		},
	},
	actions: {
		resetOrders({ commit, state, getters }: any) {
			try {
				commit('setOrders', new OrderList());
			} catch (error: any) {
				console.error(error);
			}
		},
	},
	getters: {
		hasOrders: (state: any): boolean => {
			return !state.orders;
		},
		getLastOrder: (state: any): Order => {
			if (!(state.lastOrder instanceof Order) && state.lastOrder) {
				return getOrderFactory().createOrderByResponse(state.lastOrder);
			} else {
				return state.lastOrder;
			}
		},
		getOrders: (state: any): OrderList|undefined => {
			if (state.orders instanceof OrderList) {
				return state.orders;
			} else if (!(state.orders instanceof OrderList)) {
				return getOrderFactory().createOrderListByResponse(state.orders);
			} else {
				return undefined;
			}
		},
		getOrder_by_Id: (state: any, getters: any) => (order_id: string): Order | undefined => {
			const order = getters.getOrders.getOrder_by_Id(order_id);
			if (order) {
				return order;
			}
			return undefined;
		},
	}
}

export default order;