import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "GreeveAd" }
const _hoisted_2 = { class: "p-4 mt-12" }
const _hoisted_3 = { class: "max-w-4xl mb-7 mx-auto font-comfortaa text-stroke text-4xl sm:text-7xl font-bold" }
const _hoisted_4 = { class: "" }
const _hoisted_5 = { class: "text-gr-primary" }
const _hoisted_6 = { class: "box max-w-7xl mx-auto" }
const _hoisted_7 = {
  ref: "videoRefGreeveAd2",
  class: "w-full h-full object-cover rounded-xl object-center",
  playsinline: "",
  autoplay: "",
  muted: "",
  loop: "",
  poster: "https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/videos/greeve_ad_thumbnail.png",
  controls: "",
  src: "https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/videos/greeve_ad.mp4"
}
const _hoisted_8 = { class: "block md:hidden relative" }
const _hoisted_9 = {
  id: "GreeveCollection",
  class: "mt-24 mb-56"
}
const _hoisted_10 = { class: "max-w-7xl mx-8 lg:mx-auto" }
const _hoisted_11 = { class: "max-w-4xl mb-12 mx-auto font-comfortaa text-stroke text-4xl sm:text-7xl font-bold" }
const _hoisted_12 = { class: "" }
const _hoisted_13 = { class: "text-gr-primary" }
const _hoisted_14 = {
  id: "GreeveCollection",
  class: "hidden md:block mt-24 mb-56"
}
const _hoisted_15 = { class: "max-w-7xl mx-8 lg:mx-auto" }
const _hoisted_16 = { class: "max-w-4xl mb-12 mx-auto font-comfortaa text-stroke text-4xl sm:text-7xl font-bold" }
const _hoisted_17 = { class: "" }
const _hoisted_18 = { class: "text-gr-primary" }
const _hoisted_19 = { id: "GreeveFlows" }
const _hoisted_20 = { class: "max-w-7xl mx-8 -mt-12 lg:mx-auto" }
const _hoisted_21 = { class: "max-w-4xl mx-auto font-comfortaa text-stroke text-4xl sm:text-7xl font-bold" }
const _hoisted_22 = { class: "" }
const _hoisted_23 = { class: "text-gr-primary" }
const _hoisted_24 = { class: "mx-4" }
const _hoisted_25 = { class: "p-0 max-w-6xl lg:mx-auto md:mt-12 mb-12" }
const _hoisted_26 = {
  id: "GreeveAdvantages",
  class: "mt-24 mb-56"
}
const _hoisted_27 = { class: "max-w-7xl p-4 font-semibold space-y-16 mx-auto grid lg:grid-cols-2" }
const _hoisted_28 = { class: "order-1 p-4 text-4xl self-center" }
const _hoisted_29 = { class: "font-comfortaa mb-1" }
const _hoisted_30 = { class: "pt-4 text-2xl font-medium" }
const _hoisted_31 = { class: "order-2 shadow-3xl w-full overflow-hidden text-white" }
const _hoisted_32 = {
  ref: "videoRefTalk",
  class: "rounded-2xl",
  autoplay: "",
  playsinline: "",
  muted: "",
  loop: "",
  poster: "https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/videos/talk_thumbnail.png",
  src: "https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/videos/talk.mp4"
}
const _hoisted_33 = { class: "order-5 lg:order-3 shadow-3xl w-full overflow-hidden text-white" }
const _hoisted_34 = {
  ref: "videoRefPresentation",
  class: "rounded-2xl",
  autoplay: "",
  playsinline: "",
  muted: "",
  loop: "",
  poster: "https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/videos/presentation_thumbnail.png",
  src: "https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/videos/presentation.mp4"
}
const _hoisted_35 = { class: "order-4 p-4 text-4xl self-center lg:ml-4" }
const _hoisted_36 = { class: "font-comfortaa mb-1" }
const _hoisted_37 = { class: "pt-4 text-2xl font-medium" }
const _hoisted_38 = { class: "order-5 p-4 text-4xl self-center" }
const _hoisted_39 = { class: "font-comfortaa mb-1" }
const _hoisted_40 = { class: "pt-4 text-2xl font-medium" }
const _hoisted_41 = { class: "order-6 shadow-3xl w-full overflow-hidden text-white" }
const _hoisted_42 = {
  ref: "videoRefWoman",
  class: "rounded-2xl",
  autoplay: "",
  playsinline: "",
  muted: "",
  loop: "",
  poster: "https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/videos/woman_thumbnail.png",
  src: "https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/videos/woman.mp4"
}
const _hoisted_43 = { class: "order-9 lg:order-7 shadow-3xl w-full overflow-hidden text-white" }
const _hoisted_44 = {
  ref: "videoRefSphere",
  class: "rounded-2xl",
  autoplay: "",
  playsinline: "",
  muted: "",
  loop: "",
  poster: "https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/videos/sphere_thumbnail.png",
  src: "https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/videos/sphere.mp4"
}
const _hoisted_45 = { class: "order-8 p-4 text-4xl self-center lg:ml-4" }
const _hoisted_46 = { class: "font-comfortaa mb-1" }
const _hoisted_47 = { class: "pt-4 text-2xl font-medium" }
const _hoisted_48 = {
  id: "GreeveSignUp",
  class: "mt-24 mb-56"
}
const _hoisted_49 = { class: "box max-w-7xl pt-20 pb-28 text-center mx-auto" }
const _hoisted_50 = { class: "font-comfortaa text-stroke text-4xl sm:text-7xl font-bold mb-12" }
const _hoisted_51 = { class: "" }
const _hoisted_52 = { class: "bg-black dark:bg-white font-bold text-3xl px-7 py-2 rounded-3xl mr-2 text-white dark:text-black" }
const _hoisted_53 = { class: "pt-2 text-sm font-bold" }
const _hoisted_54 = {
  id: "GreeveFaQ",
  class: "mt-24 mb-56"
}
const _hoisted_55 = { class: "max-w-7xl text-center mx-4 lg:mx-auto" }
const _hoisted_56 = {
  id: "GreeveCompany",
  class: "mt-24 mb-56"
}
const _hoisted_57 = { class: "max-w-4xl mx-8 lg:mx-auto" }
const _hoisted_58 = { class: "font-comfortaa text-stroke text-4xl sm:text-7xl font-bold" }
const _hoisted_59 = { class: "text-gr-primary" }
const _hoisted_60 = { class: "mx-auto text-xl" }
const _hoisted_61 = { class: "mt-6" }
const _hoisted_62 = { class: "pt-4 font-semibold" }
const _hoisted_63 = { class: "pt-4" }
const _hoisted_64 = { href: "https://www.theconcept-technologies.com" }
const _hoisted_65 = { class: "bg-black dark:bg-white text-2xl mt-6 px-7 py-2 rounded-3xl mr-2 text-white dark:text-black" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BackgroundCarousel = _resolveComponent("BackgroundCarousel")!
  const _component_TemplateGrid = _resolveComponent("TemplateGrid")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_faq_section = _resolveComponent("faq-section")!
  const _component_FooterBar = _resolveComponent("FooterBar")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("main", {
            style: _normalizeStyle({ paddingTop: _ctx.headerHeight + 'px' })
          }, [
            _createElementVNode("section", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('theIntro')), 1),
                  _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('ai')), 1)
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("video", _hoisted_7, null, 512)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", null, [
                _createElementVNode("section", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t('theUltimate')), 1),
                      _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t('ai')), 1),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('collection')), 1)
                    ]),
                    _createVNode(_component_BackgroundCarousel)
                  ])
                ])
              ])
            ]),
            _createElementVNode("section", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t('theUltimate')), 1),
                  _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.$t('ai')), 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('collection')), 1)
                ]),
                _cache[0] || (_cache[0] = _createElementVNode("div", { style: {"-webkit-transform":"translate3d(0px, 0px, 0px)"} }, [
                  _createElementVNode("div", { class: "text-white box max-w-7xl mx-auto" }, [
                    _createElementVNode("figure", { class: "relative h-[25rem] sm:h-[30rem] md:h-[40rem] lg:h-[50rem] mx-4 overflow-hidden" }, [
                      _createElementVNode("img", {
                        src: "https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/images/landing/collection-v2.png",
                        class: "logo absolute w-full max-h-[40em]"
                      })
                    ])
                  ])
                ], -1))
              ])
            ]),
            _createElementVNode("section", _hoisted_19, [
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("div", _hoisted_21, [
                  _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.$t('preBuildWork')), 1),
                  _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.$t('flows')), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_24, [
                _createElementVNode("div", _hoisted_25, [
                  _createVNode(_component_TemplateGrid, { background: "nav hover:bg-gray-50 dark:hover:bg-gr-darker" })
                ])
              ])
            ]),
            _createElementVNode("section", _hoisted_26, [
              _cache[1] || (_cache[1] = _createElementVNode("div", { class: "max-w-7xl text-center mx-auto" }, null, -1)),
              _createElementVNode("div", _hoisted_27, [
                _createElementVNode("div", _hoisted_28, [
                  _createElementVNode("p", _hoisted_29, _toDisplayString(_ctx.$t('createAiWorkflowsForYourCompany')), 1),
                  _createElementVNode("p", _hoisted_30, _toDisplayString(_ctx.$t('letAiDoYourWorkSoYouCanFocusOnImportantThingsOfYou')), 1)
                ]),
                _createElementVNode("div", _hoisted_31, [
                  _createElementVNode("video", _hoisted_32, null, 512)
                ]),
                _createElementVNode("div", _hoisted_33, [
                  _createElementVNode("video", _hoisted_34, null, 512)
                ]),
                _createElementVNode("div", _hoisted_35, [
                  _createElementVNode("p", _hoisted_36, _toDisplayString(_ctx.$t('preBuildWorkflows')), 1),
                  _createElementVNode("p", _hoisted_37, _toDisplayString(_ctx.$t('youCanChooseFromAllreadyBuildUpFlowsAndIntegrateIt')), 1)
                ]),
                _createElementVNode("div", _hoisted_38, [
                  _createElementVNode("p", _hoisted_39, _toDisplayString(_ctx.$t('monetizeYourCreations')), 1),
                  _createElementVNode("p", _hoisted_40, _toDisplayString(_ctx.$t('wellYouAlsoCanBuildUpYourOwnBusinessBasedOnCreatin')), 1)
                ]),
                _createElementVNode("div", _hoisted_41, [
                  _createElementVNode("video", _hoisted_42, null, 512)
                ]),
                _createElementVNode("div", _hoisted_43, [
                  _createElementVNode("video", _hoisted_44, null, 512)
                ]),
                _createElementVNode("div", _hoisted_45, [
                  _createElementVNode("p", _hoisted_46, _toDisplayString(_ctx.$t('weMakeYourCompanyAiReady')), 1),
                  _createElementVNode("p", _hoisted_47, _toDisplayString(_ctx.$t('weAlsoProvideAFullCustomIntegrationOfOurServicesAn')), 1)
                ])
              ])
            ]),
            _createElementVNode("section", _hoisted_48, [
              _createElementVNode("div", _hoisted_49, [
                _createElementVNode("div", _hoisted_50, [
                  _createElementVNode("span", _hoisted_51, _toDisplayString(_ctx.$t('startToday')), 1),
                  _cache[2] || (_cache[2] = _createElementVNode("span", { class: "text-gr-primary" }, null, -1))
                ]),
                _createVNode(_component_router_link, { to: "/pricing" }, {
                  default: _withCtx(() => [
                    _createElementVNode("button", _hoisted_52, _toDisplayString(_ctx.$t('becomePro')), 1)
                  ]),
                  _: 1
                }),
                _createElementVNode("p", _hoisted_53, _toDisplayString(_ctx.$t('get5CreditsForFree')), 1)
              ])
            ]),
            _createElementVNode("section", _hoisted_54, [
              _createElementVNode("div", _hoisted_55, [
                _createVNode(_component_faq_section, { class: "bg-gray-200 dark:bg-gr-dark rounded-xl p-12" })
              ])
            ]),
            _createElementVNode("section", _hoisted_56, [
              _createElementVNode("div", _hoisted_57, [
                _createElementVNode("div", _hoisted_58, [
                  _createElementVNode("span", _hoisted_59, _toDisplayString(_ctx.$t('powered')), 1),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('byTheconceptTechnologiesLlc')), 1)
                ]),
                _createElementVNode("div", _hoisted_60, [
                  _createElementVNode("p", _hoisted_61, _toDisplayString(_ctx.$t('vision.Article1p1')), 1),
                  _createElementVNode("p", _hoisted_62, _toDisplayString(_ctx.$t('company.Article1p2')), 1),
                  _createElementVNode("li", null, _toDisplayString(_ctx.$t('company.Article1li1')), 1),
                  _createElementVNode("li", null, _toDisplayString(_ctx.$t('company.Article1li2')), 1),
                  _createElementVNode("li", null, _toDisplayString(_ctx.$t('company.Article1li3')), 1),
                  _createElementVNode("p", _hoisted_63, _toDisplayString(_ctx.$t('company.Article1p5')), 1)
                ]),
                _createElementVNode("a", _hoisted_64, [
                  _createElementVNode("button", _hoisted_65, _toDisplayString(_ctx.$t('moreAboutUs')), 1)
                ])
              ])
            ]),
            _createVNode(_component_FooterBar, { class: "mt-12" })
          ], 4)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}