<template>
	<ion-page>
		<ion-content>
			<div class="h-full pb-1 px-2 lg:px-4">
				<div
						class="px-2 sm:px-4 lg:gap-x-5 flex-none nav rounded-2xl backdrop-filter backdrop-blur-xl sticky top-[6.5rem]">
					<notify-feature-component feature-type="feature_workflow" :title="$t('feature.workflow.title')"
																		:description="$t('feature.workflow.description')"></notify-feature-component>
				</div>
			</div>

			<FooterBar class="mt-12"></FooterBar>

<!--			<div class="h-full pb-1 px-2 lg:px-0">-->

<!--				<div class="px-2 sm:px-4 lg:gap-x-5 flex-none lg:flex relative h-full">-->
<!--					&lt;!&ndash;				<div class="mx-auto flex w-full max-w-7xl items-start gap-x-8 px-4 py-10 sm:px-6 lg:px-8">&ndash;&gt;-->
<!--					<aside :class="asideClass">-->
<!--						&lt;!&ndash; Left column area &ndash;&gt;-->
<!--						<div class="nav rounded-2xl hidden lg:block backdrop-filter backdrop-blur-xl lg:sticky lg:top-[6.5rem]">-->
<!--							<div class="nav pb-4 rounded-2xl h-auto ">-->
<!--								<div class="p-2 sm:p-4 pb-2 sm:pb-2 lg:pb-2 hidden lg:block text-right">-->
<!--									<button @click="toggleMenu" class="px-2 py-2" data-tip="ausblenden"-->
<!--													:class="[toggleButtonClass, isMenuOpen ? 'tooltip tooltip-right' : '' ]">-->
<!--										<bars-3-icon v-if="isMenuOpen === false"-->
<!--																 class="text-gray-400 dark:text-gray-500 group-hover:text-gray-500 flex-shrink-0 h-6 w-6"/>-->
<!--										<span v-else class="flex text-gray-400 dark:text-gray-500 group-hover:text-gray-500 ">-->
<!--											&lt;!&ndash;											<div class="rounded-2xl px-4 ml-0 mr-6 py-0 border-2 border-gr-dark">create</div>&ndash;&gt;-->
<!--											<bars3-bottom-right-icon class="h-6 w-6"></bars3-bottom-right-icon>-->
<!--										</span>-->
<!--									</button>-->
<!--								</div>-->
<!--								<h1>LEFT SIDE</h1>-->
<!--								<h3>+ Button to add new wizzard</h3>-->
<!--								<h3>ConvertAudio 1</h3>-->
<!--								<h3>Auto Reply E-Mail</h3>-->
<!--								<h3>Instagram-Posts</h3>-->
<!--								<h3>WhatsApp-Messages</h3>-->
<!--							</div>-->
<!--						</div>-->
<!--					</aside>-->

<!--					&lt;!&ndash;					<main class="space-y-6 px-0 w-full">&ndash;&gt;-->
<!--					<main class="flex-1 h-auto w-full absolute lg:relative lg:top-[6.5rem]">-->
<!--						&lt;!&ndash; Main area &ndash;&gt;-->
<!--						<ion-router-outlet :key="$route.fullPath" class="h-auto w-full absolute lg:relative"></ion-router-outlet>-->
<!--						<WizzardComponent v-if="currentWizzard" :wizzard="currentWizzard"></WizzardComponent>-->
<!--					</main>-->

<!--					<aside :class="asideClass">-->
<!--						&lt;!&ndash; Right column area &ndash;&gt;-->
<!--						<div class="nav rounded-2xl hidden lg:block backdrop-filter backdrop-blur-xl lg:sticky lg:top-[6.5rem]">-->
<!--							<div class="nav pb-4 rounded-2xl h-auto ">-->
<!--								<h1>RIGHT SIDE</h1>-->
<!--								<h3>Load next queue</h3>-->
<!--								<h3>show infos about the wizzard</h3>-->
<!--							</div>-->
<!--						</div>-->
<!--					</aside>-->
<!--				</div>-->
<!--			</div>-->
		</ion-content>
	</ion-page>
</template>

<script lang="ts" setup>
import {computed, defineComponent, onMounted, ref} from 'vue';
import {IonContent, IonPage, IonRouterOutlet} from '@ionic/vue';
import {Bars3BottomRightIcon, Bars3Icon} from '@heroicons/vue/20/solid';
import WizzardComponent from '@/components/wizzard/WizzardComponent.vue';
import NotifyFeatureComponent from '@/components/contact/NotifyFeatureComponent.vue';
import FooterBar from '@/components/positions/FooterBar.vue';
import useFlowFactory from '@/composable/greeve/useFlowFactory';
import {FlowSubType} from '@/greeve/flow/flow.interface';

		const {getFlowWizzardFactory} = useFlowFactory();
		const isMenuOpen = ref(false);
		const asideClass = computed(() => (isMenuOpen.value
				? 'z-50 pb-0 lg:pb-4 lg:col-span-auto lg:sticky lg:top-0'
				: 'sticky top-8 hidden w-44 shrink-0 lg:block'));
		// : 'z-50 pb-0 lg:pb-4 lg:col-span-auto lg:sticky lg:top-0'));
		const toggleButtonClass = computed(() => (isMenuOpen.value ? '' : ''));
		const menuClass = computed(() => (isMenuOpen.value ? 'menu-open' : 'menu-close'));

		const currentWizzard = ref();

		const toggleMenu = () => {
			isMenuOpen.value = !isMenuOpen.value;
		};

		onMounted(() => {
			currentWizzard.value = getFlowWizzardFactory().createWizzardByType(FlowSubType.WHATSAPP_DEFAULT_NEWSLETTER);
		})
		//TODO if wizzard selected by url load wizzard or show infos and history
		//TODO getAllWizzardTemplates on + Click
		//TODO show my Templates
</script>

<style scoped>

</style>