import {BlogInterface} from '@/greeve/blog/blog.interface';
import MarkdownParser from '@/greeve/core/markdown_parser';
import {formatDate} from '@/greeve/core/date';

export class Blog implements BlogInterface {
	id: number;
	title: string;
	slug: string;
	content_markdown: string;
	published: boolean;
	language: string;
	header_banner?: string;
	user_id?: number;
	keywords?: any;
	categories?: Array<string>;
	tags?: Array<string>;
	created_at?: Date;
	updated_at?: Date;

	constructor(id: number, title: string, slug: string, content_markdown: string, published: boolean, language: string, header_banner?: string, user_id?: number, categories: Array<string>|undefined = undefined, keywords: any = null, tags: Array<string>|undefined = undefined, created_at?: Date, updated_at?: Date) {
		this.id = id;
		this.title = title;
		this.slug = slug;
		this.content_markdown = content_markdown;
		this.published = published;
		this.language = language;
		this.header_banner = header_banner;
		this.user_id = user_id;
		this.categories = categories;
		this.keywords = keywords;
		this.tags = tags;
		this.created_at = created_at;
		this.updated_at = updated_at;
	}

	private getMarkdownParser(): MarkdownParser {
		return new MarkdownParser();
	}

	public get content(): string {
		return this.getMarkdownParser().markdownToHtml(this.content_markdown);
	}

	public get contentAsText(): string {
		return this.getMarkdownParser().markdownToText(this.content_markdown);
	}

	public get link(): string {
		return `/blog/${this.slug}`
	}

	public get tableOfContents(): Array<{ title: string, level: number, active: boolean }>
	{
		return this.getMarkdownParser().getTableOfContentsByMarkdown(this.content_markdown);
	}

	public get keywordList(): string[] {
		if (!this.keywords) {
			return [];
		}
		return this.keywords.split(',');
	}

	public get author(): string {
		return 'greeve AI';
	}

	public get formattedDate(): string {
		if (this.created_at) {
			return formatDate(this.created_at);
		}
		return '';
	}
}