<template>
	<a href='https://www.paypal.com/donate/?hosted_button_id=WP99FPLM9EWMG'>
		<img loading="lazy" :alt="$t('donateWithPaypal')" src='assets/external/paypal-support.webp' :class="height"/></a>
<!--	<form action="https://www.paypal.com/donate" method="post" target="_top" style='width:300px;' >-->
<!--		<input type="hidden" name="hosted_button_id" value="QGFXJYHYP84AN" />-->
<!--		<input type="image" src="assets/external/paypal-support.webp" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />-->
<!--	</form>-->
</template>

<script>
export default {
	name: "PayPalDonation",
	props: {
		height: {
			type: String,
			default: 'w-72'
		}
	}
}
</script>

<style scoped>
</style>
