import {PaginationMetaInterface} from "@/greeve/core/pagination_meta.interface";
import {FlowMedia} from '@/greeve/flow/media/flow_media.type';

export class FlowMediaList extends Array<FlowMedia> {

	public paginationMetaData?: PaginationMetaInterface;

	constructor(items: Array<FlowMedia> = []) {
		super();
		this.push(...items);
	}

	add(items: FlowMedia) {
		this.push(items);
	}

	remove(index: number) {
		this.splice(index, 1);
	}

	removeAll() {
		const itemCount = this.length;
		this.splice(itemCount, 1);
	}

	getItem(index: number): FlowMedia {
		return this[index];
	}

	getItemById(item_id: number): FlowMedia | undefined {
		return this.find(({id}) => id === item_id);
	}

	getItemByUuId(item_uuid: string): FlowMedia | undefined {
		return this.find(({uuid}) => uuid === item_uuid);
	}

	getItemByFileName(file_name: string): FlowMedia | undefined {
		return this.find(({name}) => file_name.includes(name));
	}

	enhanceMetaData(metaData: any) {
		this.paginationMetaData = metaData;
	}
}