import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, vModelText as _vModelText, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "md:flex" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  id: "contact-alert-message",
  class: "col-span-2"
}
const _hoisted_6 = { class: "rounded-md bg-green-50 p-4 mt-6" }
const _hoisted_7 = { class: "flex" }
const _hoisted_8 = { class: "flex-shrink-0" }
const _hoisted_9 = { class: "ml-3" }
const _hoisted_10 = { class: "text-sm font-medium text-green-800" }
const _hoisted_11 = { class: "ml-auto pl-3" }
const _hoisted_12 = { class: "-mx-1.5 -my-1.5" }
const _hoisted_13 = {
  type: "button",
  class: "inline-flex bg-green-50 rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
}
const _hoisted_14 = { class: "sr-only" }
const _hoisted_15 = { class: "rounded-md bg-red-50 p-4 mt-6" }
const _hoisted_16 = { class: "flex" }
const _hoisted_17 = { class: "flex-shrink-0" }
const _hoisted_18 = { class: "ml-3" }
const _hoisted_19 = { class: "text-sm font-medium text-red-800" }
const _hoisted_20 = { class: "col-span-12 sm:col-span-6" }
const _hoisted_21 = {
  class: "pl-1 block text-sm font-medium text-black dark:text-black",
  for: "first-name"
}
const _hoisted_22 = { class: "mt-1" }
const _hoisted_23 = { class: "col-span-12 sm:col-span-6" }
const _hoisted_24 = {
  class: "pl-1 block text-sm font-medium text-black dark:text-black",
  for: "last-name"
}
const _hoisted_25 = { class: "mt-1" }
const _hoisted_26 = { class: "col-span-12" }
const _hoisted_27 = {
  class: "pl-1 block text-sm font-medium text-black dark:text-black",
  for: "email"
}
const _hoisted_28 = { class: "mt-1" }
const _hoisted_29 = { class: "col-span-12" }
const _hoisted_30 = { class: "flex justify-between" }
const _hoisted_31 = { class: "pl-1 text-black dark:text-black block text-sm font-medium" }
const _hoisted_32 = { class: "mt-1" }
const _hoisted_33 = { class: "text-right col-span-12 pb-4" }
const _hoisted_34 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CheckCircleIcon = _resolveComponent("CheckCircleIcon")!
  const _component_XMarkIcon = _resolveComponent("XMarkIcon")!
  const _component_XCircleIcon = _resolveComponent("XCircleIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.contactCss),
    id: "contact-container"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[5] || (_cache[5] = _createElementVNode("img", {
          loading: "lazy",
          src: "https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/images/landing/3d/officeLuggage.png",
          class: "h-28 mr-8 mt-0",
          alt: "Office Luggage Image"
        }, null, -1)),
        _createElementVNode("div", null, [
          _createElementVNode("h2", {
            class: _normalizeClass(_ctx.contactHeaderCss),
            innerHTML: _ctx.contactHeader
          }, null, 10, _hoisted_3),
          _createElementVNode("p", {
            class: _normalizeClass(_ctx.contactHeaderInfoCss),
            innerHTML: _ctx.contactHeaderInfo
          }, null, 10, _hoisted_4)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _withDirectives(_createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_CheckCircleIcon, {
                  class: "h-5 w-5 text-green-400",
                  "aria-hidden": "true"
                })
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.t('inquiry.FormSuccess')), 1)
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("button", _hoisted_13, [
                    _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.t('inquiry.FormClose')), 1),
                    _createVNode(_component_XMarkIcon, {
                      class: "h-5 w-5",
                      "aria-hidden": "true"
                    })
                  ])
                ])
              ])
            ])
          ])
        ], 512), [
          [_vShow, _ctx.successMessage]
        ]),
        _withDirectives(_createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, [
                _createVNode(_component_XCircleIcon, {
                  class: "h-5 w-5 text-red-400",
                  "aria-hidden": "true"
                })
              ]),
              _createElementVNode("div", _hoisted_18, [
                _createElementVNode("h3", _hoisted_19, _toDisplayString(_ctx.errorMessage), 1)
              ])
            ])
          ])
        ], 512), [
          [_vShow, _ctx.errorMessage]
        ])
      ]),
      _createElementVNode("form", {
        onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.sendContactForm && _ctx.sendContactForm(...args)), ["prevent"])),
        id: "contact-form-container",
        class: "mt-9 grid grid-cols-1 gap-y-6 sm:grid-cols-12 sm:gap-x-8"
      }, [
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("label", _hoisted_21, _toDisplayString(_ctx.t('contact.FormFirstName')) + " *", 1),
          _createElementVNode("div", _hoisted_22, [
            _withDirectives(_createElementVNode("input", {
              id: "first-name",
              autocomplete: "given-name",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.contactData.firstName) = $event)),
              required: "",
              class: "px-4 text-black dark:text-black bg-tc-bglightest h-12 block w-full shadow-sm sm:text-sm focus:ring-tc-blue focus:border-tc-blue border-gray-300 rounded-2xl",
              name: "first-name",
              type: "text"
            }, null, 512), [
              [_vModelText, _ctx.contactData.firstName]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_23, [
          _createElementVNode("label", _hoisted_24, _toDisplayString(_ctx.t('contact.FormLastName')) + " *", 1),
          _createElementVNode("div", _hoisted_25, [
            _withDirectives(_createElementVNode("input", {
              id: "last-name",
              autocomplete: "family-name",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.contactData.lastName) = $event)),
              required: "",
              class: "px-4 text-black dark:text-black bg-tc-bglightest h-12 block w-full shadow-sm sm:text-sm focus:ring-tc-blue focus:border-tc-blue border-gray-300 rounded-2xl",
              name: "last-name",
              type: "text"
            }, null, 512), [
              [_vModelText, _ctx.contactData.lastName]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_26, [
          _createElementVNode("label", _hoisted_27, _toDisplayString(_ctx.t('contact.FormMail')) + " *", 1),
          _createElementVNode("div", _hoisted_28, [
            _withDirectives(_createElementVNode("input", {
              id: "email",
              autocomplete: "email",
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.contactData.email) = $event)),
              required: "",
              class: "px-4 text-black dark:text-black bg-tc-bglightest h-12 block w-full shadow-sm sm:text-sm focus:ring-tc-blue focus:border-tc-blue border-gray-300 rounded-2xl",
              name: "email",
              type: "email"
            }, null, 512), [
              [_vModelText, _ctx.contactData.email]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_29, [
          _createElementVNode("div", _hoisted_30, [
            _createElementVNode("label", _hoisted_31, _toDisplayString(_ctx.t('company.FormMessage')) + " *", 1)
          ]),
          _createElementVNode("div", _hoisted_32, [
            _withDirectives(_createElementVNode("textarea", {
              id: "how-can-we-help",
              "aria-describedby": "how-can-we-help-description",
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.contactData.data.message) = $event)),
              required: "",
              class: "p-4 text-black dark:text-black bg-tc-bglightest block w-full shadow-sm sm:text-sm focus:ring-tc-blue focus:border-tc-blue border-gray-300 rounded-2xl",
              name: "how-can-we-help",
              rows: "4"
            }, null, 512), [
              [_vModelText, _ctx.contactData.data.message]
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_33, [
          _createElementVNode("button", {
            class: "w-full bg-gr-primary text-white tracking-widest font-bold inline-flex justify-center py-3 px-4 border border-transparent shadow-sm text-sm rounded-2xl bg-tc-green hover:bg-tc-blue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-tc-blue",
            type: "submit",
            innerHTML: _ctx.contactSubmitButton
          }, null, 8, _hoisted_34)
        ])
      ], 32)
    ])
  ], 2))
}