import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "w-full" }
const _hoisted_3 = { class: "relative" }
const _hoisted_4 = {
  class: "ml-6 bg-transparent h-5 block text-left text-sm font-medium text-gray-400",
  for: "password"
}
const _hoisted_5 = { class: "" }
const _hoisted_6 = { class: "outer-card rounded-xl overflow-hidden" }
const _hoisted_7 = { class: "absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none" }
const _hoisted_8 = { class: "absolute z-50 inset-y-0 cursor-pointer text-gray-400 right-0 pr-6 pt-5 flex items-center text-sm leading-5" }
const _hoisted_9 = { class: "flex w-full pt-5 px-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ExclamationCircleIcon = _resolveComponent("ExclamationCircleIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.inputPlaceholder), 1),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_ion_input, {
                id: "password",
                "aria-describedby": "email-error",
                "aria-invalid": "true",
                placeholder: _ctx.inputPlaceholder,
                autocomplete: "current-password",
                modelValue: _ctx.passwordState,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.passwordState) = $event)),
                required: _ctx.requiredField,
                type: !_ctx.showPassword ? _ctx.inputType : 'text',
                name: "password",
                class: _normalizeClass([_ctx.errorMessage.length > 0 ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500' : ''])
              }, null, 8, ["placeholder", "modelValue", "required", "type", "class"]),
              _withDirectives(_createElementVNode("div", _hoisted_7, [
                _createVNode(_component_ExclamationCircleIcon, {
                  "aria-hidden": "true",
                  class: "h-5 w-5 text-red-500"
                })
              ], 512), [
                [_vShow, _ctx.errorMessage.length > 0]
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              (_openBlock(), _createElementBlock("svg", {
                class: _normalizeClass({'block h-5 w-5': !_ctx.showPassword, 'hidden': _ctx.showPassword }),
                fill: "none",
                stroke: "currentColor",
                viewBox: "0 0 24 24",
                xmlns: "http://www.w3.org/2000/svg",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showPassword = !_ctx.showPassword))
              }, _cache[3] || (_cache[3] = [
                _createElementVNode("path", {
                  d: "M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  "stroke-width": "2"
                }, null, -1)
              ]), 2)),
              (_openBlock(), _createElementBlock("svg", {
                class: _normalizeClass({'block h-5 w-5': _ctx.showPassword, 'hidden':!_ctx.showPassword }),
                fill: "none",
                stroke: "currentColor",
                viewBox: "0 0 24 24",
                xmlns: "http://www.w3.org/2000/svg",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showPassword = !_ctx.showPassword))
              }, _cache[4] || (_cache[4] = [
                _createElementVNode("path", {
                  d: "M15 12a3 3 0 11-6 0 3 3 0 016 0z",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  "stroke-width": "2"
                }, null, -1),
                _createElementVNode("path", {
                  d: "M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round",
                  "stroke-width": "2"
                }, null, -1)
              ]), 2))
            ])
          ]),
          _withDirectives(_createElementVNode("p", {
            id: "password-error",
            class: "mt-2 text-sm text-red-600"
          }, _toDisplayString(_ctx.errorMessage), 513), [
            [_vShow, _ctx.errorMessage.length > 0]
          ])
        ])
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_9, [
        _createElementVNode("span", {
          class: _normalizeClass([_ctx.currentPassword.length > 5 ? 'bg-green-700' : 'bg-gray-200 dark:bg-gr-darker', "h-2 w-1/3 rounded"])
        }, null, 2),
        _createElementVNode("span", {
          class: _normalizeClass([_ctx.currentPassword.length > 5 && _ctx.currentPassword.match(/[\w]+/) ? 'bg-green-700' : 'bg-gray-200 dark:bg-gr-darker', "h-2 w-1/3 rounded mx-3"])
        }, null, 2),
        _createElementVNode("span", {
          class: _normalizeClass([_ctx.currentPassword.length > 5 && _ctx.currentPassword.match(/[!@#$%^&*(),.?:{}|<>]/) ? 'bg-green-700' : 'bg-gray-200 dark:bg-gr-darker', "h-2 w-1/3 rounded"])
        }, null, 2)
      ], 512), [
        [_vShow, _ctx.showPasswordStrengthoMeter === true]
      ])
    ])
  ]))
}