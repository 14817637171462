import {SearchItemList} from '@/greeve/search/item/search_item_list.type';

export const enum GreeveSearchGroupTypeInterface {
	SEARCH_GROUP_TYPE_DEFAULT = 'default',
}

export const enum GreeveSearchGroupStateInterface {
	SEARCH_GROUP_STATE_ACTIVE = 'active',
	SEARCH_GROUP_STATE_DONE = 'done',
	SEARCH_GROUP_STATE_IN_PROGRESS = 'in_progress',
	SEARCH_GROUP_STATE_NEW = 'new',
	SEARCH_GROUP_STATE_NULLIFY = 'nullify',
}

export interface SearchGroupInterface {
	id: number;
	uuid: string;
	parent_id?: number | null;
	user_id?: number | null;
	type: GreeveSearchGroupTypeInterface;
	state: GreeveSearchGroupStateInterface;
	search_items?: SearchItemList|null;
	reference?: string;
	name?: string;
	is_pinned?: boolean;
	data?: any;
	created_at?: Date;
	updated_at?: Date;
}