import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8" }
const _hoisted_3 = { class: "text-center" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "text-sm font-semibold text-gr-primary uppercase tracking-wide" }
const _hoisted_6 = { class: "mt-2 text-2xl text-gray-900 dark:text-gray-400 tracking-tight" }
const _hoisted_7 = { class: "mt-2 text-base text-gray-500" }
const _hoisted_8 = { class: "mt-7" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: "min-h-full pt-10 pb-12 flex flex-col p-3",
    id: $props.errorId
  }, [
    _createElementVNode("main", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(' ' + $props.errorPadding)
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("img", {
            loading: "lazy",
            class: "mx-auto h-52 mb-4",
            src: $props.errorImage,
            alt: "greeve_logo"
          }, null, 8, _hoisted_4),
          _createElementVNode("p", _hoisted_5, _toDisplayString($props.errorNumber), 1),
          _createElementVNode("h1", _hoisted_6, _toDisplayString($props.errorTitle), 1),
          _createElementVNode("p", _hoisted_7, _toDisplayString($props.errorMessage), 1),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_router_link, {
              to: "/",
              class: "align-center rounded-xl border border-transparent bg-gr-primary hover:bg-gr-primary3 py-1 px-6 text-base font-medium text-white transition duration-300 ease-in-out"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("error.NotFoundComponent.backHomeBtn")), 1)
              ]),
              _: 1
            })
          ])
        ])
      ], 2)
    ])
  ], 8, _hoisted_1))
}