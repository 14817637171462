<template>
		<div class="loader-line1"></div>
</template>

<script>
export default {
	name: "LoadingLineBlurAnimation",
}
</script>

<style lang="scss">

.loader-line1 {
  top: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(90deg, rgba(255,211,0,1) 0%, rgba(255,118,0,1) 34.31%, rgba(246,0,0,1) 100%);
  height: 100px;
  position: fixed;
  filter: blur(100px);}

.loader-line1:before {
  transform: translate3d(0px, 5px, 140px) scale(0.95);
  -webkit-transform: translate3d(0px, 5px, 140px) scale(0.95);
  content: "";
  transition: opacity 0.3s;
  border-radius: inherit;
  position: fixed;
  top: 0;
  left: -50%;
  height: 100px;
  width: 100%;
  background: linear-gradient(90deg, rgba(246,0,0,1) 0%, rgba(255,118,0,1) 34.31%, rgba(255,211,0,1) 100%);
  -webkit-animation: lineAnim 1.5s linear infinite;
  -moz-animation: lineAnim 1.5s linear infinite;
  animation: lineAnim 1.5s linear infinite;
}
@keyframes lineAnim {
  0% {
    left: -100%;
  }
  50% {
    left: 0%;
    width: 80%;
  }
  100% {
    left: 100%;
    width: 100%;
  }
}
</style>
