import {GreeveTypeHelper} from "@/greeve/search/search_result.interface";

export default function useSearch() {

	function getGreeveSearchType(): GreeveTypeHelper
	{
		return new GreeveTypeHelper;
	}

	return {
		getGreeveSearchType
	}
}
