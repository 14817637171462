import {
	QuotaJournalList,
} from '@/greeve/user/quota/journal/quota_journal_list.type';
import {QuotaJournal} from '@/greeve/user/quota/journal/quota_journal.type';
import {
	QuotaJournalState,
	QuotaJournalType,
} from '@/greeve/user/quota/journal/quota_journal.interface';

export class QuotaJournalFactory {
	/**
	 *
	 * @param quotaJournals
	 */
	createQuotaJournalListByResponse(quotaJournals: Record<string, any>): QuotaJournalList {
		const quotaJournalList = new QuotaJournalList();

		if (quotaJournals) {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			for (const [key, item] of Object.entries(quotaJournals)) {
				quotaJournalList.push(this.createQuotaJournalByResponse(item));
			}
		}

		return quotaJournalList;
	}

	/**
	 *
	 * @param item
	 */
	createQuotaJournalByResponse(item: Record<string, any>): QuotaJournal {
		const id = item.id;
		const uuid = item.uuid;
		const quota_id = item.quota_id;
		const flow_id = item.flow_id;
		const type = item.type;
		const state = item.state;
		const reference = item.reference;
		const delta = item.delta;
		const subject = item.subject;
		const description = item.description;
		const data = item.data;
		const created_at = item.created_at ? new Date(item.created_at) : (item.createdAt ? new Date(item.createdAt) : new Date());
		const updated_at = item.updated_at ? new Date(item.updated_at) : (item.updatedAt ? new Date(item.updatedAt) : new Date());

		return new QuotaJournal(id, uuid, quota_id, flow_id, type, state, delta, reference, subject, description, data, created_at, updated_at);
	}

	createEmptyQuotaTransaction(): QuotaJournal {
		return new QuotaJournal(0, '', 0, 0, QuotaJournalType.QUOTA_JOURNAL_TYPE_DEFAULT, QuotaJournalState.QUOTA_JOURNAL_STATE_DONE);
	}
}