<template>
<InfoCard info-key="account-info" img="https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/images/landing/3d/roboticGuy.png" :info="$t('accountDescription')" :title="$t('account2')" class="mt-2 sm:mt-4"></InfoCard>
<AccountManagement></AccountManagement>
</template>

<script lang="ts">
import {defineComponent, onMounted} from 'vue';
import useFlow from '@/composable/greeve/useFlow';
import InfoCard from '@/components/modal/InfoCard.vue';
import AccountManagement from "@/components/shop/profile/AccoutManagement.vue";

export default defineComponent({
	name: 'CreditsPage',
	components: {
		InfoCard,
		AccountManagement,
	},
	setup() {
		const {flowTemplates, initFlowTemplates} = useFlow();

		onMounted(() => {
			initFlowTemplates(true);
		});

		return {flowTemplates};
	},
});

</script>
<style lang="scss">
</style>
