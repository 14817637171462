import {
	FlowQueueProcessDefault
} from '@/greeve/flow/queue/process/type/default/flow_queue_process_default.type';

export const DOWNLOAD_AND_SHOW_RESULT_KEY = 'text_result';

export class FlowQueueProcessDefaultDownloadAndShow extends FlowQueueProcessDefault {
	getResultForView(): string
	{
		let result = '';
		const resultData = this.result;
		if (resultData && resultData[DOWNLOAD_AND_SHOW_RESULT_KEY]) {
			result = resultData[DOWNLOAD_AND_SHOW_RESULT_KEY];
		}

		return result;
	}
}