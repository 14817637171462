<template>
	<div v-if="isOpen"
			 class="w-full relative z-10 sticky -mt-2 md:mt-0 my-4 mb-2 mb-2 sm:mb-4 md:mb-4 p-0 rounded-xl nav backdrop-filter backdrop-blur-3xl">
		<div
				class=" align-middle nav rounded-xl rounded-2xl p-2 sm:p-4 pl-3 sm:pl-5 text-md text-gray-900 dark:text-gray-400">
			<div class="float-right ml-2 mt-0.5" v-if="allowToClose">
				<XMarkIcon class="w-5 cursor-pointer" @click="closeModal"></XMarkIcon>
			</div>
			<div class="flex select-none">
				<div class="flex items-center">
					<img loading="lazy" :src="img" class="ml-0 mr-2 h-20 w-20 lg:h-16 lg:w-16 xl:h-15 xl:w-15" :alt="title"/>
					<div class="mr-2">
						<h3 class="text-lg font-extrabold leading-6">{{ title }}</h3>
						<p class="mt-1 text-sm md:text-base line-clamp-3">{{ info }}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
import {onMounted, ref, defineProps, computed} from 'vue';
import {XMarkIcon} from '@heroicons/vue/24/outline';
import useCustomStore from '@/composable/custom/useCustomStore';
import useUser from '@/composable/greeve/useUser';

const props = defineProps({
	img: {
		type: String,
		default: '',
	},
	title: {
		type: String,
		default: 'title',
	},
	info: {
		type: String,
		default: 'info',
	},
	allowToClose: {
		type: Boolean,
		default: true,
	},
	infoKey: {
		type: String,
		required: true,
	},
	allowedToHidePermanently: {
		type: Boolean,
		default: false,
	},
});

const {getInfoBanner_by_Id, addInfoBanner, updateInfoBannerState} = useCustomStore();
const {user, pushProfileOptions} = useUser();

const isOpen = ref(false);

function closeModal() {
	updateInfoBannerState(props.infoKey, false);
	isOpen.value = false;
	if (props.allowedToHidePermanently) {
		pushBannerVisibleState();
	}
}

const getOptionKey = () => {
	return 'banner-'+props.infoKey;
}

const isCardVisible = () => computed(() => {
	if (!user.value.profile?.getOptions()) {
		return true;
	}
	return user.value.profile?.hasOption(getOptionKey(), true);
});

const pushBannerVisibleState = () => {
	let options = user.value.profile?.getOptions();
	if (!options) {
		options = [];
	}

	options[(getOptionKey())] = isOpen.value;
	pushProfileOptions(options);
}

onMounted(() => {
	const infoBanner = getInfoBanner_by_Id(props.infoKey);
	let showBanner;
	if (!infoBanner) {
		addInfoBanner(props.infoKey);
		showBanner = true;
	} else {
		showBanner = infoBanner.active ?? true;
	}

	if (!showBanner) {
		isOpen.value = false;
		return;
	}

	isOpen.value = showBanner && isCardVisible().value;
});
</script>
