<template>
	<div class="space-y-2 sm:space-y-4">
		<section aria-labelledby="payment-billing-portal-heading">
				<div class="sm:overflow-hidden rounded-xl nav backdrop-filter backdrop-blur-3xl ">
					<div class="nav rounded-t-xl py-6 px-4 sm:p-6">
						<div>
							<h2 id="payment-details-heading"
									class="text-lg font-medium leading-6 text-gray-900 dark:text-gray-500">{{ $t('manage_billing.title') }}</h2>
							<p class="mt-1 text-sm text-gray-600">{{ $t('manage_billing.description') }}</p>
						</div>
					</div>
					<div class="nav rounded-b-xl px-4 py-3 text-right sm:px-6">
						<StripeBillingPortalButton></StripeBillingPortalButton>
					</div>
				</div>
		</section>

		<section aria-labelledby="payment-details-heading">
			<form @submit.prevent="updateBillingAddress">
				<div class="sm:overflow-hidden rounded-xl nav backdrop-filter backdrop-blur-3xl ">
					<div class="nav rounded-t-xl py-6 px-4 sm:p-6">
						<div>
							<h2 id="payment-details-heading"
								class="text-lg font-medium leading-6 text-gray-900 dark:text-gray-500">{{ $t('paymentDetails') }}</h2>
							<p class="mt-1 text-sm text-gray-600">{{ $t('updateYourBillingInformationPleaseNoteThatUpdating') }}</p>
						</div>
						<div v-if="billingAddress" class="mt-6 grid grid-cols-4 gap-6">
							<div class="col-span-4 sm:col-span-2">
								<label for="first-name"
									   class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-600">{{ $t('firstName') }}</label>
								<input type="text" name="first-name" id="first-name" autocomplete="cc-given-name" v-model="billingAddress.first_name"
									   class="mt-2 block w-full rounded-xl border-2 border-gray-200 dark:border-gr-darker px-3 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 sm:text-sm sm:leading-6"/>
							</div>
							<div class="col-span-4 sm:col-span-2">
								<label for="last-name"
									   class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-600">{{ $t('lastName') }}</label>
								<input type="text" name="last-name" id="last-name" autocomplete="cc-family-name" v-model="billingAddress.last_name"
									   class="mt-2 block w-full rounded-xl border-2 border-gray-200 dark:border-gr-darker py-1.5 px-3 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 sm:text-sm sm:leading-6"/>
							</div>
							<div class="col-span-4 sm:col-span-2">
								<label for="Street"
									   class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-600">{{ $t('auth.SetUpAccountInformationComponent.inputStreet') }}</label>
								<input type="text" name="last-name" id="street" autocomplete="cc-family-name" v-model="billingAddress.street"
									   class="mt-2 block w-full rounded-xl border-2 border-gray-200 dark:border-gr-darker py-1.5 px-3 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 sm:text-sm sm:leading-6"/>
							</div>
							<div class="col-span-4 sm:col-span-2">
								<label for="town"
									   class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-600">{{ $t('city') }}</label>
								<input type="text" name="last-name" id="town" autocomplete="cc-family-name" v-model="billingAddress.city"
									   class="mt-2 block w-full rounded-xl border-2 border-gray-200 dark:border-gr-darker py-1.5 px-3 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 sm:text-sm sm:leading-6"/>
							</div>
							<div class="col-span-4 sm:col-span-2">
								<label class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-600">{{ $t('auth.SetUpAccountInformationComponent.inputCountry') }}</label>
<!--									TODO-->
								<select id="country" v-model="billingAddress.country_code"
										class="mt-2 block w-full rounded-xl border-2 border-gray-200 dark:border-gr-darker py-1.5 px-3 text-gray-900 shadow-sm focus:ring-2 focus:ring-inset focus:ring-gray-900 sm:text-sm sm:leading-6">
									<option v-for="country in countries" :key="country.code" :value="country.code">
										{{ country.native }}
									</option>
								</select>
							</div>
							<div class="col-span-4 sm:col-span-2">
								<label for="postal-code"
									   class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-600">{{ $t('zipPostalCode') }}</label>
								<input type="text" name="postal-code" id="postal-code" autocomplete="postal-code" v-model="billingAddress.postal_code"
									   class="mt-2 block w-full rounded-xl border-2 border-gray-200 dark:border-gr-darker py-1.5 px-3 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 sm:text-sm sm:leading-6"/>
							</div>


<!--							<div class="col-span-4 sm:col-span-2">-->
<!--								<label for="billing-email-address"-->
<!--									   class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-600">Billing-->
<!--									Email address</label>-->
<!--								<input type="text" name="email-address" id="billing-email-address" autocomplete="email"-->
<!--									   class="mt-2 block w-full rounded-xl border-2 border-gray-200 dark:border-gr-darker py-1.5 px-3 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 sm:text-sm sm:leading-6"/>-->
<!--							</div>-->
<!--							<div class="col-span-4 sm:col-span-2">-->
<!--								<label for="company-name"-->
<!--									   class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-600">Company-->
<!--									Name</label>-->
<!--								<input type="text" name="email-address" id="company-name" autocomplete="email"-->
<!--									   class="mt-2 block w-full rounded-xl border-2 border-gray-200 dark:border-gr-darker py-1.5 px-3 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 sm:text-sm sm:leading-6"/>-->
<!--							</div>-->
<!--							<div class="col-span-4 sm:col-span-2">-->
<!--								<label for="tax-id"-->
<!--									   class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-600">Tax-->
<!--									ID</label>-->
<!--								<input type="text" name="email-address" id="tax-id" autocomplete="email"-->
<!--									   class="mt-2 block w-full rounded-xl border-2 border-gray-200 dark:border-gr-darker py-1.5 px-3 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 sm:text-sm sm:leading-6"/>-->
<!--							</div>-->

							<!--								<div class="col-span-4 sm:col-span-2">-->
							<!--									<label for="credit-card" class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-600">Credit Card Number</label>-->
							<!--									<input type="text" name="email-address" id="credit-card" autocomplete="email" class="mt-2 block w-full rounded-xl border-2 border-gray-200 dark:border-gr-darker py-1.5 px-3 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 sm:text-sm sm:leading-6" />-->
							<!--								</div>-->
							<!--								-->
							<!--								<div class="col-span-4 sm:col-span-1">-->
							<!--									<label for="expiration-date" class="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-600">Expration date</label>-->
							<!--									<input type="text" name="expiration-date" id="expiration-date" autocomplete="cc-exp" class="mt-2 block w-full rounded-xl border-2 border-gray-200 dark:border-gr-darker py-1.5 px-3 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 sm:text-sm sm:leading-6" placeholder="MM / YY" />-->
							<!--								</div>-->

							<!--								<div class="col-span-4 sm:col-span-1">-->
							<!--									<label for="security-code" class="flex items-center text-sm font-medium leading-6 text-gray-900 dark:text-gray-600">-->
							<!--										<span>Security code</span>-->
							<!--										<QuestionMarkCircleIcon class="ml-1 h-5 w-5 flex-shrink-0 text-gray-300" aria-hidden="true" />-->
							<!--									</label>-->
							<!--									<input type="text" name="security-code" id="security-code" autocomplete="cc-csc" class="mt-2 block w-full rounded-xl border-2 border-gray-200 dark:border-gr-darker py-1.5 px-3 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 sm:text-sm sm:leading-6" />-->
							<!--								</div>-->
						</div>
						<div v-else class="bg-gray-100 dark:bg-gr-dark rounded-2xl p-4 mt-4 w-full mx-auto">
							<div class="animate-pulse flex space-x-4">
								<div class="rounded-full bg-gray-200 dark:bg-gr-darker h-10 w-10"></div>
								<div class="flex-1 space-y-6 py-1">
									<div class="h-2 bg-gray-200 dark:bg-gr-darker rounded"></div>
									<div class="space-y-3">
										<div class="grid grid-cols-3 gap-4">
											<div class="h-2 bg-gray-200 dark:bg-gr-darker rounded col-span-2"></div>
											<div class="h-2 bg-gray-200 dark:bg-gr-darker rounded col-span-1"></div>
										</div>
										<div class="h-2 bg-gray-200 dark:bg-gr-darker rounded"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="nav rounded-b-xl px-4 py-3 text-right sm:px-6">
						<button type="submit"
								class="inline-flex justify-center rounded-xl bg-black dark:bg-gr-darker py-1.5 px-10 text-sm font-semibold text-white shadow-sm focus:outline-none">
							{{ $t('save') }}
						</button>
					</div>
				</div>
			</form>
		</section>

		<!--One Time Purchase-->
<!--		<section aria-labelledby="oneTimePurchase-heading" id="GreeveOneTime" class="mt-24 mb-56"-->
<!--				 v-if="oneTimeProductList">-->
<!--		</section>-->


		<!-- Billing history -->
		<section aria-labelledby="billing-history-heading">
			<div class="nav backdrop-filter backdrop-blur-3xl pt-6  sm:overflow-hidden rounded-xl">
				<div class="px-4 sm:px-6">
					<h2 id="billing-history-heading"
						class="text-lg font-medium leading-6 text-gray-900 dark:text-gray-500">{{ $t('billingHistory') }}</h2>
					<p class="mt-1 text-sm text-gray-500">{{ $t('downloadYourBillingsPleaseNoteThatUpdatingYourLoca') }}</p>

				</div>
				<div class="mt-6 flex flex-col">
					<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							<div class="m-6 mt-0 rounded-xl overflow-hidden border-2 border-gray-200  dark:border-gr-darker">
								<table class="min-w-full divide-y divide-gray-200 dark:divide-gr-dark">
									<thead class="bg-gray-50 dark:bg-gr-dark">
									<tr>
										<th scope="col"
											class="px-6 py-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-400">
											{{ $t('date') }}
										</th>
										<th scope="col"
											class="px-6 py-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-400">
											{{ $t('description') }}
										</th>
										<th scope="col"
											class="px-6 py-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-400">
											{{ $t('amount') }}
										</th>
										<th scope="col"
											class="relative px-6 py-3 text-left text-sm font-medium text-gray-500">
											<span class="sr-only">{{ $t('viewReceipt') }}</span>
										</th>
									</tr>
									</thead>
									<tbody v-if="orders.hasJournalEntries()" class="divide-y divide-gray-200 dark:divide-gr-dark bg-white dark:bg-gr-darker">
										<tr v-for="journal in orders.getJournalList()" :key="journal.id">
											<td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500 dark:text-gray-400">
												<time :datetime="journal.created_at">
													{{ journal.created_at.toLocaleDateString() }}
												</time>
											</td>
											<td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500 dark:text-gray-400">
												{{ journal.getOrder().getProductDescriptionSummary() }}
											</td>
											<td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500 dark:text-gray-400">
												{{ journal.getOrder().total_amount }}
											</td>
											<td class="whitespace-nowrap px-6 py-4 text-right text-sm font-medium">
												<a :href="journal.getInvoiceLink()"
													 class="text-gr-primary hover:text-gr-primary">View
													receipt</a>
											</td>
										</tr>
									</tbody>

								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script lang="ts">
import {ref, defineComponent, onBeforeMount} from 'vue';
import useTranslation from '@/composable/core/useTranslation';
import useOrder from '@/composable/greeve/useOrder';
import {GreeveApiProfile} from '@/greeve/api/profile';
import useUser from '@/composable/greeve/useUser';
import {UserAddress} from '@/greeve/user/address/user_address.type';
import useAlert from '@/composable/core/useAlert';
import useCountry from '@/composable/core/useCountry';
import UseUserFactory from '@/composable/greeve/useUserFactory';
import StripeBillingPortalButton from '@/components/shop/profile/Stripe/StripeBillingPortalButton.vue';

export default defineComponent({
	name: 'SubscriptionManagement',
	components: {
		StripeBillingPortalButton,
	},
	props: {},
	emits: [],
	setup() {
		const {getBillingAddress, initBillingAddress} = useUser();
		const {getUserAddressFactory} = UseUserFactory();
		const {orders, initOrders} = useOrder();
		const {countries} = useCountry();
		const isLoading = ref(false);
		const billingAddress: UserAddress|any = ref();
		const {addSuccess, addError} = useAlert();
		const {t} = useTranslation();

		async function updateBillingAddress() {
			//TODO loading message
			try {
				isLoading.value = true;
				if (!billingAddress.value) {
					addError({title: t('auth.auth_error_failed_title'), message: t('auth.failed'), active: true});
					return;
				}
				console.log(billingAddress.value);
				await GreeveApiProfile.updateBillingAddress(billingAddress.value);
				const success = {
					id: '',
					title: 'Your Billing Address has been updated!',
					message: '', //TODO
					active: true,
				};
				addSuccess(success);
				initBillingAddress(true);
			} catch (e) {
				//TODO define error message
				addError({title: t('auth.auth_error_failed_title'), message: t('auth.failed'), active: true});
			} finally {
				isLoading.value = false;
			}
		}

		onBeforeMount(() => {
			try {
				initBillingAddress(true).then((response) => {
					if (response) {
						billingAddress.value = getUserAddressFactory().createUserAddressByResponse(getBillingAddress.value);
					}
				});
				initOrders(true); //TODO make an api endpoint to check if something changed !!!
			} catch (e) {
				console.error(e);
			}
		});

		return {
			orders,
			billingAddress,
			updateBillingAddress,
			countries,
		};
	},
});
</script>

<style scoped>

</style>
