<template>
	<div>
		<nav :aria-label="$t('tabs')" :class="wrapper">
			<a v-for="tab in currentTabs" :key="tab.name" :aria-current="tab.current ? 'page' : undefined" :class="tab.current ? styleInactive : styleActive" :style="customStyle" @click="triggerTab(tab)">
				<span class="">
					<component :is="tab.icon" :class="[tab.current ? 'text-gr-primary' : 'text-gray-400 group-hover:text-gray-500', '-ml-0.5 mr-2 h-5 w-5']" aria-hidden="true" />
				{{ tab.name }}
				</span>
			</a>
		</nav>
	</div>
</template>

<script lang="ts">

import router from "@/router";
import {useRoute} from 'vue-router'
import {defineComponent, onMounted, ref, watch} from 'vue';

export interface TabInterface {
	name: string;
	link: string;
	value?: string;
	//eslint-disable-next-line
	icon?: any|undefined,
	data?: any|undefined;
	current: boolean;
}

export default defineComponent({
	name: 'SwitchComponent',
	components: {},
	props: {
		redirectDirectly: {
			type: Boolean,
			default: true,
		},
		wrapper: {
			type: String,
			default: 'justify-center -mb-px flex space-x-8 p-2 break-all',
		},
		styleInactive: {
			type: String,
			default: 'cursor-pointer border-bp-light text-bp-light whitespace-nowrap font-medium border-b-2 text-center',
		},
		styleActive: {
			type: String,
			default: 'cursor-pointer whitespace-nowrap font-medium border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 text-center',
		},
		customStyle: {
			type: String,
			default: 'min-width: 5em;min-height: 2.5em;',
		},
		tabs: {
			type: Array as () => Array<TabInterface>,
			default: () => [],
		},
	},
	emits: ['triggerTab'],
	setup(props, {emit}) {

		const currentTabs = ref<Array<TabInterface>>(props.tabs);
		const route = useRoute();

		function checkActiveTab() {
			currentTabs.value.map(function (x) {
				x.current = x.link.toString().trim() === route.path.toString().trim();
				return x;
			});
		}

		/**
		 *
		 * @param tab
		 */
		async function redirectToLink(tab: TabInterface) {
			currentTabs.value.map(function (x) {
				x.current = false;
				return x;
			});
			tab.current = true;
			await router.push(tab.link.toString().trim());
		}

		function triggerTab(tab: TabInterface) {
			if (props.redirectDirectly) {
				return redirectToLink(tab);
			} else {
				emit('triggerTab', tab);
			}
		}

		onMounted(() => {
			checkActiveTab();
		})

		// eslint-disable-next-line
		watch(route, (route, prevRoute) => {
			checkActiveTab();
		})

		return {
			currentTabs, triggerTab
		}
	},
	methods: {}
})
</script>

<style>
</style>
