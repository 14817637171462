// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function authMiddleware({next, to, from, store}) {
	const toPath = to.path;
	const authRoutes = ['/login', '/register', '/forgotPassword', '/user/email/verify', '/user/password/reset', '/profile'];

	const isAuthenticating = store.getters['auth/authenticating'];
	const isTokenLifeTimeExpired = store.getters['auth/isTokenLifeTimeExpired'];
	const authRouteExists = authRoutes.some(route => toPath.startsWith(route));

	if (!authRouteExists) {
		return next();
	}

	if (isAuthenticating && isTokenLifeTimeExpired && authRouteExists) {
		return next();
	} else if((!isAuthenticating || isTokenLifeTimeExpired) && authRouteExists) {
		return next('/');
	} else if((!isAuthenticating || isTokenLifeTimeExpired) && !authRouteExists) {
		return next();
	}
	return next();
}
