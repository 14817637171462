<template>
	<div class="min-h-full pt-10 pb-12 flex flex-col p-3" :id="errorId">
		<main class="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
			<div :class="' ' + errorPadding">
				<div class="text-center">
					<img loading="lazy" class="mx-auto h-52 mb-4" :src="errorImage" alt="greeve_logo">
					<p class="text-sm font-semibold text-gr-primary uppercase tracking-wide">{{ errorNumber }}</p>
					<h1 class="mt-2 text-2xl text-gray-900 dark:text-gray-400 tracking-tight">{{ errorTitle }}</h1>
					<p class="mt-2 text-base text-gray-500">{{ errorMessage }}</p>
					<div class="mt-7">
						<router-link to="/"
									 class="align-center rounded-xl border border-transparent bg-gr-primary hover:bg-gr-primary3 py-1 px-6 text-base font-medium text-white transition duration-300 ease-in-out">
							{{ $t("error.NotFoundComponent.backHomeBtn") }}
						</router-link>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script lang="ts">

export default {
	name: "ErrorComponent",
	components: {},
	props: {
		errorImage: {
			type: String,
			default: 'assets/mascot/heidee-oh.webp'
		},
		errorId: {
			type: String,
			default: ''
		},
		errorNumber: {
			type: String,
			default: ''
		},
		errorPadding: {
			type: String,
			default: 'py-16'
		},
		errorTitle: {
			type: String,
			default: ''
		},
		errorMessage: {
			type: String,
			default: ''
		}
	},
	setup() {
		return {};
	}
}
</script>

<style scoped>

</style>
