<template>
	<div v-if="headerGradient === true" class="header-gradient">
	</div>
	<div v-else>
	</div>
	<div class="relative z-10 sticky">
		<Popover id="navbar-popover" class="m-2 sm:m-4 top-4 backdrop-filter rounded-xl backdrop-blur-xl nav animated justify-between items-center transition-all duration-200 ">
			<div class="nav rounded-xl">
				<div class="mx-auto rounded-3xl max-w-7xl px-4 md:px-6 lg:px-8" :aria-label="$t('top')">
					<div class=" flex w-full items-center justify-between py-4">
						<div class="flex items-center">
							<HeaderLogo :scroll-to-top="true" redirect-link="/services" :pro="false" :beta="false"
										:small-logo="true" custom-class="cursor-pointer mt-0 h-8 w-auto">
							</HeaderLogo>
						</div>
						<div class="lg:hidden space-x-4 flex items-center">
							<credits-nav-cart></credits-nav-cart>
								<PopoverButton v-if="popoverButton" ref="popoverButton" @click="popoverButton = false"
											   class="inline-flex items-center justify-center rounded-xl bg-gray-100 dark:bg-gr-darker p-2 text-gray-400 hover:bg-gray-100 dark:hover:bg-gr-dark hover:text-gray-500 focus:outline-none">
									<span class="sr-only">{{ $t('closeMenu') }}</span>
									<XMarkIcon class="h-6 w-6" aria-hidden="true"/>
								</PopoverButton>
							<PopoverButton v-else @click="popoverButton = true"
										   class="inline-flex items-center justify-center rounded-xl bg-gray-100 dark:bg-gr-darker p-2 text-gray-400 hover:bg-gray-100 dark:hover:bg-gr-dark hover:text-gray-500 focus:outline-none">
								<span class="sr-only">{{ $t('openMenu') }}</span>
								<Bars3Icon class="h-6 w-6" aria-hidden="true"/>
							</PopoverButton>
						</div>
						<div class="hidden lg:block flex items-center">
							<div class="hidden flex-important space-x-8 flex lg:block">
								<router-link v-for="link in navigation.filter((nav) => {return nav.visible;})"
											 :key="link.name" :to="link.href"
											 :class="['transition-all duration-300', link.active ? 'font-bold rounded-xl bg-gray-100 dark:bg-gr-darker hover:bg-gray-200 dark:hover:bg-gr-dark transition duration-300 ease-in-out text-base text-xl text-gray-500 dark:text-gray-400' : '', 'text-base truncate font-medium text-xl text-gray-500 dark:text-gray-400']">
								<span class="inline-flex items-center px-6 p-2 font-bold">
									<component :is="link.icon" class="ml-0 mr-2 h-4 w-4" aria-hidden="true"/>
								{{ link.name }}</span>
								</router-link>
							</div>
						</div>
						<div class="hidden lg:block">
							<div class="justify-end items-end inline-flex items-center font-medium">
								<credits-nav-cart></credits-nav-cart>
								<router-link :to="isAuthenticated ? '/profile/account' : '/auth?redirect=profile/account'"
											 class="inline-block rounded-3xl border border-transparent py-1 px-6 text-base font-medium text-gray-500  hover:text-gray-400">
									<UserCircleIcon v-if="!user.profile?.profile_picture" class="h-7 w-47" aria-hidden="true"></UserCircleIcon>
									<img v-else class="inline-block h-7 w-47 rounded-full" :alt="user.name" :src="user.profile.profile_picture"/>
								</router-link>
<!--								TODO add route to selected Tab-->
								<router-link to="/register" v-if="!isAuthenticated"
											 class="inline-block truncate rounded-xl border border-transparent bg-gr-primary hover:bg-gr-primary3 py-1 px-6 text-base font-bold text-white transition duration-300 ease-in-out">
									{{ $t('signUp') }}
								</router-link>
								<router-link to="/auth" v-else
											 class="inline-block truncate rounded-xl border border-transparent py-1 pl-0 px-6 text-base font-bold text-gray-500 dark:text-gray-400 transition duration-300 ease-in-out">
									{{ $t('logout') }}
								</router-link>
								<div class="">
								</div>
								<div class="justify-end items-end">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<transition enter-active-class="duration-200 ease-out" enter-from-class="opacity-0 scale-95"
						enter-to-class="opacity-100 scale-100" leave-active-class="duration-100 ease-in"
						leave-from-class="opacity-100 scale-100" leave-to-class="opacity-0 scale-95">
				<PopoverPanel class="absolute inset-x-0 top-20 origin-top-bottom transform p-0 transition lg:hidden" >
					<div class="rounded-2xl bg-white dark:bg-gr-darkest shadow-lg" >
						<div class="py-4 px-4">
							<div class="grid grid-cols-1 gap-6 my-4">
								<router-link v-for="link in getVisibleNavigation()" :key="link.name" :to="link.href"
											 :class="['transition-all duration-300 text-center text-xl font-medium', link.active ? 'font-bold rounded-xl bg-gray-100 dark:bg-gr-darker hover:bg-gray-200 dark:hover:bg-gr-dark transition duration-300 ease-in-out text-base text-xl text-gray-500 dark:text-gray-400' : '', 'text-base truncate font-medium text-xl text-gray-500 dark:text-gray-400']">
								<span class="inline-flex items-center px-2  py-2 font-medium" v-if="link.visible">
									<component :is="link.icon" class="ml-0 mr-2 h-4 w-4" aria-hidden="true"/>
								{{ link.name }}</span>
								</router-link>
								<router-link :to="isAuthenticated ? '/profile/account' : '/auth?redirect=profile/account'" exact-active-class="font-bold rounded-xl bg-gray-100 dark:bg-gr-darker hover:bg-gray-200 dark:hover:bg-gr-dark transition duration-300 ease-in-out text-base text-xl text-gray-500 dark:text-gray-400"
											  :class="['transition-all duration-300 text-center text-xl font-medium truncate text-gray-500 dark:text-gray-400']">
								<span class="inline-flex items-center px-2  py-2 font-medium">
									<span class="sr-only">{{ $t('profileName') }}</span>
<!--									TODO check if user has profile picture than show image profile picutre as circleIcon with tailwind instead of UserCircleIcon-->

									<UserCircleIcon v-if="!user.profile?.profile_picture" class="ml-0 mr-2 h-4 w-4" aria-hidden="true"></UserCircleIcon>
									<img v-else class="ml-0 mr-2 inline-block h-7 w-47 rounded-full" :alt="user.name" :src="user.profile?.profile_picture"/>
<!--									<img v-else :src="user.avatarUrl" class="ml-0 mr-2 h-4 w-4" :alt="user.name">-->
								</span>
								</router-link>
							</div>
						</div>

						<div class="p-4 pt-0">
							<div v-if="!isAuthenticated">
								<router-link to="/auth"
											 class="flex w-full items-center justify-center rounded-xl border border-transparent bg-gr-primary px-4 py-2 text-base font-medium text-white shadow-sm ">
									{{ $t('signUp') }}
								</router-link>
								<p class="mt-6 mb-2 text-center text-base font-medium text-gray-500">
									{{ $t('existingCustomer', {expr: ' '}) }}
									<router-link to="/auth" class="text-gr-primary">{{ $t('signIn') }}</router-link>
								</p>
							</div>
							<div v-else>
								<router-link to="/auth"
											 class="flex w-full items-center justify-center rounded-xl border border-transparent px-4 py-2 text-base font-medium text-gray-700 dark:text-gray-400">
									{{ $t('logout') }}
								</router-link>
							</div>
						</div>
					</div>
				</PopoverPanel>
			</transition>
		</Popover>
	</div>


</template>

<script lang="ts">
import {defineComponent, HTMLAttributes, onMounted, ref, watch} from 'vue';
import {Popover, PopoverButton, PopoverGroup, PopoverPanel, TransitionRoot} from '@headlessui/vue';
import { UserCircleIcon} from '@heroicons/vue/24/outline';
import {CreditCardIcon, CubeIcon, ChatBubbleLeftIcon} from '@heroicons/vue/20/solid';
import {
	ClipboardDocumentListIcon,
	SwatchIcon,
	Bars3Icon,
	MicrophoneIcon,
	PhoneIcon,
	PlayIcon,
	MagnifyingGlassIcon,
	SparklesIcon,
	XMarkIcon,
} from '@heroicons/vue/20/solid';
import {IonButton, IonIcon, IonRippleEffect} from '@ionic/vue';
import HeaderLogo from '@/components/branding/HeaderLogo.vue';
import CheckoutNavCountry from '@/components/shop/checkout/nav/CheckoutNavCountry.vue';
import {useRoute} from 'vue-router';

interface LinkItem {
	name: string;
	icon: HTMLElement | HTMLAttributes | any;
	href: string;
	active: boolean;
	visible: boolean;
	onlyAuth?: boolean;
}

import CreditsNavCart from '@/components/shop/checkout/nav/CreditsNavCart.vue';
import useAuth from '@/composable/auth/useAuth';
import useTranslation from "@/composable/core/useTranslation";
import useUser from '@/composable/greeve/useUser';

export default defineComponent({
	name: 'NavbarPro',
	components: {
			TransitionRoot,
		ChatBubbleLeftIcon,
		CubeIcon,
		CreditsNavCart,
		CheckoutNavCountry,
		HeaderLogo,
		CreditCardIcon,
		SparklesIcon,
		PlayIcon,
		MagnifyingGlassIcon,
		PhoneIcon,
		MicrophoneIcon,
		ClipboardDocumentListIcon,
		Bars3Icon,
		XMarkIcon,
		UserCircleIcon,
		SwatchIcon,
		PopoverGroup,
		Popover,
		PopoverButton,
		PopoverPanel,
		IonIcon,
		IonButton,
		IonRippleEffect,
	},
	props: {
		link: {
			type: String,
			default: '',
		},
		headerGradient: {
			type: Boolean,
			default: false,
		},
		routeName: {
			type: String,
			default: '',
		},
		activeRouteList: {
			type: Array,
			default: () => [],
		},
	},
	setup(props) {
		const {t} = useTranslation();
		const {isAuthenticated} = useAuth();
		const {user} = useUser();
		const route = useRoute();
		const redirectLink = ref(props.link);
		const activeRoute = ref();
		const popoverButton: any = ref(false);
		const popoverHover = ref(false as any);
		const popoverTimeout = ref(null as any);
		const navigation: Array<LinkItem> | any = ref([
			{
				name: t('Workflow'),
			  	icon: SparklesIcon,
			  	href: '/flow',
			  	active: false,
			  	visible: true
			},
			{
				name: t('aiEngine'),
				icon: ChatBubbleLeftIcon,
				href: '/assistant',
				active: false,
				visible: true,
			},
			{
				name: t('pricing'),
				icon: CreditCardIcon,
				href: '/pricing',
				active: false,
				visible: !isAuthenticated.value,
				onlyAuth: false,
			},
			{
				name: t("individual"),
				icon: CubeIcon,
				href: '/enterprise',
				active: false,
				visible: isAuthenticated.value,
				onlyAuth: true,
			},
		]);
		const navigationGroup = ref([
			{name: t("service"), icon: SwatchIcon, href: '/services', active: false},
		]);

		const callsToAction = [
			{name: t("watchDemo"), nameShort: t("watchDemoShort"), href: '/workflow', icon: PlayIcon},
			{name: t("viewAllProducts"), nameShort: t("viewAllProductsShort"), href: '/services', icon: SwatchIcon},
			{name: t("contactSales"), nameShort: t("contactSalesShort"), href: '/support/contact', icon: PhoneIcon},
		];

		const ServiceLinks = [
			{
				name: t('GreeveWhisperSync'),
				icon: MicrophoneIcon,
				href: '/workflow/demo/whisper',
				description: t('VoiceInterviews'),
			},
			{
				name: t('GreeveAnamnesis'),
				icon: ClipboardDocumentListIcon,
				href: '/workflow/demo/anamnesis',
				description: t('AnamnesisAs'),
			},
			// {name: t('GreeveSeo'), icon: ClipboardDocumentListIcon, href: '#', description:  t('CreateSeo'),},
		];

		function isNavbarPopoverVisible(): boolean {
			const element = document.getElementById('navbar-popover');

			if (element) {
				const style = window.getComputedStyle(element);
				return style.display !== 'none' && style.visibility !== 'hidden';
			}

			return false;
		}

		function closeMenuAfterRedirect() {
			if (popoverButton.value) {
				try {
					popoverButton.value.el.click();
				} catch (e) {
					console.error(e);
				}
			} else if (popoverButton.value === null) {
				if (isNavbarPopoverVisible()) {
					popoverButton.value = false;
				}
			}
		}

		const hoverPopover = (e: any, open: any) => {
			popoverHover.value = true;
			if (!open) e.target.click();
		};

		function getVisibleNavigation() {
			return navigation.value.filter((nav: LinkItem) => {
				return nav.visible;
			});
		}

		function updateActiveRouteState(): void {
			let foundItem: any = null;
			if (props.activeRouteList && props.activeRouteList.length > 0) {
				const routeLink = props.activeRouteList.find((routePath) => routePath === route.path);
				foundItem = navigation.value.find((navItem: LinkItem) => navItem.href === routeLink);
			}

			if (!foundItem && navigation.value) {
				foundItem = navigation.value.find((navItem: LinkItem) => {
					let routeFirstPart = route.path.split('/')[1];
					return navItem.href.split('/')[1] === routeFirstPart;
				});
			}

			if (!foundItem && navigationGroup.value) {
				foundItem = navigationGroup.value.find((navGroupItem) => navGroupItem.href === route.path);
			}


			navigation.value.forEach((navItem: LinkItem) => {
				navItem.active = false;
			});
			navigationGroup.value.forEach((navItem) => {
				navItem.active = false;
			});

			if (foundItem) {
				foundItem.active = true;
			}
		}

		function isRouteActive(): boolean {
			if (props.routeName.length > 0 && !props.activeRouteList?.length) {
				return route.name === props.routeName;
			} else if (props.activeRouteList && props.activeRouteList.length > 0) {
				return props.activeRouteList.some((routePath) => routePath == route.path);
			} else if (navigation.value) {
				return navigation.value.some((navItem: LinkItem) => navItem.href == route.path);
			} else if (navigationGroup.value) {
				return navigationGroup.value.some((navGroupItem) => navGroupItem.href == route.path);
			} else {
				return route.path === props.link;
			}
		}

		const closePopover = (close: any) => {
			popoverHover.value = false;
			if (popoverTimeout.value) clearTimeout(popoverHover.value);
			popoverTimeout.value = setTimeout(() => {
				if (!popoverHover.value) close();
			}, 10);
		};

		onMounted(() => {
			updateActiveRouteState();
			activeRoute.value = isRouteActive();
		});

		// eslint-disable-next-line
		watch(route, (route, prevRoute) => {
			updateActiveRouteState();
			activeRoute.value = isRouteActive();
			closeMenuAfterRedirect();
		});

		watch(isAuthenticated, (newValue) => {
			navigation.value.forEach((item: LinkItem) => {
				if (item.onlyAuth && newValue) {
					item.visible = true;
				} else if (item.onlyAuth !== undefined && item.onlyAuth && !newValue) {
					item.visible = false;
				} else if (item.onlyAuth !== undefined && !item.onlyAuth && newValue) {
					item.visible = false;
				} else {
					item.visible = true;
				}
			});
		});

		return {
			getVisibleNavigation,
			callsToAction,
			navigation,
			navigationGroup,
			ServiceLinks,
			hoverPopover,
			closePopover,
			popoverHover,
			popoverTimeout,
			close,
			t,
			redirectLink,
			activeRoute,
			isAuthenticated,
			popoverButton,
			user,
		};
	},
});
</script>
<style>
.flex-important {
    display: flex !important;
}

.header-gradient {
    z-index: -1;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 45em;
    left: 0;
    background: linear-gradient(90deg, rgba(255, 211, 0, 1) 0%, rgba(255, 118, 0, 1) 34.31%, rgba(246, 0, 0, 1) 100%);
    transform: translate3d(0px, 5px, 0px) scale(0.95);
    -webkit-transform: translate3d(0px, 5px, 0px) scale(0.95);
    filter: blur(100px);
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    border-radius: inherit;
}
</style>
