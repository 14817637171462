import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, withCtx as _withCtx, Transition as _Transition, Fragment as _Fragment, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = {
  key: 0,
  class: "flex-shrink-0 h-8 w-8 md:h-10 md:w-10 rounded-full bg-gray-200 dark:bg-gr-darkest mb-10"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "grid text-gray-700 grid-cols-2" }
const _hoisted_5 = { class: "col-1 text-left text-md truncate" }
const _hoisted_6 = { class: "col-1 text-right text-md" }
const _hoisted_7 = { class: "flex" }
const _hoisted_8 = { class: "flex items-center flex-col justify-center py-2" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "" }
const _hoisted_11 = { class: "-mx-2 text-gray-400 -mt-3 md:-mt-1.5 -my-1.5" }
const _hoisted_12 = { class: "self-center" }
const _hoisted_13 = { class: "col-1" }
const _hoisted_14 = { class: "self-center" }
const _hoisted_15 = { class: "col-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserCircleIcon = _resolveComponent("UserCircleIcon")!
  const _component_ArrowPathIcon = _resolveComponent("ArrowPathIcon")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_MenuButton = _resolveComponent("MenuButton")!
  const _component_RatingAction = _resolveComponent("RatingAction")!
  const _component_MenuItem = _resolveComponent("MenuItem")!
  const _component_MenuItems = _resolveComponent("MenuItems")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_ShareAction = _resolveComponent("ShareAction")!
  const _component_InteractionAction = _resolveComponent("InteractionAction")!
  const _component_category_input = _resolveComponent("category-input")!

  return (_openBlock(), _createElementBlock("div", {
    class: "space-x-3 rounded-3xl pb-1 md:pb-6 px-2 md:px-4",
    id: _ctx.cardReference
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["flex items-end mt-3", [_ctx.reverseSide ? 'flex-row-reverse' : '']])
    }, [
      (!_ctx.reverseSide)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[1] || (_cache[1] = [
            _createElementVNode("img", {
              class: "p-2",
              src: "assets/icon.png"
            }, null, -1)
          ])))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_UserCircleIcon, {
              class: "flex-shrink-0 text-gray-300 dark:text-gray-400 h-8 w-8 md:h-10 md:w-10 rounded-full overflow-hidden mb-2.5 md:mb-3",
              "aria-hidden": "true"
            })
          ])),
      _renderSlot(_ctx.$slots, "CardContainer", {}, () => [
        _withDirectives(_createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.cardTitle), 1),
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.cardDate), 1)
        ], 512), [
          [_vShow, _ctx.cardInfo]
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(['max-w-[88%] sm:max-w-xl lg:max-w-3xl xl:max-w-4xl', _ctx.cardState === 'error' ? 'min-w-[60%]' : '', _ctx.reverseSide ? 'mr-1 md:mr-3' : 'ml-2 md:ml-4']),
          ref: "contentDiv"
        }, [
          (((!_ctx.cardContentNew || _ctx.cardContentNew.length === 0) && _ctx.cardState === 'new'))
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(['rounded-2xl py-1 px-2 md:p-3', _ctx.reverseSide ? 'bg-gr-primary text-white rounded-br-sm' : 'bg-gray-200 text-gray-700 rounded-bl-sm'])
              }, _cache[2] || (_cache[2] = [
                _createStaticVNode("<div class=\"flex items-center justify-center py-2\"><div class=\"flex space-x-2\"><span class=\"w-2 h-2 bg-gray-500 rounded-full animate-loader\"></span><span class=\"w-2 h-2 bg-gray-500 rounded-full animate-loader animation-delay-200\"></span><span class=\"w-2 h-2 bg-gray-500 rounded-full animate-loader animation-delay-400\"></span></div></div>", 1)
              ]), 2))
            : (_ctx.cardState === 'error')
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: _normalizeClass(['rounded-2xl py-1 px-2 md:p-3 border-2 border-rose-600 bg-red-600 bg-opacity-95', _ctx.reverseSide ? 'rounded-br-sm  text-gray-200 dark:text-gray-200' : 'text-gray-200 dark:text-gray-200 rounded-bl-sm'])
                }, [
                  _createElementVNode("div", _hoisted_7, [
                    _cache[3] || (_cache[3] = _createElementVNode("span", { class: "inline-flex items-center rounded-full bg-red-50 px-1.5 py-0.5 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10 mr-2" }, "Error", -1)),
                    _renderSlot(_ctx.$slots, "CardContent")
                  ])
                ], 2))
              : (_ctx.cardState === 'in_progress')
                ? (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: _normalizeClass(['rounded-2xl py-1 px-2 md:p-3', _ctx.reverseSide ? 'bg-gr-primary text-white rounded-br-sm' : 'bg-gray-200 text-gray-700 rounded-bl-sm'])
                  }, [
                    _createElementVNode("div", _hoisted_8, [
                      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "flex space-x-2 my-1" }, [
                        _createElementVNode("span", { class: "w-2 h-2 bg-gray-500 rounded-full animate-loader" }),
                        _createElementVNode("span", { class: "w-2 h-2 bg-gray-500 rounded-full animate-loader animation-delay-200" }),
                        _createElementVNode("span", { class: "w-2 h-2 bg-gray-500 rounded-full animate-loader animation-delay-400" })
                      ], -1)),
                      _createElementVNode("span", {
                        class: "inline-flex items-center my-2 mx-2 rounded-full bg-red-50 px-2 py-2 text-xs font-medium text-orange-700 ring-1 ring-inset ring-orange-600/10",
                        innerHTML: _ctx.getInProgressMessage()
                      }, null, 8, _hoisted_9),
                      _renderSlot(_ctx.$slots, "CardContent")
                    ])
                  ], 2))
                : (_openBlock(), _createElementBlock("div", {
                    key: 3,
                    class: _normalizeClass(['rounded-2xl py-1 px-2 md:p-3', _ctx.reverseSide ? 'bg-gray-300 dark:bg-gr-dark rounded-br-sm  text-gray-700 dark:text-gray-400' : 'bg-gray-200 dark:bg-gr-darker text-gray-700 dark:text-gray-400 rounded-bl-sm'])
                  }, [
                    _renderSlot(_ctx.$slots, "CardContent")
                  ], 2)),
          _createElementVNode("div", {
            class: _normalizeClass(["mt-3 mb-1", [_ctx.reverseSide ? 'items-start' : '']])
          }, [
            _withDirectives(_createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", {
                  class: _normalizeClass(["flex space-x-1 text-left", [_ctx.reverseSide ? 'flex-row' : 'flex-row-reverse']])
                }, [
                  (_ctx.cardState === 'error')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createElementVNode("div", null, [
                          _createElementVNode("button", {
                            type: "button",
                            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.retryErrorItem && _ctx.retryErrorItem(...args))),
                            class: "rounded-md px-2 py-1.5 text-sm font-medium dark:text-gray-400 focus:outline-none hover:text-gray-600"
                          }, [
                            _createVNode(_component_ArrowPathIcon, { class: "w-6 h-6 mx-auto" })
                          ])
                        ]),
                        _createVNode(_component_Menu, {
                          as: "div",
                          class: "relative text-left self-center"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_12, [
                              _createVNode(_component_MenuButton, { class: "group flex justify-center font-medium text-gray-400 hover:text-gray-600" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, {
                                    class: "px-0 text-2xl",
                                    icon: _ctx.icons.ellipsisHorizontalCircleOutline
                                  }, null, 8, ["icon"])
                                ]),
                                _: 1
                              })
                            ]),
                            _createVNode(_Transition, {
                              "enter-active-class": "transition ease-out duration-100",
                              "enter-from-class": "transform opacity-0 scale-95",
                              "enter-to-class": "transform opacity-100 scale-100",
                              "leave-active-class": "transition ease-in duration-75",
                              "leave-from-class": "transform opacity-100 scale-100",
                              "leave-to-class": "transform opacity-0 scale-95"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_MenuItems, { class: "overflow-hidden absolute right-0 top-0 bottom-auto z-10 w-20 -mt-10 origin-top-right rounded-xl bg-white dark:bg-gr-dark shadow-xl focus:outline-none backdrop-filter backdrop-blur-xl bg-rgba-white-90" }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", null, [
                                      _createVNode(_component_MenuItem, null, {
                                        default: _withCtx(() => [
                                          _createElementVNode("div", _hoisted_13, [
                                            _createVNode(_component_RatingAction, {
                                              onRate: _ctx.rateSearchResult,
                                              "rated-result": _ctx.searchResultRated,
                                              rated: _ctx.resultRated
                                            }, null, 8, ["onRate", "rated-result", "rated"])
                                          ])
                                        ]),
                                        _: 1
                                      })
                                    ])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ], 64))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createVNode(_component_ShareAction, {
                          reference: _ctx.cardReference,
                          description: _ctx.cardContentNew,
                          "share-link": _ctx.shareLink,
                          "share-mode": _ctx.shareMode,
                          "search-item": _ctx.searchItem
                        }, null, 8, ["reference", "description", "share-link", "share-mode", "search-item"]),
                        _createVNode(_component_InteractionAction, {
                          reference: _ctx.cardReference,
                          description: _ctx.cardContentNew,
                          "share-link": _ctx.shareLink,
                          "share-mode": _ctx.shareMode,
                          "is-book-marked": _ctx.isPinned,
                          onBookMark: _ctx.pinSearchItem
                        }, null, 8, ["reference", "description", "share-link", "share-mode", "is-book-marked", "onBookMark"]),
                        _createVNode(_component_Menu, {
                          as: "div",
                          class: "relative text-left self-center"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_14, [
                              _createVNode(_component_MenuButton, { class: "group flex justify-center font-medium text-gray-400 hover:text-gray-600" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, {
                                    class: "px-0 text-2xl",
                                    icon: _ctx.icons.ellipsisHorizontalCircleOutline
                                  }, null, 8, ["icon"])
                                ]),
                                _: 1
                              })
                            ]),
                            _createVNode(_Transition, {
                              "enter-active-class": "transition ease-out duration-100",
                              "enter-from-class": "transform opacity-0 scale-95",
                              "enter-to-class": "transform opacity-100 scale-100",
                              "leave-active-class": "transition ease-in duration-75",
                              "leave-from-class": "transform opacity-100 scale-100",
                              "leave-to-class": "transform opacity-0 scale-95"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_MenuItems, { class: "overflow-hidden absolute right-0 top-0 bottom-auto z-10 w-20 -mt-10 origin-top-right rounded-xl bg-white dark:bg-gr-dark shadow-xl focus:outline-none backdrop-filter backdrop-blur-xl bg-rgba-white-90" }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", null, [
                                      _createVNode(_component_MenuItem, null, {
                                        default: _withCtx(() => [
                                          _createElementVNode("div", _hoisted_15, [
                                            _createVNode(_component_RatingAction, {
                                              onRate: _ctx.rateSearchResult,
                                              "rated-result": _ctx.searchResultRated,
                                              rated: _ctx.resultRated
                                            }, null, 8, ["onRate", "rated-result", "rated"])
                                          ])
                                        ]),
                                        _: 1
                                      })
                                    ])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ], 64))
                ], 2),
                _createVNode(_Transition, null, {
                  default: _withCtx(() => [
                    _withDirectives(_createVNode(_component_category_input, {
                      onClickCategory: _ctx.searchGreeveByType,
                      "search-result": _ctx.searchItem,
                      class: "col-span-3"
                    }, null, 8, ["onClickCategory", "search-result"]), [
                      [_vShow, _ctx.categoryVisibleState]
                    ])
                  ]),
                  _: 1
                })
              ])
            ], 512), [
              [_vShow, _ctx.cardInteraction]
            ])
          ], 2)
        ], 2)
      ])
    ], 2)
  ], 8, _hoisted_1))
}