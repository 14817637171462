import {
	SearchItemDefault
} from '@/greeve/search/item/type/search_item_default.type';
import useSystem from '@/composable/core/useSystem';


export const enum AudioVoice {
	ALLOY = 'alloy',
	ECHO = 'echo',
	FABLE = 'fable',
	ONYX = 'onyx',
	NOVA = 'nova',
	SHIMMER = 'shimmer',
}

export class SearchItemTextToSpeech extends SearchItemDefault{
	public getContent()
	{
		const content = this.response?.audio_url ?? this.request?.file;
		if (!content) {
			return '';
		}
		if (!useSystem().fileStartsWithHttps(content)) {
			return this.request?.file;
		}
		return content;
	}
}