import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col sm:flex-row sm:items-center sm:justify-between mt-2" }
const _hoisted_2 = { class: "mb-2 sm:mb-0" }
const _hoisted_3 = { class: "text-sm text-gray-700" }
const _hoisted_4 = { class: "font-medium" }
const _hoisted_5 = { class: "font-medium" }
const _hoisted_6 = { class: "font-medium" }
const _hoisted_7 = { class: "flex justify-center sm:justify-end mt-2 sm:mt-0" }
const _hoisted_8 = {
  class: "flex space-x-2",
  "aria-label": "Pagination"
}
const _hoisted_9 = { class: "sr-only" }
const _hoisted_10 = ["onClick", "aria-current"]
const _hoisted_11 = { class: "sr-only" }

import { ref, computed, watch } from 'vue';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/20/solid';
import { PaginationMetaInterface } from '@/greeve/core/pagination_meta.interface';


export default /*@__PURE__*/_defineComponent({
  __name: 'DefaultPagination',
  props: {
	paginationMeta: {
		type: Object as () => PaginationMetaInterface,
		required: true
	}
},
  emits: ['page-change'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emits = __emit;

const currentPage = ref(props.paginationMeta.current_page);
const visiblePages = ref<Array<number>>([]);
const paginationMeta = ref<PaginationMetaInterface>(props.paginationMeta);

const prevPageClass = computed(() => ({
	'z-10 bg-gr-primary text-white': currentPage.value > 1,
	'text-gray-400': currentPage.value === 1,
}));

const nextPageClass = computed(() => ({
	'z-10 bg-gr-primary text-white': currentPage.value < paginationMeta.value.last_page,
	'text-gray-400': currentPage.value === paginationMeta.value.last_page,
}));

const pageClass = (page: number) => ({
	'z-10 bg-gr-primary text-white': currentPage.value === page,
	'text-gray-400': currentPage.value !== page,
});

const prevPage = () => {
	if (currentPage.value > 1) {
		currentPage.value--;
		updateVisiblePages();
		emits('page-change', currentPage.value);
	}
};

const nextPage = () => {
	if (currentPage.value < paginationMeta.value.last_page) {
		currentPage.value++;
		updateVisiblePages();
		emits('page-change', currentPage.value);
	}
};

const goToPage = (page: number) => {
	if (page >= 1 && page <= paginationMeta.value.last_page) {
		currentPage.value = page;
		updateVisiblePages();
		emits('page-change', currentPage.value);
	}
};

const updateVisiblePages = () => {
	const totalPages = paginationMeta.value.last_page;
	const currentPageValue = currentPage.value;
	let startPage = Math.max(1, currentPageValue - 2);
	let endPage = Math.min(totalPages, startPage + 4);

	if (totalPages > 5) {
		if (endPage <= 5) {
			endPage = 5;
		} else if (endPage === totalPages) {
			startPage = endPage - 4;
		}
	}

	visiblePages.value = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
};

watch(() => props.paginationMeta, (newVal) => {
	currentPage.value = newVal.current_page;
	paginationMeta.value = newVal;
	updateVisiblePages();
});

updateVisiblePages();


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, [
        _createTextVNode(_toDisplayString(_ctx.$t('pagination.showing')) + " " + _toDisplayString(' ') + " ", 1),
        _createElementVNode("span", _hoisted_4, _toDisplayString(paginationMeta.value.from), 1),
        _createTextVNode(" " + _toDisplayString(' ') + " " + _toDisplayString(_ctx.$t('pagination.to')) + " " + _toDisplayString(' ') + " ", 1),
        _createElementVNode("span", _hoisted_5, _toDisplayString(paginationMeta.value.to), 1),
        _createTextVNode(" " + _toDisplayString(' ') + " " + _toDisplayString(_ctx.$t('pagination.of')) + " " + _toDisplayString(' ') + " ", 1),
        _createElementVNode("span", _hoisted_6, _toDisplayString(paginationMeta.value.total), 1),
        _createTextVNode(" " + _toDisplayString(' ') + " " + _toDisplayString(_ctx.$t('pagination.results')), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("nav", _hoisted_8, [
        _createElementVNode("a", {
          onClick: _withModifiers(prevPage, ["prevent"]),
          class: _normalizeClass([prevPageClass.value, "cursor-pointer inline-flex items-center justify-center w-8 h-8 text-gray-400 rounded-full ring-1 ring-gray-300 hover:bg-gray-50 hover:text-gr-dark focus:z-20 focus:outline-offset-0"])
        }, [
          _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('pagination.previous')), 1),
          _createVNode(_unref(ChevronLeftIcon), {
            class: "h-5 w-5",
            "aria-hidden": "true"
          })
        ], 2),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(visiblePages.value, (page) => {
          return (_openBlock(), _createElementBlock("a", {
            key: page,
            href: "#",
            onClick: _withModifiers(($event: any) => (goToPage(page)), ["prevent"]),
            class: _normalizeClass([pageClass(page), "relative inline-flex items-center px-4 py-2 text-sm font-semibold focus:z-20 focus-visible:outline focus-visible:outline-2 hover:bg-gray-200 focus-visible:outline-offset-2 focus-visible:outline-gr-primary"]),
            "aria-current": currentPage.value === page ? 'page' : null
          }, _toDisplayString(page), 11, _hoisted_10))
        }), 128)),
        _createElementVNode("a", {
          onClick: _withModifiers(nextPage, ["prevent"]),
          class: _normalizeClass([nextPageClass.value, "cursor-pointer inline-flex items-center justify-center w-8 h-8 text-gray-400 rounded-full ring-1 ring-gray-300 hover:bg-gray-50 hover:text-gr-dark focus:z-20 focus:outline-offset-0"])
        }, [
          _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('pagination.next')), 1),
          _createVNode(_unref(ChevronRightIcon), {
            class: "h-5 w-5",
            "aria-hidden": "true"
          })
        ], 2)
      ])
    ])
  ]))
}
}

})