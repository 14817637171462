import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import {
	IonApp,
	IonFooter, IonHeader, IonRouterOutlet, IonContent,
} from '@ionic/vue';
import {onBeforeMount, ref, watch} from 'vue';
import CookiesDialog from '@/components/modal/CookiesDialog.vue';
import {useI18n} from 'vue-i18n';
import useCustomStore from '@/composable/custom/useCustomStore';
import {useRoute} from 'vue-router';
import useSystem from '@/composable/core/useSystem';
import AOS from 'aos';
import 'aos/dist/aos.css';
import NavbarPro from '@/components/navigations/NavbarPro.vue';
import LoadingAnimation from '@/components/animations/LoadingAnimation.vue';
import {useHeaderHeight} from '@/composable/global/useHeaderHeight';
import HeaderInfo from '@/components/modal/HeaderInfo.vue';
import {RouteChecker} from '@/composable/core/route_checker';
import FloatingBottomBanner from '@/components/modal/FloatingBottomBanner.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

AOS.init();
AOS.refreshHard();

const {setHeaderHeight, initHeaderHeight, headerHeight} = useHeaderHeight();
const {
	getLanguageLocale,
	setLanguageLocale,
	getDefaultBrowserLanguageCode,
	isPageInitialized,
	setIsPageInitialized,
} = useCustomStore();
const i18n = useI18n();
const route = useRoute();
const {initVersion} = useSystem();
const {isGlobalLoading, setIsGlobalLoading} = useCustomStore();

const isSystemAvailable = ref(true);
const blockedRoutes = ['/auth', '/register'];
const routeChecker = new RouteChecker(blockedRoutes);
const isHeaderBlocked = ref<boolean>(routeChecker.isRouteBlocked(route.path));

setInterval(() => {
	useSystem().checkIfSystemIsAvailable().then((response) => {
		isSystemAvailable.value = response;
	});
}, 300000)

watch(
		() => route.path,
		(newPath) => {
			isHeaderBlocked.value = routeChecker.isRouteBlocked(newPath);
			if (isHeaderBlocked.value) {
				setHeaderHeight(0);
			} else {
				initHeaderHeight();
			}
		},
);

onBeforeMount(() => {
	try {
		initVersion();
		if (getLanguageLocale.value.length === 0 || !isPageInitialized.value) {
			setLanguageLocale(getDefaultBrowserLanguageCode());
			setIsPageInitialized(true);
		}
		i18n.locale.value = getLanguageLocale.value;
		if (isHeaderBlocked.value) {
			setHeaderHeight(0);
		}
		useSystem().checkIfSystemIsAvailable().then((response) => {
			isSystemAvailable.value = response;
		});
		setIsGlobalLoading(false);
	} catch (e) {
		console.log(e);
	}
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(isGlobalLoading))
      ? (_openBlock(), _createBlock(LoadingAnimation, {
          key: 0,
          "loading-text": _ctx.$t('loading.DefaultSpinner.loadingText')
        }, null, 8, ["loading-text"]))
      : _createCommentVNode("", true),
    _createVNode(_unref(IonApp), null, {
      default: _withCtx(() => [
        _withDirectives(_createVNode(_unref(IonHeader), null, {
          default: _withCtx(() => [
            _createVNode(NavbarPro)
          ]),
          _: 1
        }, 512), [
          [_vShow, !isHeaderBlocked.value]
        ]),
        _createVNode(_unref(IonContent), {
          class: _normalizeClass(['ion-padding', isHeaderBlocked.value ? 'no-padding-top' : '']),
          style: _normalizeStyle({ '--offset-top': _unref(headerHeight) + 'px' }),
          "scroll-events": true,
          fullscreen: ""
        }, {
          default: _withCtx(() => [
            (!isHeaderBlocked.value)
              ? (_openBlock(), _createBlock(HeaderInfo, {
                  key: 0,
                  id: "promo-header",
                  "promo-code": "GREEVEBETA20",
                  "always-visible": false
                }))
              : _createCommentVNode("", true),
            _createVNode(_unref(IonRouterOutlet), { class: "h-full w-full" }),
            (!isSystemAvailable.value)
              ? (_openBlock(), _createBlock(FloatingBottomBanner, {
                  key: 1,
                  title: _ctx.$t('error.500'),
                  color: "bg-red-700"
                }, null, 8, ["title"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["class", "style"]),
        _createVNode(CookiesDialog),
        _createVNode(_unref(IonFooter))
      ]),
      _: 1
    })
  ], 64))
}
}

})