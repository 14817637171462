<template>
	<div>
		<div class="mt-6 overflow-hidden border-t border-gray-100">
			<div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
				<div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
					<table class="w-full text-left">
						<thead class="sr-only">
						<tr v-if="headers">
							<th v-for="header in headers" :key="header">{{ header }}</th>
						</tr>
						<tr v-else>
							<th>{{ $t('payment.CreditCard.nameTitle') }}</th>
							<th>{{ $t('moreDetails') }}</th>
						</tr>
						</thead>
						<tbody>
						<tr v-for="item in listData" :key="item.id">
							<td class="relative py-5 pr-6">
								<div class="flex gap-x-6">
									<component :is="item.icon"
											   :class="['hidden h-6 w-5 flex-none sm:block', item.iconColor ? 'text-'+item.iconColor+'-500' : 'text-gray-400']"
											   aria-hidden="true"/>
									<div class="flex-auto">
										<div class="flex items-start gap-x-3">
											<div class="text-sm font-medium leading-6 text-gray-900 dark:text-gray-500">{{
																								item.title
												}}
											</div>
											<div v-if="item.labels">
												<span v-for="label in item.labels" :key="label.key"
													  :class="['inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ml-1', label.color ? getLabelClassByColor(label.color) : 'bg-gray-50 text-gray-600 ring-gray-500/10']"
													  v-html="label.value"></span>
											</div>
											<div v-if="item.status"
												 :class="[getStatusCss[item.status], 'rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset']">
												{{ item.status }}
											</div>
										</div>
										<div v-if="item.detail" class="mt-1 text-xs leading-5 text-gray-500">
											{{ item.detail }}
										</div>
									</div>
								</div>
								<div class="absolute bottom-0 right-full h-px w-screen bg-gray-100"/>
								<div class="absolute bottom-0 left-0 h-px w-screen bg-gray-100"/>
							</td>
							<td class="py-5 text-right" v-if="item.link.length > 0">
								<div class="flex justify-end">
									<a :href="item.link"
									   class="text-sm font-medium leading-6 text-indigo-600 hover:text-indigo-500"
									>{{ $t('view') }}<span class="hidden sm:inline"></span><span class="sr-only"></span></a
									>
								</div>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>

		<div v-if="paginationData">
			<DefaultPagination :paginationMeta="paginationData" @page-change="handlePageChange" />
		</div>

	</div>
</template>

<script lang="ts">
import {defineComponent, onMounted, onUnmounted, ref} from 'vue';
import {ChevronLeftIcon, ChevronRightIcon} from '@heroicons/vue/20/solid';
import {Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/vue';
import {EllipsisVerticalIcon} from '@heroicons/vue/24/outline';
import {CheckCircleIcon} from '@heroicons/vue/20/solid';
import DefaultPagination from '@/components/core/DefaultPagination.vue';

export interface ListItemInterface {
	id: string;
	title: string;
	detail: string;
	additionalInfo?: string;
	info?: string;
	labels?: Array<{ key: string; value: string; color?: string }>;
	link: string;
	items?: any;
	status?: string;
	type?: string;
	date?: string;
	//eslint-disable-next-line
	icon?: any | undefined,
	iconColor?: string | undefined,
	data?: any | undefined;
}

export default defineComponent({
	name: 'List',
	components: {
		DefaultPagination,
		ChevronLeftIcon,
		ChevronRightIcon,
		Menu, MenuButton, MenuItem, MenuItems,
		EllipsisVerticalIcon,
		CheckCircleIcon,
	},
	props: {
		headers: {
			type: Array as () => Array<string>,
			default: () => [],
		},
		listData: {
			type: Array as () => Array<ListItemInterface>,
			default: () => [],
		},
		paginationData: {},
	},
	emits: ['paginatePage'],
	setup(props, {emit}) {
		const actualPage = ref(1);

		const handlePageChange = (page: number) => {
			emit('paginatePage', page);
		};

		function paginatePage(page: number) {
			emit('paginatePage', page);
		}

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		function clickItem(item: ListItemInterface) {
			//TODO
		}

		function getStatusCss(status: string) {
			let result;
			switch (status) {
				case 'alert':
					result = 'text-red-700 bg-red-50 ring-red-600/10';
					break;
				case 'success':
					result = 'text-green-700 bg-green-50 ring-green-600/20';
					break;
				case 'default':
					result = 'text-gray-600 bg-gray-50 ring-gray-500/10';
					break;
			}

			return result;
		}

		function getLabelClassByColor(color: string) {
			const ringColor = color === 'green' ? '20' : '10';
			return 'bg' + color + '-50 text-' + color + '-700 ring-' + color + '-600/' + ringColor;
		}

		onUnmounted(() => {
			//
		});

		onMounted(() => {
			//
		});

		return {paginatePage, getStatusCss, getLabelClassByColor, actualPage, handlePageChange};
	},
});
</script>

<style scoped>

</style>
