// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {AxiosError} from "axios";
import {ResponseError} from "@/greeve/api/api.interfaces";
import GreeveApiClient from "@/greeve/api/client";
import useBlogFactory from '@/composable/blog/useBlogFactory';
import {BlogList} from '@/greeve/blog/blog_list.type';
import {Blog} from '@/greeve/blog/blog.type';

const GreeveApiBlog = {
    getBlogs: async function (): Promise<BlogList|undefined> {
        try {
            const response = await GreeveApiClient.get('/blog');
            if (response) {
                const responseData = response.data.data;
                if (responseData) {
                    return useBlogFactory().getBlogFactory().createBlogListByResponse(responseData);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    getBlog: async function (blogId: number): Promise<Blog|undefined> {
        try {
            const response = await GreeveApiClient.get('/blog/'+blogId);
            if (response) {
                const responseData = response.data.data;
                if (responseData) {
                    return useBlogFactory().getBlogFactory().createBlogByResponse(responseData);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    getBlogBySlug: async function (slug: string): Promise<Blog|undefined> {
        try {
            const response = await GreeveApiClient.get('/blog/'+slug);
            if (response) {
                const responseData = response.data.data;
                if (responseData) {
                    return useBlogFactory().getBlogFactory().createBlogByResponse(responseData);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },
}

export {GreeveApiBlog};
