import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("select", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$i18n.locale) = $event)),
      class: "appearance-none text-gray-500 dark:text-gray-400 bg-gray-100 dark:bg-gr-darker flex text-sm rounded-xl p-2 w-auto bg-transparent outline-none rounded-xl",
      onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeLanguage()))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.arrLanguageMap, (languageName, languageCode) => {
        return (_openBlock(), _createElementBlock("option", {
          key: languageCode,
          value: languageCode
        }, _toDisplayString(_ctx.showFullName ? _ctx.$t('locales.' + languageCode) : languageName), 9, _hoisted_2))
      }), 128))
    ], 544), [
      [_vModelSelect, _ctx.$i18n.locale]
    ])
  ]))
}