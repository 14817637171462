import {UserAddressList} from "@/greeve/user/address/user_address_list.type";
import {UserAddress} from "@/greeve/user/address/user_address.type";

export class UserAddressFactory {
	/**
	 *
	 * @param userAddressResponse
	 */
	createUserAddressListByResponse(userAddressResponse: Record<string, any>): UserAddressList {
		const userAddressList = new UserAddressList();

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		for (const [key, item] of Object.entries(userAddressResponse)) {
			userAddressList.push(this.createUserAddressByResponse(item));
		}

		return userAddressList;
	}

	createUserAddressByResponse(item: Record<string, any>): UserAddress {
		const id = item.id;
		const userId = item.user_id;
		const primary = !!item.primary;
		const firstName = item.first_name;
		const lastName = item.last_name;
		const countryCode = item.country_code;
		const city = item.city;
		const street = item.street;
		const postalCode = item.zip_code;
		const company = item.company;
		const state = item.state ?? undefined;
		const data = item.data;
		const created_at = item.created_at ? new Date(item.created_at) : (item.createdAt ? new Date(item.createdAt) : new Date());
		const updated_at = item.updated_at ? new Date(item.updated_at) : (item.updatedAt ? new Date(item.updatedAt) : new Date());

		return new UserAddress(id, userId, primary, firstName, lastName, street, city, countryCode, postalCode, state, company, created_at, updated_at, data);
	}
}