<template>
	<div class="mx-auto max-w-4xl divide-y divide-gray-900/10 dark:divide-gray-200/10">
		<h2 class="text-2xl font-bold leading-10 tracking-tight text-gray-900  dark:text-gray-200">{{ $t('faq.title') }}
		</h2>
		<dl class="mt-10 space-y-6 divide-y divide-gray-900/10 dark:divide-gray-200/10" v-if="faqs">
			<Disclosure as="div" v-for="faq in faqs.getFaqsByLanguage()" :key="faq.question_title" class="pt-6"
						v-slot="{ open }">
				<dt>
					<DisclosureButton
							class="flex w-full items-start justify-between text-left text-gray-900 dark:text-gray-200">
												<span class="text-base font-semibold leading-7"
													  v-html="faq.question_title"/>
						<span v-show="faq.question_description"
							  class="text-base leading-7"
							  v-html="faq.question_description"/>
						<span class="ml-6 flex h-7 items-center">
												  <MinusSmallIcon v-if="!open" class="h-6 w-6" aria-hidden="true"/>
												  <PlusSmallIcon v-else class="h-6 w-6" aria-hidden="true"/>
												</span>
					</DisclosureButton>
				</dt>
				<DisclosurePanel as="dd" class="text-left mt-2 pr-12">
					<p class="text-base leading-7 text-gray-600 dark:text-gray-400" v-html="faq.answer_title"/>
					<p v-show="faq.answer_description" class="text-base leading-7 text-gray-600  dark:text-gray-400"
					   v-html="faq.answer_description"/>
					<img loading="lazy" v-show="faq.image" :src="faq.image"
						 :alt="faq.answer_title"
						 class="h-16 w-16 flex-none rounded-2xl"/>
				</DisclosurePanel>
			</Disclosure>
		</dl>
	</div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import useTranslation from "@/composable/translation/useTranslation";
import {Disclosure, DisclosureButton, DisclosurePanel} from '@headlessui/vue';
import {MinusSmallIcon, PlusSmallIcon} from '@heroicons/vue/24/outline';
import useFaq from "@/composable/faq/useFaq";

export default defineComponent({
	name: 'FaqSection',
	components: {
		Disclosure, DisclosureButton, DisclosurePanel,
		MinusSmallIcon, PlusSmallIcon
	},
	setup() {
		const {t} = useTranslation();
		const {faqs} = useFaq();
		return {t, faqs}
	}
});

</script>
<style lang="scss">
</style>
