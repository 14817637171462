<!--suppress AllyHtmlVueInspection -->
<template>
<!--	<div class="footer-gradient"></div>-->
<!--	<footer class="box bg-black w-full" id="footer">-->
	<footer class="" id="footer">
		<div class="nav rounded-2xl m-2 sm:m-4 mb-[9.35em] sm:mb-[10.35em] md:mb-[5.5em] lg:mb-4">
		<div class="nav backdrop-filter backdrop-blur-2xl rounded-2xl py-8 px-6 lg:px-8">
			<language-selector></language-selector>
			<nav class="-mx-5 mt-4 my-2 flex flex-wrap" aria-label="footer">
				<div v-for="item in navigation.main" :key="item.name" class="px-5 py-2">
					<div @click="redirectFooterByItem(item.href)"
					   class="text-base text-gray-500 dark:text-gray-400 cursor-pointer">{{ t(item.name) }}</div>
				</div>
				<div class="px-5 py-2">
					<a href="https://www.blog.greeve.ai"
					   class="text-base text-gray-500 dark:text-gray-400 cursor-pointer">{{ $t('footer.blog') }}</a>
				</div>
				<div class="px-5 py-2">
					<a @click="redirectFooterByItem('/enterprise')" class="cursor-pointer text-base text-gray-500 dark:text-gray-400 cursor-pointer">{{ $t('footer.enterprise') }}</a>
				</div>
			</nav>
			<div class="mx-auto max-w-max my-6">
			</div>
			<div class="mt-8 flex space-x-6">
				<SocialMedia social-media-style="text-gray-500 dark:text-gray-400 cursor-pointer" social-media-height="h-6 w-6"></SocialMedia>
			</div>
<!--			<div  class="mt-8 md:flex justify-center space-x-6">-->
<!--				<PayPalDonation height="w-32 mx-auto"></PayPalDonation>-->
<!--				<KoFiDonation height="w-32 mx-auto"></KoFiDonation>-->
<!--			</div>-->
			<div id="copyright" class="mt-8 mb-4 text-base text-gray-500 dark:text-gray-400"><span class="tooltip">{{ $t('copy', {getFullYear: new Date().getFullYear()}) }}
				<span v-if="appVersion" class="tooltiptext">{{ $t('currentVersion') }} {{appVersion}}</span></span> <a
					href="https://www.theconcept-technologies.com">{{ $t('terms.ContactDetail2') }}</a> {{ $t('allRightsReserved') }}

			</div>
			<div id="disclaimer" class="mx-auto text-sm text-gray-400 dark:text-gray-700"> {{ $t('chatGptDisclaimer') }}</div>
		</div>
		</div>
	</footer>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import LanguageSelector from "@/components/settings/LanguageSelector.vue";
import router from "@/router";
import useTranslation from "@/composable/translation/useTranslation";
import useSystem from "@/composable/core/useSystem";
import SocialMedia from "@/components/branding/SocialMedia.vue";

export default defineComponent({
	name: 'FooterBar',
	components: {SocialMedia, LanguageSelector},
	setup() {
		const {t} = useTranslation();
		const {appVersion} = useSystem();
		const navigation = {
			main: [
				{name: 'footer.donate', href: '/donate'},
				{name: 'footer.imprint', href: '/imprint'},
				{name: 'footer.press', href: '/press'},
				{name: 'footer.faqs', href: '/faq'},
				{name: 'footer.privacy', href: '/privacy'},
				{name: 'footer.terms', href: '/terms'},
				// { name: 'Pro', href: '/becomePro' },
			],
		}

		function redirectFooterByItem(link: string) {
			router.push(link);
		}

		return {t, navigation, redirectFooterByItem, appVersion}
	}
});

</script>

<style>
.tooltip {
    display: inline-block;
}
.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.footer-gradient {
    z-index: -1;
    position: fixed;
    top: 900px;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(90deg, rgba(255,211,0,1) 0%, rgba(255,118,0,1) 34.31%, rgba(246,0,0,1) 100%);
    transform: translate3d(0px, 5px, 0px) scale(0.95);
    -webkit-transform: translate3d(0px, 5px, 0px) scale(0.95);
    filter: blur(100px);
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    border-radius: inherit;
}

.footer-height {
	position: sticky;
top: 100vh;
}

</style>
