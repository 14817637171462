import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "relative mx-auto grid lg:px-8 justify-center" }
const _hoisted_2 = { class: "mt-6 py-16" }
const _hoisted_3 = { class: "mt-6 py-16 sm:px-6 lg:row-start-1 lg:px-0" }
const _hoisted_4 = { class: "relative lg:min-h-full" }
const _hoisted_5 = { class: "rounded-md bg-red-50 p-4" }
const _hoisted_6 = { class: "flex" }
const _hoisted_7 = { class: "ml-3" }
const _hoisted_8 = { class: "text-sm font-medium text-green-800" }
const _hoisted_9 = { class: "text-sm font-medium text-green-800" }
const _hoisted_10 = { class: "mx-auto py-16 px-4 sm:px-6 sm:py-24 lg:px-8 lg:py-32" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_line_animation = _resolveComponent("loading-line-animation")!
  const _component_loadingAnimation = _resolveComponent("loadingAnimation")!
  const _component_default_button = _resolveComponent("default-button")!
  const _component_FooterBar = _resolveComponent("FooterBar")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("main", {
            style: _normalizeStyle({ paddingTop: _ctx.headerHeight + 'px' })
          }, [
            _withDirectives(_createVNode(_component_loading_line_animation, null, null, 512), [
              [_vShow, _ctx.isLoading]
            ]),
            _createElementVNode("main", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _withDirectives(_createVNode(_component_loadingAnimation, null, null, 512), [
                    [_vShow, _ctx.isLoading]
                  ]),
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("div", _hoisted_6, [
                        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "flex-shrink-0" }, null, -1)),
                        _createElementVNode("div", _hoisted_7, [
                          _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('login.error')), 1),
                          _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t('itWillTakeAFewMinutesUntilYourProductIsReady')), 1)
                        ])
                      ])
                    ]),
                    _createElementVNode("div", null, [
                      _createElementVNode("div", _hoisted_10, [
                        _createVNode(_component_default_button, {
                          onClickButton: _ctx.redirectToCheckout,
                          "button-text": 'Zurück zum Checkout'
                        }, null, 8, ["onClickButton"])
                      ])
                    ])
                  ])
                ])
              ])
            ]),
            _createVNode(_component_FooterBar, { class: "mt-12" })
          ], 4)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}