import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, Transition as _Transition } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "header-gradient"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "relative z-10 sticky" }
const _hoisted_4 = { class: "nav rounded-xl" }
const _hoisted_5 = ["aria-label"]
const _hoisted_6 = { class: "flex w-full items-center justify-between py-4" }
const _hoisted_7 = { class: "flex items-center" }
const _hoisted_8 = { class: "lg:hidden space-x-4 flex items-center" }
const _hoisted_9 = { class: "sr-only" }
const _hoisted_10 = { class: "sr-only" }
const _hoisted_11 = { class: "hidden lg:block flex items-center" }
const _hoisted_12 = { class: "hidden flex-important space-x-8 flex lg:block" }
const _hoisted_13 = { class: "inline-flex items-center px-6 p-2 font-bold" }
const _hoisted_14 = { class: "hidden lg:block" }
const _hoisted_15 = { class: "justify-end items-end inline-flex items-center font-medium" }
const _hoisted_16 = ["alt", "src"]
const _hoisted_17 = { class: "rounded-2xl bg-white dark:bg-gr-darkest shadow-lg" }
const _hoisted_18 = { class: "py-4 px-4" }
const _hoisted_19 = { class: "grid grid-cols-1 gap-6 my-4" }
const _hoisted_20 = {
  key: 0,
  class: "inline-flex items-center px-2 py-2 font-medium"
}
const _hoisted_21 = { class: "inline-flex items-center px-2 py-2 font-medium" }
const _hoisted_22 = { class: "sr-only" }
const _hoisted_23 = ["alt", "src"]
const _hoisted_24 = { class: "p-4 pt-0" }
const _hoisted_25 = { key: 0 }
const _hoisted_26 = { class: "mt-6 mb-2 text-center text-base font-medium text-gray-500" }
const _hoisted_27 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderLogo = _resolveComponent("HeaderLogo")!
  const _component_credits_nav_cart = _resolveComponent("credits-nav-cart")!
  const _component_XMarkIcon = _resolveComponent("XMarkIcon")!
  const _component_PopoverButton = _resolveComponent("PopoverButton")!
  const _component_Bars3Icon = _resolveComponent("Bars3Icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_UserCircleIcon = _resolveComponent("UserCircleIcon")!
  const _component_PopoverPanel = _resolveComponent("PopoverPanel")!
  const _component_Popover = _resolveComponent("Popover")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.headerGradient === true)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : (_openBlock(), _createElementBlock("div", _hoisted_2)),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_Popover, {
        id: "navbar-popover",
        class: "m-2 sm:m-4 top-4 backdrop-filter rounded-xl backdrop-blur-xl nav animated justify-between items-center transition-all duration-200"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", {
              class: "mx-auto rounded-3xl max-w-7xl px-4 md:px-6 lg:px-8",
              "aria-label": _ctx.$t('top')
            }, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_HeaderLogo, {
                    "scroll-to-top": true,
                    "redirect-link": "/services",
                    pro: false,
                    beta: false,
                    "small-logo": true,
                    "custom-class": "cursor-pointer mt-0 h-8 w-auto"
                  })
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_credits_nav_cart),
                  (_ctx.popoverButton)
                    ? (_openBlock(), _createBlock(_component_PopoverButton, {
                        key: 0,
                        ref: "popoverButton",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.popoverButton = false)),
                        class: "inline-flex items-center justify-center rounded-xl bg-gray-100 dark:bg-gr-darker p-2 text-gray-400 hover:bg-gray-100 dark:hover:bg-gr-dark hover:text-gray-500 focus:outline-none"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('closeMenu')), 1),
                          _createVNode(_component_XMarkIcon, {
                            class: "h-6 w-6",
                            "aria-hidden": "true"
                          })
                        ]),
                        _: 1
                      }, 512))
                    : (_openBlock(), _createBlock(_component_PopoverButton, {
                        key: 1,
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.popoverButton = true)),
                        class: "inline-flex items-center justify-center rounded-xl bg-gray-100 dark:bg-gr-darker p-2 text-gray-400 hover:bg-gray-100 dark:hover:bg-gr-dark hover:text-gray-500 focus:outline-none"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t('openMenu')), 1),
                          _createVNode(_component_Bars3Icon, {
                            class: "h-6 w-6",
                            "aria-hidden": "true"
                          })
                        ]),
                        _: 1
                      }))
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navigation.filter((nav) => {return nav.visible;}), (link) => {
                      return (_openBlock(), _createBlock(_component_router_link, {
                        key: link.name,
                        to: link.href,
                        class: _normalizeClass(['transition-all duration-300', link.active ? 'font-bold rounded-xl bg-gray-100 dark:bg-gr-darker hover:bg-gray-200 dark:hover:bg-gr-dark transition duration-300 ease-in-out text-base text-xl text-gray-500 dark:text-gray-400' : '', 'text-base truncate font-medium text-xl text-gray-500 dark:text-gray-400'])
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_13, [
                            (_openBlock(), _createBlock(_resolveDynamicComponent(link.icon), {
                              class: "ml-0 mr-2 h-4 w-4",
                              "aria-hidden": "true"
                            })),
                            _createTextVNode(" " + _toDisplayString(link.name), 1)
                          ])
                        ]),
                        _: 2
                      }, 1032, ["to", "class"]))
                    }), 128))
                  ])
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("div", _hoisted_15, [
                    _createVNode(_component_credits_nav_cart),
                    _createVNode(_component_router_link, {
                      to: _ctx.isAuthenticated ? '/profile/account' : '/auth?redirect=profile/account',
                      class: "inline-block rounded-3xl border border-transparent py-1 px-6 text-base font-medium text-gray-500 hover:text-gray-400"
                    }, {
                      default: _withCtx(() => [
                        (!_ctx.user.profile?.profile_picture)
                          ? (_openBlock(), _createBlock(_component_UserCircleIcon, {
                              key: 0,
                              class: "h-7 w-47",
                              "aria-hidden": "true"
                            }))
                          : (_openBlock(), _createElementBlock("img", {
                              key: 1,
                              class: "inline-block h-7 w-47 rounded-full",
                              alt: _ctx.user.name,
                              src: _ctx.user.profile.profile_picture
                            }, null, 8, _hoisted_16))
                      ]),
                      _: 1
                    }, 8, ["to"]),
                    (!_ctx.isAuthenticated)
                      ? (_openBlock(), _createBlock(_component_router_link, {
                          key: 0,
                          to: "/register",
                          class: "inline-block truncate rounded-xl border border-transparent bg-gr-primary hover:bg-gr-primary3 py-1 px-6 text-base font-bold text-white transition duration-300 ease-in-out"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('signUp')), 1)
                          ]),
                          _: 1
                        }))
                      : (_openBlock(), _createBlock(_component_router_link, {
                          key: 1,
                          to: "/auth",
                          class: "inline-block truncate rounded-xl border border-transparent py-1 pl-0 px-6 text-base font-bold text-gray-500 dark:text-gray-400 transition duration-300 ease-in-out"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('logout')), 1)
                          ]),
                          _: 1
                        })),
                    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "" }, null, -1)),
                    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "justify-end items-end" }, null, -1))
                  ])
                ])
              ])
            ], 8, _hoisted_5)
          ]),
          _createVNode(_Transition, {
            "enter-active-class": "duration-200 ease-out",
            "enter-from-class": "opacity-0 scale-95",
            "enter-to-class": "opacity-100 scale-100",
            "leave-active-class": "duration-100 ease-in",
            "leave-from-class": "opacity-100 scale-100",
            "leave-to-class": "opacity-0 scale-95"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_PopoverPanel, { class: "absolute inset-x-0 top-20 origin-top-bottom transform p-0 transition lg:hidden" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("div", _hoisted_18, [
                      _createElementVNode("div", _hoisted_19, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getVisibleNavigation(), (link) => {
                          return (_openBlock(), _createBlock(_component_router_link, {
                            key: link.name,
                            to: link.href,
                            class: _normalizeClass(['transition-all duration-300 text-center text-xl font-medium', link.active ? 'font-bold rounded-xl bg-gray-100 dark:bg-gr-darker hover:bg-gray-200 dark:hover:bg-gr-dark transition duration-300 ease-in-out text-base text-xl text-gray-500 dark:text-gray-400' : '', 'text-base truncate font-medium text-xl text-gray-500 dark:text-gray-400'])
                          }, {
                            default: _withCtx(() => [
                              (link.visible)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_20, [
                                    (_openBlock(), _createBlock(_resolveDynamicComponent(link.icon), {
                                      class: "ml-0 mr-2 h-4 w-4",
                                      "aria-hidden": "true"
                                    })),
                                    _createTextVNode(" " + _toDisplayString(link.name), 1)
                                  ]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 2
                          }, 1032, ["to", "class"]))
                        }), 128)),
                        _createVNode(_component_router_link, {
                          to: _ctx.isAuthenticated ? '/profile/account' : '/auth?redirect=profile/account',
                          "exact-active-class": "font-bold rounded-xl bg-gray-100 dark:bg-gr-darker hover:bg-gray-200 dark:hover:bg-gr-dark transition duration-300 ease-in-out text-base text-xl text-gray-500 dark:text-gray-400",
                          class: _normalizeClass(['transition-all duration-300 text-center text-xl font-medium truncate text-gray-500 dark:text-gray-400'])
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", _hoisted_21, [
                              _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.$t('profileName')), 1),
                              (!_ctx.user.profile?.profile_picture)
                                ? (_openBlock(), _createBlock(_component_UserCircleIcon, {
                                    key: 0,
                                    class: "ml-0 mr-2 h-4 w-4",
                                    "aria-hidden": "true"
                                  }))
                                : (_openBlock(), _createElementBlock("img", {
                                    key: 1,
                                    class: "ml-0 mr-2 inline-block h-7 w-47 rounded-full",
                                    alt: _ctx.user.name,
                                    src: _ctx.user.profile?.profile_picture
                                  }, null, 8, _hoisted_23))
                            ])
                          ]),
                          _: 1
                        }, 8, ["to"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_24, [
                      (!_ctx.isAuthenticated)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                            _createVNode(_component_router_link, {
                              to: "/auth",
                              class: "flex w-full items-center justify-center rounded-xl border border-transparent bg-gr-primary px-4 py-2 text-base font-medium text-white shadow-sm"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('signUp')), 1)
                              ]),
                              _: 1
                            }),
                            _createElementVNode("p", _hoisted_26, [
                              _createTextVNode(_toDisplayString(_ctx.$t('existingCustomer', {expr: ' '})) + " ", 1),
                              _createVNode(_component_router_link, {
                                to: "/auth",
                                class: "text-gr-primary"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('signIn')), 1)
                                ]),
                                _: 1
                              })
                            ])
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_27, [
                            _createVNode(_component_router_link, {
                              to: "/auth",
                              class: "flex w-full items-center justify-center rounded-xl border border-transparent px-4 py-2 text-base font-medium text-gray-700 dark:text-gray-400"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('logout')), 1)
                              ]),
                              _: 1
                            })
                          ]))
                    ])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ], 64))
}