import {
	FlowProcessDependencyList
} from '@/greeve/flow/process/dependency/flow_process_dependency_list.type';
import {
	FlowProcessDependency
} from '@/greeve/flow/process/dependency/flow_process_dependency.type';
import useFlowFactory from '@/composable/greeve/useFlowFactory';

export class FlowProcessDependencyFactory {
	/**
	 *
	 * @param flow_process_dependency_data
	 */
	createFlowProcessDependencyListByResponse(flow_process_dependency_data: Record<string, any>): FlowProcessDependencyList {
		const flowProcessDependencyList = this.createEmptyFlowProcessDependencyList();

		if (flow_process_dependency_data.flow_process_dependencies) {
			for (const [dependency_Id, item] of Object.entries(flow_process_dependency_data.flow_process_dependencies)) {
				const dependencyItem: Record<string, any>|any = item;
				if (!dependencyItem) {
					continue;
				}
				flowProcessDependencyList.push(this.createFlowProcessDependencyByResponse(Number(dependency_Id), dependencyItem));
			}
		} else {
			for (const [dependency_Id, item] of Object.entries(flow_process_dependency_data)) {
				const dependencyItem: Record<string, any>|any = item;
				if (!dependencyItem) {
					continue;
				}
				flowProcessDependencyList.push(this.createFlowProcessDependencyByResponse(Number(dependency_Id), dependencyItem));
			}
		}

		return flowProcessDependencyList;
	}

	/**
	 *
	 * @param item
	 */
	createFlowProcessDependencyByResponse(dependency_Id: number, item: Record<string, any>): FlowProcessDependency {
		const id = dependency_Id;
		const data = item.data ?? undefined;
		const created_at = item.created_at ? new Date(item.created_at) : (item.createdAt ? new Date(item.createdAt) : new Date());
		const updated_at = item.updated_at ? new Date(item.updated_at) : (item.updatedAt ? new Date(item.updatedAt) : new Date());

		let from;
		if (item.from) {
			from = useFlowFactory().getFlowProcessFactory().createFlowProcessByResponse(item.from);
		}

		let to;
		if (item.to) {
			to = useFlowFactory().getFlowProcessFactory().createFlowProcessByResponse(item.to);
		}

		return new FlowProcessDependency(id, from, to, data, created_at, updated_at);
	}

	createEmptyFlowProcessDependencyList(): FlowProcessDependencyList {
		return new FlowProcessDependencyList([]);
	}
}