<template>
	<div class="wizard-container divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
		<ConfettiBombAnimation v-if="showFinishedAnimation" speed="0.7" :sound-enabled="false"></ConfettiBombAnimation>
		<div :class="['absolute w-full opacity-70 flex justify-center rounded-t-lg', wizzardColor]">{{wizzardName}}</div>
		<div class="px-4 py-5 sm:px-6">
			<div class="block sm:hidden">
				<h4 class="sr-only">Status</h4>
				<p class="text-sm font-medium text-gray-900">
				  <span
							class="inline-flex items-center gap-x-1.5 rounded-full bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-800">
    <svg class="h-1.5 w-1.5 fill-yellow-500" viewBox="0 0 6 6" aria-hidden="true">
      <circle cx="3" cy="3" r="3"/>
    </svg>
						<span> {{ currentStep }} / {{ stepCount }}</span><span v-if="currentStepStatusInfo"> - {{currentStepStatusInfo.title}}</span>
  </span>
				</p>
			</div>
			<div v-if="stepStatusInfo" class="hidden sm:block mt-6" aria-hidden="true">
				<div class="overflow-hidden rounded-full bg-gray-200">
					<transition name="progress-bar">
						<div :class="['h-2 rounded-full', wizzardColor]" :style="{ width: progressBarWidth }"/>
					</transition>
				</div>
				<div :class="['mt-6 hidden text-sm font-medium text-gray-600 sm:grid', 'grid-cols-'+stepCount]">
					<div v-for="stepInfo in stepStatusInfo" :key="stepInfo.step" :class="[stepInfo.step > 1 && stepInfo.step < stepCount ? 'text-center' : '', stepInfo.step === stepCount ? 'text-right' : '', stepInfo.current ? wizzardPrimaryTextColor : '']">
						<a class="cursor-pointer no-underline" @click="goToStep(stepInfo.step)">{{stepInfo.title}}</a>
					</div>
				</div>
			</div>		</div>
		<div class="px-4 py-5 sm:p-6">
			<!-- Content goes here -->
			<transition name="fade" mode="out-in">
				<div class="wizard-content mb-4" :key="currentStep">
<!--					TODO show loading screen inside -->
					<component key="step" v-if="isStepComponentLoaded && currentStepComponent" :is="currentStepComponent" :wizzard="wizzardObject" />
					<component key="summary" v-if="isSummaryComponentLoaded && summaryComponent" :is="summaryComponent" :wizzard="wizzardObject" />
				</div>
			</transition>
		</div>
		<div class="px-4 py-4 sm:px-6 wizard-actions flex justify-between">
			<button @click="prevStep" :disabled="(!isPrevStepAllowed)" :class="['bg-gray-500 text-white px-4 py-2 rounded', !isPrevStepAllowed ? 'disabled:opacity-40' : '']">
				Zurück
			</button>
			<button v-if="!isLastStep && !isSummaryComponentLoaded" @click="nextStep" :disabled="(!isNextStepAllowed)" :class="['text-white px-4 py-2 rounded', !isNextStepAllowed ? 'disabled:opacity-40' : 'font-semibold', wizzardColor]">
				Weiter
			</button>
			<button v-else-if="isLastStep && !isSummaryComponentLoaded" @click="showSummary" :disabled="(!isNextStepEnabled)" :class="['text-white px-4 py-2 rounded', !isNextStepEnabled ? 'disabled:opacity-40' : 'font-semibold', wizzardColor]">
				Eingabe kontrollieren
			</button>
			<button v-else-if="isLastStep && isSummaryComponentLoaded" @click="finishWizzard" :class="['text-white px-4 py-2 font-semibold rounded', wizzardColor]">
				Speichern 🎉
			</button>
		</div>
	</div>
</template>

<script lang="ts">
import {ref, computed, defineComponent, defineAsyncComponent, onMounted} from 'vue';
import {AbstractWizzard} from '@/greeve/flow/wizzard/base/abstract_wizzard';
import ConfettiBombAnimation from '@/components/animations/Lottie/ConfettiBombAnimation/ConfettiBombAnimation.vue';

export default defineComponent({
	name: 'WizzardComponent',
	components: {ConfettiBombAnimation},
	props: {
		// TODO own wizzardObject with flow, summary ( flow could have a summary ), steps, getCurrentStep, goToNextStep, goToPrevStep
		wizzard: {
			type: Object as () => AbstractWizzard,
			required: true,
		},
	},
	emits: ['wizzard-show-summary', 'wizzard-last-step', 'wizard-finished', 'wizard-cancelled'],
	setup(props, {emit}) {
		const wizzardObject = ref<AbstractWizzard>(props.wizzard);
		const currentStepObject = ref();
		const showFinishedAnimation = ref(false);
		const isStepComponentLoaded = ref(false);
		const currentStepComponent = ref<any>(null);
		const isSummaryComponentLoaded = ref(false);
		const summaryComponent = ref<any>(null);

		// TODO save all DATA IN STORAGE AND IN DATABASE REST REQUEST!!!
		const stepCount = computed(() => {
			return wizzardObject.value.getStepsCount();
		});

		const wizzardName = computed(() => {
			return wizzardObject.value.getWizzardName();
		});

		const wizzardColor = computed(() => {
			return wizzardObject.value.getWizzardColor();
		});

		const wizzardPrimaryTextColor = computed(() => {
			return wizzardObject.value.getWizzardPrimaryTextColor();
		});

		const currentStep = computed(() => {
			return wizzardObject.value.getCurrentStep();
		});

		const isPrevStepAllowed = computed(() => {
			return wizzardObject.value.isPreviousStepAllowed() || isSummaryComponentLoaded.value;
		});

		const isNextStepAllowed = computed(() => {
			return wizzardObject.value.isNextStepAllowed();
		});

		const isNextStepEnabled = computed(() => {
			return wizzardObject.value.isNextStepEnabled;
		});

		const isLastStep = computed(() => {
			return wizzardObject.value.isLastStep();
		});

		const stepStatusInfo = computed(() => {
			return wizzardObject.value.getStepsStatusInfo()
		})

		const currentStepStatusInfo = computed(() => {
			return wizzardObject.value.getStepStatusInfo(currentStep.value);
		})

		const progressBarWidth = computed(() => {
			return wizzardObject.value.getStepProgressInPercent() + '%';
		})

		const goToStep = (step: number) => {
			wizzardObject.value.goToStep(step);
			loadComponent();
		}

		const loadComponent = async () => {
			// Dynamically import the component
			isStepComponentLoaded.value = false;
			isSummaryComponentLoaded.value = false;
			summaryComponent.value = null;
			const currentStepComponentName = wizzardObject.value.getCurrentStepComponent();
			if (!currentStepComponentName) {
				return;
			}
			const componentPath = `./${wizzardObject.value.getStepsPath()}/${currentStepComponentName}.vue`;
			currentStepComponent.value = defineAsyncComponent(() => import(`${componentPath}`));
			isStepComponentLoaded.value = true;
		};

		const loadSummaryComponent = async () => {
			// Dynamically import the component
			isStepComponentLoaded.value = false;
			isSummaryComponentLoaded.value = false;
			currentStepComponent.value = null;
			const wizzardSummaryComponent = wizzardObject.value.getWizzardSummaryComponent();
			if (!wizzardSummaryComponent) {
				return;
			}
			const componentPath = `./${wizzardObject.value.getWizzardSummaryBasePath()}/${wizzardSummaryComponent}.vue`;
			summaryComponent.value = defineAsyncComponent(() => import(`${componentPath}`));
			isSummaryComponentLoaded.value = true;
		};

		const finishWizzard = () => {
			console.log("FINISH");
			showFinishedAnimation.value = true;
			emit('wizard-finished');
		};


		const showSummary = () => {
			wizzardObject.value.goToSummaryStep();
			emit('wizzard-last-step');
			loadSummaryComponent();
			emit('wizzard-show-summary');
		};

		const nextStep = () => {
			const nextStep = wizzardObject.value.goToNextStep();
			if (!nextStep) {
				emit('wizzard-last-step');
			} else {
				loadComponent();
			}
		};

		const prevStep = () => {
			if (isSummaryComponentLoaded.value) {
				goToStep(currentStep.value);
			} else {
				const previousStep = wizzardObject.value.goToPreviousStep();
				if (!previousStep) {
					emit('wizard-cancelled');
				} else {
					loadComponent();
				}
			}
		};

		onMounted(() => {
			//TODO load step or component by path
			loadComponent();
		})

		return {
			stepCount,
			currentStep,
			currentStepObject,
			nextStep,
			prevStep,
			showSummary,
			wizzardColor,
			wizzardPrimaryTextColor,
			wizzardName,
			isPrevStepAllowed,
			isNextStepAllowed,
			stepStatusInfo,
			currentStepStatusInfo,
			wizzardObject,
			currentStepComponent,
			isStepComponentLoaded,
			progressBarWidth,
			isLastStep,
			finishWizzard,
			isSummaryComponentLoaded,
			summaryComponent,
			goToStep,
			showFinishedAnimation,
			isNextStepEnabled,
		};
	},
});
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
	transition: opacity 0.4s;
}

.fade-enter, .fade-leave-to {
	opacity: 0;
}

.progress-bar-enter-active, .progress-bar-leave-active {
	transition: width 0.5s ease;
}

/* Define initial and final states */
.progress-bar-enter, .progress-bar-leave-to {
	width: 0;
}
</style>
