import {
	FlowQueueProcessInterface, FlowQueueProcessState, FlowQueueProcessType,
} from '@/greeve/flow/queue/process/flow_queue_process.interface';
import useFlow from '@/composable/greeve/useFlow';
import {AbstractFlowQueue} from '@/greeve/flow/queue/abstract_flow_queue.type';
import {
	AbstractFlowProcess
} from '@/greeve/flow/process/abstract_flow_process.type';
import i18n from '@/i18n';
import {FlowProcessType} from '@/greeve/flow/process/flow_process.interface';


export abstract class AbstractFlowQueueProcess implements FlowQueueProcessInterface {
	id: number;
	uuid: string;
	flow_queue_id: number;
	flow_process_id: number;
	job_id?: number|null;
	type: FlowQueueProcessType;
	state: FlowQueueProcessState;
	subType?: FlowProcessType|null;
	reference?: string|null;
	source?: any;
	result?: any;
	data?: any;
	created_at?: Date;
	updated_at?: Date;

	private flowQueue?: AbstractFlowQueue;
	private flowProcess?: AbstractFlowProcess;

	constructor(
			id: number, uuid: string, flow_queue_id: number, flow_process_id: number, type: FlowQueueProcessType, state: FlowQueueProcessState,
			subType?: FlowProcessType|null, job_id?: number|null, reference?: string | undefined,
			source: any = null, result: any = null, data: any = null, created_at?: Date, updated_at?: Date) {
		this.id = id;
		this.uuid = uuid;
		this.flow_queue_id = flow_queue_id;
		this.flow_process_id = flow_process_id;
		this.job_id = job_id;
		this.type = type;
		this.subType = subType;
		this.state = state;
		this.reference = reference;
		this.source = source;
		this.result = result;
		this.data = data;
		this.created_at = created_at;
		this.updated_at = updated_at;
	}

	getFlowQueue(): AbstractFlowQueue|undefined
	{
		if (this.flowQueue) {
			return this.flowQueue;
		}
		if (this.flow_queue_id) {
			const flowQueue = useFlow().getFlowQueueById(this.flow_queue_id);
			if (flowQueue) {
				return flowQueue;
				// this.flowQueue = flowQueue; //TODO CHECK vuex mutation error
			}
			return this.flowQueue;
		}

		return;
	}

	getFlowProcess(): AbstractFlowProcess|undefined
	{
		if (this.flowProcess) {
			return this.flowProcess;
		}
		const flowQueue = this.getFlowQueue();
		if (flowQueue) {
			const flow = flowQueue.getFlow();
			if (flow && flow.flow_processes && this.flow_process_id) {
				return flow.flow_processes.getItemById(this.flow_process_id);
			}
			return this.flowProcess;
		}

		return;
	}

	getCreateDateFormatted(withTime = true): string
	{
		let date = '';
		if (!this.created_at) {
			return date;
		}

		date = this.created_at.toLocaleDateString();

		if (withTime) {
			date = date + ' ' + this.created_at.toLocaleTimeString([], {timeStyle: 'short'})
		}
		return date;
	}

	getUpdateDateFormatted(withTime = true): string
	{
		let date = '';
		if (!this.updated_at) {
			return date;
		}

		date = this.updated_at.toLocaleDateString();

		if (withTime) {
			date = date + ' ' + this.updated_at.toLocaleTimeString([], {timeStyle: 'short'})
		}
		return date;
	}

	getStateLabel(): string
	{
		if (this.state) {
			return i18n.global.t('flow.queue.state.'+this.state+'.label');
		}
		return i18n.global.t('flow.queue.state.undefined.label');
	}

	getStateLabelColor(): string
	{
		switch (this.state)
		{
			case FlowQueueProcessState.DONE:
				return 'green';
			case FlowQueueProcessState.ERROR:
			case FlowQueueProcessState.DELETED:
				return 'red';
			case FlowQueueProcessState.IN_PROGRESS:
				return 'yellow';
			case FlowQueueProcessState.NEW:
			default:
				return 'gray';
		}
	}

	getResultForView(): any
	{
		return this.result;
	}
}