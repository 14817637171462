import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_greeve_shop_checkout = _resolveComponent("greeve-shop-checkout")!
  const _component_FooterBar = _resolveComponent("FooterBar")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("main", {
            style: _normalizeStyle({ paddingTop: _ctx.headerHeight + 'px' })
          }, [
            _createElementVNode("div", null, [
              _createVNode(_component_greeve_shop_checkout, { class: "mx-auto max-w-7xl pb-12" })
            ]),
            _createVNode(_component_FooterBar, { class: "mt-12" })
          ], 4)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}