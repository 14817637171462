import {computed} from 'vue';
import store from "@/store";

export interface AlertInterface {
	id?: string | undefined;
	title: string;
	message: string;
	active: boolean;
}

export const enum AlertTypes {
	SUCCESS = 'success',
	ERROR = 'error',
	WARNING = 'warning',
	SECONDARY = 'secondary',
}

export default function useAlert() {
	const getErrors = computed(() => store.getters['alert/getErrors']);
	const getSuccesses = computed(() => store.getters['alert/getSuccesses']);
	const getWarnings = computed(() => store.getters['alert/getWarnings']);
	const getSecondaries = computed(() => store.getters['alert/getSecondaries']);
	const getAllAlerts = computed(() => store.getters['alert/getAllAlerts']);

	function addError(error: AlertInterface) {
		store.commit('alert/addErrorAlert', error)
	}

	function addSuccess(success: AlertInterface) {
		store.commit('alert/addSuccessAlert', success)
	}

	function addWarning(warning: AlertInterface) {
		store.commit('alert/addWarningAlert', warning)
	}

	function addSecondary(secondary: AlertInterface) {
		store.commit('alert/addSecondaryAlert', secondary)
	}

	function removeAlert(alertId: string) {
		store.commit('alert/removeAlert', alertId)
	}

	async function removeAlertByType(type: string) {
		return store.dispatch('alert/removeAlertByType', {type: type});
	}

	async function clearErrorAlert() {
		return store.dispatch('alert/removeAlertByType', {type: AlertTypes.ERROR});
	}

	async function clearSuccessAlert() {
		return store.dispatch('alert/removeAlertByType', {type: AlertTypes.SUCCESS});
	}

	async function resetAllAlerts() {
		return store.dispatch('alert/resetAlerts');
	}

	return {
		getErrors,
		addError,
		getSuccesses,
		addSuccess,
		getWarnings,
		getSecondaries,
		getAllAlerts,
		addWarning,
		addSecondary,
		removeAlert,
		removeAlertByType,
		clearErrorAlert,
		clearSuccessAlert,
		resetAllAlerts,
	}
}
