import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "rounded-3xl px-3 font-comfortaa py-0 bg-black dark:bg-gray-400 font-bold text-white dark:text-black mt-0 float-right text-tc-bg text-xs sm:text-base sm:mr-1.5 -mt-1 sm:-mt-2" }
const _hoisted_2 = { class: "" }
const _hoisted_3 = { class: "mx-4 md:mx-7" }
const _hoisted_4 = { class: "py-32 xl:py-36 px-4 sm:px-6 lg:px-8 bg-tc-bg overflow-hidden" }
const _hoisted_5 = { class: "max-w-max lg:max-w-7xl mx-auto" }
const _hoisted_6 = { class: "relative mb-8 md:mb-2 md:px-6" }
const _hoisted_7 = { class: "text-base max-w-prose lg:max-w-none" }
const _hoisted_8 = { class: "mt-2 text-3xl leading-8 font-extrabold tracking-tight sm:text-4xl" }
const _hoisted_9 = { class: "relative" }
const _hoisted_10 = { class: "relative md:bg-tc-bg md:p-6" }
const _hoisted_11 = { class: "lg:grid lg:gap-6" }
const _hoisted_12 = { class: "break-words w-full text-lg lg:text-xl py-0 sm:py-0 lg:py-0" }
const _hoisted_13 = { class: "mb-5" }
const _hoisted_14 = { class: "mb-5" }
const _hoisted_15 = {
  class: "no-underline",
  href: "https://theconcept-technologies.com"
}
const _hoisted_16 = { class: "mb-5" }
const _hoisted_17 = { class: "mb-5" }
const _hoisted_18 = {
  class: "no-underline",
  href: "mailto:office@theconcept.app"
}
const _hoisted_19 = { class: "mb-5" }
const _hoisted_20 = { class: "mb-5" }
const _hoisted_21 = { class: "mb-5" }
const _hoisted_22 = { class: "mb-5" }
const _hoisted_23 = { class: "mb-5" }
const _hoisted_24 = { class: "mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderLogo = _resolveComponent("HeaderLogo")!
  const _component_FooterBar = _resolveComponent("FooterBar")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode(_component_HeaderLogo, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('beta')), 1)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.t('imprint.ImprintTitle')), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, [
                          _createElementVNode("div", _hoisted_13, [
                            _createElementVNode("p", null, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.t('imprint.ImprintNotice')), 1)
                            ]),
                            _createElementVNode("p", null, _toDisplayString(_ctx.t('imprint.ImprintDetail')), 1),
                            _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
                            _createElementVNode("p", null, _toDisplayString(_ctx.t('imprint.CompanyName')), 1),
                            _createElementVNode("p", null, _toDisplayString(_ctx.t('imprint.CompanyStreet')), 1),
                            _createElementVNode("p", null, _toDisplayString(_ctx.t('imprint.CompanyTown')), 1),
                            _createElementVNode("p", null, _toDisplayString(_ctx.t('imprint.CompanyCountry')), 1)
                          ]),
                          _createElementVNode("div", _hoisted_14, [
                            _createElementVNode("p", null, _toDisplayString(_ctx.t('imprint.CompanyInfo')), 1),
                            _createElementVNode("p", null, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.t('imprint.CompanyWebsiteTitle')), 1),
                              _cache[1] || (_cache[1] = _createTextVNode()),
                              _createElementVNode("a", _hoisted_15, _toDisplayString(_ctx.t('imprint.CompanyWebsite')), 1)
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_16, [
                            _createElementVNode("p", null, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.t('imprint.ObjectTitle')), 1),
                              _createTextVNode(" " + _toDisplayString(_ctx.t('imprint.ObjectDetail')), 1)
                            ]),
                            _createElementVNode("p", null, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.t('imprint.VatTitle')), 1),
                              _createTextVNode(" " + _toDisplayString(_ctx.t('imprint.VatDetail')), 1)
                            ]),
                            _createElementVNode("p", null, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.t('imprint.CoReCoTitle')), 1),
                              _createTextVNode(" " + _toDisplayString(_ctx.t('imprint.CoReCoDetail')), 1)
                            ]),
                            _createElementVNode("p", null, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.t('imprint.CoLoTitle')), 1),
                              _createTextVNode(" " + _toDisplayString(_ctx.t('imprint.CompanyStreet')), 1)
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_17, [
                            _createElementVNode("p", null, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.t('imprint.MailTitle')), 1),
                              _cache[2] || (_cache[2] = _createTextVNode()),
                              _createElementVNode("a", _hoisted_18, _toDisplayString(_ctx.t('imprint.MailDetail')), 1)
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_19, [
                            _createElementVNode("p", null, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.t('imprint.LawsTitle')), 1),
                              _createTextVNode(" " + _toDisplayString(_ctx.t('imprint.LawsDetail')), 1)
                            ]),
                            _createElementVNode("p", null, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.t('imprint.SupervisoryTitle')), 1),
                              _createTextVNode(" " + _toDisplayString(_ctx.t('imprint.SupervisoryDetail')), 1)
                            ]),
                            _createElementVNode("p", null, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.t('imprint.AwardTitle')), 1),
                              _createTextVNode(" " + _toDisplayString(_ctx.t('imprint.AwardDetail')), 1)
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_20, [
                            _createElementVNode("p", null, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.t('imprint.DataProtectionTitle')), 1)
                            ]),
                            _createElementVNode("p", null, _toDisplayString(_ctx.t('imprint.DataProtectionDetail')), 1),
                            _createElementVNode("p", null, _toDisplayString(_ctx.t('imprint.DataProtectionDetail2')), 1)
                          ]),
                          _createElementVNode("div", _hoisted_21, [
                            _createElementVNode("p", null, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.t('imprint.EuTitle')), 1)
                            ]),
                            _createElementVNode("p", null, _toDisplayString(_ctx.t('imprint.EuDetail')), 1)
                          ]),
                          _createElementVNode("div", _hoisted_22, [
                            _createElementVNode("p", null, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.t('imprint.LiabilityContentTitle')), 1)
                            ]),
                            _createElementVNode("p", null, _toDisplayString(_ctx.t('imprint.LiabilityContentDetail')), 1)
                          ]),
                          _createElementVNode("div", _hoisted_23, [
                            _createElementVNode("p", null, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.t('imprint.LiabilityLinkTitle')), 1)
                            ]),
                            _createElementVNode("p", null, _toDisplayString(_ctx.t('imprint.LiabilityLinkDetail')), 1)
                          ]),
                          _createElementVNode("div", _hoisted_24, [
                            _createElementVNode("p", null, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.t('imprint.CopyrightTitle')), 1)
                            ]),
                            _createElementVNode("p", null, _toDisplayString(_ctx.t('imprint.CopyrightDetail')), 1)
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _createVNode(_component_FooterBar)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}