<template>
	<div v-if="getImages()?.length > 0">
		<div v-if="getImages().length > 1" class="grid my-1 sm:my-0 grid-cols-2 gap-2 sm:gap-4">
			<img v-for="url in getImages()" :key="url" class="cursor-pointer h-full rounded-xl max-h-36" :src="url" alt="image" @click="openModal(url)"/>
		</div>
		<div v-else>
			<img v-for="url in getImages()" :key="url" class="cursor-pointer rounded-xl my-1 sm:my-0 max-h-36" :src="url" alt="image" @click="openModal(url)"/>
		</div>

		<blockquote class="w-full text-center italic">
			<DefaultOutputElement :content="$t('assistant.image.variation.request.title')+'...'"></DefaultOutputElement>
		</blockquote>

		<ion-modal id="image-modal" :is-open="showModal" @ionModalDidDismiss="closeModal">
			<ion-content class="ion-padding">
				<div class="modal-content flex justify-center items-center">
					<img :src="modalImage" alt="Fullscreen Image" class="my-1 sm:my-0 max-w-full max-h-full">
					<ion-button @click="closeModal()" color="light" class="absolute top-4 end-4">
						<span class="sr-only">{{ $t('close') }}</span>
						<XMarkIcon class="h-6 w-6" aria-hidden="true"/>
					</ion-button>
				</div>
			</ion-content>
		</ion-modal>
	</div>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue';
import {SearchItemImageVariation} from '@/greeve/search/item/type/subtype/search_item_image_variation.type';
import {XMarkIcon} from '@heroicons/vue/24/outline';
import {IonContent, IonModal, IonButton, useBackButton} from '@ionic/vue';
import DefaultOutputElement from '@/components/assistant/Output/Elements/DefaultOutputElement.vue';

export default defineComponent({
	name: 'ImageVariationRequestImageElement',
	components: {DefaultOutputElement, IonContent, IonModal, IonButton, XMarkIcon},
	props: {
		searchItem: {
			type: Object as () => SearchItemImageVariation,
		},
	},
	emits: [],
	setup(props) {
		const showModal = ref(false);
		const modalImage = ref('');

		const openModal = (imageUrl: string) => {
			if (!imageUrl) return;
			modalImage.value = imageUrl;
			showModal.value = true;
		};

		const closeModal = () => {
			showModal.value = false;
		};

		const getImages = (): Array<string>|any => {
			if (!props.searchItem) {
				return [];
			}
			return [props.searchItem.getOriginalImage()];
		};

		useBackButton(10, () => {
			closeModal();
		});

		return {
			showModal,
			modalImage,
			openModal,
			closeModal,
			getImages
		};
	}
});

</script>
<style lang="scss" scoped>

ion-modal#image-modal {
	--height: 100%;
	--width: 100%;
	//--width: fit-content;
	//--min-width: 250px;
	//--height: fit-content;
	//--border-radius: 6px;
	//--box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

.modal-content {
	height: 100%;
	padding: 0rem;
}
</style>