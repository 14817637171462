<template>
  <ion-page>
    <ion-content :fullscreen="true">
		<HeaderLogo>
			<div  class="rounded-3xl px-3 font-comfortaa py-0 bg-black dark:bg-gray-400 font-bold text-white dark:text-black mt-0 float-right text-tc-bg text-xs sm:text-base sm:mr-1.5 -mt-1 sm:-mt-2 ">{{ $t('beta2') }}
			</div>
		</HeaderLogo>      <div class="mx-4 md:mx-7">
        <div class="py-32 xl:py-36 px-4 sm:px-6 lg:px-8 bg-tc-bg overflow-hidden">
          <div class="max-w-max lg:max-w-7xl mx-auto">
            <div class="relative mb-8 md:mb-2 md:px-6">
              <div class="text-base max-w-prose lg:max-w-none">
                <p class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-tc-blue sm:text-4xl">
                  {{ t('pressKit.socialMediaTitle') }}</p>
              </div>
            </div>
            <div class="md:px-6">
            <SocialMedia></SocialMedia>
            </div>
          </div>
        </div>
      </div>
      <FooterBar></FooterBar>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {IonPage, IonContent} from '@ionic/vue';
import FooterBar from "@/components/positions/FooterBar.vue";
import HeaderLogo from "@/components/branding/HeaderLogo.vue";
import useTranslation from "@/composable/translation/useTranslation";
import SocialMedia from "@/components/branding/SocialMedia.vue";

export default defineComponent({
  name: 'PressPage',
  components: {
    SocialMedia,
    HeaderLogo,
    FooterBar, IonContent, IonPage},

  setup() {

    const {t} = useTranslation();
    return {t}  }
});

</script>
<style lang="scss">
</style>
