<template>
	<div class="space-y-4">
		<form @submit.prevent="updateUserPassword">
			<div class="sm:overflow-hidden rounded-xl nav backdrop-filter backdrop-blur-3xl">
				<div class="nav rounded-t-xl space-y-6 py-6 px-4 sm:p-6">
					<div>
						<h3 class="text-lg font-medium leading-6 text-gray-900 dark:text-gray-500">
							{{ $t('updatePassword') }}
						</h3>
						<p class="mt-1 text-sm text-gray-600">
							{{ $t('ensureYourAccountIsUsingALongRandomPasswordToStayS') }}
						</p>
					</div>

					<div class="grid grid-cols-6 gap-6">
						<div class="col-span-6 sm:col-span-4">
							<label for="current_password"
								   class="block text-sm font-medium text-gray-700 dark:text-gray-600">{{ $t('currentPassword') }}</label>
							<input id="current_password" type="password" v-model="userPassword.current_password"
								   class="mt-1 block w-full rounded-xl border-2 border-gray-200 dark:border-gr-darker py-2 px-3 shadow-sm focus:outline-none sm:text-sm"
								   ref="current_password" autocomplete="current-password"/>
						</div>

						<div class="col-span-6 sm:col-span-4">
							<label for="password" class="block text-sm font-medium text-gray-700 dark:text-gray-600">{{ $t('newPassword') }}</label>
							<input id="password" type="password" v-model="userPassword.password"
								   class="mt-1 block w-full rounded-xl border-2 border-gray-200 dark:border-gr-darker py-2 px-3 shadow-sm focus:outline-none sm:text-sm"
								   ref="password" autocomplete="new-password"/>
						</div>

						<div class="col-span-6 sm:col-span-4">
							<label for="password_confirmation"
								   class="block text-sm font-medium text-gray-700 dark:text-gray-600">{{ $t('confirmPassword') }}</label>
							<input id="password_confirmation" type="password"
								   v-model="userPassword.password_confirmation"
								   class="mt-1 block w-full rounded-xl border-2 border-gray-200 dark:border-gr-darker py-2 px-3 shadow-sm focus:outline-none sm:text-sm"
								   autocomplete="new-password"/>
						</div>

						<div class="col-span-6 sm:col-span-4"
							 v-if="userPassword.password.length > 0 || userPassword.password_confirmation.length > 0">
							<ul>
								<li class="flex items-center py-1">
									<div :class="{'bg-green-200 text-green-700': userPassword.password == userPassword.password_confirmation && userPassword.password.length > 0, 'bg-red-200 text-red-700':userPassword.password != userPassword.password_confirmation || userPassword.password.length == 0}"
										 class=" rounded-full p-1 fill-current ">
										<svg class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
											<path v-show="userPassword.password == userPassword.password_confirmation && userPassword.password.length > 0"
												  stroke-linecap="round"
												  stroke-linejoin="round" stroke-width="2"
												  d="M5 13l4 4L19 7"/>
											<path v-show="userPassword.password != userPassword.password_confirmation || userPassword.password.length == 0"
												  stroke-linecap="round"
												  stroke-linejoin="round" stroke-width="2"
												  d="M6 18L18 6M6 6l12 12"/>

										</svg>
									</div>
									<span :class="{'text-green-700': userPassword.password == userPassword.password_confirmation && userPassword.password.length > 0, 'text-red-700':userPassword.password != userPassword.password_confirmation || userPassword.password.length == 0}"
										  class="font-medium text-sm ml-3"
										  v-html="userPassword.password == userPassword.password_confirmation && userPassword.password.length > 0 ? 'Passwords match' : 'Passwords do not match' "></span>
								</li>
								<li class="flex items-center py-1">
									<div :class="{'bg-green-200 text-green-700': userPassword.password.length > 7, 'bg-red-200 text-red-700':userPassword.password.length < 7 }"
										 class=" rounded-full p-1 fill-current ">
										<svg class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
											<path v-show="userPassword.password.length > 7" stroke-linecap="round"
												  stroke-linejoin="round" stroke-width="2"
												  d="M5 13l4 4L19 7"/>
											<path v-show="userPassword.password.length < 7" stroke-linecap="round"
												  stroke-linejoin="round" stroke-width="2"
												  d="M6 18L18 6M6 6l12 12"/>

										</svg>
									</div>
									<span :class="{'text-green-700': userPassword.password.length > 7, 'text-red-700':userPassword.password.length < 7 }"
										  class="font-medium text-sm ml-3"
										  v-html="userPassword.password.length > 7 ? 'The minimum length is reached' : 'At least 8 characters required' "></span>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="px-4 py-3 text-right nav rounded-b-xl sm:px-6">
					<button type="submit" :disabled="!isPasswordValid()"
							:class="['inline-flex justify-center rounded-xl bg-black dark:bg-gr-darker py-1.5 px-10 text-sm font-semibold text-white shadow-sm focus:outline-none', !isPasswordValid() ? 'disabled:opacity-50' : '']">
						{{ $t('save') }}
					</button>
				</div>
			</div>
		</form>

		<!--	<form action="#" method="POST">-->
		<!--		<div class=" sm:overflow-hidden rounded-xl">-->
		<!--			<div class="space-y-6 nav backdrop-filter backdrop-blur-3xl py-6 px-4 sm:p-6">-->
		<!--				<div>-->
		<!--					<h3 class="text-lg font-medium leading-6 text-gray-900">-->
		<!--						Two Factor Authentication-->
		<!--					</h3>-->
		<!--					<p class="mt-1 text-sm text-gray-500">-->
		<!--						Add additional security to your account using two factor authentication.-->
		<!--					</p>-->
		<!--				</div>-->

		<!--				<div class="grid grid-cols-6 gap-6">-->
		<!--						<h3 class="text-lg font-medium text-gray-900" v-if="twoFactorEnabled">-->
		<!--							You have enabled two factor authentication.-->
		<!--						</h3>-->

		<!--						<h3 class="text-lg font-medium text-gray-900" v-else>-->
		<!--							You have not enabled two factor authentication.-->
		<!--						</h3>-->

		<!--						<div class="mt-3 max-w-xl text-sm text-gray-600">-->
		<!--							<p>-->
		<!--								When two factor authentication is enabled, you will be prompted for a secure, random token during authentication. You may retrieve this token from your phone's Google Authenticator application.-->
		<!--							</p>-->
		<!--						</div>-->

		<!--						<div v-if="twoFactorEnabled">-->
		<!--							<div v-if="qrCode">-->
		<!--								<div class="mt-4 max-w-xl text-sm text-gray-600">-->
		<!--									<p class="font-semibold">-->
		<!--										Two factor authentication is now enabled. Scan the following QR code using your phone's authenticator application.-->
		<!--									</p>-->
		<!--								</div>-->

		<!--								<div class="mt-4 dark:p-4 dark:w-56 dark:bg-white" v-html="qrCode">-->
		<!--								</div>-->
		<!--							</div>-->

		<!--							<div v-if="recoveryCodes.length > 0">-->
		<!--								<div class="mt-4 max-w-xl text-sm text-gray-600">-->
		<!--									<p class="font-semibold">-->
		<!--										Store these recovery codes in a secure password manager. They can be used to recover access to your account if your two factor authentication device is lost.-->
		<!--									</p>-->
		<!--								</div>-->

		<!--								<div class="grid gap-1 max-w-xl mt-4 px-4 py-4 font-mono text-sm bg-gray-100 rounded-lg">-->
		<!--									<div v-for="code in recoveryCodes" :key="code">-->
		<!--										{{ code }}-->
		<!--									</div>-->
		<!--								</div>-->
		<!--							</div>-->
		<!--						</div>-->

		<!--						<div class="mt-5">-->
		<!--							<div v-if="! twoFactorEnabled">-->
		<!--							</div>-->

		<!--							<div v-else>-->
		<!--							</div>-->
		<!--						</div>-->
		<!--			</div>-->
		<!--		</div>-->
		<!--			<div class="nav  backdrop-filter backdrop-blur-3xl px-4 py-3 text-right sm:px-6">-->
		<!--				<button type="submit"-->
		<!--						class="inline-flex justify-center rounded-xl bg-black py-1.5 px-10 text-sm font-semibold text-white shadow-sm focus:outline-none">-->
		<!--					Save-->
		<!--				</button>-->
		<!--			</div>-->
		<!--		</div>-->
		<!--	</form>-->
	</div>
</template>

<script lang="ts">
import {ref, defineComponent, onBeforeMount} from 'vue';
import useTranslation from "@/composable/translation/useTranslation";
import useUser from "@/composable/greeve/useUser";
import useAlert from "@/composable/core/useAlert";
import {GreeveApiProfile, UpdateUserPassword} from "@/greeve/api/profile";

export default defineComponent({
	name: "SecurityManagement",
	components: {},
	props: {},
	emits: [],
	setup() {
		const {t} = useTranslation();
		const {getProfile} = useUser();
		const {addSuccess, addError, resetAllAlerts} = useAlert();

		const userPassword = ref({
			current_password: '',
			password: '',
			password_confirmation: '',
		});

		function isPasswordValid(): boolean
		{
			return userPassword.value.password.length > 0 && userPassword.value.password_confirmation.length > 0 && userPassword.value.password === userPassword.value.password_confirmation && userPassword.value.current_password.length > 0;
		}

		async function updateUserPassword() {
			const userPasswordData: UpdateUserPassword = {
				current_password: String(userPassword.value.current_password),
				password: String(userPassword.value.password),
				password_confirmation: String(userPassword.value.password_confirmation),
			};
			try {
				resetAllAlerts();
				await GreeveApiProfile.updatePassword(userPasswordData);
				const success = {
					id: "",
					title: "Personal Information updated!",
					message: "Your Password successfully updated",
					active: true,
				}
				addSuccess(success);
			} catch (e) {
				console.log(e);
				addError({title: t('auth.auth_error_failed_title'), message: t('auth.failed'), active: true});
			}
		}

		onBeforeMount(() => {
			//resetAllAlerts();
		});

		return {
			userPassword,
			getProfile,
			updateUserPassword,
		isPasswordValid,
		};
	}
})
</script>

<style scoped>

</style>
