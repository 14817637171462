<template>
	<h3>{{$t('assistant.image.variation.response.title')}}</h3>
	<ImageOutputElement :response="searchItem?.getResponse()"></ImageOutputElement>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {SearchItemImageVariation} from '@/greeve/search/item/type/subtype/search_item_image_variation.type';
import ImageOutputElement from '@/components/assistant/Output/Elements/ImageOutputElement.vue';

export default defineComponent({
	name: 'ImageVariationOutputElement',
	components: {ImageOutputElement},
	props: {
		searchItem: {
			type: Object as () => SearchItemImageVariation,
		},
	},
	emits: [],
	setup() {
		return {}
	}
});

</script>
<style lang="scss" scoped>
</style>
