<template>
	<div class="relative lg:min-h-full">
		<div v-if="successCart">
			<div class="rounded-2xl nav mx-auto max-w-2xl py-2 px-4 sm:px-6 sm:py-2 lg:grid lg:max-w-7xl lg:grid-cols lg:gap-x-8 lg:px-8 lg:py-32 xl:gap-x-24">
				<div class="lg:col-start-1 text-center">
					<h1 class="text-sm font-medium text-gr-primary">{{ $t('paymentSuccessful') }}</h1>
					<p class="mt-2 text-4xl font-bold tracking-tight text-gray-900 dark:text-gray-400 sm:text-5xl">{{
												$t('thanksForOrdering')
						}}</p>
					<p class="mt-2 text-base text-gray-500">{{
												$t('weAppreciateYourOrderWereCurrentlyProcessingItSoHa')
						}}</p>

					<dl class="mt-16 text-sm font-medium">
						<dt class="text-gray-900 dark:text-gray-400">{{ $t('yourOrderHasBeenConfirmedAndYouWillReceiveAnOrderC') }}</dt>
						<dd class="mt-2 text-gr-primary">{{ order.order_number }}</dd>
					</dl>

					<!--					<ul role="list" class="mt-6 divide-y divide-gray-200 border-t border-gray-200 text-sm font-medium text-gray-500">-->
					<!--						<li v-for="cartItem in successCart.items" :key="cartItem.id" class="flex space-x-6 py-6">-->
					<!--							<img :src="cartItem.getProduct()?.getDefaultImage()" :alt="cartItem?.getProduct()?.getDefaultImage()" class="h-24 w-24 flex-none rounded-md bg-gray-100 object-cover object-center" />-->
					<!--							<div class="flex-auto space-y-1">-->
					<!--								<h3 class="text-gray-900">-->
					<!--									<a :href="cartItem?.getProduct()?.getProductLink()">{{ cartItem?.getProduct()?.description }}</a>-->
					<!--								</h3>-->
					<!--							</div>-->
					<!--							<p class="flex-none font-medium text-gray-900">{{ cartItem.getAmount() }}</p>-->
					<!--						</li>-->
					<!--					</ul>-->

					<!--					<dl class="space-y-6 border-t border-gray-200 pt-6 text-sm font-medium text-gray-500">-->
					<!--						<div class="flex items-center justify-between border-t border-gray-200 pt-6 text-gray-900">-->
					<!--							<dt class="text-base">Total</dt>-->
					<!--							<dd class="text-base">{{successCart.getTotalAmountCalculated(true)}}</dd>-->
					<!--						</div>-->
					<!--					</dl>-->

					<!--					<dl class="mt-16 grid grid-cols-2 gap-x-4 text-sm text-gray-600">-->
					<!--						<div>-->
					<!--							<dt class="font-medium text-gray-900">Invoice Address</dt>-->
					<!--							<dd class="mt-2">-->
					<!--								<address class="not-italic">-->
					<!--									<span class="block">{{successCart.customer.address.street}}</span>-->
					<!--									<span class="block">{{successCart.customer.address.postal_code}} {{successCart.customer.address.city}}</span>-->
					<!--									<span class="block" v-if="successCart.customer.address.state">{{successCart.customer.address}}</span>-->
					<!--									<span class="block">{{getCountry_by_Code(successCart.customer.address.country_code)}}</span>-->
					<!--								</address>-->
					<!--							</dd>-->
					<!--						</div>-->
					<!--						<div>-->
					<!--							<dt class="font-medium text-gray-900">Payment Information</dt>-->
					<!--							<dd class="mt-2 space-y-2 sm:flex sm:space-y-0 sm:space-x-4">-->
					<!--								<div class="flex-auto">-->
					<!--									<p class="text-gray-900">Deposited in your Stripe account</p>-->
					<!--								</div>-->
					<!--							</dd>-->
					<!--						</div>-->
					<!--					</dl>-->

					<div class="hoverme mt-16 py-6 text-right">
						<default-button @click-button="redirectToFlow" :button-text="$t('letsStart')"></default-button>
						<i v-for="n in 50" :key="n"></i>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import {ref, onMounted, defineComponent, onBeforeMount} from 'vue';
import useShop from '@/composable/greeve/useShop';
import {Cart} from '@/greeve/shop/cart/cart.type';
import useCountry from '@/composable/core/useCountry';
import DefaultButton from '@/components/core/DefaultButton.vue';
import router from '@/router';
import {Order} from '@/greeve/shop/order/order.type';

export default defineComponent({
	name: 'ThankYouProcess',
	components: {DefaultButton},
	props: {
		order: {
			type: Order,
			default: undefined,
		},
	},
	emits: ['onSuccess', 'onError'],
	setup(props, {emit}) {
		const {cart, resetCart} = useShop();
		const {getCountry_by_Code} = useCountry();
		const successCart: Cart | any = ref();

		function redirectToFlow() {
			router.push('/workflow');
		}

		function onSuccess() {
			//TODO save to cart customer address
			emit('onSuccess');
		}

		function onError(message = '', inlineError = false) {
			emit('onError', message, null, false, inlineError);
		}

		onBeforeMount(() => {
			successCart.value = Object.create(cart.value);
			if (!cart.value || (successCart.value && !cart.value.checkoutSession)) {
				router.push('/workflow');
			} else {
				resetCart();
			}
		});

		onMounted(() => {
			//todo load setup and check total amount etc...
		});

		return {
			onSuccess, onError, successCart, getCountry_by_Code, redirectToFlow,
		};
	},
});
</script>

<style lang="scss">


@keyframes bang {
    from {
        transform: translate3d(0,0,0);
        opacity: 1;
    }
}
.hoverme {
    width: 240px;
    margin:150px auto 0;
    text-align: center;
    padding: 10px 0;
    cursor: pointer;
    position: relative;
span {
    color: #333;
    font-size: .9em;
}
i {
    position: absolute;
    display: block;
    left: 50%;
    top: 0;
    width: 3px;
    height: 5px;
    background: red;
    opacity: 0;
}
&:hover {
@for $i from 1 through 50 {
    i:nth-of-type(#{$i}) {
        transform: translate3d(random(190) - 100 + px,random(50) - 100 + px,0) rotate(random(360) + deg);
        background: hsla(random(360),100%,50%,1);
        animation: bang 700ms ease-out forwards;
        opacity: 0;
    }
}
}
}

</style>
