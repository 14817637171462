import {nanoid} from 'nanoid';
import {
	GreeveProductAttribute,
	GreeveProductTypeInterface,
	ProductInterface,
} from '@/greeve/shop/product/product.interface';
import i18n from '@/i18n';
import useTranslation from '@/composable/core/useTranslation';
import {Price} from '@/greeve/shop/product/price.type';

export class Product implements ProductInterface {
	id: string;
	type: GreeveProductTypeInterface;
	product_reference?: string;
	product_group?: string;
	product_id: string;
	price_id?: string;
	price: Price;
	description: string;
	tax_code: string;
	attributes?: Record<string, any>;
	images?: Array<string>;
	unit_label?: string|null;
	data?: any;
	created_at?: Date;
	updated_at?: Date;

	constructor(type: GreeveProductTypeInterface, product_id: string, price_id: string, price: Price, description: string, tax_code: string, attributes?:Record<string, any>, images?:Array<string>, unit_label?: string|null, product_reference?: string, product_group?: string, data: any = null, created_at?: Date, updated_at?: Date) {
		this.id = nanoid();
		this.type = type;
		this.product_id = product_id;
		this.price_id = price_id;
		this.price = price;
		this.description = description;
		this.tax_code = tax_code;
		this.attributes = attributes;
		this.images = images;
		this.unit_label = unit_label;
		this.product_reference = product_reference;
		this.product_group = product_group;
		this.data = data;
		this.created_at = created_at;
		this.updated_at = updated_at;
	}

	getDefaultImage(): string
	{
		let result = 'https://dummyimage.com/600x400/000/fff.jpg&text=Product+Image+not+found';
		try {
			if (this.images && this.images?.length > 0) {
				result = this.images[0];
			}
		} catch (e:any) {
			//
		}

		return result;
	}

	getProductLink(): string
	{
		let result = this.product_id;
		try {
			if (this.data && this.data.detail_reference) {
				result = this.data.detail_reference;
			}
		} catch (e:any) {
			//
		}

		return result;
	}

	/**
	 * example: shop.product.one_time.one_time_credit_90, or shop.product.recurring.recurring_starter_plan.name
	 */
	getProductName(): string
	{
		try {
			return i18n.global.t('shop.product.'+this.price.type+'.'+this.product_reference+'.name');
		} catch (e) {
			console.error(e);
			return '';
		}
	}

	/**
	 * example: shop.product.one_time.one_time_credit_90, or shop.product.recurring.recurring_starter_plan.description
	 */
	getProductDescription(): string
	{
		try {
			return i18n.global.t('shop.product.'+this.price.type+'.'+this.product_reference+'.description')
		} catch (e) {
			console.error(e);
			return '';
		}
	}

	/**
	 * example: shop.product.one_time.one_time_credit_90, or shop.product.recurring.recurring_starter_plan.description
	 */
	getProductFeatures(): Record<string, any>
	{
		try {
			if (!this.price.type) {
				return [];
			}
			const locale: string|any = useTranslation().locale.value;
			const messages: any = useTranslation().messages.value;

			//TODO soooo slooooowww
			return messages[locale]['shop']['product'][this.price.type][this.product_reference ?? '']['features'];
		} catch (e) {
			console.error(e);
			return [];
		}
	}

	getProductFeatureByReference(reference: string): string
	{
		const featureName = this.getProductFeatures()[reference] ?? '';
		if (typeof featureName === 'string') {
			return featureName;
		} else {
			return (featureName.source ? featureName.source : useTranslation().t('shop.product.'+this.price.type+'.'+this.product_reference+'.features.'+featureName.name));
		}
	}

	getAttributeByKey(key: string): any {
		if (this.attributes && this.attributes[key]) {
			return this.attributes[key];
		}
		return null;
	}

	getProductCreditAmount(): number {
		const productCreditAmount = this.getAttributeByKey(GreeveProductAttribute.STRIPE_PRODUCT_CREDIT_AMOUNT);
		if (productCreditAmount) {
			return Number(productCreditAmount);
		}
		return 0;
	}

	getDetailLink(): string {
		return '/pricing'; //TODO
	}
// {
// 	name: 'Starter',
// 	id: 'tier-starter',
// 	href: '/shop',
// 	featured: false,
// 	description: 'All essential Advantages, to start with.',
// 	price: {monthly: '€9', annually: '€90'},
// 	mainFeatures: ['30 Credits',
// 		'Access to prebuild Flows',
// 		'extendable Credits',
// 	],
// },
}