import {
	SubscriptionList
} from '@/greeve/shop/subscription/subscription_list.type';
import {Subscription} from '@/greeve/shop/subscription/subscription.type';
import {
	SubscriptionItemFactory
} from '@/greeve/shop/subscription/subscriptionItem/subscription_item_factory.type';

export class SubscriptionFactory {
	/**
	 *
	 * @param subscriptions
	 */
	createSubscriptionListByResponse(subscriptions: Record<string, any>): SubscriptionList {
		const subscriptionList = new SubscriptionList();

		if (subscriptions) {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			for (const [key, item] of Object.entries(subscriptions)) {
				subscriptionList.push(this.createSubscriptionByResponse(item));
			}
		}

		return subscriptionList;
	}

	/**
	 *
	 * @param item
	 */
	createSubscriptionByResponse(item: Record<string, any>): Subscription {
		const id = item.id;
		const user_id = item.user_id ? item.user_id : (item.userId ? item.userId : undefined);
		const name = item.name;
		const stripe_id = item.stripe_id;
		const stripe_status = item.stripe_status;
		const stripe_price = item.stripe_price;
		const quantity = item.quantity;
		const trial_ends_at = item.trial_ends_at ? new Date(item.trial_ends_at) : undefined;
		const items = item.items ? new SubscriptionItemFactory().createSubscriptionItemListListByResponse(item.items) : undefined;
		const attributes = item.attributes ? item.attributes : undefined;
		const ends_at = item.ends_at ? new Date(item.ends_at) : undefined;
		const data = item.data;
		const created_at = item.created_at ? new Date(item.created_at) : (item.createdAt ? new Date(item.createdAt) : new Date());
		const updated_at = item.updated_at ? new Date(item.updated_at) : (item.updatedAt ? new Date(item.updatedAt) : new Date());

		return new Subscription(id, user_id, name, stripe_id, stripe_status, stripe_price, quantity, trial_ends_at, items, attributes, ends_at, data, created_at, updated_at);
	}

	createEmptySubscription(): Subscription {
		return new Subscription(0, 0, '', '', '', '', 0);
	}
}