<!--suppress ALL -->

<template>
	<div :class="customClass">
		<!--    <div class="logo-mask"></div>-->
		<router-link :to="redirectLink">
			<div class="cursor-pointer">
				<div v-if="!smallLogo" class="mx-auto max-w-md">
					<img :class="'mx-auto' + ' ' + customHeight" src="https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/images/logo/icon-text.webp" alt="greeve_logo">
					<span  v-if="beta === true"><slot></slot></span>
					<span v-else class="sm:mr-1.5 -mt-1 sm:-mt-2 rounded-3xl px-3 font-comfortaa py-0 bg-black dark:bg-gray-400 font-bold text-white dark:text-black text-xs sm:text-base mt-0 float-right text-tc-bg">{{ $t('pro') }}</span>
<!--					<div v-if="!smallLogo && beta === true" class="mt-6 text-xl text-gray-400"> {{ t('logo.credo') }}-->
<!--					</div>-->
				</div>
				<div v-else>
					<img class="mx-auto max-img-height-top" src="https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/images/logo/icon-text.webp" alt="greeve_logo"/>
<!--					<span v-if="beta === true" class="-mt-0.5 rounded-3xl px-1 font-comfortaa py-0 bg-black dark:bg-gray-400 font-bold text-white dark:text-black text-xxs mt-0 float-right text-tc-bg">{{ $t('beta') }}</span>-->
<!--					<span v-else class="-mt-0.5 rounded-3xl px-1 font-comfortaa py-0 bg-black dark:bg-gray-400 font-bold text-white dark:text-black text-xxs mt-0 float-right text-tc-bg">{{ $t('pro') }}</span>-->
				</div>
				<div v-if="shortLogo === true" class="mx-auto max-w-md">
					<img :class="'mx-auto' + ' ' + customHeight" src="https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/images/logo/icon-short.webp" alt="greeve_logo">
				</div>
				<div v-else>
				</div>
			</div>
			<span class="sr-only">{{ $t('greeve') }}</span>
		</router-link>
	</div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import useTranslation from "@/composable/translation/useTranslation";

export default defineComponent({
	name: 'HeaderLogo',
	props: {
		name: String,
		smallLogo: {
			type: Boolean,
			default: false
		},
		scrollToTop: {
			type: Boolean,
			default: false
		},
		customClass: {
			type: String,
			default: 'header p-4',
		},
		customHeight: {
			type: String,
			default: 'max-img-height',
		},
		pro: {
			type: Boolean,
			default: false,
		},
		beta: {
			type: Boolean,
			default: true,
		},
		redirectLink: {
			type: String,
			default: 'search',
		},
		shortLogo: {
			type: Boolean,
			default: false,
		},
	},
	setup() {
		const {t} = useTranslation();
		return {t,};
	}
});
</script>

<style scoped>

.header {
    margin-top: 15rem;
    text-align: center;
}

.max-img-height {
    max-height: 6em;
}

.max-img-height-top {
    max-height: 1.8em;
}

.logo-mask {
    /*TODO LET CHOOSE THE AI AN IMAGE of the DAY*/
    background-image: url(https://images.pexels.com/photos/192136/pexels-photo-192136.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2);
    /*background-image: url(https://images.pexels.com/photos/192136/pexels-photo-192136.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2);*/
    background-position: center;
    background-repeat: no-repeat;
    mask-image: url('/public/assets/icon-text.svg');
    mask-size: 100 vm;
    mask-repeat: no-repeat;
    mask-position: center;
}

</style>
