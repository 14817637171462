<script setup lang="ts">
import {defineProps, ref} from 'vue';
import { XMarkIcon } from '@heroicons/vue/20/solid';
const isVisible = ref(true);

defineProps({
	title: {
		type: String,
		required: true
	},
	description: {
		type: String,
		required: false
	},
	color: {
		type: String,
		default: 'bg-gray-900',
		required: false
	},
});

const hideBanner = () => {
	isVisible.value = false;
}
</script>

<template>
	<transition v-if="isVisible" name="fade" mode="out-in">
		<div class="pointer-events-none fixed inset-x-0 bottom-0 sm:flex sm:justify-center sm:px-6 sm:pb-12 lg:px-8">
			<div :class="['banner-shadow pointer-events-auto flex items-center justify-between gap-x-6 px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5', color]">
				<p class="text-sm leading-6 text-white">
						<strong class="font-semibold" v-html="title" v-if="title.length > 0"/>
						<span v-if="description && description.length > 0" v-html="description"/>
				</p>
				<button type="button" class="-m-1.5 flex-none p-1.5" @click="hideBanner">
					<span class="sr-only">{{$t('close')}}</span>
					<XMarkIcon class="h-5 w-5 text-white" aria-hidden="true" />
				</button>
			</div>
		</div>
	</transition>
</template>

<style scoped>
.banner-shadow {
	box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
</style>