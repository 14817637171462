/* eslint-disable @typescript-eslint/no-unused-vars */

import useBlogFactory from '@/composable/blog/useBlogFactory';
import {BlogList} from '@/greeve/blog/blog_list.type';
const {getBlogFactory} = useBlogFactory();

const blog = {
	namespaced: true,
	state: () => ({
		blogs: BlogList,
	}),
	mutations: {
		setBlogs(state: any, blogList: BlogList) {
			state.blogs = blogList;
		},
	},
	actions: {
	},
	getters: {
		getBlogs: (state: any): BlogList|undefined => {
			if (state.blogs instanceof BlogList) {
				return state.blogs;
			} else if (!(state.blogs instanceof BlogList) && state.blogs) {
				return getBlogFactory().createBlogListByResponse(state.blogs);
			} else {
				return undefined;
			}
		},
	}
}

export default blog;