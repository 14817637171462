import {
	OAuthInterface,
	OAuthState,
	OAuthType,
} from '@/greeve/user/oauth/oauth.interface';
import i18n from '@/i18n';
import {formatDateIso} from '@/greeve/core/date';
import useSystem from '@/composable/core/useSystem';


export abstract class AbstractOAuth implements OAuthInterface {
	id: number;
	user_id: number;
	uuid: string;
	type: OAuthType;
	state: OAuthState;
	reference: string|null;
	name: string|null;
	token: string|null;
	refresh_token: string|null;
	expiry_date?: Date;
	data?: any;
	scope?: any;
	created_at?: Date;
	updated_at?: Date;

	constructor(
			id: number, user_id: number, uuid: string, type: OAuthType,
			state: OAuthState, reference: string|null, name: string|null, token: string|null, refresh_token: string|null, expiry_date?: Date, scope: any = null, data: any = null, created_at?: Date, updated_at?: Date) {
		this.id = id;
		this.user_id = user_id;
		this.uuid = uuid;
		this.type = type;
		this.state = state;
		this.reference = reference;
		this.name = name;
		this.token = token;
		this.refresh_token = refresh_token;
		this.expiry_date = expiry_date;
		this.scope = scope;
		this.data = data;
		this.created_at = created_at;
		this.updated_at = updated_at;
	}

	abstract getOAuthType(): string;

	getName(): string
	{
		return i18n.global.t('oauth.'+this.getOAuthType()+'.name');
	}

	getOAuthTypeForImage(): string
	{
		return this.getOAuthType();
	}

	getImageUrl(): string
	{
		return 'https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/images/socialmedia/'+this.getOAuthTypeForImage()+'.png';
	}

	getExpiryDateFormatted(): string
	{
		if (!this.expiry_date) {
			return '';
		}
		return formatDateIso(this.expiry_date);
	}

	getDataValueByKey(key: string): any
	{
		if (this.data) {
			const parsedData = JSON.parse(this.data);
			if (parsedData && parsedData['authResponse'][key]) {
				return parsedData['authResponse'][key];
			}
		}
		return undefined;
	}

	getScopesFormatted(): string
	{
		if (!this.scope && this.getDataValueByKey('grantedScopes')) {
			return this.getDataValueByKey('grantedScopes');
		}
		if (this.scope) {
			let parsedScope = this.scope;
			if (useSystem().isJson(parsedScope)) {
				parsedScope = JSON.parse(parsedScope);
			}
			if (parsedScope) {
				return parsedScope.join(', ');
			}
		}
		return '';
	}
}