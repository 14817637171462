import {
	QuotaTransactionList
} from '@/greeve/user/quota/transaction/quota_transaction_list.type';
import {
	QuotaJournalList
} from '@/greeve/user/quota/journal/quota_journal_list.type';

export const enum QuotaType {
	QUOTA_TYPE_CREDITS = 'credits',
	QUOTA_TYPE_FREE_GREEVE_ASSISTANT_CHAT_CREDITS = 'free_greeve_assistant_chat_credits',
	QUOTA_TYPE_FREE_GREEVE_ASSISTANT_IMAGE_CREDITS = 'free_greeve_assistant_image_credits',
	QUOTA_TYPE_PRO = 'pro',
	QUOTA_TYPE_STANDARD = 'standard',
	QUOTA_TYPE_MINUTES = 'minutes',
	QUOTA_TYPE_QUANTITY = 'quantity',
}

export const enum QuotaState {
	QUOTA_STATE_AVAILABLE = 'available',
	QUOTA_STATE_USED_UP = 'used_up',
	QUOTA_STATE_LOCKED = 'locked',
}

export interface QuotaInterface {
	id: number;
	uuid: string;
	user_id: number;
	type: QuotaType,
	state: QuotaState,
	total_quota: number,
	available_quota: number,
	available_quota_in_percent: number,
	quota_transactions?: QuotaTransactionList,
	quota_journals?: QuotaJournalList,
	data?: any,
	created_at?: Date,
	updated_at?: Date,
}