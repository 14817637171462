export const enum OAuthType {
	OAUTH_TYPE_META = 'meta',
	OAUTH_TYPE_META_INSTAGRAM = 'meta_instagram',
	OAUTH_TYPE_META_WHATSAPP = 'meta_whatsapp',
	OAUTH_TYPE_GOOGLE = 'google',
}

export const enum OAuthState {
	OAUTH_STATE_VALID = 'valid',
	OAUTH_STATE_INVALID = 'invalid',
	OAUTH_STATE_MISSING_INFORMATION = 'missing_information',
	OAUTH_STATE_ERROR = 'error',
}

export interface OAuthInterface {
	id: number;
	uuid: string;
	user_id: number;
	type: OAuthType,
	state: OAuthState,
	reference: string|null,
	name: string|null,
	token: string|null,
	refresh_token: string|null,
	expiry_date?: Date,
	scope?: any,
	data?: any,
	created_at?: Date,
	updated_at?: Date,
}