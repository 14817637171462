import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import './registerServiceWorker';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './assets/styles/index.css';

/* API */
import GreeveApiClient from '@/greeve/api/client';
import store from '@/store/index';
import createI18n from './i18n';

/* Other */
import VueKinesis from 'vue-kinesis';
import {IonicVue} from '@ionic/vue';
// import Echo from 'laravel-echo';
// import Pusher from 'pusher-js';
// import useAuth from '@/composable/auth/useAuth';

import { initializeApp } from 'firebase/app';
import { firebaseConfig } from './firebaseConfig';

initializeApp(firebaseConfig);

const app = createApp(App).
use(IonicVue).
use(createI18n).
use(store).
use(router).
use(VueKinesis);

GreeveApiClient.init(process.env.VUE_APP_API_STABLE_URL);
GreeveApiClient.mount401Interceptor();

router.isReady().then(() => {
	app.mount('#app');
	// initializeEcho().then(() => {
	// 	app.mount('#app');
	// });
});

// async function initializeEcho() {
//     console.log('Initializing Echo with key', process.env.VUE_APP_PUSHER_KEY, window.location.hostname); // Log the key again inside initializeEcho
//     (Window as any).Pusher = Pusher;
// 	  (Window as any).Echo = new Echo({
// 			broadcaster: 'pusher',
// 			key: process.env.VUE_APP_PUSHER_KEY,
// 			cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
// 			wsHost: process.env.VUE_APP_API_DOMAIN_RAW,
// 			wsPort: 6001,
// 			wssPort: 6001,
// 			forceTLS: process.env.NODE_ENV === 'production' ? true : false,
// 		  encrypted: process.env.NODE_ENV === 'production' ? true : false,
// 			disableStats: true,
// 			enabledTransports: ['ws', 'wss'],
// 			authEndpoint: process.env.VUE_APP_API_STABLE_URL+'/broadcasting/auth',
// 			auth: {
// 	        headers: {
// 		        "x-api-key": process.env.VUE_APP_API_KEY,
// 		        authorization: 'Bearer ' + useAuth().getToken.value,
// 	        }
// 	    }
// 	});
// }