import {AxiosResponse} from "axios";
import {GreeveApiGetResponse, ResponseError} from "@/greeve/api/api.interfaces";
import GreeveApiClient from "@/greeve/api/client";

interface GreeveGetResponse extends GreeveApiGetResponse {
	data: {type: string, result: any};
	status: any;
}

class GreeveResponseError extends ResponseError {
}

const GreeveApiContact = {
	sendContact: async function (payload: any): Promise<any|undefined> {
		try {
			const response: AxiosResponse = await GreeveApiClient.put('/contact', payload);
			return response;
		} catch (error: any) {
			// Handle and re-throw the error
			if (error.response) {
				throw new Error(`Request failed with status code ${error.response.status}`);
			} else if (error.request) {
				throw new Error('No response was received');
			} else {
				throw new Error(`Error: ${error.message}`);
			}
		}
	},

	sendNotifyFeature: async function (payload: any): Promise<any|undefined> {
		try {
			const response: AxiosResponse = await GreeveApiClient.put('/notify/feature', payload);
			return response;
		} catch (error: any) {
			if (error.response) {
				throw new Error(`Request failed with status code ${error.response.status}`);
			} else if (error.request) {
				throw new Error('No response was received');
			} else {
				throw new Error(`Error: ${error.message}`);
			}
		}
	},
}

export {GreeveApiContact, GreeveGetResponse, GreeveResponseError};
