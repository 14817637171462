import useAuth from '@/composable/auth/useAuth';
import {MediaFileInfo} from '@/greeve/media/media_file_info.type';

export class GreeveApiAssistantStream {
    public chatStream(
        params: Record<string, string | number | undefined | null>,
        onEvent: (data: string) => void,
        onInteractionRequired: (data: string) => void,
        onBeforeEndEvent: (data: string) => void,
        onError: (data: string) => void,
        onEnd: () => void
    ) {
        const path = '/assistant/chat/create';
        const source = this.createEventSource(path, params);
        source.addEventListener('update', function (event: MessageEvent<any>) {
            if (event.data === '<END_STREAMING_SSE>') {
                source.close();
                onEnd();
            } else {
                onEvent(event.data);
            }
        });
        source.addEventListener('interactionRequired', function (event: MessageEvent<any>) {
            onInteractionRequired(event.data); //TODO add more infos not only data
        });
        source.addEventListener('beforeEndEvent', function (event: MessageEvent<any>) {
            onBeforeEndEvent(event.data);
        });
        source.addEventListener('error', function (event: MessageEvent<any>) {
            onError(event.data);
        });
    }

    public correctTextStream(
        params: Record<string, string | number | undefined | null>,
        onEvent: (data: string) => void,
        onInteractionRequired: (data: string) => void,
        onBeforeEndEvent: (data: string) => void,
        onError: (data: string) => void,
        onEnd: () => void
    ) {
        const path = '/assistant/chat/correct';
        const source = this.createEventSource(path, params);
        source.addEventListener('update', function (event: MessageEvent<any>) {
            if (event.data === '<END_STREAMING_SSE>') {
                source.close();
                onEnd();
            } else {
                onEvent(event.data);
            }
        });
        source.addEventListener('interactionRequired', function (event: MessageEvent<any>) {
            onInteractionRequired(event.data); //TODO add more infos not only data
        });
        source.addEventListener('beforeEndEvent', function (event: MessageEvent<any>) {
            onBeforeEndEvent(event.data);
        });
        source.addEventListener('error', function (event: MessageEvent<any>) {
            onError(event.data);
        });
    }

    public visionStream(
        params: Record<string, string | number | MediaFileInfo | undefined | null>,
        onEvent: (data: string) => void,
        onInteractionRequired: (data: string) => void,
        onBeforeEndEvent: (data: string) => void,
        onError: (data: string) => void,
        onEnd: () => void
    ) {
        const path = '/assistant/vision/create';
        const source = this.createEventSource(path, params);
        source.addEventListener('update', function (event: MessageEvent<any>) {
            if (event.data === '<END_STREAMING_SSE>') {
                source.close();
                onEnd();
            } else {
                onEvent(event.data);
            }
        });
        source.addEventListener('interactionRequired', function (event: MessageEvent<any>) {
            onInteractionRequired(event.data); //TODO add more infos not only data
        });
        source.addEventListener('beforeEndEvent', function (event: MessageEvent<any>) {
            onBeforeEndEvent(event.data);
        });
        source.addEventListener('error', function (event: MessageEvent<any>) {
            onError(event.data);
        });
    }

    public retryItemStream(
        params: Record<string, string | number | undefined | null>,
        onEvent: (data: string) => void,
        onInteractionRequired: (data: string) => void,
        onBeforeEndEvent: (data: string) => void,
        onError: (data: string) => void,
        onEnd: () => void
    ) {
        if (!useAuth().isAuthenticated.value) {
            throw new Error('Not Authorized');
        }
        const path = '/assistant/regenerate/itemStream';
        const source = this.createEventSource(path, params);
        source.addEventListener('update', function (event: MessageEvent<any>) {
            if (event.data === '<END_STREAMING_SSE>') {
                source.close();
                onEnd();
            } else {
                onEvent(event.data);
            }
        });
        source.addEventListener('interactionRequired', function (event: MessageEvent<any>) {
            onInteractionRequired(event.data); //TODO add more infos not only data
        });
        source.addEventListener('beforeEndEvent', function (event: MessageEvent<any>) {
            onBeforeEndEvent(event.data);
        });
        source.addEventListener('error', function (event: MessageEvent<any>) {
            onError(event.data);
        });
    }

    public completionStream(
        params: Record<string, string | number | undefined | null>,
        onEvent: (data: string) => void,
        onBeforeEndEvent: (data: string) => void,
        onError: (data: string) => void,
        onEnd: () => void
    ) {
        const path = '/assistant/completion/create';
        const source = this.createEventSource(path, params);
        source.addEventListener('update', function (event: MessageEvent<any>) {
            if (event.data === '<END_STREAMING_SSE>') {
                source.close();
                onEnd();
            } else {
                onEvent(event.data);
            }
        });
        source.addEventListener('beforeEndEvent', function (event: MessageEvent<any>) {
            onBeforeEndEvent(event.data);
        });
        source.addEventListener('error', function (event: MessageEvent<any>) {
            onError(event.data);
        });
    }

    private createEventSource(path: string, params: Record<string, string | number | MediaFileInfo | undefined | null>) {
        const authToken = useAuth().getToken.value;
        if (!authToken || authToken.length === 0) {
            throw new Error("Not authorized");
        }

        const encodedParameters = this.encodeParameters(params);
        //TODO check if x-api-key is necesary / check and create a hash!!!
        const url = `${process.env.VUE_APP_API_STABLE_URL}${path}?x-api-key=${process.env.VUE_APP_API_KEY}${encodedParameters}&Authorization=Bearer ${authToken}`;
        return new EventSource(url);
    }

    private encodeParameters(params: Record<string, string | number | MediaFileInfo | undefined | null>) {
        return Object.entries(params)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([_, value]) => value !== null)
        .map(([key, value]) => `&${encodeURIComponent(key)}=${encodeURIComponent(value as string | number)}`)
        .join('');
    }
}
