<template>
	<!--One Time Purchase-->
	<section aria-labelledby="oneTimePurchase-heading" id="GreeveOneTime"
					 v-if="oneTimeProductList">
		<div
				:class="['sm:overflow-hidden rounded-xl nav backdrop-filter backdrop-blur-3xl', !hideBottomPadding ? 'pb-28' : '']">
			<div class="rounded-t-xl space-y-6 py-6 px-4 sm:p-6">
				<div>
					<h2 id="oneTimeHeading-heading"
							class="text-lg font-medium leading-6 text-gray-900 dark:text-gray-400">{{ $t('oneTimePurchase') }}</h2>
					<p class="mt-1 text-sm text-gray-500">{{ $t('youAlwaysCanPayMoreCreditsForYourPlan') }}</p>
				</div>
				<Listbox class="z-10 w-full" as="div" v-model="selected">
					<div class="mt-2 flex items-center gap-x-4"><p
							class="text-gray-900 dark:text-gray-400 text-4xl font-bold tracking-tight">
						{{ selected.price?.getFormattedAmount(false, false) }}</p>
						<div class="text-sm leading-5 "><p class="text-gray-900 dark:text-gray-400">
							{{ $t('eur') }}</p>
							<p class="text-gray-500">{{ $t('oneTimePurchase') }}</p></div>
					</div>
					<div class="relative mt-2">
						<ListboxButton
								class="relative w-full bg-gray-200  dark:bg-gr-dark cursor-default rounded-xl py-1.5 pl-3 pr-10 text-left text-gray-900 dark:text-gray-400 font-semibold shadow-sm ring-2 ring-inset ring-gray-200 dark:ring-gr-dark focus:outline-none focus:ring-2 sm:text-sm sm:leading-6">
        <span class="inline-flex w-full truncate">
          <span class="truncate">{{ selected.getProductName() }}</span>
					<!--          <span class="ml-2 truncate text-gray-500">{{ selected.points }}</span>-->
        </span> <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true"/>
        </span>
						</ListboxButton>
						<ListboxOptions
								class="relative z-10 mt-1.5 max-h-80 w-full overflow-auto rounded-xl bg-white dark:text-gray-400 dark:bg-gr-dark text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
							<ListboxOption as="template" v-for="product in getOneTimeProducts()"
														 :key="product.id"
														 :value="product" v-slot="{ active, selected }">
								<li :class="[active ? 'dark:bg-gr-darker bg-gray-200' : 'dark:text-gray-400 text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
									<div class="flex">
															<span :class="[selected ? 'font-semibold' : 'font-normal', 'truncate']">{{
																	product?.getProductName()
																}}</span>
										<!--											<span v-if="showProductFeatures" :class="[selected ? 'font-semibold' : 'font-normal', 'truncate']">{{-->
										<!--													product?.getProductFeatures()-->
										<!--												}}</span>-->
										<span :class="[active ? 'dark:text-gray-400 text-gray-700' : 'text-gray-500', 'ml-2 truncate']">€ {{
												product?.price?.getFormattedAmount(false, false)
											}}</span>
									</div>
									<span v-if="selected"
												:class="[active ? 'dark:text-gray-400 text-gray-900' : 'dark:text-gray-400 text-gray-900', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                <CheckIcon class="h-5 w-5" aria-hidden="true"/>
              </span>
								</li>
							</ListboxOption>
						</ListboxOptions>
					</div>
				</Listbox>

				<div class="rounded-b-xl py-3 text-right">
					<button @click="addSelectedOneTimeToCart" aria-describedby="tier-scale"
									class="inline-flex justify-center rounded-xl bg-gr-primary hover:bg-gr-primary2 py-1.5 px-10 text-sm font-semibold text-white shadow-sm focus:outline-none">
						{{ $t('buyNow') }}
					</button>
				</div>
			</div>
		</div>
	</section>
</template>

<script lang="ts">
import {ref, defineComponent, onBeforeMount} from 'vue';
import useShop from '@/composable/greeve/useShop';
import {
	CheckIcon,
	ChevronDownIcon,
} from '@heroicons/vue/24/outline';
import {useRouter} from 'vue-router';
import {ProductList} from '@/greeve/shop/product/product_list.type';
import {Product} from '@/greeve/shop/product/product.type';
import {Listbox, ListboxButton, ListboxOption, ListboxOptions} from '@headlessui/vue';

export default defineComponent({
	name: 'GreeveOneTimeProducts',
	components: {
		ListboxOption,
		ListboxOptions,
		ListboxButton,
		Listbox,
		CheckIcon,
		ChevronDownIcon,
	},
	props: {
		showProductFeatures: {
			type: Boolean,
			default: false,
		},
		showTransition: {
			type: Boolean,
			default: false,
		},
		hideBottomPadding: {
			type: Boolean,
			default: false,
		},
	},
	emits: [],
	setup() {
		const router = useRouter();
		const {products, addProductToCart, initProducts} = useShop();
		const oneTimeProductList: ProductList | any[] | any = ref();
		const isProductLoading = ref(false);
		const selected: Product | any = ref();

		function addSelectedOneTimeToCart() {
			if (selected.value) {
				addProductToCart(selected.value.product_id);
				router.push('/shop');
			}
		}

		function getOneTimeProducts(force = false): ProductList | Product[] {
			if (!oneTimeProductList.value || force) {
				if (!isProductLoading.value) {
					oneTimeProductList.value = products.value.getOneTimeProductList();
					selected.value = oneTimeProductList.value[1];
				}
			}
			return oneTimeProductList.value;
		}

		async function initProductsForView() {
			getOneTimeProducts(true);
		}

		onBeforeMount(() => {
			try {
				//TODO performance!! if products older than 8 hours or dev
				if (products.value.getOneTimeProductList().length) {
					initProductsForView();
				} else {
					isProductLoading.value = true;
					initProducts(true).then(() => {
						isProductLoading.value = false;
						initProductsForView();
					});
				}
			} catch (e) {
				console.error(e);
			}
		});

		return {
			selected,
			products,
			getOneTimeProducts,
			addSelectedOneTimeToCart,
			oneTimeProductList,
		};
	},
});
</script>

<style scoped>

</style>
