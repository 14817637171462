import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "relative mx-auto grid lg:px-8" }
const _hoisted_2 = { class: "mt-6 py-16" }
const _hoisted_3 = { class: "mt-6 sm:px-6 lg:row-start-1 lg:px-0" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { class: "relative lg:min-h-full justify-center" }
const _hoisted_7 = { class: "mx-auto max-w-2xl py-2 px-4 sm:px-6 sm:py-2 lg:grid lg:max-w-7xl lg:grid-cols lg:gap-x-8 lg:px-8 lg:py-32 xl:gap-x-24" }
const _hoisted_8 = { class: "lg:col-start-1 text-center rounded-md bg-red-50 p-4" }
const _hoisted_9 = { class: "ml-3" }
const _hoisted_10 = { class: "text-sm font-medium text-red-800" }
const _hoisted_11 = { class: "text-sm font-medium" }
const _hoisted_12 = { class: "mx-auto py-16 px-4 sm:px-6 sm:py-24 lg:px-8 lg:py-32" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_line_animation = _resolveComponent("loading-line-animation")!
  const _component_LoadingAnimation = _resolveComponent("LoadingAnimation")!
  const _component_ThankYouProcess = _resolveComponent("ThankYouProcess")!
  const _component_default_button = _resolveComponent("default-button")!
  const _component_FooterBar = _resolveComponent("FooterBar")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_loading_line_animation, { key: 0 }))
            : _createCommentVNode("", true),
          _createElementVNode("main", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                (_ctx.isLoading)
                  ? (_openBlock(), _createBlock(_component_LoadingAnimation, {
                      key: 0,
                      "fixed-loading-animation": true
                    }))
                  : _createCommentVNode("", true),
                ((_ctx.isOrderSuccessfully && !_ctx.isLoading))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode(_component_ThankYouProcess, { order: _ctx.lastOrder }, null, 8, ["order"])
                    ]))
                  : ((!_ctx.isOrderSuccessfully && !_ctx.isLoading))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("div", _hoisted_7, [
                            _createElementVNode("div", _hoisted_8, [
                              _cache[0] || (_cache[0] = _createElementVNode("div", { class: "flex-shrink-0" }, null, -1)),
                              _createElementVNode("div", _hoisted_9, [
                                _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t('thereWasAnError')), 1),
                                _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t('pleaseCallTheSupportForHelp')), 1)
                              ])
                            ])
                          ]),
                          _createElementVNode("div", null, [
                            _createElementVNode("div", _hoisted_12, [
                              _createVNode(_component_default_button, {
                                onClickButton: _ctx.redirectToCheckout,
                                "button-text": _ctx.$t('backToCheckout')
                              }, null, 8, ["onClickButton", "button-text"])
                            ])
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true)
              ])
            ])
          ]),
          _createVNode(_component_FooterBar, { class: "mt-12" })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}