import {
	JournalLineInterface,
	JournalLineState,
	JournalLineType
} from "@/greeve/shop/order/journal/journalLine/journal_line.interface";

export class JournalLine implements JournalLineInterface {
	id: number;
	journal_id: number;
	parent_id?: number|null;
	type: JournalLineType;
	state?: JournalLineState|null;
	net_amount: number;
	total_amount: number;
	tax_amount: number;
	item_quantity: number;
	grand_total: number;
	data?: any;
	created_at?: Date;
	updated_at?: Date;

	constructor(id: number, journal_id: number, type: JournalLineType, net_amount: number, total_amount: number, tax_amount: number, item_quantity: number, grand_total: number, parent_id?: number|null, state?: JournalLineState|null, data: any = null, created_at?: Date, updated_at?: Date) {
		this.id = id;
		this.journal_id = journal_id;
		this.parent_id = parent_id;
		this.type = type;
		this.state = state;
		this.net_amount = net_amount;
		this.total_amount = total_amount;
		this.tax_amount = tax_amount;
		this.item_quantity = item_quantity;
		this.grand_total = grand_total;
		this.data = data;
		this.created_at = created_at;
		this.updated_at = updated_at;
	}
}