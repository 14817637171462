import {PaginationMetaInterface} from "@/greeve/core/pagination_meta.interface";
import useCustomStore from "@/composable/custom/useCustomStore";
import {Blog} from '@/greeve/blog/blog.type';
import useSystem from '@/composable/core/useSystem';

const {getLanguageLocale} = useCustomStore();

export class BlogList extends Array<Blog> {

	public paginationMetaData?: PaginationMetaInterface;

	constructor(items: Array<Blog> = []) {
		super();
		this.push(...items);
	}

	add(items: Blog) {
		this.push(items);
	}

	remove(index: number) {
		this.splice(index, 1);
	}

	removeAll() {
		const itemCount = this.length;
		this.splice(itemCount, 1);
	}

	getItem(index: number): Blog {
		return this[index];
	}

	enhanceMetaData(metaData: any) {
		this.paginationMetaData = metaData;
	}

	getBlogsByLanguage(language: string|undefined = undefined): BlogList|Blog[]
	{
		if (!language) {
			language = getLanguageLocale.value;
		}
		const blogList: BlogList|any[] = [...this];
		return blogList.filter((blog) => {
			return blog.language === language
		});
	}

	getBlogBySlug(slug: string|undefined = undefined): Blog|undefined
	{
		const blogList: BlogList|any[] = [...this];
		//TODO find only one blog with same slug
		return blogList.find((blog) => {
			return blog.slug === slug;
		})
	}

	initSeo() {
		const seoManager = useSystem().getSeoManager();
		const blogTitles: Array<string> = [];
		const keywords: Array<string> = [];
		this.forEach((blog) => {
			blogTitles.push(blog.title);
			keywords.push(...blog.keywordList);
		});
		seoManager.updateSeo('Greeve AI Blog - ' + blogTitles.join('|'), '', keywords.join(','));
	}
}