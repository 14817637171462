import {Currency, CurrencyCode} from "@/greeve/core/country/currency.type";

export class CurrencyFactory {

	/**
	 *
	 * @param currencyCode
	 */
	createCurrencyByCode(currencyCode: string): Currency {
		return new Currency(currencyCode);
	}

	createActiveCurrencyList(): Currency[] {
		const result: Currency[] = [];
		const activeCurrencyList = [CurrencyCode.EUR];
		activeCurrencyList.forEach((currencyCode) => {
			result.push(this.createCurrencyByCode(currencyCode));
		});
		return result;
	}
}