<template>
	<section class="" aria-labelledby="login-info-heading">
		<div>
			<div v-if="isAuthenticated && !isLoginProcess && !waitForLoginEvent">
				<div class="py-2" v-show="!hideNextStepButton">
					<h2 id="contact-info-heading" class="text-lg font-semibold text-gray-900 dark:text-white">{{ $t('auth.General.already_logged_in') }}</h2>
					<h1 class="mb-4 text-sm text-gray-600">{{ $t('not_user_x', {name: user.name}) }}</h1>
				</div>
				<h1 class="mt-2"  v-show="hideNextStepButton">{{ $t('auth.General.already_logged_in') }}</h1>
				<p class="mb-4"  v-show="hideNextStepButton">{{ $t('not_user_x', {name: user.name}) }}</p>
				<div class="py-2">
					<default-button button-type="button" :button-text="$t('shop.checkout.logout.title')" @click-button="logoutUser(!hardLogout)"></default-button>
				</div>
				<div class="py-2" v-show="!hideNextStepButton">
					<default-button  button-color="bg-gr-primary" :button-text="$t('shop.checkout.contact_information.step')" button-type="button" @click-button="goToNextStep()"></default-button>
				</div>
			</div>
			<div v-else>
				<p class="-mt-4"  v-show="!hideNextStepButton"></p>
				<ion-segment v-if="defaultSegment" class="mb-16 mt-4 mx-0" mode="ios" color="dark" :value="defaultSegment" @ionChange="switchSegment($event)">
					<ion-segment-button v-for="segment in segments" :key="segment.value" :value="segment.value" @click="switchSegment(segment)">
						<ion-label v-html="segment.name"></ion-label>
					</ion-segment-button>
				</ion-segment>

				<login-component v-if="selectedTab === 'login'" :show-switch="false" :show-alerts="true" @onLoginSuccess="onSuccess()" @onLoginError="onError()"></login-component>
				<register-component v-else-if="selectedTab === 'register'" :show-switch="false" @onRegisterSuccess="onSuccess()" @onRegisterError="onError()"></register-component>
			</div>
		</div>
	</section>
</template>

<script lang="ts">
import {ref, onMounted, defineComponent, watch} from 'vue';
import useTranslation from '@/composable/translation/useTranslation';
import LoginComponent from "@/components/auth/LoginComponent.vue";
import RegisterComponent from "@/components/auth/RegisterComponent.vue";
import {TabInterface} from "@/components/core/SwitchComponent.vue";
import useAlert from "@/composable/core/useAlert";
import useShop from "@/composable/greeve/useShop";
import useAuth from "@/composable/auth/useAuth";
import DefaultButton from "@/components/core/DefaultButton.vue";
import useUser from "@/composable/greeve/useUser";
import {IonSegment, IonSegmentButton, IonLabel} from '@ionic/vue';
import useCustomStore from '@/composable/custom/useCustomStore';

const LOGIN_SEGMENT = 'login';
const REGISTER_SEGMENT = 'register';

export default defineComponent({
	name: "LoginRegisterProcess",
	components: {DefaultButton, RegisterComponent, LoginComponent, IonSegment, IonSegmentButton, IonLabel},
	props: {
		hideNextStepButton: {
			type: Boolean,
			default: false,
		},
		hardLogout: {
			type: Boolean,
			default: false,
		},
		segment: {
			type: String,
			default: LOGIN_SEGMENT,
		}
	},
	emits: ['onSuccess', 'onError'],
	setup(props, {emit}) {
		const {t} = useTranslation();
		const {clearErrorAlert} = useAlert();
		const {initCustomerByUser} = useShop();
		const {isAuthenticated, logoutWithoutLoosingCart, logout} = useAuth();
		const {user} = useUser();
		const defaultSegment = ref();
		const segments = ref([
			{name: t("auth.LoginComponent.loginTitle"), value: LOGIN_SEGMENT, link: '/login', current: true},
			{name: t("auth.RegisterComponent.registerTitle"), value: REGISTER_SEGMENT, link: '/register', current: false}
		]);
		const selectedTab = ref(props.segment);
		const isLoginProcess = ref(true);
		const waitForLoginEvent = ref(false);
		const {setIsGlobalLoading} = useCustomStore();


		function switchSegment(segment: TabInterface) {
			if (segment.value) {
				selectedTab.value = segment.value;
			} else {
				selectedTab.value = segment.name;
			}
		}

		function goToNextStep() {
			emit('onSuccess');
		}

		function onSuccess() {
			clearErrorAlert();
			initCustomerByUser();
			goToNextStep();
			waitForLoginEvent.value = false;
			isLoginProcess.value = false;
		}

		function onError(message = '', inlineError = false) {
			waitForLoginEvent.value = false;
			isLoginProcess.value = false;
			emit('onError', message, null, false, inlineError);
		}

		async function logoutUser(withoutLoosingCart = false)
		{
			try {
				setIsGlobalLoading(true);
				if (withoutLoosingCart) {
					logoutWithoutLoosingCart(true)?.then(() => {
						setIsGlobalLoading(false);
					});
				} else {
					logout(true, true)?.then(() => {
						setIsGlobalLoading(false);
					})
				}
			} catch {
				setIsGlobalLoading(false);
			}
		}

		function setCurrentSegment(value: string) {
			segments.value.forEach(segment => {
				segment.current = segment.value === value;
			});
		}

		watch(() => props.segment, (newSegment, oldSegment) => {
			if (newSegment !== oldSegment) {
				selectedTab.value = newSegment;
				defaultSegment.value = newSegment;
				setCurrentSegment(newSegment);
			}
		});

		watch(() => isAuthenticated.value, (newValue) => {
			isLoginProcess.value = !newValue;
		})

		onMounted(() => {
			if (isAuthenticated.value) {
				isLoginProcess.value = false;
				waitForLoginEvent.value = false;
			} else {
				waitForLoginEvent.value = true;
			}
			defaultSegment.value = props.segment;
		})

		return {
			defaultSegment, segments, selectedTab, onSuccess, onError, switchSegment, isAuthenticated, logoutUser, user, goToNextStep, isLoginProcess, waitForLoginEvent
		};
	}
})
</script>

<style lang="scss">

ion-segment {
  @apply h-12 lg:h-10;
  min-height: unset;
  letter-spacing: unset;
  text-transform: capitalize!important;
  width: unset;
}

ion-segment {
  @apply h-10 lg:h-10 rounded-xl;
}

ion-segment-button.ios::part(indicator-background) {
  @apply font-semibold leading-5 rounded-xl #{!Important};
}

ion-segment-button {
  --indicator-color: none;
  min-height: unset;
  letter-spacing: unset;
  text-transform: capitalize!important;
  @apply font-semibold leading-5 rounded-xl #{!Important};
}

//TODO remove animation when active
//ion-segment.ion-segment-activated {
//  background: red;
//}
ion-segment:focus {
  transform: unset!important;
  transition: unset!important;
  background: unset!important;
}

</style>
