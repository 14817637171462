import {
	AbstractFlowProcess
} from '@/greeve/flow/process/abstract_flow_process.type';
import {FlowProcessList} from '@/greeve/flow/process/flow_process_list.type';
import {
	FlowProcessType,
} from '@/greeve/flow/process/flow_process.interface';
import {
	FlowProcessDefault
} from '@/greeve/flow/process/type/default/flow_process_default.type';
import {
	FlowProcessGreeveWhisper
} from '@/greeve/flow/process/type/greeve_whisper/flow_process_greeve_whisper.type';
import {
	FlowProcessDownloadAndShow
} from '@/greeve/flow/process/type/download_and_show/flow_process_download_and_show.type';
import useFlowFactory from '@/composable/greeve/useFlowFactory';

export class FlowProcessFactory {
	/**
	 *
	 * @param flow_processes
	 */
	createFlowProcessListByResponse(flow_processes: Record<string, any>): FlowProcessList {
		const flowProcessList = new FlowProcessList();

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		for (const [key, item] of Object.entries(flow_processes)) {
			if (!item) {
				continue;
			}
			flowProcessList.push(this.createFlowProcessByResponse(item));
		}

		return flowProcessList;
	}

	/**
	 *
	 * @param item
	 */
	createFlowProcessByResponse(item: Record<string, any>): AbstractFlowProcess {
		const id = item.id ?? item.flowProcessId ?? null;
		const uuid = item.uuid;
		const flow_id = item.flow_id ? item.flow_id : (item.flowId ? item.flowId : undefined);
		const type = item.type;
		const state = item.state;
		const flow_process_config_id = item.flow_process_config_id;
		//TODO Create Flow Process CONFIG
		// const flow_config = item.flow_process_config;
		let media;
		if (item.media) {
			media = useFlowFactory().getFlowMediaFactory().createFlowMediaListByResponse(item.media);
		} else if (item.mediaList) {
			media = useFlowFactory().getFlowMediaFactory().createFlowMediaListByResponse(item.mediaList);
		} else if (item.media_list) {
			media = useFlowFactory().getFlowMediaFactory().createFlowMediaListByResponse(item.media_list);
		}
		const reference = item.reference ?? undefined;
		const name = item.name ?? undefined;
		const description = item.description ?? undefined;
		const data = item.data;
		const created_at = item.created_at ? new Date(item.created_at) : (item.createdAt ? new Date(item.createdAt) : new Date());
		const updated_at = item.updated_at ? new Date(item.updated_at) : (item.updatedAt ? new Date(item.updatedAt) : new Date());

		let flowProcess;

		switch (type) {
			case FlowProcessType.DEFAULT:
				flowProcess = new FlowProcessDefault(id, uuid, flow_id, type, state, flow_process_config_id, media, reference, name, description, data, created_at, updated_at);
				break;
			case FlowProcessType.GREEVE_WHISPER:
				flowProcess = new FlowProcessGreeveWhisper(id, uuid, flow_id, type, state, flow_process_config_id, media, reference, name, description, data, created_at, updated_at);
				break;
			case FlowProcessType.DOWNLOAD_AND_SHOW:
				flowProcess = new FlowProcessDownloadAndShow(id, uuid, flow_id, type, state, flow_process_config_id, media, reference, name, description, data, created_at, updated_at);
				break;
			default:
				throw new Error('Invalid Flow Process Item: ' + JSON.stringify(item));
		}

		return flowProcess;
	}

	createEmptyFlowProcessList(): FlowProcessList {
		return new FlowProcessList([]);
	}
}