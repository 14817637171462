<template>
	<ion-page>
		<ion-content :fullscreen="true">
			<!--      <HeaderBar></HeaderBar>-->
			<HeaderLogo>
				<div  class="rounded-3xl px-3 font-comfortaa py-0 bg-black dark:bg-gray-400 font-bold text-white dark:text-black mt-0 float-right text-tc-bg text-xs sm:text-base sm:mr-1.5 -mt-1 sm:-mt-2 ">{{ $t('beta3') }}
				</div>
			</HeaderLogo>  			<div class="">

				<div class="mx-4 md:mx-7">
					<div class="py-32 xl:py-36 px-4 sm:px-6 lg:px-8 bg-tc-bg overflow-hidden">
						<div class="max-w-max lg:max-w-7xl mx-auto">
							<div class="relative z-10 mb-8 md:mb-2 md:px-6">
								<div class="text-base max-w-prose lg:max-w-none">
									<p class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-tc-blue sm:text-4xl">
										{{ t('privacy.PrivacyTitle') }}</p>
								</div>
							</div>
							<div class="relative">
								<div class="relative md:bg-tc-bg md:p-6">
									<div class="lg:grid lg:gap-6">
										<div class="break-words w-full text-lg lg:text-xl py-0 sm:py-0 lg:py-0">

											<!--          1. Privacy Introduction-->
											<ol class="list-inside font-bold" style="list-style-position: inside;">
												<li>
													<span class="">{{ t('privacy.IntroductionTitle') }}</span>
													<div class="mb-5">
														<p class="font-medium text-md mb-2">
															{{ t('privacy.IntroductionDetail') }}</p>
														<p class="font-medium text-md mb-2">
															{{ t('privacy.IntroductionDetail2') }}</p>
														<p class="font-medium text-md">
															{{ t('privacy.IntroductionDetail3') }}</p>
													</div>
												</li>

												<!--            2. Proceed Data-->
												<li><span class="">{{ t('privacy.ProceedDataTitle') }}</span>
													<div class="mb-5">
														<p class="font-medium text-md">{{
																t('privacy.ProceedDataDetail')
															}}</p>
														<p class="font-medium text-md mb-2">
															{{ t('privacy.ProceedDataDetail2') }}</p>
														<ul class="list-disc list-inside text-md font-bold"
															style="list-style-type: disc; list-style-position: inside;">
															<li><span class="font-medium">{{
																	t('privacy.ProceedDataList1Item1')
																}}</span>
															</li>
															<li><span class="font-medium">{{
																	t('privacy.ProceedDataList1Item2')
																}}</span>
															</li>
															<li><span class="font-medium">{{
																	t('privacy.ProceedDataList1Item3')
																}}</span>
															</li>
															<li><span class="font-medium">{{
																	t('privacy.ProceedDataList1Item4')
																}}</span>
															</li>
															<li><span class="font-medium">{{
																	t('privacy.ProceedDataList1Item5')
																}}</span>
															</li>
															<li><span class="font-medium">{{
																	t('privacy.ProceedDataList1Item6')
																}}</span>
															</li>
															<li><span class="font-medium">{{
																	t('privacy.ProceedDataList1Item7')
																}}</span>
															</li>
														</ul>
													</div>
													<ol class="list-inside font-bold"
														style="list-style-position: inside;">
														<div class="mb-5">
															<li><span class="">{{
																	t('privacy.ProceedDataTitle2')
																}}</span>
																<p class="font-medium">
																	{{ t('privacy.ProceedDataList2Item1') }}</p>
																<p class="font-medium">
																	{{ t('privacy.ProceedDataList2Item2') }}</p>
																<p class="font-medium">
																	{{ t('privacy.ProceedDataList2Item3') }}</p>
															</li>
														</div>
														<div class="mb-5">
															<li><span class="">{{
																	t('privacy.ProceedDataTitle3')
																}}</span>
																<p class="font-medium">
																	{{ t('privacy.ProceedData3Detail') }}</p>
																<p class="font-medium">
																	{{ t('privacy.ProceedData3Detail2') }}</p>
																<ul class="list-disc list-inside text-md font-bold"
																	style="list-style-type: disc; list-style-position: inside;">
																	<li><span class="font-medium">{{
																			t('privacy.ProceedDataList3Item1')
																		}}</span>
																	</li>
																	<li><span class="font-medium">{{
																			t('privacy.ProceedDataList3Item2')
																		}}</span>
																	</li>
																	<li><span class="font-medium">{{
																			t('privacy.ProceedDataList3Item3')
																		}}</span>
																	</li>
																</ul>
																<p class="font-medium">
																	{{ t('privacy.ProceedData3Detail3') }}</p>
																<ul class="list-disc list-inside text-md font-bold"
																	style="list-style-type: disc; list-style-position: inside;">
																	<li><span class="font-medium">{{
																			t('privacy.ProceedDataList4Item1')
																		}}</span>
																	</li>
																	<li><span class="font-medium">{{
																			t('privacy.ProceedDataList4Item2')
																		}}</span>
																	</li>
																</ul>
																<p class="font-medium">
																	{{ t('privacy.ProceedData3Detail4') }}</p>
																<ul class="list-disc list-inside text-md font-bold"
																	style="list-style-type: disc; list-style-position: inside;">
																	<li><span class="font-medium">{{
																			t('privacy.ProceedDataList5Item1')
																		}}</span>
																	</li>
																</ul>
																<p class="font-medium">
																	{{ t('privacy.ProceedData3Detail5') }}</p>
																<ul class="list-disc list-inside text-md font-bold"
																	style="list-style-type: disc; list-style-position: inside;">
																	<li><span class="font-medium">{{
																			t('privacy.ProceedDataList6Item1')
																		}}</span>
																	</li>
																</ul>
															</li>
														</div>
														<div class="mb-5">
														</div>
														<div class="mb-5">
															<li><span>{{ t('privacy.ProceedDataTitle4') }}</span>
																<p class="font-medium">
																	{{ t('privacy.ProceedData4Detail1') }}</p>
																<p class="font-medium">
																	{{ t('privacy.ProceedData4Detail2') }}</p>
																<ul class="list-disc list-inside text-md font-bold"
																	style="list-style-type: disc; list-style-position: inside;">
																	<li><span class="font-medium">{{
																			t('privacy.ProceedDataList8Item1')
																		}}</span>
																	</li>
																	<li><span class="font-medium">{{
																			t('privacy.ProceedDataList8Item2')
																		}}</span>
																	</li>
																	<li><span class="font-medium">{{
																			t('privacy.ProceedDataList8Item3')
																		}}</span>
																	</li>
																	<li><span class="font-medium">{{
																			t('privacy.ProceedDataList8Item4')
																		}}</span>
																	</li>
																	<li><span class="font-medium">{{
																			t('privacy.ProceedDataList8Item5')
																		}}</span>
																	</li>
																	<li><span class="font-medium">{{
																			t('privacy.ProceedDataList8Item6')
																		}}</span>
																	</li>
																	<li><span class="font-medium">{{
																			t('privacy.ProceedDataList8Item7')
																		}}</span>
																	</li>
																</ul>
																<p class="font-medium">
																	{{ t('privacy.ProceedData4Detail3') }}</p>
																<p class="font-medium">
																	{{ t('privacy.ProceedData4Detail4') }}</p>
															</li>
														</div>
													</ol>

												</li>

												<!--            3. Rights in accordance with the General Data Protection Regulation-->
												<li>
													<span class="">{{ t('privacy.RightInAccordanceTitle') }}</span>
													<div class="mb-5">
														<p class="font-medium text-md mb-2">
															{{ t('privacy.RightInAccordanceDetail') }}</p>
														<p class="font-medium text-md mb-2">
															{{ t('privacy.RightInAccordanceDetail2') }}</p>
														<ul class="list-disc list-inside text-md font-bold"
															style="list-style-type: disc; list-style-position: inside;">
															<li><span
																	class="font-medium">{{
																	t('privacy.RightInAccordanceListItem1')
																}}</span></li>
															<li><span
																	class="font-medium">{{
																	t('privacy.RightInAccordanceListItem2')
																}}</span></li>
															<li><span
																	class="font-medium">{{
																	t('privacy.RightInAccordanceListItem3')
																}}</span></li>
															<li><span
																	class="font-medium">{{
																	t('privacy.RightInAccordanceListItem4')
																}}</span></li>
															<li><span
																	class="font-medium">{{
																	t('privacy.RightInAccordanceListItem5')
																}}</span></li>
															<li><span
																	class="font-medium">{{
																	t('privacy.RightInAccordanceListItem6')
																}}</span></li>
															<li><span
																	class="font-medium">{{
																	t('privacy.RightInAccordanceListItem7')
																}}</span></li>
														</ul>
														<p class="font-medium text-md">
															{{ t('privacy.RightInAccordanceDetail2') }}</p>
													</div>
												</li>

												<!--            4. Security -->
												<li>
													<span class="">{{ t('privacy.SecurityTitle') }}</span>
													<div class="mb-5">
														<ol>
															<li><span>{{ t('privacy.SecurityTitle2') }}</span></li>
														</ol>
														<p class="font-medium text-md mb-2">
															{{ t('privacy.SecurityDetail1') }}</p>
														<p class="font-medium text-md mb-2">
															{{ t('privacy.SecurityDetail2') }}</p>
														<p class="font-medium text-md">{{
																t('privacy.SecurityDetail3')
															}}</p>
													</div>
												</li>

												<!--            5. Cookies-->
												<li>
													<span class="">{{ t('privacy.CookiesTitle') }}</span>
													<div class="mb-5 break-all">
														<p class="font-medium text-md mb-2">
															{{ t('privacy.CookiesDetail1') }}</p>
														<p class="text-md mb-2">{{ t('privacy.CookiesDetail2') }}</p>
														<p class="font-medium text-md mb-2">
															{{ t('privacy.CookiesDetail3') }}</p>
														<p class="font-medium text-md mb-2">
															{{ t('privacy.CookiesDetail4') }}</p>
														<p class="font-medium text-md mb-2">
															{{ t('privacy.CookiesDetail5') }}</p>
														<p class="font-medium text-md mb-2">
															{{ t('privacy.CookiesDetail6') }}</p>
														<p class="font-medium text-md mb-2">
															{{ t('privacy.CookiesDetail7') }}</p>
														<ol>
															<li><span>{{ t('privacy.CookiesTitle2') }}</span>
																<p class="font-medium text-md mb-2">
																	{{ t('privacy.Cookies2Detail1') }}</p>
																<p class="font-medium text-md mb-2">
																	{{ t('privacy.Cookies2Detail2') }}</p>
																<p class="font-medium text-md mb-2">
																	{{ t('privacy.Cookies2Detail3') }}</p>
																<p class="font-medium text-md mb-2">
																	{{ t('privacy.Cookies2Detail4') }}</p>
																<p class="font-medium text-md mb-2">
																	{{ t('privacy.Cookies2Detail5') }}</p>
																<p class="font-medium text-md mb-2">
																	<strong>{{
																			t('privacy.Cookies2TitelDetail6')
																		}}</strong>
																	{{ t('privacy.Cookies2Detail6') }}</p>
																<p class="font-medium text-md mb-2 break-all">
																	<strong>{{
																			t('privacy.Cookies2TitelDetail7')
																		}}</strong>
																	{{ t('privacy.Cookies2Detail7') }}</p>
																<p class="font-medium text-md mb-2">
																	<strong>{{
																			t('privacy.Cookies2TitelDetail8')
																		}}</strong>
																	{{ t('privacy.Cookies2Detail8') }}</p>
																<p class="font-medium text-md mb-2">
																	<strong>{{
																			t('privacy.Cookies2TitelDetail9')
																		}}</strong>
																	{{ t('privacy.Cookies2Detail9') }}</p>
																<p class="font-medium text-md mb-2">
																	{{ t('privacy.Cookies2Detail10') }}</p>
																<ul class="list-disc list-inside text-md font-bold"
																	style="list-style-type: disc; list-style-position: inside;">
																	<li><span class="font-medium">{{
																			t('privacy.Cookies2List1Item1')
																		}}</span>
																	</li>
																	<li><span class="font-medium">{{
																			t('privacy.Cookies2List1Item2')
																		}}</span>
																	</li>
																	<li><span class="font-medium">{{
																			t('privacy.Cookies2List1Item3')
																		}}</span>
																	</li>
																</ul>
															</li>
															<li><span class="font-medium">{{
																	t('privacy.Cookies3Title')
																}}</span>
																<p class="font-medium text-md mb-2">
																	{{ t('privacy.Cookies3Detail') }}</p>
																<p class="font-medium text-md mb-2">
																	{{ t('privacy.Cookies3Detail2') }}</p>
																<ol>
																	<li><span>{{
																			t('privacy.Cookies3InnerTitle1')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{ t('privacy.Cookies3InnerDetail1') }}</p>
																	</li>
																	<li><span>{{
																			t('privacy.Cookies3InnerTitle2')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{ t('privacy.Cookies3InnerDetail2') }}</p>
																	</li>
																	<li><span>{{
																			t('privacy.Cookies3InnerTitle3')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{ t('privacy.Cookies3InnerDetail3') }}</p>
																	</li>
																	<li><span>{{
																			t('privacy.Cookies3InnerTitle4')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{ t('privacy.Cookies3InnerDetail4') }}</p>
																	</li>
																	<li><span>{{
																			t('privacy.Cookies3InnerTitle5')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{ t('privacy.Cookies3InnerDetail5') }}</p>
																	</li>
																</ol>
															</li>
															<li><span>{{ t('privacy.DeleteCookiesTitle') }}</span>
																<p class="font-medium text-md mb-2">
																	{{ t('privacy.DeleteCookiesDetail') }}</p>
																<p class="font-medium text-md mb-2">
																	{{ t('privacy.DeleteCookiesDetail1') }}</p>
																<p class="font-medium text-md mb-2">
																	{{ t('privacy.DeleteCookiesDetail2') }}</p>
																<p class="font-medium text-md">
																	<a>{{ t('privacy.DeleteCookiesDetail3') }}</a>
																</p>
																<p class="font-medium text-md">
																	<a>{{ t('privacy.DeleteCookiesDetail4') }}</a>
																</p>
																<p class="font-medium text-md">
																	<a>{{ t('privacy.DeleteCookiesDetail5') }}</a>
																</p>
																<p class="font-medium text-md">
																	<a>{{ t('privacy.DeleteCookiesDetail6') }}</a>
																</p>
																<p class="font-medium text-md mb-2">
																	{{ t('privacy.DeleteCookiesDetail7') }}</p>
															</li>
															<li><span>{{
																	t('privacy.DataProtectionCookiesTitle')
																}}</span>
																<p class="font-medium text-md mb-2">
																	{{ t('privacy.DataProtectionCookiesDetail') }}</p>
																<p class="font-medium text-md mb-2">
																	{{ t('privacy.DataProtectionCookiesDetail2') }}</p>
															</li>
														</ol>
													</div>
												</li>

												<!--            6. E-Mail Marketing -->
												<li>
													<span class="">{{ t('privacy.EmailMarketingTitle') }}</span>
													<div class="mb-5">
														<p class="font-medium text-md mb-2">
															{{ t('privacy.EmailMarketingDetail') }}</p>
														<ol>
															<li><span>{{ t('privacy.EmailMarketing2Title') }}</span>
																<p class="font-medium text-md mb-2">
																	{{ t('privacy.EmailMarketing2Detail') }}</p>
																<p class="font-medium text-md mb-2">
																	{{ t('privacy.EmailMarketing2Detail2') }}</p>
															</li>
															<li><span>{{ t('privacy.EmailMarketing3Title') }}</span>
																<p class="font-medium text-md mb-2">
																	{{ t('privacy.EmailMarketing3Detail') }}</p>
																<p class="font-medium text-md mb-2">
																	{{ t('privacy.EmailMarketing3Detail2') }}</p>
															</li>
															<li><span>{{ t('privacy.EmailMarketing5Title') }}</span>
																<p class="font-medium text-md mb-2">
																	{{ t('privacy.EmailMarketing5Detail') }}</p>
															</li>
															<li><span>{{ t('privacy.EmailMarketing6Title') }}</span>
																<p class="font-medium text-md mb-2">
																	{{ t('privacy.EmailMarketing6Detail') }}</p>
																<p class="font-medium text-md mb-2">
																	{{ t('privacy.EmailMarketing6Detail2') }}</p>
															</li>
															<li><span>{{ t('privacy.EmailMarketing7Title') }}</span>
																<p class="font-medium text-md mb-2">
																	{{ t('privacy.EmailMarketing7Detail') }}</p>
															</li>
															<li><span>{{ t('privacy.EmailMarketing8Title') }}</span>
																<p class="font-medium text-md mb-2">
																	{{ t('privacy.EmailMarketing8Detail') }}</p>
															</li>

														</ol>
													</div>
												</li>

												<!--            7. External Services-->
												<li>
													<span class="">{{ t('privacy.ExternalServiceTitle') }}</span>
													<div class="mb-5">
														<ol>
															<!--                  7.1. Google Analytics Privacy-->
															<li><span>{{ t('privacy.ExternalService2Title') }}</span>
																<p class="font-medium text-md mb-2">
																	{{ t('privacy.ExternalService2Detail') }}</p>
																<ol>
																	<li><span>{{
																			t('privacy.ExternalService2InnerTitle')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService2InnerDetail')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService2InnerDetail2')
																			}}</p>
																		<ul class="list-disc list-inside text-md font-bold"
																			style="list-style-type: disc; list-style-position: inside;">
																			<li><span
																					class="font-medium">{{
																					t('privacy.	ExternalService2InnerListItem1')
																				}}</span>
																			</li>
																			<li><span
																					class="font-medium">{{
																					t('privacy.	ExternalService2InnerListItem2')
																				}}</span>
																			</li>
																			<li><span
																					class="font-medium">{{
																					t('privacy.	ExternalService2InnerListItem3')
																				}}</span>
																			</li>
																			<li><span
																					class="font-medium">{{
																					t('privacy.	ExternalService2InnerListItem4')
																				}}</span>
																			</li>
																			<li><span
																					class="font-medium">{{
																					t('privacy.	ExternalService2InnerListItem5')
																				}}</span>
																			</li>
																			<li><span
																					class="font-medium">{{
																					t('privacy.	ExternalService2InnerListItem6')
																				}}</span>
																			</li>
																		</ul>
																	</li>
																	<li><span>{{
																			t('privacy.ExternalService2Inner2Title')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService2Inner2Detail')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService2Inner2Detail2')
																			}}</p>
																	</li>
																	<li><span>{{
																			t('privacy.ExternalService2Inner3Title')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService2Inner3Detail')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService2Inner3Detail2')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService2Inner3Detail3')
																			}}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.GoogleCookieDetail') }}</p>
																		<p class="font-medium text-md break-all">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.GoogleCookieDetail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.GoogleCookieDetail3') }}
																		</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.GoogleCookieDetail4') }}
																		</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.GoogleCookie2Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.GoogleCookie2Detail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.GoogleCookie2Detail3') }}
																		</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.GoogleCookie2Detail4') }}
																		</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.GoogleCookie3Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.GoogleCookie3Detail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.GoogleCookie3Detail3') }}
																		</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.GoogleCookie3Detail4') }}
																		</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.GoogleCookie4Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.GoogleCookie4Detail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.GoogleCookie4Detail3') }}
																		</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.GoogleCookie4Detail4') }}
																		</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.GoogleCookie5Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.GoogleCookie5Detail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.GoogleCookie5Detail3') }}
																		</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.GoogleCookie5Detail4') }}
																		</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.GoogleCookie6Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.GoogleCookie6Detail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.GoogleCookie6Detail3') }}
																		</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.GoogleCookie6Detail4') }}
																		</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.GoogleCookie7Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.GoogleCookie7Detail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.GoogleCookie7Detail3') }}
																		</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.GoogleCookie7Detail4') }}
																		</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.GoogleCookie8Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.GoogleCookie8Detail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.GoogleCookie8Detail3') }}
																		</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.GoogleCookie8Detail4') }}
																		</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.GoogleCookie9Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.GoogleCookie9Detail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.GoogleCookie9Detail3') }}
																		</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.GoogleCookie9Detail4') }}
																		</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.GoogleCookie10Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.GoogleCookie10Detail2') }}
																		</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.GoogleCookie10Detail3') }}
																		</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.GoogleCookie10Detail4') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieNote')
																				}}</strong>
																			{{ t('privacy.GoogleNote') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieHeatmaps')
																				}}</strong>
																			{{ t('privacy.GoogleHeatmaps') }}
																		</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieSession')
																				}}</strong>
																			{{ t('privacy.GoogleSession') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieBounce')
																				}}</strong>
																			{{ t('privacy.GoogleBounce') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieAccount')
																				}}</strong>
																			{{ t('privacy.GoogleAccount') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{ t('privacy.CookieIp') }}</strong>
																			{{ t('privacy.GoogleIp') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieLocation')
																				}}</strong>
																			{{ t('privacy.GoogleLocation') }}
																		</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieTec')
																				}}</strong>
																			{{ t('privacy.GoogleTec') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieSource')
																				}}</strong>
																			{{ t('privacy.GoogleSource') }}</p>
																		<p class="font-medium text-md">
																			{{
																				t('privacy.ExternalService2Inner3Detail4')
																			}}</p>
																	</li>
																</ol>
															</li>

															<!--                  7.2. Mail Chimp Privacy-->
															<li><span>{{ t('privacy.ExternalService3Title') }}</span>
																<p class="font-medium text-md mb-2">
																	{{ t('privacy.ExternalService3Detail') }}</p>
																<ol>
																	<li><span>{{
																			t('privacy.ExternalService3InnerTitle')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService3InnerDetail')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService3InnerDetail2')
																			}}</p>
																	</li>
																	<li><span>{{
																			t('privacy.ExternalService3Inner2Title')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService3Inner2Detail')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService3Inner2Detail2')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService3Inner2Detail3')
																			}}</p>
																	</li>
																	<li><span>{{
																			t('privacy.ExternalService3Inner3Title')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService3Inner3Detail')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService3Inner3Detail2')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService3Inner3Detail3')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService3Inner3Detail4')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService3Inner3Detail5')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService3Inner3Detail6')
																			}}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.MailChimpCookieDetail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.MailChimpCookieDetail2') }}
																		</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.MailChimpCookieDetail3') }}
																		</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.MailChimpCookieDetail4') }}
																		</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.MailChimpCookie2Detail') }}
																		</p>
																		<p class="font-medium text-md mb-2 break-all">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.MailChimpCookie2Detail2') }}
																		</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.MailChimpCookie2Detail3') }}
																		</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.MailChimpCookie2Detail4') }}
																		</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.MailChimpCookie3Detail') }}
																		</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.MailChimpCookie3Detail2') }}
																		</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.MailChimpCookie3Detail3') }}
																		</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.MailChimpCookie3Detail4') }}
																		</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.MailChimpCookie4Detail') }}
																		</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.MailChimpCookie4Detail2') }}
																		</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.MailChimpCookie4Detail3') }}
																		</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.MailChimpCookie4Detail4') }}
																		</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService3Inner3Detail7')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService3Inner3Detail8')
																			}}</p>
																	</li>
																	<li><span>{{
																			t('privacy.ExternalService3Inner4Title')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService3Inner4Detail')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService3Inner4Detail2')
																			}}</p>
																	</li>
																</ol>
															</li>

															<!--                  7.3. Amazon Privacy-->
															<li><span>{{ t('privacy.ExternalService4Title') }}</span>
																<p class="font-medium text-md mb-2">
																	{{ t('privacy.ExternalService4Detail') }}</p>
																<p class="font-medium text-md mb-2">
																	{{ t('privacy.ExternalService4Detail2') }}</p>
																<ol>
																	<li><span>{{
																			t('privacy.ExternalService4InnerTitle')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService4InnerDetail')
																			}}</p>
																	</li>
																	<li><span>{{
																			t('privacy.ExternalService4Inner2Title')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService4Inner2Detail')
																			}}</p>
																	</li>
																	<li><span>{{
																			t('privacy.ExternalService4Inner3Title')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService4Inner3Detail')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService4Inner3Detail2')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService4Inner3Detail3')
																			}}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.AmazonDetail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.AmazonDetail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.AmazonDetail3') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.AmazonDetail4') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.Amazon2Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.Amazon2Detail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.Amazon2Detail3') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.Amazon2Detail4') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.Amazon3Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.Amazon3Detail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.Amazon3Detail3') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.Amazon3Detail4') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.Amazon4Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.Amazon4Detail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.Amazon4Detail3') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.Amazon4Detail4') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.Amazon5Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.Amazon5Detail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.Amazon5Detail3') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.Amazon5Detail4') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.Amazon6Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.Amazon6Detail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.Amazon6Detail3') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.Amazon6Detail4') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.Amazon7Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.Amazon7Detail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.Amazon7Detail3') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.Amazon7Detail4') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieNote')
																				}}</strong>
																			{{ t('privacy.AmazonNote') }}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService4Inner3Detail4')
																			}}</p>
																	</li>
																	<li><span>{{
																			t('privacy.ExternalService4Inner4Title')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService4Inner4Detail')
																			}}</p>
																	</li>
																	<li><span>{{
																			t('privacy.ExternalService4Inner5Title')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService4Inner5Detail')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService4Inner5Detail2')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService4Inner5ListItem1')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService4Inner5ListItem2')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService4Inner5ListItem3')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService4Inner5ListItem4')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService4Inner5ListItem5')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService4Inner5Detail3')
																			}}</p>
																	</li>
																</ol>
															</li>

															<!--                  7.4. Google Ads Privacy-->
															<li><span>{{ t('privacy.ExternalService5Title') }}</span>
																<p class="font-medium text-md mb-2">
																	{{ t('privacy.ExternalService5Detail') }}</p>
																<ol>
																	<li><span>{{
																			t('privacy.ExternalService5Inner5Title')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService5Detail')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService5Inner5Detail2')
																			}}</p>
																	</li>
																	<li><span>{{
																			t('privacy.ExternalService5InnerTitle')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService5InnerDetail')
																			}}</p>
																	</li>
																	<li><span>{{
																			t('privacy.ExternalService5Inner2Title')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService5Inner2Detail')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService5Inner2Detail2')
																			}}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.GoogleAdsDetail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.GoogleAdsDetail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.GoogleAdsDetail3') }}
																		</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.GoogleAdsDetail4') }}
																		</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.GoogleAds2Detail') }}</p>
																		<p class="font-medium text-md break-all">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.GoogleAds2Detail2') }}
																		</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.GoogleAds2Detail3') }}
																		</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.GoogleAds2Detail4') }}
																		</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieNote')
																				}}</strong>
																			{{ t('privacy.GoogleAdsNote') }}
																		</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService5Inner2Detail3')
																			}}</p>
																	</li>
																	<li><span>{{
																			t('privacy.ExternalService5Inner3Title')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService5Inner3Detail')
																			}}</p>
																	</li>
																	<li><span>{{
																			t('privacy.ExternalService5Inner4Title')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService5Inner4Detail')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService5Inner4ListItem1')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService5Inner4ListItem2')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService5Inner4ListItem3')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService5Inner4ListItem4')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService5Inner4ListItem5')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService5Inner4Detail2')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService5Inner4Detail3')
																			}}</p>
																	</li>
																</ol>
															</li>

															<!--                 7.5 Google Adsense Privacy-->
															<li><span>{{ t('privacy.ExternalService6Title') }}</span>
																<p class="font-medium text-md mb-2">
																	{{ t('privacy.ExternalService6Detail') }}</p>
																<ol>
																	<li><span>{{
																			t('privacy.ExternalService6InnerTitle')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService6InnerDetail')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService6InnerDetail2')
																			}}</p>
																	</li>
																	<li><span>{{
																			t('privacy.ExternalService6Inner2Title')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService6Inner2Detail')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService6Inner2Detail2')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService6Inner2Detail3')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService6Inner2Detail4')
																			}}</p>
																		<ul class="list-disc list-inside text-md font-bold"
																			style="list-style-type: disc; list-style-position: inside;">
																			<li><span
																					class="font-medium">{{
																					t('privacy.	ExternalService6Inner2List1Item1')
																				}}</span>
																			</li>
																			<li><span
																					class="font-medium">{{
																					t('privacy.	ExternalService6Inner2List1Item2')
																				}}</span>
																			</li>
																		</ul>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService6Inner2Detail5')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService6Inner2Detail6')
																			}}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.GoogleAdsenseDetail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.GoogleAdsenseDetail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.GoogleAdsenseDetail3') }}
																		</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.GoogleAdsenseDetail4') }}
																		</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.GoogleAdsense2Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.GoogleAdsense2Detail2') }}
																		</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.GoogleAdsense2Detail3') }}
																		</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.GoogleAdsense2Detail4') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.GoogleAdsense3Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.GoogleAdsense3Detail2') }}
																		</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.GoogleAdsense3Detail3') }}
																		</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.GoogleAdsense3Detail4') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.GoogleAdsense4Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.GoogleAdsense4Detail2') }}
																		</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.GoogleAdsense4Detail3') }}
																		</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.GoogleAdsense4Detail4') }}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService11Inner3Detail4')
																			}}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.GoogleAdsense5Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.GoogleAdsense5Detail2') }}
																		</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.GoogleAdsense5Detail3') }}
																		</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.GoogleAdsense5Detail4') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.GoogleAdsense6Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.GoogleAdsense6Detail2') }}
																		</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.GoogleAdsense6Detail3') }}
																		</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.GoogleAdsense6Detail4') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieNote')
																				}}</strong>
																			{{ t('privacy.GoogleAdsenseNote') }}
																		</p>

																	</li>
																	<li><span>{{
																			t('privacy.ExternalService6Inner3Title')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService6Inner3Detail')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService6Inner3Detail2')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService6Inner3Detail3')
																			}}</p>
																	</li>
																	<li><span>{{
																			t('privacy.ExternalService6Inner4Title')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService6Inner4Detail')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService6Inner4Detail2')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService6Inner4ListItem1')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService6Inner4ListItem2')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService6Inner4ListItem3')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService6Inner4ListItem4')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService6Inner4ListItem5')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService6Inner4Detail3')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService6Inner4Detail4')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService6Inner4Detail5')
																			}}</p>
																	</li>
																</ol>
															</li>
															<!--                 7.6. Stripe Privacy-->
															<li><span>{{ t('privacy.ExternalService7Title') }}</span>
																<p class="font-medium text-md mb-2">
																	{{ t('privacy.ExternalService7Detail') }}</p>
																<ol>
																	<li><span>{{
																			t('privacy.ExternalService7InnerTitle')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService7InnerDetail')
																			}}</p>
																	</li>
																	<li><span>{{
																			t('privacy.ExternalService7Inner2Title')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService7Inner2Detail')
																			}}</p>
																	</li>
																	<li><span>{{
																			t('privacy.ExternalService7Inner3Title')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService7Inner3Detail')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService7Inner3Detail2')
																			}}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.StripeDetail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.StripeDetail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.StripeDetail3') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.StripeDetail4') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.Stripe2Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.Stripe2Detail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.Stripe2Detail3') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.Stripe2Detail4') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.Stripe3Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.Stripe3Detail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.Stripe3Detail3') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.Stripe3Detail4') }}</p>
																	</li>
																	<li><span>{{
																			t('privacy.ExternalService7Inner4Title')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService7Inner4Detail')
																			}}</p>
																	</li>
																	<li><span>{{
																			t('privacy.ExternalService7Inner5Title')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService7Inner5Detail')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService7Inner5Detail2')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService7Inner5Detail3')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService7Inner5ListItem1')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService7Inner5ListItem2')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService7Inner5ListItem3')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService7Inner5ListItem4')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService7Inner5ListItem5')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService7Inner5Detail4')
																			}}</p>
																	</li>
																</ol>
															</li>

															<!--                 7.7 Facebook Privacy -->
															<li><span>{{ t('privacy.ExternalService8Title') }}</span>
																<p class="font-medium text-md mb-2">
																	{{ t('privacy.ExternalService8Detail') }}</p>
																<ol>
																	<li><span>{{
																			t('privacy.ExternalService8InnerTitle')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService8InnerDetail')
																			}}</p>
																		<ul class="list-disc list-inside text-md font-bold"
																			style="list-style-type: disc; list-style-position: inside;">
																			<li><span
																					class="font-medium">{{
																					t('privacy.	ExternalService8InnerListItem1')
																				}}</span>
																			</li>
																			<li><span
																					class="font-medium">{{
																					t('privacy.	ExternalService8InnerListItem2')
																				}}</span>
																			</li>
																			<li><span
																					class="font-medium">{{
																					t('privacy.	ExternalService8InnerListItem3')
																				}}</span>
																			</li>
																			<li><span
																					class="font-medium">{{
																					t('privacy.	ExternalService8InnerListItem4')
																				}}</span>
																			</li>
																			<li><span
																					class="font-medium">{{
																					t('privacy.	ExternalService8InnerListItem5')
																				}}</span>
																			</li>
																			<li><span
																					class="font-medium">{{
																					t('privacy.	ExternalService8InnerListItem6')
																				}}</span>
																			</li>
																			<li><span
																					class="font-medium">{{
																					t('privacy.	ExternalService8InnerListItem7')
																				}}</span>
																			</li>
																			<li><span
																					class="font-medium">{{
																					t('privacy.	ExternalService8InnerListItem8')
																				}}</span>
																			</li>
																			<li><span
																					class="font-medium">{{
																					t('privacy.	ExternalService8InnerListItem9')
																				}}</span>
																			</li>
																			<li><span
																					class="font-medium">{{
																					t('privacy.	ExternalService8InnerListItem10')
																				}}</span>
																			</li>
																			<li><span
																					class="font-medium">{{
																					t('privacy.	ExternalService8InnerListItem11')
																				}}</span>
																			</li>
																			<li><span
																					class="font-medium">{{
																					t('privacy.	ExternalService8InnerListItem12')
																				}}</span>
																			</li>
																		</ul>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService8InnerDetail2')
																			}}</p>
																	</li>
																	<li><span>{{
																			t('privacy.ExternalService8Inner2Title')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService8Inner2Detail')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService8Inner2Detail2')
																			}}</p>
																	</li>
																	<li><span>{{
																			t('privacy.ExternalService8Inner3Title')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService8Inner3Detail')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService8Inner3Detail2')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService8Inner3Detail3')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService8Inner3Detail4')
																			}}</p>
																	</li>
																	<li><span>{{
																			t('privacy.ExternalService8Inner4Title')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService8Inner4Detail')
																			}}</p>
																	</li>
																	<li><span>{{
																			t('privacy.ExternalService8Inner5Title')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService8Inner5Detail')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService8Inner5Detail2')
																			}}</p>
																		<ul class="list-disc list-inside text-md font-bold"
																			style="list-style-type: disc; list-style-position: inside;">
																			<li><span
																					class="font-medium">{{
																					t('privacy.	ExternalService8Inner5List2Item1')
																				}}</span>
																			</li>
																			<li><span
																					class="font-medium">{{
																					t('privacy.	ExternalService8Inner5List2Item2')
																				}}</span>
																			</li>
																			<li><span
																					class="font-medium">{{
																					t('privacy.	ExternalService8Inner5List2Item3')
																				}}</span>
																			</li>
																			<li><span
																					class="font-medium">{{
																					t('privacy.	ExternalService8Inner5List2Item4')
																				}}</span>
																			</li>
																			<li><span
																					class="font-medium">{{
																					t('privacy.	ExternalService8Inner5List2Item5')
																				}}</span>
																			</li>
																		</ul>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService8Inner5Detail3')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService8Inner5ListItem1')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService8Inner5ListItem2')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService8Inner5ListItem3')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService8Inner5ListItem4')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService8Inner5ListItem5')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService8Inner5Detail4')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService8Inner5Detail5')
																			}}</p>
																	</li>
																</ol>
															</li>
															<!-- 7.8 Instagram Privacy -->
															<li><span>{{ t('privacy.ExternalService9Title') }}</span>
																<p class="font-medium text-md mb-2">
																	{{ t('privacy.ExternalService9Detail') }}</p>
																<p class="font-medium text-md mb-2">
																	{{ t('privacy.ExternalService9Detail2') }}</p>
																<ol>
																	<li><span>{{
																			t('privacy.ExternalService9InnerTitle')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService9InnerDetail')
																			}}</p>
																	</li>
																	<li><span>{{
																			t('privacy.ExternalService9Inner2Title')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService9Inner2Detail')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService9Inner2Detail2')
																			}}</p>
																	</li>
																	<li><span>{{
																			t('privacy.ExternalService9Inner3Title')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService9Inner3Detail')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService9Inner3Detail2')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService9Inner3Detail3')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService9Inner3Detail4')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService9Inner3Detail5')
																			}}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.InstagramDetail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.InstagramDetail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.InstagramDetail3') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.InstagramDetail4') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.Instagram2Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.Instagram2Detail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.Instagram2Detail3') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.Instagram2Detail4') }}
																		</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.Instagram3Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.Instagram3Detail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.Instagram3Detail3') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.Instagram3Detail4') }}
																		</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.Instagram4Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.Instagram4Detail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.Instagram4Detail3') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.Instagram4Detail4') }}
																		</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService11Inner3Detail4')
																			}}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.Instagram5Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.Instagram5Detail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.Instagram5Detail3') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.Instagram5Detail4') }}
																		</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieNote')
																				}}</strong>
																			{{ t('privacy.InstagramNote') }}</p>
																	</li>
																	<li><span>{{
																			t('privacy.ExternalService9Inner5Title')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService9Inner5Detail')
																			}}</p>
																	</li>
																	<li><span>{{
																			t('privacy.ExternalService9Inner4Title')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService9Inner4Detail')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService9Inner4Detail2')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService9Inner4Detail3')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService9Inner4Detail4')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService9Inner4Detail5')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService9Inner4ListItem1')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService9Inner4ListItem2')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService9Inner4ListItem3')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService9Inner4ListItem4')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService9Inner4ListItem5')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService9Inner4Detail6')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService9Inner4Detail7')
																			}}</p>
																	</li>
																</ol>
															</li>

															<!--                 7.9 Twitter Privacy -->
															<li><span>{{ t('privacy.ExternalService10Title') }}</span>
																<p class="font-medium text-md mb-2">
																	{{ t('privacy.ExternalService10Detail') }}</p>
																<p class="font-medium text-md mb-2">
																	{{ t('privacy.ExternalService10Detail2') }}</p>
																<ol>
																	<li><span>{{
																			t('privacy.ExternalService10InnerTitle')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService10InnerDetail')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService10InnerDetail2')
																			}}</p>
																	</li>
																	<li><span>{{
																			t('privacy.ExternalService10Inner2Title')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService10Inner2Detail')
																			}}</p>
																	</li>
																	<li><span>{{
																			t('privacy.ExternalService10Inner3Title')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService10Inner3Detail')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService10Inner3Detail2')
																			}}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.TwitterDetail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.TwitterDetail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.TwitterDetail3') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.TwitterDetail4') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.Twitter2Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.Twitter2Detail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.Twitter2Detail3') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.Twitter2Detail4') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.Twitter3Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.Twitter3Detail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.Twitter3Detail3') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.Twitter3Detail4') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.Twitter4Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.Twitter4Detail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.Twitter4Detail3') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.Twitter4Detail4') }}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService11Inner3Detail4')
																			}}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.Twitter5Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.Twitter5Detail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.Twitter5Detail3') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.Twitter5Detail4') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.Twitter6Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.Twitter6Detail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.Twitter6Detail3') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.Twitter6Detail4') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.Twitter7Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.Twitter7Detail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.Twitter7Detail3') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.Twitter7Detail4') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.Twitter8Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.Twitter8Detail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.Twitter8Detail3') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.Twitter8Detail4') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieNote')
																				}}</strong>
																			{{ t('privacy.TwitterNote') }}</p>
																	</li>
																	<li><span>{{
																			t('privacy.ExternalService10Inner4Title')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService10Inner4Detail')
																			}}</p>
																	</li>
																	<li><span>{{
																			t('privacy.ExternalService10Inner5Title')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService10Inner5Detail')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService10Inner5Detail2')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService10Inner5Detail3')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService10Inner5Detail4')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService10Inner4ListItem1')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService10Inner4ListItem2')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService10Inner4ListItem3')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService10Inner4ListItem4')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService10Inner4ListItem5')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService10Inner4Detail5')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService10Inner4Detail6')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService10Inner4Detail7')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService10Inner4Detail8')
																			}}</p>
																	</li>
																</ol>
															</li>

															<!--                 7.10 Youtube Privacy -->
															<li><span>{{ t('privacy.ExternalService11Title') }}</span>
																<p class="font-medium text-md mb-2">
																	{{ t('privacy.ExternalService11Detail') }}</p>
																<p class="font-medium text-md mb-2">
																	{{ t('privacy.ExternalService11Detail2') }}</p>
																<ol>
																	<li><span>{{
																			t('privacy.ExternalService11InnerTitle')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService11InnerDetail')
																			}}</p>
																	</li>
																	<li><span>{{
																			t('privacy.ExternalService11Inner2Title')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService11Inner2Detail')
																			}}</p>
																	</li>
																	<li><span>{{
																			t('privacy.ExternalService11Inner3Title')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService11Inner3Detail')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService11Inner3Detail2')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService11Inner3Detail3')
																			}}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.YoutubeDetail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.YoutubeDetail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.YoutubeDetail3') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.YoutubeDetail4') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.Youtube2Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.Youtube2Detail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.Youtube2Detail3') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.Youtube2Detail4') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.Youtube3Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.Youtube3Detail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.Youtube3Detail3') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.Youtube3Detail4') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.Youtube4Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.Youtube4Detail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.Youtube4Detail3') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.Youtube4Detail4') }}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService11Inner3Detail4')
																			}}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.Youtube5Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.Youtube5Detail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.Youtube5Detail3') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.Youtube5Detail4') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.Youtube6Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.Youtube6Detail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.Youtube6Detail3') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.Youtube6Detail4') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.Youtube7Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.Youtube7Detail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.Youtube7Detail3') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.Youtube7Detail4') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.Youtube8Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.Youtube8Detail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.Youtube8Detail3') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.Youtube8Detail4') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.Youtube9Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.Youtube9Detail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.Youtube9Detail3') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.Youtube9Detail4') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.Youtube10Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.Youtube10Detail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.Youtube10Detail3') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.Youtube10Detail4') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.Youtube11Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.Youtube11Detail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.Youtube11Detail3') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.Youtube11Detail4') }}</p>
																	</li>
																	<li><span>{{
																			t('privacy.ExternalService11Inner4Title')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService11Inner4Detail')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService11Inner4Detail2')
																			}}</p>
																	</li>
																	<li><span>{{
																			t('privacy.ExternalService11Inner5Title')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService11Inner5Detail')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService11Inner5Detail2')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService11Inner5ListItem1')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService11Inner5ListItem2')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService11Inner5ListItem3')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService11Inner5ListItem4')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService11Inner5ListItem5')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService11Inner5Detail3')
																			}}</p>
																	</li>
																</ol>
															</li>

															<!--                  7.11 Google Recaptcha Privacy -->
															<li><span>{{ t('privacy.ExternalService12Title') }}</span>
																<p class="font-medium text-md mb-2">
																	{{ t('privacy.ExternalService12Detail') }}</p>
																<ol>
																	<li><span>{{
																			t('privacy.ExternalService12Inner2Title')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService12Inner2Detail')
																			}}</p>
																	</li>
																	<li><span>{{
																			t('privacy.ExternalService12Inner3Title')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService12Inner3Detail')
																			}}</p>
																	</li>
																	<li><span>{{
																			t('privacy.ExternalService12Inner4Title')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService12Inner4Detail')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService12Inner4Detail2')
																			}}</p>
																		<ul class="list-disc list-inside text-md font-bold"
																			style="list-style-type: disc; list-style-position: inside;">
																			<li><span
																					class="font-medium">{{
																					t('privacy.	ExternalService12Inner4ListItem1')
																				}}</span>
																			</li>
																			<li><span
																					class="font-medium">{{
																					t('privacy.	ExternalService12Inner4ListItem2')
																				}}</span>
																			</li>
																			<li><span
																					class="font-medium">{{
																					t('privacy.	ExternalService12Inner4ListItem3')
																				}}</span>
																			</li>
																			<li><span
																					class="font-medium">{{
																					t('privacy.	ExternalService12Inner4ListItem4')
																				}}</span>
																			</li>
																			<li><span
																					class="font-medium">{{
																					t('privacy.	ExternalService12Inner4ListItem5')
																				}}</span>
																			</li>
																			<li><span
																					class="font-medium">{{
																					t('privacy.	ExternalService12Inner4ListItem6')
																				}}</span>
																			</li>
																			<li><span
																					class="font-medium">{{
																					t('privacy.	ExternalService12Inner4ListItem7')
																				}}</span>
																			</li>
																			<li><span
																					class="font-medium">{{
																					t('privacy.	ExternalService12Inner4ListItem8')
																				}}</span>
																			</li>
																		</ul>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService12Inner4Detail3')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService12Inner4Detail4')
																			}}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.ReCaptchaDetail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.ReCaptchaDetail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.ReCaptchaDetail3') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.ReCaptchaDetail4') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.ReCaptcha2Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.ReCaptcha2Detail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.ReCaptcha2Detail3') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.ReCaptcha2Detail4') }}
																		</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.ReCaptcha3Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.ReCaptcha3Detail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.ReCaptcha3Detail3') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.ReCaptcha3Detail4') }}
																		</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.ReCaptcha4Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.ReCaptcha4Detail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.ReCaptcha4Detail3') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.ReCaptcha4Detail4') }}
																		</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.ReCaptcha5Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.ReCaptcha5Detail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.ReCaptcha5Detail3') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.ReCaptcha5Detail4') }}
																		</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieName')
																				}}</strong>
																			{{ t('privacy.ReCaptcha6Detail') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookieValue')
																				}}</strong>
																			{{ t('privacy.ReCaptcha6Detail2') }}</p>
																		<p class="font-medium text-md">
																			<strong>{{
																					t('privacy.CookiePurpose')
																				}}</strong>
																			{{ t('privacy.ReCaptcha6Detail3') }}</p>
																		<p class="font-medium text-md mb-2">
																			<strong>{{
																					t('privacy.CookieDate')
																				}}</strong>
																			{{ t('privacy.ReCaptcha6Detail4') }}
																		</p>
																	</li>
																	<li><span>{{
																			t('privacy.ExternalService12Inner5Title')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService12Inner5Detail')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService12Inner5Detail2')
																			}}</p>
																	</li>
																	<li><span>{{
																			t('privacy.ExternalService12Inner6Title')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService12Inner6Detail')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService12Inner6Detail2')
																			}}</p>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService12Inner6Detail3')
																			}}</p>
																	</li>
																</ol>
															</li>
															<!--                  7.13 Trustpilot Privacy -->
															<li><span>{{ t('privacy.ExternalService13Title') }}</span>
																<p class="font-medium text-md mb-2">
																	{{ t('privacy.ExternalService13Detail') }}<a
																		href="https://uk.legal.trustpilot.com/for-reviewers/end-user-privacy-terms">{{
																		t('privacy.ExternalService13Detail2')
																	}}</a>{{ t('privacy.ExternalService13Detail3') }}
																</p>
																<ol>
																	<li><span>{{
																			t('privacy.ExternalService13Inner2Title')
																		}}</span>
																		<p class="font-medium text-md mb-2">
																			{{
																				t('privacy.ExternalService13Inner2Detail')
																			}}</p>
																	</li>
																</ol>
															</li>
															<!--                  7.14 Open AI Privacy -->
															<li class="font-medium text-md mb-2">
																<p><strong>{{
																		t('privacy.ExternalService14Title')
																	}}</strong></p>
																<p><strong>{{
																		t('privacy.ExternalService14Detail1')
																	}}</strong></p>
																<p>{{ t('privacy.ExternalService14Detail2') }}</p>
																<p><strong>{{
																		t('privacy.ExternalService14Detail3')
																	}}</strong></p>
																<p>{{ t('privacy.ExternalService14Detail4') }}</p>
																<p><strong>{{
																		t('privacy.ExternalService14Detail5')
																	}}</strong></p>
																<p>{{ t('privacy.ExternalService14Detail6') }}</p>
																<p><strong>{{
																		t('privacy.ExternalService14Detail7')
																	}}</strong></p>
																<p>{{ t('privacy.ExternalService14Detail8') }}</p>
																<p><strong>{{
																		t('privacy.ExternalService14Detail9')
																	}}</strong></p>
																<p>{{ t('privacy.ExternalService14Detail10') }}</p>
																<ul>
																	<li>{{
																			t('privacy.ExternalService14Detail11')
																		}}
																	</li>
																	<li>{{
																			t('privacy.ExternalService14Detail12')
																		}}
																	</li>
																	<li>{{
																			t('privacy.ExternalService14Detail13')
																		}}
																	</li>
																	<li>{{ t('privacy.ExternalService14Detail14') }}<a
																			href="https://www.allaboutcookies.org/"></a>{{
																			t('privacy.ExternalService14Detail14Link')
																		}}
																	</li>
																	<li>{{
																			t('privacy.ExternalService14Detail15')
																		}}
																	</li>
																	<li>{{
																			t('privacy.ExternalService14Detail16')
																		}}
																	</li>
																</ul>
																<p><strong>{{
																		t('privacy.ExternalService14Detail17')
																	}}</strong></p>
																<p>{{ t('privacy.ExternalService14Detail18') }}</p>
																<p><strong>{{
																		t('privacy.ExternalService14Detail19')
																	}}</strong></p>
																<p>{{ t('privacy.ExternalService14Detail20') }}</p>
																<p><strong>{{
																		t('privacy.ExternalService14Detail21')
																	}}</strong></p>
																<p>{{ t('privacy.ExternalService14Detail22') }}</p>
																<ul>
																	<li>{{
																			t('privacy.ExternalService14Detail23')
																		}}
																	</li>
																	<li>{{
																			t('privacy.ExternalService14Detail24')
																		}}
																	</li>
																	<li>{{
																			t('privacy.ExternalService14Detail25')
																		}}
																	</li>
																	<li>{{
																			t('privacy.ExternalService14Detail26')
																		}}
																	</li>
																	<li>{{
																			t('privacy.ExternalService14Detail27')
																		}}
																	</li>
																	<li>{{
																			t('privacy.ExternalService14Detail28')
																		}}
																	</li>
																</ul>
																<p><strong>{{
																		t('privacy.ExternalService14Detail29')
																	}}</strong></p>
																<p>{{ t('privacy.ExternalService14Detail30') }}</p>
																<p><strong>{{
																		t('privacy.ExternalService14Detail31')
																	}}</strong></p>
																<p><strong>{{
																		t('privacy.ExternalService14Detail32')
																	}}</strong></p>
																<p>{{ t('privacy.ExternalService14Detail33') }}</p>
																<p><strong>{{
																		t('privacy.ExternalService14Detail34')
																	}}</strong></p>
																<p>{{ t('privacy.ExternalService14Detail35') }}</p>
																<p><strong>{{
																		t('privacy.ExternalService14Detail36')
																	}}</strong></p>
																<p>{{ t('privacy.ExternalService14Detail37') }}</p>
																<p><strong>{{
																		t('privacy.ExternalService14Detail38')
																	}}</strong></p>
																<p>{{ t('privacy.ExternalService14Detail39') }}</p>
																<p><strong>{{
																		t('privacy.ExternalService14Detail40')
																	}}</strong></p>
																<p>{{ t('privacy.ExternalService14Detail41') }}</p>
																<p><strong>{{
																		t('privacy.ExternalService14Detail42')
																	}}</strong></p>
																<p>{{ t('privacy.ExternalService14Detail43') }}</p>
															</li>
														</ol>
													</div>
												</li>

												<!--            8. Californian Privacy Act-->
												<li class="font-medium text-md mb-2">
													<p><strong>{{ t('privacy.CaliforniaPrivacyActTitle') }}</strong></p>
													<p>{{ t('privacy.CaliforniaPrivacyActDetail1') }}</p>
													<ul class="font-medium text-md">
														<li><strong>{{
																t('privacy.CaliforniaPrivacyActDetail2')
															}}</strong></li>
														<li>{{ t('privacy.CaliforniaPrivacyActDetail3') }}</li>
														<li>{{ t('privacy.CaliforniaPrivacyActDetail4') }}</li>
														<li>{{ t('privacy.CaliforniaPrivacyActDetail5') }}</li>
													</ul>
													<ul class="font-medium text-md">
														<li><strong>{{
																t('privacy.CaliforniaPrivacyActDetail6')
															}}</strong></li>
														<li>{{ t('privacy.CaliforniaPrivacyActDetail7') }}</li>
														<li>{{ t('privacy.CaliforniaPrivacyActDetail8') }}</li>
														<li>{{ t('privacy.CaliforniaPrivacyActDetail9') }}</li>
													</ul>
													<ul class="font-medium text-md">
														<li><strong>{{
																t('privacy.CaliforniaPrivacyActDetail10')
															}}</strong></li>
														<li>{{ t('privacy.CaliforniaPrivacyActDetail11') }}</li>
														<li>{{ t('privacy.CaliforniaPrivacyActDetail12') }}</li>
														<li>{{ t('privacy.CaliforniaPrivacyActDetail13') }}</li>
													</ul>
													<p>{{ t('privacy.CaliforniaPrivacyActDetail14') }}</p>
													<ul class="font-medium text-md mb-2">
														<li>{{ t('privacy.CaliforniaPrivacyActDetail15') }}</li>
														<li>{{ t('privacy.CaliforniaPrivacyActDetail16') }}</li>
														<li>{{ t('privacy.CaliforniaPrivacyActDetail17') }}</li>
													</ul>
													<p>{{ t('privacy.CaliforniaPrivacyActDetail18') }}</p>
													<p>{{ t('privacy.CaliforniaPrivacyActDetail19') }}<a
															href="mailto:privacy@theconcept.app">{{
															t('privacy.CaliforniaPrivacyActDetail19Link')
														}}</a>.</p>
													<p>{{ t('privacy.CaliforniaPrivacyActDetail20') }}</p>
													<p>{{ t('privacy.CaliforniaPrivacyActDetail21') }}
													</p>
												</li>

												<!--            9. Privacy Agent-->
												<li>
													<span class="">{{ t('privacy.PrivacyAgentTitle') }}</span>
													<div class="mb-0">
														<p class="font-medium text-md">
															{{ t('privacy.PrivacyAgentDetail') }}</p>
														<p class="font-medium text-md mb-0"><a
																href="mailto:privacy@theoncept.app">{{
																t('privacy.PrivacyAgentMail')
															}}</a>
														</p>
														<p class="font-medium text-md">{{
																t('privacy.PrivacyInfo')
															}}</p>
													</div>
												</li>

											</ol>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>

			</div>

			<FooterBar></FooterBar>
		</ion-content>
	</ion-page>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {IonContent, IonPage} from '@ionic/vue';
import FooterBar from "@/components/positions/FooterBar.vue";
import HeaderLogo from "@/components/branding/HeaderLogo.vue";
import useTranslation from "@/composable/translation/useTranslation";

export default defineComponent({
	name: 'PrivacyPage',
	components: {
		HeaderLogo,
		FooterBar, IonContent, IonPage
	},
	setup() {
		const {t} = useTranslation();
		return {t,}
	}
});

</script>
<style lang="scss">
</style>
