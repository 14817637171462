import {
	SearchItemAuthorization
} from '@/greeve/search/authorization/search_item_authorization.type';
import {SearchGroup} from '@/greeve/search/group/search_group.type';

export interface ResponseSearchItemAuthorization {
	uuid: string;
	search_group?: SearchGroup;
	search_item_group_id?: number;
	search_item_group_uuid?: string;
	prompt?: string;
	reference: string;
}

export class SearchItemAuthorizationFactory {
	/**
	 *
	 * @param item
	 */
	createSearchItemAuthorizationByResponse(item: ResponseSearchItemAuthorization): SearchItemAuthorization {
		const uuid = item.uuid;
		const reference = item.reference;
		const search_group = item.search_group ? item.search_group : undefined;
		const search_item_group_id = item.search_item_group_id ? item.search_item_group_id : undefined;
		const search_item_group_uuid = item.search_item_group_uuid ? item.search_item_group_uuid : undefined;
		const prompt = item.prompt ? item.prompt : undefined;

		return new SearchItemAuthorization(uuid, reference, search_item_group_id, search_item_group_uuid, prompt, search_group);
	}
}