import {PaginationMetaInterface} from '@/greeve/core/pagination_meta.interface';
import {SearchGroup} from '@/greeve/search/group/search_group.type';
import {
	GreeveSearchGroupStateInterface,
} from '@/greeve/search/group/search_group.interface';

export class SearchGroupList extends Array<SearchGroup> {

	public paginationMetaData?: PaginationMetaInterface;

	constructor(items: Array<SearchGroup> = []) {
		super();
		this.push(...items);
	}

	add(items: SearchGroup) {
		this.push(items);
	}

	remove(index: number) {
		this.splice(index, 1);
	}

	removeAll() {
		const itemCount = this.length;
		this.splice(itemCount, 1);
	}

	hasMoreItemsToLoad(): boolean {
		if (!this.paginationMetaData) {
			return false;
		}
		if (this.paginationMetaData?.current_page < this.paginationMetaData?.last_page) {
			return true;
		}
		return false;
	}

	getItem(index: number): SearchGroup {
		return this[index];
	}

	getItemByType(searchResultType: string): SearchGroup | undefined {
		return this.find(({type}) => type === searchResultType);
	}

	getItemByUuId(group_uuid: string, clone = true): SearchGroup|undefined {
		if (clone) {
			const searchGroupList: SearchGroupList|SearchGroup[] = [...this];
			return searchGroupList.find(({uuid}) => uuid === group_uuid);
		} else {
			return this.find(({uuid}) => uuid === group_uuid);
		}
	}

	getFirstNewActiveGroupWithoutItems(clone = true): SearchGroup|undefined {
		if (clone) {
			const searchGroupList: SearchGroupList|SearchGroup[] = [...this];
			return searchGroupList.find((group) => group.state === GreeveSearchGroupStateInterface.SEARCH_GROUP_STATE_NEW && (!group.search_items || group.search_items.length === 0 ));
		} else {
			return this.find((group) => group.state === GreeveSearchGroupStateInterface.SEARCH_GROUP_STATE_NEW && (!group.search_items || group.search_items.length === 0 ));
		}
	}

	getNewestGroup(clone = true): SearchGroup|undefined {
		if (clone) {
			const searchGroupList: SearchGroupList|SearchGroup[] = [...this];
			return searchGroupList.sort((a, b) => b.id - a.id)[0];
		} else {
			return this.sort((a, b) => b.id - a.id)[0];
		}
	}

	sortItemsByDate(desc = true) {
		this.sort((a:SearchGroup, b: SearchGroup) => {
			const created_at_a = a.created_at;
			const created_at_b = b.created_at;
			if (!created_at_a || !created_at_b) {
				return desc ? 1 : -1;
			}
			return desc ? created_at_b.getTime() - created_at_a.getTime() : created_at_a.getTime() - created_at_b.getTime();
		});
	}

	sortItemsById() {
		this.sort((a:SearchGroup|any, b: SearchGroup|any) => {
			return a.id - b.id;
		});
	}

	getFirstId(): number|undefined {
		let result;
		try {
			const firstItem = this.at(0);

			if (firstItem) {
				result = firstItem.id;
			}
		} catch (e) {
			console.error(e);
		}

		return result;
	}

	getLastId(): number|undefined {
		let result;
		try {
			const lastItem = this.at(-1);

			if (lastItem) {
				result = lastItem.id;
			}
		} catch (e) {
			console.error(e);
		}

		return result;
	}

	getLastUuid(): string|undefined {
		let result;
		try {
			const lastItem = this.at(-1);

			if (lastItem) {
				result = lastItem.uuid;
			}
		} catch (e) {
			console.error(e);
		}

		return result;
	}

	getLastResult(): SearchGroup|undefined {
		let result;
		try {
			const lastItem = this.at(-1);

			if (lastItem) {
				result = lastItem
			}
		} catch (e) {
			console.error(e);
		}

		return result;
	}

	computeHash(): string {
		// Convert object to JSON string
		const jsonString = JSON.stringify(this);

		// Simple hash function
		let hash = 0;
		if (jsonString.length === 0) {
			return hash.toString();
		}
		for (let i = 0; i < jsonString.length; i++) {
			const char = jsonString.charCodeAt(i);
			hash = ((hash << 5) - hash) + char;
			hash |= 0; // Convert to 32bit integer
		}
		return hash.toString();
	}


	filterList(ignoreGroupUuid_List: string[], clone = true): SearchGroup[]|SearchGroupList {
		if (clone) {
			let searchGroupList: SearchGroupList|SearchGroup[] = [...this];
			searchGroupList = searchGroupList.filter((searchGroup: SearchGroup) => !ignoreGroupUuid_List.includes(searchGroup.uuid));
			return searchGroupList;
		} else {
			return this.filter((searchGroup: SearchGroup) => !ignoreGroupUuid_List.includes(searchGroup.uuid));
		}
	}

	enhanceMetaData(metaData: PaginationMetaInterface) {
		this.paginationMetaData = metaData;
	}
}