import {nanoid} from 'nanoid';
import {CustomerAddressInterface} from "@/greeve/shop/customer/customer_address.interface";
import {GreeveCoreOptionInterface} from "@/greeve/core/option.interface";
import useCountry from '@/composable/core/useCountry';
import {Country} from '@/greeve/core/country/country.type';

export const OPTION_SAVE_ADDRESS = 'option_save_address';

export class CustomerAddress implements CustomerAddressInterface {
	id: string;
	name: string;
	street: string
	street_number?: string
	apartment?: string
	city: string
	state?: string
	postal_code?: string
	country_code: string
	data?: any;
	options?: GreeveCoreOptionInterface[];
	created_at?: Date;
	updated_at?: Date;


	constructor(name: string, street: string, city: string, countryCode: string, street_number?: string, apartment?: string, state?: string, postal_code?: string, created_at?: Date, updated_at?: Date, data: any = null, options?: GreeveCoreOptionInterface[]) {
		this.id = nanoid();
		this.name = name;
		this.street = street;
		this.city = city;
		this.country_code = countryCode;
		this.street_number = street_number;
		this.apartment = apartment;
		this.postal_code = postal_code;
		this.state = state;
		this.data = data;
		this.options = options;
		this.created_at = created_at;
		this.updated_at = updated_at;
	}

	getSaveAddressOption() {
		console.log(this.options)
		if (!this.options) {
			return false
		}
		return !!this.options.find((item: any) => item.key === OPTION_SAVE_ADDRESS && item.value === true);
	}

	addSaveAddressOption(value = false) {
		console.log("OPTIONS", this.options)
		if (!this.options) {
			this.options = [];
		} else {
			if (this.options.find((item: any) => item.key === OPTION_SAVE_ADDRESS && item.value === value)) {
				console.log("EXISTS")
				return;
			}
		}
		console.log("ADD", this.options)
		this.options.push({"key": OPTION_SAVE_ADDRESS, "value": value});
		console.log(this.options)
	}

	addOption(key: string, value: any) {
		if (!this.options) {
			this.options = [];
		}
		this.options.push({"key": key, "value": value});
	}

	getCountry(): Country|undefined {
		return useCountry().getCountry_by_Code(this.country_code);
	}

	getCountryName(): string {
		if (!this.country_code) {
			return '';
		}
		const country = this.getCountry();
		if (country) {
			return country.name;
		}
		return this.country_code;
	}
}