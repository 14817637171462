<template>
		<div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 pt-4 gap-4">
			<div v-for="flowTemplate in flowTemplatesForView" :key="flowTemplate.uuid"
				 :class="'rounded-2xl p-4 col-span-1' + ' ' + background">
<!--				<ConfirmDialog :title="$t('flow.template.convert.dialog.title')"-->
<!--							   :description="$t('flow.template.convert.dialog.description') + ' ' + this.getBlockQuoteForDialog_by_Template(flowTemplate)"-->
<!--							   :cancel-button-name="$t('flow.template.convert.dialog.cancel_button')"-->
<!--							   :confirm-button-name="$t('flow.template.convert.dialog.confirm_button')"-->
<!--							   @confirm="convertTemplateToFlow(flowTemplate)"-->
<!--							   type="success"-->
<!--							   @cancel-confirm="showConfirmConvertTemplateDialog = false"-->
<!--							   :visible="showConfirmConvertTemplateDialog"></ConfirmDialog>-->
				<a class="cursor-pointer" @click="convertTemplateToFlow(flowTemplate)">
					<div class="flex h-full flex-col">
						<div class="flex flex-row" v-if="flowTemplate.getFlowIcon()">
								<span class="inline-flex h-10 w-10 items-center justify-center rounded-xl shadow-md bg-black text-white sm:h-12 sm:w-12">
						<component :is="flowTemplate.getFlowIcon()" class="h-6 w-6" aria-hidden="true"/>
					  </span>
						</div>
						<div class="ml-0 flex md:flex-1 flex-col justify-between mt-4">
							<div>
								<p class="text-base font-medium text-gray-900 dark:text-gray-200 font-semibold truncate"
								   :id="flowTemplate.uuid">
									{{ flowTemplate.getTranslatedName() }}</p>
								<p class="mt-1 text-sm text-gray-500 dark:text-gray-400 line-clamp-3">
									{{ flowTemplate.getTranslatedDescription() }}</p>
								<p class="mt-2 pt-2 text-xs text-gray-400 dark:text-gray-500 border-t-2 border-gr-darker">
									{{ flowTemplate.getFlowPriceInformation() }}
								</p>
							</div>
						</div>
					</div>
				</a>
			</div>
		</div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from 'vue';
import {CheckCircleIcon} from '@heroicons/vue/24/outline';
import {CheckIcon, ChevronDownIcon} from '@heroicons/vue/20/solid';
import useFlow from '@/composable/greeve/useFlow';
import ConfirmDialog from '@/components/modal/ConfirmDialog.vue';
import {FlowTemplate} from '@/greeve/flow/type/template/flow_template.type';
import {AbstractFlow} from '@/greeve/flow/abstract_flow.type';
import useCustomStore from '@/composable/custom/useCustomStore';
import useAuth from '@/composable/auth/useAuth';
import useTranslation from '@/composable/translation/useTranslation';
import router from '@/router';
import {FlowList} from '@/greeve/flow/flow_list.type';

export default defineComponent({
	name: 'TemplateGrid',
	components: {
		ConfirmDialog,
		CheckCircleIcon,
		CheckIcon,
		ChevronDownIcon,
	},
	props: {
		background: {
			type: String,
			default: 'bg-gray-100 dark:bg-gr-dark hover:bg-gray-100 dark:hover:bg-gray-800'
		}
	},
	emits: [],
	setup() {
		const {flowTemplates, convertFlowTemplate} = useFlow();
		const {setIsGlobalLoading} = useCustomStore();
		const {t} = useTranslation();
		const {isAuthenticated} = useAuth();
		const showConfirmConvertTemplateDialog = ref(false);
		const showError = ref(false);
		const errorMessage = ref('');
		const errorTitle = ref('');
		const flowTemplatesForView = ref<FlowList|AbstractFlow[]|undefined>();
		const isLoading = ref(false);

		function clearErrorMessage() {
			errorTitle.value = '';
			errorMessage.value = '';
			showError.value = false;
		}

		function convertTemplateToFlow(flowTemplate: FlowTemplate) {
			if (!isAuthenticated.value) {
				errorTitle.value = t('login.unauthenticated.title');
				errorMessage.value = t('login.unauthenticated.description') + ' <a href="/auth" class="text-black underline">' + t('signIn') + '</a>';
				showError.value = true;
				showConfirmConvertTemplateDialog.value = false;
				return;
			}
			//TODO call api and redirect to flow with uuid from api show loading animation
			setIsGlobalLoading(true);
			const timeoutId = setTimeout(() => {
				setIsGlobalLoading(false);
			}, 10000);
			try {
				convertFlowTemplate(flowTemplate).then((result) => {
					if (result && result.uuid) {
						setTimeout(() => {
							router.push(result.getFlowLink()).then(() => {
								clearTimeout(timeoutId);
								setIsGlobalLoading(false);
							});
						}, 50);
					} else {
						errorTitle.value = t('toast.errorDefault');
						showError.value = true;
					}
				});
			} catch (e) {
				console.error(e);
				clearTimeout(timeoutId);
				setIsGlobalLoading(false);
			} finally {
				showConfirmConvertTemplateDialog.value = false;
			}
		}

		function getBlockQuoteForDialog_by_Template(flowTemplate: FlowTemplate | AbstractFlow) {
			return '<blockquote class="p-4 my-4 border-l-4 border-gray-300 bg-gray-50 dark:border-gray-500 dark:bg-gray-800">\n' +
					'    <p class="italic font-medium leading-relaxed text-gray-900 dark:text-white">' +
					flowTemplate.getTranslatedDescription() + '</p>\n' +
					'</blockquote>';
		}

		onMounted(() => {
			flowTemplatesForView.value = flowTemplates.value;
		});

		return {
			flowTemplatesForView,
			showConfirmConvertTemplateDialog,
			convertTemplateToFlow,
			getBlockQuoteForDialog_by_Template,
			clearErrorMessage,
			errorTitle,
			errorMessage,
			showError,
			isLoading,
		};
	},
});
</script>

<style scoped>


</style>
