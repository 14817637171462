<template>
	<a href='https://ko-fi.com/F1F61J5LS' target='_blank'>
		<img loading="lazy" :alt="$t('supportUsOnKoficom')" src='assets/external/kofi-support.webp' :class="height"/></a>
</template>

<script>
export default {
	name: "KoFiDonation",
	props: {
		height: {
			type: String,
			default: 'w-72'
		}
	}
}
</script>

<style scoped>
</style>
