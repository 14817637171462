export const enum GreeveRequestTypeInterface {
	DEFAULT = 'default',
	DEFAULT_HTML = 'default_html',
	COMPLETE_TEXT = 'complete_text',
	RECIPE_CREATOR = 'recipe_creator',
	STUDY_NOTES = 'study_notes',
	IMAGE_GENERATION = 'image_generation',
	CODE = 'code',
}

export const enum GreeveResponseTypeInterface {
	SEARCH_ITEM_TYPE_DEFAULT = 'html',
}

export const enum GreeveResponseSubTypeInterface {
	SEARCH_ITEM_SUBTYPE_HTML = 'html',
	SEARCH_ITEM_SUBTYPE_RECIPE = 'recipe',
	SEARCH_ITEM_SUBTYPE_STUDY = 'study',
	SEARCH_ITEM_SUBTYPE_IMAGE_GENERATION = 'image_generation',
	SEARCH_ITEM_SUBTYPE_CODE = 'code',
	SEARCH_ITEM_SUBTYPE_BLOG = 'blog',
	SEARCH_ITEM_SUBTYPE_ARTICLE = 'article',
}

export class GreeveTypeHelper {

	public getDefaultType(): string
	{
		return GreeveRequestTypeInterface.DEFAULT;
	}

	public getDefaultHtmlType(): string
	{
		return GreeveRequestTypeInterface.DEFAULT_HTML;
	}

	public getRecipeCreatorType(): string
	{
		return GreeveRequestTypeInterface.RECIPE_CREATOR;
	}

	public getStudyNotesType(): string
	{
		return GreeveRequestTypeInterface.STUDY_NOTES;
	}

	public getImageGenerationType(): string
	{
		return GreeveRequestTypeInterface.IMAGE_GENERATION;
	}

	public getCodeType(): string
	{
		return GreeveRequestTypeInterface.CODE;
	}

	public getTypeDefault(): string
	{
		return GreeveResponseTypeInterface.SEARCH_ITEM_TYPE_DEFAULT;
	}

	public getSubTypeHtml(): string
	{
		return GreeveResponseSubTypeInterface.SEARCH_ITEM_SUBTYPE_HTML;
	}

	public getSubTypeImageGeneration(): string
	{
		return GreeveResponseSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_GENERATION;
	}

	public getSubTypeRecipe(): string
	{
		return GreeveResponseSubTypeInterface.SEARCH_ITEM_SUBTYPE_RECIPE;
	}

	public getSubTypeStudy(): string
	{
		return GreeveResponseSubTypeInterface.SEARCH_ITEM_SUBTYPE_STUDY;
	}

	public getSubTypeBlog(): string
	{
		return GreeveResponseSubTypeInterface.SEARCH_ITEM_SUBTYPE_BLOG;
	}

	public getSubTypeArticle(): string
	{
		return GreeveResponseSubTypeInterface.SEARCH_ITEM_SUBTYPE_ARTICLE;
	}

	public getSubTypeCode(): string
	{
		return GreeveResponseSubTypeInterface.SEARCH_ITEM_SUBTYPE_CODE;
	}
}

export interface SearchResultInterface {
	id: number;
	uuid: string;
	type: GreeveResponseTypeInterface;
	subType: GreeveResponseSubTypeInterface;
	prompt: string;
	reference?: string;
	group_reference?: string;
	data?: any;
	rawWithoutSpecialChars?: any;
	created_at?: Date;
	updated_at?: Date;
}