import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cols-1 text-base" }
const _hoisted_2 = { class: "p-4 flex flex-wrap rounded-xl bg-gray-200 dark:bg-gr-dark justify-between gap-4" }
const _hoisted_3 = { class: "text-xs -mt-1.5 truncate" }
const _hoisted_4 = { class: "text-xs -mt-1.5 truncate" }
const _hoisted_5 = { class: "text-xs -mt-1.5 truncate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: "category-btn dark:text-gray-400 text-center w-20 p-2",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clickCategory(_ctx.getGreeveSearchType().getRecipeCreatorType())))
        }, [
          _createVNode(_component_ion_icon, {
            class: "text-2xl",
            icon: _ctx.icons.fastFoodOutline
          }, null, 8, ["icon"]),
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("categories.recipe")), 1)
        ]),
        _createElementVNode("div", {
          class: "category-btn dark:text-gray-400 text-center w-20 p-2",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clickCategory(_ctx.getGreeveSearchType().getImageGenerationType())))
        }, [
          _createVNode(_component_ion_icon, {
            class: "text-2xl",
            icon: _ctx.icons.imageOutline
          }, null, 8, ["icon"]),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("categories.image")), 1)
        ]),
        _createElementVNode("div", {
          class: "category-btn dark:text-gray-400 text-center w-20 p-2",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.clickCategory(_ctx.getGreeveSearchType().getCodeType())))
        }, [
          _createVNode(_component_ion_icon, {
            class: "text-2xl",
            icon: _ctx.icons.code
          }, null, 8, ["icon"]),
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("categories.code")), 1)
        ])
      ])
    ])
  ]))
}