export const enum DiscountType {
	DISCOUNT = 'discount',
}


export const enum DiscountObject {
	PROMO_CODE = 'promotion_code',
	COUPON = 'coupon',
}


export interface DiscountItemInterface {
	id: string,
	object: DiscountObject,
	coupon: any,
	name?: string,
	percentOff?: number,
	promotionCode?: string,
	checkoutSession?: string,
	currency?: string,
	customer?: any,
	end?: string,
	invoice?: string,
	invoiceItem?: string,
	start?: number,
	restrictions?: any,
	metaData?: any,
}