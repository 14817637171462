import {CheckoutSession} from "@/greeve/shop/checkout/session/checkout_session.type";
import {CurrencyCode} from "@/greeve/core/country/currency.type";

export class CheckoutSessionFactory {
	/**
	 *
	 * @param item
	 */
	createCheckoutSessionByResponse(item: Record<string, any>): CheckoutSession {
		const url = item.url;
		const currency = item.currency ?? CurrencyCode.EUR;
		const customer = item.customer;
		const status = item.status;
		const allowPromotionCode = item.allow_promotion_code ?? false;
		const amountSubtotal = item.amount_subtotal;
		const amountTotal = item.amount_total;
		const cancelUrl = item.cancel_url;
		const successUrl = item.success_url;

		// const shippingAmount = item.shipping_amount;
		// const taxAmount = item.tax_amount;
		const totalAmount = item.total_amount;
		const totalDetails = item.total_details;
		const data = item.data ?? null;
		const subscription = item.subscription ?? null;
		const created = item.created ? new Date(item.created) : new Date();
		const expires = new Date(item.expires);

		return new CheckoutSession(url, cancelUrl, successUrl, status, totalAmount, totalDetails, amountTotal, amountSubtotal, currency, allowPromotionCode, created, expires, customer, subscription, data);
	}
}