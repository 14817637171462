import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "fixed inset-0 z-10 overflow-y-auto" }
const _hoisted_2 = { class: "flex min-h-full justify-center p-4 text-center items-center sm:p-0" }
const _hoisted_3 = { class: "absolute right-0 top-0 hidden pr-4 pt-4 sm:block" }
const _hoisted_4 = { class: "sr-only" }
const _hoisted_5 = { class: "sm:flex sm:items-start" }
const _hoisted_6 = {
  key: 0,
  class: "mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
}
const _hoisted_7 = { class: "mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left" }
const _hoisted_8 = { class: "mt-2" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "mt-5 sm:mt-4 sm:flex sm:flex-row-reverse" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TransitionChild = _resolveComponent("TransitionChild")!
  const _component_XMarkIcon = _resolveComponent("XMarkIcon")!
  const _component_DialogTitle = _resolveComponent("DialogTitle")!
  const _component_input_field = _resolveComponent("input-field")!
  const _component_DialogPanel = _resolveComponent("DialogPanel")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_TransitionRoot = _resolveComponent("TransitionRoot")!

  return (_openBlock(), _createBlock(_component_TransitionRoot, {
    as: "template",
    show: _ctx.open
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Dialog, {
        as: "div",
        class: "relative z-10",
        onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.cancelDialogOutsideClick()))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_TransitionChild, {
            as: "template",
            enter: "ease-out duration-300",
            "enter-from": "opacity-0",
            "enter-to": "opacity-100",
            leave: "ease-in duration-200",
            "leave-from": "opacity-100",
            "leave-to": "opacity-0"
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createElementVNode("div", { class: "fixed inset-0 backdrop-filter backdrop-blur-md transition-opacity" }, null, -1)
            ])),
            _: 1
          }),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_TransitionChild, {
                as: "template",
                enter: "ease-out duration-300",
                "enter-from": "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
                "enter-to": "opacity-100 translate-y-0 sm:scale-100",
                leave: "ease-in duration-200",
                "leave-from": "opacity-100 translate-y-0 sm:scale-100",
                "leave-to": "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DialogPanel, { class: "relative transform overflow-hidden rounded-xl bg-white dark:bg-gr-dark px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("button", {
                          type: "button",
                          class: "rounded-xl bg-white text-gray-400 hover:text-gray-500 focus:outline-none",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.cancelDialog()))
                        }, [
                          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('close')), 1),
                          _createVNode(_component_XMarkIcon, {
                            class: "h-6 w-6",
                            "aria-hidden": "true"
                          })
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_5, [
                        (_ctx.showIcon)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon), {
                                class: "h-6 w-6 text-red-600",
                                "aria-hidden": "true"
                              }))
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_7, [
                          _createVNode(_component_DialogTitle, {
                            as: "h3",
                            class: "text-base font-semibold leading-6 text-gray-900"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.title), 1)
                            ]),
                            _: 1
                          }),
                          _createElementVNode("div", _hoisted_8, [
                            _createElementVNode("p", {
                              class: "text-sm text-gray-500",
                              innerHTML: _ctx.description
                            }, null, 8, _hoisted_9)
                          ])
                        ])
                      ]),
                      _createVNode(_component_input_field, {
                        value: _ctx.newValue.value,
                        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newValue.value) = $event)),
                        "input-placeholder": _ctx.inputPlaceholder,
                        onKeydown: _withKeys(_ctx.confirm, ["enter"])
                      }, null, 8, ["value", "input-placeholder", "onKeydown"]),
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("button", {
                          type: "button",
                          class: "inline-flex w-full justify-center rounded-xl bg-gr-primary px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto outline-none",
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.confirm()))
                        }, _toDisplayString(_ctx.confirmButtonName), 1),
                        _createElementVNode("button", {
                          type: "button",
                          class: "mt-3 inline-flex w-full justify-center rounded-xl bg-gray-200 dark:bg-gr-darker px-3 py-2 text-sm font-semibold text-gray-900 dark:text-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 sm:mt-0 sm:w-auto",
                          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.cancelDialog()))
                        }, _toDisplayString(_ctx.cancelButtonName), 1)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["show"]))
}