import {
	AbstractSearchItem
} from '@/greeve/search/item/abstract_search_item.type';
import { GreeveSearchItemTypeInterface } from "../search_item.interface";
import {MediaFileInfo} from '@/greeve/media/media_file_info.type';

export class SearchItemUploadInteraction extends AbstractSearchItem{
	mediaFileInfo?: MediaFileInfo

	getType(): GreeveSearchItemTypeInterface {
		return GreeveSearchItemTypeInterface.SEARCH_ITEM_TYPE_UPLOAD_INTERACTION;
	}

	public setMediaFileInfo(mediaFileInfo: MediaFileInfo): void {
		this.mediaFileInfo = mediaFileInfo;
	}

	public getMediaFileInfo(): MediaFileInfo|undefined {
		return this.mediaFileInfo;
	}
}