import {AbstractFlow} from '@/greeve/flow/abstract_flow.type';
import i18n from '@/i18n';

export class FlowTemplate extends AbstractFlow{
	//TODO get FlowPriceInformation
	getFlowPriceInformation(): string|undefined
	{
		//TODO GET BY API
		return;
	}

	getFlowIcon(): any
	{
		return;
	}

	getTranslatedName(): string
	{
		return i18n.global.t('flow.'+this.type+'.'+this.sub_type+'.title');
	}

	getTranslatedDescription(): string
	{
		return i18n.global.t('flow.'+this.type+'.'+this.sub_type+'.description');
	}

	getTemplateImage(): string
	{
		return 'https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/videos/talk_thumbnail.png';
	}

	getTemplateDetailTitle(): string
	{
		return i18n.global.t('flow.'+this.type+'.'+this.sub_type+'.detail.title');
	}

	getTemplateDetailInformation(): string
	{
		return i18n.global.t('flow.'+this.type+'.'+this.sub_type+'.detail.description');
	}
}