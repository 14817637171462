import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fixed inset-0 z-50 flex items-center justify-center bg-black/50 backdrop-blur-sm"
}
const _hoisted_2 = { class: "text-white" }

import {onMounted, ref} from 'vue';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { AuthenticationError, GreeveApiAuth } from '@/greeve/api/auth';
import useToastMessage from '@/composable/core/useToastMessage';
import useTranslation from '@/composable/translation/useTranslation';
import useCustomStore from '@/composable/custom/useCustomStore';


export default /*@__PURE__*/_defineComponent({
  __name: 'GoogleOAuthComponent',
  emits: ['onStart', 'onEnd', 'onSuccess', 'onError'],
  setup(__props, { emit: __emit }) {

const emits = __emit;

const { t } = useTranslation();
const { openToast } = useToastMessage();
const loading = ref(false); // Zustand für das Laden
const {getLanguageLocale} = useCustomStore();

const setLanguageCode = async () => {
	if (getLanguageLocale.value === 'de') {
		await FirebaseAuthentication.setLanguageCode({ languageCode: 'de-DE' });
		return;
	}
	await FirebaseAuthentication.setLanguageCode({ languageCode: 'en-US' });
};

const loginWithGoogle = async () => {
	emits('onStart');
	loading.value = true; // Ladezustand aktivieren
	try {
		await setLanguageCode();

		const authResult = await FirebaseAuthentication.signInWithGoogle();
		const authToken = await GreeveApiAuth.signInOauth(authResult);
		emits('onSuccess', authToken);
	} catch (error) {
		await handleGoogleLoginError(error as any);
	} finally {
		loading.value = false;
		emits('onEnd');
	}
};

const handleGoogleLoginError = async (error: any) => {
	let emitError = true;
	try {
		if (error instanceof AuthenticationError) {
			if (error.message.includes('Provider Data Error')) {
				await openToast(t('login.UserNotAllowed'), 'danger', 'top', true, 10000, undefined, true);
			} else {
				await openToast(t('login.error'), 'danger', 'top', true, 5000, undefined, true);
			}
		} else {
			if (error.code === 'auth/popup-closed-by-user') {
				emitError = false;
				await openToast(t('login.error'), 'warning', 'top', true, 5000, undefined, true);
			} else {
				await openToast(t('login.error'), 'danger', 'top', true, 5000, undefined, true);
			}
			if (error.error == 'popup_closed_by_user') {
				emitError = false;
				await openToast(t('login.error'), 'warning', 'top', true, 5000, undefined, true);
			} else {
				await openToast(t('login.error'), 'danger', 'top', true, 5000, undefined, true);
			}
		}
	} catch (error) {
		await openToast(t('login.errorLogin'), 'danger', 'top', true, 5000, undefined, true);
	} finally {
		if (emitError) {
			emits('onError', error);
		}
	}
};

const setupAuthStateListener = () => {
	FirebaseAuthentication.addListener('authStateChange', (state) => {
		//TODO show user Name in Google Button if exists
		if (!state.user) {
			loading.value = false;
		}
	});
};

onMounted(() => {
	setupAuthStateListener();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (loading.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('version.newVersionAvailable.button.loading')), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("button", {
      onClick: loginWithGoogle,
      type: "button",
      class: "flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent"
    }, _cache[0] || (_cache[0] = [
      _createStaticVNode("<svg class=\"h-5 w-5\" aria-hidden=\"true\" viewBox=\"0 0 24 24\" data-v-30f1814c><path d=\"M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z\" fill=\"#EA4335\" data-v-30f1814c></path><path d=\"M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z\" fill=\"#4285F4\" data-v-30f1814c></path><path d=\"M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z\" fill=\"#FBBC05\" data-v-30f1814c></path><path d=\"M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z\" fill=\"#34A853\" data-v-30f1814c></path></svg><span class=\"text-sm font-semibold leading-6\" data-v-30f1814c>Google</span>", 2)
    ]))
  ]))
}
}

})