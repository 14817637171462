import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_FooterBar = _resolveComponent("FooterBar")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, {
        "scroll-y": _ctx.$route.fullPath==='/blog'
      }, {
        default: _withCtx(() => [
          _createElementVNode("main", {
            style: _normalizeStyle({ paddingTop: _ctx.headerHeight + 'px' }),
            class: "h-full px-2 lg:px-0"
          }, [
            (_openBlock(), _createBlock(_component_ion_router_outlet, {
              key: _ctx.$route.fullPath,
              class: "relative mx-auto max-w-7xl px-4"
            }))
          ], 4),
          _createVNode(_component_FooterBar)
        ]),
        _: 1
      }, 8, ["scroll-y"])
    ]),
    _: 1
  }))
}