// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {AxiosError} from "axios/index";
import GreeveApiClient from "./client";
import {ResponseError} from "@/greeve/api/api.interfaces";
import {ApiOAuthInterface} from '@/greeve/user/oauth/api_oauth.interface';
import {OAuthFactory} from '@/greeve/user/oauth/oauth_factory.type';
import {OAuthList} from '@/greeve/user/oauth/oauth_list.type';

class AuthenticationError extends Error {
	errorCode: any;

	constructor(errorCode: any, message: string | undefined) {
		super(message);
		this.name = this.constructor.name;
		if (message != null) {
			this.message = message;
		}
		this.errorCode = errorCode;
	}
}

export interface UpdateUserDataName {
	first_name: string,
	last_name: string
}

export interface UpdateUserPassword {
	current_password: string,
	password: string,
	password_confirmation: string
}

const GreeveApiOAuth = {
	getOAuthList: async function (): Promise<OAuthList|undefined> {
		try {
			const response = await GreeveApiClient.get('/user/profile/oauth');
			if (response) {
				const responseData = response.data.data;
				const oAuthFactory = new OAuthFactory();
				if (responseData) {
					return oAuthFactory.createOAuthListByResponse(responseData);
				} else {
					return oAuthFactory.createEmptyOAuthList();
				}
			}
			return undefined;
		} catch (error: AxiosError|any) {
			if (error.response) {
				const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
				throw new ResponseError(
						error.response.status,
						errorMessage
				);
			} else if (error.request) {
				console.log(error.request);
			} else {
				console.log('Error', error.message);
			}
		}
	},

	addOAuthProfile: async function (oAuthApiData: ApiOAuthInterface) {
		try {
			const response = await GreeveApiClient.post('/user/profile/oauth/connect', oAuthApiData);
			console.log("ADD RESPONSE", response)
			if (response) {
				const responseData = response.data.data;
				if (responseData) {
					const oAuthFactory = new OAuthFactory();
					return oAuthFactory.createOAuthListByResponse(responseData);
				}
			}
			return undefined;
		} catch (error: AxiosError|any) {
			if (error.response) {
				const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
				throw new ResponseError(
						error.response.status,
						errorMessage
				);
			} else if (error.request) {
				console.log(error.request);
			} else {
				console.log('Error', error.message);
			}
		}
	},

	updateOAuthProfile: async function (oAuthApiData: ApiOAuthInterface) {
		try {
			const response = await GreeveApiClient.post('/user/profile/oauth/connect', oAuthApiData);
			if (response) {
				const responseData = response.data.data;
				if (responseData) {
					const oAuthFactory = new OAuthFactory();
					return oAuthFactory.createOAuthListByResponse(responseData);
				}
			}
			return undefined;
		} catch (error: AxiosError|any) {
			if (error.response) {
				const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
				throw new ResponseError(
						error.response.status,
						errorMessage
				);
			} else if (error.request) {
				console.log(error.request);
			} else {
				console.log('Error', error.message);
			}
		}
	},


	deleteOAuthProfile: async function (uuid: string) {
		try {
			const response = await GreeveApiClient.post('/user/profile/oauth/delete', {uuid: uuid});
			if (response) {
				const responseData = response.data.data;
				if (responseData) {
					const oAuthFactory = new OAuthFactory();
					return oAuthFactory.createOAuthListByResponse(responseData);
				}
			}
			return undefined;
		} catch (error: AxiosError|any) {
			if (error.response) {
				const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
				throw new ResponseError(
						error.response.status,
						errorMessage
				);
			} else if (error.request) {
				console.log(error.request);
			} else {
				console.log('Error', error.message);
			}
		}
	},

	catchError: function (error: any) {
		let status;
		let description;

		if (error.response === undefined) {
			status = error.message;
			description = error.message;
		} else {
			status = error.response.status;
			description = error.response.data.error_description ?? error.message;
		}

		throw new AuthenticationError(status, description);
	}
}

export {GreeveApiOAuth, AuthenticationError};
