import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, Transition as _Transition, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "px-2 sm:px-4 lg:gap-x-5 flex-none lg:flex h-full" }
const _hoisted_2 = { class: "nav rounded-2xl hidden lg:block backdrop-filter backdrop-blur-xl lg:sticky lg:top-[6.5rem]" }
const _hoisted_3 = { class: "nav pb-4 rounded-2xl h-auto" }
const _hoisted_4 = { class: "flex grow flex-col gap-y-5 overflow-y-auto bg-white px-4 nav rounded-2xl lg:block backdrop-filter backdrop-blur-xl lg:sticky lg:top-[6.5rem]" }
const _hoisted_5 = { class: "right-0 nav pb-4 rounded-2xl h-auto" }
const _hoisted_6 = { class: "px-0 p-2 right-0 sm:p-4 sm:px-0 pb-2 sm:pb-2 lg:pb-2 hidden lg:block text-right" }
const _hoisted_7 = {
  key: 1,
  class: "flex text-gray-400 dark:text-gray-500 group-hover:text-gray-500"
}
const _hoisted_8 = { class: "relative mb-12" }
const _hoisted_9 = { class: "inline-flex divide-x w-full rounded-xl text-white" }
const _hoisted_10 = ["src"]
const _hoisted_11 = ["is-menu-open"]
const _hoisted_12 = { class: "flex flex-col" }
const _hoisted_13 = { class: "flex" }
const _hoisted_14 = ["is-menu-open"]
const _hoisted_15 = { class: "flex flex-1 flex-col" }
const _hoisted_16 = {
  role: "list",
  class: "flex flex-1 flex-col gap-y-7"
}
const _hoisted_17 = {
  role: "list",
  class: "-mx-2 mt-2 space-y-1"
}
const _hoisted_18 = ["href"]
const _hoisted_19 = { class: "truncate" }
const _hoisted_20 = {
  role: "list",
  class: "-mx-2 mt-2 space-y-1"
}
const _hoisted_21 = ["href"]
const _hoisted_22 = ["is-menu-open"]
const _hoisted_23 = { class: "space-y-6 px-0 w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bars_3_icon = _resolveComponent("bars-3-icon")!
  const _component_bars3_bottom_right_icon = _resolveComponent("bars3-bottom-right-icon")!
  const _component_ChevronDownIcon = _resolveComponent("ChevronDownIcon")!
  const _component_ListboxButton = _resolveComponent("ListboxButton")!
  const _component_ListboxOption = _resolveComponent("ListboxOption")!
  const _component_ListboxOptions = _resolveComponent("ListboxOptions")!
  const _component_Listbox = _resolveComponent("Listbox")!
  const _component_WizzardComponent = _resolveComponent("WizzardComponent")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_FooterBar = _resolveComponent("FooterBar")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("section", {
            style: _normalizeStyle({ paddingTop: _ctx.headerHeight + 'px' }),
            class: "h-full pb-1 px-2 lg:px-0"
          }, [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_Transition, { name: "slide" }, {
                default: _withCtx(() => [
                  _createElementVNode("aside", {
                    class: _normalizeClass(_ctx.asideClass)
                  }, [
                    _createVNode(_Transition, { name: "slide" }, {
                      default: _withCtx(() => [
                        _createElementVNode("aside", {
                          class: _normalizeClass(_ctx.asideClass)
                        }, [
                          _createElementVNode("div", _hoisted_2, [
                            _createElementVNode("div", _hoisted_3, [
                              _createElementVNode("div", _hoisted_4, [
                                _createElementVNode("div", _hoisted_5, [
                                  _createElementVNode("div", _hoisted_6, [
                                    _createElementVNode("button", {
                                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleMenu && _ctx.toggleMenu(...args))),
                                      class: _normalizeClass(["px-2 py-2", [_ctx.toggleButtonClass, _ctx.groupMenuToggleState ? 'tooltip tooltip-right' : '' ]]),
                                      "data-tip": "ausblenden"
                                    }, [
                                      (_ctx.groupMenuToggleState === false)
                                        ? (_openBlock(), _createBlock(_component_bars_3_icon, {
                                            key: 0,
                                            class: "text-gray-400 dark:text-gray-500 group-hover:text-gray-500 flex-shrink-0 h-6 w-6"
                                          }))
                                        : (_openBlock(), _createElementBlock("span", _hoisted_7, [
                                            _createVNode(_component_bars3_bottom_right_icon, { class: "h-6 w-6" })
                                          ]))
                                    ], 2)
                                  ])
                                ]),
                                _createVNode(_component_Listbox, {
                                  as: "div",
                                  modelValue: _ctx.selected,
                                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selected) = $event))
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_8, [
                                      _createElementVNode("div", _hoisted_9, [
                                        _createVNode(_component_ListboxButton, {
                                          class: _normalizeClass('inline-flex flex-1 items-center rounded-xl px-2 py-1' + ' ' + _ctx.selected.color)
                                        }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("img", {
                                              src: _ctx.selected.logo,
                                              class: _normalizeClass([_ctx.selected.current ? '' : '', 'h-4 w-4 shrink-0']),
                                              "aria-hidden": "true"
                                            }, null, 10, _hoisted_10),
                                            _createElementVNode("p", {
                                              "is-menu-open": _ctx.groupMenuToggleState,
                                              class: "text-xs font-semibold ml-2 block truncate"
                                            }, _toDisplayString(_ctx.selected.name), 9, _hoisted_11),
                                            _createVNode(_component_ChevronDownIcon, {
                                              class: "group pointer-events-none h-6 w-6",
                                              "aria-hidden": "true"
                                            })
                                          ]),
                                          _: 1
                                        }, 8, ["class"])
                                      ]),
                                      _createVNode(_Transition, {
                                        "leave-active-class": "transition ease-in duration-100",
                                        "leave-from-class": "opacity-100",
                                        "leave-to-class": "opacity-0"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ListboxOptions, { class: "relative z-10 mt-2 w-72 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" }, {
                                            default: _withCtx(() => [
                                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navigation, (item) => {
                                                return (_openBlock(), _createBlock(_component_ListboxOption, {
                                                  as: "template",
                                                  key: item.name,
                                                  value: item
                                                }, {
                                                  default: _withCtx(({ active, selected }) => [
                                                    _createElementVNode("li", {
                                                      class: _normalizeClass([active ? 'bg-gr-primary text-white' : 'text-gray-900', 'cursor-default select-none p-3 px-4 text-sm'])
                                                    }, [
                                                      _createElementVNode("div", _hoisted_12, [
                                                        _createElementVNode("div", _hoisted_13, [
                                                          (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), {
                                                            class: _normalizeClass([item.current ? '' : '', 'h-6 w-6 shrink-0 mr-2']),
                                                            "aria-hidden": "true"
                                                          }, null, 8, ["class"])),
                                                          _createElementVNode("p", {
                                                            "is-menu-open": _ctx.groupMenuToggleState,
                                                            class: _normalizeClass(selected ? 'font-semibold' : 'font-normal')
                                                          }, _toDisplayString(item.name), 11, _hoisted_14)
                                                        ]),
                                                        _createElementVNode("p", {
                                                          class: _normalizeClass([active ? 'text-indigo-200' : 'text-gray-500', 'mt-0'])
                                                        }, _toDisplayString(item.description), 3)
                                                      ])
                                                    ], 2)
                                                  ]),
                                                  _: 2
                                                }, 1032, ["value"]))
                                              }), 128))
                                            ]),
                                            _: 1
                                          })
                                        ]),
                                        _: 1
                                      })
                                    ])
                                  ]),
                                  _: 1
                                }, 8, ["modelValue"]),
                                _createElementVNode("nav", _hoisted_15, [
                                  _createElementVNode("ul", _hoisted_16, [
                                    _createElementVNode("li", null, [
                                      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "text-xs font-semibold leading-6 text-gray-400" }, "Actions", -1)),
                                      _createElementVNode("ul", _hoisted_17, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actions, (action) => {
                                          return (_openBlock(), _createElementBlock("li", {
                                            key: action.name
                                          }, [
                                            _createElementVNode("a", {
                                              href: action.href,
                                              class: _normalizeClass([action.current ? 'bg-gray-50 text-gray-900' : 'text-gray-700 hover:text-gray-900 hover:bg-gray-50', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'])
                                            }, [
                                              (_openBlock(), _createBlock(_resolveDynamicComponent(action.icon), {
                                                class: _normalizeClass([action.current ? 'text-gray-900' : 'text-gray-400 group-hover:text-gray-900', 'h-6 w-6 shrink-0']),
                                                "aria-hidden": "true"
                                              }, null, 8, ["class"])),
                                              _createElementVNode("span", _hoisted_19, _toDisplayString(action.name), 1)
                                            ], 10, _hoisted_18)
                                          ]))
                                        }), 128))
                                      ])
                                    ]),
                                    _createElementVNode("li", null, [
                                      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "text-xs font-semibold leading-6 text-gray-400" }, "Workflows", -1)),
                                      _createElementVNode("ul", _hoisted_20, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workflows, (workflow) => {
                                          return (_openBlock(), _createElementBlock("li", {
                                            key: workflow.name
                                          }, [
                                            _createElementVNode("a", {
                                              href: workflow.href,
                                              class: _normalizeClass([workflow.current ? 'bg-gray-50 text-gray-900' : 'text-gray-700 hover:text-gray-900 hover:bg-gray-50', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'])
                                            }, [
                                              (_openBlock(), _createBlock(_resolveDynamicComponent(workflow.icon), {
                                                class: _normalizeClass([workflow.current ? 'text-gray-900' : 'text-gray-400 group-hover:text-gray-900', 'h-6 w-6 shrink-0']),
                                                "aria-hidden": "true"
                                              }, null, 8, ["class"])),
                                              _createElementVNode("span", {
                                                class: "truncate",
                                                "is-menu-open": _ctx.groupMenuToggleState
                                              }, _toDisplayString(workflow.name), 9, _hoisted_22)
                                            ], 10, _hoisted_21)
                                          ]))
                                        }), 128))
                                      ])
                                    ])
                                  ])
                                ])
                              ])
                            ])
                          ])
                        ], 2)
                      ]),
                      _: 1
                    })
                  ], 2)
                ]),
                _: 1
              }),
              _createElementVNode("main", _hoisted_23, [
                (_ctx.currentWizzard)
                  ? (_openBlock(), _createBlock(_component_WizzardComponent, {
                      key: 0,
                      wizzard: _ctx.currentWizzard
                    }, null, 8, ["wizzard"]))
                  : _createCommentVNode("", true),
                (_openBlock(), _createBlock(_component_ion_router_outlet, {
                  key: _ctx.$route.fullPath,
                  class: "h-auto w-full absolute lg:relative"
                }))
              ])
            ])
          ], 4),
          _withDirectives(_createVNode(_component_FooterBar, { class: "mt-12" }, null, 512), [
            [_vShow, false]
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}