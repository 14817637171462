<template>
	<TransitionRoot as="template" :show="open">
		<Dialog as="div" class="relative z-10" @close="cancelDialogOutsideClick()">
			<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
											 leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
				<div class="fixed inset-0 backdrop-filter backdrop-blur-md transition-opacity"/>
			</TransitionChild>

			<div class="fixed inset-0 z-10 overflow-y-auto">
				<div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
					<TransitionChild as="template" enter="ease-out duration-300"
													 enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
													 enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
													 leave-from="opacity-100 translate-y-0 sm:scale-100"
													 leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
						<DialogPanel
								class="relative transform overflow-hidden rounded-xl bg-white dark:bg-gr-dark px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
							<div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
								<button type="button" class="rounded-xl bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
												@click="cancelDialog()">
									<span class="sr-only">{{ $t('close') }}</span>
									<XMarkIcon class="h-6 w-6" aria-hidden="true"/>
								</button>
							</div>
							<div class="sm:flex sm:items-start">
								<div
										class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
									<component :is="icon" class="h-6 w-6 text-red-600" aria-hidden="true"/>
								</div>
								<div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
									<DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">{{ title }}</DialogTitle>
									<div class="mt-2">
										<p class="text-sm text-gray-500" v-html="description"></p>
									</div>
								</div>
							</div>
							<div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
								<button type="button"
												class="inline-flex w-full justify-center rounded-xl bg-gr-primary px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto outline-none"
												@click="confirm()">{{ confirmButtonName }}
								</button>
								<button type="button"
												class="mt-3 inline-flex w-full justify-center rounded-xl bg-gray-200 dark:bg-gr-darker px-3 py-2 text-sm font-semibold text-gray-900 dark:text-gray-50 shadow-sm ring-1 ring-inset ring-gray-300 sm:mt-0 sm:w-auto"
												@click="cancelDialog()">{{ cancelButtonName }}
								</button>
							</div>
						</DialogPanel>
					</TransitionChild>
				</div>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref, toRef, watch} from 'vue';
import useTranslation from '@/composable/translation/useTranslation';
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue';
import {XMarkIcon, ExclamationTriangleIcon} from '@heroicons/vue/24/outline';

export interface ScriptInterface {
	src: string;
	type?: string;
	arguments?: any;
	content?: string;
}

export default defineComponent({
	name: 'ConfirmDialog',
	components: {
		XMarkIcon, ExclamationTriangleIcon,
		Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,
	},
	props: {
		title: {type: String},
		description: {type: String},
		confirmButtonName: {type: String, default: 'Confirm'},
		icon: {type: String, default: ExclamationTriangleIcon},
		cancelButtonName: {type: String, default: 'Cancel'},
		visible: {type: Boolean, default: false},
		type: {type: String, default: 'alert'},
		allowBodyDismiss: {
			type: Boolean,
			default: true,
		},
		showDismissButton: {
			type: Boolean,
			default: true,
		},
		showLoadingAnimationOnConfirm: {
			type: Boolean,
			default: true,
		},
	},
	emits: ['confirm', 'cancelConfirm'],
	setup(props, {emit}) {
		const dialogTitle = toRef(props, 'title');
		const dialogDescription = toRef(props, 'description');
		const visible = toRef(props, 'visible');
		const open = ref(visible.value);
		const {t} = useTranslation();

		function toggleDialog() {
			open.value = !open.value;
		}

		const cancelDialogOutsideClick = () => {
			if (props.allowBodyDismiss) {
				open.value = false;
				emit('cancelConfirm');
			}
		};

		const cancelDialog = () => {
			open.value = false;
			emit('cancelConfirm');
		};

		function confirm() {
			emit('confirm');
		}

		watch(() => props.visible, (newValue: boolean) => {
			open.value = newValue;
		});

		onMounted(() => {
			//
		});

		return {
			dialogTitle,
			dialogDescription,
			open,
			toggleDialog,
			confirm,
			cancelDialog,
			cancelDialogOutsideClick,
			t,
		};
	},
});
</script>

<style lang="scss">
</style>
