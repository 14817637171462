<template>
	<div class="rounded-lg">
		<label class="ml-6 bg-transparent h-5 block text-left text-sm font-medium text-gray-400"
			   for="email">{{ $t('jobs.FormMail') }}</label>
		<div>
			<div class="outer-card rounded-xl overflow-hidden">
				<ion-input id="email" aria-describedby="email-error" aria-invalid="true" :placeholder="inputPlaceholder"
						   :required="requiredField"
						   :type="inputType"
						   v-model="emailState"
						   autocomplete="email"
						   :class="[ errorMessage.length > 0 ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500' : '']"/>
			</div>
			<div v-show="errorMessage.length > 0"
				 class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
				<ExclamationCircleIcon aria-hidden="true" class="h-5 w-5 text-red-500"/>
			</div>
		</div>
		<p v-show="errorMessage.length > 0" id="email-error" class="mt-2 text-sm text-red-600">{{ errorMessage }}</p>
	</div>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue';
import {useVModel} from '@/composable/input/useVModel';
import {ExclamationCircleIcon} from '@heroicons/vue/20/solid'
import {IonInput} from '@ionic/vue';

export default defineComponent({
	name: 'InputNickMail',
	components: {ExclamationCircleIcon, IonInput},
	emits: ['update:email', 'update:password'],
	props: {
		email: String,
		inputType: {
			type: String,
			default: "email"
		},
		inputPlaceholder: {
			type: String,
			default: "you@example.com"
		},
		requiredField: {
			type: Boolean,
			default: true
		},
		errorMessage: {
			type: String,
			default: ""
		},
	},
	setup(props) {

		const errorMsg = ref(props.errorMessage);

		function hasError() {
			return errorMsg.value && errorMsg.value.length > 0;
		}

		return {
			emailState: useVModel(props, 'email'), ExclamationCircleIcon, hasError, errorMsg
		};
	}
})
</script>

<style lang="scss">
/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: currentColor;
  -webkit-box-shadow: none;
  transition: background-color 5000s ease-in-out 0s;
  color: currentColor;
  background-color: transparent;
}

.input-shadow {
  --box-shadow: inset 0.2rem 0.2rem 0.5rem var(--grayLight-2), inset -0.2rem -0.2rem 0.5rem var(--whyte) !important;
}

ion-input {
  @apply rounded-xl h-12 lg:h-10 lg:border-0 dark:border-gray-400 relative #{!important};
  box-shadow: inset 0.2rem 0.2rem 0.5rem var(--grayLight-2), inset -0.2rem -0.2rem 0.5rem var(--whyte);
}

.native-input {
  @apply px-6 #{!important};
}

</style>
