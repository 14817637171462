<template>
	<ion-content :scroll-events="true" @ionScroll="onScroll" class="relative mx-auto px-4" v-if="post">
		<div ref="progressBar" class="blog-post-progress"></div>

		<!-- Image Hero Banner -->
		<section class="relative">
			<img :src="post.header_banner" alt="Hero Banner" class="w-full h-78 object-cover">
			<div class="absolute inset-0 bg-black opacity-50"></div>
			<div class="absolute inset-0 flex flex-col items-center justify-center text-white">
				<h1 class="text-4xl font-bold mb-4">{{ post.title }}</h1>
			</div>
		</section>

		<!-- Main Content -->
		<div class="container mx-auto px-2 py-2 flex bg-white my-4 rounded-2xl min-h-screen shadow-2xl">
			<!-- Table of Contents -->
			<aside class="w-1/4 hidden lg:block p-3 bg-gray-200 rounded-xl">
				<nav class="sticky top-16">
					<router-link to="/blog" class="flex items-center text-gr-dark bg-white rounded hover:underline">
						<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
						</svg>
						Back to Blog
					</router-link>
					<h2 class="text-xl font-bold mb-4 pt-2">Inhaltsverzeichnis</h2>
					<ul class="list-disc list-inside">
						<li v-for="item in post.tableOfContents" :key="item.title">
							<a @click="scrollToElement(item.title, 0, 0,'center', 'nearest', 2, true)" class="cursor-pointer text-gray-600 hover:underline">{{ item.title }}</a>
						</li>
					</ul>
				</nav>
			</aside>

			<!-- Blog Content -->
			<article class="p-4 pl-8 w-full lg:w-3/4" v-html="post.content"></article>
		</div>
	</ion-content>
</template>
<script lang="ts">
import {defineComponent, onBeforeMount, ref} from 'vue';
import useBlog from '@/composable/blog/useBlog';
import {Blog} from '@/greeve/blog/blog.type';
import useSystem from '@/composable/core/useSystem';
import {IonContent} from '@ionic/vue';
import {useHeaderHeight} from '@/composable/global/useHeaderHeight';

export default defineComponent({
	name: 'PostPage',
	components: {IonContent},
	props: {
		slug: {
			type: String,
		},
	},
	setup(props) {
		const {getBlogBySlug} = useBlog();
		const {getSeoManager, scrollToElement, getAnchorFromUrl} = useSystem();
		const post = ref<Blog>();
		const {headerHeight} = useHeaderHeight();

		const progressBar = ref<HTMLElement>();

		const onScroll = (event: any) => {
			if (!progressBar.value) {
				return;
			}

			const scrollTop = event.detail.scrollTop + headerHeight.value + 22;

			const { scrollHeight } = event.target;

			const scrolled = (scrollTop*100)/scrollHeight;
			progressBar.value.style.width = `${scrolled}%`;
		};


		const loadBlog = () => {
			if (!props.slug) {
				return;
			}
			getBlogBySlug(props.slug).then((blog) => {
				post.value = blog;
				let title = post.value?.title;
				if (!title) {
					return;
				}
				title = 'Greeve Blog - ' + title;
				getSeoManager().updateSeo(title, post.value?.contentAsText ?? '', post.value?.keywordList.join(', ') ?? '', post.value?.header_banner);
				const anchor = getAnchorFromUrl();
				if (anchor) {
					scrollToElement(anchor, 0, 400, 'center', 'nearest', 2);
				}
			});
		}

		onBeforeMount(() => {
			loadBlog();
			const progressBarElement = document.getElementById('progressBar');
			if (progressBarElement) {
				progressBar.value = progressBarElement;
			}
		});

		return {
			post,
			onScroll,
			progressBar,
			scrollToElement,
		};
	},
});

</script>
<style lang="scss">

.bg-gradient {
	background: linear-gradient(90deg, rgba(246, 0, 0, 1) 0%, rgba(255, 211, 0, 1) 34.31%, rgba(255, 118, 0, 1) 100%);
}

.blog-post-progress {
	position: fixed;
	top: 0;
	left: 0;
	width: 0%;
	height: 4px;
	background: linear-gradient(90deg, rgba(246, 0, 0, 1) 0%, rgba(255, 211, 0, 1) 34.31%, rgba(255, 118, 0, 1) 100%);
	z-index: 50;
}
</style>

