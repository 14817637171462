import {PaginationMetaInterface} from '@/greeve/core/pagination_meta.interface';
import {
	QuotaTransaction,
} from '@/greeve/user/quota/transaction/quota_transaction.type';
import {
	QuotaTransactionState,
	QuotaTransactionType,
} from '@/greeve/user/quota/transaction/quota_transaction.interface';
import {ListItemInterface} from '@/components/core/List.vue';
import {ArrowDownCircleIcon, ArrowUpCircleIcon} from '@heroicons/vue/20/solid';
import {formatDate} from '@/greeve/core/date';

export class QuotaTransactionList extends Array<QuotaTransaction> {

	public paginationMetaData?: PaginationMetaInterface;

	constructor(items: Array<QuotaTransaction> = []) {
		super();
		this.push(...items);
	}

	add(items: QuotaTransaction) {
		this.push(items);
	}

	remove(index: number) {
		this.splice(index, 1);
	}

	removeAll() {
		const itemCount = this.length;
		this.splice(itemCount, 1);
	}

	hasData(): boolean {
		return this.length > 0;
	}

	getQuotaTransactions_by_Type(
			type: QuotaTransactionType, state?: QuotaTransactionState,
			clone = true): QuotaTransaction[] {
		if (clone) {
			const quotaTransactionList: QuotaTransactionList | any[] = [...this];

			return quotaTransactionList.filter((quotaTranaction) => {
				let found = true;
				if (type) {
					found = quotaTranaction.type === type;
				}
				if (!found) {
					return found;
				}
				if (state) {
					found = quotaTranaction.state === state;
				}
				if (!found) {
					return found;
				}
				return quotaTranaction;
			});
		} else {
			return this.filter((quotaTranaction) => {
				let found = true;
				if (type) {
					found = quotaTranaction.type === type;
				}
				if (!found) {
					return found;
				}
				if (state) {
					found = quotaTranaction.state === state;
				}
				if (!found) {
					return found;
				}
				return quotaTranaction;
			});
		}
	}

	getQuotaTransaction(index: number, clone = true): QuotaTransaction {
		if (clone) {
			const quotaTransactionList: QuotaTransactionList | any[] = [...this];
			return quotaTransactionList[index];
		} else {
			return this[index];
		}
	}

	getQuotaTransaction_by_Id(
			QuotaTransactionId: number, clone = true): QuotaTransaction | undefined {
		if (clone) {
			const quotaTransactionList: QuotaTransactionList | any[] = [...this];
			return quotaTransactionList.find(({id}) => id === QuotaTransactionId);
		} else {
			return this.find(({id}) => id === QuotaTransactionId);
		}
	}

	enhanceMetaData(metaData: any) {
		this.paginationMetaData = metaData;
	}

	getQuotaTransactionListByName(QuotaTransactionName: string): QuotaTransactionList | QuotaTransaction[] {
		const quotaTransactionList: QuotaTransactionList | any[] = [...this];
		return quotaTransactionList.filter((QuotaTransaction) => {
			return QuotaTransaction.name === QuotaTransactionName;
		});
	}

	getQuotaTransactionListByReference(QuotaTransactionReference: string): QuotaTransactionList | QuotaTransaction[] {
		const quotaTransactionList: QuotaTransactionList | any[] = [...this];
		return quotaTransactionList.filter((QuotaTransaction) => {
			return QuotaTransaction.reference === QuotaTransactionReference;
		});
	}

	getQuotaTransaction_ListItems(hideCanceled = true): ListItemInterface[] {
		const result: ListItemInterface[] = [];
		const quotaTransactionList: QuotaTransactionList | any[] = [...this];
		quotaTransactionList.forEach((transaction: QuotaTransaction) => {
			if (hideCanceled && transaction.type === QuotaTransactionType.QUOTA_TRANSACTION_TYPE_CANCELED) {
				return;
			}
			const color = transaction.type === QuotaTransactionType.QUOTA_TRANSACTION_TYPE_DEPOSIT ? 'green' : 'red';

			let createDateFormatted = '';
			if (transaction.created_at) {
				createDateFormatted = formatDate(transaction.created_at, 'medium', 'short');
			}
			const listItem: ListItemInterface = {
				id: transaction.uuid,
				title: transaction.getTransactionDetailName(),
				detail: transaction.getTransactionInfo(),
				additionalInfo: '',
				info: '',
				labels: [{key: transaction.type, value: transaction.getTransactionName(), color: color},{key: createDateFormatted, value: createDateFormatted, color: 'gray'}],
				link: '',
				date: transaction.created_at?.toLocaleDateString(),
				//eslint-disable-next-line
				icon: transaction.type ===
				QuotaTransactionType.QUOTA_TRANSACTION_TYPE_DEPOSIT
						? ArrowUpCircleIcon
						: ArrowDownCircleIcon,
				iconColor: color,
			};
			result.push(listItem);
		});
		return result;
	}
}