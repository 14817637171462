class MarkdownParser {

	private _cssNamespace = '';

	private _htmlMarkdown = '';

	constructor(cssNameSpace = '') {
		this._cssNamespace = cssNameSpace;
	}

	get cssNamespace(): string {
		return this._cssNamespace;
	}

	set cssNamespace(value: string) {
		this._cssNamespace = value;
	}

	get htmlMarkdown(): string {
		return this._htmlMarkdown;
	}

	set htmlMarkdown(value: string) {
		this._htmlMarkdown = value;
	}

	public isContentSame(content: string): boolean {
		if (!content) {
			return false;
		}
		return this.htmlMarkdown === content;
	}

	public markdownToHtml(markdownText: string): string {
		if (this.isContentSame(markdownText)) {
			return this.htmlMarkdown;
		}
		// Convert headers
		// markdownText = markdownText.replace(/(?:\n|^)#{1} (.+)/g, (match, p1) => {
		// 	const id = this.getAnchorByTitle(p1);
		// 	return `<h1 id="${id}">${p1}</h1>`;
		// });
		// Convert headers (h1 to h6)
		for (let i = 1; i <= 6; i++) {
			const regex = new RegExp(`(?:\\n|^)#{${i}} (.+)`, 'g');
			markdownText = markdownText.replace(regex, (match, p1) => {
				const id = this.getAnchorByTitle(p1);
				return `<h${i} id="${id}">${p1}</h${i}>`;
			});
		}
		// markdownText = markdownText.replace(/(?:\n|^)#{1} (.+)/g,`<h1>$1</h1>`);
		markdownText = markdownText.replace(/(?:\n|^)#{2} (.+)/g,`<h2>$1</h2>`);
		markdownText = markdownText.replace(/(?:\n|^)#{3} (.+)/g,`<h3>$1</h3>`);
		markdownText = markdownText.replace(/(?:\n|^)#{4} (.+)/g,`<h4>$1</h4>`);
		markdownText = markdownText.replace(/(?:\n|^)#{5} (.+)/g,`<h5>$1</h5>`);
		markdownText = markdownText.replace(/(?:\n|^)#{6} (.+)/g,`<h6>$1</h6>`);
		// Convert bold text
		markdownText = markdownText.replace(/\*\*(.+?)\*\*/g, `<strong>$1</strong>`);
		markdownText = markdownText.replace(/__(.+?)__/g, `<strong>$1</strong>`);
		// Convert italic text
		markdownText = markdownText.replace(/\*(.+?)\*/g, `<em>$1</em>`);
		markdownText = markdownText.replace(/_(.+?)_/g, `<em>$1</em>`);
		// Convert links, but exclude images
		markdownText = markdownText.replace(/(?<!!)\[(.*?)]\((.*?)\)/g, `<a href="$2" target="_blank">$1</a>`);
		// Convert images
		markdownText = markdownText.replace(/!\[(.*?)\]\((.*?)\)/g, (match, alt, src) => {
			return `<img src="${src}" alt="${alt}"/>`;
		})
		// Convert checklists
		markdownText = markdownText.replace(/- \[ \] (.+)/g, (match, p1) => {
			return `<div class="relative flex items-center ${this._cssNamespace}"><div class="flex h-6 items-center"><input id="comments" aria-describedby="comments-description" name="checkbox" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" disabled /></div><div class="ml-3 text-sm leading-6"><label for="checkbox" class="font-medium text-gray-900">${p1}</label></div></div>`;
		});
		markdownText = markdownText.replace(/- \[x\] (.+)/g, (match, p1) => {
			return `<div class="relative flex items-center ${this._cssNamespace}"><div class="flex h-6 items-center"><input id="comments" aria-describedby="comments-description" name="checkbox" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" checked disabled /></div><div class="ml-3 text-sm leading-6"><label for="checkbox" class="font-medium text-gray-900">${p1}</label></div></div>`;
		});

		// Convert code blocks
		markdownText = markdownText.replace(
				/```(\w+)\n([\s\S]*?)```/g,
				(match, language, codeBlock) => {
					// Escape backticks within code content
					const escapedCodeBlock = codeBlock.replace(/`/g, '&#96;');
					return `<pre class="${this._cssNamespace} language-${language}"><code class="${this._cssNamespace} language-${language}">${escapedCodeBlock}</code></pre>`;
				}
		);

		// Convert inline code
		markdownText = markdownText.replace(/`([^`]*)`/g, `<code>$1</code>`);

		// Convert blockquotes
		markdownText = markdownText.replace(/(?:\n|^)>(.+)/g, `<blockquote>$1</blockquote>`);

		// Convert unordered lists
		markdownText = markdownText.replace(/(?:\n|^)- (.+)/g, `<ul><li>$1</li></ul>`);

		// Convert ordered lists
		markdownText = markdownText.replace(/(?:\n|^)\d+\. (.+)/g, `<ol><li>$1</li></ol>`);

		// Handle line breaks within paragraphs
		markdownText = markdownText.replace(/([^\n])\n([^\n])/g, '$1<br/>$2');

		// Handle paragraphs by wrapping text blocks in <p> tags
		markdownText = markdownText.split(/\n{2,}/).map(paragraph => `<p>${paragraph}</p>`).join('');

		// Convert tables
		const tableRegex = /\|(.+?)\|(.+?)\|(.+?)\|(.+?)\|\s*(?:\n|\r\n?)((?: *\|.+\|\n)+)(?:\n|\r\n?)?/g;

		markdownText = markdownText.replace(tableRegex, (match: string, ...tableParts: string[]) => {
			const headers: string[] = tableParts.slice(0, 4).map(header => header.trim());
			const rows: string[] = tableParts[4].trim().split(/\n|\r\n/);

			let tableHtml = `<div class="${this._cssNamespace} overflow-hidden rounded-lg bg-white shadow"><div class="px-4 py-5 sm:p-6"><table class="min-w-full divide-y divide-gray-300 ${this._cssNamespace}">`;
			tableHtml += `<thead><tr>`;
			headers.forEach((header: string) => {
				tableHtml += `<th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8">${header}</th>`;
			});
			tableHtml += `</tr></thead><tbody class="divide-y divide-gray-200 bg-white">`;
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			rows.forEach((row: string, index: number) => {
				row = row.replace(/\|[-: ]+\|[-: ]+\|[-: ]+\|[-: ]+\|/g, '');
				const cells: string[] = row.trim().split('|').slice(1, -1).map(cell => cell.trim());
				if (cells.length > 0) {
					tableHtml += `<tr>`;
					cells.forEach((cell: string) => {
						tableHtml += `<td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">${cell}</td>`;
					});
					tableHtml += `</tr>`;
				}
			});
			tableHtml += `</tbody></table></div></div>`;

			return tableHtml;
		});

		// Convert custom color syntax
		markdownText = markdownText.replace(/\{color:(.+?)\}(.+?)\{\/color\}/g, `<span style="color:$1">$2</span>`);

		this.htmlMarkdown = `<div class="${this._cssNamespace}">${markdownText}</div>`;
		return this.htmlMarkdown;
	}


	public markdownToText(markdownText: string): string {
		// Remove Headers
		markdownText = markdownText.replace(/(?:\n|^)#{1,6} (.+)/g, '$1');

		// Remove bold text
		markdownText = markdownText.replace(/\*\*(.+?)\*\*/g, '$1');
		markdownText = markdownText.replace(/__(.+?)__/g, '$1');

		// Remove italic text
		markdownText = markdownText.replace(/\*(.+?)\*/g, '$1');
		markdownText = markdownText.replace(/_(.+?)_/g, '$1');

		// Remove links but keep the link text
		markdownText = markdownText.replace(/\[(.*?)\]\((.*?)\)/g, '$1');

		// Remove images but keep the alt text
		markdownText = markdownText.replace(/!\[(.*?)\]\((.*?)\)/g, '$1');

		// Remove code blocks
		markdownText = markdownText.replace(/```[\s\S]*?```/g, '');

		// Remove inline code
		markdownText = markdownText.replace(/`([^`]*)`/g, '$1');

		// Remove blockquotes
		markdownText = markdownText.replace(/(?:\n|^)> (.+)/g, '$1');

		// Remove unordered list markers but keep the text
		markdownText = markdownText.replace(/(?:\n|^)- (.+)/g, '$1');

		// Remove ordered list markers but keep the text
		markdownText = markdownText.replace(/(?:\n|^)\d+\. (.+)/g, '$1');

		// Remove table syntax but keep the text
		markdownText = markdownText.replace(/\|(.+?)\|(.+?)\|(.+?)\|(.+?)\|\s*(?:\n|\r\n?)((?: *\|.+\|\n)+)(?:\n|\r\n?)?/g, (match: string, ...tableParts: string[]) => {
			const headers = tableParts.slice(0, 4).map(header => header.trim());
			const rows = tableParts[4].trim().split(/\n|\r\n/);

			let tableText = headers.join(' ') + '\n';
			rows.forEach((row: string) => {
				row = row.replace(/\|[-: ]+\|[-: ]+\|[-: ]+\|[-: ]+\|/g, '');
				const cells = row.trim().split('|').slice(1, -1).map(cell => cell.trim());
				if (cells.length > 0) {
					tableText += cells.join(' ') + '\n';
				}
			});

			return tableText;
		});

		// Remove custom color syntax but keep the text
		markdownText = markdownText.replace(/\{color:(.+?)\}(.+?)\{\/color\}/g, '$2');

		// Remove any remaining markdown symbols
		markdownText = markdownText.replace(/[*_~`]/g, '');

		return markdownText.trim();
	}

	private getAnchorByTitle(title: string): string
	{
		return title.toLowerCase().replace(/[^a-z0-9]+/g, '-');
	}

	public getTableOfContentsByMarkdown(markdownText: string): Array<{ title: string, level: number, active: boolean }> {
		const lines = markdownText.split('\n');
		const toc = [];

		for (const line of lines) {
			const match = /^(#{1,6}) (.+)/.exec(line);
			if (match) {
				const level = match[1].length;
				const title = match[2];
				const anchor = this.getAnchorByTitle(title);
				const isActive = window.location.hash === `#${anchor}`;

				toc.push({ title, level, active: isActive });
			}
		}

		return toc;
	}
}

export default MarkdownParser;