<template>
	<main class="relative mx-auto px-4">
		<h1>Greeve Blog</h1>
		<div class="mx-auto mt-2 grid max-w-2xl grid-cols-1 gap-y-20 gap-x-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
			<article v-for="post in blogs" :key="post.id"
							 class="outer-card nav p-4 flex flex-col items-start justify-between">
				<div class="relative w-full">
					<router-link :to="post.link">
						<img :src="post.header_banner" alt=""
								 class="aspect-[16/9] w-full rounded-xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"/>
						<div class="absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10"/>
					</router-link>
				</div>
				<div class="max-w-xl">
					<div class="mt-4 flex items-center gap-x-4 text-xs">
						<time v-if="post.created_at" :datetime="post.formattedDate" class="text-gray-500">{{ post.formattedDate }}</time>
						<div class="relative rounded-full bg-gray-50 py-1.5 px-3 font-medium text-gray-600" v-for="category in post.categories" :key="category">{{category }}</div>
					</div>
					<div class="group relative">
						<h3 class="mt-3 text-lg font-semibold leading-6 text-gray-900 dark:text-gray-500 group-hover:text-gray-600">
							<!--											TODO add Article ID-->
							<router-link :to="post.link">
								<span class="absolute inset-0"/>
								{{ post.title }}
							</router-link>
						</h3>
						<p class="mt-5 text-sm leading-6 text-gray-600 line-clamp-3" v-html="post.content"></p>
					</div>
					<div class="relative mt-6 flex items-center gap-x-4">
						<img :src="post.author" alt="" class="h-10 w-10 rounded-full bg-gray-100"/>
						<div class="text-sm leading-6">
							<p class="font-semibold text-gray-900 dark:text-gray-500">
								<!--												<a :href="post.author.href">-->
								<!--													<span class="absolute inset-0" />-->
								<!--													{{ post.author.name }}-->
								<!--												</a>-->
								<span class="absolute inset-0"/>
								{{ post.author }}
							</p>
							<p class="text-gray-600">{{ post.author }}</p>
						</div>
					</div>
				</div>
			</article>
		</div>
	</main>
</template>
<script lang="ts">
import {defineComponent, onMounted} from 'vue';
import useBlog from '@/composable/blog/useBlog';

export default defineComponent({
	name: 'BlogListPage',
	components: {},
	setup() {
		const {blogs, initBlogs} = useBlog();

		onMounted(() => {
			initBlogs(true).then(() => {
				if (blogs.value) {
					blogs.value?.initSeo();
				}
			});
		});

		return {
			blogs,
		};
	},
});

</script>
<style lang="scss">

.bg-gradient {
	background: linear-gradient(90deg, rgba(246, 0, 0, 1) 0%, rgba(255, 211, 0, 1) 34.31%, rgba(255, 118, 0, 1) 100%);
}
</style>

