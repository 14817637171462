<template>
	<div>
		<div card-padding="px-6 pb-6 sm:px-9 py-6" >
			<DangerAlert :error-message="errorMessage" :error-title="errorTitle" :show-title="true" @closeAlert="clearErrorMessage()"></DangerAlert>
			<form class="space-y-3 -mt-5" @submit.prevent="onSubmit">
				<input-nick-mail v-model:email="user.email" :error-message="user.emailError" required-field="true"/>
				<input-password v-model:password="user.password" :error-message="user.passwordError"
								required-field="true"/>
        <DefaultButton :button-text="$t('auth.RegisterComponent.registerBtnRegister')" button-type="submit"
                       button-width="w-full" style="margin-top: 1.3rem;"></DefaultButton>


				<div class="flex items-start px-4 pt-2">
					<div class="flex h-5 mt-0.5 items-center">
						<input id="terms" v-model="user.terms" aria-describedby="terms-description" name="terms"
							   required type="checkbox" class="h-4 w-4 rounded border-gray-200 text-indigo-600"/>
					</div>
					<div class="ml-3 text-sm">
						<label for="offers"
							   class="font-medium text-gray-700 dark:text-gray-400 text-xs">{{
								$t("terms.TermsTitle")
							}}</label>
						<p class="text-gray-500 text-xs">{{ $t("auth.RegisterComponent.dataProcessing") }}</p>
					</div>
				</div>

        <div class="mt-8">
          <div>
            <div class="relative mt-6">
<!--              <div class="absolute inset-0 flex items-center" aria-hidden="true">-->
<!--                <div class="w-full border-t border-gray-700"/>-->
<!--              </div>-->
<!--              <div class="relative flex justify-center text-sm">-->
<!--								<span class="bg-white dark:bg-gr-darker px-2 text-gray-500 dark:text-gray-500 rounded-xl">-->
<!--					{{ $t('auth.RegisterComponent.registerExternal') }}</span>-->
<!--              </div>-->
            </div>
          </div>
<!--          <div class="w-full my-6">-->
<!--            <google-login-component></google-login-component>-->
<!--          </div>-->
        </div>


        <!--        TODO include Recaptcha-->
<!--				<div></div>-->

			</form>
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent, reactive} from 'vue';
import InputNickMail from "@/components/inputs/InputNickMail.vue";
import InputPassword from "@/components/inputs/InputPassword.vue";
import useUserRepository from "@/composable/greeve/useUser";
import DefaultButton from "@/components/core/DefaultButton.vue";
import router from '@/router';
import useTranslation from "@/composable/translation/useTranslation";
import DangerAlert from "@/components/alert/Base/DangerAlert.vue";
import useAuth from "@/composable/auth/useAuth";
import i18n from "@/i18n";
import {GreeveApiAuth} from "@/greeve/api/auth";

export default defineComponent({
	name: 'RegisterComponent',
	components: {
		DefaultButton,
		InputNickMail,
		InputPassword,
		DangerAlert,
	},
	props: {
		redirectAfterSubmit: {
			type: Boolean,
			default: false
		},
		showSwitch: {
			type: Boolean,
			default: true
		},
	},
	emits: ['onRegisterSuccess', 'onRegisterError'],
	setup(props, {emit}) {
		const {initUserByUserType} = useUserRepository();
		const {
			errorMessage,
			errorTitle,
			clearErrorMessage,
			setErrorTitle,
			setErrorMessage,
			getDeviceHash
		} = useAuth();

		const user = reactive({
			email: "",
			emailError: "",
			password: "",
			passwordError: "",
			terms: false,
		});

		const {t,} = useTranslation();

		function clearUserErrors() {
			user.emailError = "";
			user.passwordError = "";
		}

		async function onSubmit() {
			clearErrorMessage();
			clearUserErrors();

			const registerData = {
				name: user.email,
				email: user.email,
				password: user.password,
				password_confirmation: user.password,
				terms: user.terms,
				device_name: getDeviceHash(),
			};

			try {
				const token = await GreeveApiAuth.registerUser(registerData);
				if (token) {
					const userResponse = await GreeveApiAuth.getUser();
					if (userResponse && userResponse.id) {
						initUserByUserType(userResponse);
						if (props.redirectAfterSubmit) {
							router.push('/');
						}
						emit('onRegisterSuccess');
					} else {
						setErrorTitle(t('auth.auth_error_failed_title'));
						setErrorMessage(t('auth.failed'));
						emit('onRegisterError', t('auth.failed'));
					}
				}
			} catch (error:any) {
				console.log(error);
				setErrorTitle(t('auth.auth_error_failed_title'));
				setErrorMessage(t('auth.failed'));
				if (error.message.email) {
					user.emailError = error.message.email.join("<br>");
				}
				if (error.message.password) {
					user.passwordError = error.message.password.join("<br>");
				}
				emit('onRegisterError', t('auth.failed'));
			}
		}

		return {
      tabs: [
        {name: i18n.global.t("auth.LoginComponent.loginTitle"), link: '/login', current: false},
        {name: i18n.global.t("auth.RegisterComponent.registerTitle"), link: '/register', current: true}
      ],
			t, user,
			onSubmit,
			errorMessage,
			errorTitle,
			clearErrorMessage,
		}

	},
})
</script>

<style scoped>
.btn-forgot-pw {
    align-self: flex-start;
    letter-spacing: 1.33px;
    line-height: 18px;
    margin-left: 106px;
    margin-top: 31px;
    min-height: 27px;
    width: 350px;
}

.btn-login {
    align-items: flex-end;
    background-color: var(--west-side);
    border-radius: 25px;
    display: flex;
    height: 49px;
    justify-content: flex-end;
    margin-top: 46px;
    min-width: 194px;
    padding: 6px 4px;
}

.btn-register {
    align-items: flex-start;
    display: flex;
    height: 49px;
    left: 69px;
    min-width: 196px;
    position: absolute;
    top: -91px;
}

.div {
    background-color: var(--white);
    border-radius: 25px;
    height: 49px;
    left: 0;
    position: absolute;
    top: 0;
    width: 194px;
}

.google-login {
    height: 56px;
    margin-top: 8px;
    object-fit: cover;
    width: 56px;
}

.login {
    -webkit-text-stroke: 1px var(--white);
    letter-spacing: 1.78px;
    line-height: 24px;
    min-height: 35px;
    min-width: 184px;
    text-align: center;
    width: 184px;
}

.logindesign1290 {
    align-items: flex-start;
    background-color: var(--black-haze);
    display: flex;
    height: 1080px;
    padding: 0 239px;
    width: 1290px;
}

.ornament {
    align-items: flex-start;
    display: flex;
    height: 188px;
    justify-content: center;
    left: -239px;
    min-width: 811px;
    position: absolute;
    top: -172px;
}

.ornament-front {
    height: 64px;
    object-fit: cover;
    width: 383px;
}

.overlap-group {
    align-items: center;
    background-color: var(--white);
    border-radius: 57px;
    box-shadow: -7px 3px 24px #00000012;
    display: flex;
    flex-direction: column;
    margin-top: 172px;
    min-height: 857px;
    position: relative;
    width: 812px;
}

.overlap-group1 {
    align-self: flex-end;
    height: 188px;
    margin-right: -238.5px;
    margin-top: 243px;
    position: relative;
    width: 811px;
}

.overlap-group2 {
    align-items: flex-end;
    background-position: 50% 50%;
    background-size: cover;
    display: flex;
    height: 189px;
    justify-content: flex-end;
    margin-top: -0.39px;
    min-width: 812px;
    padding: 0 30.8px;
}

.overlap-group3 {
    height: 49px;
    position: relative;
    width: 196px;
}

.place {
    left: 0;
    letter-spacing: 1.78px;
    line-height: 24px;
    position: absolute;
    text-align: center;
    top: 8px;
    width: 196px;
}

.register-text {
    left: -127px;
    letter-spacing: 1.78px;
    line-height: 24px;
    position: absolute;
    text-align: center;
    top: -159px;
    width: 588px;
}

.title-external-service {
    letter-spacing: 1.78px;
    line-height: 24px;
    margin-left: 2px;
    margin-top: 44px;
    min-height: 35px;
    min-width: 380px;
    text-align: center;
    white-space: nowrap;
}

.title-login {
    letter-spacing: 1.78px;
    line-height: 24px;
    margin-left: 2px;
    min-height: 35px;
    min-width: 256px;
    text-align: center;
    width: 256px;
}
</style>
