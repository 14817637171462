import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "rounded-3xl px-3 font-comfortaa py-0 bg-black dark:bg-gray-400 font-bold text-white dark:text-black mt-0 float-right text-tc-bg text-xs sm:text-base sm:mr-1.5 -mt-1 sm:-mt-2" }
const _hoisted_2 = { class: "mx-4 md:mx-7" }
const _hoisted_3 = { class: "py-32 xl:py-36 px-4 sm:px-6 lg:px-8 bg-tc-bg overflow-hidden" }
const _hoisted_4 = { class: "max-w-max lg:max-w-7xl mx-auto" }
const _hoisted_5 = { class: "relative mb-8 md:mb-2 md:px-6" }
const _hoisted_6 = { class: "text-base max-w-prose lg:max-w-none" }
const _hoisted_7 = { class: "mt-2 text-3xl leading-8 font-extrabold tracking-tight text-tc-blue sm:text-4xl" }
const _hoisted_8 = { class: "relative" }
const _hoisted_9 = { class: "relative md:bg-tc-bg md:p-6" }
const _hoisted_10 = { class: "lg:grid lg:gap-6" }
const _hoisted_11 = { class: "break-words w-full text-lg lg:text-xl py-0 sm:py-0 lg:py-0" }
const _hoisted_12 = { class: "mb-5" }
const _hoisted_13 = { class: "mb-4" }
const _hoisted_14 = { class: "mb-4" }
const _hoisted_15 = { class: "mb-5" }
const _hoisted_16 = { class: "md:flex space-x-4" }
const _hoisted_17 = { class: "mb-0" }
const _hoisted_18 = { class: "md:px-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderLogo = _resolveComponent("HeaderLogo")!
  const _component_PayPalDonation = _resolveComponent("PayPalDonation")!
  const _component_KoFiDonation = _resolveComponent("KoFiDonation")!
  const _component_SocialMedia = _resolveComponent("SocialMedia")!
  const _component_FooterBar = _resolveComponent("FooterBar")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode(_component_HeaderLogo, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('beta')), 1)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.t('donate.header')), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, [
                          _createElementVNode("p", null, [
                            _createElementVNode("strong", null, _toDisplayString(_ctx.t('donate.detailHeader')), 1)
                          ]),
                          _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
                          _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.t('donate.detail1')), 1),
                          _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.t('donate.detail2')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.t('donate.detail3')), 1)
                        ]),
                        _createElementVNode("div", _hoisted_15, [
                          _createElementVNode("div", _hoisted_16, [
                            _createVNode(_component_PayPalDonation),
                            _createVNode(_component_KoFiDonation)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_17, [
                          _createElementVNode("p", null, _toDisplayString(_ctx.t('donate.thanks')), 1)
                        ])
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _createVNode(_component_SocialMedia)
                ])
              ])
            ])
          ]),
          _createVNode(_component_FooterBar)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}