import {FlowFactory} from '@/greeve/flow/flow_factory';
import {FlowProcessFactory} from '@/greeve/flow/process/flow_process_factory';
import {
	FlowQueueJournalFactory
} from '@/greeve/flow/queue/journal/flow_queue_journal_factory.type';
import {
	FlowProcessDependencyFactory
} from '@/greeve/flow/process/dependency/flow_process_dependency_factory';
import {FlowMediaFactory} from '@/greeve/flow/media/flow_media_factory';
import {FlowQueueFactory} from '@/greeve/flow/queue/flow_queue_factory';
import {
	FlowQueueProcessFactory
} from '@/greeve/flow/queue/process/flow_queue_process_factory';
import {WizzardFactory} from '@/greeve/flow/wizzard/wizzard_factory';

export default function useFlowFactory() {

	let flowFactory: FlowFactory | null = null;
	const getFlowFactory = (): FlowFactory => {
		if (!flowFactory) {
			flowFactory = new FlowFactory();
		}
		return flowFactory;
	}

	let flowProcessFactory: FlowProcessFactory | null = null;

	const getFlowProcessFactory = (): FlowProcessFactory => {
		if (!flowProcessFactory) {
			flowProcessFactory = new FlowProcessFactory();
		}
		return flowProcessFactory;
	}

	let flowQueueJournalFactory: FlowQueueJournalFactory | null = null;

	const getFlowQueueJournalFactory = (): FlowQueueJournalFactory => {
		if (!flowQueueJournalFactory) {
			flowQueueJournalFactory = new FlowQueueJournalFactory();
		}
		return flowQueueJournalFactory;
	}

	let flowProcessDependencyFactory: FlowProcessDependencyFactory | null = null;

	const getFlowProcessDependencyFactory = (): FlowProcessDependencyFactory => {
		if (!flowProcessDependencyFactory) {
			flowProcessDependencyFactory = new FlowProcessDependencyFactory();
		}
		return flowProcessDependencyFactory;
	}

	let flowMediaFactory: FlowMediaFactory | null = null;

	const getFlowMediaFactory = (): FlowMediaFactory => {
		if (!flowMediaFactory) {
			flowMediaFactory = new FlowMediaFactory();
		}
		return flowMediaFactory;
	}

	let flowQueueFactory: FlowQueueFactory | null = null;

	const getFlowQueueFactory = (): FlowQueueFactory => {
		if (!flowQueueFactory) {
			flowQueueFactory = new FlowQueueFactory();
		}
		return flowQueueFactory;
	}

	let flowQueueProcessFactory: FlowQueueProcessFactory | null = null;

	const getFlowQueueProcessFactory = (): FlowQueueProcessFactory => {
		if (!flowQueueProcessFactory) {
			flowQueueProcessFactory = new FlowQueueProcessFactory();
		}
		return flowQueueProcessFactory;
	}

	let flowWizzardFactory: WizzardFactory | null = null;

	const getFlowWizzardFactory = (): WizzardFactory => {
		if (!flowWizzardFactory) {
			flowWizzardFactory = new WizzardFactory();
		}
		return flowWizzardFactory;
	}


	return {
		getFlowFactory,
		getFlowProcessFactory,
		getFlowQueueJournalFactory,
		getFlowProcessDependencyFactory,
		getFlowMediaFactory,
		getFlowQueueFactory,
		getFlowQueueProcessFactory,
		getFlowWizzardFactory,
	}
}