import useFlowFactory from '@/composable/greeve/useFlowFactory';
import {FlowQueueList} from '@/greeve/flow/queue/flow_queue_list.type';
import {AbstractFlowQueue} from '@/greeve/flow/queue/abstract_flow_queue.type';
import {FlowQueueType} from '@/greeve/flow/queue/flow_queue.interface';
import {
	FlowQueueDefault
} from '@/greeve/flow/queue/type/flow_queue_default.type';
import {
	FlowQueueProcessList
} from '@/greeve/flow/queue/process/flow_queue_process_list.type';
import {
	FlowQueueJournalList
} from '@/greeve/flow/queue/journal/flow_queue_journal_list.type';

export class FlowQueueFactory {
	/**
	 *
	 * @param flow_queues
	 */
	createFlowQueueListByResponse(flow_queues: Record<string, any>): FlowQueueList {
		const flowQueueList = new FlowQueueList();

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		for (const [key, item] of Object.entries(flow_queues)) {
			if (!item) {
				continue;
			}
			flowQueueList.push(this.createFlowQueueByResponse(item));
		}

		return flowQueueList;
	}

	/**
	 *
	 * @param item
	 */
	createFlowQueueByResponse(item: Record<string, any>): AbstractFlowQueue {
		const id = item.id;
		const uuid = item.uuid;
		const flowId = item.flow_id ? item.flow_id : (item.flowId ? item.flowId : undefined);
		const user_id = item.user_id ? item.user_id : (item.userId ? item.userId : undefined);
		const type = item.type;
		const state = item.state;
		const next_execution_date = item.nextExecution ? new Date(item.nextExecution) : (item.next_execution_date ? new Date(item.next_execution_date) : undefined);
		const flow_quota_amount = item.flow_quota_amount ? item.flow_quota_amount : (item.flowQuotaAmount ? item.flowQuotaAmount : 0) ?? 0;
		const parent_id = item.parent_id ? item.parent_id : (item.parentId ? item.parentId : undefined) ?? undefined;
		const reference = item.reference ?? undefined;
		const name = item.name ?? undefined;
		const description = item.description ?? undefined;
		let flowQueueProcessList: FlowQueueProcessList | undefined;
		if (item.flow_queue_processes && item.flow_queue_processes.length > 0) {
			flowQueueProcessList = useFlowFactory().
			getFlowQueueProcessFactory().
			createFlowQueueProcessListByResponse(item.flow_queue_processes);
		} else if (item.flowQueueProcesses && item.flowQueueProcesses.length > 0) {
			flowQueueProcessList = useFlowFactory().
			getFlowQueueProcessFactory().
			createFlowQueueProcessListByResponse(item.flowQueueProcesses);
		}
		let flowQueueJournalList: FlowQueueJournalList | undefined;
		if (item.flow_queue_journals && item.flow_queue_journals.length > 0) {
			flowQueueJournalList = useFlowFactory().
			getFlowQueueJournalFactory().
			createFlowQueueJournalListByResponse(item.flow_queue_journals);
		} else if (item.flowQueueJournals && item.flowQueueJournals.length > 0) {
			flowQueueJournalList = useFlowFactory().
			getFlowQueueJournalFactory().
			createFlowQueueJournalListByResponse(item.flowQueueJournals);
		}
		const result = item.result;
		const data = item.data;
		const created_at = item.created_at ? new Date(item.created_at) : (item.createdAt ? new Date(item.createdAt) : new Date());
		const updated_at = item.updated_at ? new Date(item.updated_at) : (item.updatedAt ? new Date(item.updatedAt) : new Date());

		let flowQueue;

		switch (type) {
			case FlowQueueType.DEFAULT:
				flowQueue = new FlowQueueDefault(id, uuid, flowId, type, state, user_id,
						next_execution_date, flowQueueProcessList, flowQueueJournalList, flow_quota_amount,
							parent_id, reference, name, description, result, data,
							created_at, updated_at);
					break;
			default:
				throw new Error('Invalid Flow Queue: ' + JSON.stringify(item));
		}

		return flowQueue;
	}

	createEmptyFlowQueueList(): FlowQueueList {
		return new FlowQueueList([]);
	}
}