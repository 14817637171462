import {PaginationMetaInterface} from "@/greeve/core/pagination_meta.interface";
import {Country} from "@/greeve/core/country/country.type";

export class CountryList extends Array<Country> {

	public paginationMetaData?: PaginationMetaInterface;

	constructor(items: Array<Country> = []) {
		super();
		this.push(...items);
	}

	add(items: Country) {
		this.push(items);
	}

	remove(index: number) {
		this.splice(index, 1);
	}

	removeAll() {
		const itemCount = this.length;
		this.splice(itemCount, 1);
	}

	getItem(index: number): Country {
		return this[index];
	}

	getItemByCode(countryCode: string): Country | undefined {
		return this.find(({code}) => code === countryCode);
	}

	enhanceMetaData(metaData: any) {
		this.paginationMetaData = metaData;
	}

	getCountryByLocale(locale: string): Country|undefined {
		let countryCode = 'de';
		switch (locale) {
			case 'de':
				countryCode = 'DE';
				break;
			case 'en':
				countryCode = 'US';
				break;
		}
		return this.getItemByCode(countryCode);
	}
}