import {createI18n, LocaleMessages, VueMessageType} from 'vue-i18n';
import useCustomStore from "./composable/custom/useCustomStore";
const {getLanguageLocale} = useCustomStore();

// if (!getLanguageLocale.value || getLanguageLocale.value.length === 0) {
//   setLanguageLocale(getDefaultBrowserLanguageCode());
// }

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages(): LocaleMessages<VueMessageType> {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages: LocaleMessages<VueMessageType> = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key).default
    }
  })
  return messages
}

function getLocale() {
  try {
    return getLanguageLocale.value;
  } catch (error) {
    return process.env.VUE_APP_I18N_LOCALE;
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default createI18n({
  legacy: false,
  locale: getLocale() || process.env.VUE_APP_I18N_LOCALE,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  availableLocales: {},
  messages: loadLocaleMessages(),
  useScope: 'global',
  globalInjection: true,
})
