import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!

  return (_ctx.isAvailable)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleSpeaking && _ctx.toggleSpeaking(...args))),
          type: "button",
          class: "rounded-md px-2 py-1.5 text-xl font-medium dark:text-gray-400 focus:outline-none"
        }, [
          _createVNode(_component_ion_icon, {
            class: _normalizeClass(['text-2xl', _ctx.isRecording ? 'text-red-600 animate-ping-slow' : '']),
            icon: _ctx.icons.micOutline
          }, null, 8, ["class", "icon"])
        ])
      ]))
    : _createCommentVNode("", true)
}