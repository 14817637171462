import {PaginationMetaInterface} from "@/greeve/core/pagination_meta.interface";
import {AbstractFlow} from '@/greeve/flow/abstract_flow.type';
import {FlowQueueList} from '@/greeve/flow/queue/flow_queue_list.type';

export class FlowList extends Array<AbstractFlow> {

	public paginationMetaData?: PaginationMetaInterface;

	constructor(items: Array<AbstractFlow> = []) {
		super();
		this.push(...items);
	}

	add(items: AbstractFlow) {
		this.push(items);
	}

	remove(index: number) {
		this.splice(index, 1);
	}

	removeAll() {
		const itemCount = this.length;
		this.splice(itemCount, 1);
	}

	getItem(index: number): AbstractFlow {
		return this[index];
	}

	getItemById(item_id: number): AbstractFlow | undefined {
		return this.find(({id}) => id === item_id);
	}

	getItemByUuId(item_uuid: string): AbstractFlow | undefined {
		return this.find(({uuid}) => uuid === item_uuid);
	}

	getUniqueList(): FlowList
	{
		const currentFlowList: FlowList|any[] = [...this];
		const flowList: FlowList = new FlowList();
		const seen = new Set();

		for (const flow of currentFlowList) {
			if (!seen.has(flow.id)) {
				seen.add(flow.id);
				flowList.push(flow);
			}
		}
		return flowList;
	}

	getFlowQueueList(sortDesc = true): FlowQueueList|undefined
	{
		const currentFlowList: FlowList|Array<AbstractFlow> = [...this];
		const flowQueueList = new FlowQueueList();

		if (!currentFlowList) {
			return;
		}

		for (const flow of currentFlowList) {
			if (flow.flow_queues) {
				flowQueueList.push(...flow.flow_queues);
			}
		}

		if (sortDesc) {
			flowQueueList.sort((a, b) => b.created_at && a.created_at ? b.created_at.getTime() - a.created_at.getTime() : 0);
		}

		return flowQueueList;
	}

	getFlowByName(searchTerm: string): AbstractFlow | undefined
	{
		const currentFlowList: FlowList | Array<AbstractFlow> = [...this];
		const regex = new RegExp(searchTerm, 'i');
		return currentFlowList.find(flow => regex.test(flow.getUrlName()));
	}

	filterListByName(searchTerm: string): AbstractFlow[]
	{
		const currentFlowList: FlowList|Array<AbstractFlow> = [...this];
		const regex = new RegExp(searchTerm, 'i');
		return currentFlowList.filter(flow => regex.test(flow.getTranslatedName()));
	}

	enhanceMetaData(metaData: any) {
		this.paginationMetaData = metaData;
	}
}