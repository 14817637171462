import {PaginationMetaInterface} from "@/greeve/core/pagination_meta.interface";
import {AbstractFlowQueue} from '@/greeve/flow/queue/abstract_flow_queue.type';
import {
	AbstractFlowQueueProcess
} from '@/greeve/flow/queue/process/abstract_flow_queue_process.type';

export class FlowQueueList extends Array<AbstractFlowQueue> {

	public paginationMetaData?: PaginationMetaInterface;

	constructor(items: Array<AbstractFlowQueue> = []) {
		super();
		this.push(...items);
	}

	add(items: AbstractFlowQueue) {
		this.push(items);
	}

	remove(index: number) {
		this.splice(index, 1);
	}

	removeAll() {
		const itemCount = this.length;
		this.splice(itemCount, 1);
	}

	sliceItems(start: number, end?: number) {
		const list: FlowQueueList|any[] = [...this];
		list.splice(start, end);
		return list;
	}

	getItem(index: number): AbstractFlowQueue {
		return this[index];
	}

	getItemById(item_id: number): AbstractFlowQueue | undefined {
		return this.find(({id}) => id === item_id);
	}

	getItemByUuId(item_uuid: string): AbstractFlowQueue | undefined {
		return this.find(({uuid}) => uuid === item_uuid);
	}

	getNewestItem(): AbstractFlowQueue | undefined {

		if (!this) {
			return;
		}

		let newestItem = this[0];

		for(let i = 1; i < this.length; i++){
			const itemDate = this[i].created_at?.getTime() ?? undefined;
			if (itemDate && newestItem.created_at) {
				if(itemDate > newestItem.created_at.getTime()){
					newestItem = this[i];
				}
			}
		}
		return newestItem;
	}


	getNewestProcess_by_Id(flow_process_id: number): AbstractFlowQueueProcess | undefined {
		const newestQueue = this.getNewestItem();
		if (!newestQueue) {
			return;
		}

		let newestProcess;
		if (newestQueue.flow_queue_processes) {
			newestProcess = newestQueue.flow_queue_processes.getItemByFlowProcessId(flow_process_id);
		}

		return newestProcess;
	}

	enhanceMetaData(metaData: any) {
		this.paginationMetaData = metaData;
	}
}