import {
	QuotaInterface,
	QuotaState,
	QuotaType,
} from '@/greeve/user/quota/quota.interface';
import {
	QuotaTransactionList
} from '@/greeve/user/quota/transaction/quota_transaction_list.type';
import {
	QuotaJournalList
} from '@/greeve/user/quota/journal/quota_journal_list.type';
import i18n from '@/i18n';


export abstract class AbstractQuota implements QuotaInterface {
	id: number;
	user_id: number;
	uuid: string;
	type: QuotaType;
	state: QuotaState;
	total_quota: number;
	available_quota: number;
	available_quota_in_percent: number;
	quota_transactions?: QuotaTransactionList;
	quota_journals?: QuotaJournalList;
	data?: any;
	created_at?: Date;
	updated_at?: Date;

	constructor(
			id: number, user_id: number, uuid: string, type: QuotaType,
			state: QuotaState, total_quota: number, available_quota: number, available_quota_in_percent: number,
			quota_transactions?: QuotaTransactionList, quota_journals?: QuotaJournalList, data: any = null, created_at?: Date, updated_at?: Date) {
		this.id = id;
		this.user_id = user_id;
		this.uuid = uuid;
		this.type = type;
		this.state = state;
		this.total_quota = total_quota;
		this.available_quota = available_quota;
		this.available_quota_in_percent = available_quota_in_percent;
		this.quota_transactions = quota_transactions;
		this.quota_journals = quota_journals;
		this.data = data;
		this.created_at = created_at;
		this.updated_at = updated_at;
	}

	abstract getQuotaType(): string;

	getQuotaName(): string {
		return i18n.global.t('shop.quota.'+this.getQuotaType()+'.name');
	}
}