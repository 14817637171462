export interface GreeveApiGetResponse {
	error: string|null,
	status: number
}

export interface GreeveApiJsonResponse {
	status: string,
	message: string
}


export class ResponseError extends Error {
	errorCode: any;
	errorMessage: any;
	constructor(errorCode: any, message: string | undefined) {
		super(message);
		this.name = this.constructor.name;
		if (message != null) {
			this.message = message;
		}
		this.errorCode = errorCode;
	}
}