import {PaginationMetaInterface} from '@/greeve/core/pagination_meta.interface';
import {
	AbstractSearchItem,
} from '@/greeve/search/item/abstract_search_item.type';
import {
	GreeveSearchItemStateInterface,
} from '@/greeve/search/item/search_item.interface';

export class SearchItemList extends Array<AbstractSearchItem> {

	public paginationMetaData?: PaginationMetaInterface;

	constructor(items: Array<AbstractSearchItem> = []) {
		super();
		this.push(...items);
	}

	add(items: AbstractSearchItem) {
		this.push(items);
	}

	remove(index: number) {
		if (index >= 0 && index < this.length) {
			console.log(this, this.values())
			this.splice(index, 1);
		} else {
			console.error("Invalid index provided:", index);
		}
	}

	removeAll() {
		const itemCount = this.length;
		this.splice(itemCount, 1);
	}

	getItem(index: number): AbstractSearchItem {
		return this[index];
	}

	getItemByType(searchResultType: string): AbstractSearchItem | undefined {
		return this.find((item) => item.getType() === searchResultType);
	}

	getItemBySubType(searchResultSubType: string): AbstractSearchItem | undefined {
		return this.find(({subType}) => subType === searchResultSubType);
	}

	sortItemsById() {
		this.sort((a:AbstractSearchItem|any, b: AbstractSearchItem|any) => {
			return a.id - b.id;
		});
	}

	getFirstId(): number|undefined {
		let result;
		try {
			const firstItem = this.at(0);

			if (firstItem) {
				result = firstItem.id;
			}
		} catch (e) {
			console.error(e);
		}

		return result;
	}

	getLastId(): number|undefined {
		let result;
		try {
			const lastItem = this.at(-1);

			if (lastItem) {
				result = lastItem.id;
			}
		} catch (e) {
			console.error(e);
		}

		return result;
	}

	getGroupReference(): string|undefined {
		const searchResultList: SearchItemList|any[] = [...this];
		const result = searchResultList.find((searchResult: AbstractSearchItem) => {
			return searchResult.group_reference && searchResult.group_reference.length > 0
		});
		if (result) {
			return result.group_reference;
		}
		return undefined;
	}

	getLastResult(): AbstractSearchItem|undefined {
		let result;
		try {
			const lastItem = this.at(-1);

			if (lastItem) {
				result = lastItem
			}
		} catch (e) {
			console.error(e);
		}

		return result;
	}

	filterPinnedList(isPinned = true, clone = true): AbstractSearchItem[]|SearchItemList {
		if (clone) {
			let searchItemList: SearchItemList|AbstractSearchItem[] = [...this];
			searchItemList = searchItemList.filter((searchItem: AbstractSearchItem) => searchItem.is_pinned === isPinned);
			return searchItemList;
		} else {
			return this.filter((searchItem: AbstractSearchItem) => searchItem.is_pinned === isPinned);
		}
	}

	filterStateInProgressList(clone = true): AbstractSearchItem[]|SearchItemList {
		if (clone) {
			let searchItemList: SearchItemList|AbstractSearchItem[] = [...this];
			searchItemList = searchItemList.filter((searchItem: AbstractSearchItem) => searchItem.state === GreeveSearchItemStateInterface.SEARCH_ITEM_STATE_IN_PROGRESS);
			return searchItemList;
		} else {
			return this.filter((searchItem: AbstractSearchItem) => searchItem.state === GreeveSearchItemStateInterface.SEARCH_ITEM_STATE_IN_PROGRESS);
		}
	}

	getInProgressUuids(): string[] {
		return this.filterStateInProgressList(true).map((searchItem: AbstractSearchItem) => searchItem.uuid);
	}

	enhanceMetaData(metaData: any) {
		this.paginationMetaData = metaData;
	}
}