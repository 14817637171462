<template>
  <ion-page>
    <ion-content :fullscreen="true">
		<HeaderLogo>
			<div  class="rounded-3xl px-3 font-comfortaa py-0 bg-black dark:bg-gray-400 font-bold text-white dark:text-black mt-0 float-right text-tc-bg text-xs sm:text-base sm:mr-1.5 -mt-1 sm:-mt-2 ">{{ $t('beta') }}
			</div>
		</HeaderLogo>
		<div class="">

        <div class="mx-4 md:mx-7">


          <div class="py-32 xl:py-36 px-4 sm:px-6 lg:px-8 bg-tc-bg overflow-hidden">
            <div class="max-w-max lg:max-w-7xl mx-auto">
              <div class="relative mb-8 md:mb-2 md:px-6">
                <div class="text-base max-w-prose lg:max-w-none">
                  <p class="mt-2 text-3xl leading-8 font-extrabold tracking-tight sm:text-4xl">
                    {{ t('imprint.ImprintTitle') }}</p>
                </div>
              </div>
              <div class="relative">
                <div class=" relative md:bg-tc-bg md:p-6">
                  <div class="lg:grid lg:gap-6">

                    <div class="break-words w-full text-lg lg:text-xl py-0 sm:py-0 lg:py-0 ">
                      <div class="mb-5">
                        <p><strong>{{ t('imprint.ImprintNotice') }}</strong></p>
                        <p>{{ t('imprint.ImprintDetail') }}</p><br>
                        <p>{{ t('imprint.CompanyName') }}</p>
                        <p>{{ t('imprint.CompanyStreet') }}</p>
                        <p>{{ t('imprint.CompanyTown') }}</p>
                        <p>{{ t('imprint.CompanyCountry') }}</p>
                      </div>
                      <div class="mb-5">
                        <p>{{ t('imprint.CompanyInfo') }}</p>
                        <p><strong>{{ t('imprint.CompanyWebsiteTitle') }}</strong> <a class="no-underline"
                                                                                      href="https://theconcept-technologies.com">{{ t('imprint.CompanyWebsite') }}</a>
                        </p>
                      </div>
                      <div class="mb-5">
                        <p><strong>{{ t('imprint.ObjectTitle') }}</strong>
                          {{ t('imprint.ObjectDetail') }}</p>
                        <p><strong>{{ t('imprint.VatTitle') }}</strong>
                          {{ t('imprint.VatDetail') }}</p>
                        <!--                    <p><strong>{{ t('imprint.GlnTitle') }}</strong>-->
                        <!--                      {{ t('imprint.GlnDetail') }}</p>-->
                        <!--                    <p><strong>{{ t('imprint.GisaTitle') }}</strong>-->
                        <!--                      {{ t('imprint.GisaDetail') }}</p>-->
                        <!--                    <p><strong>{{ t('imprint.CoReNuTitle') }}</strong>-->
                        <!--                      {{ t('imprint.CoReNuDetail') }}</p>-->
                        <p><strong>{{ t('imprint.CoReCoTitle') }}</strong>
                          {{ t('imprint.CoReCoDetail') }}</p>
                        <p><strong>{{ t('imprint.CoLoTitle') }}</strong>
                          {{ t('imprint.CompanyStreet') }}</p>
                      </div>
                      <div class="mb-5">
                        <p><strong>{{ t('imprint.MailTitle') }}</strong> <a class="no-underline"
                                                                            href="mailto:office@theconcept.app">
                          {{ t('imprint.MailDetail') }}</a>
                        </p>
                      </div>
                      <div class="mb-5">
                        <!--                    <p><strong>{{ t('imprint.MemberTitle') }}</strong>-->
                        <!--                      {{ t('imprint.MemberDetail') }}</p>-->
                        <p><strong>{{ t('imprint.LawsTitle') }}</strong>
                          {{ t('imprint.LawsDetail') }}</p>
                        <p><strong>{{ t('imprint.SupervisoryTitle') }}</strong>
                          {{ t('imprint.SupervisoryDetail') }}</p>
                        <p><strong>{{ t('imprint.AwardTitle') }}</strong>
                          {{ t('imprint.AwardDetail') }}</p>
                      </div>
                      <div class="mb-5">
                        <p><strong>{{ t('imprint.DataProtectionTitle') }}</strong></p>
                        <p>{{ t('imprint.DataProtectionDetail') }}</p>
                        <p>{{ t('imprint.DataProtectionDetail2') }}</p>
                      </div>
                      <div class="mb-5">
                        <p><strong>{{ t('imprint.EuTitle') }}</strong></p>
                        <p>{{ t('imprint.EuDetail') }}</p>
                      </div>
                      <div class="mb-5">
                        <p><strong>{{ t('imprint.LiabilityContentTitle') }}</strong></p>
                        <p>{{ t('imprint.LiabilityContentDetail') }}</p>
                      </div>
                      <div class="mb-5">
                        <p><strong>{{ t('imprint.LiabilityLinkTitle') }}</strong></p>
                        <p>{{ t('imprint.LiabilityLinkDetail') }}</p>
                      </div>
                      <div class="mb-0">
                        <p><strong>{{ t('imprint.CopyrightTitle') }}</strong></p>
                        <p>{{ t('imprint.CopyrightDetail') }}</p>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>


      </div>

      <FooterBar></FooterBar>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {IonPage, IonContent} from '@ionic/vue';
import FooterBar from "@/components/positions/FooterBar.vue";
import useTranslation from "@/composable/translation/useTranslation";
import HeaderLogo from "@/components/branding/HeaderLogo.vue";


export default defineComponent({
  name: 'ImprintPage',
  components: {
    HeaderLogo,
    FooterBar, IonContent, IonPage},
  setup() {
    const {t} = useTranslation();
    return {t,}
  }
});

</script>
<style lang="scss">
</style>
