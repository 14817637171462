import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "relative isolate overflow-hidden pt-14" }
const _hoisted_2 = {
  id: "GreeveSignUp",
  class: "mt-24 mb-56"
}
const _hoisted_3 = { class: "box max-w-7xl pt-20 pb-28 text-center mx-auto" }
const _hoisted_4 = { class: "mx-auto max-w-2xl py-20 sm:py-48 lg:py-56" }
const _hoisted_5 = { class: "text-center" }
const _hoisted_6 = { class: "text-4xl font-bold tracking-tight text-gr-darkest dark:text-white sm:text-6xl" }
const _hoisted_7 = { class: "mt-6 text-lg leading-8 text-gr-dark dark:text-gray-300" }
const _hoisted_8 = { class: "p-4 mt-10 flex items-center justify-center gap-x-6" }
const _hoisted_9 = { class: "box bg-white bg-opacity-55 py-32" }
const _hoisted_10 = { class: "" }
const _hoisted_11 = { class: "mx-auto max-w-7xl px-6 lg:px-8" }
const _hoisted_12 = { class: "mx-auto max-w-2xl lg:mx-0" }
const _hoisted_13 = { class: "text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl" }
const _hoisted_14 = { class: "mt-6 text-base leading-7 text-gray-600" }
const _hoisted_15 = { class: "mx-auto mt-16 flex max-w-2xl flex-col gap-8 lg:mx-0 lg:mt-20 lg:max-w-none lg:flex-row lg:items-end" }
const _hoisted_16 = { class: "flex flex-col-reverse justify-between gap-x-16 gap-y-4 rounded-2xl bg-gray-50 p-8 sm:w-3/4 sm:max-w-md sm:flex-row-reverse sm:items-end lg:w-72 lg:max-w-none lg:flex-none lg:flex-col lg:items-start" }
const _hoisted_17 = { class: "flex-none text-3xl font-bold tracking-tight text-gray-900" }
const _hoisted_18 = { class: "sm:w-80 sm:shrink lg:w-auto lg:flex-none" }
const _hoisted_19 = { class: "text-lg font-semibold tracking-tight text-gray-900" }
const _hoisted_20 = { class: "mt-2 text-base leading-7 text-gray-600" }
const _hoisted_21 = { class: "flex flex-col-reverse justify-between gap-x-16 gap-y-4 rounded-2xl bg-gray-900 p-8 sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-sm lg:flex-auto lg:flex-col lg:items-start lg:gap-y-16" }
const _hoisted_22 = { class: "flex-none text-3xl font-bold tracking-tight text-white" }
const _hoisted_23 = { class: "sm:w-80 sm:shrink lg:w-auto lg:flex-none" }
const _hoisted_24 = { class: "text-lg font-semibold tracking-tight text-white" }
const _hoisted_25 = { class: "mt-2 text-base leading-7 text-gray-400" }
const _hoisted_26 = { class: "mt-2 text-base leading-7 text-gray-300 list-disc" }
const _hoisted_27 = { class: "flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-indigo-600 p-8 sm:w-11/12 sm:max-w-xl sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-none lg:flex-auto lg:flex-col lg:items-start lg:gap-y-28" }
const _hoisted_28 = { class: "flex-none text-3xl font-bold tracking-tight text-white" }
const _hoisted_29 = { class: "sm:w-80 sm:shrink lg:w-auto lg:flex-none" }
const _hoisted_30 = { class: "text-lg font-semibold tracking-tight text-white" }
const _hoisted_31 = { class: "mt-2 text-base leading-7 text-indigo-200" }
const _hoisted_32 = { class: "relative isolate overflow-hidden bg-gray-900 bg-opacity-55 box" }
const _hoisted_33 = { class: "px-6 py-24 sm:px-6 sm:py-32 lg:px-8" }
const _hoisted_34 = { class: "mx-auto max-w-2xl text-center" }
const _hoisted_35 = { class: "text-3xl font-bold tracking-tight text-white sm:text-4xl" }
const _hoisted_36 = { class: "mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-100" }
const _hoisted_37 = { class: "mt-10 flex items-center justify-center gap-x-6" }
const _hoisted_38 = {
  class: "box",
  id: "contact"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inquiry_component = _resolveComponent("inquiry-component")!
  const _component_FooterBar = _resolveComponent("FooterBar")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("section", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("h1", _hoisted_6, _toDisplayString(_ctx.$t('enterprise.hero.title')), 1),
                    _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('enterprise.hero.subtitle')), 1),
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("button", {
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.scrollToElement('contact'))),
                        class: "bg-black dark:bg-white font-bold text-lg px-7 py-2 rounded-3xl mr-2 text-white dark:text-black"
                      }, _toDisplayString(_ctx.$t('enterprise.hero.button')), 1)
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("h2", _hoisted_13, _toDisplayString(_ctx.$t('enterprise.stats.title')), 1),
                      _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.$t('enterprise.stats.description')), 1)
                    ]),
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("div", _hoisted_16, [
                        _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.$t('enterprise.stats.llm.stat')), 1),
                        _createElementVNode("div", _hoisted_18, [
                          _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.$t('enterprise.stats.llm.title')), 1),
                          _createElementVNode("p", _hoisted_20, _toDisplayString(_ctx.$t('enterprise.stats.llm.description')), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_21, [
                        _createElementVNode("p", _hoisted_22, _toDisplayString(_ctx.$t('enterprise.stats.models.stat')), 1),
                        _createElementVNode("div", _hoisted_23, [
                          _createElementVNode("p", _hoisted_24, _toDisplayString(_ctx.$t('enterprise.stats.models.title')), 1),
                          _createElementVNode("p", _hoisted_25, _toDisplayString(_ctx.$t('enterprise.stats.models.description')), 1),
                          _createElementVNode("ul", _hoisted_26, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedModes, (mode, index) => {
                              return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(mode), 1))
                            }), 128)),
                            (_ctx.shouldShowMore)
                              ? (_openBlock(), _createElementBlock("p", {
                                  key: 0,
                                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleCollapse && _ctx.toggleCollapse(...args))),
                                  class: "cursor-pointer text-blue-500"
                                }, _toDisplayString(_ctx.collapsed ? _ctx.$t('enterprise.stats.models.show_more') : _ctx.$t('enterprise.stats.models.show_less')), 1))
                              : _createCommentVNode("", true)
                          ])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_27, [
                        _createElementVNode("p", _hoisted_28, _toDisplayString(_ctx.$t('enterprise.stats.support.stat')), 1),
                        _createElementVNode("div", _hoisted_29, [
                          _createElementVNode("p", _hoisted_30, _toDisplayString(_ctx.$t('enterprise.stats.support.title')), 1),
                          _createElementVNode("p", _hoisted_31, _toDisplayString(_ctx.$t('enterprise.stats.support.description')), 1)
                        ])
                      ])
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_32, [
                _createElementVNode("div", _hoisted_33, [
                  _createElementVNode("div", _hoisted_34, [
                    _createElementVNode("h2", _hoisted_35, [
                      _createTextVNode(_toDisplayString(_ctx.$t('enterprise.hero.eyecatcher.title')), 1),
                      _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1))
                    ]),
                    _createElementVNode("p", _hoisted_36, _toDisplayString(_ctx.$t('enterprise.hero.eyecatcher.description')), 1),
                    _createElementVNode("div", _hoisted_37, [
                      _createElementVNode("a", {
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.scrollToElement('contact'))),
                        class: "cursor-pointer rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                      }, _toDisplayString(_ctx.$t('enterprise.hero.eyecatcher.button')), 1)
                    ])
                  ])
                ]),
                _cache[4] || (_cache[4] = _createElementVNode("svg", {
                  viewBox: "0 0 1024 1024",
                  class: "absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]",
                  "aria-hidden": "true"
                }, [
                  _createElementVNode("circle", {
                    cx: "512",
                    cy: "512",
                    r: "512",
                    fill: "url(#8d958450-c69f-4251-94bc-4e091a323369)",
                    "fill-opacity": "0.7"
                  }),
                  _createElementVNode("defs", null, [
                    _createElementVNode("radialGradient", { id: "8d958450-c69f-4251-94bc-4e091a323369" }, [
                      _createElementVNode("stop", { "stop-color": "#7775D6" }),
                      _createElementVNode("stop", {
                        offset: "1",
                        "stop-color": "#E935C1"
                      })
                    ])
                  ])
                ], -1))
              ]),
              _createElementVNode("div", _hoisted_38, [
                _createVNode(_component_inquiry_component, {
                  "contact-type": "enterprise",
                  "contact-header": _ctx.$t('inquiry.HeaderTitle'),
                  "contact-css": "p-4 lg:pt-20 bg-white bg-opacity-85 lg:px-20",
                  "contact-header-info": _ctx.$t('inquiry.HeaderInfo'),
                  "contact-submit-button": _ctx.$t('inquiry.FormBtn')
                }, null, 8, ["contact-header", "contact-header-info", "contact-submit-button"])
              ])
            ]),
            _createVNode(_component_FooterBar, { class: "mt-12" })
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}