import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {onMounted, ref} from 'vue';
import {IonPage, IonContent} from '@ionic/vue';
import FooterBar from '@/components/positions/FooterBar.vue';
import useSystem from '@/composable/core/useSystem';
import {useHeaderHeight} from '@/composable/global/useHeaderHeight';
import router from '@/router';
import useCustomStore from '@/composable/custom/useCustomStore';
import {useRoute} from 'vue-router';
import useAuth from '@/composable/auth/useAuth';
import AuthComponent from '@/components/auth/AuthComponent.vue';

const LOGIN_SEGMENT = 'login';
const REGISTER_SEGMENT = 'register';


export default /*@__PURE__*/_defineComponent({
  __name: 'AuthenticationPage',
  setup(__props) {

const {headerHeight} = useHeaderHeight();
const {isAuthenticated} = useAuth();
const {addLeadingSlash, getQueryByKey} = useSystem();
const {setIsGlobalLoading} = useCustomStore();
const defaultSegment = ref(LOGIN_SEGMENT);
const route = useRoute();

onMounted(() => {
	if (isAuthenticated.value) {
		const redirectQuery = getQueryByKey('redirect');
		if (redirectQuery) {
			setTimeout(() => {
				router.push(addLeadingSlash(redirectQuery));
				setIsGlobalLoading(false);
			}, 100);
		}
	} else if (route.path === '/register') {
		defaultSegment.value = REGISTER_SEGMENT;
	}
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonContent), null, {
        default: _withCtx(() => [
          _createElementVNode("main", {
            style: _normalizeStyle({ paddingTop: _unref(headerHeight) + 'px' })
          }, [
            _createVNode(AuthComponent)
          ], 4),
          _createVNode(FooterBar, { class: "mt-12" })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})