import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("button", {
      type: "button",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.shareCard && _ctx.shareCard(...args))),
      class: "rounded-md px-2 py-1.5 text-sm font-medium dark:text-gray-400 focus:outline-none"
    }, _cache[1] || (_cache[1] = [
      _createElementVNode("svg", {
        xmlns: "http://www.w3.org/2000/svg",
        fill: "none",
        viewBox: "0 0 24 24",
        "stroke-width": "1.5",
        stroke: "currentColor",
        class: "w-6 h-6 mx-auto"
      }, [
        _createElementVNode("path", {
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
          d: "M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15m0-3l-3-3m0 0l-3 3m3-3V15"
        })
      ], -1)
    ]))
  ]))
}