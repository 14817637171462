import {PaginationMetaInterface} from "@/greeve/core/pagination_meta.interface";
import {PaymentItem} from "@/greeve/shop/order/paymentItem/payment_item.type";

export class PaymentItemList extends Array<PaymentItem> {

	public paginationMetaData?: PaginationMetaInterface;

	constructor(items: Array<PaymentItem> = []) {
		super();
		this.push(...items);
	}

	add(items: PaymentItem) {
		this.push(items);
	}

	remove(index: number) {
		this.splice(index, 1);
	}

	removeAll() {
		const itemCount = this.length;
		this.splice(itemCount, 1);
	}

	getItem(index: number): PaymentItem {
		return this[index];
	}

	getItemById(item_id: number): PaymentItem | undefined {
		return this.find(({id}) => id === item_id);
	}

	enhanceMetaData(metaData: any) {
		this.paginationMetaData = metaData;
	}
}