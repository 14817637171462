<template>
	<TransitionRoot as="template" :show="isOpen">
		<TransitionChild as="template" enter="ease-out duration-200"
						 enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						 enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
						 leave-from="opacity-100 translate-y-0 sm:scale-100"
						 leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
			<div :id="id" class="relative shadow-xl z-10 -mt-4 -mx-2 md:mx-0 rounded-2xl backdrop-filter backdrop-blur-xl p-0 bg-gradient-to-r from-gr-yellow via-gr-primary to-gr-primaryDark">
				<div class="align-middle rounded-2xl p-4 sm:p-4 pl-3 sm:pl-5 text-md text-black">
					<div class="float-right ml-2 mt-0.5 cursor-pointer">
						<XMarkIcon class="w-5" @click="closeModal()"></XMarkIcon>
					</div>
					<div class="flex ">
						<p class=" md:pl-2 lg:pl-4">
							<span class="select-none">{{ $t('greeveioIsCurrentlyBetaButWeAreSoProudOfItWeAlread') }}</span>
							<span class="font-bold ml-2 cursor-pointer" @click="handleAction">{{
																$t('saveOnYourSubscription')
								}} <span
										class="mx-1 bg-white dark:bg-black rounded-lg text-black dark:text-white px-4 tracking-widest">{{
																	promoCode
									}}</span></span>
						</p></div>
				</div>
			</div>
		</TransitionChild>
	</TransitionRoot>
</template>

<script lang="ts">
import {defineComponent, onMounted, onUnmounted, ref} from 'vue';
import {XMarkIcon} from '@heroicons/vue/24/outline';
import {
	TransitionRoot,
	TransitionChild,
} from '@headlessui/vue';
import useCustomStore from '@/composable/custom/useCustomStore';
import {nanoid} from 'nanoid';
import useShop from '@/composable/greeve/useShop';
import router from '@/router';

export default defineComponent({
	name: 'HeaderInfo',
	components: {
		XMarkIcon,
		TransitionRoot,
		TransitionChild,
	},
	props: {
		id: {
			type: String,
			default: nanoid(),
		},
		promoCode: {
			type: String,
			default: 'GREEVEBETA20',
		},
		visible: {
			type: Boolean,
			default: true,
		},
		alwaysVisible: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const {getInfoBanner_by_Id, addInfoBanner, updateInfoBannerState} = useCustomStore();
		const {addDiscountToCart} = useShop();
		const isOpen = ref(false);
		const opacity = ref(1);

		function handleAction() {
			addDiscountToCart(props.promoCode);
			router.push('shop');
			isOpen.value = false;
			//TODO HIDE BANNER
		}

		function closeModal() {
			updateInfoBannerState(props.id, false);
			isOpen.value = false;
		}

		function openModal() {
			updateInfoBannerState(props.id, true);
			isOpen.value = true;
		}

		onMounted(() => {
			addInfoBanner(props.id, props.visible);
			const infoBanner = getInfoBanner_by_Id(props.id);

			if (props.alwaysVisible) {
				isOpen.value = true;
			} else {
				if (infoBanner) {
					isOpen.value = infoBanner.active;
				} else {
					isOpen.value = true;
				}
			}
		});


		onUnmounted(() => {
			// window.removeEventListener('scroll', handleScroll);
		});

		//TODO
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const header = document.getElementById('header');

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		function fadeOutOnScroll(element: any) {
			if (!element) {
				return;
			}

			const distanceToTop = window.pageYOffset + element.getBoundingClientRect().top;
			const elementHeight = element.offsetHeight;
			const scrollTop = document.documentElement.scrollTop;

			let opacity = 1;

			if (scrollTop > distanceToTop) {
				opacity = 1 - (scrollTop - distanceToTop) / elementHeight;
			}

			if (opacity >= 0) {
				element.style.opacity = opacity;
			}
		}


		return {
			openModal, closeModal, isOpen, opacity, handleAction,
		};
	},
});

</script>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>

