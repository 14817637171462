import {User} from "@/greeve/user/user.type";
import {UserProfile} from "@/greeve/user/user_profile.type";

export class UserFactory {
	/**
	 *
	 * @param item
	 */
	createUserByResponse(item: Record<string, any>): User {
		const id = item.id;
		const type = item.type;
		const state = item.state;
		const stripeId = item.stripe_id;
		const name = item.name;
		const email = item.email;
		const emailVerifiedAt = item.email_verified_at ? new Date(item.email_verified_at) : undefined;
		const current_team_id = item.current_team_id;
		const avatarUrl = item.avatarUrl;
		const profile = item.profile ? this.createUserProfile(item.profile) : undefined;
		const data = item.data;
		const created_at = item.created_at ? new Date(item.created_at) : (item.createdAt ? new Date(item.createdAt) : new Date());
		const updated_at = item.updated_at ? new Date(item.updated_at) : (item.updatedAt ? new Date(item.updatedAt) : new Date());

		return new User(id, type, state, name, email, emailVerifiedAt, current_team_id, avatarUrl, profile, stripeId, data, created_at, updated_at);
	}

	createUserProfile(item: Record<string, any>): UserProfile {
		const id = item.id;
		const userId = item.user_id;
		const countryCode = item.country_code;
		const userLangauge = item.language;
		const username = item.username;
		const beta = !!item.beta;
		const hideProfile = !!item.hide_profile;
		const hideName = !!item.hide_name;
		const firstName = item.first_name;
		const lastName = item.last_name;
		const address = item.address;
		const degree = item.degree;
		const gender = item.gender;
		const birthday = item.birthday ? new Date(item.birthday) : undefined;
		const profilePicture = item.profile_picture;
		const job = item.job;
		// const address = item.address ? useCartFactory().getCustomerFactory().createCustomerAddressByResponse(item.address) : undefined;
		const data = item.data ?? undefined;
		const created_at = item.created_at ? new Date(item.created_at) : (item.createdAt ? new Date(item.createdAt) : new Date());
		const updated_at = item.updated_at ? new Date(item.updated_at) : (item.updatedAt ? new Date(item.updatedAt) : new Date());

		return new UserProfile(id, userId, countryCode, userLangauge, username, beta, hideProfile, hideName, firstName, lastName, address, degree, gender, birthday, profilePicture, job, data, created_at, updated_at);
	}

	createEmptyUser(): User {
		return new User(0, '', '', '', '');
	}
}