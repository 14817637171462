import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, Transition as _Transition } from "vue"

const _hoisted_1 = {
  href: "#",
  class: "flex items-center text-gray-500 group-hover:text-gray-600"
}
const _hoisted_2 = { class: "ml-2 hidden lg:block text-sm font-medium" }
const _hoisted_3 = { class: "sr-only" }
const _hoisted_4 = { class: "sr-only" }
const _hoisted_5 = {
  role: "list",
  class: "divide-y divide-gray-200"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "flex items-center text-gray-700 hover:text-gray-800" }
const _hoisted_8 = { class: "ml-3 block text-sm font-medium" }
const _hoisted_9 = { class: "sr-only" }
const _hoisted_10 = { class: "flex items-center" }
const _hoisted_11 = { class: "flex m-4 items-center text-gray-400 dark:text-gray-500 font-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PopoverButton = _resolveComponent("PopoverButton")!
  const _component_PopoverPanel = _resolveComponent("PopoverPanel")!
  const _component_Popover = _resolveComponent("Popover")!

  return (_openBlock(), _createBlock(_component_Popover, { class: "sm:ml-4 flow-root text-sm lg:relative lg:ml-8" }, {
    default: _withCtx(() => [
      _createVNode(_component_PopoverButton, { class: "group flex items-center bg-gray-100 outline-none hover:bg-gray-200 dark:hover:bg-gr-dark dark:bg-gr-darker rounded-xl px-2 py-2 lg:py-1.5" }, {
        default: _withCtx(() => [
          _createElementVNode("a", _hoisted_1, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.cart.getCurrencyObject().getCurrencyIcon()), { class: "block h-auto w-4 flex-shrink-0" })),
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.cart.getCurrencyObject().code.toUpperCase()), 1),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('changeCurrency')), 1)
          ])
        ]),
        _: 1
      }),
      _createVNode(_Transition, {
        "enter-active-class": "transition ease-out duration-200",
        "enter-from-class": "opacity-0",
        "enter-to-class": "opacity-100",
        "leave-active-class": "transition ease-in duration-150",
        "leave-from-class": "opacity-100",
        "leave-to-class": "opacity-0"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_PopoverPanel, {
            class: _normalizeClass('mt-px bg-white dark:bg-gr-darker pb-0 shadow-lg sm:px-2 rounded-xl' + ' ' + _ctx.popoverPosition + ' ' + _ctx.popoverSize)
          }, {
            default: _withCtx(() => [
              _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.$t('whleDeineWhrungAus')), 1),
              _createElementVNode("ul", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeCurrency.filter(currencyItem => {return currencyItem.code !== _ctx.cart.currency}), (currency) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: currency.code,
                    class: "flex items-center py-6 cursor-pointer",
                    onClick: ($event: any) => (_ctx.selectCurrency(currency))
                  }, [
                    _createElementVNode("div", _hoisted_7, [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.cart.getCurrencyObject().getCurrencyIcon()), { class: "block h-auto w-7 flex-shrink-0" })),
                      _createElementVNode("span", _hoisted_8, _toDisplayString(currency.code.toUpperCase()), 1),
                      _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('changeCurrency')), 1)
                    ])
                  ], 8, _hoisted_6))
                }), 128)),
                _createElementVNode("li", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('imMomentUntersttzenWirNurDenEurAlsWhrungAberInNahe')), 1)
                  ])
                ])
              ])
            ]),
            _: 1
          }, 8, ["class"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}