<template>
		<InfoCard info-key="security-info" img="https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/images/landing/3d/privacyShield.png" :info="$t('securityDescription')" :title="$t('security2')" class="mt-2 sm:mt-4"></InfoCard>
		<SecurityManagement></SecurityManagement>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import InfoCard from '@/components/modal/InfoCard.vue';
import SecurityManagement from "@/components/shop/profile/SecurityManagement.vue";

export default defineComponent({
	name: 'SecurityPage',
	components: {
		InfoCard,
		SecurityManagement,
	},
	setup() {
		return {};
	},
});

</script>
<style lang="scss">
</style>

