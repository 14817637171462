import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mx-auto w-full md:w-[17em] text-center" }
const _hoisted_2 = { class: "mx-auto outer-card bg-white dark:bg-gr-darkest lg:gap-8" }
const _hoisted_3 = { class: "m-1 rounded-2xl" }
const _hoisted_4 = { class: "absolute p-2 pt-3.5 right-0 text-gray-500 dark:text-gray-200" }
const _hoisted_5 = {
  ref: "dropRef",
  class: "dropzone custom-dropzone"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfirmDialog = _resolveComponent("ConfirmDialog")!
  const _component_LoadingAnimation = _resolveComponent("LoadingAnimation")!
  const _component_SettingsNodePopover = _resolveComponent("SettingsNodePopover")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.fileToDelete && _ctx.selectedFlowProcess)
      ? (_openBlock(), _createBlock(_component_ConfirmDialog, {
          key: 0,
          title: _ctx.$t('flow.process.'+ _ctx.selectedFlowProcess.type +'.vue_flow.dialog.delete.title'),
          description: _ctx.$t('flow.process.'+ _ctx.selectedFlowProcess.type +'.vue_flow.dialog.delete.description', {fileName: _ctx.fileToDelete.name}),
          "cancel-button-name": _ctx.$t('flow.process.'+ _ctx.selectedFlowProcess.type +'.vue_flow.dialog.delete.cancel_button'),
          "confirm-button-name": _ctx.$t('flow.process.'+ _ctx.selectedFlowProcess.type +'.vue_flow.dialog.delete.confirm_button'),
          onConfirm: _ctx.deleteFile,
          type: "success",
          onCancelConfirm: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showConfirmDeleteFileDialog = false)),
          visible: _ctx.showConfirmDeleteFileDialog
        }, null, 8, ["title", "description", "cancel-button-name", "confirm-button-name", "onConfirm", "visible"]))
      : _createCommentVNode("", true),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_LoadingAnimation, {
          key: 1,
          "fixed-loading-animation": true
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_SettingsNodePopover)
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_5, null, 512),
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "dropzone preview-container" }, null, -1))
          ])
        ])
      ])
    ])
  ], 64))
}