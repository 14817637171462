<template>
	<div v-if="isVisible" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 p-4">
		<div class="bg-white rounded-lg p-8">
			<div class="flex items-center mb-4">
				<template v-if="!isLoading">
					<ArrowPathIcon class="h-6 w-6 text-gr-primary2 mr-2"></ArrowPathIcon>
				</template>
				<template v-else>
					<ArrowPathIcon class="animate-spin h-6 w-6 text-gr-primary2 mr-2"></ArrowPathIcon>
				</template>
				<p class="text-lg font-semibold">{{ $t('version.newVersionAvailable.title') }}</p>
			</div>
			<p class="text-lg mb-4">{{ $t('version.newVersionAvailable.description') }}</p>
			<div class="flex justify-end">
				<button @click="handleConfirm" :disabled="isLoading" :class="['px-4 py-2 text-white rounded-lg focus:outline-none bg-gr-primary', '', isLoading ? 'disabled:opacity-75 cursor-not-allowed' : '']">
					<template v-if="!isLoading">
						{{ $t('version.newVersionAvailable.button.reload') }}
					</template>
					<template v-else>
						<span>{{ $t('version.newVersionAvailable.button.loading') }}</span>
					</template>
				</button>
				<button @click="closeDialog" class="ml-4 px-4 py-2 bg-gray-300 text-gray-700 rounded-lg focus:outline-none">Cancel</button>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import {ArrowPathIcon} from '@heroicons/vue/24/outline';

const isVisible = ref(true);
const isLoading = ref(false);
const reloadIntervalId = ref();

const closeDialog = () => {
	if (reloadIntervalId.value) {
		clearTimeout(reloadIntervalId.value);
		reloadIntervalId.value = undefined;
	}
	isVisible.value = false;
};

const handleConfirm = () => {
	isLoading.value = true;
	reloadIntervalId.value = setTimeout(() => {
		window.location.reload();
	}, 500);
};
</script>

<style>
@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
.animate-spin {
	animation: spin 1s linear infinite;
}
</style>
