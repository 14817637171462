// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {AxiosError} from "axios";
import {GreeveApiGetResponse, ResponseError} from "@/greeve/api/api.interfaces";
import GreeveApiClient from "@/greeve/api/client";
import useOrderFactory from "@/composable/greeve/useOrderFactory";
import {Order} from "@/greeve/shop/order/order.type";
import {OrderList} from "@/greeve/shop/order/order_list.type";

interface GreeveGetResponse extends GreeveApiGetResponse {
    data: {type: string, result: any};
    status: any;
}

class GreeveResponseError extends ResponseError {
}

const GreeveApiOrder = {
    getOrders: async function (ignore_load_customer = true): Promise<OrderList|undefined> {
        try {
            const params: any = {};
            if (ignore_load_customer) {
                params.option_ignore_load_customer = true;
            }
            const response = await GreeveApiClient.get('/user/order', params);
            if (response) {
                const responseData = response.data.data.data;
                if (responseData) {
                    return useOrderFactory().getOrderFactory().createOrderListByResponse(responseData);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },

    getOrderByExternalReference: async function (external_reference: string, orderKey: string|null = ''): Promise<Order|undefined> {
        try {
            let response;
            if (orderKey) {
                const reference = external_reference + ';' + orderKey;
                response = await GreeveApiClient.get('/user/order/external_order/'+reference);
            } else {
                response = await GreeveApiClient.get('/user/order/external_reference/'+external_reference);
            }
            if (response) {
                const responseData = response.data.data;
                if (responseData) {
                    return useOrderFactory().getOrderFactory().createOrderByResponse(responseData);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error);
            }
        }
    },

    getOrderStateById: async function (id: number): Promise<Order|undefined> {
        try {
            const response = await GreeveApiClient.get('/user/order/state/'+id);
            if (response) {
                const responseData = response.data;
                if (responseData) {
                    return responseData;
                }

                // const responseData = response.data.data.data;
                if (responseData) {
                    return useOrderFactory().getOrderFactory().createOrderByResponse(responseData);
                }
            }
            return undefined;
        } catch (error: AxiosError|any) {
            if (error.response) {
                const errorMessage = error.response?.data?.message ? error.response.data.message : (error?.message ? error.message : '');
                throw new ResponseError(
                    error.response.status,
                    errorMessage
                );
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    },
}

export {GreeveApiOrder, GreeveGetResponse, GreeveResponseError};
