import {Country} from "@/greeve/core/country/country.type";
import {CountryList} from "@/greeve/core/country/country_list.type";

export class CountryFactory {
	/**
	 *
	 * @param countryResponse
	 */
	createCountryListByResponse(countryResponse: Record<string, any>): CountryList {
		const countryList = new CountryList();

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		for (const [key, item] of Object.entries(countryResponse)) {
			countryList.push(this.createCountryByResponse(item));
		}

		return countryList;
	}

	/**
	 *
	 * @param item
	 */
	createCountryByResponse(item: Record<string, any>): Country {
		const code = item.code;
		const name = item.name;
		const native = item.native;
		const continent = item.continent;
		const continent_code = item.continent_code;
		const phone = item.phone;
		const capital = item.capital;
		const currency = item.currency;
		const languages = item.languages;
		const active = item.active;
		const data = item.data;
		const created_at = item.created_at ? new Date(item.created_at) : (item.createdAt ? new Date(item.createdAt) : new Date());
		const updated_at = item.updated_at ? new Date(item.updated_at) : (item.updatedAt ? new Date(item.updatedAt) : new Date());

		return new Country(code, name, native, continent, continent_code, phone, capital, currency, languages, active, created_at, updated_at, data);
	}
}