import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "loading-overlay"
}
const _hoisted_2 = {
  key: 0,
  class: "loading-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "loading-spinner",
          style: _normalizeStyle({ width: _ctx.spinnerSize, height: _ctx.spinnerSize })
        }, null, 4),
        (_ctx.loadingText)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.loadingText), 1))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}