import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "group truncate flex items-center p-2 px-4 py-2 bg-gray-100 hover:bg-gray-200 dark:hover:bg-gr-dark dark:bg-gr-darker rounded-xl outline-none" }
const _hoisted_2 = { class: "text-sm font-bold text-gray-500 dark:text-gray-400 group-hover:text-gray-800 dark:group-hover:text-gray-400" }
const _hoisted_3 = { class: "sr-only" }
const _hoisted_4 = { class: "group truncate flex items-center p-2 px-4 py-2 bg-gray-100 hover:bg-gray-200 dark:hover:bg-gr-dark dark:bg-gr-darker rounded-xl outline-none" }
const _hoisted_5 = { class: "text-sm font-bold text-gray-500 dark:text-gray-400 group-hover:text-gray-800 dark:group-hover:text-gray-400" }
const _hoisted_6 = { class: "sr-only" }
const _hoisted_7 = { class: "group truncate flex items-center p-2 px-4 py-2 bg-gray-100 hover:bg-gray-200 dark:hover:bg-gr-dark dark:bg-gr-darker rounded-xl outline-none" }
const _hoisted_8 = { class: "text-sm font-bold text-gray-500 dark:text-gray-400 group-hover:text-gray-800 dark:group-hover:text-gray-400" }
const _hoisted_9 = { class: "sr-only" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.isAuthenticated && (!_ctx.cart.items.length || _ctx.cart.items.length < 0))
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        to: "/profile/credits",
        class: "ml-4 flow-root text-sm lg:relative lg:ml-8"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('credits3', {getAvailableCreditAmount: _ctx.getQuotas?.getAvailableCreditAmount()})), 1),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('itemsInCartViewBag')), 1)
          ])
        ]),
        _: 1
      }))
    : (_ctx.isAuthenticated && _ctx.cart.items.length > 0)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 1,
          to: "/shop",
          class: "ml-4 flow-root text-sm lg:relative lg:ml-8"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('credits3', {getAvailableCreditAmount: _ctx.getQuotas?.getAvailableCreditAmount()})), 1),
              _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('itemsInCartViewBag')), 1)
            ])
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_router_link, {
          key: 2,
          to: "/pricing",
          class: "ml-4 flow-root text-sm lg:relative lg:ml-8"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('credits3', {getAvailableCreditAmount: _ctx.getQuotas?.getAvailableCreditAmount()})), 1),
              _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('itemsInCartViewBag')), 1)
            ])
          ]),
          _: 1
        }))
}