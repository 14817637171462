<template>
	<div class="rounded-b-xl w-full py-3 text-right">
		<button @click="redirectToCustomerBillingPortal" aria-describedby="tier-scale"
						class="inline-flex justify-center rounded-xl bg-gr-primary hover:bg-gr-primary2 py-1.5 px-10 text-sm font-semibold text-white shadow-sm focus:outline-none">
			{{ $t('manage_billing.button_title') }}
		</button>
	</div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import useUser from '@/composable/greeve/useUser';
import useAlert from '@/composable/core/useAlert';
import useCustomStore from '@/composable/custom/useCustomStore';

export default defineComponent({
	name: 'StripeBillingPortalButton',
	components: {},
	props: {},
	emits: [],
	setup() {
		// const router = useRouter();
		const {getBillingPortalUrl} = useUser();
		const { setIsGlobalLoading } = useCustomStore();
		const {addError} = useAlert();

		function redirectToCustomerBillingPortal() {
			setIsGlobalLoading(true);
			setTimeout(() => {
				setIsGlobalLoading(false);
			}, 8000);
			getBillingPortalUrl().then(link => {
				if (link) {
					setIsGlobalLoading(false);
					window.location.href = link;
				} else {
					const error = {
						id: '',
						title: 'Error',
						message: 'Error redirecting to customer billing portal! Please try again later!',
						active: true,
					};
					addError(error);
				}
			})
			// router.push('shop');
		}

		return {
			redirectToCustomerBillingPortal
		};
	},
});
</script>

<style scoped>

</style>
