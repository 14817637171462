import {FaqInterface} from "@/greeve/faq/faq.interface";

export class Faq implements FaqInterface {
	question_title: string;
	question_description?: string;
	language?: string;
	answer_title: string;
	answer_description?: string;
	image?: string;
	order?: number;
	data?: any;
	created_at?: Date;
	updated_at?: Date;


	constructor(questionTitle: string, questionDescription: string, answerTitle: string, answerDescription?: string, language?: string, image?: string, order?: number, data: any = null, created_at?: Date, updated_at?: Date) {
		this.question_title = questionTitle;
		this.question_description = questionDescription;
		this.language = language;
		this.answer_title = answerTitle;
		this.answer_description = answerDescription;
		this.image = image;
		this.order = order;
		this.data = data;
		this.created_at = created_at;
		this.updated_at = updated_at;
	}
}