<script setup lang="ts">

import DangerAlert from '@/components/alert/Base/DangerAlert.vue';
import useAlert from '@/composable/core/useAlert';
import useUser from '@/composable/greeve/useUser';
import useAssistant from '@/composable/greeve/useAssistant';
import useCustomStore from '@/composable/custom/useCustomStore';
import useAuth from '@/composable/auth/useAuth';
import {onMounted, reactive, ref} from 'vue';
import useTranslation from '@/composable/translation/useTranslation';
import {GreeveApiAuth} from '@/greeve/api/auth';
import router from '@/router';
import {RouteChecker} from '@/composable/core/route_checker';
import {useRoute} from 'vue-router';
import PasswordStrenghtOmeter from '@/components/core/PasswordStrenghtOmeter.vue';
import GoogleOAuthComponent from '@/components/auth/firebase/GoogleOAuthComponent.vue';
const {user} = useUser();

const {clearErrorAlert} = useAlert();
const {initUserByUserType, initQuotas, initSubscriptions, initLastUserAddress} = useUser();
const {initSearchGroups} = useAssistant();
const { setIsGlobalLoading } = useCustomStore();
const {isAuthenticated, logout} = useAuth();
const route = useRoute();

const {
	errorMessage,
	errorTitle,
	getDeviceHash,
} = useAuth();

const {t} = useTranslation();
const isRegisterMode = ref(false);
const routeChecker = ref<RouteChecker>();
const signInEmail = ref<HTMLInputElement | null>(null);
const registerEmail = ref<HTMLInputElement | null>(null);
const waitForLoginEvent = ref(false);

const signInUser = reactive({
	email: '',
	password: '',
	emailError: '',
	passwordError: '',
});

const registerUser = reactive({
	email: "",
	emailError: "",
	password: "",
	passwordError: "",
	terms: false,
});

async function sendLoginSuccessEvent() {
	clearUserData();
	await router.push('/');
	setIsGlobalLoading(false);
}

function sendLoginErrorEvent() {
	setIsGlobalLoading(false);
}

const clearUserData = () => {
	signInUser.email = '';
	signInUser.password = '';
	registerUser.email = '';
	registerUser.password = '';
	registerUser.terms = false;
}

function hideErrorMessage()
{
	clearErrorAlert();
	errorMessage.value = '';
	errorTitle.value = '';
}

function handleSignin(token: string, showLoadingAnimation = true) {
	try {
		if (!token) {
			throw new Error('Invalid token');
		}
		if (showLoadingAnimation) {
			setIsGlobalLoading(true);
			clearErrorAlert();
		}
		GreeveApiAuth.getUser().then((userResponse) => {
			if (!userResponse || !userResponse.id) {
				throw new Error('Invalid User');
			}
			initUserByUserType(userResponse);
			clearErrorAlert();
			try {
				initQuotas(true);
				initSubscriptions(true);
				initLastUserAddress();
			} catch (e) {
				console.error(e);
			}
			initSearchGroups(true, true, 5).then(() => {
				waitForLoginEvent.value = false;
				sendLoginSuccessEvent();
			});
		});
	} catch (e) {
		console.error(e);
		errorTitle.value = t('auth.auth_error_failed_title');
		errorMessage.value = t('auth.failed');
		sendLoginErrorEvent();
		setIsGlobalLoading(false);
	}
}

function onSignIn() {
	const loginData = {
		email: signInUser.email,
		password: signInUser.password,
		device_name: getDeviceHash(),
	};
	try {
		setIsGlobalLoading(true);
		clearErrorAlert();
		GreeveApiAuth.signIn(loginData).then((token) => {
			handleSignin(token, false);
		}).catch((e) => {
			console.error('OHH', e);
			errorTitle.value = t('auth.auth_error_failed_title');
			errorMessage.value = t('auth.failed');
			sendLoginErrorEvent();
			setIsGlobalLoading(false);
		});
	} catch (e) {
		console.error(e);
		errorTitle.value = t('auth.auth_error_failed_title');
		errorMessage.value = t('auth.failed');
		sendLoginErrorEvent();
		setIsGlobalLoading(false);
	}
}

function clearRegisterUserErrors() {
	registerUser.emailError = "";
	registerUser.passwordError = "";
}

function onRegister() {
	clearErrorAlert();
	clearRegisterUserErrors();

	const registerData = {
		name: registerUser.email,
		email: registerUser.email,
		password: registerUser.password,
		password_confirmation: registerUser.password,
		terms: registerUser.terms,
		device_name: getDeviceHash(),
	};

	try {
		setIsGlobalLoading(true);
		clearErrorAlert();
		GreeveApiAuth.registerUser(registerData).then((token) => {
			if (!token) {
				throw new Error('Invalid token');
			}
			GreeveApiAuth.getUser().then((userResponse) => {
				if (!userResponse || !userResponse.id) {
					throw new Error('Invalid User');
				}
				initUserByUserType(userResponse);
				clearErrorAlert();
				try {
					initQuotas(true);
					initSubscriptions(true);
					initLastUserAddress();
				} catch (e) {
					console.error(e);
				}
				initSearchGroups(true, true, 5).then(() => {
					sendLoginSuccessEvent();
				})
			});
		}).catch((e) => {
			console.error('OHH', e);
			errorTitle.value = t('auth.auth_error_failed_title');
			errorMessage.value = t('auth.failed');
			if (e.message.email) {
				user.emailError = e.message.email.join("<br>");
			}
			if (e.message.password) {
				user.passwordError = e.message.password.join("<br>");
			}
			sendLoginErrorEvent();
			setIsGlobalLoading(false);
		});
	} catch (e: any) {
		console.error(e);
		errorTitle.value = t('auth.auth_error_failed_title');
		errorMessage.value = t('auth.failed');
		if (e.message.email) {
			user.emailError = e.message.email.join("<br>");
		}
		if (e.message.password) {
			user.passwordError = e.message.password.join("<br>");
		}
		sendLoginErrorEvent();
		setIsGlobalLoading(false);
	}
}

function logoutUser()
{
	try {
		setIsGlobalLoading(true);
		logout(true, true)?.then(() => {
			setIsGlobalLoading(false);
		})
	} catch {
		setIsGlobalLoading(false);
	}
}

const checkMode = () => {
	isRegisterMode.value = routeChecker.value?.isRouteActive(route.path) ?? false;
	if (isRegisterMode.value) {
		initRegisterMode();
	} else {
		initSignInMode();
	}
}

const initRegisterMode = () => {
	const currentQuery = route.query;
	router.replace({ path: '/register', query: { ...currentQuery } });
	isRegisterMode.value = true;
	if (registerEmail.value) {
		registerEmail.value.focus();
	}
};

const initSignInMode = () => {
	const currentQuery = route.query;
	router.replace({ path: '/auth', query: { ...currentQuery } });

	isRegisterMode.value = false;
	if (signInEmail.value) {
		signInEmail.value.focus();
	}}

onMounted(() => {
	routeChecker.value = new RouteChecker(['/register']);
	checkMode();
})
</script>

<template>
	<main class="dark:bg-gr-dark bg-gray-50">
<!--	<main class="bg-gray-50">-->
		<div class="relative mx-auto max-w-7xl">
			<div
					class="absolute -right-60 -top-44 h-60 w-[36rem] transform-gpu md:right-0 bg-[linear-gradient(115deg,var(--tw-gradient-stops))] from-[#fff1be] from-[28%] via-[#ee87cb] via-[70%] to-[#b060ff] rotate-[-10deg] rounded-full blur-3xl"></div>
		</div>
		<DangerAlert v-show="errorMessage" :error-message="errorMessage" :error-title="errorTitle" class="pb-8" css-class="absolute w-auto right-0"
								 :show-title="true" @closeAlert="hideErrorMessage"></DangerAlert>

		<div v-if="isAuthenticated && !waitForLoginEvent">
			<div class="isolate flex min-h-dvh items-center justify-center p-6 lg:p-8">
				<div class="w-full max-w-md rounded-xl bg-white shadow-md ring-1 ring-black/5">
					<div class="p-7 sm:p-11">
						<div class="flex items-start"><a title="Home" data-headlessui-state="" @click="router.push('/')" class="cursor-pointer">
							<img class="h-9" src="https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/images/logo/icon-text.webp" alt="greeve_logo">
						</a></div>
						<h1 class="mt-8 text-base/6 font-medium text-black">{{ $t('auth.General.already_logged_in') }}</h1>
						<p class="mt-1 text-sm/5 text-gray-600">{{ $t('not_user_x', {name: user.name}) }}</p>
						<div class="mt-8">
							<button @click="logoutUser"
											class="w-full inline-flex items-center justify-center px-4 py-[calc(theme(spacing.2)-1px)] rounded-full border border-transparent bg-gray-950 shadow-md whitespace-nowrap text-base font-medium text-white data-[disabled]:bg-gray-950 data-[hover]:bg-gray-800 data-[disabled]:opacity-40"
											data-headlessui-state="" v-html="$t('shop.checkout.logout.title')">
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-else>
			<!--		REGISTER -->
			<div v-if="isRegisterMode" class="isolate flex min-h-dvh items-center justify-center p-6 lg:p-8">
				<div class="w-full max-w-md rounded-xl bg-white shadow-md ring-1 ring-black/5">
					<form class="p-7 sm:p-11" @submit.prevent="onRegister">
						<div class="flex items-start"><a title="Home" data-headlessui-state="" @click="router.push('/')" class="cursor-pointer">
							<img class="h-9" src="https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/images/logo/icon-text.webp" alt="greeve_logo">
						</a></div>
						<h1 class="mt-8 text-base/6 font-medium text-black">{{$t('auth.RegisterComponent.title')}}</h1>
						<p class="mt-1 text-sm/5 text-gray-600">{{$t('auth.RegisterComponent.description')}}</p>
						<div class="mt-8 space-y-3" data-headlessui-state=""><label class="text-sm/5 font-medium text-black"
																																				id="headlessui-label-:r1:"
																																				for="registerEmail"
																																				data-headlessui-state="">{{ $t('jobs.FormMail') }}</label><input
								required=""
								class="block w-full rounded-lg text-black border border-transparent shadow ring-1 ring-black/10 px-[calc(theme(spacing.2)-1px)] py-[calc(theme(spacing[1.5])-1px)] text-base/6 sm:text-sm/6 data-[focus]:outline data-[focus]:outline-2 data-[focus]:-outline-offset-1 data-[focus]:outline-black"
								ref="registerEmail" data-headlessui-state="autofocus" data-autofocus="" type="email" name="registerEmail" v-model="registerUser.email" :autofocus="true"
								aria-labelledby="headlessui-label-:r1:"><span hidden=""
																															style="position: fixed; top: 1px; left: 1px; width: 1px; height: 0px; padding: 0px; margin: -1px; overflow: hidden; clip: rect(0px, 0px, 0px, 0px); white-space: nowrap; border-width: 0px; display: none;"></span>
						</div>
						<div class="mt-8 space-y-3" data-headlessui-state=""><label class="text-sm/5 font-medium text-black"
																																				id="headlessui-label-:r4:"
																																				for="headlessui-control-:r3:"
																																				data-headlessui-state="">{{$t('auth.LoginComponent.password')}}</label><input
								required=""
								class="block w-full rounded-lg border text-black border-transparent shadow ring-1 ring-black/10 px-[calc(theme(spacing.2)-1px)] py-[calc(theme(spacing[1.5])-1px)] text-base/6 sm:text-sm/6 data-[focus]:outline data-[focus]:outline-2 data-[focus]:-outline-offset-1 data-[focus]:outline-black"
								id="headlessui-control-:r3:" data-headlessui-state="" type="password" name="password" v-model="registerUser.password"
								aria-labelledby="headlessui-label-:r4:"><span hidden=""
																															style="position: fixed; top: 1px; left: 1px; width: 1px; height: 0px; padding: 0px; margin: -1px; overflow: hidden; clip: rect(0px, 0px, 0px, 0px); white-space: nowrap; border-width: 0px; display: none;"></span>
						</div>
						<password-strenght-ometer :current-password="registerUser.password" :show-password-strengtho-meter="registerUser.password.length > 0"></password-strenght-ometer>

						<div class="mt-8 text-sm/5">

							<div class="flex items-start">
								<div class="flex h-5 mt-0.5 items-center">
									<input id="terms" v-model="registerUser.terms" aria-describedby="terms-description" name="terms"
												 required type="checkbox" class="h-4 w-4 rounded border-gray-200 text-gr-primary2"/>
								</div>
								<div class="ml-3 text-sm">
									<label for="offers"
												 class="font-medium text-gray-700 dark:text-gray-400 text-xs">{{
											$t("terms.TermsTitle")
										}}</label>
									<p class="text-gray-500 text-xs">{{ $t("auth.RegisterComponent.dataProcessing") }}</p>
								</div>
							</div>
						</div>


						<div class="mt-8">
							<button type="submit"
											class="w-full inline-flex items-center justify-center px-4 py-[calc(theme(spacing.2)-1px)] rounded-full border border-transparent bg-gray-950 shadow-md whitespace-nowrap text-base font-medium text-white data-[disabled]:bg-gray-950 data-[hover]:bg-gray-800 data-[disabled]:opacity-40"
											data-headlessui-state="">{{$t('auth.RegisterComponent.registerBtnRegister')}}
							</button>
						</div>

						<div>
							<div class="relative mt-10">
								<div class="absolute inset-0 flex items-center" aria-hidden="true">
									<div class="w-full border-t border-gray-200" />
								</div>
								<div class="relative flex justify-center text-sm font-medium leading-6">
									<span class="bg-white px-6 text-gray-900">{{$t('auth.RegisterComponent.registerExternal')}}</span>
								</div>
							</div>

							<div class="mt-6 grid grid-cols-1 gap-4">
								<GoogleOAuthComponent @on-success="handleSignin" @on-error="sendLoginErrorEvent" @on-start="waitForLoginEvent = true" @on-end="waitForLoginEvent = false"></GoogleOAuthComponent>
							</div>
						</div>
					</form>

					<div class="m-1.5 rounded-lg text-black bg-gray-50 py-4 text-center text-sm/5 ring-1 ring-black/5"><a
							class="font-medium hover:text-gray-600 underline cursor-pointer" data-headlessui-state="" @click="initSignInMode">{{$t('auth.LoginComponent.loginTitle')}}</a></div>
				</div>
			</div>
			<!--		LOGIN -->
			<div v-else class="isolate flex min-h-dvh items-center justify-center p-6 lg:p-8">
				<div class="w-full max-w-md rounded-xl bg-white shadow-md ring-1 ring-black/5">
					<form class="p-7 sm:p-11" @submit.prevent="onSignIn">
						<div class="flex items-start"><a title="Home" data-headlessui-state="" @click="router.push('/')" class="cursor-pointer">
							<img class="h-9" src="https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/images/logo/icon-text.webp" alt="greeve_logo">
						</a></div>
						<h1 class="mt-8 text-black text-base/6 font-medium">{{$t('auth.General.welcome_back')}}</h1>
						<p class="mt-1 text-sm/5 text-gray-600">{{$t('auth.General.welcome_back_description')}}</p>
						<div class="mt-8 space-y-3" data-headlessui-state=""><label class="text-sm/5 font-medium text-black"
																																				id="headlessui-label-:r1:"
																																				for="signInEmail"
																																				data-headlessui-state="">{{ $t('jobs.FormMail') }}</label><input
								required
								class="block w-full rounded-lg border border-transparent shadow text-black ring-1 ring-black/10 px-[calc(theme(spacing.2)-1px)] py-[calc(theme(spacing[1.5])-1px)] text-base/6 sm:text-sm/6 data-[focus]:outline data-[focus]:outline-2 data-[focus]:-outline-offset-1 data-[focus]:outline-black"
								id="signInEmail" ref="signInEmail" data-headlessui-state="autofocus" type="email" name="signInEmail" v-model="signInUser.email" :autofocus="true"
								aria-labelledby="headlessui-label-:r1:"><span hidden=""
																															style="position: fixed; top: 1px; left: 1px; width: 1px; height: 0px; padding: 0px; margin: -1px; overflow: hidden; clip: rect(0px, 0px, 0px, 0px); white-space: nowrap; border-width: 0px; display: none;"></span>
						</div>
						<div class="mt-8 space-y-3" data-headlessui-state=""><label class="text-sm/5 font-medium text-black"
																																				id="headlessui-label-:r4:"
																																				for="headlessui-control-:r3:"
																																				data-headlessui-state="">{{$t('auth.LoginComponent.password')}}</label><input
								required
								class="block w-full rounded-lg border border-transparent text-black shadow ring-1 ring-black/10 px-[calc(theme(spacing.2)-1px)] py-[calc(theme(spacing[1.5])-1px)] text-base/6 sm:text-sm/6 data-[focus]:outline data-[focus]:outline-2 data-[focus]:-outline-offset-1 data-[focus]:outline-black"
								id="headlessui-control-:r3:" data-headlessui-state="" type="password" name="password" v-model="signInUser.password"
								aria-labelledby="headlessui-label-:r4:"><span hidden=""
																															style="position: fixed; top: 1px; left: 1px; width: 1px; height: 0px; padding: 0px; margin: -1px; overflow: hidden; clip: rect(0px, 0px, 0px, 0px); white-space: nowrap; border-width: 0px; display: none;"></span>
						</div>
						<div class="mt-8 flex items-end justify-end text-sm/5">
							<a class="font-medium hover:text-gray-600 cursor-pointer text-gray-700" data-headlessui-state="" @click="router.push('/forgot-password')">{{$t('auth.LoginComponent.loginBtnForgot')}}</a>
						</div>
						<div class="mt-8">
							<button type="submit"
											class="w-full inline-flex items-center justify-center px-4 py-[calc(theme(spacing.2)-1px)] rounded-full border border-transparent bg-gray-950 shadow-md whitespace-nowrap text-base font-medium text-white data-[disabled]:bg-gray-950 data-[hover]:bg-gray-800 data-[disabled]:opacity-40"
											data-headlessui-state="">{{$t('auth.LoginComponent.loginBtnLogin')}}
							</button>
						</div>

						<div>
							<div class="relative mt-10">
								<div class="absolute inset-0 flex items-center" aria-hidden="true">
									<div class="w-full border-t border-gray-200" />
								</div>
								<div class="relative flex justify-center text-sm font-medium leading-6">
									<span class="bg-white px-6 text-gray-900">{{$t('auth.RegisterComponent.registerExternal')}}</span>
								</div>
							</div>

							<div class="mt-6 grid grid-cols-1 gap-4">
								<GoogleOAuthComponent @on-success="handleSignin" @on-error="sendLoginErrorEvent" @on-start="waitForLoginEvent = true" @on-end="waitForLoginEvent = false"></GoogleOAuthComponent>

<!--								<a class="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent">-->
<!--									<svg class="h-5 w-5 fill-[#24292F]" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">-->
<!--										<path fill-rule="evenodd" d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z" clip-rule="evenodd" />-->
<!--									</svg>-->
<!--									<span class="text-sm font-semibold leading-6">GitHub</span>-->
<!--								</a>-->
							</div>
						</div>
					</form>

					<div class="m-1.5 rounded-lg text-black bg-gray-50 py-4 text-center text-sm/5 ring-1 ring-black/5">{{$t('auth.LoginComponent.notAMember')}} <a
							class="font-medium hover:text-gray-600 underline cursor-pointer" @click="initRegisterMode">{{$t('auth.LoginComponent.createAccount')}}</a></div>
				</div>
			</div>
		</div>
	</main>
</template>

<style scoped>
</style>