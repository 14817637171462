<template>
	<div v-if="show" class="loading-overlay">
		<div class="loading-spinner" :style="{ width: spinnerSize, height: spinnerSize }"></div>
		<div v-if="loadingText" class="loading-text">{{ loadingText }}</div>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'LoadingAnimation',
	props: {
		show: { type: Boolean, default: true },
		size: { type: String, default: '150px' }, // Default size
		loadingText: { type: String, default: 'Loading, please wait...' },
		color: { type: String, default: '#ff5e00' },
	},
	computed: {
		spinnerSize() {
			return this.size;
		},
	},
});
</script>

<style scoped>
.loading-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.5);
	backdrop-filter: blur(5px);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 9999;
}

.loading-spinner {
	border-radius: 50%;
	border: 3px solid transparent;
	border-top-color: #ff5e00; /* Default color */
	animation: spin 1s linear infinite;
}

.loading-text {
	margin-top: 1rem;
	animation: pulse 1s infinite alternate;
}

@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}

@keyframes pulse {
	0% { opacity: 0.5; }
	100% { opacity: 1; }
}
</style>
