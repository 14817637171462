import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "rounded-3xl px-3 font-comfortaa py-0 bg-black dark:bg-gray-400 font-bold text-white dark:text-black mt-0 float-right text-tc-bg text-xs sm:text-base sm:mr-1.5 -mt-1 sm:-mt-2" }
const _hoisted_2 = { class: "mx-4 md:mx-7" }
const _hoisted_3 = { class: "py-32 xl:py-36 px-4 sm:px-6 lg:px-8 bg-tc-bg overflow-hidden" }
const _hoisted_4 = { class: "max-w-max lg:max-w-7xl mx-auto" }
const _hoisted_5 = { class: "relative mb-8 md:mb-2 md:px-6" }
const _hoisted_6 = { class: "text-base max-w-prose lg:max-w-none" }
const _hoisted_7 = { class: "mt-2 text-3xl leading-8 font-extrabold tracking-tight text-tc-blue sm:text-4xl" }
const _hoisted_8 = { class: "md:px-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderLogo = _resolveComponent("HeaderLogo")!
  const _component_SocialMedia = _resolveComponent("SocialMedia")!
  const _component_FooterBar = _resolveComponent("FooterBar")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode(_component_HeaderLogo, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('beta2')), 1)
            ]),
            _: 1
          }),
          _cache[0] || (_cache[0] = _createTextVNode()),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.t('pressKit.socialMediaTitle')), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_SocialMedia)
                ])
              ])
            ])
          ]),
          _createVNode(_component_FooterBar)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}