import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  class: "",
  id: "footer"
}
const _hoisted_2 = { class: "nav rounded-2xl m-2 sm:m-4 mb-[9.35em] sm:mb-[10.35em] md:mb-[5.5em] lg:mb-4" }
const _hoisted_3 = { class: "nav backdrop-filter backdrop-blur-2xl rounded-2xl py-8 px-6 lg:px-8" }
const _hoisted_4 = {
  class: "-mx-5 mt-4 my-2 flex flex-wrap",
  "aria-label": "footer"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "px-5 py-2" }
const _hoisted_7 = {
  href: "https://www.blog.greeve.ai",
  class: "text-base text-gray-500 dark:text-gray-400 cursor-pointer"
}
const _hoisted_8 = { class: "px-5 py-2" }
const _hoisted_9 = { class: "mt-8 flex space-x-6" }
const _hoisted_10 = {
  id: "copyright",
  class: "mt-8 mb-4 text-base text-gray-500 dark:text-gray-400"
}
const _hoisted_11 = { class: "tooltip" }
const _hoisted_12 = {
  key: 0,
  class: "tooltiptext"
}
const _hoisted_13 = { href: "https://www.theconcept-technologies.com" }
const _hoisted_14 = {
  id: "disclaimer",
  class: "mx-auto text-sm text-gray-400 dark:text-gray-700"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_language_selector = _resolveComponent("language-selector")!
  const _component_SocialMedia = _resolveComponent("SocialMedia")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_language_selector),
        _createElementVNode("nav", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navigation.main, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.name,
              class: "px-5 py-2"
            }, [
              _createElementVNode("div", {
                onClick: ($event: any) => (_ctx.redirectFooterByItem(item.href)),
                class: "text-base text-gray-500 dark:text-gray-400 cursor-pointer"
              }, _toDisplayString(_ctx.t(item.name)), 9, _hoisted_5)
            ]))
          }), 128)),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("a", _hoisted_7, _toDisplayString(_ctx.$t('footer.blog')), 1)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("a", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.redirectFooterByItem('/enterprise'))),
              class: "cursor-pointer text-base text-gray-500 dark:text-gray-400 cursor-pointer"
            }, _toDisplayString(_ctx.$t('footer.enterprise')), 1)
          ])
        ]),
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "mx-auto max-w-max my-6" }, null, -1)),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_SocialMedia, {
            "social-media-style": "text-gray-500 dark:text-gray-400 cursor-pointer",
            "social-media-height": "h-6 w-6"
          })
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("span", _hoisted_11, [
            _createTextVNode(_toDisplayString(_ctx.$t('copy', {getFullYear: new Date().getFullYear()})) + " ", 1),
            (_ctx.appVersion)
              ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.$t('currentVersion')) + " " + _toDisplayString(_ctx.appVersion), 1))
              : _createCommentVNode("", true)
          ]),
          _cache[1] || (_cache[1] = _createTextVNode()),
          _createElementVNode("a", _hoisted_13, _toDisplayString(_ctx.$t('terms.ContactDetail2')), 1),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('allRightsReserved')), 1)
        ]),
        _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t('chatGptDisclaimer')), 1)
      ])
    ])
  ]))
}