import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "cursor-pointer" }
const _hoisted_2 = {
  key: 0,
  class: "mx-auto max-w-md"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "sm:mr-1.5 -mt-1 sm:-mt-2 rounded-3xl px-3 font-comfortaa py-0 bg-black dark:bg-gray-400 font-bold text-white dark:text-black text-xs sm:text-base mt-0 float-right text-tc-bg"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 2,
  class: "mx-auto max-w-md"
}
const _hoisted_7 = { key: 3 }
const _hoisted_8 = { class: "sr-only" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.customClass)
  }, [
    _createVNode(_component_router_link, { to: _ctx.redirectLink }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (!_ctx.smallLogo)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("img", {
                  class: _normalizeClass('mx-auto' + ' ' + _ctx.customHeight),
                  src: "https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/images/logo/icon-text.webp",
                  alt: "greeve_logo"
                }, null, 2),
                (_ctx.beta === true)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                    ]))
                  : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t('pro')), 1))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[0] || (_cache[0] = [
                _createElementVNode("img", {
                  class: "mx-auto max-img-height-top",
                  src: "https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/images/logo/icon-text.webp",
                  alt: "greeve_logo"
                }, null, -1)
              ]))),
          (_ctx.shortLogo === true)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("img", {
                  class: _normalizeClass('mx-auto' + ' ' + _ctx.customHeight),
                  src: "https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/images/logo/icon-short.webp",
                  alt: "greeve_logo"
                }, null, 2)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_7))
        ]),
        _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('greeve')), 1)
      ]),
      _: 3
    }, 8, ["to"])
  ], 2))
}