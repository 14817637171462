<template>
	<ion-page>
		<ion-content>
			<main  :style="{ paddingTop: headerHeight + 'px' }">
			<error-component :error-number="$t('error.NotFoundComponent.404Number')" :error-message="$t('error.NotFoundComponent.404Message')" :error-title="$t('error.NotFoundComponent.404Title')"></error-component>
			<FooterBar class="mt-12"></FooterBar>
			</main>
		</ion-content>
	</ion-page>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {IonPage, IonContent} from '@ionic/vue';
import FooterBar from "@/components/positions/FooterBar.vue";
import useTranslation from "@/composable/translation/useTranslation";
import ErrorComponent from "@/components/errors/ErrorComponent.vue";
import {useHeaderHeight} from "@/composable/global/useHeaderHeight";


export default defineComponent({
	name: 'ErrorPage',
	components: {
		ErrorComponent, FooterBar, IonContent, IonPage
	},
	setup() {
		const { headerHeight } = useHeaderHeight();

		const {t} = useTranslation();
		return {
      t, headerHeight
		}
	}
});

</script>
