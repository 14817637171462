import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "mt-6 overflow-hidden border-t border-gray-100" }
const _hoisted_2 = { class: "mx-auto max-w-7xl px-4 sm:px-6 lg:px-8" }
const _hoisted_3 = { class: "mx-auto max-w-2xl lg:mx-0 lg:max-w-none" }
const _hoisted_4 = { class: "w-full text-left" }
const _hoisted_5 = { class: "sr-only" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "relative py-5 pr-6" }
const _hoisted_9 = { class: "flex gap-x-6" }
const _hoisted_10 = { class: "flex-auto" }
const _hoisted_11 = { class: "flex items-start gap-x-3" }
const _hoisted_12 = { class: "text-sm font-medium leading-6 text-gray-900 dark:text-gray-500" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = {
  key: 0,
  class: "mt-1 text-xs leading-5 text-gray-500"
}
const _hoisted_16 = {
  key: 0,
  class: "py-5 text-right"
}
const _hoisted_17 = { class: "flex justify-end" }
const _hoisted_18 = ["href"]
const _hoisted_19 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DefaultPagination = _resolveComponent("DefaultPagination")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("table", _hoisted_4, [
            _createElementVNode("thead", _hoisted_5, [
              (_ctx.headers)
                ? (_openBlock(), _createElementBlock("tr", _hoisted_6, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header) => {
                      return (_openBlock(), _createElementBlock("th", { key: header }, _toDisplayString(header), 1))
                    }), 128))
                  ]))
                : (_openBlock(), _createElementBlock("tr", _hoisted_7, [
                    _createElementVNode("th", null, _toDisplayString(_ctx.$t('payment.CreditCard.nameTitle')), 1),
                    _createElementVNode("th", null, _toDisplayString(_ctx.$t('moreDetails')), 1)
                  ]))
            ]),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listData, (item) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: item.id
                }, [
                  _createElementVNode("td", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), {
                        class: _normalizeClass(['hidden h-6 w-5 flex-none sm:block', item.iconColor ? 'text-'+item.iconColor+'-500' : 'text-gray-400']),
                        "aria-hidden": "true"
                      }, null, 8, ["class"])),
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("div", _hoisted_11, [
                          _createElementVNode("div", _hoisted_12, _toDisplayString(item.title), 1),
                          (item.labels)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.labels, (label) => {
                                  return (_openBlock(), _createElementBlock("span", {
                                    key: label.key,
                                    class: _normalizeClass(['inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ml-1', label.color ? _ctx.getLabelClassByColor(label.color) : 'bg-gray-50 text-gray-600 ring-gray-500/10']),
                                    innerHTML: label.value
                                  }, null, 10, _hoisted_14))
                                }), 128))
                              ]))
                            : _createCommentVNode("", true),
                          (item.status)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 1,
                                class: _normalizeClass([_ctx.getStatusCss[item.status], 'rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset'])
                              }, _toDisplayString(item.status), 3))
                            : _createCommentVNode("", true)
                        ]),
                        (item.detail)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(item.detail), 1))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "absolute bottom-0 right-full h-px w-screen bg-gray-100" }, null, -1)),
                    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "absolute bottom-0 left-0 h-px w-screen bg-gray-100" }, null, -1))
                  ]),
                  (item.link.length > 0)
                    ? (_openBlock(), _createElementBlock("td", _hoisted_16, [
                        _createElementVNode("div", _hoisted_17, [
                          _createElementVNode("a", {
                            href: item.link,
                            class: "text-sm font-medium leading-6 text-indigo-600 hover:text-indigo-500"
                          }, [
                            _createTextVNode(_toDisplayString(_ctx.$t('view')), 1),
                            _cache[2] || (_cache[2] = _createElementVNode("span", { class: "hidden sm:inline" }, null, -1)),
                            _cache[3] || (_cache[3] = _createElementVNode("span", { class: "sr-only" }, null, -1))
                          ], 8, _hoisted_18)
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ])
          ])
        ])
      ])
    ]),
    (_ctx.paginationData)
      ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
          _createVNode(_component_DefaultPagination, {
            paginationMeta: _ctx.paginationData,
            onPageChange: _ctx.handlePageChange
          }, null, 8, ["paginationMeta", "onPageChange"])
        ]))
      : _createCommentVNode("", true)
  ]))
}