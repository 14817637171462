import {nanoid} from 'nanoid';
import {CartInterface, CartState} from '@/greeve/shop/cart/cart.interface';
import {CustomerInterface} from '@/greeve/shop/customer/customer.interface';
import {Customer} from '@/greeve/shop/customer/customer.type';
import {CartItemList} from '@/greeve/shop/cart/item/cart_item_list.type';
import {
	CheckoutSession,
} from '@/greeve/shop/checkout/session/checkout_session.type';
import {Currency} from '@/greeve/core/country/currency.type';
import useCurrencyFactory from '@/composable/core/useCurrencyFactory';
import {
	GreeveProductPriceSubType,
	GreeveProductPriceType,
} from '@/greeve/shop/product/price.interface';
import {CartItem} from '@/greeve/shop/cart/item/cart_item.type';
import {
	DiscountItemList
} from '@/greeve/shop/cart/discount/discount_item_list.type';

export class Cart implements CartInterface {
	id: string;
	state: CartState;
	currency: string;
	customer?: CustomerInterface;
	checkoutSession?: CheckoutSession;
	items: CartItemList;
	discounts: DiscountItemList;
	shipping_amount: number;
	tax_amount: number;
	total_amount: number;
	data?: any;
	created_at?: Date;
	updated_at?: Date;
	recalculateCart = false;

	constructor(
			currency: string, state: CartState, items: CartItemList, discounts: DiscountItemList,
			customer?: Customer, checkoutSession?: CheckoutSession,
			shipping_amount = 0, tax_amount = 0, total_amount = 0, data: any = null,
			created_at?: Date, updated_at?: Date) {
		this.id = nanoid();
		this.state = state;
		this.currency = currency;
		this.customer = customer;
		this.checkoutSession = checkoutSession;
		this.items = items;
		this.discounts = discounts;
		this.shipping_amount = shipping_amount;
		this.tax_amount = tax_amount;
		this.total_amount = total_amount;
		this.data = data;
		this.created_at = created_at;
		this.updated_at = updated_at;
	}

	getCurrencyObject(): Currency {
		return useCurrencyFactory().
		getCurrencyFactory().
		createCurrencyByCode(this.currency);
	}

	private getAmountByCartItems(
			brutto = true, toDecimal = true,
			priceType: GreeveProductPriceType | undefined = undefined,
			priceSubType: GreeveProductPriceSubType | undefined = undefined,
			includeDiscounts = false): number {
		let value = 0;
		this.items?.forEach((item: CartItem) => {
			if (priceType || priceSubType) {
				const price = item.getPrice();
				if (price && priceType && price.type !== priceType) {
					return;
				}
				if (price && priceSubType && price.sub_type !== priceSubType) {
					return;
				}
			}
			if (brutto) {
				value += (item.amount * item.quantity);
			} else {
				//TODO use netto amount
				value += (item.amount * item.quantity);
			}
		});

		if (includeDiscounts && this.hasDiscounts()) {
			this.discounts.getUniqueList().forEach((discount) => {
				value -= (value/100) * discount.getDiscountPercent();
			})
		}

		//TODO check if brutto or netto
		if (toDecimal) {
			value = (value / 100);
		}
		return value;
	}

	hasRecurringProducts(subType: GreeveProductPriceSubType | undefined = undefined): boolean {
		let result = false;
		this.items?.every((item: CartItem) => {
			const price = item.getPrice();
			if (price?.type === GreeveProductPriceType.RECURRING_PRICE) {
				if (subType) {
					if (price.sub_type === subType) {
						result = true;
						return false;
					} else {
						result = false;
						return true;
					}
				} else {
					result = true;
					return false;
				}
			}
			return true;
		});
		return result;
	}

	hasOneTimeProducts(): boolean {
		let result = false;
		this.items?.every((item: CartItem) => {
			const price = item.getPrice();
			if (price?.type === GreeveProductPriceType.ONE_TIME_PRICE) {
				result = true;
				return false;
			}
			return true;
		});
		return result;
	}

	getTotalOneTimePaymentAmount(
			withSymbol = false, startSymbol = true): number | string {
		let value: string | number = this.getAmountByCartItems(false, true,
				GreeveProductPriceType.ONE_TIME_PRICE).toFixed(2);
		if (withSymbol) {
			const symbol = this.getCurrencyObject().getCurrencySymbol();
			if (startSymbol) {
				value = symbol + String(value);
			} else {
				value = String(value) + symbol;
			}
		}
		return value;
	}

	getTotalMonthlyAmount(
			withSymbol = false, startSymbol = true): number | string {
		let value: string | number = this.getAmountByCartItems(false, true,
				GreeveProductPriceType.RECURRING_PRICE,
				GreeveProductPriceSubType.RECURRING_MONTHLY, true).toFixed(2);
		if (withSymbol) {
			const symbol = this.getCurrencyObject().getCurrencySymbol();
			if (startSymbol) {
				value = symbol + String(value);
			} else {
				value = String(value) + symbol;
			}
		}
		return value;
	}

	getTotalYearlyAmount(
			withSymbol = false, startSymbol = true): number | string {
		let value: string | number = this.getAmountByCartItems(false, true,
				GreeveProductPriceType.RECURRING_PRICE,
				GreeveProductPriceSubType.RECURRING_YEARLY, true).toFixed(2);
		if (withSymbol) {
			const symbol = this.getCurrencyObject().getCurrencySymbol();
			if (startSymbol) {
				value = symbol + String(value);
			} else {
				value = String(value) + symbol;
			}
		}
		return value;
	}

	getTotalNettoAmountCalculated(
			withSymbol = false, startSymbol = true): number | string {
		let value: string | number = this.getAmountByCartItems(false).toFixed(2);
		if (withSymbol) {
			const symbol = this.getCurrencyObject().getCurrencySymbol();
			if (startSymbol) {
				value = symbol + String(value);
			} else {
				value = String(value) + symbol;
			}
		}
		return value;
	}

	getTotalAmountCalculated(
			withSymbol = false, startSymbol = true): number | string {
		let value: string | number = this.getAmountByCartItems(true, true, undefined, undefined, true).toFixed(2);
		if (withSymbol) {
			const symbol = this.getCurrencyObject().getCurrencySymbol();
			if (startSymbol) {
				value = symbol + String(value);
			} else {
				value = String(value) + symbol;
			}
		}
		return value;
	}

	hasTaxAmount(): boolean {
		const taxAmount =  (this.getAmountByCartItems(true) -
				this.getAmountByCartItems(false));
		return taxAmount > 0;
	}

	getTotalTaxAmountCalculated(
			withSymbol = false, startSymbol = true): number | string {
		let value: string | number = (this.getAmountByCartItems(true) -
				this.getAmountByCartItems(false)).toFixed(2);
		if (withSymbol) {
			const symbol = this.getCurrencyObject().getCurrencySymbol();
			if (startSymbol) {
				value = symbol + String(value);
			} else {
				value = String(value) + symbol;
			}
		}
		return value;
	}

	isAddressValidated(): boolean {
		if (!this.customer?.email) {
			return false;
		}

		if (!this.customer?.address || !this.customer.address.name ||
				!this.customer.address.city) {
			return false;
		}

		return true;
	}

	hasDiscounts(): boolean {
		return this.discounts && this.discounts.length > 0;
	}

	hasDiscount(discountId: string): boolean {
		if (!this.hasDiscounts()) {
			return false;
		}
		const discountItem = this.discounts.find(({id}) => id === discountId);
		return !!discountItem;
	}
}