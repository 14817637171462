import {
	FlowQueueJournalList,
} from '@/greeve/flow/queue/journal/flow_queue_journal_list.type';
import {
	FlowQueueJournal,
} from '@/greeve/flow/queue/journal/flow_queue_journal.type';

export class FlowQueueJournalFactory {
	/**
	 *
	 * @param journalResponse
	 */
	createFlowQueueJournalListByResponse(journalResponse: Record<string, any>): FlowQueueJournalList {
		const flowQueueJournalList = new FlowQueueJournalList();

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		for (const [key, item] of Object.entries(journalResponse)) {
			flowQueueJournalList.push(this.createFlowQueueJournalByResponse(item));
		}

		return flowQueueJournalList;
	}

	/**
	 *
	 * @param item
	 */
	createFlowQueueJournalByResponse(item: Record<string, any>): FlowQueueJournal {
		const id = item.id;
		const uuid = item.uuid;
		const type = item.type;
		const state = item.state;
		const flow_queue_id = item.flow_queue_id ? item.flow_queue_id : (item.flowQueueId ? item.flowQueueId : undefined);
		const flow_process_id = item.flow_process_id ? item.flow_process_id : (item.flowProcessId ? item.flowProcessId : undefined);
		const reference = item.reference;
		const title = item.title;
		const description = item.description;
		const data = item.data;
		const created_at = item.created_at ? new Date(item.created_at) : (item.createdAt ? new Date(item.createdAt) : new Date());
		const updated_at = item.updated_at ? new Date(item.updated_at) : (item.updatedAt ? new Date(item.updatedAt) : new Date());

		return new FlowQueueJournal(id, uuid, flow_queue_id, type, state,
				flow_process_id, reference, title, description, data, created_at,
				updated_at);
	}
}