import {PaginationMetaInterface} from "@/greeve/core/pagination_meta.interface";
import {
	AbstractDiscountItem
} from '@/greeve/shop/cart/discount/abstract_discount_item.type';

export class DiscountItemList extends Array<AbstractDiscountItem> {

	public paginationMetaData?: PaginationMetaInterface;

	constructor(items: Array<AbstractDiscountItem> = []) {
		super();
		this.push(...items);
	}

	add(items: AbstractDiscountItem) {
		this.push(items);
	}

	remove(index: number) {
		this.splice(index, 1);
	}

	removeAll() {
		const itemCount = this.length;
		this.splice(itemCount, 1);
	}

	getItem(index: number): AbstractDiscountItem {
		return this[index];
	}

	getItemById(item_id: string): AbstractDiscountItem | undefined {
		return this.find(({id}) => id === item_id);
	}

	getUniqueList(): DiscountItemList
	{
		const discountItemList: DiscountItemList|any[] = [...this];
		const uniqueDiscounts: DiscountItemList = new DiscountItemList();
		const seen = new Set();

		for (const discount of discountItemList) {
			if (!seen.has(discount.id)) {
				seen.add(discount.id);
				uniqueDiscounts.push(discount);
			}
		}
		return uniqueDiscounts;
	}

	enhanceMetaData(metaData: any) {
		this.paginationMetaData = metaData;
	}
}