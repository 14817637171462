<template>
	<InfoCard info-key="credits-info" img="https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/images/landing/3d/coinSack.png" :info="$t('creditsDescription')" :title="$t('credits4')" class="mt-2 sm:mt-4"></InfoCard>
	<CreditManagement></CreditManagement>
</template>

<script lang="ts">
import {defineComponent, onMounted} from 'vue';
import useFlow from '@/composable/greeve/useFlow';
import InfoCard from '@/components/modal/InfoCard.vue';
import CreditManagement from "@/components/shop/profile/CreditManagement.vue";

export default defineComponent({
	name: 'CreditsPage',
	components: {
		InfoCard,
		CreditManagement,
	},
	setup() {
		const {flowTemplates, initFlowTemplates} = useFlow();

		onMounted(() => {
			initFlowTemplates(true);
		});

		return {flowTemplates};
	},
});

</script>
<style lang="scss">
</style>

