<template>
	<div v-if="selectList && selected" :class="[minWidth.length > 0 ? minWidth : '']">
		<Listbox as="div" v-model="selected" :class="[showAbove ? 'mb-1 bottom-full' : 'mt-1 top-full']" v-slot="{ open }">
			<ListboxLabel v-if="showLabel" class="block text-sm font-medium leading-6 text-gray-900"
						  v-html="labelText"/>
			<div :class="['relative', topMargin ? 'mt-2' : '', open ? 'z-50' : 'z-10']">
				<ListboxButton
						:class="['relative w-full cursor-default  rounded-lg  ring-1 ring-black ring-opacity-5  bg-white dark:bg-gr-darker py-1.5 pl-3 pr-10 text-left font-medium text-gray-900 dark:text-gray-400 shadow-sm focus:outline-none focus:ring-2 sm:text-sm sm:leading-6', 'focus:ring-'+bgColor]">
					<span class="block truncate">{{ getItemName(selected) }}</span>
					<span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true"/>
        </span>
				</ListboxButton>

				<transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
							leave-to-class="opacity-0">
					<ListboxOptions
							class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-lg bg-white dark:bg-gr-darker py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm pb-28">
			  <ListboxOption as="template" v-for="item in selectList" :key="item"
							 :value="item" v-slot="{ active, selected }">
							<li :class="[active ? getBackgroundColor() : '', active ? 'text-white dark:text-gr-dark' : 'text-gray-900 dark:text-gray-500', 'relative cursor-default select-none py-2 pl-8 pr-4']">

								<div class="flex">
									<span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{
																				getItemName(item)
										}}</span>
									<span v-if="additionalItemDescription"
										  :class="[active ? getDescriptionTextColor() : 'text-gray-500 dark:text-gray-700', 'ml-2 truncate']">€ {{
																				getItemDescription(item)
										}}</span>
								</div>

								<span v-if="selected"
									  :class="[active ? 'dark:text-gray-700 text-gray-900' : getTextColor(), 'absolute inset-y-0 left-0 flex items-center pl-1.5']">
                <CheckIcon class="h-5 w-5" aria-hidden="true"/>
              </span>
							</li>
						</ListboxOption>
					</ListboxOptions>
				</transition>
			</div>
		</Listbox>
	</div>
</template>

<script lang="ts">
import {ref, onMounted, defineComponent, watch, Transition} from 'vue';
import {CheckIcon, ChevronDownIcon} from '@heroicons/vue/20/solid';
import {Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions} from '@headlessui/vue';
import {nanoid} from 'nanoid';
import useSystem from '@/composable/core/useSystem';

export default defineComponent({
	name: 'ListSelectDropdown',
	components: {
		CheckIcon, ChevronDownIcon,
		Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions,
		Transition,
	},
	props: {
		componentId: {type: String, default: nanoid()},
		model: {},
		list: {
			type: Array,
			default: () => [],
		},
		valueKey: {
			type: String,
			default: undefined,
		},
		nameKey: {
			type: String,
			default: undefined,
		},
		additionalItemDescription: {
			type: String,
			default: undefined,
		},
		defaultValue: {
			default: 1,
		},
		labelText: {
			type: String,
			default: '',
		},
		showLabel: {
			type: Boolean,
			default: false,
		},
		showAbove: {
			type: Boolean,
			default: false,
		},
		minWidth: {
			type: String,
			default: '',
		},
		textColor: {
			type: String,
			default: 'gr-primary',
		},
		descriptionTextColor: {
			type: String,
			default: 'gray-700',
		},
		bgColor: {
			type: String,
			default: 'gr-primary',
		},
		topMargin: {
			type: Boolean,
			default: true,
		},
	},
	emits: ['changeSelectedValue'],
	setup(props, {emit}) {
		const selectList: any = ref(props.list);
		const selected: any = ref();
		const itemDataMap: Record<string, any> = ref([]);
		const {areArraysDifferent} = useSystem();


		function getTextColor(): string {
			return 'dark:text-gray-700 text-' + props.textColor;
		}

		function getDescriptionTextColor(): string {
			return 'dark:text-gray-700 text-' + props.descriptionTextColor;
		}

		function getBackgroundColor(): string {
			return 'bg-' + props.bgColor;
		}

		function changeSelectedValue(key: any, value: any) {
			emit('changeSelectedValue', props.model, key, value);
		}

		function removeParentheses(str: string): string {
			return str.replace(/[()]/g, '');
		}

		function isFunctionCallString(str: string): boolean {
			const functionCallPattern = /^[a-zA-Z_$][0-9a-zA-Z_$]*\(\s*\)$/;
			return functionCallPattern.test(str);
		}

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		function getItem(item: any) {
			const valueKey = props.valueKey;
			if (valueKey) {
				if (isFunctionCallString(valueKey)) {
					const methodName = removeParentheses(valueKey);
					return item[methodName]();
				} else {
					return item[valueKey];
				}
			} else {
				return item;
			}
		}

		function getItemValue(item: any) {
			const valueKey = props.valueKey;
			if (valueKey) {
				if (isFunctionCallString(valueKey)) {
					const methodName = removeParentheses(valueKey);
					return item[methodName]();
				} else {
					return item[valueKey];
				}
			} else {
				return item;
			}
		}

		function isItemValueInMap(key: string): boolean {
			if (!itemDataMap.value) {
				return false;
			}
			return itemDataMap.value[key] !== undefined;
		}

		function getItemValueByMap(key: string): string|undefined {
			return itemDataMap.value[key];
		}

		function addItemValueToMap(key: string, value: any): string|undefined {
			return itemDataMap.value[key] = value;
		}

		function getItemName(item: any) {
			const nameKey = props.nameKey;
			if (nameKey) {
				const normalizedKey = removeParentheses(nameKey);
				const itemMapKey = getItemValue(item) + '_' + normalizedKey;
				if (isItemValueInMap(itemMapKey)) {
					return getItemValueByMap(itemMapKey);
				} else {
					let name;
					if (isFunctionCallString(nameKey)) {
						name = item[normalizedKey]();
					} else {
						name = item[normalizedKey];
					}
					addItemValueToMap(itemMapKey, name);
				}
			} else {
				return item;
			}
		}

		function getItemDescription(item: any) {
			const additionalItemDescription = props.additionalItemDescription;
			if (additionalItemDescription) {
				const normalizedKey = removeParentheses(additionalItemDescription);
				const itemMapKey = getItemValue(item) + '_' + normalizedKey;
				if (isItemValueInMap(itemMapKey)) {
					return getItemValueByMap(itemMapKey);
				} else {
					let name;
					if (isFunctionCallString(additionalItemDescription)) {
						name = item[normalizedKey]();
					} else {
						name = item[normalizedKey];
					}
					addItemValueToMap(itemMapKey, name);
				}
			} else {
				return item;
			}
		}

		function initSelectedItem(defaultValue: any = undefined) {
			if (!defaultValue) {
				defaultValue = props.defaultValue;
			}
			const valueKey = props.valueKey;
			if (valueKey) {
				if (isFunctionCallString(valueKey)) {
					const methodName = removeParentheses(valueKey);
					selected.value = selectList.value.find((item: any) => {
						return item[methodName]() === defaultValue;
					});
				} else {
					selected.value = selectList.value.find((item: any) => {
						return item[valueKey] === defaultValue;
					});
				}
			} else {
				selected.value = selectList.value.find((item: any) => item === defaultValue);
			}
		}

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		function getItemByValue(defaultValue: any = undefined) {
			if (!defaultValue) {
				defaultValue = props.defaultValue;
			}
			const valueKey = props.valueKey;
			if (valueKey) {
				if (isFunctionCallString(valueKey)) {
					const methodName = removeParentheses(valueKey);
					return selectList.value.find((item: any) => {
						return item[methodName]() === defaultValue;
					});
				} else {
					return selectList.value.find((item: any) => {
						return item[valueKey] === defaultValue;
					});
				}
			} else {
				return selectList.value.find((item: any) => item === defaultValue);
			}
		}

		watch(() => props.list, (list, oldList) => {
			if (list && oldList && areArraysDifferent(list, oldList)) {
				itemDataMap.value = [];
				selectList.value = list;
				initSelectedItem();
			}
		});

		watch(() => selected.value, (item, oldItem) => {
			if (!oldItem || item !== oldItem) {
				const key = getItemValue(item);
				const value = key;
				changeSelectedValue(key, value);
				// if (value !== props.defaultValue) {
				// }
			}
		});

		onMounted(() => {
			initSelectedItem();
		});

		return {
			selectList,
			selected,
			changeSelectedValue,
			getTextColor,
			getDescriptionTextColor,
			getBackgroundColor,
			getItemValue,
			getItemName,
			getItemDescription,
		};
	},
});
</script>

<style scoped>

</style>
