<template>
	<div class="flex items-center justify-center w-full"
			 @dragover.prevent
			 @dragenter.prevent="handleDragEnter"
			 @dragleave.prevent="handleDragLeave"
			 @drop.prevent="handleDrop"
			 :class="[isDragging ? 'border-blue-500 border-dashed animate-pulse' : '']">
		<div v-show="isLoading" class="pt-2 pb-2 absolute w-full">
			<loading-line-blur-animation class="absolute h-full w-full" v-show="isLoading"></loading-line-blur-animation>
		</div>
		<label for="dropzone-file" :class="[isLoading ? 'cursor-not-allowed opacity-25' : '', 'flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600']">
			<div v-if="previewUrl" class="relative w-full h-full">
				<template v-if="isImage(selectedFile.type)">
					<img :src="previewUrl" alt="Preview" class="object-cover w-full h-full rounded-lg" />
				</template>
				<template v-else-if="isDocument(selectedFile.type)">
					<embed :src="previewUrl" type="application/pdf" class="object-cover w-full h-full rounded-lg" />
				</template>
				<template v-else-if="isAudio(selectedFile.type)">
					<p class="mt-2 text-sm text-gray-600 p-4 pt-1 absolute">{{ selectedFile.name }}</p>
					<audio controls class="w-full h-full absolute">
						<source :src="previewUrl">
						Your browser does not support the audio element.
					</audio>
				</template>
				<button @click="removeFile" class="absolute top-0 right-0 m-2 p-1 bg-red-500 text-white rounded-full hover:bg-red-600 focus:outline-none">
					<svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
						<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
					</svg>
				</button>
			</div>
			<div v-else class="flex flex-col items-center justify-center pt-5 pb-6">
				<svg class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
					<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
				</svg>
				<p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span> or drag and drop</p>
				<p class="text-xs text-gray-500 dark:text-gray-400 p-2">Allowed types: {{ allowedTypes.join(', ') }}</p>
			</div>
			<input id="dropzone-file" type="file" class="hidden" @change="handleFileChange" :accept="uploadInputAcceptList" />
		</label>
		<button v-if="previewUrl && showUploadButton" @click="uploadFileToServer" class="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
			Upload
		</button>
	</div>
</template>

<script lang="ts">
import {computed, defineComponent, ref} from 'vue';
import useAssistant from '@/composable/greeve/useAssistant';
import LoadingLineBlurAnimation from '@/components/animations/LoadingLineBlurAnimation.vue';
import Heic2any from 'heic2any';
import useToastMessage from '@/composable/core/useToastMessage';
import useTranslation from '@/composable/translation/useTranslation';

export default defineComponent({
	name: 'UploadInteraction',
	components: {LoadingLineBlurAnimation},
	props: {
		allowedTypes: {
			type: Array,
			default: () => ['image/jpeg', 'image/png', 'image/gif', 'application/pdf', 'audio/mp3']
		},
		showUploadButton: {
			type: Boolean,
			default: false
		}
	},
	emits: ['onUpload', 'onUploadEnd', 'onUploadError'],
	setup(props, {emit}) {
		const {t} = useTranslation();
		const {uploadFile} = useAssistant();
		const {openToast} = useToastMessage();
		const previewUrl = ref<any>(null);
		const selectedFile = ref<any>(null);
		const isLoading = ref(false);
		const isDragging = ref(false);
		const loadingTimeoutId = ref();

		const uploadInputAcceptList = computed(() => {
				const imageTypes = props.allowedTypes.filter((type: any) => type.startsWith('image/')).join(',');
				const otherTypes = props.allowedTypes.filter((type: any) => !type.startsWith('image/')).join(',');
				return `${imageTypes},${otherTypes}`;
		});

		const handleDragOver = (event: any) => {
			event.preventDefault();
			isDragging.value = true;
		};

		const handleDragEnter = () => {
			isDragging.value = true;
		};

		const handleDragLeave = () => {
			isDragging.value = false;
		};

		const handleDrop = (event: any) => {
			event.preventDefault();
			isDragging.value = false;

			const file = event.dataTransfer.files[0];
			if (!file) return;

			// Handle the dropped file
			handleDroppedFile(file);
			uploadFileToServer(); // Automatically trigger upload after dropping the file
		};

		const handleDroppedFile = (file: Blob) => {
			// Handle the dropped file here
			selectedFile.value = file;
			renderPreview(file);
		};

		const renderPreview = (file: Blob) => {
			const reader = new FileReader();
			reader.onload = (e: any) => {
				previewUrl.value = e.target.result;
			};
			reader.readAsDataURL(file);
		};

		const handleFileChange = async (event: any) => {
			const file = event.target.files[0];
			if (!file) return;

			if (!props.allowedTypes.includes(file.type)) {
				alert('Unsupported file type!');
				return;
			}

			isLoading.value = true;
			loadingTimeoutId.value = setTimeout(() => {
				isLoading.value = false
			}, 500000);

			if (file.type === 'image/heic') {
				try {
					// Convert HEIC to JPEG
					const jpegBlob: any = await Heic2any({
						blob: file,
						toType: 'image/jpeg'
					});
					// Create a new File object from the converted JPEG Blob
					const jpegFile = new File([jpegBlob], `${file.name}.jpg`, { type: 'image/jpeg' });
					// Proceed with the converted JPEG file
					handleConvertedFile(jpegFile);
				} catch (error) {
					isLoading.value = false;
					console.error('Error converting HEIC to JPEG:', error);
					await openToast(t("assistant.output.upload.file.image.error.description"), 'danger', 'top', true, 8000, undefined, true);
				}
			} else {
				// Proceed with the selected file as is
				handleConvertedFile(file);
			}
		};

		const handleConvertedFile = (file: any) => {
			selectedFile.value = file;
			const reader = new FileReader();
			reader.onload = (e: any) => {
				previewUrl.value = e.target.result;
			};
			reader.readAsDataURL(file);

			uploadFileToServer(); // Automatically trigger upload after selecting the file
		};

		const removeFile = (event: any) => {
			event.preventDefault();
			event.stopPropagation();
			previewUrl.value = null;
			selectedFile.value = null;
		};

		const uploadFileToServer = async () => {
			if (!selectedFile.value) return;

			try {
				emit('onUpload', selectedFile.value);
				isLoading.value = true;
				const fileInfo = await uploadFile(selectedFile.value);
				emit('onUploadEnd', fileInfo)
				if (loadingTimeoutId.value) {
					clearTimeout(loadingTimeoutId.value);
				}
				isLoading.value = false;
			} catch (e) {
				console.error(e);
				await openToast(t("assistant.output.upload.file.error.description"), 'danger', 'top', true, 8000, undefined, true);
				if (loadingTimeoutId.value) {
					clearTimeout(loadingTimeoutId.value);
				}
				previewUrl.value = null;
				selectedFile.value = null;
				isLoading.value = false;
				emit('onUploadError', e);
			}
		};

		const isImage = (type: string) => type.startsWith('image');
		const isDocument = (type: string) => type === 'application/pdf';
		const isAudio = (type: string) => type.startsWith('audio');

		return {
			previewUrl,
			handleFileChange,
			removeFile,
			uploadFileToServer,
			isImage,
			isDocument,
			isAudio,
			selectedFile,
			handleDrop,
			isDragging,
			handleDragOver,
			handleDragLeave,
			handleDragEnter,
			isLoading,
			uploadInputAcceptList,
		};
	}
});
</script>

<style>
/* Tailwind styles */
</style>
