export class RouteChecker {
    private readonly routes: string[];

    constructor(routes: string[]) {
        this.routes = routes;
    }

    public isRouteBlocked(currentRoute: string): boolean {
        if (!currentRoute) {
            return false;
        }
        return this.routes.some(route => route.toLowerCase() === currentRoute.toLowerCase());
    }

    public isRouteActive(currentRoute: string): boolean {
        if (!currentRoute) {
            return false;
        }
        return this.routes.includes(currentRoute);
    }
}