import {
	QuotaTransaction
} from '@/greeve/user/quota/transaction/quota_transaction.type';
import {
	QuotaTransactionState,
	QuotaTransactionType,
} from '@/greeve/user/quota/transaction/quota_transaction.interface';
import {QuotaList} from '@/greeve/user/quota/quota_list.type';
import {AbstractQuota} from '@/greeve/user/quota/abstract_quota.type';
import {QuotaType} from '@/greeve/user/quota/quota.interface';
import {QuotaCredit} from '@/greeve/user/quota/credit/quota_credit.type';
import UseUserFactory from '@/composable/greeve/useUserFactory';
import {
	QuotaAssistantFreeChatCredit
} from '@/greeve/user/quota/free/quota_assistant_free_chat_credit.type';
import {
	QuotaAssistantFreeImageCredit
} from '@/greeve/user/quota/free/quota_assistant_free_image_credit.type';

export class QuotaFactory {
	/**
	 *
	 * @param quotas
	 */
	createQuotaListByResponse(quotas: Record<string, any>): QuotaList {
		const quotaList = new QuotaList();

		if (quotas) {
			if (Array.isArray(quotas)) {
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				for (const [key, item] of Object.entries(quotas)) {
					quotaList.push(this.createQuotaByResponse(item));
				}
			} else {
				quotaList.push(this.createQuotaByResponse(quotas));
			}
		}

		return quotaList;
	}

	/**
	 *
	 * @param item
	 */
	createQuotaByResponse(item: Record<string, any>): AbstractQuota {
		return this.createQuotaByType(item);
	}

	createEmptyQuotaTransaction(): QuotaTransaction {
		return new QuotaTransaction(0, '', 0, QuotaTransactionType.QUOTA_TRANSACTION_TYPE_CONSUMED, QuotaTransactionState.QUOTA_TRANSACTION_STATE_DONE, 0);
	}

	createQuotaByType(item: Record<string, any>): AbstractQuota
	{
		switch (item.type) {
			case QuotaType.QUOTA_TYPE_FREE_GREEVE_ASSISTANT_CHAT_CREDITS:
			case QuotaType.QUOTA_TYPE_FREE_GREEVE_ASSISTANT_IMAGE_CREDITS:
				return this.createFreeAssistantQuota(item, item.type);
			case QuotaType.QUOTA_TYPE_CREDITS:
			default:
				return this.createCreditQuota(item);
		}
	}

	createCreditQuota(item: Record<string, any>): QuotaCredit
	{
		const id = item.id;
		const user_id = item.uuid;
		const uuid = item.uuid;
		const type = item.type;
		const state = item.state;
		const total_quota = item.total_quota;
		const available_quota = item.available_quota;
		const available_quota_in_percent = item.available_quota_in_percent;

		let quota_transactions;
		if (item.quota_transactions && item.quota_transactions.length > 0) {
			quota_transactions = UseUserFactory().getQuotaTransactionFactory().createQuotaTransactionListByResponse(item.quota_transactions);
		}
		let quota_journals;
		if (item.quota_journals && item.quota_journals.length > 0) {
			quota_journals = UseUserFactory().getQuotaJournalFactory().createQuotaJournalListByResponse(item.quota_journals);
		}

		const data = item.data;
		const created_at = item.created_at ? new Date(item.created_at) : (item.createdAt ? new Date(item.createdAt) : new Date());
		const updated_at = item.updated_at ? new Date(item.updated_at) : (item.updatedAt ? new Date(item.updatedAt) : new Date());

		return new QuotaCredit(id, user_id, uuid, type, state, total_quota, available_quota, available_quota_in_percent, quota_transactions, quota_journals, data, created_at, updated_at);
	}

	createFreeAssistantQuota(item: Record<string, any>, assistantType: QuotaType.QUOTA_TYPE_FREE_GREEVE_ASSISTANT_CHAT_CREDITS|QuotaType.QUOTA_TYPE_FREE_GREEVE_ASSISTANT_IMAGE_CREDITS): QuotaAssistantFreeChatCredit
	{
		const id = item.id;
		const user_id = item.uuid;
		const uuid = item.uuid;
		const type = item.type;
		const state = item.state;
		const total_quota = item.total_quota;
		const available_quota = item.available_quota;
		const available_quota_in_percent = item.available_quota_in_percent;

		let quota_transactions;
		if (item.quota_transactions && item.quota_transactions.length > 0) {
			quota_transactions = UseUserFactory().getQuotaTransactionFactory().createQuotaTransactionListByResponse(item.quota_transactions);
		}
		let quota_journals;
		if (item.quota_journals && item.quota_journals.length > 0) {
			quota_journals = UseUserFactory().getQuotaJournalFactory().createQuotaJournalListByResponse(item.quota_journals);
		}

		const data = item.data;
		const created_at = item.created_at ? new Date(item.created_at) : (item.createdAt ? new Date(item.createdAt) : new Date());
		const updated_at = item.updated_at ? new Date(item.updated_at) : (item.updatedAt ? new Date(item.updatedAt) : new Date());

		switch (assistantType) {
			case QuotaType.QUOTA_TYPE_FREE_GREEVE_ASSISTANT_CHAT_CREDITS:
				return new QuotaAssistantFreeChatCredit(id, user_id, uuid, type, state, total_quota, available_quota, available_quota_in_percent, quota_transactions, quota_journals, data, created_at, updated_at);
			case QuotaType.QUOTA_TYPE_FREE_GREEVE_ASSISTANT_IMAGE_CREDITS:
				return new QuotaAssistantFreeImageCredit(id, user_id, uuid, type, state, total_quota, available_quota, available_quota_in_percent, quota_transactions, quota_journals, data, created_at, updated_at);
			default:
				throw new Error(`Invalid Free Credits Type ${assistantType}`);
		}
	}
}