import {PaginationMetaInterface} from "@/greeve/core/pagination_meta.interface";
import {Faq} from "@/greeve/faq/faq.type";
import moment from "moment/moment";
import useCustomStore from "@/composable/custom/useCustomStore";

const {getLanguageLocale} = useCustomStore();

export class FaqList extends Array<Faq> {

	public paginationMetaData?: PaginationMetaInterface;

	constructor(items: Array<Faq> = []) {
		super();
		this.push(...items);
	}

	add(items: Faq) {
		this.push(items);
	}

	remove(index: number) {
		this.splice(index, 1);
	}

	removeAll() {
		const itemCount = this.length;
		this.splice(itemCount, 1);
	}

	getItem(index: number): Faq {
		return this[index];
	}

	getItemByOrder(faqOrder: number): Faq | undefined {
		return this.find(({order}) => order === faqOrder);
	}

	enhanceMetaData(metaData: any) {
		this.paginationMetaData = metaData;
	}

	getFaqsByLanguage(language: string|undefined = undefined): FaqList|Faq[]
	{
		if (!language) {
			language = getLanguageLocale.value;
		}
		const faqList: FaqList|any[] = [...this];
		return faqList.filter((faq) => {
			return faq.language === language
		});
	}

	isFaqListOlderThan(minutes = 0): boolean
	{
		let result = false;
		try {
			if (this.length > 0) {
				const lastItem = this.at(-1);

				if (lastItem) {
					const expiresDate = moment(lastItem.created_at).add(minutes, 'm');
					result = moment().isAfter(expiresDate);
				}
			} else {
				result = true;
			}
		} catch (error:any) {
			console.log(error)
		}

		return result;
	}
}