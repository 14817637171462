export const enum QuotaJournalType {
	QUOTA_JOURNAL_TYPE_USED_UP = 'used_up',
	QUOTA_JOURNAL_TYPE_NULLIFIED = 'nullified',
	QUOTA_JOURNAL_TYPE_INFO = 'info',
	QUOTA_JOURNAL_TYPE_DEFAULT = 'default',
}

export const enum QuotaJournalState {
	QUOTA_JOURNAL_STATE_DONE = 'done',
	QUOTA_JOURNAL_STATE_ERROR = 'error',
}

export interface QuotaJournalInterface {
	id: number,
	uuid: string;
	quota_id: number;
	flow_id: number;
	type: QuotaJournalType,
	state: QuotaJournalState,
	reference?: string,
	delta?: number,
	subject?: string,
	description?: string,
	data?: any,
	created_at?: Date,
	updated_at?: Date,
}