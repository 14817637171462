import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "py-1" }
const _hoisted_2 = ["onClick"]

import {Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/vue';
import {EllipsisVerticalIcon} from '@heroicons/vue/20/solid';

import {MenuItemType} from '@/greeve/core/menu_item_type';


// Export the component
const __default__ = {
	name: 'DynamicMenu',
}


export default /*@__PURE__*/_defineComponent({
  ...__default__,
  props: {
	menuItems: {
		type: Object as () => MenuItemType[],
		required: true
	},
	reference: {
		type: String,
	}
},
  emits: ['menu-click'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emits = __emit;

function handleMenuItemClick(item: MenuItemType) {
	emits('menu-click', item, props.reference);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Menu), {
    as: "div",
    class: "relative inline-block text-left"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_unref(MenuButton), { class: "flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-gr-primary focus:ring-offset-2 focus:ring-offset-gray-100" }, {
          default: _withCtx(() => [
            _cache[0] || (_cache[0] = _createElementVNode("span", { class: "sr-only" }, "Open options", -1)),
            _createVNode(_unref(EllipsisVerticalIcon), {
              class: "h-5 w-5",
              "aria-hidden": "true"
            })
          ]),
          _: 1
        })
      ]),
      _createVNode(_Transition, {
        "enter-active-class": "transition ease-out duration-100",
        "enter-from-class": "transform opacity-0 scale-95",
        "enter-to-class": "transform opacity-100 scale-100",
        "leave-active-class": "transition ease-in duration-75",
        "leave-from-class": "transform opacity-100 scale-100",
        "leave-to-class": "transform opacity-0 scale-95"
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(MenuItems), { class: "fixed right-0 sm:absolute sm:left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.menuItems, (item, index) => {
                  return (_openBlock(), _createBlock(_unref(MenuItem), { key: index }, {
                    default: _withCtx(({ active }) => [
                      _createElementVNode("a", {
                        onClick: ($event: any) => (handleMenuItemClick(item)),
                        class: _normalizeClass([active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'flex items-center px-4 py-2 text-sm cursor-pointer'])
                      }, [
                        (item.icon)
                          ? (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), {
                              key: 0,
                              class: "h-4 w-4 mr-2",
                              "aria-hidden": "true"
                            }))
                          : _createCommentVNode("", true),
                        _createTextVNode(" " + _toDisplayString(item.label), 1)
                      ], 10, _hoisted_2)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})