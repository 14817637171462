import {CURRENT_STEP, UPCOMING_STEP} from "@/composable/greeve/useShop";
import useTranslation from "@/composable/translation/useTranslation";
import {StepInterface} from "@/greeve/shop/checkout/steps/step.interface";

export class CheckoutSteps {
	private _steps: Array<StepInterface> = [];

	constructor() {
		const {t} = useTranslation();
		this.steps = [
			{
				id: 1,
				name: t('shop.checkout.login.title'),
				value: 'loginRegisterProcess',
				href: '#',
				status: CURRENT_STEP,
				allowedToGoBack: false,
				allowedToGoForward: false,
				allowedToCloseCheckout: true,
				allowedToShowButton: false,
			},
			{
				id: 2,
				name: t('shop.checkout.contact_information.title'),
				value: 'userInformationProcess',
				href: '#',
				status: UPCOMING_STEP,
				allowedToGoBack: true,
				allowedToGoForward: false,
				allowedToCloseCheckout: false,
				allowedToShowButton: false,
			},
			{
				id: 3,
				name: t('shop.checkout.order_confirm.title'),
				value: 'orderConfirmProcess',
				href: '#',
				status: UPCOMING_STEP,
				allowedToGoBack: true,
				allowedToGoForward: false,
				allowedToCloseCheckout: true,
				allowedToShowButton: true,
			},
		];
	}

	get steps(): Array<StepInterface> {
		return this._steps;
	}

	set steps(value: Array<StepInterface>) {
		this._steps = value;
	}

	getStepByStatus(status: string): StepInterface | undefined {
		return this.steps.find((step) => step.status === status);
	}
}