import {
	SearchItemDefault
} from '@/greeve/search/item/type/search_item_default.type';
import useSystem from '@/composable/core/useSystem';

export const enum ImageModel {
	DALL_E_2 = 'dall-e-2',
	DALL_E_3 = 'dall-e-3',
	AWS_BEDROCK_TITAN_IMAGE = 'amazon.titan-image-generator-v1',
	AWS_BEDROCK_TITAN_IMAGE_V2 = 'amazon.titan-image-generator-v2:0',
	AWS_BEDROCK_STABLE_DIFFUSION = 'aws_stable_diffusion',
}

export const enum ImageSize {
	SIZE_256_256 = '256x256',
	SIZE_512_512 = '512x512',
	SIZE_1024_1024 = '1024x1024',
	SIZE_1024_1792 = '1024x1792',
	SIZE_1792_1024 = '1792x1024',
}

export class SearchItemImage extends SearchItemDefault{

	public getResponse(): any {
		if (!this.response) {
			return undefined;
		}

		let responseObj;
		if (typeof this.response === 'object') {
			responseObj = this.response;
		} else if (useSystem().isJson(this.response)) {
			responseObj = JSON.parse(this.response) || '';
		} else {
			return undefined;
		}

		return responseObj;
	}

	public getContent()
	{
		// "response": "[{\"url\": \"https://images.pexels.com/photos/1574184/pexels-photo-1574184.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1\", \"b64_json\": \"\"}]",
		if (!this.response) {
			return '';
		}

		let responseObj;
		if (typeof this.response === 'object') {
			responseObj = this.response;
		} else if (useSystem().isJson(this.response)) {
			responseObj = JSON.parse(this.response) || '';
		} else {
			return '';
		}

		const imageUrlList: string[] = [];
		responseObj.forEach((item: { url: string }) => {
			imageUrlList.push(item.url);
		});

		let result = '';
		if (imageUrlList.length > 1) {
			result += `<div class="grid my-1 sm:my-0 grid-cols-2 gap-2 sm:gap-4">`;
			imageUrlList.forEach((url) => {
				result += `<div><img class="h-full rounded-xl" src="${url}" /></div>`;
			});
			result += `</div>`;
		} else {
			imageUrlList.forEach((url) => {
				result += `<img class="rounded-xl my-1 sm:my-0" src="${url}" />`;
			});
		}

		return result;
	}
}
