<template>
	<div class="flex flex-col mt-20 md:mt-0 md:space-x-4 md:flex-row">
		<!-- First Column -->
		<div :class="['transition', isFirstColumnVisible ? firstColumnWidthClass : 'w-auto', props.firstColBg ? props.firstColBg : '', props.colRounded ? props.colRounded : '']">
			<div class="flex items-center">
				<button @click="toggleCollapse('first')" class="p-2">
					<ArrowFirstIcon class="w-6 h-6 text-gray-500" />
				</button>
				<slot name="first-title" v-if="isFirstColumnVisible || !isMobile"></slot>
			</div>
			<div :class="{'mt-2': isFirstColumnVisible || !isMobile, 'hidden': isMobile && !isFirstColumnVisible}">
				<slot name="first-content"></slot>
			</div>
		</div>

		<!-- Middle Column -->
		<div :class="['flex-auto', props.middleColBg ? props.middleColBg : '', props.colRounded ? props.colRounded : '', isMobile && (isFirstColumnVisible || isLastColumnVisible) ? 'hidden' : 'block', mdMiddleColumnWidthClass]">
			<div class="mt-2">
				<slot name="middle-title"></slot>
			</div>
			<div class="mt-2">
				<slot name="middle-content"></slot>
			</div>
		</div>

		<!-- Last Column -->
		<div :class="['transition', isLastColumnVisible ? lastColumnWidthClass : 'w-auto', props.lastColBg ? props.lastColBg : '', props.colRounded ? props.colRounded : '']">
			<div class="flex items-center">
				<button @click="toggleCollapse('last')" class="p-2">
					<ArrowLastIcon class="w-6 h-6 text-gray-500" />
				</button>
				<slot name="last-title" v-if="isLastColumnVisible || !isMobile"></slot>
			</div>
			<div :class="{'mt-2': isLastColumnVisible || !isMobile, 'hidden': isMobile && !isLastColumnVisible}">
				<slot name="last-content"></slot>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted, onBeforeUnmount } from 'vue';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/24/outline';

// Define the properties for the component
// eslint-disable-next-line
const props = defineProps<{
	isFirstCollapsed: boolean;
	isLastCollapsed: boolean;
	firstWidth: string;
	lastWidth: string;
	firstColBg: string;
	middleColBg: string;
	lastColBg: string;
	colRounded: string;
}>();

// Reactive state for column visibility
const isFirstColumnVisible = ref(!props.isFirstCollapsed);
const isLastColumnVisible = ref(!props.isLastCollapsed);
const isMobile = ref(window.innerWidth < 768);

// Methods to toggle the visibility of columns
const toggleCollapse = (column: 'first' | 'last') => {
	if (column === 'first') {
		isFirstColumnVisible.value = !isFirstColumnVisible.value;
		if (isFirstColumnVisible.value && isMobile.value) {
			isLastColumnVisible.value = false;
		} else if (!isFirstColumnVisible.value && !isLastColumnVisible.value) {
			isLastColumnVisible.value = true;
		}
	} else if (column === 'last') {
		isLastColumnVisible.value = !isLastColumnVisible.value;
		if (isLastColumnVisible.value && isMobile.value) {
			isFirstColumnVisible.value = false;
		} else if (!isFirstColumnVisible.value && !isLastColumnVisible.value) {
			isFirstColumnVisible.value = true;
		}
	}
};

// Computed properties to determine the icon to show for toggling
const ArrowFirstIcon = computed(() => {
	return isFirstColumnVisible.value ? ChevronLeftIcon : ChevronRightIcon;
});

const ArrowLastIcon = computed(() => {
	return isLastColumnVisible.value ? ChevronRightIcon : ChevronLeftIcon;
});

// Computed properties to determine the width class for the columns
const firstColumnWidthClass = computed(() => {
	return isFirstColumnVisible.value ? `w-${props.firstWidth}` : 'w-auto';
});

const lastColumnWidthClass = computed(() => {
	return isLastColumnVisible.value ? `w-${props.lastWidth}` : 'w-auto';
});

const mdMiddleColumnWidthClass = computed(() => {
	if (!isFirstColumnVisible.value && isLastColumnVisible.value) {
		return 'md:w-3/4';
	} else if (isFirstColumnVisible.value && !isLastColumnVisible.value) {
		return 'md:w-3/4';
	} else if (!isFirstColumnVisible.value && !isLastColumnVisible.value) {
		return 'md:w-full';
	} else {
		return 'md:w-1/2';
	}
});

// Method to check if the screen size is mobile
const checkIsMobile = () => {
	isMobile.value = window.innerWidth < 768;
};

// Add event listener to handle screen resize
onMounted(() => {
	window.addEventListener('resize', checkIsMobile);
	checkIsMobile();
});

// Remove event listener when component is unmounted
onBeforeUnmount(() => {
	window.removeEventListener('resize', checkIsMobile);
});
</script>

<style scoped>
.transition {
    transition: all 0.3s ease;
}
</style>
