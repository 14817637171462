import {PaginationMetaInterface} from '@/greeve/core/pagination_meta.interface';
import {AbstractQuota} from '@/greeve/user/quota/abstract_quota.type';
import {QuotaCredit} from '@/greeve/user/quota/credit/quota_credit.type';
import {QuotaState, QuotaType} from '@/greeve/user/quota/quota.interface';
import {
	QuotaAssistantFreeChatCredit
} from '@/greeve/user/quota/free/quota_assistant_free_chat_credit.type';
import {
	QuotaAssistantFreeImageCredit
} from '@/greeve/user/quota/free/quota_assistant_free_image_credit.type';

export class QuotaList extends Array<AbstractQuota> {

	public paginationMetaData?: PaginationMetaInterface;

	constructor(items: Array<AbstractQuota> = []) {
		super();
		this.push(...items);
	}

	add(items: AbstractQuota) {
		this.push(items);
	}

	remove(index: number) {
		this.splice(index, 1);
	}

	removeAll() {
		const itemCount = this.length;
		this.splice(itemCount, 1);
	}

	hasData(): boolean {
		return this.length > 0;
	}

	enhanceMetaData(metaData: any) {
		this.paginationMetaData = metaData;
	}

	getAvailableQuotaByType(quotaType: QuotaType): AbstractQuota | undefined {
		const quotaList: QuotaList|AbstractQuota[] = [...this];
		return quotaList.find((quota) => {
			let found = true;
			found = quota.getQuotaType() === quotaType;
			if (!found) {
				return found;
			}
			found = quota.state === QuotaState.QUOTA_STATE_AVAILABLE;
			if (!found) {
				return found;
			}
			return quota;
		});
	}

	getAvailableQuotaAmountByType(quotaType: QuotaType): number {
		const creditQuota= this.getAvailableQuotaByType(quotaType);
		if (!creditQuota) {
			return 0;
		}

		return creditQuota.available_quota;
	}

	getAvailableCreditQuota(): QuotaCredit | undefined {
			const quotaList: QuotaList|AbstractQuota[] = [...this];
			return quotaList.find((quota) => {
				let found = true;
				found = quota.getQuotaType() === QuotaType.QUOTA_TYPE_CREDITS;
				if (!found) {
					return found;
				}
				found = quota.state === QuotaState.QUOTA_STATE_AVAILABLE;
				if (!found) {
					return found;
				}
				return quota;
			});
	}

	getAvailableCreditAmount(): number {
		const creditQuota= this.getAvailableCreditQuota();
		if (!creditQuota) {
			return 0;
		}

		return creditQuota.available_quota;
	}

	getAvailableFreeChatQuota(): QuotaAssistantFreeChatCredit | undefined {
			const quotaList: QuotaList|AbstractQuota[] = [...this];
			return quotaList.find((quota) => {
				let found = true;
				found = quota.getQuotaType() === QuotaType.QUOTA_TYPE_FREE_GREEVE_ASSISTANT_CHAT_CREDITS;
				if (!found) {
					return found;
				}
				found = quota.state === QuotaState.QUOTA_STATE_AVAILABLE;
				if (!found) {
					return found;
				}
				return quota;
			});
	}

	getAvailableFreeChatAmount(): number {
		const creditQuota= this.getAvailableFreeChatQuota();
		if (!creditQuota) {
			return 0;
		}

		return creditQuota.available_quota;
	}

	getAvailableFreeImageQuota(): QuotaAssistantFreeImageCredit | undefined {
			const quotaList: QuotaList|AbstractQuota[] = [...this];
			return quotaList.find((quota) => {
				let found = true;
				found = quota.getQuotaType() === QuotaType.QUOTA_TYPE_FREE_GREEVE_ASSISTANT_IMAGE_CREDITS;
				if (!found) {
					return found;
				}
				found = quota.state === QuotaState.QUOTA_STATE_AVAILABLE;
				if (!found) {
					return found;
				}
				return quota;
			});
	}

	getAvailableFreeImageAmount(): number {
		const creditQuota= this.getAvailableFreeImageQuota();
		if (!creditQuota) {
			return 0;
		}

		return creditQuota.available_quota;
	}
}