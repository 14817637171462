import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BookmarkIcon = _resolveComponent("BookmarkIcon")!
  const _component_ArrowDownIcon = _resolveComponent("ArrowDownIcon")!
  const _component_ClipboardIcon = _resolveComponent("ClipboardIcon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("button", {
      type: "button",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.bookMarkAction && _ctx.bookMarkAction(...args))),
      class: "rounded-md px-2 py-1.5 text-sm font-medium dark:text-gray-400 focus:outline-none"
    }, [
      _createVNode(_component_BookmarkIcon, {
        class: _normalizeClass([{'bookmarked': _ctx.isBookMarked}, "w-6 h-6 mx-auto"])
      }, null, 8, ["class"])
    ]),
    (_ctx.shareMode === 'image')
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          type: "button",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.downloadImageByDescription && _ctx.downloadImageByDescription(...args))),
          class: "rounded-md px-2 py-1.5 text-sm font-medium dark:text-gray-400 focus:outline-none"
        }, [
          _createVNode(_component_ArrowDownIcon, { class: "w-6 h-6 mx-auto" })
        ]))
      : (_openBlock(), _createElementBlock("button", {
          key: 1,
          type: "button",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.copyContentToClipboard && _ctx.copyContentToClipboard(...args))),
          class: "rounded-md px-2 py-1.5 text-sm font-medium dark:text-gray-400 focus:outline-none"
        }, [
          _createVNode(_component_ClipboardIcon, { class: "w-6 h-6 mx-auto" })
        ]))
  ]))
}