import {
	FlowMediaInterface,
	MEDIA_MODEL_TYPE,
} from '@/greeve/flow/media/flow_media.interface';


export interface FlowMediaInfo {
	filePath?: string;
	externalFileUrl?: string;
}

export class FlowMedia implements FlowMediaInterface {
	id: number;
	model_type: MEDIA_MODEL_TYPE;
	model_id: number;
	uuid: string;
	collection_name: string;
	name: string;
	file_name: string;
	mime_type: string;
	disk: string;
	conversions_disk: string;
	size: number;
	order_column: number;
	original_url: string;
	preview_url?: string;
	manipulations?: any;
	custom_properties?: any;
	generated_conversions?: any;
	responsive_images?: any;
	data?: any;
	created_at?: Date;
	updated_at?: Date;

	constructor(
			id: number, model_type: MEDIA_MODEL_TYPE, model_id: number, uuid: string, collection_name: string,
			name: string, file_name: string, mime_type: string, disk: string, conversions_disk: string, size: number, order_column: number,
			original_url: string, preview_url?: string | undefined, manipulations?: any | undefined, custom_properties?: any | undefined,
			generated_conversions?: any | undefined, responsive_images?: any | undefined,
			data: any = null, created_at?: Date,
			updated_at?: Date) {
		this.id = id;
		this.model_type = model_type;
		this.model_id = model_id;
		this.uuid = uuid;
		this.collection_name = collection_name;
		this.name = name;
		this.file_name = file_name;
		this.mime_type = mime_type;
		this.disk = disk;
		this.conversions_disk = conversions_disk;
		this.size = size;
		this.order_column = order_column;
		this.original_url = original_url;
		this.preview_url = preview_url;
		this.manipulations = manipulations;
		this.custom_properties = custom_properties;
		this.generated_conversions = generated_conversions;
		this.responsive_images = responsive_images;
		this.data = data;
		this.created_at = created_at;
		this.updated_at = updated_at;
	}

	getFileInfo(): FlowMediaInfo|undefined {
		if (this.custom_properties && this.custom_properties.file_info) {
			return this.custom_properties.file_info;
		}
	}

	getTemporaryUrlExpiredDate(): Date|undefined {
		if (this.custom_properties && this.custom_properties.expires) {
			return new Date(this.custom_properties.expires);
		}
	}

	isUrlExpired(): boolean {
		const temporaryUrlExpiredDate = this.getTemporaryUrlExpiredDate();
		if (!temporaryUrlExpiredDate) {
			return false;
		}

		const currentDate: Date = new Date();
		return temporaryUrlExpiredDate < currentDate;
	}

	getTemporaryUrl(): string|undefined {
		if (this.custom_properties && this.custom_properties.temporary_url) {
			return this.custom_properties.temporary_url;
		}
	}
}