<template>
		<InfoCard info-key="team-info" img="https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/images/landing/3d/chair.png" :info="$t('teamDescription')" :title="$t('team2')" class="mt-2 sm:mt-4"></InfoCard>
		<TeamManagement></TeamManagement>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import InfoCard from '@/components/modal/InfoCard.vue';
import TeamManagement from "@/components/shop/profile/TeamManagement.vue";

export default defineComponent({
	name: 'TemplatePage',
	components: {
		InfoCard,
		TeamManagement,
	},
	setup() {
		return {}
	},
});

</script>
<style lang="scss">
</style>

