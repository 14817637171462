import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "relative lg:min-h-full" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "rounded-2xl nav mx-auto max-w-2xl py-2 px-4 sm:px-6 sm:py-2 lg:grid lg:max-w-7xl lg:grid-cols lg:gap-x-8 lg:px-8 lg:py-32 xl:gap-x-24" }
const _hoisted_4 = { class: "lg:col-start-1 text-center" }
const _hoisted_5 = { class: "text-sm font-medium text-gr-primary" }
const _hoisted_6 = { class: "mt-2 text-4xl font-bold tracking-tight text-gray-900 dark:text-gray-400 sm:text-5xl" }
const _hoisted_7 = { class: "mt-2 text-base text-gray-500" }
const _hoisted_8 = { class: "mt-16 text-sm font-medium" }
const _hoisted_9 = { class: "text-gray-900 dark:text-gray-400" }
const _hoisted_10 = { class: "mt-2 text-gr-primary" }
const _hoisted_11 = { class: "hoverme mt-16 py-6 text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_default_button = _resolveComponent("default-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.successCart)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.$t('paymentSuccessful')), 1),
              _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('thanksForOrdering')), 1),
              _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('weAppreciateYourOrderWereCurrentlyProcessingItSoHa')), 1),
              _createElementVNode("dl", _hoisted_8, [
                _createElementVNode("dt", _hoisted_9, _toDisplayString(_ctx.$t('yourOrderHasBeenConfirmedAndYouWillReceiveAnOrderC')), 1),
                _createElementVNode("dd", _hoisted_10, _toDisplayString(_ctx.order.order_number), 1)
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_default_button, {
                  onClickButton: _ctx.redirectToFlow,
                  "button-text": _ctx.$t('letsStart')
                }, null, 8, ["onClickButton", "button-text"]),
                (_openBlock(), _createElementBlock(_Fragment, null, _renderList(50, (n) => {
                  return _createElementVNode("i", { key: n })
                }), 64))
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}