import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "space-y-2 sm:space-y-4" }
const _hoisted_2 = { class: "sm:overflow-hidden rounded-xl nav backdrop-filter backdrop-blur-3xl" }
const _hoisted_3 = { class: "nav rounded-xl py-6 px-4" }
const _hoisted_4 = { class: "text-lg font-medium leading-6 text-gray-900 dark:text-gray-500" }
const _hoisted_5 = { class: "mt-1 text-sm text-gray-600" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.$t('comingSoon')), 1),
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('manageTeamMembers')), 1)
        ])
      ])
    ])
  ]))
}