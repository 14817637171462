import {
	GreeveProductPriceBillingScheme, GreeveProductPriceSubType,
	GreeveProductPriceType,
	ProductPriceInterface
} from "@/greeve/shop/product/price.interface";
import {Currency} from "@/greeve/core/country/currency.type";
import useCurrencyFactory from "@/composable/core/useCurrencyFactory";

export class Price implements ProductPriceInterface {
	price_id: string;
	type: GreeveProductPriceType;
	sub_type?: GreeveProductPriceSubType;
	active: boolean;
	billing_scheme: GreeveProductPriceBillingScheme;
	amount: number;
	tax_amount: number;
	currency: string;
	unit_amount: number;
	unit_amount_decimal: string;
	object?: string;
	custom_unit_amount?: number;
	recurring?: {
		aggregate_usage?: any,
		interval?: string,
		interval_count?: number,
		trial_period_days?: any,
		usage_type?: string,
	};
	tax_behavior?: string;
	tiers_mode?: any;
	transform_quantity?: {
		divide_by?: number,
		round?: string,
	};
	metadata?: any;
	data?: any;


	constructor(price_id: string, type: GreeveProductPriceType, active: boolean, billing_scheme: GreeveProductPriceBillingScheme, amount: number, tax_amount: number, currency: string, unit_amount: number, unit_amount_decimal: string, object?: string, custom_unit_amount?: number, recurring?: any, tax_behavior?: string, tiers_mode?: any, transform_quantity?: any, metadata?: any, sub_type?: GreeveProductPriceSubType, data?: any) {
		this.price_id = price_id;
		this.type = type;
		this.sub_type = sub_type;
		this.active = active;
		this.billing_scheme = billing_scheme;
		this.amount = amount;
		this.tax_amount = tax_amount;
		this.currency = currency;
		this.unit_amount = unit_amount;
		this.unit_amount_decimal = unit_amount_decimal;
		this.object = object;
		this.custom_unit_amount = custom_unit_amount;
		this.recurring = recurring;
		this.tax_behavior = tax_behavior;
		this.tiers_mode = tiers_mode;
		this.transform_quantity = transform_quantity;
		this.metadata = metadata;
		this.data = data;
	}

	getCurrencyObject(): Currency
	{
		return useCurrencyFactory().getCurrencyFactory().createCurrencyByCode(this.currency);
	}

	getFormattedAmount(withSymbol = false, startSymbol = true): number|string
	{
		let value: string|number = this.unit_amount;
		if (value) {
			value = (value / 100).toFixed(2);
		}
		if (withSymbol) {
			const symbol = this.getCurrencyObject().getCurrencySymbol();
			if (startSymbol) {
				value = symbol + String(value);
			} else {
				value = String(value) + symbol;
			}
		}
		return value;
	}
}