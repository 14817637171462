<!--suppress AllyHtmlVueInspection, AllyHtmlVueInspection -->
<template>
	<ion-page>
		<ion-content>
			<main :style="{ paddingTop: headerHeight + 'px' }">
				<!--Plan Comparison-->
				<section id="GreevePricingPlans" v-if="recurringProductList" class="mt-12 mb-56">
					<div class="max-w-7xl lg:mx-auto">
						<div
								class="max-w-4xl mx-8 lg:mx-auto mb-12 mx-auto font-comfortaa text-stroke text-6xl sm:text-7xl font-bold">
							<span class="">{{ $t('be') }} </span><span class="text-gr-primary">{{ $t('pro') }}</span>{{ $t('doPro') }}
						</div>
						<div style="-webkit-transform: translate3d(0px, 0px, 0px);">
							<div class="text-white box max-w-7xl mx-4 mx-auto">
								<figure class="relative">
									<div class="isolate overflow-hidden">
										<div class="flow-root">
											<div class="mx-auto max-w-7xl px-0 lg:px-8">
												<div class="relative z-10">
													<div class="flex justify-center">

														<RadioGroup v-model="frequency"
																				class="grid grid-cols-2 gap-x-1 rounded-xl nav p-1 text-center text-xs font-medium text-gray-500 dark:text-gray-400">
															<RadioGroupLabel class="sr-only">{{ $t('paymentFrequency') }}
															</RadioGroupLabel>
															<RadioGroupOption as="template" v-for="option in frequencies"
																								:key="option.value" :value="option"
																								v-slot="{ checked }">
																<div
																		:class="[checked ? 'bg-gray-100 dark:bg-gr-darker text-gray-500 dark:text-gray-400 ' : '', 'cursor-pointer rounded-lg py-1 px-2.5']">
																	<span>{{ option.label }}</span>
																</div>
															</RadioGroupOption>
														</RadioGroup>
													</div>
												</div>
												<div
														class="relative mx-auto mt-10 grid max-w-md grid-cols-1 gap-y-8 lg:mx-0 lg:-mb-14 lg:max-w-none lg:grid-cols-3">
													<div
															class="hidden lg:absolute lg:inset-x-px lg:bottom-0 lg:top-4 lg:block lg:rounded-t-2xl lg:bg-black lg:ring-1 lg:ring-white/10"
															aria-hidden="true"/>
													<div v-for="recurringProduct in getRecurringProducts()"
															 :key="recurringProduct.id"
															 :class="[isProductFeatured(recurringProduct) ? 'z-10 bg-white shadow-xl ring-1 ring-gray-900/10' : 'bg-gray-800/80 ring-1 ring-white/10 dark:ring-black/10 lg:bg-transparent lg:pb-14 lg:ring-0', 'relative rounded-2xl']">
														<div class="p-8 lg:pt-12 xl:p-10 xl:pt-14">
															<h3 :id="recurringProduct.id"
																	:class="[isProductFeatured(recurringProduct) ? 'text-gray-900' : 'text-white', 'text-sm font-semibold leading-6']">
																{{ recurringProduct.getProductName() }}</h3>
															<div
																	class="flex flex-col gap-6 sm:flex-row sm:items-end sm:justify-between lg:flex-col lg:items-stretch">
																<div class="mt-2 flex items-center gap-x-4">
																	<p :class="[isProductFeatured(recurringProduct) ? 'text-gray-900' : 'text-white', 'text-4xl font-bold tracking-tight']">
																		{{
																			recurringProduct.price?.getFormattedAmount(false,
																					false)
																		}}</p>
																	<div class="text-sm leading-5">
																		<p :class="isProductFeatured(recurringProduct) ? 'text-gray-900' : 'text-white'">
																			{{ $t('eur') }}</p>
																		<p :class="isProductFeatured(recurringProduct) ? 'text-gray-500' : 'text-gray-400'">
																			{{ $t('shop.product.' + frequency.value) }}</p>
																	</div>
																</div>
																<button @click="buyThisPlan(recurringProduct)"
																				:aria-describedby="recurringProduct.id"
																				:class="[isProductFeatured(recurringProduct) ? 'bg-gr-primary hover:bg-gr-primary2 shadow-sm focus-visible:outline-black' : 'bg-white/10 hover:bg-white/20 focus-visible:outline-white', 'rounded-xl py-2 px-3 text-center text-sm font-semibold leading-6 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2']">
																	{{ $t('buyThisPlan') }}
																</button>
															</div>
															<div class="mt-8 flow-root sm:mt-10">
																<ul role="list"
																		:class="[isProductFeatured(recurringProduct) ? 'divide-gray-900/5 border-gray-900/5 text-gray-600' : 'divide-white/5 border-white/5 text-white', '-my-2 divide-y border-t text-sm leading-6 lg:border-t-0']">
																	<li v-for="mainFeature in recurringProduct.getProductFeatures()"
																			:key="mainFeature" class="flex gap-x-3 py-2">
																		<CheckIcon
																				:class="[isProductFeatured(recurringProduct) ? 'text-black' : 'text-gray-500', 'h-6 w-5 flex-none']"
																				aria-hidden="true"/>
																		{{
																			typeof mainFeature === 'string' ? mainFeature : (mainFeature.source
																					? mainFeature.source
																					: $t('shop.product.' + recurringProduct.price.type + '.' +
																							recurringProduct.product_reference + '.features.' + mainFeature.name))
																		}}
																	</li>
																</ul>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div
												class="relative mx-auto rounded-xl max-w-2xl lg:max-w-7xl bg-gray-50 dark:bg-gr-darkester mt-12 lg:mt-0 lg:pt-14">
											<div class="mx-auto py-6 px-6 lg:px-8">
												<!-- Feature comparison (up to lg) -->
												<section aria-labelledby="mobile-comparison-heading" class="lg:hidden">
													<h2 id="mobile-comparison-heading" class="sr-only">Feature
														comparison</h2>

													<div class="mx-auto max-w-2xl space-y-16">
														<div v-for="recurringProduct in getRecurringProducts()"
																 :key="recurringProduct.id"
																 class="border-t border-gray-900/10">
															<div
																	:class="[isProductFeatured(recurringProduct) ? 'border-black dark:border-gray-400' : 'border-transparent', '-mt-px w-72 border-t-2 pt-10 md:w-80']">
																<h3 :class="[isProductFeatured(recurringProduct) ? 'text-black dark:text-gray-400' : 'text-gray-900 dark:text-gray-400', 'text-sm font-semibold leading-6']">
																	{{ recurringProduct.getProductName() }}</h3>
																<p class="mt-1 text-sm leading-6 text-gray-600">
																	{{ recurringProduct.getProductDescription() }}</p>
															</div>

															<div class="mt-10 space-y-10">
																<div v-for="section in sections" :key="section.name">
																	<h4 class="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-400">
																		{{ section.name }}</h4>
																	<div class="relative mt-6">
																		<!-- Fake card background -->
																		<div aria-hidden="true"
																				 class="absolute inset-y-0 right-0 hidden w-1/2 rounded-lg bg-white dark:bg-gr-dark shadow-sm sm:block"/>

																		<div
																				:class="[isProductFeatured(recurringProduct) ? 'ring-2 ring-black dark:ring-gray-400' : 'ring-1 ring-gray-900/10', 'relative rounded-lg bg-white dark:bg-gr-darker shadow-sm sm:rounded-none sm:bg-transparent sm:shadow-none sm:ring-0']">
																			<dl class="divide-y divide-gray-200 dark:divide-gr-darkester text-sm leading-6">
																				<div v-for="feature in section.features"
																						 :key="feature.reference"
																						 class="flex items-center justify-between py-3 px-4 sm:grid sm:grid-cols-2 sm:px-0">
																					<dt class="pr-4 text-gray-600 dark:text-gray-400">
																						{{ recurringProduct?.getProductFeatureByReference(feature.reference) }}
																					</dt>
																					<dd class="flex items-center justify-end sm:justify-center sm:px-4">
																					<span
																							v-if="typeof feature.tiers[recurringProduct.product_reference] === 'string'"
																							:class="isProductFeatured(recurringProduct) ? 'font-semibold text-black dark:text-gray-400' : 'text-gray-900 dark:text-gray-500'">{{
																							feature.tiers[recurringProduct.product_reference]
																						}}</span>
																						<template v-else>
																							<CheckIcon
																									v-if="feature.tiers[recurringProduct.product_reference] === true"
																									class="mx-auto h-5 w-5 text-black dark:text-gray-400"
																									aria-hidden="true"/>
																							<XMarkIcon v-else
																												 class="mx-auto h-5 w-5 text-gray-400 dark:text-gray-500"
																												 aria-hidden="true"/>
																							<span class="sr-only">{{
																									$t('key', {
																										expr: feature.tiers[recurringProduct.product_reference] ===
																										true ? 'Yes' : 'No',
																									})
																								}}</span>
																						</template>
																					</dd>
																				</div>
																			</dl>
																		</div>

																		<!-- Fake card border -->
																		<div aria-hidden="true"
																				 :class="[isProductFeatured(recurringProduct) ? 'ring-2 ring-black dark:ring-gray-400' : 'ring-1 ring-gray-900/10', 'pointer-events-none absolute inset-y-0 right-0 hidden w-1/2 rounded-lg sm:block']"/>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</section>

												<!-- Feature comparison (lg+) -->
												<section aria-labelledby="comparison-heading" class="hidden lg:block">
													<h2 id="comparison-heading" class="sr-only">{{ $t('featureComparison') }}</h2>

													<div class="grid grid-cols-4 gap-x-8 border-t border-gray-900/10 before:block">
														<div v-for="recurringProduct in getRecurringProducts()"
																 :key="recurringProduct.id" aria-hidden="true"
																 class="-mt-px">
															<div
																	:class="[isProductFeatured(recurringProduct) ? 'border-black dark:border-gray-400' : 'border-transparent', 'border-t-2 pt-10']">
																<p :class="[isProductFeatured(recurringProduct) ? 'text-black dark:text-gray-400' : 'text-gray-900 dark:text-gray-500', 'text-sm font-semibold leading-6']">
																	{{ recurringProduct.getProductName() }}</p>
																<p class="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
																	{{ recurringProduct.getProductDescription() }}</p>
															</div>
														</div>
													</div>

													<div class="-mt-6 space-y-16">
														<div v-for="section in sections" :key="section.name">
															<h3 class="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-500">
																{{ section.name }}</h3>
															<div class="relative -mx-8 mt-10">
																<!-- Fake card backgrounds -->
																<div class="absolute inset-y-0 inset-x-8 grid grid-cols-4 gap-x-8 before:block"
																		 aria-hidden="true">
																	<div class="h-full w-full rounded-lg bg-white dark:bg-gr-dark shadow-sm"/>
																	<div class="h-full w-full rounded-lg bg-white dark:bg-gr-dark shadow-sm"/>
																	<div class="h-full w-full rounded-lg bg-white dark:bg-gr-dark shadow-sm"/>
																</div>

																<table class="relative w-full border-separate border-spacing-x-8">
																	<thead>
																	<tr class="text-left">
																		<th scope="col">
																			<span class="sr-only">{{ $t('feature') }}</span>
																		</th>
																		<th v-for="recurringProduct in getRecurringProducts()"
																				:key="recurringProduct.id"
																				scope="col">
																			<span class="sr-only">{{$t('tier', {getProductName: recurringProduct.getProductName()}) }}</span>
																		</th>
																	</tr>
																	</thead>
																	<tbody>
																	<tr v-for="(feature, featureIdx) in section.features"
																			:key="feature.reference">
																		<th scope="row"
																				class="w-1/4 py-3 pr-4 text-left text-sm font-normal leading-6 text-gray-900 dark:text-gray-400">
																			{{ feature.name }}
																			<div v-if="featureIdx !== section.features.length - 1"
																					 class="absolute inset-x-8 mt-3 h-px bg-gray-200 dark:bg-gr-darkester"/>
																		</th>
																		<td v-for="recurringProduct in getRecurringProducts()"
																				:key="recurringProduct.id"
																				class="relative w-1/4 px-4 py-0 text-center">
                        <span class="relative h-full w-full py-3">
                          <span v-if="typeof feature.tiers[recurringProduct.product_reference] === 'string'"
																:class="[isProductFeatured(recurringProduct) ? 'font-semibold text-black dark:text-gray-400' : 'text-gray-900 dark:text-gray-500', 'text-sm leading-6 dark:text-gray-500']">{{
															feature.tiers[recurringProduct.product_reference]
														}}</span>
                          <template v-else>
                            <CheckIcon v-if="feature.tiers[recurringProduct.product_reference] === true"
																			 class="mx-auto h-5 w-5 text-black dark:text-gray-500" aria-hidden="true"/>
                            <XMarkIcon v-else class="mx-auto h-5 w-5 text-gray-400 dark:text-gray-500"
																			 aria-hidden="true"/>
                            <span class="sr-only">{{$t('key', {expr: feature.tiers[recurringProduct.product_reference] === true ? 'Yes' : 'No'}) }}</span>
                          </template>
                        </span>
																		</td>
																	</tr>
																	</tbody>
																</table>

																<!-- Fake card borders -->
																<div
																		class="pointer-events-none absolute inset-y-0 inset-x-8 grid grid-cols-4 gap-x-8 before:block"
																		aria-hidden="true">
																	<div v-for="recurringProduct in getRecurringProducts()"
																			 :key="recurringProduct.id"
																			 :class="[isProductFeatured(recurringProduct) ? 'ring-2 ring-black dark:ring-gray-500' : 'ring-1 ring-gray-900/10', 'rounded-lg']"/>
																</div>
															</div>
														</div>
													</div>
												</section>
											</div>
										</div>
									</div>
								</figure>
							</div>
						</div>
					</div>
				</section>

				<!--One Time Purchase TODO DISABLED!-->
				<!--			<section id="GreeveOneTime" class="mt-24 mb-56" v-if="oneTimeProductList">-->
				<!--				<div class="max-w-4xl pb-28 mx-auto">-->
				<!--					<div class="mx-4 lg:mx-0 font-comfortaa text-stroke text-5xl sm:text-7xl font-bold mb-12">-->
				<!--						<span class="">{{ $t('oneTimePurchase') }}</span><span class="text-gr-primary"></span>-->
				<!--					</div>-->
				<!--					<div class="mx-auto">-->
				<!--						<div class="shadow-xl rounded-2xl nav mx-4  p-8 lg:pt-12 xl:p-10 xl:pt-14 sm:w-1/2">-->
				<!--							<h3 id="tier-scale"-->
				<!--								class="text-gray-900 dark:text-gray-500 text-sm font-semibold leading-6">-->
				<!--								{{ $t('shop.product.features.credits') }}</h3>-->
				<!--							<div class="flex flex-col gap-4  sm:items-end sm:justify-between lg:flex-col lg:items-stretch" v-if="selected">-->

				<!--								<Listbox class="w-full" as="div" v-model="selected">-->
				<!--									<div class="mt-2 flex items-center gap-x-4"><p-->
				<!--											class="text-gray-900 dark:text-gray-400 text-4xl font-bold tracking-tight">-->
				<!--										{{ selected.price?.getFormattedAmount(false, false) }}</p>-->
				<!--										<div class="text-sm leading-5 "><p class="text-gray-900 dark:text-gray-400">-->
				<!--											{{ $t('eur') }}</p>-->
				<!--											<p class="text-gray-500">{{ $t('oneTimePurchase') }}</p></div>-->
				<!--									</div>-->
				<!--									<div class="relative mt-2">-->
				<!--										<ListboxButton-->
				<!--												class="relative w-full bg-gray-200  dark:bg-gr-dark cursor-default rounded-xl py-1.5 pl-3 pr-10 text-left text-gray-900 dark:text-gray-400 font-semibold shadow-sm ring-2 ring-inset ring-gray-200 dark:ring-gr-dark focus:outline-none focus:ring-2 sm:text-sm sm:leading-6">-->
				<!--        <span class="inline-flex w-full truncate">-->
				<!--          <span class="truncate">{{ selected.getProductName() }}</span>-->
				<!--			&lt;!&ndash;          <span class="ml-2 truncate text-gray-500">{{ selected.points }}</span>&ndash;&gt;-->
				<!--        </span> <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">-->
				<!--          <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true"/>-->
				<!--        </span>-->
				<!--										</ListboxButton>-->
				<!--										<transition leave-active-class="transition ease-in duration-100"-->
				<!--													leave-from-class="opacity-100" leave-to-class="opacity-0">-->
				<!--											<ListboxOptions-->
				<!--													class="absolute z-10 mt-1.5 max-h-60 w-full overflow-auto rounded-xl bg-white dark:text-gray-400 dark:bg-gr-dark text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">-->
				<!--												<ListboxOption as="template" v-for="product in getOneTimeProducts()"-->
				<!--															   :key="product.id"-->
				<!--															   :value="product" v-slot="{ active, selected }">-->
				<!--													<li :class="[active ? 'dark:bg-gr-darker bg-gray-200' : 'dark:text-gray-400 text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">-->
				<!--														<div class="flex">-->
				<!--															<span :class="[selected ? 'font-semibold' : 'font-normal', 'truncate']">{{-->
				<!--																	product.getProductName()-->
				<!--								  }}</span>-->
				<!--															<span :class="[selected ? 'font-semibold' : 'font-normal', 'truncate']">{{-->
				<!--																																product.getProductFeatures()-->
				<!--																}}</span>-->
				<!--															<span :class="[active ? 'dark:text-gray-400 text-gray-700' : 'text-gray-500', 'ml-2 truncate']">€ {{-->
				<!--																																product.price?.getFormattedAmount(false, false)-->
				<!--																}}</span>-->
				<!--														</div>-->
				<!--														<span v-if="selected"-->
				<!--															  :class="[active ? 'dark:text-gray-400 text-gray-900' : 'dark:text-gray-400 text-gray-900', 'absolute inset-y-0 right-0 flex items-center pr-4']">-->
				<!--                <CheckIcon class="h-5 w-5" aria-hidden="true"/>-->
				<!--              </span>-->
				<!--													</li>-->
				<!--												</ListboxOption>-->
				<!--											</ListboxOptions>-->
				<!--										</transition>-->
				<!--									</div>-->
				<!--								</Listbox>-->

				<!--								<button @click="addSelectedOneTimeToCart" aria-describedby="tier-scale"-->
				<!--										class="w-full bg-gr-primary hover:bg-gr-primary2 shadow-sm focus-visible:outline-black rounded-xl py-2 px-3 text-center text-sm font-semibold leading-6 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">-->
				<!--									{{ $t('buyNow') }}-->
				<!--								</button>-->
				<!--							</div>-->
				<!--						</div>-->
				<!--					</div>-->
				<!--				</div>-->
				<!--			</section>-->
				<!--Footer-->
				<FooterBar class="mt-12"></FooterBar>
			</main>
		</ion-content>
	</ion-page>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref, watch} from 'vue';
import {
	RadioGroup,
	RadioGroupLabel,
	RadioGroupOption,
} from '@headlessui/vue';
import {IonContent, IonPage} from '@ionic/vue';
import FooterBar from '@/components/positions/FooterBar.vue';
import {CheckIcon, XMarkIcon} from '@heroicons/vue/20/solid';
import useShop from '@/composable/greeve/useShop';
import {GreeveProductPriceSubType, GreeveProductPriceType} from '../../greeve/shop/product/price.interface';
import {GreeveProductReference} from '@/greeve/shop/product/product.interface';
import {ProductList} from '@/greeve/shop/product/product_list.type';
import {Product} from '@/greeve/shop/product/product.type';
import {useRouter} from 'vue-router';
import useTranslation from '@/composable/core/useTranslation';
import {useHeaderHeight} from '@/composable/global/useHeaderHeight';

export default defineComponent({
	name: 'ShopPlans',
	components: {
		RadioGroup, RadioGroupLabel, RadioGroupOption,
		FooterBar, IonContent, IonPage, CheckIcon, XMarkIcon,
	},
	setup() {
		const {headerHeight} = useHeaderHeight();
		const router = useRouter();
		const {
			products,
			isLastProductRefreshOlderThan,
			initProducts,
			addProductToCart,
			addRecurringProductToCart,
		} = useShop();
		const {t} = useTranslation();
		const oneTimeProductList: ProductList | any = ref();
		const recurringProductList: ProductList | any = ref();
		const isLoading = ref(false);

		const selectedFrequency: GreeveProductPriceSubType | any = ref(GreeveProductPriceSubType.RECURRING_MONTHLY);
		const frequencies = [
			{
				value: GreeveProductPriceSubType.RECURRING_MONTHLY,
				label: t('shop.product.' + GreeveProductPriceSubType.RECURRING_MONTHLY),
			},
			{
				value: GreeveProductPriceSubType.RECURRING_YEARLY,
				label: t('shop.product.' + GreeveProductPriceSubType.RECURRING_YEARLY),
			},
		];
		const frequency = ref(frequencies[0]);

		const sections = [
			{
				name: t('shop.product.feature_name'),
				features: [
					{
						reference: 'credits',
						name: t('shop.product.features.credits'),
						tiers: {
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_STARTER_PLAN]: '30',
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_BUSINESS_PLAN]: '500',
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN]: '200',
						},
					},
					{
						reference: 'flows',
						name: t('shop.product.features.flows'),
						tiers: {
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_STARTER_PLAN]: true,
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_BUSINESS_PLAN]: true,
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN]: true,
						},
					},
					{
						reference: 'extendable_credits',
						name: t('shop.product.features.extendable_credits'),
						tiers: {
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_STARTER_PLAN]: true,
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_BUSINESS_PLAN]: true,
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN]: true,
						},
					},
					{
						reference: 'multi_accounts',
						name: t('shop.product.features.multi_accounts'),
						tiers: {
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_STARTER_PLAN]: false,
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_BUSINESS_PLAN]: 'up to 7 accounts',
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN]: '3 accounts',
						},
					},
					{
						reference: 'api_access',
						name: t('shop.product.features.api_access'),
						tiers: {
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_STARTER_PLAN]: false,
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_BUSINESS_PLAN]: true,
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN]: true,
						},
					},
					{
						reference: 'api_calls',
						name: t('shop.product.features.api_calls'),
						tiers: {
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_STARTER_PLAN]: '0',
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_BUSINESS_PLAN]: 'unlimited',
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN]: '20.000',
						},
					},
					{
						reference: 'multi_access_token',
						name: t('shop.product.features.multi_access_token'),
						tiers: {
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_STARTER_PLAN]: false,
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_BUSINESS_PLAN]: 'up to 2 Tokens',
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN]: false,
						},
					},
					{
						reference: 'support_standard',
						name: t('shop.product.features.support_standard'),
						tiers: {
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_STARTER_PLAN]: true,
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_BUSINESS_PLAN]: true,
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN]: true,
						},
					},
					{
						reference: 'support_premium',
						name: t('shop.product.features.support_premium'),
						tiers: {
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_STARTER_PLAN]: false,
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_BUSINESS_PLAN]: true,
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN]: false,
						},
					},
					{
						reference: 'employee_training',
						name: t('shop.product.features.employee_training'),
						tiers: {
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_STARTER_PLAN]: false,
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_BUSINESS_PLAN]: 'up to 10 Employees',
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN]: false,
						},
					},
					{
						reference: 'secure_plus',
						name: t('shop.product.features.secure_plus'),
						tiers: {
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_STARTER_PLAN]: false,
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_BUSINESS_PLAN]: true,
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN]: false,
						},
					},
				],
			},
		];

		const selected: Product | any = ref();

		function addSelectedOneTimeToCart() {
			if (selected.value) {
				addProductToCart(selected.value.product_id);
			}
			router.push('/shop');
		}

		function isProductFeatured(product: Product): boolean {
			return product.product_reference === GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN;
		}

		function buyThisPlan(product: Product) {
			addRecurringProductToCart(product.product_id, product.price.sub_type);
			router.push('/shop');
		}

		function getOneTimeProducts(force = false) {
			if (!oneTimeProductList.value || force) {
				oneTimeProductList.value = products.value.getOneTimeProductList();
				selected.value = oneTimeProductList.value[1];
			}
			return oneTimeProductList.value;
		}

		watch(frequency, (frequence, oldFrequence) => {
			if (frequence.value !== oldFrequence.value) {
				getRecurringProducts(true);
			}
		});

		function sortRecurringProductList() {
			recurringProductList.value = recurringProductList.value.sort((a: Product, b: Product) => {
				if (a.product_reference === GreeveProductReference.STRIPE_PRODUCT_RECURRING_STARTER_PLAN) return -1;
				if (b.product_reference === GreeveProductReference.STRIPE_PRODUCT_RECURRING_STARTER_PLAN) return 1;
				if (a.product_reference === GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN) return -1;
				if (b.product_reference === GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN) return 1;

				return 0;
			});
		}

		function getRecurringProducts(force = false): ProductList {
			if ((!recurringProductList.value || force) && !isLoading.value) {
				isLoading.value = true;
				initProducts(true).then(() => {
					if (!recurringProductList.value || force) {
						const subType = frequency.value.value;
						recurringProductList.value = products.value.getProductListByPriceTypeReferenceList(
								GreeveProductPriceType.RECURRING_PRICE, subType, [
									GreeveProductReference.STRIPE_PRODUCT_RECURRING_STARTER_PLAN,
									GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN,
									GreeveProductReference.STRIPE_PRODUCT_RECURRING_BUSINESS_PLAN]);
						sortRecurringProductList();
					}
					isLoading.value = false;
				}).catch((e) => {
					console.error(e);
					isLoading.value = false;
				});
			} else if (!recurringProductList.value) {
				const subType = frequency.value.value;
				recurringProductList.value = products.value.getProductListByPriceTypeReferenceList(
						GreeveProductPriceType.RECURRING_PRICE, subType, [
							GreeveProductReference.STRIPE_PRODUCT_RECURRING_STARTER_PLAN,
							GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN,
							GreeveProductReference.STRIPE_PRODUCT_RECURRING_BUSINESS_PLAN]);
				sortRecurringProductList();
			}

			return recurringProductList.value;
		}

		async function initProductsForView() {
			getOneTimeProducts();
			getRecurringProducts();
		}

		onMounted(() => {
			try {
				isLoading.value = true;
				if (!products.value || products.value.length === 0 || isLastProductRefreshOlderThan(12)) {
					initProducts(true).then(() => {
						initProductsForView().then(() => {
							isLoading.value = false;
						});
					});
				} else {
					initProductsForView().then(() => {
						isLoading.value = false;
					});
				}
			} catch (e) {
				console.error(e);
			}
		});

		return {
			selected,
			frequency,
			frequencies,
			sections,
			products,
			getRecurringProducts,
			getOneTimeProducts,
			selectedFrequency,
			addSelectedOneTimeToCart,
			isProductFeatured,
			buyThisPlan,
			recurringProductList,
			oneTimeProductList, headerHeight,
		};
	},
});

</script>
<style lang="scss">
</style>
