import {computed, ComputedRef, onBeforeMount, ref} from "vue";
import store from "@/store";
import {CountryList} from "@/greeve/core/country/country_list.type";
import {Currency} from "@/greeve/core/country/currency.type";
import useCurrencyFactory from "@/composable/core/useCurrencyFactory";

export default function useCurrency() {
	const countries: ComputedRef<CountryList|undefined> = computed(() => store.getters['country/getCountries']);
	const activeCurrency: Currency[]|any = ref([]);

	function getActiveCurrencies(): Currency[] {
		if (activeCurrency.value.length === 0) {
			activeCurrency.value = useCurrencyFactory().getCurrencyFactory().createActiveCurrencyList();
		}
		return activeCurrency.value;
	}

	onBeforeMount((forceRefresh = false) => {
		if (activeCurrency.value.length === 0 || forceRefresh) {
			activeCurrency.value = useCurrencyFactory().getCurrencyFactory().createActiveCurrencyList();
		}
	});

	return {
		countries,
		activeCurrency,
		getActiveCurrencies,
	}
}
