import {CountryList} from "@/greeve/core/country/country_list.type";
import useCountryFactory from "@/composable/core/useCountryFactory";

const {getCountryFactory} = useCountryFactory();

/* eslint-disable @typescript-eslint/no-unused-vars */

const country = {
	namespaced: true,
	state: () => ({
		countries: CountryList,
	}),
	mutations: {
		setCountries(state: any, countryList: CountryList) {
			state.countries = countryList;
		},
	},
	actions: {
	},
	getters: {
		getCountries: (state: any): CountryList|undefined => {
			if (state.countries instanceof CountryList) {
				return state.countries;
			} else if (!(state.countries instanceof CountryList)) {
				return getCountryFactory().createCountryListByResponse(state.countries);
			} else {
				return undefined;
			}
		},
	}
}

export default country;