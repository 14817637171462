import {FlowProcessList} from '@/greeve/flow/process/flow_process_list.type';
import {
	FlowProcessDependencyList
} from '@/greeve/flow/process/dependency/flow_process_dependency_list.type';
import {FlowQueueList} from '@/greeve/flow/queue/flow_queue_list.type';

export const enum FlowState {
	ACTIVE = 'active',
	DELETED = 'deleted',
	DONE = 'done',
	DISABLED = 'disabled',
	ERROR = 'error',
	RUNNING = 'running',
	NEW = 'new',
}

export const enum FlowType {
	DEFAULT = 'default',
	TEMPLATE = 'template',
	CUSTOM = 'custom',
}

export const enum FlowSubType {
	WHATSAPP_DEFAULT_NEWSLETTER = 'whatsapp_default_newsletter',
	WHISPER_TEMPLATE = 'whisper_template',
	WHISPER_VIDEO_TEMPLATE = 'whisper_video_template',
}

export const enum FlowWizzardState {
	NEW = 'new',
	ERROR = 'error',
	READY = 'ready',
}


export interface FlowInterface {
	id: number,
	uuid: string,
	user_id: number,
	flow_config_id?: number|null,
	// flow_config?: FlowConfig|null,
	flow_processes?: FlowProcessList|null,
	flow_process_dependencies?: FlowProcessDependencyList|null,
	flow_queues?: FlowQueueList|null,
	//TODO FlowProcessDepenendcy_List -> getVueFlow_by_FlowProcessDependency...
	parent_id?: number|null,
	type: FlowType,
	sub_type?: FlowSubType|null,
	state: FlowState,
	reference?: string|null,
	name?: string|null,
	description?: string|null,
	data?: any,
	created_at?: Date,
	updated_at?: Date,
}