<template>
	<div>
		<div>
			<DangerAlert v-show="showAlerts && errorMessage" :error-message="errorMessage" :error-title="errorTitle" class="pb-8"
									 :show-title="true" @closeAlert="hideErrorMessage"></DangerAlert>

			<SwitchComponent v-show="showSwitch"
											 wrapper="cursor-pointer rounded-t-3xl mb-12 rounded-3xl shadow-[inset_0_-2px_4px_rgba(0,0,0,0.6)] p-0 m-0 justify-center pb-0 flex space-x-0 text-md"
											 style-inactive="rounded-xl bg-bp-complementDarker text-white dark:text-gray-300 shadow-3xl text-center w-full p-3"
											 style-active="rounded-xl text-gray-700 dark:text-gray-400 text-center w-full p-3"
											 :tabs="tabs"></SwitchComponent>
			<form class="space-y-3 -mt-5" @submit.prevent="onSubmit">
				<input-nick-mail v-model:email="user.email" :error-message="user.emailError"/>
				<input-password v-model:password="user.password" :show-password-strengtho-meter="false"
												:error-message="user.passwordError" input-placeholder="Password"/>
				<DefaultButton :button-text="$t('auth.LoginComponent.loginBtnLogin')" button-type="submit"
											 button-width="w-full" style="margin-block: initial; margin-top: 1.2rem;"></DefaultButton>
				<div class="text-xs sm:text-sm text-left">
					<LinkButton :button-text="$t('auth.LoginComponent.loginBtnForgot')" :center-button="false"
											button-alignment="" button-color="bg-transparent"
											button-hover="" button-width=""
											custom-css="flex p-0 m-0" link="/forgot-password" button-x-padding="pl-6"
											text-alignment="text-left" button-y-padding="pt-2 pb-0"
											text-color="text-gray-600"></LinkButton>
				</div>

				<div class="mt-8">
					<div>
						<div class="relative mt-6">
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent, reactive} from 'vue';
import InputNickMail from '@/components/inputs/InputNickMail.vue';
import InputPassword from '@/components/inputs/InputPassword.vue';
import DefaultButton from '@/components/core/DefaultButton.vue';
import LinkButton from '@/components/core/LinkButton.vue';
import useTranslation from '@/composable/translation/useTranslation';
import useAlert from '@/composable/core/useAlert';
import useUser from '@/composable/greeve/useUser';
import useAuth from '@/composable/auth/useAuth';
import {GreeveApiAuth} from '@/greeve/api/auth';
import SwitchComponent from '@/components/core/SwitchComponent.vue';
import DangerAlert from '@/components/alert/Base/DangerAlert.vue';
import useAssistant from '@/composable/greeve/useAssistant';
import useCustomStore from '@/composable/custom/useCustomStore';
import router from '@/router';

export default defineComponent({
	name: 'LoginComponent',
	components: {
		DangerAlert,
		SwitchComponent,
		LinkButton,
		DefaultButton,
		InputNickMail,
		InputPassword,
	},
	props: {
		redirectAfterSubmit: {
			type: Boolean,
			default: false,
		},
		showSwitch: {
			type: Boolean,
			default: true,
		},
		showAlerts: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['onLoginSuccess', 'onLoginError'],
	setup(props, {emit}) {
		const {clearErrorAlert} = useAlert();
		const {initUserByUserType, initQuotas, initSubscriptions, initLastUserAddress} = useUser();
		const {initSearchGroups} = useAssistant();
		const { setIsGlobalLoading } = useCustomStore();

		const {
			errorMessage,
			errorTitle,
			clearErrorMessage,
			getDeviceHash,
		} = useAuth();

		const user = reactive({
			email: '',
			password: '',
			emailError: '',
			passwordError: '',
		});

		const {t} = useTranslation();

		function sendLoginSuccessEvent() {
			setIsGlobalLoading(false);
			emit('onLoginSuccess');
		}

		function sendLoginErrorEvent() {
			setIsGlobalLoading(false);
			emit('onLoginError');
		}

		function hideErrorMessage()
		{
			clearErrorAlert();
			errorMessage.value = '';
			errorTitle.value = '';
		}

		async function onSubmit() {
			const loginData = {
				email: user.email,
				password: user.password,
				device_name: getDeviceHash(),
			};
			try {
				setIsGlobalLoading(true);
				clearErrorAlert();
				const token = await GreeveApiAuth.signIn(loginData);
				if (token) {
					const userResponse = await GreeveApiAuth.getUser();
					if (userResponse && userResponse.id) {
						initUserByUserType(userResponse);
						clearErrorAlert();
						try {
							initSearchGroups(true, false);
							initQuotas(true);
							initSubscriptions(true);
							initLastUserAddress();
						} catch (e) {
							console.error(e);
						}
						if (props.redirectAfterSubmit) {
							router.push('/');
						}
						sendLoginSuccessEvent();
					}
				}
			} catch (e) {
				console.error(e);
				errorTitle.value = t('auth.auth_error_failed_title');
				errorMessage.value = t('auth.failed');
				sendLoginErrorEvent();
				setIsGlobalLoading(false);
			}

			// 	return GreeveApiAuth.signIn(loginData).then(async (token) => {
			// 		if (!token) {
			// 			return;
			// 		}
			// 		GreeveApiAuth.getUser().then(async (userResponse) => {
			// 			if (userResponse && userResponse.id) {
			// 				initUserByUserType(userResponse);
			// 				clearErrorAlert();
			// 				try {
			// 					initQuotas(true);
			// 					initSubscriptions(true);
			// 					initLastUserAddress();
			// 					initSearchGroups(true, false).then(() => {
			// 						if (props.redirectAfterSubmit) {
			// 							router.push('/');
			// 						}
			// 						sendLoginSuccessEvent();
			// 					});
			// 				} catch (e) {
			// 					console.error(e);
			// 				}
			// 			}
			// 		});
			// 	})
			// } catch (e) {
			// 	console.error(e);
			// 	errorTitle.value = t('auth.auth_error_failed_title');
			// 	errorMessage.value = t('auth.failed');
			// 	sendLoginErrorEvent();
			// 	setIsGlobalLoading(false);
			// }
		}

		return {
			tabs: [
				{name: t('auth.LoginComponent.loginTitle'), link: '/login', current: true},
				{name: t('auth.RegisterComponent.registerTitle'), link: '/register', current: false},
			],
			user,
			onSubmit,
			errorMessage,
			errorTitle,
			clearErrorMessage,
			hideErrorMessage,
		};

	},
});
</script>

<style scoped>
.btn-forgot-pw {
	align-self: flex-start;
	letter-spacing: 1.33px;
	line-height: 18px;
	margin-left: 106px;
	margin-top: 31px;
	min-height: 27px;
	width: 350px;
}

.btn-login {
	align-items: flex-end;
	background-color: var(--west-side);
	border-radius: 25px;
	display: flex;
	height: 49px;
	justify-content: flex-end;
	margin-top: 46px;
	min-width: 194px;
	padding: 6px 4px;
}

.btn-register {
	align-items: flex-start;
	display: flex;
	height: 49px;
	left: 69px;
	min-width: 196px;
	position: absolute;
	top: -91px;
}

.div {
	background-color: var(--white);
	border-radius: 25px;
	height: 49px;
	left: 0;
	position: absolute;
	top: 0;
	width: 194px;
}

.google-login {
	height: 56px;
	margin-top: 8px;
	object-fit: cover;
	width: 56px;
}

.login {
	-webkit-text-stroke: 1px var(--white);
	letter-spacing: 1.78px;
	line-height: 24px;
	min-height: 35px;
	min-width: 184px;
	text-align: center;
	width: 184px;
}

.logindesign1290 {
	align-items: flex-start;
	background-color: var(--black-haze);
	display: flex;
	height: 1080px;
	padding: 0 239px;
	width: 1290px;
}

.ornament {
	align-items: flex-start;
	display: flex;
	height: 188px;
	justify-content: center;
	left: -239px;
	min-width: 811px;
	position: absolute;
	top: -172px;
}

.ornament-front {
	height: 64px;
	object-fit: cover;
	width: 383px;
}

.overlap-group {
	align-items: center;
	background-color: var(--white);
	border-radius: 57px;
	box-shadow: -7px 3px 24px #00000012;
	display: flex;
	flex-direction: column;
	margin-top: 172px;
	min-height: 857px;
	position: relative;
	width: 812px;
}

.overlap-group1 {
	align-self: flex-end;
	height: 188px;
	margin-right: -238.5px;
	margin-top: 243px;
	position: relative;
	width: 811px;
}

.overlap-group2 {
	align-items: flex-end;
	background-position: 50% 50%;
	background-size: cover;
	display: flex;
	height: 189px;
	justify-content: flex-end;
	margin-top: -0.39px;
	min-width: 812px;
	padding: 0 30.8px;
}

.overlap-group3 {
	height: 49px;
	position: relative;
	width: 196px;
}

.place {
	left: 0;
	letter-spacing: 1.78px;
	line-height: 24px;
	position: absolute;
	text-align: center;
	top: 8px;
	width: 196px;
}

.register-text {
	left: -127px;
	letter-spacing: 1.78px;
	line-height: 24px;
	position: absolute;
	text-align: center;
	top: -159px;
	width: 588px;
}

.title-external-service {
	letter-spacing: 1.78px;
	line-height: 24px;
	margin-left: 2px;
	margin-top: 44px;
	min-height: 35px;
	min-width: 380px;
	text-align: center;
	white-space: nowrap;
}

.title-login {
	letter-spacing: 1.78px;
	line-height: 24px;
	margin-left: 2px;
	min-height: 35px;
	min-width: 256px;
	text-align: center;
	width: 256px;
}
</style>
