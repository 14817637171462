<template>
		<InfoCard info-key="plan-info" img="https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/images/landing/3d/pencilRack.png" :info="$t('subscriptionDescription')" :title="$t('subscription2')" class="mt-2 sm:mt-4"></InfoCard>
		<SubscriptionManagement></SubscriptionManagement>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import InfoCard from '@/components/modal/InfoCard.vue';
import SubscriptionManagement from "@/components/shop/profile/SubscriptionManagement.vue";

export default defineComponent({
	name: 'PlanPage',
	components: {
		InfoCard,
		SubscriptionManagement,
	},
	setup() {
		return {}
	},
});

</script>
<style lang="scss">
</style>

