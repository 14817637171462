import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "",
  "aria-labelledby": "login-info-heading"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "py-2" }
const _hoisted_4 = {
  id: "contact-info-heading",
  class: "text-lg font-semibold text-gray-900 dark:text-white"
}
const _hoisted_5 = { class: "mb-4 text-sm text-gray-600" }
const _hoisted_6 = { class: "py-2" }
const _hoisted_7 = { class: "py-2" }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "-mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_default_button = _resolveComponent("default-button")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_segment_button = _resolveComponent("ion-segment-button")!
  const _component_ion_segment = _resolveComponent("ion-segment")!
  const _component_login_component = _resolveComponent("login-component")!
  const _component_register_component = _resolveComponent("register-component")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", null, [
      (_ctx.isAuthenticated && !_ctx.isLoginProcess && !_ctx.waitForLoginEvent)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _withDirectives(_createElementVNode("div", _hoisted_3, [
              _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.$t('auth.General.already_logged_in')), 1),
              _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.$t('not_user_x', {name: _ctx.user.name})), 1)
            ], 512), [
              [_vShow, !_ctx.hideNextStepButton]
            ]),
            _withDirectives(_createElementVNode("h1", { class: "mt-2" }, _toDisplayString(_ctx.$t('auth.General.already_logged_in')), 513), [
              [_vShow, _ctx.hideNextStepButton]
            ]),
            _withDirectives(_createElementVNode("p", { class: "mb-4" }, _toDisplayString(_ctx.$t('not_user_x', {name: _ctx.user.name})), 513), [
              [_vShow, _ctx.hideNextStepButton]
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_default_button, {
                "button-type": "button",
                "button-text": _ctx.$t('shop.checkout.logout.title'),
                onClickButton: _cache[0] || (_cache[0] = ($event: any) => (_ctx.logoutUser(!_ctx.hardLogout)))
              }, null, 8, ["button-text"])
            ]),
            _withDirectives(_createElementVNode("div", _hoisted_7, [
              _createVNode(_component_default_button, {
                "button-color": "bg-gr-primary",
                "button-text": _ctx.$t('shop.checkout.contact_information.step'),
                "button-type": "button",
                onClickButton: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToNextStep()))
              }, null, 8, ["button-text"])
            ], 512), [
              [_vShow, !_ctx.hideNextStepButton]
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _withDirectives(_createElementVNode("p", _hoisted_9, null, 512), [
              [_vShow, !_ctx.hideNextStepButton]
            ]),
            (_ctx.defaultSegment)
              ? (_openBlock(), _createBlock(_component_ion_segment, {
                  key: 0,
                  class: "mb-16 mt-4 mx-0",
                  mode: "ios",
                  color: "dark",
                  value: _ctx.defaultSegment,
                  onIonChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.switchSegment($event)))
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.segments, (segment) => {
                      return (_openBlock(), _createBlock(_component_ion_segment_button, {
                        key: segment.value,
                        value: segment.value,
                        onClick: ($event: any) => (_ctx.switchSegment(segment))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, {
                            innerHTML: segment.name
                          }, null, 8, ["innerHTML"])
                        ]),
                        _: 2
                      }, 1032, ["value", "onClick"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["value"]))
              : _createCommentVNode("", true),
            (_ctx.selectedTab === 'login')
              ? (_openBlock(), _createBlock(_component_login_component, {
                  key: 1,
                  "show-switch": false,
                  "show-alerts": true,
                  onOnLoginSuccess: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onSuccess())),
                  onOnLoginError: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onError()))
                }))
              : (_ctx.selectedTab === 'register')
                ? (_openBlock(), _createBlock(_component_register_component, {
                    key: 2,
                    "show-switch": false,
                    onOnRegisterSuccess: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onSuccess())),
                    onOnRegisterError: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onError()))
                  }))
                : _createCommentVNode("", true)
          ]))
    ])
  ]))
}