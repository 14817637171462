import {Cart} from "@/greeve/shop/cart/cart.type";
import UseUserFactory from "@/composable/greeve/useUserFactory";
import {User} from "@/greeve/user/user.type";
import {ProfileInterface} from "@/greeve/user/user.interface";
import {UpdateUserDataName} from "@/greeve/api/profile";
import {
	SubscriptionList
} from '@/greeve/shop/subscription/subscription_list.type';
import {UserAddress} from '@/greeve/user/address/user_address.type';
import {QuotaList} from '@/greeve/user/quota/quota_list.type';
import {OAuthList} from '@/greeve/user/oauth/oauth_list.type';
import {AbstractQuota} from '@/greeve/user/quota/abstract_quota.type';

const {getUserFactory, getSubscriptionFactory, getUserAddressFactory, getQuotaFactory, getOAuthFactory} = UseUserFactory();

/* eslint-disable @typescript-eslint/no-unused-vars */

interface UserDataInterface {
	username: string;
	id: number;
	name?: string;
	givenName?: string;
	email: string;
	profileImage: string;
	profile?: ProfileInterface;
	subscriptions?: SubscriptionList;
	quotas?: QuotaList;
	oauths?: OAuthList;
	billingAddress?: UserAddress;
	latestUserAddress?: UserAddress;
}

const user = {
	namespaced: true,
	state: () => ({
		userData: {} as () => UserDataInterface,
		user: User,
	}),
	mutations: {
		id(state: any, id: number) {
			state.userData.id = id;
		},
		username(state: any, name: string) {
			state.userData.username = name;
		},
		givenName(state: any, givenName: string) {
			state.userData.givenName = givenName;
		},
		name(state: any, name: string) {
			state.userData.name = name;
		},
		email(state: any, email: string) {
			state.userData.email = email;
		},
		profileImage(state: any, profileImage: string) {
			state.userData.profileImage = profileImage;
		},
		profile(state: any, profile: ProfileInterface) {
			state.userData.profile = profile;
		},
		subscriptions(state: any, subscriptions: SubscriptionList) {
			state.userData.subscriptions = subscriptions;
		},
		quotas(state: any, quotas: QuotaList) {
			state.userData.quotas = quotas;
		},
		quotaTransactions(state: any, {quotaUuid, quotaTransactionList}: any) {
			if (!state.userData.quotas) {
				return;
			}
			const index = state.userData.quotas.findIndex((quota: AbstractQuota) => {
				return quota.uuid === quotaUuid;
			});

			if (index === -1) {
				return;
			}

			if (!state.userData.quotas[index].quota_transactions) {
				state.userData.quotas[index].quota_transactions = [];
			}

			state.userData.quotas[index].quota_transactions = quotaTransactionList;
		},
		addQuotaTransactions(state: any, {quotaUuid, quotaTransactionList}: any) {
			if (!state.userData.quotas) {
				return;
			}
			const index = state.userData.quotas.findIndex((quota: AbstractQuota) => {
				return quota.uuid === quotaUuid;
			});

			if (index === -1) {
				return;
			}

			if (!state.userData.quotas[index].quota_transactions) {
				state.userData.quotas[index].quota_transactions = [];
			}

			state.userData.quotas[index].quota_transactions.push(...quotaTransactionList);
		},
		billingAddress(state: any, billingAddress: UserAddress) {
			state.userData.billingAddress = billingAddress;
		},
		latestUserAddress(state: any, latestUserAddress: UserAddress) {
			state.userData.latestUserAddress = latestUserAddress;
		},
		oauths(state: any, oauthList: OAuthList) {
			state.userData.oauths = oauthList;
		},
		profileName(state: any, profileName: UpdateUserDataName) {
			state.userData.profile.first_name = profileName.first_name;
			state.userData.profile.last_name = profileName.last_name;
		},
		profileLanguage(state: any, language: string) {
			state.userData.profile.language = language;
		},
		initUser(state: any, data: any) {
			state.userData.id = data.id;
			state.userData.username = data.name;
			state.userData.email = data.email;
			state.userData.profileImage = data.profileImage;
			if (data.profile) {
				state.userData.profile = data.profile;
			}
		},
	},
	actions: {},
	getters: {
		getUser: (state: any): User => {
			if (!state.userData) {
				return getUserFactory().createEmptyUser();
			} else if (!(state.cart instanceof Cart)) {
				return getUserFactory().createUserByResponse(state.userData);
			} else {
				return state.user;
			}
		},

		// eslint-disable-next-line
		fullName: (state: any) => {
			let result = state.userData.username ?? "";
			if (!result || result.length === 0) {
				if (state.userData.givenName) {
					result = state.userData.givenName;
				} else {
					result = '';
				}
			}

			return result;
		},

		// eslint-disable-next-line
		getEmail: (state: any) => {
			return state.userData.email;
		},

		// eslint-disable-next-line
		getGivenName: (state: any) => {
			return state.userData.givenName;
		},

		// eslint-disable-next-line
		getProfileImage: (state: any) => {
			return state.userData.profileImage;
		},

		// eslint-disable-next-line
		getProfile: (state: any) => {
			return state.userData.profile;
		},

		getSubscriptions: (state: any): SubscriptionList => {
			if (!(state.userData.subscriptions instanceof SubscriptionList)) {
				return getSubscriptionFactory().createSubscriptionListByResponse(state.userData.subscriptions);
			} else {
				return state.userData.subscriptions;
			}
		},

		getQuotas: (state: any): QuotaList => {
			if (state.userData.quotas && !(state.userData.quotas instanceof QuotaList)) {
				return getQuotaFactory().createQuotaListByResponse(state.userData.quotas);
			} else {
				return state.userData.quotas;
			}
		},

		getOAuths: (state: any): OAuthList => {
			if (state.userData.oauths && !(state.userData.oauths instanceof OAuthList)) {
				return getOAuthFactory().createOAuthListByResponse(state.userData.oauths);
			} else {
				return state.userData.oauths;
			}
		},

		getBillingAddress: (state: any): UserAddress => {
			if (state.userData.billingAddress && !(state.userData.billingAddress instanceof UserAddress)) {
				return getUserAddressFactory().createUserAddressByResponse(state.userData.billingAddress);
			} else {
				return state.userData.billingAddress;
			}
		},

		getLatestUserAddress: (state: any): UserAddress => {
			if (state.userData.latestUserAddress && !(state.userData.latestUserAddress instanceof UserAddress)) {
				return getUserAddressFactory().createUserAddressByResponse(state.userData.latestUserAddress);
			} else {
				return state.userData.latestUserAddress;
			}
		},

		// eslint-disable-next-line
		getUserAbbr: (state: any) => {
			try {
				let name = state.userData.username;
				if (!name) {
					name = state.userData.givenName;
				}
				let nameAbbr = '';
				const nameAbbrList = name.split(" ").map((n: any)=>n[0]).join("");
				if (nameAbbrList.length > 1) {
					nameAbbr = nameAbbrList.substring(0, 1) + nameAbbrList.slice(nameAbbrList.length -1);
				} else {
					nameAbbr = nameAbbrList;
				}
				return nameAbbr;
			} catch (error) {
				return "";
			}
		},
	}
}

export default user;