import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "p-2 sm:p-4 pt-0 pb-3 sm:pt-0 hidden lg:flex flex-row justify-end"
}
const _hoisted_2 = {
  key: 0,
  class: "truncate text-base font-semibold pl-3 pr-1 text-gray-500 group-hover:text-gr-gray-500 hidden lg:block"
}
const _hoisted_3 = ["data-tip"]
const _hoisted_4 = {
  key: 1,
  class: "mx-2 sm:mx-4 my-0 sm:my-0 bg-gray-200 dark:bg-gr-dark rounded-xl hidden lg:block text-right"
}
const _hoisted_5 = { class: "flex justify-between pl-2" }
const _hoisted_6 = ["contenteditable"]
const _hoisted_7 = {
  key: 1,
  class: "flex items-center pr-2"
}
const _hoisted_8 = { key: 2 }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = {
  key: 0,
  class: "mt-1 m-2 sm:m-4 mb-0 sm:mb-0 hidden lg:block text-right rounded-2xl"
}
const _hoisted_11 = { class: "hidden lg:block text-right max-h-[60vh] rounded-xl overflow-y-auto overflow-x-hidden" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { key: 2 }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { class: "flex justify-between pl-2 pr-5" }
const _hoisted_16 = ["onClick", "data-tip"]
const _hoisted_17 = {
  key: 0,
  class: "max-w-[10em] truncate text-base font-semibold pl-3 pr-1 text-gray-500 group-hover:text-gr-gray-500 hidden lg:block"
}
const _hoisted_18 = { class: "truncate text-base font-semibold pl-3 pr-1 text-gray-500 group-hover:text-gr-gray-500 hidden sm:block lg:hidden" }
const _hoisted_19 = {
  key: 0,
  class: "flex"
}
const _hoisted_20 = { key: 1 }
const _hoisted_21 = { class: "fixed inset-0 flex" }
const _hoisted_22 = { class: "m-4 mx-8 mt-28 absolute flex justify-end" }
const _hoisted_23 = { key: 0 }
const _hoisted_24 = { class: "m-4 mt-24 max-h-[60vh] flex grow rounded-2xl bg-white dark:bg-gr-darkest shadow-md flex-col gap-y-0 overflow-y-auto px-6 pb-2" }
const _hoisted_25 = { class: "flex flex-1 flex-col" }
const _hoisted_26 = {
  role: "list",
  class: "flex flex-1 flex-col gap-y-7"
}
const _hoisted_27 = {
  role: "list",
  class: "-mx-2 space-y-2"
}
const _hoisted_28 = { class: "flex" }
const _hoisted_29 = { class: "" }
const _hoisted_30 = {
  key: 0,
  class: "flex"
}
const _hoisted_31 = { class: "bg-gray-200 dark:bg-gr-dark text-gray-500 w-full group flex justify-between rounded-2xl" }
const _hoisted_32 = {
  key: 1,
  class: "flex items-center pr-2"
}
const _hoisted_33 = { class: "p-4" }
const _hoisted_34 = ["onClick"]
const _hoisted_35 = { key: 0 }
const _hoisted_36 = { class: "flex justify-start w-full" }
const _hoisted_37 = ["onClick", "data-tip"]
const _hoisted_38 = { class: "truncate text-left text-base font-semibold pl-3 pr-1 text-gray-500 group-hover:text-gr-gray-500 whitespace-nowrap" }
const _hoisted_39 = {
  key: 0,
  class: "flex justify-end items-center pr-2",
  style: {"flex":"auto"}
}

import {onMounted, ref, computed, reactive, watch} from 'vue';
import {
	ChatBubbleLeftIcon, PlusCircleIcon, ClockIcon, ArrowSmallDownIcon, ArrowSmallUpIcon, TrashIcon, PencilIcon, ArrowPathIcon
} from '@heroicons/vue/20/solid';
import useTranslation from '@/composable/translation/useTranslation';
import router from '@/router';
import useAssistant from '@/composable/greeve/useAssistant';
import {SearchGroup} from '@/greeve/search/group/search_group.type';
import useAuth from '@/composable/auth/useAuth';
import {SearchGroupList} from '@/greeve/search/group/search_group_list.type';
import {Dialog, DialogPanel, TransitionChild, TransitionRoot} from '@headlessui/vue';
import {PlusIcon, XMarkIcon,
} from '@heroicons/vue/24/outline';
import useCustomStore from '@/composable/custom/useCustomStore';
import {useRoute} from 'vue-router';
import DynamicMenu from '@/components/core/DynamicMenu.vue';
import { MenuItemType } from '@/greeve/core/menu_item_type';
import ConfirmWithInputFieldDialog from '@/components/modal/ConfirmWithInputFieldDialog.vue';
import ConfirmDialog from '@/components/modal/ConfirmDialog.vue';



export default /*@__PURE__*/_defineComponent({
  __name: 'GroupList',
  props: {
	group_uuid: {
		type: String,
		default: '',
	},
	group_reference: {
		type: String,
		default: '',
	},
	updateTimestamp: {
		type: Number,
		default: undefined,
	},
	hasEmptyGroup: {
		type: Boolean,
		default: false,
	},
	isMenuOpen: {
		type: Boolean,
		default: false,
	},
},
  emits: ['groupSelected'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emits = __emit;

const {t} = useTranslation();
const {isAuthenticated} = useAuth();
const {
	initSearchGroups,
	getGroupByUuid,
	searchGroups,
	addNewGroup,
	editGroup,
	deleteGroupByUuid,
	lastGroupUuid,
} = useAssistant();
const selectedGroup = ref<SearchGroup | any>();
const isGroupLoading = ref(false);
const isNewChatLoading = ref(false);
const isHistoryGroupsCollapsed = ref(false);
const isEditable: any = reactive([]);
const isSelectedEditable = ref(false);
const {
	mobileMenuSidebar,
	setMobileMenuSidebar,
	setIsGlobalLoading,
	isLoadAllGroups,
	setLoadAllGroups,
} = useCustomStore();
const route = useRoute();
const groupedHistory = ref<{ date: string; groups: SearchGroup[] | SearchGroupList, collapsed: boolean }[]>([]);
const hasMoreGroupsToLoad = ref(false);
const showEditGroupDialog = ref(false);
const showConfirmDeleteGroupDialog = ref(false);
const actionGroupUuid = ref();

const editGroupValue = computed(() => {
	if (actionGroupUuid.value) {
		const groupToEdit = getGroupByUuid(actionGroupUuid.value);
		if (groupToEdit) {
			return groupToEdit.getName();
		}
	}
	return '';
});

const groupMenuItems = ref<MenuItemType[]>([
	{label: t('group.change.button'), action: handleShowEditConfirmDialog, icon: PencilIcon},
	{label: t('group.delete.button'), action: handleShowDeleteGroupConfirmDialog, icon: TrashIcon},
]);

function handleGroupMenuAction(item: MenuItemType, reference?: string) {
	item.action(reference); // Execute the action associated with the clicked menu item
}

function handleShowEditConfirmDialog(reference: string) {
	actionGroupUuid.value = reference;
	showEditGroupDialog.value = true;
}

function handleShowDeleteGroupConfirmDialog(reference: string) {
	actionGroupUuid.value = reference;
	showConfirmDeleteGroupDialog.value = true;
}

function handleGroupDelete() {
	if (actionGroupUuid.value) {
		try {
			setIsGlobalLoading(true);
			const loadingTimeoutId = setTimeout(() => {
				setIsGlobalLoading(false);
			}, 10000);
			showConfirmDeleteGroupDialog.value = false;
			deleteGroupByUuid(actionGroupUuid.value).then(() => {
				if (searchGroups.value) {
					const activeGroup = searchGroups.value?.getNewestGroup();
					if (activeGroup) {
						updateGroup(activeGroup);
					}
				}
				setIsGlobalLoading(false);
				clearTimeout(loadingTimeoutId);
			});
		} catch (e) {
			setIsGlobalLoading(false);
		}
	}
}

function handleGroupEdit(inputValue: string) {
	if (actionGroupUuid.value) {
		try {
			setIsGlobalLoading(true);
			const loadingTimeoutId = setTimeout(() => {
				setIsGlobalLoading(false);
			}, 10000);
			showEditGroupDialog.value = false;
			editGroup(actionGroupUuid.value, inputValue, false).then(() => {
				const group = getGroupByUuid(actionGroupUuid.value);
				if (group) {
					updateGroup(group);
				}
				setIsGlobalLoading(false);
				clearTimeout(loadingTimeoutId);
			});
		} catch (e) {
			setIsGlobalLoading(false);
		}
	}
}

function selectGroup(searchGroup: SearchGroup) {
	selectedGroup.value = searchGroup;
	setMobileMenuSidebar(false);
	document.title = 'greeve - ' + searchGroup.getName(50);
	router.push(
			'/assistant/chat/' + searchGroup.getUrlName() + '/' + searchGroup.getUuidEncoded() + '/' + searchGroup.reference);
	emits('groupSelected');
	isGroupLoading.value = false;
	isNewChatLoading.value = false;
}

function addNewGroupAndMarkAsActive() {
	if (!isAuthenticated.value) {
		return;
	}
	isNewChatLoading.value = true;
	const firstNewGroup = searchGroups.value?.getFirstNewActiveGroupWithoutItems();
	if (firstNewGroup) {
		selectedGroup.value = firstNewGroup;
		selectGroup(selectedGroup.value);
	} else {
		addNewGroup().then((group) => {
			selectedGroup.value = group;
			selectGroup(selectedGroup.value);
		});
	}
}

//TODO remove
const makeGroupEditable = (groupUuid: string) => {
	isEditable[groupUuid] = false;
};

async function updateGroupName(event: FocusEvent, groupUuid: string) {
	const updateGroup = getGroupByUuid(groupUuid);
	if (!updateGroup) {
		isEditable[groupUuid] = false;
		return;
	}
	try {
		isEditable[groupUuid] = false;
		const target = event.target as HTMLDivElement;
		if (target.textContent === updateGroup.getName()) {
			return;
		}
		await editGroup(updateGroup.uuid, target.textContent || '');
	} catch (e) {
		console.error(e);
	} finally {
		isEditable[groupUuid] = false;
	}
}

function updateGroup(searchGroupNew: SearchGroup) {
	if (searchGroupNew.uuid && searchGroupNew.reference) {
		selectedGroup.value = getGroupByUuid(searchGroupNew.uuid);
		if (selectedGroup.value) {
			isSelectedEditable.value = false;
			selectGroup(selectedGroup.value);
		}
	}
}

//TODO load groups without items

async function initGroups(loadAll = false) {
	if (isGroupLoading.value) {
		return;
	}
	const loadItems = false;
	isGroupLoading.value = true;
	const pageCount = loadAll ? 0 : 8;
	//TODO check if group selected and load first selected group
	if (useAuth().isAuthenticated.value && (!props.group_uuid || props.group_uuid.length === 0)) {
		initSearchGroups(true, loadItems, pageCount).then(() => {
			if (lastGroupUuid.value) {
				selectedGroup.value = getGroupByUuid(lastGroupUuid.value);
				if (selectedGroup.value) {
					selectGroup(selectedGroup.value);
				}
			} else {
				addNewGroupAndMarkAsActive();
			}
			groupHistoryByDate();
			isGroupLoading.value = false;
		});
	} else if (props.group_uuid && props.group_uuid.length > 0) {
		if (useAuth().isAuthenticated.value) {
			initSearchGroups(true, loadItems, pageCount).then(() => {
				selectedGroup.value = getGroupByUuid(props.group_uuid);
				if (selectedGroup.value) {
					selectGroup(selectedGroup.value);
				} else {
					addNewGroupAndMarkAsActive();
				}
				groupHistoryByDate();
				isGroupLoading.value = false;
			});
		} else {
			selectedGroup.value = getGroupByUuid(props.group_uuid);
			if (selectedGroup.value) {
				selectGroup(selectedGroup.value);
			} else {
				addNewGroupAndMarkAsActive();
			}
			groupHistoryByDate();
		}
	} else if (lastGroupUuid.value) {
		selectedGroup.value = getGroupByUuid(lastGroupUuid.value);
		if (selectedGroup.value) {
			selectGroup(selectedGroup.value);
		}
		groupHistoryByDate();
	} else {
		addNewGroupAndMarkAsActive();
		groupHistoryByDate();
	}
}

function getAllGroups(): SearchGroupList | undefined {
	if (searchGroups.value) {
		const ignoreList = [];
		if (selectedGroup.value && selectedGroup.value?.uuid) {
			ignoreList.push(selectedGroup.value.uuid);
		}
		return searchGroups.value;
	}
	return;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getAllGroupsWithoutSelected(): SearchGroup[] | SearchGroupList | undefined {
	if (searchGroups.value) {
		const ignoreList = [];
		if (selectedGroup.value && selectedGroup.value?.uuid) {
			ignoreList.push(selectedGroup.value.uuid);
		}
		return searchGroups.value?.filterList(ignoreList);
	}
	return;
}

// Method to group history items by date
const groupHistoryByDate = () => {
	const groups = getAllGroups();
	const grouped: { [key: string]: SearchGroup[] } = {};

	// Grouping items by date
	groups?.forEach(group => {
		if (group?.updated_at) {
			const dateKey = getDateKey(group?.updated_at);
			if (!grouped[dateKey]) {
				grouped[dateKey] = [];
			}
			grouped[dateKey].push(group);
		}
	});

	// Creating an array of grouped items with date
	const groupedArray = Object.keys(grouped).map(date => ({
		date,
		groups: grouped[date],
		collapsed: false,
	}));

	// Sorting by date (you can implement custom sorting logic here)
	groupedArray.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

	groupedHistory.value = groupedArray;
	hasMoreGroupsToLoad.value = !!(groups && groups?.hasMoreItemsToLoad());
};

// Helper method to get a date key (Today, Yesterday, etc.)
const getDateKey = (parsedDate: Date): string => {
	const today = new Date();
	const yesterday = new Date(today);
	yesterday.setDate(today.getDate() - 1);

	if (isSameDay(parsedDate, today)) {
		return t('historyGroup.today');
	} else if (isSameDay(parsedDate, yesterday)) {
		return t('historyGroup.yesterday');
	} else {
		return getMonthYear(parsedDate);
	}
};

// Helper method to check if two dates are the same day
const isSameDay = (date1: Date, date2: Date): boolean => {
	return date1.getFullYear() === date2.getFullYear() &&
			date1.getMonth() === date2.getMonth() &&
			date1.getDate() === date2.getDate();
};

// Helper method to get month and year in format "Month Year"
const getMonthYear = (date: Date): string => {
	const monthNumber = date.getMonth() + 1;
	const yearSuffix = (date.getFullYear() !== (new Date).getFullYear()) ? ` ${date.getFullYear()}` : '';
	return t(`historyGroup.${monthNumber}`) + yearSuffix;
};

// Method to toggle collapse state of a group
const toggleCollapse = (index: number) => {
	groupedHistory.value[index].collapsed = !groupedHistory.value[index].collapsed;
};

function loadAllGroups() {
	if (!isGroupLoading.value && isAuthenticated) {
		setLoadAllGroups(true);
		initGroups(true);
	}
}

function refreshGroup() {
	if (!isGroupLoading.value && isAuthenticated) {
		initGroups(isLoadAllGroups.value);
	}
}

watch(() => searchGroups.value,
		(newGroups: SearchGroupList | undefined, oldGroups: SearchGroupList | undefined) => {
			if (!isAuthenticated) {
				return;
			}

			if ((newGroups && newGroups !== oldGroups)) {
				let newSelectedGroup;
				if (selectedGroup.value && selectedGroup.value.uuid) {
					newSelectedGroup = newGroups.getItemByUuId(selectedGroup.value.uuid);
				} else if (lastGroupUuid.value) {
					newSelectedGroup = newGroups.getItemByUuId(lastGroupUuid.value);
				}

				if (newSelectedGroup && newSelectedGroup.name && newSelectedGroup.name?.length > 0 && newSelectedGroup?.name !==
						selectedGroup.value?.name) {
					updateGroup(newSelectedGroup);
				}
			}
		});

watch(() => props.updateTimestamp, (updateTimestamp) => {
	if (updateTimestamp && route.path.includes('/assistant') && !isGroupLoading.value && isAuthenticated) {
		initGroups(isLoadAllGroups.value);
	}
});

onMounted(() => {
	if (!isGroupLoading.value && isAuthenticated) {
		initGroups(isLoadAllGroups.value);
	}
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(isAuthenticated))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("button", {
            onClick: addNewGroupAndMarkAsActive,
            class: _normalizeClass(['flex-auto inline-flex rounded-full bg-white text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50', __props.isMenuOpen ? 'px-3.5 py-2' : 'tooltip tooltip-top px-2.5 py-1']),
            "data-tip": "new Chat"
          }, [
            _createVNode(_unref(PlusCircleIcon), {
              class: _normalizeClass(['text-gray-400 dark:text-gray-500 group-hover:text-gray-500 flex-shrink-0', !__props.isMenuOpen ? 'w-full h-8' : 'h-6 w-6'])
            }, null, 8, ["class"]),
            (__props.isMenuOpen)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t('newChat')), 1))
              : _createCommentVNode("", true)
          ], 2),
          (__props.isMenuOpen)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                onClick: refreshGroup,
                class: "px-2 py-2 tooltip tooltip-top",
                "data-tip": _ctx.$t('version.newVersionAvailable.button.reload')
              }, [
                _createVNode(_unref(ArrowPathIcon), { class: "text-gray-400 dark:text-gray-500 group-hover:text-gray-500 flex-shrink-0 h-6 w-6" })
              ], 8, _hoisted_3))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (selectedGroup.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (!isSelectedEditable.value)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (selectGroup(selectedGroup.value))),
                  class: "max-w-[10em] px-2 py-2 flex tooltip tooltip-top break-words",
                  "data-tip": "active Chat",
                  onDblclick: _cache[1] || (_cache[1] = () => makeGroupEditable(selectedGroup.value.uuid)),
                  onBlur: _cache[2] || (_cache[2] = event => updateGroupName(event, selectedGroup.value.uuid)),
                  onKeydown: _cache[3] || (_cache[3] = _withKeys(event => updateGroupName(event, selectedGroup.value.uuid), ["enter"]))
                }, [
                  _createVNode(_unref(ChatBubbleLeftIcon), { class: "text-gray-400 dark:text-gray-500 group-hover:text-gray-500 flex-shrink-0 h-6 w-6" }),
                  _createElementVNode("span", {
                    class: _normalizeClass(['truncate text-base font-semibold pl-3 pr-1 text-gray-500 group-hover:text-gr-gray-500', __props.isMenuOpen ? 'hidden lg:block' : 'sm:block lg:hidden']),
                    contenteditable: isEditable[selectedGroup.value.uuid]
                  }, _toDisplayString(selectedGroup.value.getName()), 11, _hoisted_6)
                ], 32))
              : _createCommentVNode("", true),
            (_unref(isAuthenticated))
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(DynamicMenu, {
                    key: "selectedGroup",
                    menuItems: groupMenuItems.value,
                    reference: selectedGroup.value.uuid,
                    onMenuClick: handleGroupMenuAction
                  }, null, 8, ["menuItems", "reference"])
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(ConfirmWithInputFieldDialog, {
      "input-value": editGroupValue.value,
      visible: showEditGroupDialog.value,
      icon: _unref(PencilIcon),
      "input-placeholder": _ctx.$t('group.change.name.placeholder'),
      type: "success",
      onConfirm: handleGroupEdit,
      "confirm-button-name": _ctx.$t('group.change.confirm'),
      "cancel-button-name": _ctx.$t('group.change.cancel'),
      onCancelConfirm: _cache[4] || (_cache[4] = ($event: any) => (showEditGroupDialog.value = false))
    }, null, 8, ["input-value", "visible", "icon", "input-placeholder", "confirm-button-name", "cancel-button-name"]),
    _createVNode(ConfirmDialog, {
      title: _ctx.$t('group.delete.title'),
      description: _ctx.$t('group.delete.description'),
      "cancel-button-name": _ctx.$t('group.delete.cancel'),
      "confirm-button-name": _ctx.$t('group.delete.confirm'),
      type: "alert",
      onConfirm: handleGroupDelete,
      onCancelConfirm: _cache[5] || (_cache[5] = ($event: any) => (showConfirmDeleteGroupDialog.value = false)),
      visible: showConfirmDeleteGroupDialog.value
    }, null, 8, ["title", "description", "cancel-button-name", "confirm-button-name", "visible"]),
    (_unref(isAuthenticated))
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          (isGroupLoading.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[9] || (_cache[9] = [
                _createElementVNode("div", {
                  role: "status",
                  class: "text-center mt-2 mb-2"
                }, [
                  _createElementVNode("svg", {
                    "aria-hidden": "true",
                    class: "inline justify-center w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-gr-primary",
                    viewBox: "0 0 100 101",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg"
                  }, [
                    _createElementVNode("path", {
                      d: "M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z",
                      fill: "currentColor"
                    }),
                    _createElementVNode("path", {
                      d: "M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z",
                      fill: "currentFill"
                    })
                  ]),
                  _createElementVNode("span", { class: "sr-only" }, "Loading...")
                ], -1)
              ])))
            : _createCommentVNode("", true),
          _createElementVNode("div", null, [
            (!_unref(mobileMenuSidebar))
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(groupedHistory.value, (group, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "pl-2 hidden lg:block text-right mt-2",
                        key: index
                      }, [
                        _createElementVNode("button", {
                          onClick: ($event: any) => (toggleCollapse(index)),
                          class: "flex items-center justify-start w-full text-gray-500 text-sm mb-2"
                        }, [
                          (!group.collapsed)
                            ? (_openBlock(), _createBlock(_unref(ArrowSmallUpIcon), {
                                key: 0,
                                class: "h-4 w-4 mr-1"
                              }))
                            : _createCommentVNode("", true),
                          (group.collapsed)
                            ? (_openBlock(), _createBlock(_unref(ArrowSmallDownIcon), {
                                key: 1,
                                class: "h-4 w-4 mr-1"
                              }))
                            : _createCommentVNode("", true),
                          (__props.isMenuOpen)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(group.date), 1))
                            : _createCommentVNode("", true)
                        ], 8, _hoisted_12),
                        (!group.collapsed)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.groups, (historyGroup) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  key: historyGroup.id,
                                  class: _normalizeClass({ 'bg-gray-200 dark:bg-gr-dark rounded-xl': selectedGroup.value && selectedGroup.value.uuid === historyGroup.uuid })
                                }, [
                                  _createElementVNode("div", _hoisted_15, [
                                    _createElementVNode("button", {
                                      onClick: ($event: any) => (selectGroup(historyGroup)),
                                      class: "py-2 flex tooltip tooltip-top",
                                      "data-tip": historyGroup.getName()
                                    }, [
                                      _createVNode(_unref(ChatBubbleLeftIcon), { class: "text-gray-400 dark:text-gray-500 group-hover:text-gray-500 flex-shrink-0 h-6 w-6" }),
                                      (__props.isMenuOpen)
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(historyGroup.getName()), 1))
                                        : _createCommentVNode("", true),
                                      _createElementVNode("span", _hoisted_18, _toDisplayString(historyGroup.getName()), 1)
                                    ], 8, _hoisted_16)
                                  ])
                                ], 2))
                              }), 128))
                            ]))
                          : _createCommentVNode("", true)
                      ]))
                    }), 128)),
                    (__props.isMenuOpen)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                          (hasMoreGroupsToLoad.value)
                            ? (_openBlock(), _createElementBlock("button", {
                                key: 0,
                                onClick: loadAllGroups,
                                type: "button",
                                class: "rounded items-center justify-center flex bg-white px-4 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 w-full"
                              }, _toDisplayString(_ctx.$t('enterprise.stats.models.show_more')), 1))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]))
              : (_unref(mobileMenuSidebar))
                ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                    _createVNode(_unref(TransitionRoot), {
                      as: "template",
                      show: _unref(mobileMenuSidebar)
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(Dialog), {
                          as: "div",
                          class: "relative z-9 lg:hidden",
                          onClose: _cache[8] || (_cache[8] = ($event: any) => (_unref(setMobileMenuSidebar)(false)))
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_21, [
                              _createVNode(_unref(TransitionChild), {
                                as: "template",
                                enter: "transition ease-in-out duration-300 transform",
                                "enter-from": "-translate-x-full",
                                "enter-to": "translate-x-0",
                                leave: "transition ease-in-out duration-300 transform",
                                "leave-from": "translate-x-0",
                                "leave-to": "-translate-x-full"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_unref(DialogPanel), { class: "relative flex w-full flex-1" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_unref(TransitionChild), {
                                        as: "template",
                                        enter: "ease-in-out duration-300",
                                        "enter-from": "opacity-0",
                                        "enter-to": "opacity-100",
                                        leave: "ease-in-out duration-300",
                                        "leave-from": "opacity-100",
                                        "leave-to": "opacity-0"
                                      }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("div", _hoisted_22, [
                                            _createElementVNode("button", {
                                              type: "button",
                                              class: "-m-2.5 p-2.5",
                                              onClick: _cache[6] || (_cache[6] = ($event: any) => (_unref(setMobileMenuSidebar)(false)))
                                            }, [
                                              _cache[10] || (_cache[10] = _createElementVNode("span", { class: "sr-only" }, "Close sidebar", -1)),
                                              _createVNode(_unref(XMarkIcon), {
                                                class: "h-6 w-6",
                                                "aria-hidden": "true"
                                              })
                                            ])
                                          ])
                                        ]),
                                        _: 1
                                      }),
                                      (isNewChatLoading.value)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_23, _cache[11] || (_cache[11] = [
                                            _createElementVNode("div", {
                                              role: "status",
                                              class: "text-center mt-2 mb-2 absolute h-full w-full flex items-center justify-center backdrop-filter backdrop-blur-sm"
                                            }, [
                                              _createElementVNode("svg", {
                                                "aria-hidden": "true",
                                                class: "inline justify-center w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-gr-primary",
                                                viewBox: "0 0 100 101",
                                                fill: "none",
                                                xmlns: "http://www.w3.org/2000/svg"
                                              }, [
                                                _createElementVNode("path", {
                                                  d: "M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z",
                                                  fill: "currentColor"
                                                }),
                                                _createElementVNode("path", {
                                                  d: "M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z",
                                                  fill: "currentFill"
                                                })
                                              ]),
                                              _createElementVNode("span", { class: "sr-only" }, "Loading...")
                                            ], -1)
                                          ])))
                                        : _createCommentVNode("", true),
                                      _createElementVNode("div", _hoisted_24, [
                                        _cache[12] || (_cache[12] = _createElementVNode("div", { class: "flex h-16 shrink-0 items-center" }, null, -1)),
                                        _createElementVNode("nav", _hoisted_25, [
                                          _createElementVNode("ul", _hoisted_26, [
                                            _createElementVNode("li", null, [
                                              _createElementVNode("ul", _hoisted_27, [
                                                _createElementVNode("button", {
                                                  type: "button",
                                                  class: "w-full self-center justify-center flex lg:hidden rounded-full bg-white px-3 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50",
                                                  onClick: addNewGroupAndMarkAsActive
                                                }, [
                                                  _createElementVNode("span", _hoisted_28, [
                                                    _createVNode(_unref(PlusIcon), {
                                                      class: "mx-2 h-6 w-6",
                                                      "aria-hidden": "true"
                                                    })
                                                  ]),
                                                  _createElementVNode("span", _hoisted_29, _toDisplayString(_ctx.$t('newChat')), 1)
                                                ]),
                                                (selectedGroup.value)
                                                  ? (_openBlock(), _createElementBlock("li", _hoisted_30, [
                                                      _createElementVNode("div", _hoisted_31, [
                                                        (!isSelectedEditable.value)
                                                          ? (_openBlock(), _createElementBlock("a", {
                                                              key: 0,
                                                              onClick: _cache[7] || (_cache[7] = ($event: any) => (selectGroup(selectedGroup.value))),
                                                              class: "flex gap-x-3 px-4 py-2 text-sm leading-6 font-semibold tooltip-top whitespace-nowrap",
                                                              "data-tip": "active Chat"
                                                            }, [
                                                              _createVNode(_unref(ChatBubbleLeftIcon), {
                                                                class: "h-6 w-6 shrink-0",
                                                                "aria-hidden": "true"
                                                              }),
                                                              _createTextVNode(" " + _toDisplayString(selectedGroup.value.getName()), 1)
                                                            ]))
                                                          : _createCommentVNode("", true),
                                                        (_unref(isAuthenticated))
                                                          ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                                                              _createVNode(DynamicMenu, {
                                                                key: "mobile-selected",
                                                                menuItems: groupMenuItems.value,
                                                                reference: selectedGroup.value.uuid,
                                                                onMenuClick: handleGroupMenuAction
                                                              }, null, 8, ["menuItems", "reference"])
                                                            ]))
                                                          : _createCommentVNode("", true)
                                                      ])
                                                    ]))
                                                  : _createCommentVNode("", true)
                                              ])
                                            ])
                                          ])
                                        ]),
                                        _createElementVNode("div", _hoisted_33, [
                                          _createElementVNode("h3", {
                                            class: _normalizeClass(['group flex gap-x-3 rounded-2xl pt-1 pb-3 text-sm leading-6 font-semibold', isHistoryGroupsCollapsed.value ? '' : ''])
                                          }, [
                                            _createVNode(_unref(ClockIcon), {
                                              class: "h-6 w-6 shrink-0",
                                              "aria-hidden": "true"
                                            }),
                                            _createTextVNode(" " + _toDisplayString(_ctx.$t('allChats')), 1)
                                          ], 2),
                                          _createElementVNode("ul", null, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(groupedHistory.value, (group, index) => {
                                              return (_openBlock(), _createElementBlock("li", { key: index }, [
                                                _createElementVNode("button", {
                                                  onClick: ($event: any) => (toggleCollapse(index)),
                                                  class: "flex items-center justify-start w-full text-gray-500 text-sm mb-2"
                                                }, [
                                                  (!group.collapsed)
                                                    ? (_openBlock(), _createBlock(_unref(ArrowSmallUpIcon), {
                                                        key: 0,
                                                        class: "h-4 w-4 mr-1"
                                                      }))
                                                    : _createCommentVNode("", true),
                                                  (group.collapsed)
                                                    ? (_openBlock(), _createBlock(_unref(ArrowSmallDownIcon), {
                                                        key: 1,
                                                        class: "h-4 w-4 mr-1"
                                                      }))
                                                    : _createCommentVNode("", true),
                                                  _createElementVNode("span", null, _toDisplayString(group.date), 1)
                                                ], 8, _hoisted_34),
                                                (!group.collapsed)
                                                  ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                                                      _createElementVNode("ul", null, [
                                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.groups, (historyGroup) => {
                                                          return (_openBlock(), _createElementBlock("li", {
                                                            key: historyGroup.id
                                                          }, [
                                                            _createElementVNode("div", {
                                                              class: _normalizeClass(['py-2', selectedGroup.value && selectedGroup.value.uuid === historyGroup.uuid ? 'bg-gray-200 dark:bg-gr-dark rounded-xl' : ''])
                                                            }, [
                                                              _createElementVNode("div", _hoisted_36, [
                                                                _createElementVNode("button", {
                                                                  onClick: ($event: any) => (selectGroup(historyGroup)),
                                                                  class: "tooltip tooltip-top contents",
                                                                  "data-tip": historyGroup.getName()
                                                                }, [
                                                                  _createVNode(_unref(ChatBubbleLeftIcon), { class: "text-gray-400 dark:text-gray-500 group-hover:text-gray-500 flex-shrink-0 h-6 w-6" }),
                                                                  _createElementVNode("span", _hoisted_38, _toDisplayString(historyGroup.getName()), 1)
                                                                ], 8, _hoisted_37),
                                                                (_unref(isAuthenticated))
                                                                  ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
                                                                      _createVNode(DynamicMenu, {
                                                                        key: "mobile",
                                                                        menuItems: groupMenuItems.value,
                                                                        reference: historyGroup.uuid,
                                                                        onMenuClick: handleGroupMenuAction
                                                                      }, null, 8, ["menuItems", "reference"])
                                                                    ]))
                                                                  : _createCommentVNode("", true)
                                                              ])
                                                            ], 2)
                                                          ]))
                                                        }), 128))
                                                      ])
                                                    ]))
                                                  : _createCommentVNode("", true)
                                              ]))
                                            }), 128))
                                          ])
                                        ])
                                      ])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["show"])
                  ]))
                : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})