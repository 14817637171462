import {FlowMediaList} from '@/greeve/flow/media/flow_media_list.type';

export const enum FlowProcessState {
	ACTIVE = 'active',
	DELETED = 'deleted',
	DONE = 'done',
	DISABLED = 'disabled',
	ERROR = 'error',
	RUNNING = 'running',
}

export const enum FlowProcessType {
	DEFAULT = 'default',
	CUSTOM = 'custom',
	DOCS = 'docs',
	DOWNLOAD_AND_SHOW = 'download_and_show',
	EMAIL = 'email',
	GMAIL = 'gmail',
	GOOGLE_DRIVE = 'google_drive',
	GRAMMAR_CHECK = 'grammar_check',
	GREEVE_WHISPER = 'greeve_whisper',
	JIRA = 'jira',
	SLIDES = 'slides',
	SPREADSHEET = 'spreadsheet',
	TRANSLATE = 'translate',
	YOUTRACK = 'youtrack',
}


export interface FlowProcessInterface {
	id: number,
	uuid: string,
	flow_id: number,
	flow_process_config_id?: number|null,
	// flow_process_config?: FlowProcessConfig|null,
	media_list?: FlowMediaList|null,
	type: FlowProcessType,
	state: FlowProcessState,
	reference?: string|null,
	name?: string|null,
	description?: string|null,
	data?: any,
	created_at?: Date,
	updated_at?: Date,
}