import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImageOutputElement = _resolveComponent("ImageOutputElement")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h3", null, _toDisplayString(_ctx.$t('assistant.image.variation.response.title')), 1),
    _createVNode(_component_ImageOutputElement, {
      response: _ctx.searchItem?.getResponse()
    }, null, 8, ["response"])
  ], 64))
}