import {PaginationMetaInterface} from "@/greeve/core/pagination_meta.interface";
import {UserAddress} from "@/greeve/user/address/user_address.type";

export class UserAddressList extends Array<UserAddress> {

	public paginationMetaData?: PaginationMetaInterface;

	constructor(items: Array<UserAddress> = []) {
		super();
		this.push(...items);
	}

	add(items: UserAddress) {
		this.push(items);
	}

	remove(index: number) {
		this.splice(index, 1);
	}

	removeAll() {
		const itemCount = this.length;
		this.splice(itemCount, 1);
	}

	getItem(index: number): UserAddress {
		return this[index];
	}

	getUserAddress_by_Id(item_id: number): UserAddress | undefined {
		return this.find(({id}) => id === item_id);
	}

	getLastId(): number|undefined {
		let result;
		try {
			const lastItem = this.at(-1);

			if (lastItem) {
				result = lastItem.id;
			}
		} catch (e) {
			console.error(e);
		}

		return result;
	}

	enhanceMetaData(metaData: any) {
		this.paginationMetaData = metaData;
	}
}