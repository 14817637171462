<template>
	<BaseAlertComponent :show-close-button="showCloseButton" :alert-padding="alertPadding" :message="errorMessage" :show-title="showTitle" :title="errorTitle" alert-type="error" :css-class="cssClass"
						@closeAlert="closeAlert()"></BaseAlertComponent>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import BaseAlertComponent from "@/components/alert/Base/BaseAlertComponent.vue";

export default defineComponent({
	name: 'DangerAlert',
	components: {BaseAlertComponent},
	props: {
		errorMessage: {
			type: String,
			default: "",
		},
		errorTitle: {
			type: String,
			default: "",
		},
		alertPadding: {
			type: String,
			default: "pt-0",
		},
		showTitle: {
			type: Boolean,
			default: true,
		},
		cssClass: {
			type: String,
			default: "",
		},
		showCloseButton: {
			type: Boolean,
			default: true,
		},
	},
	emits: ['closeAlert'],
	setup(props, {emit}) {
		const closeAlert = () => {
			emit('closeAlert');
		};

		return {closeAlert};
	}
})
</script>

<style scoped>
</style>