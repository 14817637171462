<template>
	<Popover class="flow-root text-sm lg:relative">
		<PopoverButton class="outline-none items-center">
			<EllipsisVerticalIcon class="h-5 w-5 -mb-[7px]"></EllipsisVerticalIcon>
		</PopoverButton>
		<transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0"
					enter-to-class="opacity-100" leave-active-class="transition ease-in duration-150"
					leave-from-class="opacity-100" leave-to-class="opacity-0">
			<PopoverPanel class="absolute inset-x-0 top-16 mt-px bg-white dark:bg-gr-dark shadow-lg p-5 lg:top-full lg:left-auto lg:right-0 lg:mt-3 lg:w-52 rounded-xl">
				<h3 v-if="SettingsAvailable === true" class="mx-1 text-sm mb-0 font-semibold leading-6 dark:text-gray-500 ">
					{{ $t('nodeSettings') }}</h3>
				<h3 v-else class="mx-1 text-sm mb-0 font-semibold leading-6 dark:text-gray-500 ">
					{{ $t('noSettingsAvailable') }}</h3>
<!--				<h3 class="mx-1 text-sm mt-4 mb-0 font-semibold leading-6 dark:text-gray-500 ">-->
<!--					{{ $t('changeLanguage') }}</h3>-->
				<div class="flex flex-col gap-4 sm:flex-row sm:items-end sm:justify-between lg:flex-col lg:items-stretch">
					<ListSelectDropdown></ListSelectDropdown>
				</div>
			</PopoverPanel>
		</transition>
	</Popover>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {EllipsisVerticalIcon} from '@heroicons/vue/24/outline';
import {
	Popover,
	PopoverButton,
	PopoverPanel,
} from '@headlessui/vue';
import ListSelectDropdown from '@/components/core/ListSelectDropdown.vue';

export default defineComponent({
	name: 'SettingsNodePopover',
	components: {
		ListSelectDropdown,
		EllipsisVerticalIcon,
		Popover, PopoverButton, PopoverPanel,
	},
	props: {
		SettingsAvailable:  {
			default: false,
			type: Boolean,
		},
	},
	emits: [],
	setup() {
		return {
		};
	},
});
</script>

<style scoped>

</style>
