import {computed, ComputedRef, ref} from 'vue';
import store from '@/store';
import {GreeveApiAuth} from "@/greeve/api/auth";
import {FirebaseAuthentication} from '@capacitor-firebase/authentication';
import useUser from '@/composable/greeve/useUser';

export default function useAuth() {

	const isAuthenticated: ComputedRef<boolean> = computed(() => store.getters['auth/isAuthenticated']);
	const isTokenLifeTimeExpired: ComputedRef<boolean> = computed(() => store.getters['auth/isTokenLifeTimeExpired']);
	const getToken: ComputedRef<string> = computed(() => store.getters['auth/getToken']);
	const getRefreshToken: ComputedRef<string> = computed(() => store.getters['auth/getRefreshToken']);
	const getLifeTime: ComputedRef<string> = computed(() => store.getters['auth/getLifeTime']);

	const errorMessage = ref("");
	const errorTitle = ref("");
	const deviceHash = ref("");

	function checkIsAuthenticated() {
		if ((!isAuthenticated.value || isTokenLifeTimeExpired.value)) {
			GreeveApiAuth.logout()
		}
	}

	const oAuthSignOut = async () => {
		await FirebaseAuthentication.signOut();
	};

	function logout(redirectToStartPage = false, force = false)
	{
		if (isAuthenticated.value || force) {
			if (useUser().user.value.type === 'oauth') {
				oAuthSignOut();
			}
			return GreeveApiAuth.logout(redirectToStartPage);
		}
	}

	function logoutWithoutLoosingCart(force = true)
	{
		if (isAuthenticated.value || force) {
			return GreeveApiAuth.logout(false, false);
		}
	}

	function saveToken(accessToken: string) {
		store.commit('auth/token', accessToken);
		store.commit('auth/authenticating', true);
	}

	function setTokenLifeTime(lifetime: number) {
		store.commit('auth/tokenLifeTime', lifetime);
	}

	function removeToken() {
		store.commit('auth/token', '');
		store.commit('auth/refreshToken', '');
		store.commit('auth/authenticating', false);
	}

	function removeRefreshToken() {
		store.commit('auth/refreshToken', '');
	}

	function saveRefreshToken(refreshToken: string) {
		store.commit('auth/refreshToken', refreshToken);
	}

	async function refreshToken() {
		//TODO
		// const refreshToken = MobiPointApiToken.getRefreshToken();
		// try {
		// 	const response = await MobiPointsApiClient.customRequest(requestData);
		//
		// 	MobiPointApiToken.saveToken(response.data.access_token);
		// 	MobiPointApiToken.saveRefreshToken(response.data.refresh_token);
		// 	MobiPointsApiClient.setHeader();
		//
		// 	return response.data.access_token;
		// } catch (error: any | AxiosError) {
		// 	throw new AuthenticationError(
		// 		error.response.status,
		// 		error.response.data.error_description
		// 	);
		// }
	}

	function calculateTokenLifeTime(days = 10) {
		const date = new Date();
		date.setDate(date.getDate() + days);
		return date;
	}

	function getDeviceHash(maxLength = 254) {
		deviceHash.value = window.navigator.userAgent.substr(0,maxLength);
		return deviceHash.value;
	}

	function clearErrorMessage() {
		errorTitle.value = "";
		errorMessage.value = "";
	}

	function setErrorTitle(title: string) {
		errorTitle.value = title;
	}

	function setErrorMessage(message: string) {
		errorMessage.value = message;
	}

	return {
		isAuthenticated,
		isTokenLifeTimeExpired,
		checkIsAuthenticated,
		getToken,
		getRefreshToken,
		getLifeTime,
		saveToken,
		setTokenLifeTime,
		removeToken,
		removeRefreshToken,
		saveRefreshToken,
		refreshToken,
		calculateTokenLifeTime,
		errorMessage,
		errorTitle,
		clearErrorMessage,
		setErrorTitle,
		setErrorMessage,
		getDeviceHash,
		logout,
		logoutWithoutLoosingCart,
	}
}
