import {BlogFactory} from '@/greeve/blog/blog_factory.type';

export default function useBlogFactory() {

	let blogFactory: BlogFactory | null = null;
	const getBlogFactory = (): BlogFactory => {
		if (!blogFactory) {
			blogFactory = new BlogFactory();
		}
		return blogFactory;
	}

	return {
		getBlogFactory,
	}
}