<template>
	<header class="relative z-10 sticky animated justify-between items-center transition-all duration-200">
	<div class="mx-auto max-w-7xl pb-2">
		<div class="flex items-baseline justify-between bg-gray-50 dark:bg-gr-dark mt-0 m-2 sm:m-4 mb-0 rounded-xl border-gray-200 px-6 py-4 lg:py-4">
			<h1 class="">
				<nav class="flex" aria-label="CheckoutNavbar">
					<ol role="list" class="flex items-center space-x-2 md:space-x-4">
						<li>
							<div>
								<router-link to="/pricing" class="text-gray-400 hover:text-gray-500">
									<CreditCardIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
									<span class="sr-only">{{ $t('pricing') }}</span>
								</router-link>
							</div>
						</li>
						<ChevronRightIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
						<li v-for="(step, stepIdx) in steps" :key="step.name" class="flex items-center">
							<a v-if="step.status === 'completed'" @click="updateStep(step)" aria-current="page"
							   class="flex items-center cursor-pointer text-green-600">
								<span class="text-sm font-semibold text-gray-500 dark:text-gray-300">
									{{ step.name }}
<!--									<ion-icon class="h-3 w-4 text-green-600 dark:text-gray-100"-->
<!--											  :icon="icons.checkmarkOutline"></ion-icon>-->
								</span>
							</a>
							<a v-else-if="step.status === 'current'" @click="updateStep(step)" aria-current="page"
							   class="flex items-center cursor-pointer text-gr-primary">
								<span class="text-sm font-semibold text-gr-primary dark:text-gr-primary">
									{{ step.name }}</span>
							</a>
							<a v-else-if="step.status === 'upcoming'" @click="updateStep(step, true)"
							   aria-current="page" class="flex items-center cursor-pointer text-gray-400">
								<span class="text-sm font-medium text-gray-500 dark:text-gray-500">{{
										step.name
									}}</span>
							</a>
							<ChevronRightIcon v-if="stepIdx !== steps.length - 1" class="ml-4 h-5 w-5 text-gray-400 dark:text-gray-400"
											  aria-hidden="true"/>
						</li>
<!--						-->
<!--						<li v-for="(step, stepIdx) in steps" :key="step.name">-->
<!--							<div class="flex items-center">-->
<!--								<ChevronRightIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />-->
<!--								<router-link :to="page.href" class="ml-2 md:ml-4 text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-gray-700" :aria-current="page.current ? 'page' : undefined">{{ page.name }}</router-link>-->
<!--							</div>-->
<!--						</li>-->
					</ol>
				</nav>
			</h1>
			<div class="flex justify-end items-end items-center">
				<checkout-nav-country class="hidden sm:block"></checkout-nav-country>
				<checkout-nav-cart  class="hidden sm:block"></checkout-nav-cart>
			</div>
		</div>
	</div>
	</header>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref, watch} from 'vue';
import {ChevronRightIcon, CreditCardIcon} from '@heroicons/vue/20/solid'
import {StepInterface} from "@/greeve/shop/checkout/steps/step.interface";
import CheckoutNavCart from "@/components/shop/checkout/nav/CheckoutNavCart.vue";
import CheckoutNavCountry from "@/components/shop/checkout/nav/CheckoutNavCountry.vue";
import * as icons from 'ionicons/icons';
import useTranslation from "@/composable/translation/useTranslation";

export default defineComponent({
	name: "CheckoutNavBar",
	components: {CheckoutNavCountry, CreditCardIcon, CheckoutNavCart, ChevronRightIcon},
	props: {
		steps: {
			type: Array as () => Array<StepInterface>,
			default: () => [],
		},
		currentStep: {
			type: Number,
			default: 1
		},
	},
	emits: ['updateStep'],
	setup(props, {emit}) {
		const {t} = useTranslation();
		const activeStep = ref();

		function updateStep(step: any, upcomingEvent = false) {
			if (activeStep.value.id != step.id && ((activeStep.value.allowedToGoBack && !upcomingEvent) || (activeStep.value.allowedToGoForward && upcomingEvent))) {
				activeStep.value = step;
				emit('updateStep', step.id);
			}
		}

		function setActiveStepByActiveStep(step: number) {
			if (props.steps) {
				activeStep.value = props.steps.find((stepItem) => stepItem.id === step);
			}
		}

		watch(() => props.currentStep, (newValue) => activeStep.value = props.steps.find((stepItem) => stepItem.id === newValue));

		onMounted(() => {
			setActiveStepByActiveStep(props.currentStep);
		})

		return {
			t, activeStep, updateStep, icons
		};
	}
})
</script>

<style scoped>

</style>
