import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-xs sm:text-sm text-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DangerAlert = _resolveComponent("DangerAlert")!
  const _component_SwitchComponent = _resolveComponent("SwitchComponent")!
  const _component_input_nick_mail = _resolveComponent("input-nick-mail")!
  const _component_input_password = _resolveComponent("input-password")!
  const _component_DefaultButton = _resolveComponent("DefaultButton")!
  const _component_LinkButton = _resolveComponent("LinkButton")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _withDirectives(_createVNode(_component_DangerAlert, {
        "error-message": _ctx.errorMessage,
        "error-title": _ctx.errorTitle,
        class: "pb-8",
        "show-title": true,
        onCloseAlert: _ctx.hideErrorMessage
      }, null, 8, ["error-message", "error-title", "onCloseAlert"]), [
        [_vShow, _ctx.showAlerts && _ctx.errorMessage]
      ]),
      _withDirectives(_createVNode(_component_SwitchComponent, {
        wrapper: "cursor-pointer rounded-t-3xl mb-12 rounded-3xl shadow-[inset_0_-2px_4px_rgba(0,0,0,0.6)] p-0 m-0 justify-center pb-0 flex space-x-0 text-md",
        "style-inactive": "rounded-xl bg-bp-complementDarker text-white dark:text-gray-300 shadow-3xl text-center w-full p-3",
        "style-active": "rounded-xl text-gray-700 dark:text-gray-400 text-center w-full p-3",
        tabs: _ctx.tabs
      }, null, 8, ["tabs"]), [
        [_vShow, _ctx.showSwitch]
      ]),
      _createElementVNode("form", {
        class: "space-y-3 -mt-5",
        onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
      }, [
        _createVNode(_component_input_nick_mail, {
          email: _ctx.user.email,
          "onUpdate:email": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.user.email) = $event)),
          "error-message": _ctx.user.emailError
        }, null, 8, ["email", "error-message"]),
        _createVNode(_component_input_password, {
          password: _ctx.user.password,
          "onUpdate:password": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.user.password) = $event)),
          "show-password-strengtho-meter": false,
          "error-message": _ctx.user.passwordError,
          "input-placeholder": "Password"
        }, null, 8, ["password", "error-message"]),
        _createVNode(_component_DefaultButton, {
          "button-text": _ctx.$t('auth.LoginComponent.loginBtnLogin'),
          "button-type": "submit",
          "button-width": "w-full",
          style: {"margin-block":"initial","margin-top":"1.2rem"}
        }, null, 8, ["button-text"]),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_LinkButton, {
            "button-text": _ctx.$t('auth.LoginComponent.loginBtnForgot'),
            "center-button": false,
            "button-alignment": "",
            "button-color": "bg-transparent",
            "button-hover": "",
            "button-width": "",
            "custom-css": "flex p-0 m-0",
            link: "/forgot-password",
            "button-x-padding": "pl-6",
            "text-alignment": "text-left",
            "button-y-padding": "pt-2 pb-0",
            "text-color": "text-gray-600"
          }, null, 8, ["button-text"])
        ]),
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "mt-8" }, [
          _createElementVNode("div", null, [
            _createElementVNode("div", { class: "relative mt-6" })
          ])
        ], -1))
      ], 32)
    ])
  ]))
}