import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, vModelCheckbox as _vModelCheckbox, withModifiers as _withModifiers, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "dark:bg-gr-dark bg-gray-50" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "isolate flex min-h-dvh items-center justify-center p-6 lg:p-8" }
const _hoisted_4 = { class: "w-full max-w-md rounded-xl bg-white shadow-md ring-1 ring-black/5" }
const _hoisted_5 = { class: "p-7 sm:p-11" }
const _hoisted_6 = { class: "flex items-start" }
const _hoisted_7 = { class: "mt-8 text-base/6 font-medium text-black" }
const _hoisted_8 = { class: "mt-1 text-sm/5 text-gray-600" }
const _hoisted_9 = { class: "mt-8" }
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { key: 1 }
const _hoisted_12 = {
  key: 0,
  class: "isolate flex min-h-dvh items-center justify-center p-6 lg:p-8"
}
const _hoisted_13 = { class: "w-full max-w-md rounded-xl bg-white shadow-md ring-1 ring-black/5" }
const _hoisted_14 = { class: "flex items-start" }
const _hoisted_15 = { class: "mt-8 text-base/6 font-medium text-black" }
const _hoisted_16 = { class: "mt-1 text-sm/5 text-gray-600" }
const _hoisted_17 = {
  class: "mt-8 space-y-3",
  "data-headlessui-state": ""
}
const _hoisted_18 = {
  class: "text-sm/5 font-medium text-black",
  id: "headlessui-label-:r1:",
  for: "registerEmail",
  "data-headlessui-state": ""
}
const _hoisted_19 = {
  class: "mt-8 space-y-3",
  "data-headlessui-state": ""
}
const _hoisted_20 = {
  class: "text-sm/5 font-medium text-black",
  id: "headlessui-label-:r4:",
  for: "headlessui-control-:r3:",
  "data-headlessui-state": ""
}
const _hoisted_21 = { class: "mt-8 text-sm/5" }
const _hoisted_22 = { class: "flex items-start" }
const _hoisted_23 = { class: "flex h-5 mt-0.5 items-center" }
const _hoisted_24 = { class: "ml-3 text-sm" }
const _hoisted_25 = {
  for: "offers",
  class: "font-medium text-gray-700 dark:text-gray-400 text-xs"
}
const _hoisted_26 = { class: "text-gray-500 text-xs" }
const _hoisted_27 = { class: "mt-8" }
const _hoisted_28 = {
  type: "submit",
  class: "w-full inline-flex items-center justify-center px-4 py-[calc(theme(spacing.2)-1px)] rounded-full border border-transparent bg-gray-950 shadow-md whitespace-nowrap text-base font-medium text-white data-[disabled]:bg-gray-950 data-[hover]:bg-gray-800 data-[disabled]:opacity-40",
  "data-headlessui-state": ""
}
const _hoisted_29 = { class: "relative mt-10" }
const _hoisted_30 = { class: "relative flex justify-center text-sm font-medium leading-6" }
const _hoisted_31 = { class: "bg-white px-6 text-gray-900" }
const _hoisted_32 = { class: "mt-6 grid grid-cols-1 gap-4" }
const _hoisted_33 = { class: "m-1.5 rounded-lg text-black bg-gray-50 py-4 text-center text-sm/5 ring-1 ring-black/5" }
const _hoisted_34 = {
  key: 1,
  class: "isolate flex min-h-dvh items-center justify-center p-6 lg:p-8"
}
const _hoisted_35 = { class: "w-full max-w-md rounded-xl bg-white shadow-md ring-1 ring-black/5" }
const _hoisted_36 = { class: "flex items-start" }
const _hoisted_37 = { class: "mt-8 text-black text-base/6 font-medium" }
const _hoisted_38 = { class: "mt-1 text-sm/5 text-gray-600" }
const _hoisted_39 = {
  class: "mt-8 space-y-3",
  "data-headlessui-state": ""
}
const _hoisted_40 = {
  class: "text-sm/5 font-medium text-black",
  id: "headlessui-label-:r1:",
  for: "signInEmail",
  "data-headlessui-state": ""
}
const _hoisted_41 = {
  class: "mt-8 space-y-3",
  "data-headlessui-state": ""
}
const _hoisted_42 = {
  class: "text-sm/5 font-medium text-black",
  id: "headlessui-label-:r4:",
  for: "headlessui-control-:r3:",
  "data-headlessui-state": ""
}
const _hoisted_43 = { class: "mt-8 flex items-end justify-end text-sm/5" }
const _hoisted_44 = { class: "mt-8" }
const _hoisted_45 = {
  type: "submit",
  class: "w-full inline-flex items-center justify-center px-4 py-[calc(theme(spacing.2)-1px)] rounded-full border border-transparent bg-gray-950 shadow-md whitespace-nowrap text-base font-medium text-white data-[disabled]:bg-gray-950 data-[hover]:bg-gray-800 data-[disabled]:opacity-40",
  "data-headlessui-state": ""
}
const _hoisted_46 = { class: "relative mt-10" }
const _hoisted_47 = { class: "relative flex justify-center text-sm font-medium leading-6" }
const _hoisted_48 = { class: "bg-white px-6 text-gray-900" }
const _hoisted_49 = { class: "mt-6 grid grid-cols-1 gap-4" }
const _hoisted_50 = { class: "m-1.5 rounded-lg text-black bg-gray-50 py-4 text-center text-sm/5 ring-1 ring-black/5" }

import DangerAlert from '@/components/alert/Base/DangerAlert.vue';
import useAlert from '@/composable/core/useAlert';
import useUser from '@/composable/greeve/useUser';
import useAssistant from '@/composable/greeve/useAssistant';
import useCustomStore from '@/composable/custom/useCustomStore';
import useAuth from '@/composable/auth/useAuth';
import {onMounted, reactive, ref} from 'vue';
import useTranslation from '@/composable/translation/useTranslation';
import {GreeveApiAuth} from '@/greeve/api/auth';
import router from '@/router';
import {RouteChecker} from '@/composable/core/route_checker';
import {useRoute} from 'vue-router';
import PasswordStrenghtOmeter from '@/components/core/PasswordStrenghtOmeter.vue';
import GoogleOAuthComponent from '@/components/auth/firebase/GoogleOAuthComponent.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'AuthComponent',
  setup(__props) {


const {user} = useUser();

const {clearErrorAlert} = useAlert();
const {initUserByUserType, initQuotas, initSubscriptions, initLastUserAddress} = useUser();
const {initSearchGroups} = useAssistant();
const { setIsGlobalLoading } = useCustomStore();
const {isAuthenticated, logout} = useAuth();
const route = useRoute();

const {
	errorMessage,
	errorTitle,
	getDeviceHash,
} = useAuth();

const {t} = useTranslation();
const isRegisterMode = ref(false);
const routeChecker = ref<RouteChecker>();
const signInEmail = ref<HTMLInputElement | null>(null);
const registerEmail = ref<HTMLInputElement | null>(null);
const waitForLoginEvent = ref(false);

const signInUser = reactive({
	email: '',
	password: '',
	emailError: '',
	passwordError: '',
});

const registerUser = reactive({
	email: "",
	emailError: "",
	password: "",
	passwordError: "",
	terms: false,
});

async function sendLoginSuccessEvent() {
	clearUserData();
	await router.push('/');
	setIsGlobalLoading(false);
}

function sendLoginErrorEvent() {
	setIsGlobalLoading(false);
}

const clearUserData = () => {
	signInUser.email = '';
	signInUser.password = '';
	registerUser.email = '';
	registerUser.password = '';
	registerUser.terms = false;
}

function hideErrorMessage()
{
	clearErrorAlert();
	errorMessage.value = '';
	errorTitle.value = '';
}

function handleSignin(token: string, showLoadingAnimation = true) {
	try {
		if (!token) {
			throw new Error('Invalid token');
		}
		if (showLoadingAnimation) {
			setIsGlobalLoading(true);
			clearErrorAlert();
		}
		GreeveApiAuth.getUser().then((userResponse) => {
			if (!userResponse || !userResponse.id) {
				throw new Error('Invalid User');
			}
			initUserByUserType(userResponse);
			clearErrorAlert();
			try {
				initQuotas(true);
				initSubscriptions(true);
				initLastUserAddress();
			} catch (e) {
				console.error(e);
			}
			initSearchGroups(true, true, 5).then(() => {
				waitForLoginEvent.value = false;
				sendLoginSuccessEvent();
			});
		});
	} catch (e) {
		console.error(e);
		errorTitle.value = t('auth.auth_error_failed_title');
		errorMessage.value = t('auth.failed');
		sendLoginErrorEvent();
		setIsGlobalLoading(false);
	}
}

function onSignIn() {
	const loginData = {
		email: signInUser.email,
		password: signInUser.password,
		device_name: getDeviceHash(),
	};
	try {
		setIsGlobalLoading(true);
		clearErrorAlert();
		GreeveApiAuth.signIn(loginData).then((token) => {
			handleSignin(token, false);
		}).catch((e) => {
			console.error('OHH', e);
			errorTitle.value = t('auth.auth_error_failed_title');
			errorMessage.value = t('auth.failed');
			sendLoginErrorEvent();
			setIsGlobalLoading(false);
		});
	} catch (e) {
		console.error(e);
		errorTitle.value = t('auth.auth_error_failed_title');
		errorMessage.value = t('auth.failed');
		sendLoginErrorEvent();
		setIsGlobalLoading(false);
	}
}

function clearRegisterUserErrors() {
	registerUser.emailError = "";
	registerUser.passwordError = "";
}

function onRegister() {
	clearErrorAlert();
	clearRegisterUserErrors();

	const registerData = {
		name: registerUser.email,
		email: registerUser.email,
		password: registerUser.password,
		password_confirmation: registerUser.password,
		terms: registerUser.terms,
		device_name: getDeviceHash(),
	};

	try {
		setIsGlobalLoading(true);
		clearErrorAlert();
		GreeveApiAuth.registerUser(registerData).then((token) => {
			if (!token) {
				throw new Error('Invalid token');
			}
			GreeveApiAuth.getUser().then((userResponse) => {
				if (!userResponse || !userResponse.id) {
					throw new Error('Invalid User');
				}
				initUserByUserType(userResponse);
				clearErrorAlert();
				try {
					initQuotas(true);
					initSubscriptions(true);
					initLastUserAddress();
				} catch (e) {
					console.error(e);
				}
				initSearchGroups(true, true, 5).then(() => {
					sendLoginSuccessEvent();
				})
			});
		}).catch((e) => {
			console.error('OHH', e);
			errorTitle.value = t('auth.auth_error_failed_title');
			errorMessage.value = t('auth.failed');
			if (e.message.email) {
				user.emailError = e.message.email.join("<br>");
			}
			if (e.message.password) {
				user.passwordError = e.message.password.join("<br>");
			}
			sendLoginErrorEvent();
			setIsGlobalLoading(false);
		});
	} catch (e: any) {
		console.error(e);
		errorTitle.value = t('auth.auth_error_failed_title');
		errorMessage.value = t('auth.failed');
		if (e.message.email) {
			user.emailError = e.message.email.join("<br>");
		}
		if (e.message.password) {
			user.passwordError = e.message.password.join("<br>");
		}
		sendLoginErrorEvent();
		setIsGlobalLoading(false);
	}
}

function logoutUser()
{
	try {
		setIsGlobalLoading(true);
		logout(true, true)?.then(() => {
			setIsGlobalLoading(false);
		})
	} catch {
		setIsGlobalLoading(false);
	}
}

const checkMode = () => {
	isRegisterMode.value = routeChecker.value?.isRouteActive(route.path) ?? false;
	if (isRegisterMode.value) {
		initRegisterMode();
	} else {
		initSignInMode();
	}
}

const initRegisterMode = () => {
	const currentQuery = route.query;
	router.replace({ path: '/register', query: { ...currentQuery } });
	isRegisterMode.value = true;
	if (registerEmail.value) {
		registerEmail.value.focus();
	}
};

const initSignInMode = () => {
	const currentQuery = route.query;
	router.replace({ path: '/auth', query: { ...currentQuery } });

	isRegisterMode.value = false;
	if (signInEmail.value) {
		signInEmail.value.focus();
	}}

onMounted(() => {
	routeChecker.value = new RouteChecker(['/register']);
	checkMode();
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _cache[22] || (_cache[22] = _createElementVNode("div", { class: "relative mx-auto max-w-7xl" }, [
      _createElementVNode("div", { class: "absolute -right-60 -top-44 h-60 w-[36rem] transform-gpu md:right-0 bg-[linear-gradient(115deg,var(--tw-gradient-stops))] from-[#fff1be] from-[28%] via-[#ee87cb] via-[70%] to-[#b060ff] rotate-[-10deg] rounded-full blur-3xl" })
    ], -1)),
    _withDirectives(_createVNode(DangerAlert, {
      "error-message": _unref(errorMessage),
      "error-title": _unref(errorTitle),
      class: "pb-8",
      "css-class": "absolute w-auto right-0",
      "show-title": true,
      onCloseAlert: hideErrorMessage
    }, null, 8, ["error-message", "error-title"]), [
      [_vShow, _unref(errorMessage)]
    ]),
    (_unref(isAuthenticated) && !waitForLoginEvent.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("a", {
                    title: "Home",
                    "data-headlessui-state": "",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push('/'))),
                    class: "cursor-pointer"
                  }, _cache[13] || (_cache[13] = [
                    _createElementVNode("img", {
                      class: "h-9",
                      src: "https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/images/logo/icon-text.webp",
                      alt: "greeve_logo"
                    }, null, -1)
                  ]))
                ]),
                _createElementVNode("h1", _hoisted_7, _toDisplayString(_ctx.$t('auth.General.already_logged_in')), 1),
                _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('not_user_x', {name: _unref(user).name})), 1),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("button", {
                    onClick: logoutUser,
                    class: "w-full inline-flex items-center justify-center px-4 py-[calc(theme(spacing.2)-1px)] rounded-full border border-transparent bg-gray-950 shadow-md whitespace-nowrap text-base font-medium text-white data-[disabled]:bg-gray-950 data-[hover]:bg-gray-800 data-[disabled]:opacity-40",
                    "data-headlessui-state": "",
                    innerHTML: _ctx.$t('shop.checkout.logout.title')
                  }, null, 8, _hoisted_10)
                ])
              ])
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_11, [
          (isRegisterMode.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("form", {
                    class: "p-7 sm:p-11",
                    onSubmit: _withModifiers(onRegister, ["prevent"])
                  }, [
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("a", {
                        title: "Home",
                        "data-headlessui-state": "",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(router).push('/'))),
                        class: "cursor-pointer"
                      }, _cache[14] || (_cache[14] = [
                        _createElementVNode("img", {
                          class: "h-9",
                          src: "https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/images/logo/icon-text.webp",
                          alt: "greeve_logo"
                        }, null, -1)
                      ]))
                    ]),
                    _createElementVNode("h1", _hoisted_15, _toDisplayString(_ctx.$t('auth.RegisterComponent.title')), 1),
                    _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.$t('auth.RegisterComponent.description')), 1),
                    _createElementVNode("div", _hoisted_17, [
                      _createElementVNode("label", _hoisted_18, _toDisplayString(_ctx.$t('jobs.FormMail')), 1),
                      _withDirectives(_createElementVNode("input", {
                        required: "",
                        class: "block w-full rounded-lg text-black border border-transparent shadow ring-1 ring-black/10 px-[calc(theme(spacing.2)-1px)] py-[calc(theme(spacing[1.5])-1px)] text-base/6 sm:text-sm/6 data-[focus]:outline data-[focus]:outline-2 data-[focus]:-outline-offset-1 data-[focus]:outline-black",
                        ref_key: "registerEmail",
                        ref: registerEmail,
                        "data-headlessui-state": "autofocus",
                        "data-autofocus": "",
                        type: "email",
                        name: "registerEmail",
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((registerUser.email) = $event)),
                        autofocus: true,
                        "aria-labelledby": "headlessui-label-:r1:"
                      }, null, 512), [
                        [_vModelText, registerUser.email]
                      ]),
                      _cache[15] || (_cache[15] = _createElementVNode("span", {
                        hidden: "",
                        style: {"position":"fixed","top":"1px","left":"1px","width":"1px","height":"0px","padding":"0px","margin":"-1px","overflow":"hidden","clip":"rect(0px, 0px, 0px, 0px)","white-space":"nowrap","border-width":"0px","display":"none"}
                      }, null, -1))
                    ]),
                    _createElementVNode("div", _hoisted_19, [
                      _createElementVNode("label", _hoisted_20, _toDisplayString(_ctx.$t('auth.LoginComponent.password')), 1),
                      _withDirectives(_createElementVNode("input", {
                        required: "",
                        class: "block w-full rounded-lg border text-black border-transparent shadow ring-1 ring-black/10 px-[calc(theme(spacing.2)-1px)] py-[calc(theme(spacing[1.5])-1px)] text-base/6 sm:text-sm/6 data-[focus]:outline data-[focus]:outline-2 data-[focus]:-outline-offset-1 data-[focus]:outline-black",
                        id: "headlessui-control-:r3:",
                        "data-headlessui-state": "",
                        type: "password",
                        name: "password",
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((registerUser.password) = $event)),
                        "aria-labelledby": "headlessui-label-:r4:"
                      }, null, 512), [
                        [_vModelText, registerUser.password]
                      ]),
                      _cache[16] || (_cache[16] = _createElementVNode("span", {
                        hidden: "",
                        style: {"position":"fixed","top":"1px","left":"1px","width":"1px","height":"0px","padding":"0px","margin":"-1px","overflow":"hidden","clip":"rect(0px, 0px, 0px, 0px)","white-space":"nowrap","border-width":"0px","display":"none"}
                      }, null, -1))
                    ]),
                    _createVNode(PasswordStrenghtOmeter, {
                      "current-password": registerUser.password,
                      "show-password-strengtho-meter": registerUser.password.length > 0
                    }, null, 8, ["current-password", "show-password-strengtho-meter"]),
                    _createElementVNode("div", _hoisted_21, [
                      _createElementVNode("div", _hoisted_22, [
                        _createElementVNode("div", _hoisted_23, [
                          _withDirectives(_createElementVNode("input", {
                            id: "terms",
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((registerUser.terms) = $event)),
                            "aria-describedby": "terms-description",
                            name: "terms",
                            required: "",
                            type: "checkbox",
                            class: "h-4 w-4 rounded border-gray-200 text-gr-primary2"
                          }, null, 512), [
                            [_vModelCheckbox, registerUser.terms]
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_24, [
                          _createElementVNode("label", _hoisted_25, _toDisplayString(_ctx.$t("terms.TermsTitle")), 1),
                          _createElementVNode("p", _hoisted_26, _toDisplayString(_ctx.$t("auth.RegisterComponent.dataProcessing")), 1)
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_27, [
                      _createElementVNode("button", _hoisted_28, _toDisplayString(_ctx.$t('auth.RegisterComponent.registerBtnRegister')), 1)
                    ]),
                    _createElementVNode("div", null, [
                      _createElementVNode("div", _hoisted_29, [
                        _cache[17] || (_cache[17] = _createElementVNode("div", {
                          class: "absolute inset-0 flex items-center",
                          "aria-hidden": "true"
                        }, [
                          _createElementVNode("div", { class: "w-full border-t border-gray-200" })
                        ], -1)),
                        _createElementVNode("div", _hoisted_30, [
                          _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.$t('auth.RegisterComponent.registerExternal')), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_32, [
                        _createVNode(GoogleOAuthComponent, {
                          onOnSuccess: handleSignin,
                          onOnError: sendLoginErrorEvent,
                          onOnStart: _cache[5] || (_cache[5] = ($event: any) => (waitForLoginEvent.value = true)),
                          onOnEnd: _cache[6] || (_cache[6] = ($event: any) => (waitForLoginEvent.value = false))
                        })
                      ])
                    ])
                  ], 32),
                  _createElementVNode("div", _hoisted_33, [
                    _createElementVNode("a", {
                      class: "font-medium hover:text-gray-600 underline cursor-pointer",
                      "data-headlessui-state": "",
                      onClick: initSignInMode
                    }, _toDisplayString(_ctx.$t('auth.LoginComponent.loginTitle')), 1)
                  ])
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_34, [
                _createElementVNode("div", _hoisted_35, [
                  _createElementVNode("form", {
                    class: "p-7 sm:p-11",
                    onSubmit: _withModifiers(onSignIn, ["prevent"])
                  }, [
                    _createElementVNode("div", _hoisted_36, [
                      _createElementVNode("a", {
                        title: "Home",
                        "data-headlessui-state": "",
                        onClick: _cache[7] || (_cache[7] = ($event: any) => (_unref(router).push('/'))),
                        class: "cursor-pointer"
                      }, _cache[18] || (_cache[18] = [
                        _createElementVNode("img", {
                          class: "h-9",
                          src: "https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/images/logo/icon-text.webp",
                          alt: "greeve_logo"
                        }, null, -1)
                      ]))
                    ]),
                    _createElementVNode("h1", _hoisted_37, _toDisplayString(_ctx.$t('auth.General.welcome_back')), 1),
                    _createElementVNode("p", _hoisted_38, _toDisplayString(_ctx.$t('auth.General.welcome_back_description')), 1),
                    _createElementVNode("div", _hoisted_39, [
                      _createElementVNode("label", _hoisted_40, _toDisplayString(_ctx.$t('jobs.FormMail')), 1),
                      _withDirectives(_createElementVNode("input", {
                        required: "",
                        class: "block w-full rounded-lg border border-transparent shadow text-black ring-1 ring-black/10 px-[calc(theme(spacing.2)-1px)] py-[calc(theme(spacing[1.5])-1px)] text-base/6 sm:text-sm/6 data-[focus]:outline data-[focus]:outline-2 data-[focus]:-outline-offset-1 data-[focus]:outline-black",
                        id: "signInEmail",
                        ref_key: "signInEmail",
                        ref: signInEmail,
                        "data-headlessui-state": "autofocus",
                        type: "email",
                        name: "signInEmail",
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((signInUser.email) = $event)),
                        autofocus: true,
                        "aria-labelledby": "headlessui-label-:r1:"
                      }, null, 512), [
                        [_vModelText, signInUser.email]
                      ]),
                      _cache[19] || (_cache[19] = _createElementVNode("span", {
                        hidden: "",
                        style: {"position":"fixed","top":"1px","left":"1px","width":"1px","height":"0px","padding":"0px","margin":"-1px","overflow":"hidden","clip":"rect(0px, 0px, 0px, 0px)","white-space":"nowrap","border-width":"0px","display":"none"}
                      }, null, -1))
                    ]),
                    _createElementVNode("div", _hoisted_41, [
                      _createElementVNode("label", _hoisted_42, _toDisplayString(_ctx.$t('auth.LoginComponent.password')), 1),
                      _withDirectives(_createElementVNode("input", {
                        required: "",
                        class: "block w-full rounded-lg border border-transparent text-black shadow ring-1 ring-black/10 px-[calc(theme(spacing.2)-1px)] py-[calc(theme(spacing[1.5])-1px)] text-base/6 sm:text-sm/6 data-[focus]:outline data-[focus]:outline-2 data-[focus]:-outline-offset-1 data-[focus]:outline-black",
                        id: "headlessui-control-:r3:",
                        "data-headlessui-state": "",
                        type: "password",
                        name: "password",
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((signInUser.password) = $event)),
                        "aria-labelledby": "headlessui-label-:r4:"
                      }, null, 512), [
                        [_vModelText, signInUser.password]
                      ]),
                      _cache[20] || (_cache[20] = _createElementVNode("span", {
                        hidden: "",
                        style: {"position":"fixed","top":"1px","left":"1px","width":"1px","height":"0px","padding":"0px","margin":"-1px","overflow":"hidden","clip":"rect(0px, 0px, 0px, 0px)","white-space":"nowrap","border-width":"0px","display":"none"}
                      }, null, -1))
                    ]),
                    _createElementVNode("div", _hoisted_43, [
                      _createElementVNode("a", {
                        class: "font-medium hover:text-gray-600 cursor-pointer text-gray-700",
                        "data-headlessui-state": "",
                        onClick: _cache[10] || (_cache[10] = ($event: any) => (_unref(router).push('/forgot-password')))
                      }, _toDisplayString(_ctx.$t('auth.LoginComponent.loginBtnForgot')), 1)
                    ]),
                    _createElementVNode("div", _hoisted_44, [
                      _createElementVNode("button", _hoisted_45, _toDisplayString(_ctx.$t('auth.LoginComponent.loginBtnLogin')), 1)
                    ]),
                    _createElementVNode("div", null, [
                      _createElementVNode("div", _hoisted_46, [
                        _cache[21] || (_cache[21] = _createElementVNode("div", {
                          class: "absolute inset-0 flex items-center",
                          "aria-hidden": "true"
                        }, [
                          _createElementVNode("div", { class: "w-full border-t border-gray-200" })
                        ], -1)),
                        _createElementVNode("div", _hoisted_47, [
                          _createElementVNode("span", _hoisted_48, _toDisplayString(_ctx.$t('auth.RegisterComponent.registerExternal')), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_49, [
                        _createVNode(GoogleOAuthComponent, {
                          onOnSuccess: handleSignin,
                          onOnError: sendLoginErrorEvent,
                          onOnStart: _cache[11] || (_cache[11] = ($event: any) => (waitForLoginEvent.value = true)),
                          onOnEnd: _cache[12] || (_cache[12] = ($event: any) => (waitForLoginEvent.value = false))
                        })
                      ])
                    ])
                  ], 32),
                  _createElementVNode("div", _hoisted_50, [
                    _createTextVNode(_toDisplayString(_ctx.$t('auth.LoginComponent.notAMember')) + " ", 1),
                    _createElementVNode("a", {
                      class: "font-medium hover:text-gray-600 underline cursor-pointer",
                      onClick: initRegisterMode
                    }, _toDisplayString(_ctx.$t('auth.LoginComponent.createAccount')), 1)
                  ])
                ])
              ]))
        ]))
  ]))
}
}

})