import {ref, onMounted, onUnmounted, computed} from 'vue';

const headerHeightValue = ref(0);
let mutationObserver: MutationObserver | null = null;

export function useHeaderHeight() {

	const headerHeight = computed(() => {
				return headerHeightValue.value;
			},
	);

	const setHeaderHeight = (height: number) => {
		headerHeightValue.value = height;
	};

	const initHeaderHeight = () => {
		const headerElement = document.querySelector('ion-header');
		if (headerElement) {
			setHeaderHeight(headerElement.offsetHeight);
		}
	}

	onMounted(() => {
		const headerElement = document.querySelector('ion-header');
		if (headerElement) {
			setHeaderHeight(headerElement.offsetHeight);

			mutationObserver = new MutationObserver(() => {
				setHeaderHeight(headerElement.offsetHeight);
			});

			mutationObserver.observe(headerElement, { attributes: true });
		}
	});
	onUnmounted(() => {
		if (mutationObserver) {
			mutationObserver.disconnect();
		}
	});

	return { initHeaderHeight, headerHeight, setHeaderHeight };
}
