import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.initializeMetaAuth && _ctx.initializeMetaAuth(...args))),
    class: "cursor-pointer hover:bg-gray-100 hover:text-gray-900 text-gray-700 block px-4 py-2 text-sm flex"
  }, [
    _createElementVNode("img", {
      src: _ctx.getImageUrlByType(),
      alt: 'Login with ' + _ctx.getNameByType(),
      class: "h-5 pr-2"
    }, null, 8, _hoisted_1),
    (_ctx.business)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.getNameByType()) + " Business", 1))
      : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.getNameByType()), 1))
  ]))
}