<template>
	<ion-page>
		<ion-content>
			<main :style="{ paddingTop: headerHeight + 'px' }">
				<AuthComponent></AuthComponent>
			</main>
			<FooterBar class="mt-12"></FooterBar>
		</ion-content>
	</ion-page>
</template>

<script setup lang="ts">
import {onMounted, ref} from 'vue';
import {IonPage, IonContent} from '@ionic/vue';
import FooterBar from '@/components/positions/FooterBar.vue';
import useSystem from '@/composable/core/useSystem';
import {useHeaderHeight} from '@/composable/global/useHeaderHeight';
import router from '@/router';
import useCustomStore from '@/composable/custom/useCustomStore';
import {useRoute} from 'vue-router';
import useAuth from '@/composable/auth/useAuth';
import AuthComponent from '@/components/auth/AuthComponent.vue';

const LOGIN_SEGMENT = 'login';
const REGISTER_SEGMENT = 'register';

const {headerHeight} = useHeaderHeight();
const {isAuthenticated} = useAuth();
const {addLeadingSlash, getQueryByKey} = useSystem();
const {setIsGlobalLoading} = useCustomStore();
const defaultSegment = ref(LOGIN_SEGMENT);
const route = useRoute();

onMounted(() => {
	if (isAuthenticated.value) {
		const redirectQuery = getQueryByKey('redirect');
		if (redirectQuery) {
			setTimeout(() => {
				router.push(addLeadingSlash(redirectQuery));
				setIsGlobalLoading(false);
			}, 100);
		}
	} else if (route.path === '/register') {
		defaultSegment.value = REGISTER_SEGMENT;
	}
});

</script>
<style>
</style>
