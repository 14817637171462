import {FlowMediaList} from '@/greeve/flow/media/flow_media_list.type';
import {FlowMedia} from '@/greeve/flow/media/flow_media.type';

export class FlowMediaFactory {
	/**
	 *
	 * @param flow_media_data
	 */
	createFlowMediaListByResponse(flow_media_data: Record<string, any>): FlowMediaList {
		const flowMediaList = this.createEmptyFlowMediaList();

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		for (const [key, item] of Object.entries(flow_media_data)) {
			if (item === undefined) {
				continue;
			}
			flowMediaList.push(this.createFlowMediaByResponse(item));
		}
		return flowMediaList;
	}

	/**
	 *
	 * @param item
	 */
	createFlowMediaByResponse(item: Record<string, any>): FlowMedia {
		const id = item.id;
		const model_type = item.model_type;
		const model_id = item.model_id;
		const uuid = item.uuid;
		const collection_name = item.collection_name;
		const name = item.name;
		const file_name = item.file_name;
		const mime_type = item.mime_type;
		const disk = item.disk;
		const conversions_disk = item.conversions_disk;
		const size = item.size;
		const order_column = Number(item.order_column);
		const original_url = item.original_url;
		const preview_url = item.preview_url ?? undefined;
		const manipulations = item.manipulations ?? undefined;
		const custom_properties = item.custom_properties ?? undefined;
		const generated_conversions = item.generated_conversions ?? undefined;
		const responsive_images = item.responsive_images ?? undefined;
		const data = item.data;
		const created_at = item.created_at ? new Date(item.created_at) : (item.createdAt ? new Date(item.createdAt) : new Date());
		const updated_at = item.updated_at ? new Date(item.updated_at) : (item.updatedAt ? new Date(item.updatedAt) : new Date());

		return new FlowMedia(id, model_type, model_id, uuid, collection_name, name, file_name, mime_type, disk, conversions_disk, size, order_column, original_url, preview_url, manipulations, custom_properties, generated_conversions, responsive_images, data, created_at, updated_at);
	}

	createEmptyFlowMediaList(): FlowMediaList {
		return new FlowMediaList([]);
	}
}