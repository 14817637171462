import {PaginationMetaInterface} from "@/greeve/core/pagination_meta.interface";
import {
	AbstractFlowProcess
} from '@/greeve/flow/process/abstract_flow_process.type';

export class FlowProcessList extends Array<AbstractFlowProcess> {

	public paginationMetaData?: PaginationMetaInterface;

	constructor(items: Array<AbstractFlowProcess> = []) {
		super();
		this.push(...items);
	}

	add(items: AbstractFlowProcess) {
		this.push(items);
	}

	remove(index: number) {
		this.splice(index, 1);
	}

	removeAll() {
		const itemCount = this.length;
		this.splice(itemCount, 1);
	}

	getItem(index: number): AbstractFlowProcess {
		return this[index];
	}

	getItemById(item_id: number): AbstractFlowProcess | undefined {
		return this.find(({id}) => id === item_id);
	}

	getItemByUuId(item_uuid: string): AbstractFlowProcess | undefined {
		return this.find(({uuid}) => uuid === item_uuid);
	}

	getUniqueList(): FlowProcessList
	{
		const currentFlowProcessList: FlowProcessList|any[] = [...this];
		const flowProcessList: FlowProcessList = new FlowProcessList();
		const seen = new Set();

		for (const flowProcess of currentFlowProcessList) {
			if (!seen.has(flowProcess.id)) {
				seen.add(flowProcess.id);
				flowProcessList.push(flowProcess);
			}
		}
		return flowProcessList;
	}

	enhanceMetaData(metaData: any) {
		this.paginationMetaData = metaData;
	}
}