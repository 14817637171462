<template>
	<Popover class="sm:ml-4 flow-root text-sm lg:relative lg:ml-8">
		<PopoverButton class="group flex items-center bg-gray-100 outline-none hover:bg-gray-200 dark:hover:bg-gr-dark dark:bg-gr-darker rounded-xl px-2 py-2 lg:py-1.5">
			<a href="#" class="flex items-center  text-gray-500 group-hover:text-gray-600">
				<component :is="cart.getCurrencyObject().getCurrencyIcon()" class="block h-auto w-4 flex-shrink-0"></component>
				<span class="ml-2 hidden lg:block text-sm font-medium">{{cart.getCurrencyObject().code.toUpperCase()}}</span>
				<span class="sr-only">{{ $t('changeCurrency') }}</span>
			</a>
		</PopoverButton>
		<transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0" enter-to-class="opacity-100" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100" leave-to-class="opacity-0">
			<!--suppress AllyPlainJsInspection -->
			<PopoverPanel
					:class="'mt-px bg-white dark:bg-gr-darker pb-0 shadow-lg sm:px-2 rounded-xl' + ' ' + popoverPosition + ' ' + popoverSize">
				<h2 class="sr-only">{{ $t('whleDeineWhrungAus') }}</h2>

				<ul role="list" class="divide-y divide-gray-200">
					<li v-for="currency in activeCurrency.filter(currencyItem => {return currencyItem.code !== cart.currency})" :key="currency.code" class="flex items-center py-6 cursor-pointer" @click="selectCurrency(currency)">
						<div class="flex items-center text-gray-700 hover:text-gray-800">
							<component :is="cart.getCurrencyObject().getCurrencyIcon()" class="block h-auto w-7 flex-shrink-0"></component>
							<span class="ml-3 block text-sm font-medium">{{currency.code.toUpperCase()}}</span>
							<span class="sr-only">{{ $t('changeCurrency') }}</span>
						</div>
					</li>
					<li class="flex items-center">
						<div class="flex m-4 items-center text-gray-400 dark:text-gray-500 font-medium">
							<p>{{ $t('imMomentUntersttzenWirNurDenEurAlsWhrungAberInNahe') }}</p>
						</div>
					</li>
				</ul>
			</PopoverPanel>
		</transition>
	</Popover>
</template>

<script lang="ts">
import {onMounted, defineComponent} from 'vue';
import {Popover, PopoverButton, PopoverPanel} from '@headlessui/vue'
import useCurrency from "@/composable/core/useCurrency";
import useShop from "@/composable/greeve/useShop";
import {Currency} from "@/greeve/core/country/currency.type";
import {toUpperCase} from "uri-js/dist/esnext/util";

export default defineComponent({
	name: "CheckoutNavCountry",
	methods: {toUpperCase},
	components: {Popover, PopoverButton, PopoverPanel},
	props: {
		checkoutLink: {
			type: String,
			default: ''
		},
		viewShoppingBagLink: {
			type: String,
			default: ''
		},
		popoverPosition: {
			type: String,
			default: 'absolute inset-x-0 left-auto top-14 lg:top-10 right-28 lg:right-1 -mr-1.5'
		},
		popoverSize: {
			type: String,
			default: ' w-80'
		},
	},
	emits: [],
	setup() {
		const {cart, setCurrency} = useShop();
		const {activeCurrency} = useCurrency();

		function selectCurrency(currency: Currency) {
			console.log("test currency", currency);
			setCurrency(currency.code);
		}

		onMounted(() => {
			// const faqList: FaqList|any[] = [...this];
			// return faqList.filter((faq) => {
			// 	return faq.language === language
			// });
			// setActiveStepByActiveStep(props.currentStep);
		})

		return {
			cart,
			activeCurrency,
			selectCurrency
		};
	}
})
</script>

<style scoped>

</style>
