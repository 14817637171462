import {FlowSubType} from '@/greeve/flow/flow.interface';
import {
	WizzardWhatsAppDefaultNewsletter
} from '@/greeve/flow/wizzard/whatsapp/wizzard_whatsapp_default_newsletter';
import {AbstractFlow} from '@/greeve/flow/abstract_flow.type';

export class WizzardFactory {
	createWizzardByType(type: FlowSubType) {
		switch (type) {
			case FlowSubType.WHATSAPP_DEFAULT_NEWSLETTER:
				return new WizzardWhatsAppDefaultNewsletter();
			default:
				throw new Error(`Unknown wizzard type ${type}`);
		}
	}

	createWizzardByFlow(flow: AbstractFlow) {
		if (!flow.sub_type) {
			throw new Error('Invalid flow');
		}
		const wizzard = this.createWizzardByType(flow.sub_type);
		wizzard.flow = flow;
		return wizzard;
	}

	createWizzardListByFlows(flows: AbstractFlow[]) {
		return flows.map(flow => this.createWizzardByFlow(flow));
	}
}