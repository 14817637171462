import {
	FlowQueueJournalInterface, FlowQueueJournalState, FlowQueueJournalType,
} from '@/greeve/flow/queue/journal/flow_queue_journal.interface';

export class FlowQueueJournal implements FlowQueueJournalInterface {
	id: number;
	uuid: string;
	flow_queue_id: number;
	flow_process_id?: number|null;
	type: FlowQueueJournalType;
	state: FlowQueueJournalState;
	reference?: string|null;
	title?: string|null;
	description?: string|null;
	data?: any;
	created_at?: Date;
	updated_at?: Date;


	constructor(id: number, uuid: string, flow_queue_id: number, type: FlowQueueJournalType, state: FlowQueueJournalState, flow_process_id?: number|null, reference?: string|null, title?: string|null, description?: string|null, data: any = null, created_at?: Date, updated_at?: Date) {
		this.id = id;
		this.uuid = uuid;
		this.flow_queue_id = flow_queue_id;
		this.type = type;
		this.state = state;
		this.flow_process_id = flow_process_id;
		this.reference = reference;
		this.title = title;
		this.description = description;
		this.data = data;
		this.created_at = created_at;
		this.updated_at = updated_at;
	}
}