import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "mx-4 md:mx-auto max-w-7xl" }
const _hoisted_2 = { class: "mx-auto grid max-w-md grid-cols-1 gap-8 lg:max-w-4xl lg:grid-cols-2 lg:gap-8" }
const _hoisted_3 = { class: "flex h-full flex-col" }
const _hoisted_4 = { class: "flex flex-row" }
const _hoisted_5 = { class: "inline-flex h-10 w-10 items-center justify-center rounded-xl shadow-md bg-black text-white sm:h-12 sm:w-12" }
const _hoisted_6 = { class: "ml-0 flex md:flex-1 flex-col justify-between mt-4" }
const _hoisted_7 = ["id"]
const _hoisted_8 = { class: "mt-1 text-sm text-gray-500 dark:text-gray-400 line-clamp-2" }
const _hoisted_9 = { class: "px-2 item-center flex-auto flex space-x-4" }
const _hoisted_10 = { class: "relative mt-2" }
const _hoisted_11 = { class: "inline-flex w-full truncate" }
const _hoisted_12 = { class: "truncate" }
const _hoisted_13 = { class: "ml-2 truncate text-gray-500" }
const _hoisted_14 = { class: "pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2" }
const _hoisted_15 = { class: "flex" }
const _hoisted_16 = { class: "flex space-x-4 mb-3 mx-2 text-sm font-medium" }
const _hoisted_17 = { class: "flex-auto flex space-x-4" }
const _hoisted_18 = {
  class: "h-10 w-full px-6",
  type: "submit"
}
const _hoisted_19 = ["onClick", "aria-describedby"]
const _hoisted_20 = {
  key: 0,
  class: "mx-auto flex align-center justify-center content-center"
}
const _hoisted_21 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ChevronDownIcon = _resolveComponent("ChevronDownIcon")!
  const _component_ListboxButton = _resolveComponent("ListboxButton")!
  const _component_CheckIcon = _resolveComponent("CheckIcon")!
  const _component_ListboxOption = _resolveComponent("ListboxOption")!
  const _component_ListboxOptions = _resolveComponent("ListboxOptions")!
  const _component_Listbox = _resolveComponent("Listbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (product) => {
        return (_openBlock(), _createElementBlock("div", {
          key: product.product_id,
          class: "flex w-full flex-col md:mr-4 p-4 mb-4 rounded-2xl outer-card bg-gr-baser dark:bg-gr-dark"
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ServiceLinks, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.name
            }, [
              _createVNode(_component_router_link, {
                to: item.href,
                class: "bg-gray-100 dark:bg-gr-darker flex flex-col justify-between rounded-2xl m-2 p-6 hover:bg-gray-100 dark:hover:bg-gr-dark"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("span", _hoisted_5, [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), {
                          class: "h-6 w-6",
                          "aria-hidden": "true"
                        }))
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", null, [
                        _createElementVNode("p", {
                          class: "text-base font-medium text-gray-900 dark:text-gray-200 font-semibold truncate",
                          id: product.product_id
                        }, _toDisplayString(product.description) + " " + _toDisplayString(item.name), 9, _hoisted_7),
                        _createElementVNode("p", _hoisted_8, _toDisplayString(product.description) + " " + _toDisplayString(item.description), 1)
                      ])
                    ])
                  ])
                ]),
                _: 2
              }, 1032, ["to"])
            ]))
          }), 128)),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_Listbox, {
              class: "w-full pb-4",
              as: "div",
              modelValue: _ctx.selected,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event))
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_ListboxButton, { class: "relative w-full cursor-default rounded-xl py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 sm:text-sm sm:leading-6" }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_11, [
                        _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.selected.name), 1),
                        _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.selected.points), 1)
                      ]),
                      _createElementVNode("span", _hoisted_14, [
                        _createVNode(_component_ChevronDownIcon, {
                          class: "h-5 w-5 text-gray-400",
                          "aria-hidden": "true"
                        })
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_Transition, {
                    "leave-active-class": "transition ease-in duration-100",
                    "leave-from-class": "opacity-100",
                    "leave-to-class": "opacity-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ListboxOptions, { class: "absolute z-10 mt-1.5 max-h-60 w-full overflow-auto rounded-xl bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm" }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.piece, (piece) => {
                            return (_openBlock(), _createBlock(_component_ListboxOption, {
                              as: "template",
                              key: piece.points,
                              value: piece
                            }, {
                              default: _withCtx(({ active, selected }) => [
                                _createElementVNode("li", {
                                  class: _normalizeClass([active ? 'bg-gray-200' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9'])
                                }, [
                                  _createElementVNode("div", _hoisted_15, [
                                    _createElementVNode("span", {
                                      class: _normalizeClass([selected ? 'font-semibold' : 'font-normal', 'truncate'])
                                    }, _toDisplayString(piece.name), 3),
                                    _createElementVNode("span", {
                                      class: _normalizeClass([active ? 'text-gray-700' : 'text-gray-500', 'ml-2 truncate'])
                                    }, _toDisplayString(piece.points), 3)
                                  ]),
                                  selected
                                    ? (_openBlock(), _createElementBlock("span", {
                                        key: 0,
                                        class: _normalizeClass([active ? 'text-gray-900' : 'text-gray-900', 'absolute inset-y-0 right-0 flex items-center pr-4'])
                                      }, [
                                        _createVNode(_component_CheckIcon, {
                                          class: "h-5 w-5",
                                          "aria-hidden": "true"
                                        })
                                      ], 2))
                                    : _createCommentVNode("", true)
                                ], 2)
                              ]),
                              _: 2
                            }, 1032, ["value"]))
                          }), 128))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, [
              _createVNode(_component_router_link, {
                to: "/pricing",
                class: "h-10 w-full px-6 font-semibold rounded-xl bg-gr-primary text-white"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("button", _hoisted_18, _toDisplayString(_ctx.$t('useTemplate')), 1)
                ]),
                _: 1
              }),
              _createElementVNode("button", {
                onClick: ($event: any) => (_ctx.addProductToCart_by_Product(product)),
                "aria-describedby": product.product_id,
                class: "h-10 w-full px-6 font-semibold rounded-xl bg-black text-white",
                type: "button"
              }, [
                (_ctx.addToCartLoading)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_20, _cache[1] || (_cache[1] = [
                      _createElementVNode("svg", {
                        class: "animate-spin mr-2 h-5 w-5",
                        xmlns: "http://www.w3.org/2000/svg",
                        fill: "none",
                        viewBox: "0 0 24 24"
                      }, [
                        _createElementVNode("circle", {
                          class: "opacity-25",
                          cx: "12",
                          cy: "12",
                          r: "10",
                          stroke: "currentColor",
                          "stroke-width": "4"
                        }),
                        _createElementVNode("path", {
                          class: "opacity-75",
                          fill: "currentColor",
                          d: "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        })
                      ], -1)
                    ])))
                  : (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString(_ctx.$t('addToCart')), 1))
              ], 8, _hoisted_19)
            ])
          ])
        ]))
      }), 128))
    ])
  ]))
}