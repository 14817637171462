import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "block truncate" }
const _hoisted_2 = { class: "pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2" }
const _hoisted_3 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListboxLabel = _resolveComponent("ListboxLabel")!
  const _component_ChevronDownIcon = _resolveComponent("ChevronDownIcon")!
  const _component_ListboxButton = _resolveComponent("ListboxButton")!
  const _component_CheckIcon = _resolveComponent("CheckIcon")!
  const _component_ListboxOption = _resolveComponent("ListboxOption")!
  const _component_ListboxOptions = _resolveComponent("ListboxOptions")!
  const _component_Listbox = _resolveComponent("Listbox")!

  return (_ctx.selectList && _ctx.selected)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass([_ctx.minWidth.length > 0 ? _ctx.minWidth : ''])
      }, [
        _createVNode(_component_Listbox, {
          as: "div",
          modelValue: _ctx.selected,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event)),
          class: _normalizeClass([_ctx.showAbove ? 'mb-1 bottom-full' : 'mt-1 top-full'])
        }, {
          default: _withCtx(({ open }) => [
            (_ctx.showLabel)
              ? (_openBlock(), _createBlock(_component_ListboxLabel, {
                  key: 0,
                  class: "block text-sm font-medium leading-6 text-gray-900",
                  innerHTML: _ctx.labelText
                }, null, 8, ["innerHTML"]))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: _normalizeClass(['relative', _ctx.topMargin ? 'mt-2' : '', open ? 'z-50' : 'z-10'])
            }, [
              _createVNode(_component_ListboxButton, {
                class: _normalizeClass(['relative w-full cursor-default  rounded-lg  ring-1 ring-black ring-opacity-5  bg-white dark:bg-gr-darker py-1.5 pl-3 pr-10 text-left font-medium text-gray-900 dark:text-gray-400 shadow-sm focus:outline-none focus:ring-2 sm:text-sm sm:leading-6', 'focus:ring-'+_ctx.bgColor])
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.getItemName(_ctx.selected)), 1),
                  _createElementVNode("span", _hoisted_2, [
                    _createVNode(_component_ChevronDownIcon, {
                      class: "h-5 w-5 text-gray-400",
                      "aria-hidden": "true"
                    })
                  ])
                ]),
                _: 1
              }, 8, ["class"]),
              _createVNode(_Transition, {
                "leave-active-class": "transition ease-in duration-100",
                "leave-from-class": "opacity-100",
                "leave-to-class": "opacity-0"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ListboxOptions, { class: "absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-lg bg-white dark:bg-gr-darker py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm pb-28" }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectList, (item) => {
                        return (_openBlock(), _createBlock(_component_ListboxOption, {
                          as: "template",
                          key: item,
                          value: item
                        }, {
                          default: _withCtx(({ active, selected }) => [
                            _createElementVNode("li", {
                              class: _normalizeClass([active ? _ctx.getBackgroundColor() : '', active ? 'text-white dark:text-gr-dark' : 'text-gray-900 dark:text-gray-500', 'relative cursor-default select-none py-2 pl-8 pr-4'])
                            }, [
                              _createElementVNode("div", _hoisted_3, [
                                _createElementVNode("span", {
                                  class: _normalizeClass([selected ? 'font-semibold' : 'font-normal', 'block truncate'])
                                }, _toDisplayString(_ctx.getItemName(item)), 3),
                                (_ctx.additionalItemDescription)
                                  ? (_openBlock(), _createElementBlock("span", {
                                      key: 0,
                                      class: _normalizeClass([active ? _ctx.getDescriptionTextColor() : 'text-gray-500 dark:text-gray-700', 'ml-2 truncate'])
                                    }, "€ " + _toDisplayString(_ctx.getItemDescription(item)), 3))
                                  : _createCommentVNode("", true)
                              ]),
                              selected
                                ? (_openBlock(), _createElementBlock("span", {
                                    key: 0,
                                    class: _normalizeClass([active ? 'dark:text-gray-700 text-gray-900' : _ctx.getTextColor(), 'absolute inset-y-0 left-0 flex items-center pl-1.5'])
                                  }, [
                                    _createVNode(_component_CheckIcon, {
                                      class: "h-5 w-5",
                                      "aria-hidden": "true"
                                    })
                                  ], 2))
                                : _createCommentVNode("", true)
                            ], 2)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ], 2)
          ]),
          _: 1
        }, 8, ["modelValue", "class"])
      ], 2))
    : _createCommentVNode("", true)
}