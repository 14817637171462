import {PaginationMetaInterface} from "@/greeve/core/pagination_meta.interface";
import {CartItem} from "@/greeve/shop/cart/item/cart_item.type";
import {
	GreeveProductPriceSubType,
	GreeveProductPriceType,
} from '@/greeve/shop/product/price.interface';

export class CartItemList extends Array<CartItem> {

	public paginationMetaData?: PaginationMetaInterface;

	constructor(items: Array<CartItem> = []) {
		super();
		this.push(...items);
	}

	add(items: CartItem) {
		this.push(items);
	}

	remove(index: number) {
		this.splice(index, 1);
	}

	removeAll() {
		const itemCount = this.length;
		this.splice(itemCount, 1);
	}

	getItem(index: number): CartItem {
		return this[index];
	}

	getItemById(item_id: string): CartItem | undefined {
		return this.find(({id}) => id === item_id);
	}

	getItemByProductId(productId: string): CartItem | undefined {
		return this.find(({product_id}) => product_id === productId);
	}

	getItems_by_PriceType(priceType: GreeveProductPriceType, subType?: GreeveProductPriceSubType, prodId?: string, clone = true): CartItem[]|CartItemList| undefined {
		try {
			if (clone) {
				const cartItemList: CartItemList|CartItem[] = [...this];
				return cartItemList.filter((cartItem) => {
					let found = true;
					if (prodId) {
						found = cartItem.product_id === prodId;
					}
					if (!found) {
						return found;
					}
					if (priceType) {
						found = cartItem.getPrice()?.type === priceType;
					}
					if (!found) {
						return found;
					}
					if (subType) {
						found = cartItem.getPrice()?.sub_type === subType;
					}
					if (!found) {
						return found;
					}
					return cartItem;
				});
			} else {
				return this.filter((cartItem) => {
					let found = true;
					if (prodId) {
						found = cartItem.product_id === prodId;
					}
					if (!found) {
						return found;
					}
					if (priceType) {
						found = cartItem.getPrice()?.type === priceType;
					}
					if (!found) {
						return found;
					}
					if (subType) {
						found = cartItem.getPrice()?.sub_type === subType;
					}
					if (!found) {
						return found;
					}
					return cartItem;
				});
			}
		} catch (e) {
			console.error(e);
		}
		return undefined;
	}

	enhanceMetaData(metaData: any) {
		this.paginationMetaData = metaData;
	}
}