import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "fixed inset-0 z-10 overflow-y-auto" }
const _hoisted_2 = { class: "sm:flex min-h-full overflow-hidden items-end justify-center text-center sm:items-center sm:p-0" }
const _hoisted_3 = { class: "p-4 overflow-hidden rounded-2xl" }
const _hoisted_4 = { class: "sr-only" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TransitionChild = _resolveComponent("TransitionChild")!
  const _component_XMarkIcon = _resolveComponent("XMarkIcon")!
  const _component_PricingSectionSmall = _resolveComponent("PricingSectionSmall")!
  const _component_DialogPanel = _resolveComponent("DialogPanel")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_TransitionRoot = _resolveComponent("TransitionRoot")!

  return (_openBlock(), _createBlock(_component_TransitionRoot, {
    as: "template",
    show: _ctx.open
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Dialog, {
        as: "div",
        class: "relative z-10",
        onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.cancelDialogOutsideClick()))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_TransitionChild, {
            as: "template",
            enter: "ease-out duration-300",
            "enter-from": "opacity-0",
            "enter-to": "opacity-100",
            leave: "ease-in duration-200",
            "leave-from": "opacity-100",
            "leave-to": "opacity-0"
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createElementVNode("div", { class: "fixed inset-0 backdrop-filter backdrop-blur-sm transition-opacity" }, null, -1)
            ])),
            _: 1
          }),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_TransitionChild, {
                as: "template",
                enter: "ease-out duration-300",
                "enter-from": "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
                "enter-to": "opacity-100 translate-y-0 sm:scale-100",
                leave: "ease-in duration-200",
                "leave-from": "opacity-100 translate-y-0 sm:scale-100",
                "leave-to": "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DialogPanel, { class: "relative transform rounded-xl bg-white dark:bg-gr-dark text-left shadow-xl transition-all sm:w-full sm:max-w-4xl" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("button", {
                          type: "button",
                          class: "ion-float-end rounded-xl bg-white text-gray-400 hover:text-gray-500 focus:outline-none",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.cancelDialog()))
                        }, [
                          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('close')), 1),
                          _createVNode(_component_XMarkIcon, {
                            class: "h-6 w-6",
                            "aria-hidden": "true"
                          })
                        ]),
                        _createVNode(_component_PricingSectionSmall)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["show"]))
}