<template>
	<TransitionRoot as="template" :show="open">
		<Dialog as="div" class="relative z-10" @close="cancelDialogOutsideClick()">
			<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
											 leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
				<div class="fixed inset-0 backdrop-filter backdrop-blur-sm transition-opacity"/>
			</TransitionChild>
			<div class="fixed inset-0 z-10 overflow-y-auto">
				<div class="sm:flex min-h-full overflow-hidden items-end justify-center text-center sm:items-center sm:p-0">
					<TransitionChild as="template" enter="ease-out duration-300"
													 enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
													 enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
													 leave-from="opacity-100 translate-y-0 sm:scale-100"
													 leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
						<DialogPanel
								class="relative transform rounded-xl bg-white dark:bg-gr-dark text-left shadow-xl transition-all sm:w-full sm:max-w-4xl">
							<div class="p-4 overflow-hidden rounded-2xl">
								<button type="button" class="ion-float-end rounded-xl bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
												@click="cancelDialog()">
									<span class="sr-only">{{ $t('close') }}</span>
									<XMarkIcon class="h-6 w-6" aria-hidden="true"/>
								</button>
								<PricingSectionSmall></PricingSectionSmall>

							</div>
						</DialogPanel>
					</TransitionChild>
				</div>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref, toRef, watch} from 'vue';
import useTranslation from '@/composable/translation/useTranslation';
import {Dialog, DialogPanel, TransitionChild, TransitionRoot} from '@headlessui/vue';
import {XMarkIcon} from '@heroicons/vue/24/outline';
import PricingSectionSmall from "@/views/shop/PricingSectionSmall.vue";

export interface ScriptInterface {
	src: string;
	type?: string;
	arguments?: any;
	content?: string;
}

export default defineComponent({
	name: 'ProAdDialog',
	components: {
		PricingSectionSmall,
		XMarkIcon,
		Dialog, DialogPanel, TransitionChild, TransitionRoot,
	},
	props: {
		title: {type: String},
		description: {type: String},
		confirmButtonName: {type: String, default: 'Confirm'},
		cancelButtonName: {type: String, default: 'Cancel'},
		visible: {type: Boolean, default: false},
		type: {type: String, default: 'alert'},
		allowBodyDismiss: {
			type: Boolean,
			default: true,
		},
		showDismissButton: {
			type: Boolean,
			default: true,
		},
		showLoadingAnimationOnConfirm: {
			type: Boolean,
			default: true,
		},
	},
	emits: ['confirm', 'cancelConfirm'],
	setup(props, {emit}) {
		const dialogTitle = toRef(props, 'title');
		const dialogDescription = toRef(props, 'description');
		const visible = toRef(props, 'visible');
		const open = ref<boolean>(visible.value);
		const {t} = useTranslation();

		function toggleDialog() {
			open.value = !open.value;
		}

		const cancelDialogOutsideClick = () => {
			if (props.allowBodyDismiss) {
				open.value = false;
				emit('cancelConfirm');
			}
		};

		const cancelDialog = () => {
			open.value = false;
			emit('cancelConfirm');
		};

		function confirm() {
			emit('confirm');
		}

		watch(() => props.visible, (newValue: boolean) => {
			open.value = newValue;
		});

		onMounted(() => {
			//
		});

		return {
			dialogTitle,
			dialogDescription,
			open,
			toggleDialog,
			confirm,
			cancelDialog,
			cancelDialogOutsideClick,
			t,
		};
	},
});
</script>

<style lang="scss">
</style>
