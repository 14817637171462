import {Currency} from "@/greeve/core/country/currency.type";
import useCurrencyFactory from "@/composable/core/useCurrencyFactory";
import {JournalInterface, JournalState, JournalType} from "@/greeve/shop/order/journal/journal.interface";
import {JournalLineList} from "@/greeve/shop/order/journal/journalLine/journal_line_list.type";
import {JournalLine} from "@/greeve/shop/order/journal/journalLine/journal_line.type";
import {Order} from "@/greeve/shop/order/order.type";
import useOrder from "@/composable/greeve/useOrder";

export class Journal implements JournalInterface {
	id: number;
	uuid: string;
	user_id: number;
	journal_lines?: JournalLineList|null;
	parent_id?: number|null;
	order_id?: number|null;
	type: JournalType;
	state: JournalState;
	country_code: string;
	currency_code: string;
	journal_number: string;
	journal_number_formatted: string;
	reference?: string|null;
	external_reference?: string|null;
	net_amount: number;
	total_amount: number;
	tax_amount: number;
	item_quantity: number;
	grand_total: number;
	exchange_rate?: number|null;
	brokerage?: number|null;
	first_name?: string|null;
	last_name?: string|null;
	company?: string|null;
	is_company?: boolean|null;
	uid?: string|null;
	tax_number?: string|null;
	street: string;
	zip_code?: string|null;
	city?: string|null;
	lat?: number|null;
	lng?: number|null;
	phone?: string|null;
	description?: string|null;
	data?: any;
	created_at?: Date;
	updated_at?: Date;


	constructor(id: number, uuid: string, user_id: number, type: JournalType, state: JournalState, country_code: string, currency_code: string, journal_number: string, journal_number_formatted: string, net_amount: number, total_amount: number, tax_amount: number, item_quantity: number, grand_total: number, street: string, journal_lines?: JournalLineList|null, order_id?: number|null, parent_id?: number|null, reference?: string|null, external_reference?: string|null, exchange_rate?: number|null, brokerage?: number|null, first_name?: string|null, last_name?: string|null, company?: string|null, is_company?: boolean|null, uid?: string|null, tax_number?: string|null, zip_code?: string|null, city?: string|null, lat?: number|null, lng?: number|null, phone?: string|null, description?: string|null, data: any = null, created_at?: Date, updated_at?: Date) {
		this.id = id;
		this.uuid = uuid;
		this.state = state;
		this.user_id = user_id;
		this.type = type;
		this.state = state;
		this.country_code = country_code;
		this.currency_code = currency_code;
		this.journal_number = journal_number;
		this.journal_number_formatted = journal_number_formatted;
		this.net_amount = net_amount;
		this.tax_amount = tax_amount;
		this.total_amount = total_amount;
		this.item_quantity = item_quantity;
		this.grand_total = grand_total;
		this.street = street;
		this.journal_lines = journal_lines;
		this.order_id = order_id;
		this.parent_id = parent_id;
		this.reference = reference;
		this.external_reference = external_reference;
		this.exchange_rate = exchange_rate;
		this.brokerage = brokerage;
		this.first_name = first_name;
		this.last_name = last_name;
		this.company = company;
		this.is_company = is_company;
		this.uid = uid;
		this.tax_number = tax_number;
		this.zip_code = zip_code;
		this.city = city;
		this.lat = lat;
		this.lng = lng;
		this.phone = phone;
		this.description = description;
		this.data = data;
		this.created_at = created_at;
		this.updated_at = updated_at;
	}

	private order?: Order;


	getCurrencyObject(): Currency
	{
		return useCurrencyFactory().getCurrencyFactory().createCurrencyByCode(this.currency_code);
	}

	getAmountByJournalLine(brutto = true, toDecimal= true): number
	{
		let value = 0;
		this.journal_lines?.forEach((item: JournalLine) => {
			if (brutto) {
				value += (item.total_amount);
			} else {
				value += (item.net_amount*item.item_quantity);
			}
		});
		//TODO check if brutto or netto
		if (toDecimal) {
			value = (value/100);
		}
		return value;
	}

	getOrder(): Order|undefined
	{
		if (this.order) {
			return this.order;
		}
		if (this.order_id) {
			const order = useOrder().getOrderById(this.order_id);
			if (order) {
				return order;
				// this.order = order; //TODO CHECK vuex mutation error
			}
			return this.order;
		}

		return;
	}

	getInvoiceLink(): string|undefined
	{
		if (this.data && this.data.invoice_pdf) {
			return String(this.data.invoice_pdf);
		}
		return;
	}
}