<template>
	<div v-if="showAlert && ( (getAllAlerts() && getAllAlerts()[alertType] && getAllAlerts()[alertType].length > 0) || alertMessage)" :class="['z-10 mt-7 my-4 mb-2 mb-2 sm:mb-4 md:mb-4 p-0 rounded-xl', cssClass, alertPadding]">
		<div :id="alertId" :class="[alertPosition, 'rounded-xl backdrop-filter backdrop-blur-3xl bg-opacity-20', isError() ? 'bg-red-400 dark:bg-red-700 ' : '', isSuccess() ? 'bg-green-400 dark:bg-green-700' : '', isWarning() ? 'bg-yellow-400 dark:bg-yellow-700' : '', isSecondary() ? 'bg-gray-400 dark:bg-gray-700' : '']" class="md:mb-6 align-middle rounded-xl rounded-2xl p-2 sm:p-4 pl-3 sm:pl-5 text-md text-gray-900 dark:text-gray-400">
			<div v-show="showCloseButton" class="float-right ml-2 mt-0.5">
				<XMarkIcon class="w-5 cursor-pointer" @click="closeAlert(alertId)"></XMarkIcon>
			</div>
			<div class="flex select-none">
				<div class="flex items-center">
					<XCircleIcon v-show="isError()" aria-hidden="true" class="h-12 w-12 text-red-400"/>
					<CheckCircleIcon v-show="isSuccess()" aria-hidden="true" class="h-12 w-12 text-green-400"/>
					<ExclamationTriangleIcon v-show="isWarning()" aria-hidden="true" class="h-12 w-12 text-yellow-400"/>
					<ExclamationTriangleIcon v-show="isSecondary()" aria-hidden="true" class="h-12 w-12 text-gray-400"/>
<!--					<img src="https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/images/landing/3d/lab.png" v-show="isError()" class="ml-0 mr-2 h-20 w-20 lg:h-16 lg:w-16 xl:h-15 xl:w-15" alt="error" />-->
<!--					<img src="https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/images/landing/3d/pencilRack.png" v-show="isSuccess()" class="ml-0 mr-2 h-20 w-20 lg:h-16 lg:w-16 xl:h-15 xl:w-15" alt="success" />-->
<!--					<img src="https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/images/landing/3d/attention.png" v-show="isWarning()" class="ml-0 mr-2 h-20 w-20 lg:h-16 lg:w-16 xl:h-15 xl:w-15" alt="warning" />-->
<!--					<img src="https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/images/landing/3d/pencilRack.png" v-show="isSecondary()" class="ml-0 mr-2 h-20 w-20 lg:h-16 lg:w-16 xl:h-15 xl:w-15" alt="secondary" />-->
					<div class="ml-3">
						<h3 v-show="showTitle && alertTitle.length > 0"
							:class="['text-lg font-bold leading-6',  isError() ? 'text-red-800 dark:text-red-300' : '', isSuccess() ? 'text-green-800 dark:text-green-300' : '', isWarning() ? 'text-yellow-800 dark:text-yellow-300' : '', isSecondary() ? 'text-gray-800 dark:text-gray-300' : '']">
							{{ alertTitle }}</h3>
						<div
								:class="['mt-1 text-sm md:text-base line-clamp-3', isError() ? 'text-red-700 dark:text-red-300' : '', isSuccess() ? 'text-green-700 dark:text-green-300' : '', isWarning() ? 'text-yellow-700 dark:text-yellow-300' : '', isSecondary() ? 'text-gray-700 dark:text-gray-300' : '']">
							<ul v-if="showAsList" class="list-disc pl-5 space-y-1" role="list">
								<li v-for="item in getAllAlerts()[alertType]" :key="item">{{ item }}</li>
							</ul>
							<div v-else-if="!showAsList && hasAlertMessageList()">
								<div v-for="item in getAllAlerts()[alertType]" :key="item">
									<div class="text-lg font-bold leading-6">{{ item.title }}</div>
									{{ item.message }}
								</div>
							</div>
							<p v-else v-html="alertMessage"></p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent, ref, watch} from 'vue';
import useAlerts from "@/composable/core/useAlert";
import {CheckCircleIcon, ExclamationTriangleIcon, XCircleIcon, XMarkIcon} from '@heroicons/vue/20/solid'

export default defineComponent({
	name: 'BaseAlertComponent',
	components: {
		XCircleIcon, CheckCircleIcon,
		XMarkIcon,
		ExclamationTriangleIcon
	},
	props: {
		id: {
			type: String,
			default: null,
		},
		alertPadding: {
			type: String,
			// default: "pt-4",
			default: "pt-4 fixed w-full p-4",
		},
		alertPosition: {
			type: String,
			default: "p-4 ",
		},
		alertType: {
			type: String,
			default: "error",
		},
		title: {
			type: String,
			default: "",
		},
		message: {
			type: String,
			default: "",
		},
		showTitle: {
			type: Boolean,
			default: true,
		},
		showIcon: {
			type: Boolean,
			default: true,
		},
		showCloseButton: {
			type: Boolean,
			default: true,
		},
		showAsList: {
			type: Boolean,
			default: false,
		},
		cssClass: {
			type: String,
			default: "w-full relative sticky ",
		},
		useAlertListMessageFromStorage: {
			type: Boolean,
			default: false,
		},
		showAlert: {
			type: Boolean,
			default: true,
		},
	},
	emits: ['closeAlert'],
	setup(props, {emit}) {
		const {getAllAlerts, removeAlertByType} = useAlerts();
		const alertId = ref(props.id);
		const alertMessageList = ref();
		const alertTitle = ref(props.title);
		const alertMessage = ref(props.message);
		const alertUseAlertListMessageFromStorage = ref(props.useAlertListMessageFromStorage);
		const hasErrorMessagesToShow = ref(false);
		const test = ref();

		function hasAlertMessageList(): boolean {
			if (!alertUseAlertListMessageFromStorage.value && alertMessage && alertMessage.value.length > 0) {
				return false;
			}
			return getAllAlerts.value !== undefined && getAllAlerts.value[props.alertType] && getAllAlerts.value[props.alertType].length > 0;
		}

		// function updateErrorMessageToShowCheck() {
		// 	hasErrorMessagesToShow.value = ((hasAlertMessageList() || alertTitle.value.length > 0 || alertMessage.value.length > 0) && props.showAlert) === true;
		// }

		function isError() {
			return props.alertType === "error";
		}

		function isSuccess() {
			return props.alertType === "success";
		}

		function isWarning() {
			return props.alertType === "warning";
		}

		function isSecondary() {
			return props.alertType === "secondary";
		}

		const closeAlert = (selectedId: any) => {
			//TODO check if delete element or just disable it
			if (selectedId) {
				const elementToRemove = document.getElementById(selectedId);
				if (elementToRemove) {
					alertTitle.value = "";
					alertMessage.value = "";
					if (alertUseAlertListMessageFromStorage.value) {
						removeAlertByType(props.alertType);
					}
				}
			} else {
				removeAlertByType(props.alertType);
			}
			hasErrorMessagesToShow.value = false;
			emit('closeAlert');
		};

		watch(() => props.message, (newMessage) => {
			if (newMessage && newMessage.length > 0) {
				alertMessage.value = newMessage;
			}
		});

		watch(() => props.title, (newTitle) => {
			if (newTitle && newTitle.length > 0) {
				alertTitle.value = newTitle;
			}
		});

		return {
			getAllAlerts,
			alertMessageList,
			hasAlertMessageList,
			alertId,
			alertTitle,
			alertMessage,
			closeAlert,
			isError,
			isSuccess,
			isWarning,
			isSecondary,
			hasErrorMessagesToShow,
			test,
		};
	}
})
</script>

<style scoped>
</style>
