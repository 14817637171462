import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "grid my-1 sm:my-0 grid-cols-2 gap-2 sm:gap-4"
}
const _hoisted_3 = ["src", "onClick"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["src", "onClick"]
const _hoisted_6 = { class: "w-full text-center italic" }
const _hoisted_7 = { class: "modal-content flex justify-center items-center" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "sr-only" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DefaultOutputElement = _resolveComponent("DefaultOutputElement")!
  const _component_XMarkIcon = _resolveComponent("XMarkIcon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_modal = _resolveComponent("ion-modal")!

  return (_ctx.getImages()?.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.getImages().length > 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getImages(), (url) => {
                return (_openBlock(), _createElementBlock("img", {
                  key: url,
                  class: "cursor-pointer h-full rounded-xl max-h-32",
                  src: url,
                  alt: "image",
                  onClick: ($event: any) => (_ctx.openModal(url))
                }, null, 8, _hoisted_3))
              }), 128))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getImages(), (url) => {
                return (_openBlock(), _createElementBlock("img", {
                  key: url,
                  class: "cursor-pointer rounded-xl my-1 sm:my-0 max-h-32",
                  src: url,
                  alt: "image",
                  onClick: ($event: any) => (_ctx.openModal(url))
                }, null, 8, _hoisted_5))
              }), 128))
            ])),
        _createElementVNode("blockquote", _hoisted_6, [
          _createVNode(_component_DefaultOutputElement, {
            content: _ctx.searchItem?.prompt
          }, null, 8, ["content"])
        ]),
        _createVNode(_component_ion_modal, {
          id: "image-modal",
          "is-open": _ctx.showModal,
          onIonModalDidDismiss: _ctx.closeModal
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_content, { class: "ion-padding" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("img", {
                    src: _ctx.modalImage,
                    alt: "Fullscreen Image",
                    class: "my-1 sm:my-0 max-w-full max-h-full"
                  }, null, 8, _hoisted_8),
                  _createVNode(_component_ion_button, {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal())),
                    color: "light",
                    class: "absolute top-4 end-4"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('close')), 1),
                      _createVNode(_component_XMarkIcon, {
                        class: "h-6 w-6",
                        "aria-hidden": "true"
                      })
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["is-open", "onIonModalDidDismiss"])
      ]))
    : _createCommentVNode("", true)
}