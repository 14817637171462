import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "rounded-b-xl w-full py-3 text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.redirectToCustomerBillingPortal && _ctx.redirectToCustomerBillingPortal(...args))),
      "aria-describedby": "tier-scale",
      class: "inline-flex justify-center rounded-xl bg-gr-primary hover:bg-gr-primary2 py-1.5 px-10 text-sm font-semibold text-white shadow-sm focus:outline-none"
    }, _toDisplayString(_ctx.$t('manage_billing.button_title')), 1)
  ]))
}