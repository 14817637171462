import {
	FlowProcessInterface, FlowProcessState, FlowProcessType,
} from '@/greeve/flow/process/flow_process.interface';
import i18n from '@/i18n';
import {FlowMediaList} from '@/greeve/flow/media/flow_media_list.type';

export interface FlowProcessVueFlowPositionInterface {
	x?: number,
	y?: number,
}


export abstract class AbstractFlowProcess implements FlowProcessInterface{
	id: number;
	uuid: string;
	flow_id: number;
	flow_process_config_id?: number|null;
	// flow_process_config?: FlowProcessConfig|null,
	media_list?: FlowMediaList|null;
	type: FlowProcessType;
	state: FlowProcessState;
	reference?: string|null;
	name?: string|null;
	description?: string|null;
	data?: any;
	created_at?: Date;
	updated_at?: Date;

	constructor(
			id: number, uuid: string, flow_id: number, type: FlowProcessType, state: FlowProcessState,
			flow_process_config_id?: number|null, media_list?: FlowMediaList|undefined, reference?: string | undefined,
			name?: string | undefined, description?: string|null, data: any = null, created_at?: Date, updated_at?: Date) {
		this.id = id;
		this.uuid = uuid;
		this.flow_id = flow_id;
		this.flow_process_config_id = flow_process_config_id;
		this.media_list = media_list;
		this.type = type;
		this.state = state;
		this.reference = reference;
		this.name = name;
		this.description = description;
		this.data = data;
		this.created_at = created_at;
		this.updated_at = updated_at;
	}

	getVueFlowTemplate(): any
	{
		return;
	}

	getVueFlowLabel(): string
	{
		return i18n.global.t('flow.process.'+this.type+'.vue_flow.label');
	}

	getVueFlowPosition(): FlowProcessVueFlowPositionInterface
	{
		if (this.data && this.data.position) {
			const position: FlowProcessVueFlowPositionInterface = this.data.position;
			return {x: position.x, y: position.y};
		}
		return {x: 0, y: 0};
	}

	getCreateDateFormatted(withTime = true): string
	{
		let date = '';
		if (!this.created_at) {
			return date;
		}

		date = this.created_at.toLocaleDateString();

		if (withTime) {
			date = date + ' ' + this.created_at.toLocaleTimeString([], {timeStyle: 'short'})
		}
		return date;
	}

	getUpdateDateFormatted(withTime = true): string
	{
		let date = '';
		if (!this.updated_at) {
			return date;
		}

		date = this.updated_at.toLocaleDateString();

		if (withTime) {
			date = date + ' ' + this.updated_at.toLocaleTimeString([], {timeStyle: 'short'})
		}
		return date;
	}
}