import {UserFactory} from "@/greeve/user/user_factory.type";
import {UserAddressFactory} from "@/greeve/user/address/user_address_factory.type";
import {SubscriptionFactory} from "@/greeve/shop/subscription/subscription_factory.type";
import {QuotaFactory} from '@/greeve/user/quota/quota_factory.type';
import {
	QuotaTransactionFactory
} from '@/greeve/user/quota/transaction/quota_transaction_factory.type';
import {
	QuotaJournalFactory
} from '@/greeve/user/quota/journal/quota_journal_factory.type';
import {OAuthFactory} from '@/greeve/user/oauth/oauth_factory.type';

export default function UseUserFactory() {

	let userFactory: UserFactory | null = null;
	const getUserFactory = (): UserFactory => {
		if (!userFactory) {
			userFactory = new UserFactory();
		}
		return userFactory;
	}

	let userAddressFactory: UserAddressFactory | null = null;
	const getUserAddressFactory = (): UserAddressFactory => {
		if (!userAddressFactory) {
			userAddressFactory = new UserAddressFactory();
		}
		return userAddressFactory;
	}

	let subscriptionFactory: SubscriptionFactory | null = null;
	const getSubscriptionFactory = (): SubscriptionFactory => {
		if (!subscriptionFactory) {
			subscriptionFactory = new SubscriptionFactory();
		}
		return subscriptionFactory;
	}

	let quotaFactory: QuotaFactory | null = null;
	const getQuotaFactory = (): QuotaFactory => {
		if (!quotaFactory) {
			quotaFactory = new QuotaFactory();
		}
		return quotaFactory;
	}

	let quotaTransactionFactory: QuotaTransactionFactory | null = null;
	const getQuotaTransactionFactory = (): QuotaTransactionFactory => {
		if (!quotaTransactionFactory) {
			quotaTransactionFactory = new QuotaTransactionFactory();
		}
		return quotaTransactionFactory;
	}

	let quotaJournalFactory: QuotaJournalFactory | null = null;
	const getQuotaJournalFactory = (): QuotaJournalFactory => {
		if (!quotaJournalFactory) {
			quotaJournalFactory = new QuotaJournalFactory();
		}
		return quotaJournalFactory;
	}

	let oauthFacotry: OAuthFactory | null = null;
	const getOAuthFactory = (): OAuthFactory => {
		if (!oauthFacotry) {
			oauthFacotry = new OAuthFactory();
		}
		return oauthFacotry;
	}

	return {
		getUserFactory,
		getUserAddressFactory,
		getSubscriptionFactory,
		getQuotaFactory,
		getQuotaTransactionFactory,
		getQuotaJournalFactory,
		getOAuthFactory,
	}
}