<template>
	<Menu as="div" class="relative inline-block text-left">
		<div>
			<MenuButton
					class="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-gr-primary focus:ring-offset-2 focus:ring-offset-gray-100">
				<span class="sr-only">Open options</span>
				<EllipsisVerticalIcon class="h-5 w-5" aria-hidden="true"/>
			</MenuButton>
		</div>

			<transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
				<MenuItems class="fixed right-0 sm:absolute sm:left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
					<div class="py-1">
						<MenuItem v-for="(item, index) in menuItems" :key="index" v-slot="{ active }">
							<a @click="handleMenuItemClick(item)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'flex items-center px-4 py-2 text-sm cursor-pointer']">
								<template v-if="item.icon">
									<component :is="item.icon" class="h-4 w-4 mr-2" aria-hidden="true"></component>
								</template>
								{{ item.label }}
							</a>
						</MenuItem>
					</div>
				</MenuItems>
			</transition>
		</Menu>
</template>

<script setup lang="ts">
import {Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/vue';
import {EllipsisVerticalIcon} from '@heroicons/vue/20/solid';
import {defineProps, defineEmits} from 'vue';
import {MenuItemType} from '@/greeve/core/menu_item_type';

const props = defineProps({
	menuItems: {
		type: Object as () => MenuItemType[],
		required: true
	},
	reference: {
		type: String,
	}
});

const emits = defineEmits(['menu-click']);

function handleMenuItemClick(item: MenuItemType) {
	emits('menu-click', item, props.reference);
}
</script>

<script lang="ts">
// Export the component
export default {
	name: 'DynamicMenu',
}
</script>
