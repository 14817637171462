<template>


	<h1 class="sr-only">{{ $t('shop.checkout.order_information.title') }}</h1>
	<section aria-labelledby="summary-heading" class="bg-gray-50 dark:bg-gr-dark px-4 sm:px-6 lg:col-start-2 lg:row-start-1 lg:bg-transparent lg:px-0">
		<div class="mx-auto w-full lg:max-w-none" v-if="cart.items && cart.items.length > 0">
			<h2 id="summary-heading" class="text-lg font-semibold text-gray-900 dark:text-white">{{ $t('shop.checkout.order_information.title') }}</h2>
					<dl class="py-6 grid grid-cols-2 gap-x-4 text-sm text-gray-600">
						<div>
							<dt class="font-medium text-gray-400">{{ $t('billingAddress') }}</dt>
							<dd class="mt-2">
								<address class="not-italic">
									<span class="block">{{ cart.customer.address.name }}</span>
									<span class="block">{{ cart.customer.address.street }} {{ cart.customer.address.street_number }} </span>
									<span class="block">{{ cart.customer.address.city }}, {{ cart.customer.address.getCountryName() }}</span>
<!--									<span class="block">UID</span>-->
<!--									<span class="block">other</span>-->
								</address>
							</dd>
						</div>
					</dl>

				<dl class="space-y-1 border-t border-gray-400 py-6 text-sm font-medium text-gray-500">
					<dt class="font-medium text-gray-400 mb-2">{{ $t('servicesOverview') }}</dt>
					<div v-for="item in cart.items" :key="item.id" class="font-medium grid grid-cols-3 text-gray-500 dark:text-gray-400 bg-gray-200  dark:bg-gr-darker shadow-sm focus-visible:outline-black rounded-xl py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">
						<p>{{ item.getProduct()?.getProductName() }}<span v-if="item.quantity > 1"> (x {{ item.getQuantity() }})</span></p>
						<p>
								<span>{{ item.getPrice()?.getFormattedAmount(true) }}<span v-if="item.getProduct()?.price?.sub_type === getRecurringMonthlySubType()">/mo</span><span v-else-if="item.getProduct()?.price?.sub_type === getRecurringYearlySubType()">/yr</span></span>
						</p>
						<p v-if="item.getProduct()?.price?.type !== getOneTimePriceType()">{{ item.getProduct()?.getProductCreditAmount() }} {{$t('shop.product.features.credits')}}</p>
					</div>
				</dl>

					<dl class="space-y-1 border-t border-gray-400 pt-6 pb-2 text-sm font-medium text-gray-500" v-if="cart.hasRecurringProducts()">
						<dt class="font-medium text-gray-400 mb-2">{{ $t('paymentDuration') }}</dt>
						<div class="flex justify-between" v-if="cart.hasRecurringProducts(getRecurringMonthlySubType())">
							<dt>{{ $t('monthlyPayment') }}</dt>
							<dd class="text-gray-600">{{cart.getTotalMonthlyAmount(true)}}</dd>
						</div>
						<div class="flex justify-between" v-if="cart.hasRecurringProducts(getRecurringYearlySubType())">
							<dt>{{ $t('annualPayment') }}</dt>
							<dd class="text-gray-600">{{cart.getTotalYearlyAmount(true)}}</dd>
						</div>
					</dl>
					<dl class="space-y-1 border-t border-gray-400 pt-6 pb-2 text-sm font-medium text-gray-500" v-if="cart.hasOneTimeProducts()">
						<dt class="font-medium text-gray-400 mb-2">{{ $t('oneTimePayments') }}</dt>
						<div class="flex justify-between">
							<dt></dt>
							<dd class="text-gray-600">{{cart.getTotalOneTimePaymentAmount(true)}}</dd>
						</div>
					</dl>
						<dl class="border-gray-500 py-2 text-sm font-medium text-gray-500">
						<div class="flex justify-between" v-if="cart.hasTaxAmount()">
							<dt>{{ $t('taxes') }}</dt>
							<dd class="text-gray-600">{{cart.getTotalTaxAmountCalculated(true)}}</dd>
						</div>
						<div class="flex items-center justify-between font-semibold mt-2 border-t border-gray-500 py-2 text-gray-400">
							<dt class="text-base">{{ $t('total') }}</dt>
							<dd class="text-base">{{cart.getTotalAmountCalculated(true)}}</dd>
						</div>
					</dl>
						<checkout-pay-button></checkout-pay-button>
				</div>
		<div v-else>
		</div>
	</section>
</template>

<script lang="ts">
import {defineComponent, onMounted} from 'vue';
import useShop from '@/composable/greeve/useShop';
import CheckoutPayButton from '@/components/shop/checkout/cart/CheckoutPayButton.vue';

export default defineComponent({
	name: "OrderConfirmProcess",
	components: {CheckoutPayButton},
	// components: { Disclosure, DisclosureButton, DisclosurePanel,LockClosedIcon},
	props: {},
	emits: ['onSuccess', 'onError'],
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setup(props, {emit}) {
		const {cart, getOneTimePriceType, getRecurringMonthlySubType, getRecurringYearlySubType} = useShop();

		function onSuccess() {
			//TODO save to cart customer address
			emit('onSuccess');
		}

		function onError(message = '', inlineError = false) {
			emit('onError', message, null, false, inlineError);
		}

		onMounted(() => {
			//todo load setup and check total amount etc...
		})

		return {
			onSuccess, onError,
			cart, getOneTimePriceType,getRecurringMonthlySubType, getRecurringYearlySubType
		};
	}
})
</script>

<style scoped>

</style>
