import {FlowTemplate} from '@/greeve/flow/type/template/flow_template.type';
import {MicrophoneIcon, VideoCameraIcon} from '@heroicons/vue/20/solid';
import {FlowSubType} from '@/greeve/flow/flow.interface';

export class FlowTemplateWhisper extends FlowTemplate {
	getFlowPriceInformation(): string|undefined
	{
		//TODO GET BY API
		return '0.5 Credits/Minute';
	}

	getFlowIcon(): any
	{
		if (this.sub_type === FlowSubType.WHISPER_VIDEO_TEMPLATE) {
			return VideoCameraIcon;
		}
		return MicrophoneIcon;
	}
}