import {
	DiscountObject,
} from '@/greeve/shop/cart/discount/discount.interface';
import {
	AbstractDiscountItem
} from '@/greeve/shop/cart/discount/abstract_discount_item.type';

export class CouponDiscountItem extends AbstractDiscountItem{
	getObject(): DiscountObject {
		return DiscountObject.COUPON;
	}
}