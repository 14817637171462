<template>
	<div class="flex items-center rounded-full shadow min-w-[17em] sm:min-w-[26em]">
		<audio controls class="w-full" v-if="src || searchItem?.getSourceAudioFile()">
			<source :src="src && src.length > 0 ? src : searchItem?.getSourceAudioFile()" type="audio/mpeg">
			Your browser does not support the audio element.
		</audio>
	</div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {SearchItemTranscribeAudio} from '@/greeve/search/item/type/subtype/search_item_transcribe_audio.type';

export default defineComponent({
	name: 'AudioOutputElement',
	components: {},
	props: {
		searchItem: {
			type: Object as () => SearchItemTranscribeAudio,
		},
		src: {
			type: String,
		},
	},
	emits: [],
	setup() {
		return {};
	},
});

</script>
<style lang="scss" scoped>
audio {
	width: 100%;
}
</style>
