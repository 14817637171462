import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "grid grid-cols-2 gap-3 sm:grid-cols-2" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InfoQuickViewDialog = _resolveComponent("InfoQuickViewDialog")!
  const _component_UploadInteraction = _resolveComponent("UploadInteraction")!
  const _component_RadioGroupLabel = _resolveComponent("RadioGroupLabel")!
  const _component_RadioGroupOption = _resolveComponent("RadioGroupOption")!
  const _component_RadioGroup = _resolveComponent("RadioGroup")!
  const _component_output_card = _resolveComponent("output-card")!
  const _component_DefaultOutputElement = _resolveComponent("DefaultOutputElement")!
  const _component_ImageVariationRequestImageElement = _resolveComponent("ImageVariationRequestImageElement")!
  const _component_ImageVisionInitialImageElement = _resolveComponent("ImageVisionInitialImageElement")!
  const _component_AudioOutputElement = _resolveComponent("AudioOutputElement")!
  const _component_ImageOutputElement = _resolveComponent("ImageOutputElement")!
  const _component_ImageVariationOutputElement = _resolveComponent("ImageVariationOutputElement")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.contentSearchItem?.isInteractionSearchItemUpload())
      ? (_openBlock(), _createBlock(_component_output_card, {
          key: 0,
          "card-reference": 'prompt-'+_ctx.contentSearchItem?.uuid,
          "card-content": '',
          "item-uuid": _ctx.contentSearchItem?.uuid,
          "is-pinned": _ctx.contentSearchItem?.is_pinned,
          onPinItem: _cache[4] || (_cache[4] = ($event: any) => (_ctx.contentSearchItem?.is_pinned != _ctx.contentSearchItem?.is_pinned)),
          "card-interaction": false,
          "card-info": false,
          "reverse-side": false
        }, {
          CardContent: _withCtx(() => [
            _createElementVNode("h1", null, _toDisplayString(_ctx.$t('assistant.output.upload.file.title')), 1),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("h2", {
                  class: "text-sm font-medium leading-6 text-gray-900",
                  innerHTML: _ctx.getUploadTitle
                }, null, 8, _hoisted_2),
                _createVNode(_component_InfoQuickViewDialog, {
                  title: _ctx.getUploadInfoTitle,
                  "sub-title": _ctx.getUploadInfoSubTitle,
                  description: _ctx.getUploadInfoDescription,
                  visible: _ctx.isInfoDialogVisible,
                  onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isInfoDialogVisible=false))
                }, null, 8, ["title", "sub-title", "description", "visible"]),
                _createElementVNode("a", {
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isInfoDialogVisible = true)),
                  class: "cursor-pointer text-sm font-medium leading-6 text-gr-primary3 hover:text-gr-primary2"
                }, _toDisplayString(_ctx.$t('assistant.output.upload.file.image.moreInfosHowToUse')), 1)
              ]),
              _createVNode(_component_UploadInteraction, {
                "allowed-types": _ctx.getUploadAllowedFileTypeList,
                onOnUpload: _ctx.uploadStarted,
                onOnUploadEnd: _ctx.uploadFinished,
                onOnUploadError: _ctx.uploadError
              }, null, 8, ["allowed-types", "onOnUpload", "onOnUploadEnd", "onOnUploadError"]),
              (_ctx.isUploadActionListVisible && _ctx.getAllowedUploadActionListBySubType.length > 0)
                ? (_openBlock(), _createBlock(_component_RadioGroup, {
                    key: 0,
                    modelValue: _ctx.selectedUploadAction,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedUploadAction) = $event)),
                    class: _normalizeClass([_ctx.fileUploadLoading ? 'cursor-not-allowed disabled:opacity-75' : '', 'mt-2'])
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_RadioGroupLabel, { class: "sr-only" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('assistant.output.upload.file.image.chooseAnImageAction')), 1)
                        ]),
                        _: 1
                      }),
                      _createElementVNode("div", _hoisted_3, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getAllowedUploadActionListBySubType, (uploadAction) => {
                          return (_openBlock(), _createBlock(_component_RadioGroupOption, {
                            as: "template",
                            key: uploadAction.subType,
                            value: uploadAction,
                            disabled: !uploadAction.visible
                          }, {
                            default: _withCtx(({ active, checked }) => [
                              _createElementVNode("div", {
                                class: _normalizeClass([uploadAction.visible && !_ctx.fileUploadLoading ? 'cursor-pointer focus:outline-none' : 'cursor-not-allowed opacity-25', active ? 'ring-2 ring-gr-primary ring-offset-2' : '', checked ? 'bg-gr-primary text-white hover:bg-gr-primary-500' : 'ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50', 'flex items-center justify-center rounded-md py-3 px-3 text-sm font-semibold uppercase sm:flex-1'])
                              }, [
                                _createVNode(_component_RadioGroupLabel, { as: "span" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(uploadAction.name), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ], 2)
                            ]),
                            _: 2
                          }, 1032, ["value", "disabled"]))
                        }), 128))
                      ])
                    ]),
                    _: 1
                  }, 8, ["modelValue", "class"]))
                : _createCommentVNode("", true),
              _createElementVNode("button", {
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.cancelUploadPrompt(_ctx.contentSearchItem?.uuid))),
                type: "button",
                disabled: _ctx.fileUploadLoading,
                class: _normalizeClass([_ctx.fileUploadLoading ? 'cursor-not-allowed disabled:opacity-75' : '', 'mt-2 w-full rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'])
              }, _toDisplayString(_ctx.$t('modal.cancelButtonText')), 11, _hoisted_4)
            ])
          ]),
          _: 1
        }, 8, ["card-reference", "item-uuid", "is-pinned"]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.contentSearchItem?.prompt && !_ctx.contentSearchItem.hasCustomOutput())
            ? (_openBlock(), _createBlock(_component_output_card, {
                key: 0,
                "card-reference": 'prompt-'+_ctx.contentSearchItem.uuid,
                "share-link": _ctx.contentSearchItem.getSharedLink(),
                "share-mode": _ctx.contentSearchItem.getSharedMode(),
                "card-content": _ctx.contentSearchItem.prompt,
                "item-uuid": _ctx.contentSearchItem.uuid,
                "is-pinned": _ctx.contentSearchItem.is_pinned,
                onPinItem: _cache[5] || (_cache[5] = ($event: any) => (_ctx.contentSearchItem.is_pinned != _ctx.contentSearchItem.is_pinned)),
                "card-interaction": false,
                "card-info": false,
                "reverse-side": true
              }, {
                CardContent: _withCtx(() => [
                  _createVNode(_component_DefaultOutputElement, {
                    content: _ctx.contentSearchItem.prompt
                  }, null, 8, ["content"])
                ]),
                _: 1
              }, 8, ["card-reference", "share-link", "share-mode", "card-content", "item-uuid", "is-pinned"]))
            : _createCommentVNode("", true),
          (_ctx.contentSearchItem && _ctx.contentSearchItem.hasCustomOutput())
            ? (_openBlock(), _createBlock(_component_output_card, {
                key: 1,
                "card-reference": 'prompt-'+_ctx.contentSearchItem.uuid,
                "share-link": _ctx.contentSearchItem.getSharedLink(),
                "share-mode": _ctx.contentSearchItem.getSharedMode(),
                "card-content": _ctx.contentSearchItem.prompt,
                "item-uuid": _ctx.contentSearchItem.uuid,
                "is-pinned": _ctx.contentSearchItem.is_pinned,
                onPinItem: _cache[6] || (_cache[6] = ($event: any) => (_ctx.contentSearchItem.is_pinned != _ctx.contentSearchItem.is_pinned)),
                "card-interaction": false,
                "card-info": false,
                "reverse-side": true
              }, {
                CardContent: _withCtx(() => [
                  (_ctx.contentSearchItem?.subType === _ctx.GreeveSearchItemSubType.SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION)
                    ? (_openBlock(), _createBlock(_component_ImageVariationRequestImageElement, {
                        key: 0,
                        "search-item": _ctx.contentSearchItem
                      }, null, 8, ["search-item"]))
                    : (_ctx.contentSearchItem?.subType === _ctx.GreeveSearchItemSubType.SEARCH_ITEM_SUBTYPE_VISION)
                      ? (_openBlock(), _createBlock(_component_ImageVisionInitialImageElement, {
                          key: 1,
                          "search-item": _ctx.contentSearchItem
                        }, null, 8, ["search-item"]))
                      : (_ctx.contentSearchItem?.subType === _ctx.GreeveSearchItemSubType.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT)
                        ? (_openBlock(), _createBlock(_component_AudioOutputElement, {
                            key: 2,
                            "search-item": _ctx.contentSearchItem
                          }, null, 8, ["search-item"]))
                        : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["card-reference", "share-link", "share-mode", "card-content", "item-uuid", "is-pinned"]))
            : _createCommentVNode("", true),
          _createVNode(_component_output_card, {
            "card-reference": 'response-'+_ctx.contentSearchItem?.uuid,
            "card-state": _ctx.contentSearchItem?.state,
            "share-link": _ctx.contentSearchItem?.getSharedLink(),
            "share-mode": _ctx.contentSearchItem?.getSharedMode(),
            "search-item": _ctx.searchItem,
            "card-content": _ctx.contentSearchItem?.getContent(),
            "item-uuid": _ctx.contentSearchItem?.uuid,
            "is-pinned": _ctx.contentSearchItem?.is_pinned,
            onPinItem: _cache[7] || (_cache[7] = ($event: any) => (_ctx.contentSearchItem?.is_pinned != _ctx.contentSearchItem?.is_pinned)),
            onRetryErrorItem: _ctx.retryErrorItem,
            "card-interaction": true,
            "card-info": false,
            "reverse-side": false
          }, {
            CardContent: _withCtx(() => [
              (_ctx.contentSearchItem?.hasError())
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_component_DefaultOutputElement, {
                      content: _ctx.contentSearchItem?.getErrorContent()
                    }, null, 8, ["content"])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    (_ctx.contentSearchItem?.subType === _ctx.GreeveSearchItemSubType.SEARCH_ITEM_SUBTYPE_TEXT_TO_SPEECH)
                      ? (_openBlock(), _createBlock(_component_AudioOutputElement, {
                          key: 0,
                          src: _ctx.contentSearchItem?.getContent()
                        }, null, 8, ["src"]))
                      : (_ctx.contentSearchItem?.subType === _ctx.GreeveSearchItemSubType.SEARCH_ITEM_SUBTYPE_IMAGE)
                        ? (_openBlock(), _createBlock(_component_ImageOutputElement, {
                            key: 1,
                            response: _ctx.contentSearchItem?.getResponse()
                          }, null, 8, ["response"]))
                        : (_ctx.contentSearchItem?.subType === _ctx.GreeveSearchItemSubType.SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION)
                          ? (_openBlock(), _createBlock(_component_ImageVariationOutputElement, {
                              key: 2,
                              "search-item": _ctx.contentSearchItem
                            }, null, 8, ["search-item"]))
                          : (_openBlock(), _createBlock(_component_DefaultOutputElement, {
                              key: 3,
                              content: _ctx.contentSearchItem?.getContent()
                            }, null, 8, ["content"]))
                  ]))
            ]),
            _: 1
          }, 8, ["card-reference", "card-state", "share-link", "share-mode", "search-item", "card-content", "item-uuid", "is-pinned", "onRetryErrorItem"])
        ], 64))
  ]))
}