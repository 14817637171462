import {AbstractOAuth} from '@/greeve/user/oauth/abstract_oauth.type';
import {OAuthType} from '@/greeve/user/oauth/oauth.interface';

export class OAuthMeta extends AbstractOAuth {
	getOAuthType(): string {
		return OAuthType.OAUTH_TYPE_META;
	}

	getName(): string
	{
		let name = super.getName();
		if (this.data && this.data.isBusiness) {
			name += ' Business';
		}
		return name;
	}
}