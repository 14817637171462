<template>
		<a @click="initializeMetaAuth" class="cursor-pointer hover:bg-gray-100 hover:text-gray-900 text-gray-700 block px-4 py-2 text-sm flex"><img :src="getImageUrlByType()" :alt="'Login with ' + getNameByType()" class="h-5 pr-2"/><span v-if="business">{{getNameByType()}} Business</span><span v-else>{{getNameByType()}}</span></a>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue';
import useToastMessage from '@/composable/core/useToastMessage';
import {loadingController} from '@ionic/vue';
import {AuthenticationError} from '@/greeve/api/auth';
import useTranslation from '@/composable/translation/useTranslation';
import {ApiOAuthInterface} from '@/greeve/user/oauth/api_oauth.interface';
import {addSeconds, getCurrentDate} from '@/greeve/core/date';
import {OAuthType} from '@/greeve/user/oauth/oauth.interface';
import useUser from '@/composable/greeve/useUser';
import useAlert from '@/composable/core/useAlert';

export default defineComponent({
	name: 'MetaOAuthComponent',
	props: {
		business: {
			type: Boolean,
			default: false
		},
		active: {
			type: Boolean,
			default: false,
		},
		oAuthType: {
			type: Object as () => OAuthType,
			default: () => OAuthType.OAUTH_TYPE_META,
		}
	},
	emits: ['loginSuccess', 'loginError'],
	setup(props, {emit}) {
		const {t} = useTranslation();
		const providerLink = ref("");
		const {openToast} = useToastMessage();
		const loadingTimeout = ref(160000);
		const loading = ref();
		const {addOAuth} = useUser();
		const {addSuccess} = useAlert();

		async function presentLoading() {
			loading.value = await loadingController
				.create({
					cssClass: '',
					//TODO add Translations
					message: t('oauth.connect_loading'),
					duration: loadingTimeout.value,
				});

			await loading.value.present();

			setTimeout(function () {
				loading.value.dismiss()
			}, loadingTimeout.value);
		}

		function getImageUrlByType(): string
		{
			switch (props.oAuthType)
			{
				case OAuthType.OAUTH_TYPE_META_INSTAGRAM:
					return 'https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/images/socialmedia/instagram.png';
				case OAuthType.OAUTH_TYPE_META_WHATSAPP:
					return 'https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/images/socialmedia/whatsapp.png';
				case OAuthType.OAUTH_TYPE_META:
				default:
					return 'https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/images/socialmedia/facebook.png';
			}
		}

		function getNameByType(): string
		{
			switch (props.oAuthType)
			{
				case OAuthType.OAUTH_TYPE_META_INSTAGRAM:
					return 'Instagram';
				case OAuthType.OAUTH_TYPE_META_WHATSAPP:
					return 'WhatsApp';
				case OAuthType.OAUTH_TYPE_META:
				default:
					return 'Facebook';
			}
		}

		async function handleFacebookLoginError(error: any) {
			try {
				if (error instanceof AuthenticationError) {
					if (error.message.includes('Provider Data Error')) {
						await openToast(t("login.UserNotAllowed"), 'danger', 'top', true, 10000, undefined, true);
					} else {
						await openToast(t("login.error"), 'danger', 'top', true, 5000, undefined, true);
					}
				} else {
					if (error.error == "popup_closed_by_user") {
						await openToast(t("login.error"), 'warning', 'top', true, 5000, undefined, true);
					} else {
						await openToast(t("login.error"), 'danger', 'top', true, 5000, undefined, true);
					}
				}
			} catch (error) {
				await openToast(t("login.errorLogin"), 'danger', 'top', true, 5000, undefined, true);
			} finally {
				emit('loginError');
			}
		}

		async function loginWithFB(options?: any): Promise<any> {
			return new Promise((resolve, reject) => {
				// eslint-disable-next-line no-undef
				FB.login(function(response) {
					if (response.authResponse) {
						resolve(response);
					} else {
						reject('User cancelled login or did not fully authorize.');
					}
				}, options);
			});
		}

		const initializeMetaAuth = async () => {
			let configId = '986516032586997';
			let extraSetup = {};
			let responseType: string|null = null;
			let override_default_response_type = false;
			// let scope = 'email,public_profile,user_posts,user_photos,user_videos,pages_show_list';
			if (props.business) {
				if (props.oAuthType === OAuthType.OAUTH_TYPE_META_WHATSAPP) {
					configId = '250510528039526';
					// scope = 'pages_show_list,email,whatsapp_business_messaging,whatsapp_business_management';
					responseType = 'code';
					override_default_response_type = true;
					//TODO prefill setup data like company
					// 	setup: {
					// 		business: {
					// 			name: 'Acme Inc.',
					// 					email: 'johndoe@acme.com',
					// 					phone: {
					// 				code: 1,
					// 						number: '6505551234'
					// 			},
					// 			website: 'https://www.acme.com',
					// 					address: {
					// 				streetAddress1: '1 Acme Way',
					// 						city: 'Acme Town',
					// 						state: 'CA',
					// 						zipPostal: '94000',
					// 						country: 'US'
					// 			},
					// 			timezone: 'UTC-08:00'
					// 		},
					// 		phone: {
					// 			displayName: 'Acme Inc',
					// 					category: 'ENTERTAIN',
					// 					description: 'Acme Inc. is a leading entertainment company.',
					// 		}
					// 	}
				} else if (props.oAuthType === OAuthType.OAUTH_TYPE_META_INSTAGRAM) {
					configId = '337384908915719';
					// scope = 'pages_show_list,email,instagram_basic,instagram_content_publish,instagram_manage_comments'; //instagram_manage_messages
				}
			}
			loadFacebookSDK().then(() => {
				presentLoading();

				const fbLoginOptions = {
					config_id: configId,
					response_type: responseType,
					override_default_response_type: override_default_response_type,
					return_scopes: true,
					extras: extraSetup
				}

				console.log("FBOPTIONS", fbLoginOptions)
				loginWithFB(fbLoginOptions).then((response) => {
				// loginWithFB({scope, return_scopes: true}).then((response) => {
					console.log(response);
					const data = {
						isBusiness: props.business,
						authResponse: response.authResponse
					};
					const oauthData: ApiOAuthInterface = {
						data: data,
						expiry_date: addSeconds(getCurrentDate(), response.authResponse.expiresIn),
						name: undefined,
						reference: response.authResponse.userID,
						refresh_token: undefined,
						token: response.authResponse.accessToken,
						type: props.oAuthType,
					};

					addNewOAuthToProfile(oauthData).then(() => {
						setTimeout(() => {
							loading.value.dismiss();
							clearTimeout(loadingTimeout.value);
						}, 1000)
					})
				}).catch(function(error) {
					console.log(error);
					handleFacebookLoginError(error);
					if (loading.value) {
						loading.value.dismiss();
						clearTimeout(loadingTimeout.value);
					}
				});
			});
		};

		const loadFacebookSDK = async () => {
			return new Promise((resolve) => {
				// Check if the Facebook SDK is already loaded
				if (typeof FB === 'undefined') {
					const script = document.createElement('script');
					script.src = 'https://connect.facebook.net/en_US/sdk.js';
					script.async = true;
					script.defer = true;
					script.crossOrigin = 'anonymous';
					script.onload = () => {
						initializeFacebookSDK();
						resolve(true); // Resolve the promise once the script is loaded
					};
					document.head.appendChild(script);
				} else {
					initializeFacebookSDK();
					resolve(true); // Resolve the promise immediately if the SDK is already loaded
				}
			});
		};

		const initializeFacebookSDK = () => {
			// eslint-disable-next-line no-undef
			FB.init({
				appId: props.business ? process.env.VUE_APP_FB_APP_ID_BUSINESS : process.env.VUE_APP_FB_APP_ID_CONSUMER,
				autoLogAppEvents: true,
				xfbml: true,
				status: true,
				cookie: true,
				version: 'v18.0',
			});
		};

		async function addNewOAuthToProfile(data: ApiOAuthInterface)
		{
			addOAuth(data).then(() => {
				const success = {
					id: "",
					title: "New OAuth Account successfully connected!",
					message: "",
					active: true,
				}
				addSuccess(success);
			});
		}

		return {t, providerLink, initializeMetaAuth, getImageUrlByType, getNameByType}
	},
})
</script>

<style scoped>
</style>
