import {FaqFactory} from "@/greeve/faq/faq_factory.type";

export default function useFaqFactory() {

	let faqFactory: FaqFactory | null = null;
	const getFaqFactory = (): FaqFactory => {
		if (!faqFactory) {
			faqFactory = new FaqFactory();
		}
		return faqFactory;
	}

	return {
		getFaqFactory,
	}
}