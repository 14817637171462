import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  ref: "progressBar",
  class: "blog-post-progress"
}
const _hoisted_2 = { class: "relative" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "absolute inset-0 flex flex-col items-center justify-center text-white" }
const _hoisted_5 = { class: "text-4xl font-bold mb-4" }
const _hoisted_6 = { class: "container mx-auto px-2 py-2 flex bg-white my-4 rounded-2xl min-h-screen shadow-2xl" }
const _hoisted_7 = { class: "w-1/4 hidden lg:block p-3 bg-gray-200 rounded-xl" }
const _hoisted_8 = { class: "sticky top-16" }
const _hoisted_9 = { class: "list-disc list-inside" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_ctx.post)
    ? (_openBlock(), _createBlock(_component_ion_content, {
        key: 0,
        "scroll-events": true,
        onIonScroll: _ctx.onScroll,
        class: "relative mx-auto px-4"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, null, 512),
          _createElementVNode("section", _hoisted_2, [
            _createElementVNode("img", {
              src: _ctx.post.header_banner,
              alt: "Hero Banner",
              class: "w-full h-78 object-cover"
            }, null, 8, _hoisted_3),
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "absolute inset-0 bg-black opacity-50" }, null, -1)),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.post.title), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("aside", _hoisted_7, [
              _createElementVNode("nav", _hoisted_8, [
                _createVNode(_component_router_link, {
                  to: "/blog",
                  class: "flex items-center text-gr-dark bg-white rounded hover:underline"
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createElementVNode("svg", {
                      xmlns: "http://www.w3.org/2000/svg",
                      class: "h-6 w-6 mr-1",
                      fill: "none",
                      viewBox: "0 0 24 24",
                      stroke: "currentColor"
                    }, [
                      _createElementVNode("path", {
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                        "stroke-width": "2",
                        d: "M15 19l-7-7 7-7"
                      })
                    ], -1),
                    _createTextVNode(" Back to Blog ")
                  ])),
                  _: 1
                }),
                _cache[2] || (_cache[2] = _createElementVNode("h2", { class: "text-xl font-bold mb-4 pt-2" }, "Inhaltsverzeichnis", -1)),
                _createElementVNode("ul", _hoisted_9, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.post.tableOfContents, (item) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: item.title
                    }, [
                      _createElementVNode("a", {
                        onClick: ($event: any) => (_ctx.scrollToElement(item.title, 0, 0,'center', 'nearest', 2, true)),
                        class: "cursor-pointer text-gray-600 hover:underline"
                      }, _toDisplayString(item.title), 9, _hoisted_10)
                    ]))
                  }), 128))
                ])
              ])
            ]),
            _createElementVNode("article", {
              class: "p-4 pl-8 w-full lg:w-3/4",
              innerHTML: _ctx.post.content
            }, null, 8, _hoisted_11)
          ])
        ]),
        _: 1
      }, 8, ["onIonScroll"]))
    : _createCommentVNode("", true)
}