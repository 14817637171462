import router from "@/router";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function devMiddleware({next, to, from, store}) {
	const env = process.env.NODE_ENV;

	if (env === "development") {
		return next();
	} else {
		router.push('/error')
	}
	return next();
}