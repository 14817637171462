import {
	SubscriptionItemList
} from '@/greeve/shop/subscription/subscriptionItem/subscription_item_list.type';

export const enum SubscriptionState {
	ACTIVE = 'active',
}

export interface SubscriptionInterface {
	id: number,
	user_id: number;
	name: string,
	stripe_id: string,
	stripe_status: string,
	stripe_price: string,
	quantity: number,
	trial_ends_at?: Date,
	items?: SubscriptionItemList,
	attributes?: Record<string, any>,
	ends_at?: Date,
	data?: any,
	created_at?: Date,
	updated_at?: Date,
}