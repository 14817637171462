import {SearchGroup} from '@/greeve/search/group/search_group.type';

export const enum GreeveSearchItemTypeInterface {
	SEARCH_ITEM_TYPE_DEFAULT = 'default',
	SEARCH_ITEM_TYPE_AUTHORIZATION = 'authorization',
	SEARCH_ITEM_TYPE_INTERACTION = 'interaction',
	SEARCH_ITEM_TYPE_UPLOAD_INTERACTION = 'upload_interaction',
}

//TODO new item_type_upload -> interaction with user and state new show upload Component as prompt...
//TODO select subtype by button group.
//TODO send prompt and change upload prompt to state done! hide upload and show only image or document icon with name...
//TODO response normal...

export const enum GreeveSearchItemSubTypeInterface {
	SEARCH_ITEM_SUBTYPE_DEFAULT = 'default',
	SEARCH_ITEM_SUBTYPE_CHAT = 'chat',
	SEARCH_ITEM_SUBTYPE_COMPLETION = 'completion',
	SEARCH_ITEM_SUBTYPE_IMAGE = 'image',
	SEARCH_ITEM_SUBTYPE_IMAGE_EDIT = 'image_edit',
	SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION = 'image_variation',
	SEARCH_ITEM_SUBTYPE_IMAGE_REMOVE_BACKGROUND = 'image_remove_bg',
	SEARCH_ITEM_SUBTYPE_VISION = 'vision',
	SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT = 'speech_to_text',
	SEARCH_ITEM_SUBTYPE_DOCUMENT = 'document',
	SEARCH_ITEM_SUBTYPE_HTML = 'html',
	SEARCH_ITEM_SUBTYPE_RECIPE = 'recipe',
	SEARCH_ITEM_SUBTYPE_STUDY = 'study',
	SEARCH_ITEM_SUBTYPE_IMAGE_GENERATION = 'image_generation',
	SEARCH_ITEM_SUBTYPE_TEXT_TO_SPEECH = 'text_to_speech',
	SEARCH_ITEM_SUBTYPE_CODE = 'code',
	SEARCH_ITEM_SUBTYPE_BLOG = 'blog',
	SEARCH_ITEM_SUBTYPE_ARTICLE = 'article',
}

export const enum GreeveSearchItemStateInterface {
	SEARCH_ITEM_STATE_NEW = 'new',
	SEARCH_ITEM_STATE_IN_PROGRESS = 'in_progress',
	SEARCH_ITEM_STATE_ERROR = 'error',
	SEARCH_ITEM_STATE_DONE = 'done',
	SEARCH_ITEM_STATE_NULLIFY = 'nullify',
}

export interface SearchItemInterface {
	id: number;
	search_item_group_id?: number;
	search_group?: SearchGroup;
	uuid: string;
	subType: GreeveSearchItemSubTypeInterface;
	state: GreeveSearchItemStateInterface;
	prompt?: string;
	reference?: string;
	group_reference?: string;
	is_pinned: boolean;
	request?: any;
	response?: any;
	data?: any;
	created_at?: Date;
	updated_at?: Date;
}

export interface SearchItemResponseErrorInterface {
	message: string;
	code?: number;
	response?: any;
	data?: any;
	created_at?: Date;
	updated_at?: Date;
}