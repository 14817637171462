import {SearchGroupList} from '@/greeve/search/group/search_group_list.type';
import {SearchGroup} from '@/greeve/search/group/search_group.type';
import {
	GreeveSearchGroupTypeInterface
} from '@/greeve/search/group/search_group.interface';
import {SearchItemList} from '@/greeve/search/item/search_item_list.type';
import useAssistantFactory from '@/composable/greeve/useAssistantFactory';
import {
	AbstractPaginationFactory
} from '@/greeve/core/abstract_pagination_factory.type';

export class SearchGroupFactory extends AbstractPaginationFactory{
	/**
	 *
	 * @param searchGroup
	 */
	createSearchGroupListByResponse(searchGroup: Record<string, any>): SearchGroupList {
		const searchGroupList = new SearchGroupList();

		if (this.hasPaginationMetaData(searchGroup)) {
			searchGroupList.enhanceMetaData(this.getMetaDataByResponse(searchGroup));
			searchGroup = searchGroup.data;
		}

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		for (const [key, item] of Object.entries(searchGroup)) {
			searchGroupList.push(this.createSearchGroupByResponse(item));
		}

		return searchGroupList;
	}

	/**
	 *
	 * @param item
	 */
	createSearchGroupByResponse(item: Record<string, any>): SearchGroup {
		const id = item.id;
		const uuid = item.uuid;
		const type = item.type ?? GreeveSearchGroupTypeInterface.SEARCH_GROUP_TYPE_DEFAULT;
		const state = item.state;

		let searchItemList: SearchItemList | undefined;
		if (item.search_items && item.search_items.length > 0) {
			searchItemList = useAssistantFactory().getSearchItemFactory().createSearchItemListByResponse(item.search_items);
		} else if (item.searchItems && item.searchItems.length > 0) {
			searchItemList = useAssistantFactory().getSearchItemFactory().createSearchItemListByResponse(item.searchItems)
		} else {
			searchItemList = useAssistantFactory().getSearchItemFactory().createEmptySearchItemList();
		}

		const reference = item.reference ? item.reference : undefined;
		const group_name = item.name ? item.name : undefined;
		const user_id = item.user_id ? item.user_id : (item.userId ? item.userId : undefined);
		const parent_id = item.parent_id ? item.parent_id : (item.parentId ? item.parentId : undefined);
		const is_pinned = item.is_pinned ? item.is_pinned : (item.isPinned ? item.isPinned : undefined);
		const data = item.data;
		const created_at = item.created_at ? new Date(item.created_at) : (item.createdAt ? new Date(item.createdAt) : new Date());
		const updated_at = item.updated_at ? new Date(item.updated_at) : (item.updatedAt ? new Date(item.updatedAt) : new Date());

		return new SearchGroup(id, uuid, type, state, searchItemList, reference, user_id, parent_id, group_name, is_pinned, data, created_at, updated_at);
	}

	createEmptySearchGroupList() {
			return new SearchGroupList([]);
	}
}