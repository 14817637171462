export const enum GreeveProductPriceType {
	RECURRING_PRICE = 'recurring',
	ONE_TIME_PRICE = 'one_time',
}
export const enum GreeveProductPriceSubType {
	RECURRING_MONTHLY = 'recurring_monthly',
	RECURRING_YEARLY = 'recurring_yearly',
}

/**
 * PER_UNIT indicates that the fixed amount (specified in unit_amount or unit_amount_decimal) will be charged per unit in quantity (for prices with usage_type=licensed), or per unit of total usage (for prices with usage_type=metered)
 * TIERED indicates that the unit pricing will be computed using a tiering strategy as defined using the tiers and tiers_mode attributes.
 */
export const enum GreeveProductPriceBillingScheme {
	PER_UNIT = 'per_unit',
	TIERED = 'tiered',
}

export interface ProductPriceInterface {
	price_id: string;
	type: GreeveProductPriceType;
	sub_type?: GreeveProductPriceSubType;
	object?: string;
	active: boolean;
	billing_scheme: GreeveProductPriceBillingScheme;
	amount: number,
	tax_amount: number,
	currency: string,
	custom_unit_amount?: number,
	recurring?: {
		aggregate_usage?: any,
		interval?: string,
		interval_count?: number,
		trial_period_days?: any,
		usage_type?: string,
	},
	tax_behavior?: string,
	tiers_mode?: any,
	transform_quantity?: {
		divide_by?: number,
		round?: string,
	},
	unit_amount: number,
	unit_amount_decimal: string,
	metadata?: any,
	data?: any,
}
