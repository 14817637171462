import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "mx-auto py-16 px-4 sm:py-4 sm:px-6 lg:max-w-7xl lg:px-4" }
const _hoisted_2 = { class: "lg:grid lg:grid-cols-7 lg:grid-rows-1 lg:gap-x-8 lg:gap-y-10 xl:gap-x-16" }
const _hoisted_3 = { class: "lg:col-span-4 lg:row-end-1" }
const _hoisted_4 = { class: "aspect-w-4 h-96 aspect-h-3 overflow-hidden rounded-xl bg-gray-50" }
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = { class: "mx-auto mt-8 max-w-2xl md:mt-16 lg:col-span-3 lg:row-span-2 lg:row-end-2 lg:mt-0 lg:max-w-none" }
const _hoisted_7 = { class: "flex flex-col-reverse" }
const _hoisted_8 = { class: "mt-4" }
const _hoisted_9 = { class: "text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl" }
const _hoisted_10 = {
  id: "information-heading",
  class: "sr-only"
}
const _hoisted_11 = { class: "mt-2 text-sm text-gray-500" }
const _hoisted_12 = ["datetime"]
const _hoisted_13 = {
  class: "flex px-4 py-1.5 my-4 mb-0 items-center text-center bg-gray-100 text-gray-500 rounded-xl",
  type: "submit"
}
const _hoisted_14 = { class: "text-md" }
const _hoisted_15 = { class: "sr-only" }
const _hoisted_16 = { class: "flex items-center" }
const _hoisted_17 = { class: "sr-only" }
const _hoisted_18 = { class: "mt-6 text-gray-500" }
const _hoisted_19 = { class: "mt-8" }
const _hoisted_20 = { class: "flex items-center justify-between" }
const _hoisted_21 = { class: "text-sm font-medium text-gray-900 dark:text-gray-200" }
const _hoisted_22 = { class: "grid grid-cols-3 gap-3 sm:grid-cols-6" }
const _hoisted_23 = { class: "text-sm font-medium" }
const _hoisted_24 = { class: "flex-auto mt-8 md:flex md:space-x-4 space-y-4 md:space-y-0" }
const _hoisted_25 = {
  class: "h-12 w-full px-6 font-semibold rounded-xl bg-black text-white",
  type: "submit"
}
const _hoisted_26 = ["aria-describedby"]
const _hoisted_27 = {
  key: 0,
  class: "mx-auto flex align-center justify-center content-center"
}
const _hoisted_28 = { key: 1 }
const _hoisted_29 = { class: "mt-10 border-t border-gray-200 pt-10" }
const _hoisted_30 = { class: "text-sm font-medium text-gray-900 dark:text-gray-200" }
const _hoisted_31 = { class: "prose prose-sm mt-4 text-gray-500" }
const _hoisted_32 = { role: "list" }
const _hoisted_33 = { class: "mt-10 border-t border-gray-200 pt-10" }
const _hoisted_34 = { class: "text-sm font-medium text-gray-900 dark:text-gray-200" }
const _hoisted_35 = { class: "mt-4 text-sm text-gray-500" }
const _hoisted_36 = ["href"]
const _hoisted_37 = { class: "mt-10 border-t border-gray-200 pt-10" }
const _hoisted_38 = { class: "text-sm font-medium text-gray-900 dark:text-gray-200" }
const _hoisted_39 = {
  role: "list",
  class: "mt-4 flex items-center space-x-6"
}
const _hoisted_40 = {
  href: "#",
  class: "flex h-6 w-6 items-center justify-center text-gray-400 hover:text-gray-500"
}
const _hoisted_41 = { class: "sr-only" }
const _hoisted_42 = {
  href: "#",
  class: "flex h-6 w-6 items-center justify-center text-gray-400 hover:text-gray-500"
}
const _hoisted_43 = { class: "sr-only" }
const _hoisted_44 = {
  href: "#",
  class: "flex h-6 w-6 items-center justify-center text-gray-400 hover:text-gray-500"
}
const _hoisted_45 = { class: "sr-only" }
const _hoisted_46 = { class: "mx-auto mt-16 w-full max-w-2xl lg:col-span-4 lg:mt-0 lg:max-w-none" }
const _hoisted_47 = { class: "border-b border-gray-200" }
const _hoisted_48 = { class: "sr-only" }
const _hoisted_49 = { class: "flex" }
const _hoisted_50 = { class: "flex-none mt-1.5 mr-3" }
const _hoisted_51 = ["src"]
const _hoisted_52 = { class: "font-medium text-gray-900 dark:text-gray-200" }
const _hoisted_53 = ["datetime"]
const _hoisted_54 = { class: "mt-4 flex items-center" }
const _hoisted_55 = { class: "sr-only" }
const _hoisted_56 = ["innerHTML"]
const _hoisted_57 = { class: "sr-only" }
const _hoisted_58 = { class: "mt-10 font-medium text-gray-900 dark:text-gray-200" }
const _hoisted_59 = { class: "prose prose-sm mt-2 max-w-none text-gray-500" }
const _hoisted_60 = { class: "sr-only" }
const _hoisted_61 = ["innerHTML"]
const _hoisted_62 = { class: "md:mx-4 max-w-2xl py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8" }
const _hoisted_63 = { class: "text-2xl font-bold tracking-tight text-gray-900" }
const _hoisted_64 = { class: "mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8" }
const _hoisted_65 = { class: "group relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BreadcrumbsHeader = _resolveComponent("BreadcrumbsHeader")!
  const _component_PlayIcon = _resolveComponent("PlayIcon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_StarIcon = _resolveComponent("StarIcon")!
  const _component_RadioGroupLabel = _resolveComponent("RadioGroupLabel")!
  const _component_RadioGroupOption = _resolveComponent("RadioGroupOption")!
  const _component_RadioGroup = _resolveComponent("RadioGroup")!
  const _component_Tab = _resolveComponent("Tab")!
  const _component_TabList = _resolveComponent("TabList")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_TabPanels = _resolveComponent("TabPanels")!
  const _component_TabGroup = _resolveComponent("TabGroup")!
  const _component_GreeveShopProduct = _resolveComponent("GreeveShopProduct")!
  const _component_FooterBar = _resolveComponent("FooterBar")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("main", {
            style: _normalizeStyle({ paddingTop: _ctx.headerHeight + 'px' })
          }, [
            _createElementVNode("div", null, [
              _createVNode(_component_BreadcrumbsHeader, {
                from: "0",
                till: "2"
              }),
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("img", {
                        loading: "lazy",
                        src: _ctx.product.imageSrc,
                        alt: _ctx.product.imageAlt,
                        class: "bg-gradient object-cover object-center"
                      }, null, 8, _hoisted_5)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("h1", _hoisted_9, _toDisplayString(_ctx.product.name), 1),
                        _createElementVNode("h2", _hoisted_10, _toDisplayString(_ctx.$t('productInformation')), 1),
                        _createElementVNode("p", _hoisted_11, [
                          _createTextVNode(_toDisplayString(_ctx.$t('versionUpdated', {name: _ctx.product.version.name})) + " ", 1),
                          _createElementVNode("time", {
                            datetime: _ctx.product.version.datetime
                          }, _toDisplayString(_ctx.product.version.date), 9, _hoisted_12),
                          _cache[2] || (_cache[2] = _createTextVNode(") "))
                        ]),
                        _createVNode(_component_router_link, { to: "/workflow/demo" }, {
                          default: _withCtx(() => [
                            _createElementVNode("button", _hoisted_13, [
                              _createVNode(_component_PlayIcon, { class: "mx-auto text-gray-400 mr-1 h-6 w-6" }),
                              _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t('watchDemo')), 1)
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _createElementVNode("div", null, [
                        _createElementVNode("h3", _hoisted_15, _toDisplayString(_ctx.$t('reviews')), 1),
                        _createElementVNode("div", _hoisted_16, [
                          (_openBlock(), _createElementBlock(_Fragment, null, _renderList([0, 1, 2, 3, 4], (rating) => {
                            return _createVNode(_component_StarIcon, {
                              key: rating,
                              class: _normalizeClass([_ctx.reviews.average > rating ? 'text-yellow-400' : 'text-gray-300', 'h-5 w-5 flex-shrink-0']),
                              "aria-hidden": "true"
                            }, null, 8, ["class"])
                          }), 64))
                        ]),
                        _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.$t('outOf5Stars2', {average: _ctx.reviews.average})), 1)
                      ])
                    ]),
                    _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.product.description), 1),
                    _createElementVNode("div", _hoisted_19, [
                      _createElementVNode("div", _hoisted_20, [
                        _createElementVNode("h2", _hoisted_21, _toDisplayString(_ctx.$t('chooseYourNeededMinutes')), 1)
                      ]),
                      _createVNode(_component_RadioGroup, {
                        modelValue: _ctx.selectedSize,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedSize) = $event)),
                        class: "mt-4"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_RadioGroupLabel, { class: "sr-only" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('chooseATime')), 1)
                            ]),
                            _: 1
                          }),
                          _createElementVNode("div", _hoisted_22, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.product.sizes, (size) => {
                              return (_openBlock(), _createBlock(_component_RadioGroupOption, {
                                as: "template",
                                key: size.name,
                                value: size,
                                disabled: !size.inStock
                              }, {
                                default: _withCtx(({ active, checked }) => [
                                  _createElementVNode("div", {
                                    class: _normalizeClass([size.inStock ? 'cursor-pointer focus:outline-none' : 'opacity-25 cursor-not-allowed', active ? 'outline-none' : '', checked ? 'bg-gray-100 ring-black ring-2 border-transparent font-semibold hover:bg-gray-100' : 'bg-white border-gray-200 text-gray-400 hover:bg-gray-50', 'border rounded-xl py-3 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1'])
                                  }, [
                                    _createVNode(_component_RadioGroupLabel, { as: "span" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(size.name), 1)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ], 2)
                                ]),
                                _: 2
                              }, 1032, ["value", "disabled"]))
                            }), 128))
                          ])
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_23, [
                      _createElementVNode("div", _hoisted_24, [
                        _createElementVNode("button", _hoisted_25, _toDisplayString(_ctx.$t('buyNow')), 1),
                        _createElementVNode("button", {
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.addProductToCart_by_Product(_ctx.product))),
                          "aria-describedby": _ctx.product.product_id,
                          class: "h-12 w-full px-6 font-semibold rounded-xl bg-gray-100",
                          type: "button"
                        }, [
                          (_ctx.addToCartLoading)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_27, _cache[3] || (_cache[3] = [
                                _createElementVNode("svg", {
                                  class: "animate-spin mr-2 h-5 w-5",
                                  xmlns: "http://www.w3.org/2000/svg",
                                  fill: "none",
                                  viewBox: "0 0 24 24"
                                }, [
                                  _createElementVNode("circle", {
                                    class: "opacity-25",
                                    cx: "12",
                                    cy: "12",
                                    r: "10",
                                    stroke: "currentColor",
                                    "stroke-width": "4"
                                  }),
                                  _createElementVNode("path", {
                                    class: "opacity-75",
                                    fill: "currentColor",
                                    d: "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                  })
                                ], -1)
                              ])))
                            : (_openBlock(), _createElementBlock("span", _hoisted_28, _toDisplayString(_ctx.$t('addToCart')), 1))
                        ], 8, _hoisted_26)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_29, [
                      _createElementVNode("h3", _hoisted_30, _toDisplayString(_ctx.$t('highlights')), 1),
                      _createElementVNode("div", _hoisted_31, [
                        _createElementVNode("ul", _hoisted_32, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.product.highlights, (highlight) => {
                            return (_openBlock(), _createElementBlock("li", { key: highlight }, _toDisplayString(highlight), 1))
                          }), 128))
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_33, [
                      _createElementVNode("h3", _hoisted_34, _toDisplayString(_ctx.$t('license')), 1),
                      _createElementVNode("p", _hoisted_35, [
                        _createTextVNode(_toDisplayString(_ctx.license.summary) + " ", 1),
                        _createElementVNode("a", {
                          href: _ctx.license.href,
                          class: "font-medium text-gr-primary hover:text-gr-primary2"
                        }, _toDisplayString(_ctx.$t('readFullLicense')), 9, _hoisted_36)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_37, [
                      _createElementVNode("h3", _hoisted_38, _toDisplayString(_ctx.$t('share')), 1),
                      _createElementVNode("ul", _hoisted_39, [
                        _createElementVNode("li", null, [
                          _createElementVNode("a", _hoisted_40, [
                            _createElementVNode("span", _hoisted_41, _toDisplayString(_ctx.$t('shareOnFacebook')), 1),
                            _cache[4] || (_cache[4] = _createElementVNode("svg", {
                              class: "h-5 w-5",
                              "aria-hidden": "true",
                              fill: "currentColor",
                              viewBox: "0 0 20 20"
                            }, [
                              _createElementVNode("path", {
                                "fill-rule": "evenodd",
                                d: "M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z",
                                "clip-rule": "evenodd"
                              })
                            ], -1))
                          ])
                        ]),
                        _createElementVNode("li", null, [
                          _createElementVNode("a", _hoisted_42, [
                            _createElementVNode("span", _hoisted_43, _toDisplayString(_ctx.$t('shareOnInstagram')), 1),
                            _cache[5] || (_cache[5] = _createElementVNode("svg", {
                              class: "h-6 w-6",
                              "aria-hidden": "true",
                              fill: "currentColor",
                              viewBox: "0 0 24 24"
                            }, [
                              _createElementVNode("path", {
                                "fill-rule": "evenodd",
                                d: "M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z",
                                "clip-rule": "evenodd"
                              })
                            ], -1))
                          ])
                        ]),
                        _createElementVNode("li", null, [
                          _createElementVNode("a", _hoisted_44, [
                            _createElementVNode("span", _hoisted_45, _toDisplayString(_ctx.$t('shareOnTwitter')), 1),
                            _cache[6] || (_cache[6] = _createElementVNode("svg", {
                              class: "h-5 w-5",
                              "aria-hidden": "true",
                              fill: "currentColor",
                              viewBox: "0 0 20 20"
                            }, [
                              _createElementVNode("path", { d: "M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" })
                            ], -1))
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_46, [
                    _createVNode(_component_TabGroup, { as: "div" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_47, [
                          _createVNode(_component_TabList, { class: "-mb-px flex sm:space-x-8" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_Tab, { as: "template" }, {
                                default: _withCtx(({ selected }) => [
                                  _createElementVNode("button", {
                                    class: _normalizeClass([selected ? 'rounded-xl bg-gray-100 text-gray-500 outline-none' : 'border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300', 'whitespace-nowrap border-b-2 py-2 px-4 sm:px-6 mb-6 text-sm font-medium'])
                                  }, _toDisplayString(_ctx.$t('customerReviews')), 3)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_Tab, { as: "template" }, {
                                default: _withCtx(({ selected }) => [
                                  _createElementVNode("button", {
                                    class: _normalizeClass([selected ? 'rounded-xl bg-gray-100 text-gray-500 outline-none' : 'border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300', 'whitespace-nowrap border-b-2 py-2 px-4 sm:px-6 mb-6 text-sm font-medium'])
                                  }, _toDisplayString(_ctx.$t('faq')), 3)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_Tab, { as: "template" }, {
                                default: _withCtx(({ selected }) => [
                                  _createElementVNode("button", {
                                    class: _normalizeClass([selected ? 'rounded-xl bg-gray-100 text-gray-500 outline-none' : 'border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300', 'whitespace-nowrap border-b-2 py-2 px-4 sm:px-6 mb-6 text-sm font-medium'])
                                  }, _toDisplayString(_ctx.$t('license')), 3)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _createVNode(_component_TabPanels, { as: "template" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_TabPanel, { class: "lg:mx-6 -mb-10" }, {
                              default: _withCtx(() => [
                                _createElementVNode("h3", _hoisted_48, _toDisplayString(_ctx.$t('customerReviews')), 1),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.reviews.featured, (review, reviewIdx) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: review.id,
                                    class: "flex space-x-4 text-sm text-gray-500"
                                  }, [
                                    _createElementVNode("div", {
                                      class: _normalizeClass([reviewIdx === 0 ? '' : 'border-t border-gray-200', 'py-10'])
                                    }, [
                                      _createElementVNode("div", _hoisted_49, [
                                        _createElementVNode("div", _hoisted_50, [
                                          _createElementVNode("img", {
                                            loading: "lazy",
                                            src: review.avatarSrc,
                                            alt: "",
                                            class: "h-10 w-10 rounded-full"
                                          }, null, 8, _hoisted_51)
                                        ]),
                                        _createElementVNode("div", null, [
                                          _createElementVNode("h3", _hoisted_52, _toDisplayString(review.author), 1),
                                          _createElementVNode("p", null, [
                                            _createElementVNode("time", {
                                              datetime: review.datetime
                                            }, _toDisplayString(review.date), 9, _hoisted_53)
                                          ])
                                        ])
                                      ]),
                                      _createElementVNode("div", _hoisted_54, [
                                        (_openBlock(), _createElementBlock(_Fragment, null, _renderList([0, 1, 2, 3, 4], (rating) => {
                                          return _createVNode(_component_StarIcon, {
                                            key: rating,
                                            class: _normalizeClass([review.rating > rating ? 'text-yellow-400' : 'text-gray-300', 'h-5 w-5 flex-shrink-0']),
                                            "aria-hidden": "true"
                                          }, null, 8, ["class"])
                                        }), 64))
                                      ]),
                                      _createElementVNode("p", _hoisted_55, _toDisplayString(_ctx.$t('outOf5Stars', {rating: review.rating})), 1),
                                      _createElementVNode("div", {
                                        class: "prose prose-sm mt-4 max-w-none text-gray-500",
                                        innerHTML: review.content
                                      }, null, 8, _hoisted_56)
                                    ], 2)
                                  ]))
                                }), 128))
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_TabPanel, { class: "text-sm lg:mx-6 text-gray-500" }, {
                              default: _withCtx(() => [
                                _createElementVNode("h3", _hoisted_57, _toDisplayString(_ctx.$t('frequentlyAskedQuestions')), 1),
                                _createElementVNode("dl", null, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.faqs, (faq) => {
                                    return (_openBlock(), _createElementBlock(_Fragment, {
                                      key: faq.question
                                    }, [
                                      _createElementVNode("dt", _hoisted_58, _toDisplayString(faq.question), 1),
                                      _createElementVNode("dd", _hoisted_59, [
                                        _createElementVNode("p", null, _toDisplayString(faq.answer), 1)
                                      ])
                                    ], 64))
                                  }), 128))
                                ])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_TabPanel, { class: "pt-10 lg:mx-6" }, {
                              default: _withCtx(() => [
                                _createElementVNode("h3", _hoisted_60, _toDisplayString(_ctx.$t('license')), 1),
                                _createElementVNode("div", {
                                  class: "prose prose-sm max-w-none text-gray-500",
                                  innerHTML: _ctx.license.content
                                }, null, 8, _hoisted_61)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_62, [
                _createElementVNode("h2", _hoisted_63, _toDisplayString(_ctx.$t('customersAlsoPurchased')), 1),
                _createElementVNode("div", _hoisted_64, [
                  _createElementVNode("div", _hoisted_65, [
                    _createVNode(_component_GreeveShopProduct)
                  ])
                ])
              ])
            ]),
            _createVNode(_component_FooterBar, { class: "mt-12" })
          ], 4)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}