import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "rounded-lg" }
const _hoisted_2 = {
  class: "ml-6 bg-transparent h-5 block text-left text-sm font-medium text-gray-400",
  for: "email"
}
const _hoisted_3 = { class: "outer-card rounded-xl overflow-hidden" }
const _hoisted_4 = { class: "absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ExclamationCircleIcon = _resolveComponent("ExclamationCircleIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t('jobs.FormMail')), 1),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_ion_input, {
          id: "email",
          "aria-describedby": "email-error",
          "aria-invalid": "true",
          placeholder: _ctx.inputPlaceholder,
          required: _ctx.requiredField,
          type: _ctx.inputType,
          modelValue: _ctx.emailState,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.emailState) = $event)),
          autocomplete: "email",
          class: _normalizeClass([ _ctx.errorMessage.length > 0 ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500' : ''])
        }, null, 8, ["placeholder", "required", "type", "modelValue", "class"])
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_4, [
        _createVNode(_component_ExclamationCircleIcon, {
          "aria-hidden": "true",
          class: "h-5 w-5 text-red-500"
        })
      ], 512), [
        [_vShow, _ctx.errorMessage.length > 0]
      ])
    ]),
    _withDirectives(_createElementVNode("p", {
      id: "email-error",
      class: "mt-2 text-sm text-red-600"
    }, _toDisplayString(_ctx.errorMessage), 513), [
      [_vShow, _ctx.errorMessage.length > 0]
    ])
  ]))
}