<template>
	<LoadingAnimation v-if="isGlobalLoading" :loading-text="$t('loading.DefaultSpinner.loadingText')"></LoadingAnimation>
	<ion-app>
		<ion-header v-show="!isHeaderBlocked">
			<NavbarPro></NavbarPro>
		</ion-header>
		<ion-content :class="['ion-padding', isHeaderBlocked ? 'no-padding-top' : '']"  :style="{ '--offset-top': headerHeight + 'px' }" :scroll-events="true" fullscreen>
			<HeaderInfo v-if="!isHeaderBlocked" id="promo-header" promo-code="GREEVEBETA20"
									:always-visible="false"></HeaderInfo>
			<ion-router-outlet class="h-full w-full"/>
			<FloatingBottomBanner v-if="!isSystemAvailable" :title="$t('error.500')" color="bg-red-700"></FloatingBottomBanner>
		</ion-content>
		<CookiesDialog></CookiesDialog>
		<ion-footer>
		</ion-footer>
	</ion-app>
</template>

<script setup lang="ts">
import {
	IonApp,
	IonFooter, IonHeader, IonRouterOutlet, IonContent,
} from '@ionic/vue';
import {onBeforeMount, ref, watch} from 'vue';
import CookiesDialog from '@/components/modal/CookiesDialog.vue';
import {useI18n} from 'vue-i18n';
import useCustomStore from '@/composable/custom/useCustomStore';
import {useRoute} from 'vue-router';
import useSystem from '@/composable/core/useSystem';
import AOS from 'aos';
import 'aos/dist/aos.css';
import NavbarPro from '@/components/navigations/NavbarPro.vue';
import LoadingAnimation from '@/components/animations/LoadingAnimation.vue';
import {useHeaderHeight} from '@/composable/global/useHeaderHeight';
import HeaderInfo from '@/components/modal/HeaderInfo.vue';
import {RouteChecker} from '@/composable/core/route_checker';
import FloatingBottomBanner from '@/components/modal/FloatingBottomBanner.vue';

AOS.init();
AOS.refreshHard();

const {setHeaderHeight, initHeaderHeight, headerHeight} = useHeaderHeight();
const {
	getLanguageLocale,
	setLanguageLocale,
	getDefaultBrowserLanguageCode,
	isPageInitialized,
	setIsPageInitialized,
} = useCustomStore();
const i18n = useI18n();
const route = useRoute();
const {initVersion} = useSystem();
const {isGlobalLoading, setIsGlobalLoading} = useCustomStore();

const isSystemAvailable = ref(true);
const blockedRoutes = ['/auth', '/register'];
const routeChecker = new RouteChecker(blockedRoutes);
const isHeaderBlocked = ref<boolean>(routeChecker.isRouteBlocked(route.path));

setInterval(() => {
	useSystem().checkIfSystemIsAvailable().then((response) => {
		isSystemAvailable.value = response;
	});
}, 300000)

watch(
		() => route.path,
		(newPath) => {
			isHeaderBlocked.value = routeChecker.isRouteBlocked(newPath);
			if (isHeaderBlocked.value) {
				setHeaderHeight(0);
			} else {
				initHeaderHeight();
			}
		},
);

onBeforeMount(() => {
	try {
		initVersion();
		if (getLanguageLocale.value.length === 0 || !isPageInitialized.value) {
			setLanguageLocale(getDefaultBrowserLanguageCode());
			setIsPageInitialized(true);
		}
		i18n.locale.value = getLanguageLocale.value;
		if (isHeaderBlocked.value) {
			setHeaderHeight(0);
		}
		useSystem().checkIfSystemIsAvailable().then((response) => {
			isSystemAvailable.value = response;
		});
		setIsGlobalLoading(false);
	} catch (e) {
		console.log(e);
	}
});
</script>

<style lang="scss">
html {
	scroll-behavior: smooth;
}
ion-content {
	padding-top: var(--offset-top, 0);
	--offset-top: 0;
}

.no-padding-top {
	--offset-top: 0px !important;
}

</style>
