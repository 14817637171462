import {CurrencyFactory} from "@/greeve/core/country/currency_factory.type";

export default function useCurrencyFactory() {

	let currencyFactory: CurrencyFactory | null = null;
	const getCurrencyFactory = (): CurrencyFactory => {
		if (!currencyFactory) {
			currencyFactory = new CurrencyFactory();
		}
		return currencyFactory;
	}

	return {
		getCurrencyFactory,
	}
}