import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "rounded-3xl px-3 font-comfortaa py-0 bg-black dark:bg-gray-400 font-bold text-white dark:text-black mt-0 float-right text-tc-bg text-xs sm:text-base sm:mr-1.5 -mt-1 sm:-mt-2" }
const _hoisted_2 = { class: "" }
const _hoisted_3 = { class: "mx-4 md:mx-7" }
const _hoisted_4 = { class: "py-32 xl:py-36 px-4 sm:px-6 lg:px-8 bg-tc-bg overflow-hidden" }
const _hoisted_5 = { class: "max-w-max lg:max-w-7xl mx-auto" }
const _hoisted_6 = { class: "relative mb-8 md:mb-2 md:px-6" }
const _hoisted_7 = { class: "text-base max-w-prose lg:max-w-none" }
const _hoisted_8 = { class: "mt-2 text-3xl leading-8 font-extrabold tracking-tight text-tc-blue sm:text-4xl" }
const _hoisted_9 = { class: "relative" }
const _hoisted_10 = { class: "relative md:bg-tc-bg md:p-6" }
const _hoisted_11 = { class: "lg:grid lg:gap-6" }
const _hoisted_12 = { class: "break-words w-full text-lg lg:text-xl py-0 sm:py-0 lg:py-0" }
const _hoisted_13 = { href: "mailto:service@theconcept.app" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderLogo = _resolveComponent("HeaderLogo")!
  const _component_FooterBar = _resolveComponent("FooterBar")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode(_component_HeaderLogo, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('beta')), 1)
            ]),
            _: 1
          }),
          _cache[115] || (_cache[115] = _createTextVNode()),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.t('terms.TermsTitle')), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createElementVNode("div", _hoisted_12, [
                          _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1)),
                          _createElementVNode("ol", null, [
                            _createElementVNode("li", null, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.$t('1AllgemeineBestimmungen')), 1)
                            ])
                          ]),
                          _cache[9] || (_cache[9] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('11Geltungsbereich')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('dieseAllgemeinenGeschftsbedingungenAgbRegelnDieNut')), 1),
                          _cache[10] || (_cache[10] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('12ZustimmungUndAnnahmeDerAgb')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('durchDieNutzungUnsererWebsiteUndServicesErklrenSie')), 1),
                          _cache[11] || (_cache[11] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('12KitechnologieUndNutzung')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('unsereDienstleistungVerwendetModernsteKnstlicheInt')), 1),
                          _cache[12] || (_cache[12] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('14Nutzungsberechtigung')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('dieWebsiteIstFrBenutzerBestimmtDieMindestens13Jahr')), 1),
                          _cache[13] || (_cache[13] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('15RegistrierungUndBenutzerkonto')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('umBestimmteFunktionenUnsererWebsiteUndServicesNutz')), 1),
                          _cache[14] || (_cache[14] = _createElementVNode("p", null, null, -1)),
                          _cache[15] || (_cache[15] = _createElementVNode("br", null, null, -1)),
                          _createElementVNode("ol", null, [
                            _createElementVNode("li", null, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.$t('2NutzungsrechteUndEinschrnkungen')), 1)
                            ])
                          ]),
                          _cache[16] || (_cache[16] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('21GeistigesEigentum')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('dieWebsiteUndServicesVonTheconceptTechnologiesLlcS')), 1),
                          _cache[17] || (_cache[17] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, [
                            _createTextVNode(_toDisplayString(_ctx.$t('derInhaltUndDieMarkenWerdenAufDerWebsiteAsIsNurZuI')), 1),
                            _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
                            _createTextVNode(" " + _toDisplayString(_ctx.$t('sofernSieZurNutzungDerWebsiteBerechtigtSindErhalte')), 1)
                          ]),
                          _cache[18] || (_cache[18] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('22ErlaubteNutzung')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('sieDrfenUnsereWebsiteUndServicesNurFrRechtmigeZwec')), 1),
                          _cache[19] || (_cache[19] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('23VerboteneAktivitten')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('beiDerNutzungUnsererWebsiteUndServicesSindIhnenFol')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('dieVerletzungVonGeistigenEigentumsrechtenDritter')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('dieVerbreitungVonIllegalenBedrohlichenDiffamierend')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('dieNutzungUnsererWebsiteUndServicesInEinerWeiseDie')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('derVersuchUnbefugtenZugriffAufUnsereWebsiteUndServ')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('dasSammelnOderSpeichernPersonenbezogenerDatenAnder')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('dieVerwendungVonAutomatisiertenSystemenEinschlieli')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('dieVerbreitungVonVirenMalwareOderAnderenSchdlichen')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('dieNutzungDerFreiZugnglichenTeileUnsererWebsiteUnd')), 1),
                          _cache[20] || (_cache[20] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('24Benutzerinformation')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('durchDieNutzungDerWebsiteErklrenUndGarantierenSieD')), 1),
                          _createElementVNode("ul", null, [
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('alleRegistrierungsinformationenDieSieBermittelnWah')), 1),
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('sieWerdenDieRichtigkeitDieserInformationenAufrecht')), 1),
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('sieSindGeschftsfhigUndStimmenZuDieseNutzungsbeding')), 1),
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('sieSindNichtUnter13JahreAlt')), 1),
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('sieSindInDemLandInDemSieWohnenNichtMinderjhrigOder')), 1),
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('sieGreifenNichtBerAutomatisierteOderNichtmenschlic')), 1),
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('sieWerdenDieWebsiteNichtFrIllegaleOderUnbefugteZwe')), 1),
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('ihreNutzungDerWebsiteVersttNichtGegenGeltendeGeset')), 1)
                          ]),
                          _cache[21] || (_cache[21] = _createElementVNode("p", null, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('wennSieUnwahreUngenaueNichtAktuelleOderUnvollstndi')), 1),
                          _cache[22] || (_cache[22] = _createElementVNode("p", null, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('esKannErforderlichSeinDassSieSichAufDerWebsiteRegi')), 1),
                          _cache[23] || (_cache[23] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('25Benutzerinhalte')), 1),
                          _cache[24] || (_cache[24] = _createElementVNode("p", null, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('wirSindNichtFrBenutzerinhalteVerantwortlichUnabhng')), 1),
                          _cache[25] || (_cache[25] = _createElementVNode("p", null, null, -1)),
                          _cache[26] || (_cache[26] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, [
                            _createTextVNode(_toDisplayString(_ctx.$t('25VerpflichtungenZurNutzungenDesServices')), 1),
                            _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
                            _createTextVNode(" " + _toDisplayString(_ctx.$t('alsNutzerDerSaasAnwendungVerpflichtenSieSichFolgen')), 1)
                          ]),
                          _cache[27] || (_cache[27] = _createElementVNode("p", null, null, -1)),
                          _createElementVNode("ul", null, [
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('jeglicheUnbefugteNutzungDerWebsiteEinschlielichDes')), 1),
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('dieWebsiteZuNutzenUmFrWarenUndDienstleistungenZuWe')), 1),
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('einenEinkuferOderEinkaufsagentenBenutzenUmAufDerWe')), 1),
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('sicherheitsrelevanteFunktionenDerWebsiteZuUmgehenZ')), 1),
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('unsUndAndereBenutzerAuszutricksenZuBetrgenOderInDi')), 1),
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('sichAnEinerAutomatisiertenNutzungDesSystemsZuBetei')), 1),
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('lschenSieDenHinweisAufDasUrheberrechtOderAndereEig')), 1),
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('kopierenOderAnpassenDerSoftwareDerWebsiteEinschlie')), 1),
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('sieDrfenDieWebsiteNichtVerkaufenVermietenVerleasen')), 1),
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('esIstIhnenNichtGestattetDieWebsiteZuVerndernDavonA')), 1),
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('sieDrfenNichtAufDieWebsiteZugreifenUmEineHnlicheOd')), 1),
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('sofernNichtAusdrcklichAngegebenDarfKeinTeilDerWebs')), 1)
                          ]),
                          _cache[28] || (_cache[28] = _createElementVNode("p", null, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('jeglicheNutzungDerWebsiteDieGegenDieVorstehendenBe')), 1),
                          _cache[29] || (_cache[29] = _createElementVNode("p", null, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('wirBehaltenUnsDasRechtVorAlleBenutzerinhalteZuBerp')), 1),
                          _cache[30] || (_cache[30] = _createElementVNode("p", null, null, -1)),
                          _cache[31] || (_cache[31] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('27Bewertungen')), 1),
                          _createElementVNode("ul", null, [
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('wirKnnenIhnenAufDerWebsiteBereicheZurVerfgungStell')), 1),
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('dieErstellungDerVertriebDieBertragungDieFfentliche')), 1),
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('sieSindDerUrheberUndEigentmerOderVerfgenBerDieErfo')), 1),
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('sieHabenDieSchriftlicheZustimmungFreigabeUndoderEr')), 1),
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('ihreBeitrgeSindNichtFalschUngenauOderIrrefhrend')), 1),
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('ihreBeitrgeSindKeineUnaufgeforderteOderUnerlaubteW')), 1),
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('ihreBeitrgeSindNichtObsznUnzchtigLaszivSchmutzigGe')), 1),
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('ihreBeitrgeMachenNiemandenLcherlichVerhhnenVerungl')), 1),
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('ihreBeitrgeWerdenNichtVerwendetUmEineAnderePersonZ')), 1)
                          ]),
                          _cache[32] || (_cache[32] = _createElementVNode("p", null, null, -1)),
                          _createElementVNode("p", null, [
                            _createTextVNode(_toDisplayString(_ctx.$t('wirKnnenNachEigenemErmessenBewertungenAnnehmenAble')), 1),
                            _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
                            _createTextVNode(" " + _toDisplayString(_ctx.$t('alsTeilDerFunktionalittDerWebsiteKnnenSieIhrKontoM')), 1)
                          ]),
                          _createElementVNode("ul", null, [
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('dieErstellungDerVertriebDieBertragungDieFfentliche')), 1),
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('sieSindDerUrheberUndEigentmerOderVerfgenBerDieErfo')), 1)
                          ]),
                          _cache[33] || (_cache[33] = _createElementVNode("p", null, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('indemSieUnsZugangZuKontenVonDrittanbieternGewhrenV')), 1),
                          _createElementVNode("ul", null, [
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('dieErstellungDerVertriebDieBertragungDieFfentliche')), 1),
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('sieSindDerUrheberUndEigentmerOderVerfgenBerDieErfo')), 1)
                          ]),
                          _cache[34] || (_cache[34] = _createElementVNode("p", null, null, -1)),
                          _cache[35] || (_cache[35] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('28DrittanbieterKonten')), 1),
                          _createElementVNode("p", null, [
                            _createTextVNode(_toDisplayString(_ctx.$t('jeNachDenVonIhnenGewhltenDrittanbieterkontenUndVor')), 1),
                            _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
                            _createTextVNode(" " + _toDisplayString(_ctx.$t('sieErkennenAnUndErklrenSichDamitEinverstandenDassA')), 1)
                          ]),
                          _cache[36] || (_cache[36] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('29ElektronischeKommunikationTransaktionenUndUnters')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('derBesuchDerWebsiteDasSendenVonEmailsAnUnsUndDasAu')), 1),
                          _cache[37] || (_cache[37] = _createElementVNode("p", null, null, -1)),
                          _cache[38] || (_cache[38] = _createElementVNode("br", null, null, -1)),
                          _createElementVNode("ol", null, [
                            _createElementVNode("li", null, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.$t('3datenschutzUndSicherheit')), 1)
                            ])
                          ]),
                          _cache[39] || (_cache[39] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('31Datenschutz')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('wirNehmenDenSchutzIhrerPersnlichenDatenErnstUndVer')), 1),
                          _cache[40] || (_cache[40] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('32Sicherheitsmanahmen')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('wirErgreifenAngemesseneTechnischeUndOrganisatorisc')), 1),
                          _cache[41] || (_cache[41] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('33Datenverarbeitung')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('wirNehmenDenSchutzIhrerDatenSehrErnstAlleVonIhnenZ')), 1),
                          _cache[42] || (_cache[42] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('34Datenspeicherung')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('wirSpeichernBestimmteDatenDieSieAnDieWebsiteBertra')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('kalifornischeBenutzerUndEinwohner')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('wennEineBeschwerdeBeiUnsNichtZufriedenstellendGels')), 1),
                          _cache[43] || (_cache[43] = _createElementVNode("p", null, null, -1)),
                          _cache[44] || (_cache[44] = _createElementVNode("p", null, null, -1)),
                          _cache[45] || (_cache[45] = _createElementVNode("br", null, null, -1)),
                          _createElementVNode("ol", null, [
                            _createElementVNode("li", null, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.$t('4nderungenBeendigungOderUnterbrechungenDerVerfgbar')), 1)
                            ])
                          ]),
                          _cache[46] || (_cache[46] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('41NderungenDerAgb')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('wirBehaltenUnsDasRechtVorDieseAgbJederzeitNachEige')), 1),
                          _cache[47] || (_cache[47] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('42Beendigung')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('wirBehaltenUnsDasRechtVorIhreNutzungUnsererWebsite')), 1),
                          _cache[48] || (_cache[48] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('43ModifikationenUndUnterbrechungen')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('wirBehaltenUnsDasRechtVorDenInhaltDerWebsiteJederz')), 1),
                          _cache[49] || (_cache[49] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('44VerfgbarkeitUndUnterbrechungen')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('wirKnnenNichtGarantierenDassUnsereWebsiteJederzeit')), 1),
                          _cache[50] || (_cache[50] = _createElementVNode("p", null, null, -1)),
                          _cache[51] || (_cache[51] = _createElementVNode("p", null, null, -1)),
                          _cache[52] || (_cache[52] = _createElementVNode("br", null, null, -1)),
                          _createElementVNode("ol", null, [
                            _createElementVNode("li", null, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.$t('5Creditssystem')), 1)
                            ])
                          ]),
                          _cache[53] || (_cache[53] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('unsereDiensteKnnenEinCreditssystemEnthaltenBeiDemS')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('dieKostenEinesWorkflowsInCreditsKnnenJeNachArtUndU')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('erworbeneCreditsSindNichtBertragbarUndKnnenNichtIn')), 1),
                          _cache[54] || (_cache[54] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('wirBehaltenUnsDasRechtVorDiePreiseUnsererCreditsUn')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('esLiegtInIhrerVerantwortungIhreCreditsZuVerwaltenV')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('wirBehaltenUnsDasRechtVorDasCreditssystemJederzeit')), 1),
                          _cache[55] || (_cache[55] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('51Creditssystem')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('wirBietenIhnenDieMglichkeitEinCreditssystemZuNutze')), 1),
                          _cache[56] || (_cache[56] = _createElementVNode("p", null, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('aAufUnsererWebsiteBietenWirEinCreditssystemAnDasEs')), 1),
                          _cache[57] || (_cache[57] = _createElementVNode("p", null, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('bDiePreiseFrCreditsWerdenAufUnsererWebsiteAngegebe')), 1),
                          _cache[58] || (_cache[58] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('52ZahlungenUndAbrechnungVonCredits')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('aDerKaufVonCreditsErfolgtEntwederBerEinAbonnementO')), 1),
                          _cache[59] || (_cache[59] = _createElementVNode("p", null, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('bBeiAbonnementsErfolgtDieZahlungRegelmigGemDenAnge')), 1),
                          _cache[60] || (_cache[60] = _createElementVNode("p", null, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('cAlleZahlungenWerdenBerSichereZahlungsplattformenA')), 1),
                          _cache[61] || (_cache[61] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('53VerwendungVonCredits')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('aSieKnnenIhreErworbenenCreditsVerwendenUmWorkflows')), 1),
                          _cache[62] || (_cache[62] = _createElementVNode("p", null, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('bCreditsSindNichtBertragbarUndKnnenNichtGegenBarge')), 1),
                          _cache[63] || (_cache[63] = _createElementVNode("p", null, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('cWirBehaltenUnsDasRechtVorDiePreiseFrCreditsUndDie')), 1),
                          _cache[64] || (_cache[64] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('54Creditsgltigkeit')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('gekaufteCreditsHabenEineBegrenzteGltigkeitsdauerWi')), 1),
                          _cache[65] || (_cache[65] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('55NderungenAmCreditssystem')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('wirBehaltenUnsDasRechtVorDasCreditssystemEinschlie')), 1),
                          _cache[66] || (_cache[66] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('56KndigungUndRckerstattung')), 1),
                          _cache[67] || (_cache[67] = _createElementVNode("p", null, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('aSieKnnenIhrAbonnementFrCreditsJederzeitBerIhrBenu')), 1),
                          _cache[68] || (_cache[68] = _createElementVNode("p", null, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('bBeiEinzelkufenVonCreditsIstEineRckerstattungNurUn')), 1),
                          _cache[69] || (_cache[69] = _createElementVNode("p", null, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('cWirBehaltenUnsDasRechtVorIhrCreditskontoZuSperren')), 1),
                          _cache[70] || (_cache[70] = _createElementVNode("p", null, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('creditsKnnenFrDenErwerbVonWorkflowsVerwendetWerden')), 1),
                          _cache[71] || (_cache[71] = _createElementVNode("p", null, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('bitteBeachtenSieDassDasCreditssystemBestimmtenEins')), 1),
                          _cache[72] || (_cache[72] = _createElementVNode("p", null, null, -1)),
                          _cache[73] || (_cache[73] = _createElementVNode("br", null, null, -1)),
                          _createElementVNode("ol", null, [
                            _createElementVNode("li", null, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.$t('6Haftungsbeschrnkungen')), 1)
                            ])
                          ]),
                          _cache[74] || (_cache[74] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('61Haftungsausschluss')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('dieNutzungUnsererWebsiteUndServicesErfolgtAufIhrEi')), 1),
                          _cache[75] || (_cache[75] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('62Haftungsbeschrnkung')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('inKeinemFallHaftenWirIhnenOderDrittenGegenberFrDir')), 1),
                          _cache[76] || (_cache[76] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('63Freistellung')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('sieErklrenSichDamitEinverstandenUnsUnsereGeschftsf')), 1),
                          _cache[77] || (_cache[77] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('64AusnahmenVonDerHaftungsbeschrnkung')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('dieInDenVorstehendenAbstzenGenanntenHaftungsbeschr')), 1),
                          _cache[78] || (_cache[78] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('65Haftungsklauseln')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('sieEntbindenHiermitDasUnternehmenUndUnsereLeitende')), 1),
                          _cache[79] || (_cache[79] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('66WebsitesUndInhalteVonDritten')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('dieWebsiteKannLinksZuAnderenWebsitesWebsitesDritte')), 1),
                          _cache[80] || (_cache[80] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, [
                            _createTextVNode(_toDisplayString(_ctx.$t('67Werbung')), 1),
                            _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
                            _createTextVNode(" " + _toDisplayString(_ctx.$t('wirGestattenWerbetreibendenIhreWerbungUndAndereInf')), 1),
                            _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
                            _createTextVNode(" " + _toDisplayString(_ctx.$t('wirGestattenWerbetreibendenIhreWerbungUndAndereInf')), 1),
                            _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
                            _createTextVNode(" " + _toDisplayString(_ctx.$t('beiUnserenDienstleistungenHandeltEsSichUmCommercia')), 1)
                          ]),
                          _cache[81] || (_cache[81] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('68FehlerhafteOderUnerwarteteWorkflowausfhrung')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('wirBernehmenKeineHaftungFrProblemeDieSichAusDerNut')), 1),
                          _cache[82] || (_cache[82] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('69Entschdigung')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('sieErklrenSichDamitEinverstandenUnsZuVerteidigenZu')), 1),
                          _createElementVNode("ul", null, [
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('ihreBeitrge')), 1),
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('derNutzungDerWebsite')), 1),
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('verstoGegenDieseNutzungsbedingungen')), 1),
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('jeglicheVerletzungIhrerZusicherungenUndGewhrleistu')), 1),
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('ihreVerletzungDerRechteDritterEinschlielichAberNic')), 1),
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('jedeOffenkundigSchdigendeHandlungGegenberEinemAnde')), 1)
                          ]),
                          _cache[83] || (_cache[83] = _createElementVNode("p", null, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('ungeachtetDesVorstehendenBehaltenWirUnsDasRechtVor')), 1),
                          _cache[84] || (_cache[84] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('610Disclaimer')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('dieSeiteWirdAufEinerAsisUndAsavailableBasisBereitg')), 1),
                          _createElementVNode("ul", null, [
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('terms.DisclaimerListItem1')), 1),
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('personenOderSachschdenJeglicherArtDieSichAusIhremZ')), 1),
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('jeglicherUnbefugterZugriffAufOderDieNutzungUnserer')), 1),
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('jeglicheUnterbrechungOderBeendigungDerBertragungZu')), 1),
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('jeglicheBugsVirenTrojanischePferdeOderHnlichesDieV')), 1),
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('frJeglicheFehlerOderAuslassungenInInhaltenUndMater')), 1)
                          ]),
                          _cache[85] || (_cache[85] = _createElementVNode("p", null, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('wirBernehmenKeineGewhrleistungUntersttzungGarantie')), 1),
                          _cache[86] || (_cache[86] = _createElementVNode("p", null, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('ungeachtetDesVorstehendenBehaltenWirUnsDasRechtVor')), 1),
                          _cache[87] || (_cache[87] = _createElementVNode("p", null, null, -1)),
                          _cache[88] || (_cache[88] = _createElementVNode("br", null, null, -1)),
                          _createElementVNode("ol", null, [
                            _createElementVNode("li", null, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.$t('7ZahlungenUndAbrechnung')), 1)
                            ])
                          ]),
                          _cache[89] || (_cache[89] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('esKannSeinDassSieFrDenZugriffAufEinigeUnsererDiens')), 1),
                          _cache[90] || (_cache[90] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('aDerKaufVonCreditsErfolgtEntwederBerEinAbonnementO')), 1),
                          _cache[91] || (_cache[91] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('bBeiAbonnementsErfolgtDieZahlungRegelmigGemDenAnge')), 1),
                          _cache[92] || (_cache[92] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('cAlleZahlungenWerdenBerSichereZahlungsplattformenA')), 1),
                          _cache[93] || (_cache[93] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('dSieStimmenZuDassWirIhreZahlungsinformationenErfas')), 1),
                          _cache[94] || (_cache[94] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('eAbonnementsVerlngernSichAutomatischZuDenJeweilsAn')), 1),
                          _cache[95] || (_cache[95] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('zurBezahlungWerdenServicesVonStripeVerwendetEsGelt')), 1),
                          _cache[96] || (_cache[96] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('wirAkzeptierenUnterAnderemFolgendenZahlungsarten')), 1),
                          _createElementVNode("ul", null, [
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('terms.IntellectualList2Item1')), 1),
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('terms.IntellectualList2Item2')), 1),
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('terms.IntellectualList2Item3')), 1),
                            _createElementVNode("li", null, _toDisplayString(_ctx.$t('terms.IntellectualList2Item4')), 1)
                          ]),
                          _cache[97] || (_cache[97] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('sieErklrenSichDamitEinverstandenAlleKostenOderGebh')), 1),
                          _cache[98] || (_cache[98] = _createElementVNode("p", null, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('wirBehaltenUnsDasRechtVorFehlerOderIrrtmerBeiDerPr')), 1),
                          _cache[99] || (_cache[99] = _createElementVNode("p", null, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('alleKufeSindNichtErstattungsfhigSieKnnenIhrAbonnem')), 1),
                          _cache[100] || (_cache[100] = _createElementVNode("p", null, null, -1)),
                          _cache[101] || (_cache[101] = _createElementVNode("br", null, null, -1)),
                          _createElementVNode("ol", null, [
                            _createElementVNode("li", null, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.$t('8SonstigeBestimmungen')), 1)
                            ])
                          ]),
                          _cache[102] || (_cache[102] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('81SalvatorischeKlausel')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('sollteEineBestimmungDieserAgbGanzOderTeilweiseUnwi')), 1),
                          _cache[103] || (_cache[103] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('82AnwendbaresRechtUndGerichtsstand')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('dieseAgbUnterliegenDemSterreichischenRechtFrStreit')), 1),
                          _cache[104] || (_cache[104] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('83NderungenDerAgb')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('wirBehaltenUnsDasRechtVorDieseAgbJederzeitUndNachE')), 1),
                          _cache[105] || (_cache[105] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('84GesamteVereinbarung')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('dieseAgbStellenDieGesamteVereinbarungZwischenIhnen')), 1),
                          _cache[106] || (_cache[106] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('85BertragungDerRechteUndPflichten')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('sieDrfenIhreRechteUndPflichtenAusDiesenAgbNichtOhn')), 1),
                          _cache[107] || (_cache[107] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('86KeineVerzichtserklrung')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('dasVersumnisUnsererseitsEinRechtOderEineBestimmung')), 1),
                          _cache[108] || (_cache[108] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('87SprachlicheAuslegung')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('dieseAgbWurdenInMehrerenSprachenBereitgestelltImFa')), 1),
                          _cache[109] || (_cache[109] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('88AnwendbaresRechtUndGerichtsstand')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('dieseAgbUnterliegenDemAmerikanischenRechtBeiStreit')), 1),
                          _cache[110] || (_cache[110] = _createElementVNode("p", null, null, -1)),
                          _cache[111] || (_cache[111] = _createElementVNode("br", null, null, -1)),
                          _createElementVNode("ol", null, [
                            _createElementVNode("li", null, [
                              _createElementVNode("strong", null, _toDisplayString(_ctx.$t('9Kontakt')), 1)
                            ])
                          ]),
                          _cache[112] || (_cache[112] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('wirDankenIhnenFrDieNutzungUnsererWebsiteUndStehenI')), 1),
                          _cache[113] || (_cache[113] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('terms.ContactDetail2')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('serviceGreeve')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('447Broadway2StockPmb963')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('terms.ContactDetail5')), 1),
                          _createElementVNode("p", null, [
                            _cache[7] || (_cache[7] = _createTextVNode("E-Mail: ")),
                            _createElementVNode("a", _hoisted_13, _toDisplayString(_ctx.$t('mailto')), 1)
                          ]),
                          _cache[114] || (_cache[114] = _createElementVNode("p", { class: "pt-3" }, null, -1)),
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('bitteGebenSieBeiKontaktaufnahmeIhreVollstndigenKon')), 1)
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _createVNode(_component_FooterBar)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}