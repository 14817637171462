import { resolveComponent as _resolveComponent, createVNode as _createVNode, vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { "card-padding": "px-6 pb-6 sm:px-9 py-6" }
const _hoisted_2 = { class: "flex items-start px-4 pt-2" }
const _hoisted_3 = { class: "flex h-5 mt-0.5 items-center" }
const _hoisted_4 = { class: "ml-3 text-sm" }
const _hoisted_5 = {
  for: "offers",
  class: "font-medium text-gray-700 dark:text-gray-400 text-xs"
}
const _hoisted_6 = { class: "text-gray-500 text-xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DangerAlert = _resolveComponent("DangerAlert")!
  const _component_input_nick_mail = _resolveComponent("input-nick-mail")!
  const _component_input_password = _resolveComponent("input-password")!
  const _component_DefaultButton = _resolveComponent("DefaultButton")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_DangerAlert, {
        "error-message": _ctx.errorMessage,
        "error-title": _ctx.errorTitle,
        "show-title": true,
        onCloseAlert: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clearErrorMessage()))
      }, null, 8, ["error-message", "error-title"]),
      _createElementVNode("form", {
        class: "space-y-3 -mt-5",
        onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
      }, [
        _createVNode(_component_input_nick_mail, {
          email: _ctx.user.email,
          "onUpdate:email": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.user.email) = $event)),
          "error-message": _ctx.user.emailError,
          "required-field": "true"
        }, null, 8, ["email", "error-message"]),
        _createVNode(_component_input_password, {
          password: _ctx.user.password,
          "onUpdate:password": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.user.password) = $event)),
          "error-message": _ctx.user.passwordError,
          "required-field": "true"
        }, null, 8, ["password", "error-message"]),
        _createVNode(_component_DefaultButton, {
          "button-text": _ctx.$t('auth.RegisterComponent.registerBtnRegister'),
          "button-type": "submit",
          "button-width": "w-full",
          style: {"margin-top":"1.3rem"}
        }, null, 8, ["button-text"]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _withDirectives(_createElementVNode("input", {
              id: "terms",
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.user.terms) = $event)),
              "aria-describedby": "terms-description",
              name: "terms",
              required: "",
              type: "checkbox",
              class: "h-4 w-4 rounded border-gray-200 text-indigo-600"
            }, null, 512), [
              [_vModelCheckbox, _ctx.user.terms]
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("terms.TermsTitle")), 1),
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t("auth.RegisterComponent.dataProcessing")), 1)
          ])
        ]),
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "mt-8" }, [
          _createElementVNode("div", null, [
            _createElementVNode("div", { class: "relative mt-6" })
          ])
        ], -1))
      ], 32)
    ])
  ]))
}