import {CustomerInterface} from "@/greeve/shop/customer/customer.interface";
import {OrderItemList} from "@/greeve/shop/order/orderItem/order_item_list.type";
import {AddressInterface} from "@/greeve/user/user.interface";
import {PaymentItemList} from "@/greeve/shop/order/paymentItem/payment_item_list.type";
import {JournalList} from "@/greeve/shop/order/journal/journal_list.type";

export const enum OrderType {
	ABO = 'abo',
	INQUIRY = 'inquiry',
	ORDER = 'order',
}

export const enum OrderState {
	NEW = 'new',
	IN_PROGRESS = 'in_progress',
	PENDING = 'pending',
	DONE = 'done',
	ERROR = 'error',
}

export interface OrderInterface {
	id: number,
	uuid: string,
	type: OrderType,
	state: OrderState,
	order_items?: OrderItemList|null,
	payment_items?: PaymentItemList|null,
	journals?: JournalList|null,
	user_address?: AddressInterface|null,
	customer?: CustomerInterface|null,
	tax_code: string,
	country_code: string,
	currency_code: string,
	order_number: string,
	transaction?: string|null,
	reference?: string|null,
	external_reference?: string|null,
	net_amount: number,
	total_amount: number,
	tax_amount: number,
	item_quantity: number,
	grand_total: number,
	exchange_rate: number,
	data?: any,
	created_at?: Date,
	updated_at?: Date,
}