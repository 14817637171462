import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "pointer-events-none fixed inset-x-0 bottom-[7.9em] sm:bottom-[8.9em] md:bottom-[4.4em] lg:bottom-0 px-2 sm:px-4 lg:px-5 pb-6 lg:pb-5"
}
const _hoisted_2 = {
  id: "cookiesComponent",
  "data-aos": "fade-up",
  "data-aos-anchor": "#cookiesComponent",
  class: "pointer-events-auto ml-auto max-w-xl rounded-xl overflow-hidden nav backdrop-filter backdrop-blur-xl shadow-lg"
}
const _hoisted_3 = { class: "nav backdrop-filter backdrop-blur-xl p-5" }
const _hoisted_4 = { class: "float-right mt-0.5 mr-5" }
const _hoisted_5 = { class: "text-sm leading-6 text-gray-900 dark:text-gray-400" }
const _hoisted_6 = { class: "text-base font-medium leading-6 mb-2" }
const _hoisted_7 = { class: "text-sm h-40 overflow-scroll leading-6 text-gray-900 dark:text-gray-400" }
const _hoisted_8 = { class: "mt-4 flex items-center gap-x-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_XMarkIcon = _resolveComponent("XMarkIcon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (!_ctx.hasCookiesAccepted)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.showButton === true)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDialog && _ctx.toggleDialog(...args)))
            }))
          : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_XMarkIcon, {
                "aria-hidden": "true",
                class: "w-4 absolute z-20 text-gray-400 cursor-pointer",
                onClick: _ctx.cancelDialog
              }, null, 8, ["onClick"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.t("modal.CookiesDialog.CookiesText")), 1),
              _createElementVNode("p", _hoisted_7, [
                _createTextVNode(_toDisplayString(_ctx.$t('weUseCookiesToPersonalizeContentAndAdsProvideSocia')) + " ", 1),
                _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
                _createTextVNode(_toDisplayString(_ctx.$t('furthermoreProcessingAndUseOfCookiesByThirdpartyPr')) + " ", 1),
                _createVNode(_component_router_link, {
                  to: "/cookies",
                  class: "font-bold"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("modal.CookiesDialog.CookiesTitle")), 1)
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("button", {
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.confirm(true))),
                  type: "button",
                  class: "rounded-xl bg-gr-darkest dark:bg-gr-dark px-6 py-2 text-sm font-extrabold text-white shadow-sm"
                }, _toDisplayString(_ctx.t("modal.CookiesDialog.CookiesAccept")), 1),
                _createElementVNode("button", {
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.confirm(false))),
                  type: "button",
                  class: "text-sm font-semibold leading-6 text-gray-900 dark:text-gray-400"
                }, _toDisplayString(_ctx.t("modal.CookiesDialog.CookiesReject")), 1)
              ])
            ])
          ])
        ], 512), [
          [_vShow, _ctx.dialogVisible]
        ])
      ]))
    : _createCommentVNode("", true)
}