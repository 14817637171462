/* eslint-disable @typescript-eslint/no-unused-vars */

const auth = {
	namespaced: true,
	state: () => ({
		authenticating: false,
		accessToken: "",
		refreshToken: "",
		authenticationErrorCode: 0,
		authenticationError: "",
		refreshTokenPromise: null,
		tokenLifeTime: 0,
		loading: false,
	}),
	mutations: {
		token(state: any, token: string) {
			state.accessToken = token;
		},
		refreshToken(state: any, refreshToken: string) {
			state.refreshToken = refreshToken;
		},
		authenticating(state: any, authenticated: boolean) {
			state.authenticating = authenticated;
		},
		signOutRequest(state: any) {
			state.authenticating = false;
		},
		tokenLifeTime(state: any, tokenLifeTime: number) {
			state.tokenLifeTime = tokenLifeTime;
		},
	},
	actions: {},
	getters: {
		authenticationErrorCode: (state: { authenticationErrorCode: any }) => {
			return state.authenticationErrorCode;
		},

		authenticationError: (state: { authenticationError: any }) => {
			return state.authenticationError;
		},

		authenticating: (state: { authenticating: any }) => {
			return state.authenticating;
		},

		getLifeTime: (state: any) => {
			return state.tokenLifeTime;
		},

		getToken: (state: any) => {
			return state.accessToken;
		},

		getRefreshToken: (state: any) => {
			return state.refreshToken;
		},

		isTokenLifeTimeExpired: (state: any) => {
			const currentTimeStampInSeconds = Math.floor(Date.now() / 1000);
			return state.tokenLifeTime < currentTimeStampInSeconds;
		},

		isAuthenticated: (state: any, getters: any) => {
			return state.authenticating && !getters.isTokenLifeTimeExpired;
		},
	}
}

export default auth;