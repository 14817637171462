import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Transition as _Transition, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "py-1" }
const _hoisted_2 = {
  key: 0,
  role: "list",
  class: "grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8 pt-4"
}
const _hoisted_3 = { class: "flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { class: "text-sm font-medium leading-6 text-gray-900" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "sr-only" }
const _hoisted_8 = { class: "-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6" }
const _hoisted_9 = { class: "flex justify-between gap-x-4 py-3" }
const _hoisted_10 = { class: "text-gray-500" }
const _hoisted_11 = {
  key: 0,
  class: "text-gray-700"
}
const _hoisted_12 = ["datetime"]
const _hoisted_13 = { class: "flex justify-between gap-x-4 py-3" }
const _hoisted_14 = { class: "text-gray-500" }
const _hoisted_15 = { class: "flex items-start gap-x-2" }
const _hoisted_16 = { class: "font-medium text-gray-900 dark:text-gray-200" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlusIcon = _resolveComponent("PlusIcon")!
  const _component_MenuButton = _resolveComponent("MenuButton")!
  const _component_MetaOAuthComponent = _resolveComponent("MetaOAuthComponent")!
  const _component_MenuItem = _resolveComponent("MenuItem")!
  const _component_GoogleLoginComponent = _resolveComponent("GoogleLoginComponent")!
  const _component_MenuItems = _resolveComponent("MenuItems")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_EllipsisHorizontalIcon = _resolveComponent("EllipsisHorizontalIcon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Menu, {
      as: "div",
      class: "relative inline-block w-full"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createVNode(_component_MenuButton, { class: "inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('oauth.add')) + " ", 1),
              _createVNode(_component_PlusIcon, {
                class: "-mr-1 h-5 w-5 text-gray-400",
                "aria-hidden": "true"
              })
            ]),
            _: 1
          })
        ]),
        _createVNode(_Transition, {
          "enter-active-class": "transition ease-out duration-100",
          "enter-from-class": "transform opacity-0 scale-95",
          "enter-to-class": "transform opacity-100 scale-100",
          "leave-active-class": "transition ease-in duration-75",
          "leave-from-class": "transform opacity-100 scale-100",
          "leave-to-class": "transform opacity-0 scale-95"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_MenuItems, { class: "absolute justify-center z-10 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none w-full" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_MenuItem, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_MetaOAuthComponent, {
                        key: "fb-business",
                        business: true,
                        "o-auth-type": _ctx.OAuthType.OAUTH_TYPE_META
                      }, null, 8, ["o-auth-type"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_MenuItem, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_MetaOAuthComponent, {
                        key: "ig-business",
                        business: true,
                        "o-auth-type": _ctx.OAuthType.OAUTH_TYPE_META_INSTAGRAM
                      }, null, 8, ["o-auth-type"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_MenuItem, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_MetaOAuthComponent, {
                        key: "wa-business",
                        business: true,
                        "o-auth-type": _ctx.OAuthType.OAUTH_TYPE_META_WHATSAPP
                      }, null, 8, ["o-auth-type"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_MenuItem, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_GoogleLoginComponent, { key: "go-login" })
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.getOAuths && _ctx.getOAuths.length > 0)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getOAuths, (oauth) => {
            return (_openBlock(), _createElementBlock("li", {
              key: oauth.id,
              class: "overflow-hidden rounded-xl border border-gray-200"
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("img", {
                  src: oauth.getImageUrl(),
                  alt: oauth.getName(),
                  class: "h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
                }, null, 8, _hoisted_4),
                _createElementVNode("div", _hoisted_5, _toDisplayString(oauth.getName()), 1),
                _createVNode(_component_Menu, {
                  as: "div",
                  class: "relative ml-auto"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_MenuButton, { class: "-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_EllipsisHorizontalIcon, {
                          class: "h-5 w-5",
                          "aria-hidden": "true"
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_Transition, {
                      "enter-active-class": "transition ease-out duration-100",
                      "enter-from-class": "transform opacity-0 scale-95",
                      "enter-to-class": "transform opacity-100 scale-100",
                      "leave-active-class": "transition ease-in duration-75",
                      "leave-from-class": "transform opacity-100 scale-100",
                      "leave-to-class": "transform opacity-0 scale-95"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_MenuItems, { class: "absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_MenuItem, null, {
                              default: _withCtx(({ active }) => [
                                _createElementVNode("a", {
                                  onClick: ($event: any) => (_ctx.deleteOAuthToProfile(oauth.uuid)),
                                  class: _normalizeClass([active ? 'bg-gray-50' : '', 'block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer'])
                                }, [
                                  _createTextVNode(_toDisplayString(_ctx.$t('oauth.delete')), 1),
                                  _createElementVNode("span", _hoisted_7, ", " + _toDisplayString(oauth.getName()), 1)
                                ], 10, _hoisted_6)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1024)
              ]),
              _createElementVNode("dl", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("dt", _hoisted_10, _toDisplayString(_ctx.$t('oauth.expiry_date')), 1),
                  (oauth.expiry_date)
                    ? (_openBlock(), _createElementBlock("dd", _hoisted_11, [
                        _createElementVNode("time", {
                          datetime: oauth.expiry_date
                        }, _toDisplayString(oauth.getExpiryDateFormatted()), 9, _hoisted_12)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_13, [
                  _createElementVNode("dt", _hoisted_14, _toDisplayString(_ctx.$t('oauth.permissions')), 1),
                  _createElementVNode("dd", _hoisted_15, [
                    _createElementVNode("div", _hoisted_16, _toDisplayString(oauth.getScopesFormatted()), 1)
                  ])
                ])
              ])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}