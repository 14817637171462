<template>
	<ion-page>
		<ion-content>
			<main :style="{ paddingTop: headerHeight + 'px' }" >
			<div>
<!--				<div class="fixed top-0 left-0 hidden h-full w-1/2 bg-white dark:bg-gr-darkest lg:block" aria-hidden="true" />-->
<!--				<div class="fixed top-0 right-0 hidden h-full w-1/2 bg-gray-100 dark:bg-gr-darkester lg:block" aria-hidden="true" />-->
				<greeve-shop-checkout class="mx-auto max-w-7xl pb-12"></greeve-shop-checkout>
			</div>
			<FooterBar class="mt-12"></FooterBar>
			</main>
		</ion-content>
	</ion-page>
</template>

<script lang="ts">
import {defineComponent, onMounted} from 'vue';
import {IonPage, IonContent} from '@ionic/vue';
import FooterBar from "@/components/positions/FooterBar.vue";
import useSystem from "@/composable/core/useSystem";
import GreeveShopCheckout from "@/components/shop/checkout/GreeveShopCheckout.vue";
import {useHeaderHeight} from "@/composable/global/useHeaderHeight";

export default defineComponent({
	name: 'ShopPage',
	components: {
		GreeveShopCheckout,
		FooterBar, IonContent, IonPage
	},
	setup() {
		const { headerHeight } = useHeaderHeight();
		const {scrollToTop} = useSystem();

		onMounted(() => {
			scrollToTop();
		})

		return { headerHeight
		}
	}
});

</script>
<style lang="scss">
</style>
