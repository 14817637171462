<template>
	<ion-page>
		<ion-content>
			<main  :style="{ paddingTop: headerHeight + 'px' }" >
			<!--Animation & Ad-->
			<section id="GreeveAd">
				<div class="p-4 mt-12">
					<div class="max-w-4xl mb-7 mx-auto font-comfortaa text-stroke text-4xl sm:text-7xl font-bold">
						<span class="">{{ $t('theIntro') }} </span><span class="text-gr-primary">{{
							$t('ai')
						}}</span>
					</div>
					<div class="box max-w-7xl mx-auto">
						<video ref="videoRefGreeveAd2" class="w-full h-full object-cover rounded-xl object-center" playsinline autoplay muted loop
							 poster="https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/videos/greeve_ad_thumbnail.png"
							   controls src="https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/videos/greeve_ad.mp4"/>
					</div>
				</div>
			</section>

				<!--Collection 2-->
				<div class="block md:hidden relative">
					<div>
						<section id="GreeveCollection" class="mt-24 mb-56">
							<div class="max-w-7xl mx-8 lg:mx-auto">
								<div class="max-w-4xl mb-12 mx-auto font-comfortaa text-stroke text-4xl sm:text-7xl font-bold">
									<span class="">{{ $t('theUltimate') }} </span><span class="text-gr-primary">{{ $t('ai') }}</span> {{ $t('collection') }}
								</div>
								<BackgroundCarousel></BackgroundCarousel>

							</div>
						</section>
					</div>
				</div>
				<section id="GreeveCollection" class="hidden md:block mt-24 mb-56">
					<div class="max-w-7xl mx-8 lg:mx-auto">
						<div class="max-w-4xl mb-12 mx-auto font-comfortaa text-stroke text-4xl sm:text-7xl font-bold">
							<span class="">{{ $t('theUltimate') }} </span><span class="text-gr-primary">{{
								$t('ai')
							}}</span> {{ $t('collection') }}
						</div>

						<div style="-webkit-transform: translate3d(0px, 0px, 0px);">
							<div class="text-white box max-w-7xl mx-auto">
								<figure class="relative h-[25rem] sm:h-[30rem] md:h-[40rem] lg:h-[50rem] mx-4 overflow-hidden">
									<img src="https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/images/landing/collection-v2.png"
										 class="logo absolute w-full max-h-[40em]">
								</figure>
							</div>
						</div>
					</div>
				</section>

			<!--Flow TODO Activate it again when Workflows are live-->
			<section id="GreeveFlows">
				<div class="max-w-7xl mx-8  -mt-12 lg:mx-auto">
					<div class="max-w-4xl mx-auto font-comfortaa text-stroke text-4xl sm:text-7xl font-bold">
						<span class="">{{ $t('preBuildWork') }} </span><span class="text-gr-primary">{{
												$t('flows')
						}}</span>
					</div>
				</div>
<!--				TODO add Origin Templates-->
				<div class="mx-4">
				<div class="p-0 max-w-6xl lg:mx-auto md:mt-12 mb-12">
					<TemplateGrid background="nav hover:bg-gray-50 dark:hover:bg-gr-darker"></TemplateGrid>
				</div>
				</div>
			</section>

			<!--Advantages-->
			<section id="GreeveAdvantages" class="mt-24 mb-56">
				<div class="max-w-7xl text-center mx-auto">
				</div>
				<div class="max-w-7xl p-4 font-semibold space-y-16 mx-auto grid lg:grid-cols-2">
					<div class="order-1 p-4 text-4xl self-center"><p class="font-comfortaa mb-1">{{
												$t('createAiWorkflowsForYourCompany')
						}}</p>
						<p class="pt-4 text-2xl font-medium">{{
														$t('letAiDoYourWorkSoYouCanFocusOnImportantThingsOfYou')
							}}</p>
					</div>
					<div class="order-2 shadow-3xl w-full overflow-hidden text-white">
						<video ref="videoRefTalk" class="rounded-2xl" autoplay playsinline muted loop
									 poster="https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/videos/talk_thumbnail.png"
									 src="https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/videos/talk.mp4"/>
					</div>
					<div class="order-5 lg:order-3 shadow-3xl w-full overflow-hidden text-white">
						<video ref="videoRefPresentation" class="rounded-2xl" autoplay playsinline muted loop
									 poster="https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/videos/presentation_thumbnail.png"
									 src="https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/videos/presentation.mp4"/>
					</div>
					<div class="order-4 p-4 text-4xl self-center lg:ml-4">
						<p class="font-comfortaa mb-1">{{ $t('preBuildWorkflows') }}</p>
						<p class="pt-4 text-2xl font-medium">{{
														$t('youCanChooseFromAllreadyBuildUpFlowsAndIntegrateIt')
							}}</p>
					</div>
					<div class="order-5 p-4 text-4xl self-center ">
						<p class="font-comfortaa mb-1">{{ $t('monetizeYourCreations') }}</p>
						<p class="pt-4 text-2xl font-medium">{{
														$t('wellYouAlsoCanBuildUpYourOwnBusinessBasedOnCreatin')
							}}</p>
					</div>
					<div class="order-6 shadow-3xl w-full overflow-hidden text-white">
						<video ref="videoRefWoman" class="rounded-2xl" autoplay playsinline muted loop
									 poster="https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/videos/woman_thumbnail.png"
									 src="https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/videos/woman.mp4"/>
					</div>
					<div class="order-9 lg:order-7 shadow-3xl w-full overflow-hidden text-white">
						<video ref="videoRefSphere" class="rounded-2xl" autoplay playsinline muted loop
									 poster="https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/videos/sphere_thumbnail.png"
									 src="https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/videos/sphere.mp4"/>
					</div>
					<div class="order-8 p-4 text-4xl self-center lg:ml-4">
						<p class="font-comfortaa mb-1">{{ $t('weMakeYourCompanyAiReady') }}</p>
						<p class="pt-4 text-2xl font-medium">{{
														$t('weAlsoProvideAFullCustomIntegrationOfOurServicesAn')
							}}</p>
					</div>

				</div>
			</section>

			<!--Sign Up Button-->
			<section id="GreeveSignUp" class="mt-24 mb-56">
				<div class="box max-w-7xl pt-20 pb-28 text-center mx-auto">
					<div class="font-comfortaa text-stroke text-4xl sm:text-7xl font-bold mb-12">
						<span class="">{{ $t('startToday') }}</span><span class="text-gr-primary"></span>
					</div>
					<router-link to="/pricing">
						<button class="bg-black dark:bg-white font-bold text-3xl px-7 py-2 rounded-3xl mr-2 text-white dark:text-black">
							{{ $t('becomePro') }}
						</button>
					</router-link>
					<p class="pt-2 text-sm font-bold">{{ $t('get5CreditsForFree') }}</p>
				</div>
			</section>

			<!--FAQ-->
			<section id="GreeveFaQ" class="mt-24 mb-56">
				<div class="max-w-7xl text-center mx-4 lg:mx-auto">
					<faq-section class="bg-gray-200 dark:bg-gr-dark rounded-xl p-12"></faq-section>
				</div>
			</section>

			<!--Company-->
			<section id="GreeveCompany" class="mt-24 mb-56">
				<div class="max-w-4xl mx-8 lg:mx-auto">
					<!--				<div>-->
					<!--					<img class="h-20 w-20 relative" src="assets/video/tc_logo.png">-->
					<!--				</div>-->
					<div class="font-comfortaa text-stroke text-4xl sm:text-7xl font-bold">
						<span class="text-gr-primary">{{ $t('powered') }} </span> {{
												$t('byTheconceptTechnologiesLlc')
						}}
					</div>

					<div class="mx-auto text-xl">
						<p class="mt-6">
							{{ $t('vision.Article1p1') }}
						</p>
						<p class="pt-4 font-semibold">{{ $t('company.Article1p2') }}</p>
						<li>{{ $t('company.Article1li1') }}</li>
						<li>{{ $t('company.Article1li2') }}</li>
						<li>{{ $t('company.Article1li3') }}</li>
						<p class="pt-4">{{ $t('company.Article1p5') }}</p>
					</div>
					<a href="https://www.theconcept-technologies.com">
						<button class="bg-black dark:bg-white text-2xl mt-6 px-7 py-2 rounded-3xl mr-2 text-white dark:text-black">
							{{ $t('moreAboutUs') }}
						</button>
					</a>
				</div>
			</section>

			<!--Footer-->
			<FooterBar class="mt-12"></FooterBar>
			</main>
		</ion-content>
	</ion-page>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from 'vue';
import {IonContent, IonPage} from '@ionic/vue';
import FooterBar from '@/components/positions/FooterBar.vue';
import FaqSection from '@/components/sections/FaqSection.vue';
import { onBeforeRouteLeave } from 'vue-router'
import BackgroundCarousel from "@/components/animations/BackgroundCarousel.vue";
import {useHeaderHeight} from "@/composable/global/useHeaderHeight";
import TemplateGrid from "@/components/workflow/template/TemplateGrid.vue";

export default defineComponent({
	name: 'ShopProducts',
	components: {
		TemplateGrid,
		BackgroundCarousel,
		FaqSection, FooterBar, IonContent, IonPage,
	},
	setup() {
		const { headerHeight } = useHeaderHeight();
		const videoRefGreeveAd2: HTMLVideoElement | any = ref(null)
		const videoRefTalk: HTMLVideoElement | any = ref(null)
		const videoRefPresentation: HTMLVideoElement | any = ref(null)
		const videoRefSphere: HTMLVideoElement | any = ref(null)
		const videoRefWoman: HTMLVideoElement | any = ref(null)

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const playVideos = () => {
			videoRefGreeveAd2.value.play();
			videoRefTalk.value.play();
			videoRefPresentation.value.play();
			videoRefSphere.value.play();
			videoRefWoman.value.play();
		}

		const stopVideos = () => {
			// video.currentTime = 0
			videoRefGreeveAd2.value.pause();
			videoRefTalk.value.pause();
			videoRefPresentation.value.pause();
			videoRefSphere.value.pause();
			videoRefWoman.value.pause();
		}

		onBeforeRouteLeave(() => {
			stopVideos()
		})

		onMounted(() => {
			//
		})

		return {
			videoRefGreeveAd2,
			videoRefTalk,
			videoRefPresentation,
			videoRefSphere,
			videoRefWoman, headerHeight
		};
	},
});

</script>
<style lang="scss">

.text-stroke {
  stroke-width: 1px;
  -webkit-text-stroke: 1px currentColor;
}

.text-gradient {
  background: linear-gradient(90deg, rgba(246, 0, 0, 1) 0%, rgba(255, 211, 0, 1) 34.31%, rgba(255, 118, 0, 1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.box {
  /* Required properties */
  position: relative;
}

.box:before {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(90deg, rgba(255, 211, 0, 1) 0%, rgba(255, 118, 0, 1) 34.31%, rgba(246, 0, 0, 1) 100%);
  transform: translate3d(0px, 5px, 0px) scale(0.95);
  -webkit-transform: translate3d(0px, 5px, 0px) scale(0.95);
  filter: blur(100px);
  transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
  border-radius: inherit;
}

/*
* Prevents issues when the parent creates a
* stacking context. (For example, using the transform
* property )
*/
.box::after {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

@keyframes showTopText {
  0% {
    transform: translate3d(0, 100%, 0);
  }
  40%, 60% {
    transform: translate3d(0, 50%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes showBottomText {
  0% {
    transform: translate3d(0, -100%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

.animated-title {
  height: 100vmin;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90vmin;
}

.animated-title > div {
  height: 50%;
  overflow: hidden;
  position: absolute;
  width: 100%;
}

.animated-title > div div {
  padding: 2vmin 0;
  position: absolute;
}

.animated-title > div div span {
  display: flex;
}

.animated-title > div.text-top {
  border-bottom: 1vmin solid #000000;
  top: 0;
}

@media (prefers-color-scheme: dark) {
  .animated-title > div.text-top {
    border-bottom: 1vmin solid #ffffff;
    top: 0;
  }
}

.animated-title > div.text-top div {
  animation: showTopText 1s;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
  bottom: 0;
  transform: translate(0, 100%);
  -webkit-transform: translate(0, 100%);

}

.animated-title > div.text-bottom {
  bottom: 0;
  position: relative;
}

.animated-title > div.text-bottom div {
  animation: showBottomText 0.5s;
  animation-delay: 1.2s;
  animation-fill-mode: forwards;
  top: 0;
  transform: translate(0, -100%);
  -webkit-transform: translate(0, -100%);

}

.background-content {
  --background: black !important;
}

.logo {
  width: auto !important;
  left: 50%;
  top: 9%;
  transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
}

.carousel-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.animate-carousel1 {
  position: absolute;
  top: 0;
  left: 0;
  animation: carousel-animation1 10s linear infinite;
}

.animate-carousel2 {
  position: absolute;
  top: 0;
  left: 0;
  animation: carousel-animation2 15s linear infinite;
}

@keyframes carousel-animation1 {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes carousel-animation2 {
  0% {
    transform: translateX(200%);
  }
  100% {
    transform: translateX(-200%);
  }
}

</style>
