import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "mx-2 sm:mx-4 relative justify-between items-center transition-all duration-200" }
const _hoisted_3 = { class: "mx-auto max-w-7xl pb-12" }
const _hoisted_4 = { class: "dark:bg-gr-dark bg-gray-50 rounded-xl grid grid-cols-1 gap-x-8 lg:grid-cols-2 px-4 pt-0 md:pt-4 lg:px-8 pb-4 lg:py-8 xl:gap-x-8" }
const _hoisted_5 = { class: "dark:bg-gr-darkest bg-gray-200 rounded-xl" }
const _hoisted_6 = { class: "col-start-1 row-start-1 dark:lg:border-gr-darkest lg:border-gray-200 lg:p-8 lg:border-2 rounded-xl" }
const _hoisted_7 = { class: "justify-center items-center mt-6 w-full sm:m-auto" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CheckoutNavBar = _resolveComponent("CheckoutNavBar")!
  const _component_OrderInformation = _resolveComponent("OrderInformation")!
  const _component_DangerAlert = _resolveComponent("DangerAlert")!
  const _component_LoginRegisterProcess = _resolveComponent("LoginRegisterProcess")!
  const _component_ContactInformationProcess = _resolveComponent("ContactInformationProcess")!
  const _component_OrderConfirmProcess = _resolveComponent("OrderConfirmProcess")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createVNode(_component_CheckoutNavBar, {
      steps: _ctx.checkoutSteps.steps,
      "current-step": _ctx.activeStepId,
      onUpdateStep: _ctx.changeStep
    }, null, 8, ["steps", "current-step", "onUpdateStep"]), [
      [_vShow, _ctx.showHeader]
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_OrderInformation)
          ]),
          _createElementVNode("form", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_DangerAlert, {
                "error-message": _ctx.errorMessage,
                "error-title": _ctx.errorTitle,
                "alert-padding": "pt-1 pb-2",
                "show-title": true
              }, null, 8, ["error-message", "error-title"]),
              (_ctx.activeStepId === 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createVNode(_component_LoginRegisterProcess, {
                      onOnSuccess: _ctx.goToNextStep,
                      onOnError: _ctx.showError
                    }, null, 8, ["onOnSuccess", "onOnError"])
                  ]))
                : (_ctx.activeStepId === 2 && _ctx.isAuthenticated)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createVNode(_component_ContactInformationProcess, {
                        onOnSuccess: _ctx.goToNextStep,
                        onOnError: _ctx.showError
                      }, null, 8, ["onOnSuccess", "onOnError"])
                    ]))
                  : (_ctx.activeStepId === 3 && _ctx.isAuthenticated)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                        _createVNode(_component_OrderConfirmProcess)
                      ]))
                    : _createCommentVNode("", true)
            ])
          ])
        ])
      ])
    ])
  ]))
}