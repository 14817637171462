import {computed, ComputedRef, onBeforeMount, ref} from "vue";
import store from "@/store";
import {CountryList} from "@/greeve/core/country/country_list.type";
import {GreeveApiSystem} from "@/greeve/api/system";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {AxiosError} from "axios";
import useCustomStore from "@/composable/custom/useCustomStore";
import {Country} from "@/greeve/core/country/country.type";

export default function useCountry() {
	const countries: ComputedRef<CountryList|undefined> = computed(() => store.getters['country/getCountries']);
	const isLoading = ref(false);
	const {getLanguageLocale} = useCustomStore();

	function getCountry_by_Code(countryCode: string): Country|undefined {
		return countries.value?.getItemByCode(countryCode);
	}

	function getDefaultCountry_by_Locale(): string
	{
		let result = '';
		try {
			if (countries.value) {
				const country = countries.value.getCountryByLocale(getLanguageLocale.value);
				if (country) {
					result = country.code;
				}
			}
		} catch (error: any) {
			result = '';
		}
		return result;
	}

	async function loadCountries() {
		// eslint-disable-next-line no-useless-catch
		try {
			return await GreeveApiSystem.getCountries();
		} catch (error: any | AxiosError) {
			throw error;
		}
	}

	async function initCountries(forceRefresh = false) {
		if (!countries.value || countries.value.length < 1 || forceRefresh && !isLoading.value) {
			isLoading.value = true;
			store.commit('country/setCountries', await loadCountries());
		}
	}

	onBeforeMount(() => {
		initCountries().then(() => {
			//	console.log("challenges loaded");
		});
	});

	return {
		countries,
		loadCountries,
		getCountry_by_Code,
		getDefaultCountry_by_Locale
	}
}
