import {computed, ComputedRef, onBeforeMount, ref} from "vue";
import store from "@/store";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {AxiosError} from "axios";
import {FaqList} from "@/greeve/faq/faq_list.type";
import {GreeveApiFaq} from "@/greeve/api/faq";

export default function useFaq() {
	const faqs: ComputedRef<FaqList|undefined> = computed(() => store.getters['faq/getFaqs']);
	const isLoading = ref(false);

	async function loadFaqs() {
		// eslint-disable-next-line no-useless-catch
		try {
			return await GreeveApiFaq.getFaqs();
		} catch (error: any | AxiosError) {
			throw error;
		}
	}

	async function initFaqs(forceRefresh = false) {
		if (!faqs.value || faqs.value.length < 1 || (forceRefresh && !isLoading.value) || (faqs.value && faqs.value?.isFaqListOlderThan(15))) {
			isLoading.value = true;
			store.commit('faq/setFaqs', await loadFaqs());
		}
	}

	onBeforeMount(() => {
		initFaqs(true).then(() => {
			//	console.log("challenges loaded");
		});
	});

	return {
		faqs,
		loadFaqs,
	}
}
