import useFlowFactory from '@/composable/greeve/useFlowFactory';
import {FlowList} from '@/greeve/flow/flow_list.type';
import {AbstractFlow} from '@/greeve/flow/abstract_flow.type';
import {FlowQueueList} from '@/greeve/flow/queue/flow_queue_list.type';
import {AbstractFlowQueue} from '@/greeve/flow/queue/abstract_flow_queue.type';

const {getFlowFactory} = useFlowFactory();

/* eslint-disable @typescript-eslint/no-unused-vars */

export interface SetFlowQueueList {
	flowUuid: string,
	flowQueueList: FlowQueueList,
}

export interface SetFlowQueue {
	flowUuid: string,
	flowQueue: AbstractFlowQueue,
}

const flow = {
	namespaced: true,
	state: () => ({
		flows: FlowList,
		flowTemplates: FlowList,
		lastFlowUuid: "",
		// templates: FlowTemplateList,
		// userFlows: FlowUserList,
		// additionalInfo: InfoList
	}),
	mutations: {
		setFlows(state: any, flows: FlowList) {
			state.flows = flows;
		},
		setFlow(state: any, flow: AbstractFlow) {
			if (state.flows) {
				const index = state.flows.findIndex((flow: AbstractFlow) => {
					return flow.uuid === flow.uuid;
				});

				if (index !== -1) {
					state.flows[index] = flow;
				}
			} else {
				if (!state.flows) {
					state.flows = getFlowFactory().createEmptyFlowList();
				}
				state.flows.push(flow);
			}
		},
		setFlowTemplates(state: any, flowTemplateList: FlowList) {
			state.flowTemplates = flowTemplateList;
		},
		setFlowProcessDependencies(state: any, {flowUuid, flowProcessDependencyList}: any) {
			if (!state.flows) {
				return;
			}
			const index = state.flows.findIndex((flow: AbstractFlow) => {
				return flow.uuid === flowUuid;
			});

			if (index !== -1) {
				state.flows[index].flow_process_dependencies = flowProcessDependencyList;
			}
		},
		setFlowQueues(state: any, {flowUuid, flowQueueList}: SetFlowQueueList) {
			if (!state.flows) {
				return;
			}
			const index = state.flows.findIndex((flow: AbstractFlow) => {
				return flow.uuid === flowUuid;
			});

			if (index !== -1) {
				console.log("OLD QUEUE", state.flows[index].flow_queues, "NEW", flowQueueList)
				state.flows[index].flow_queues = flowQueueList;
			}
		},
		setFlowQueue(state: any, {flowUuid, flowQueue}: SetFlowQueue) {
			if (!state.flows) {
				return;
			}
			const index = state.flows.findIndex((flow: AbstractFlow) => {
				return flow.uuid === flowUuid;
			});

			if (index !== -1) {
				if (state.flows[index].flow_queues) {
					const flowQueueIndex = state.flows[index].flow_queues.findIndex((flowQueue: AbstractFlowQueue) => {
						return flowQueue.uuid === flowQueue.uuid;
					});
					const newFlow = state.flows[index];
					if (newFlow && flowQueueIndex !== -1) {
						newFlow.flow_queues[flowQueueIndex] = flowQueue;
						if (state.flows) {
							state.flows[index] = newFlow;
						} else {
							if (!state.flows) {
								state.flows = getFlowFactory().createEmptyFlowList();
							}
							state.flows.push(newFlow);
						}
					}
				}
			}
		},
		setFlowQueueState(state: any, {flowUuid, flowQueue}: SetFlowQueue) {
			if (!state.flows) {
				return;
			}
			const index = state.flows.findIndex((flow: AbstractFlow) => {
				return flow.uuid === flowUuid;
			});

			if (index !== -1) {
				if (state.flows[index].flow_queues) {
					const flowQueueIndex = state.flows[index].flow_queues.findIndex((flowQueue: AbstractFlowQueue) => {
						return flowQueue.uuid === flowQueue.uuid;
					});
					const newFlow = state.flows[index];
					if (newFlow && flowQueueIndex !== -1) {
						newFlow.flow_queues[flowQueueIndex].state = flowQueue.state;
						if (state.flows) {
							state.flows[index] = newFlow;
						} else {
							if (!state.flows) {
								state.flows = getFlowFactory().createEmptyFlowList();
							}
							state.flows.push(newFlow);
						}
					}
				}
			}
		},
		setLastFlowUuid(state: any, lastFlowUuid: string) {
			state.lastFlowUuid = lastFlowUuid;
		},
	},
	actions: {
		resetFlows({ commit, state, getters }: any) {
			try {
				commit('setFlows', getFlowFactory().createEmptyFlowList());
			} catch (error: any) {
				console.error(error);
			}
		},
		resetFlowTemplates({ commit, state, getters }: any) {
			try {
				commit('setFlowTemplates', getFlowFactory().createEmptyFlowList());
			} catch (error: any) {
				console.error(error);
			}
		},
	},
	getters: {
		getFlows: (state: any): FlowList|undefined => {
			if (state.flows instanceof FlowList) {
				return state.flows;
			} else if (!(state.flows instanceof FlowList)) {
				return getFlowFactory().createFlowListByResponse(state.flows);
			} else {
				return undefined;
			}
		},
		getFlow_by_Id: (state: any, getters: any) => (flow_id: string): AbstractFlow | undefined => {
			const flow = getters.getFlows.getItemById(flow_id);
			if (flow) {
				return flow;
			}
			return undefined;
		},
		getFlow_by_Uuid: (state: any, getters: any) => (flow_uuid: string): AbstractFlow | undefined => {
			const flow = getters.getFlows.getItemByUuId(flow_uuid);
			if (flow) {
				return flow;
			}
			return undefined;
		},
		getFlowQueue_by_Id: (state: any, getters: any) => (flow_queue_id: number): AbstractFlow | undefined => {
			const flowQueueList = getters.getFlows.getFlowQueueList();
			if (flowQueueList) {
				return flowQueueList.getItemById(flow_queue_id);
			}
			return undefined;
		},
		getFlowTemplates: (state: any): FlowList|undefined => {
			if (state.flowTemplates instanceof FlowList) {
				return state.flowTemplates;
			} else if (!(state.flowTemplates instanceof FlowList)) {
				return getFlowFactory().createFlowListByResponse(state.flowTemplates);
			} else {
				return undefined;
			}
		},
		getLastFlowUuid: (state: any): string|null|undefined => {
			return state.lastFlowUuid;
		},
	}
}

export default flow;