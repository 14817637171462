<template>
	<div v-if="open" class="w-full relative z-10 sticky p-0 nav rounded-xl h-full" >
		<div class="md:mb-6 align-middle rounded-2xl p-[1px] text-md dark:text-gray-400 flex h-full w-full justify-center items-center">
			<div class="flex select-none">
				<div class="w-full rounded-xl  py-4 md:py-12 md:pb-24 text-center items-center">
					<div class="md:mt-10 md:mb-6" >
						<h3 class="text-lg font-extrabold leading-6 md:mb-ß">{{ $t('paywall.title') }}</h3>
						<p class="mt-1 text-sm md:text-base line-clamp-3">{{ $t('paywall.description') }}</p>
					</div>
					<div class="flex mt-2 gap-2.5 justify-center">
						<button type="button"
								class="rounded-xl w-32 dark:bg-white bg-black px-4 py-2 text-sm font-semibold text-white dark:text-black shadow-sm sm:ml-3 outline-none"
								@click="confirm()">{{ $t('paywall.button_login') }}
						</button>
						<button type="button"
										 class="rounded-xl w-32 dark:bg-white bg-black px-4 py-2 text-sm font-semibold text-white dark:text-black shadow-sm sm:ml-3 outline-none"
										 @click="register()">{{ $t('paywall.button_register') }}
					</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref, toRef, watch} from 'vue';
import useTranslation from '@/composable/translation/useTranslation';
import {ExclamationTriangleIcon} from '@heroicons/vue/24/outline';
import router from '@/router';

export interface ScriptInterface {
	src: string;
	type?: string;
	arguments?: any;
	content?: string;
}

export default defineComponent({
	name: 'PaywallOverlay',
	components: {},
	props: {
		title: {type: String},
		description: {type: String},
		confirmButtonName: {type: String, default: 'Confirm'},
		icon: {type: Object, default: ExclamationTriangleIcon},
		cancelButtonName: {type: String, default: 'Cancel'},
		visible: {type: Boolean, default: false},
		type: {type: String, default: 'alert'},
		allowBodyDismiss: {
			type: Boolean,
			default: true,
		},
		showDismissButton: {
			type: Boolean,
			default: true,
		},
		showLoadingAnimationOnConfirm: {
			type: Boolean,
			default: true,
		},
		redirectLink: {
			type: String,
			default: '',
		},
	},
	emits: ['confirm', 'cancelConfirm'],
	setup(props, {emit}) {
		const dialogTitle = toRef(props, 'title');
		const dialogDescription = toRef(props, 'description');
		const visible = toRef(props, 'visible');
		const open = ref(visible.value);
		const {t} = useTranslation();

		function toggleDialog() {
			open.value = !open.value;
		}

		const cancelDialogOutsideClick = () => {
			if (props.allowBodyDismiss) {
				open.value = false;
				emit('cancelConfirm');
			}
		};

		const cancelDialog = () => {
			open.value = false;
			emit('cancelConfirm');
		};

		function confirm() {
			if (props.redirectLink) {
				router.push('/auth?redirect='+props.redirectLink)
			} else {
				router.push('/auth')
			}
			emit('confirm');
		}

		function register() {
			if (props.redirectLink) {
				router.push('/register?redirect='+props.redirectLink)
			} else {
				router.push('/register')
			}
			emit('confirm');
		}

		watch(() => props.visible, (newValue: boolean) => {
			open.value = newValue;
		});

		onMounted(() => {
			//
		});

		return {
			dialogTitle,
			dialogDescription,
			open,
			toggleDialog,
			confirm,
			register,
			cancelDialog,
			cancelDialogOutsideClick,
			t,
		};
	},
});
</script>

<style lang="scss">
</style>
