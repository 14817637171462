import {
	FlowQueueProcessList
} from '@/greeve/flow/queue/process/flow_queue_process_list.type';
import {
	AbstractFlowQueueProcess
} from '@/greeve/flow/queue/process/abstract_flow_queue_process.type';
import {
	FlowQueueProcessDefault
} from '@/greeve/flow/queue/process/type/default/flow_queue_process_default.type';
import {
	FlowQueueProcessType,
} from '@/greeve/flow/queue/process/flow_queue_process.interface';
import {FlowProcessType} from '@/greeve/flow/process/flow_process.interface';
import {
	FlowQueueProcessDefaultDownloadAndShow
} from '@/greeve/flow/queue/process/type/default/subType/flow_queue_process_default_download_and_show.type';

export class FlowQueueProcessFactory {
	/**
	 *
	 * @param flow_queue_processes
	 */
	createFlowQueueProcessListByResponse(flow_queue_processes: Record<string, any>): FlowQueueProcessList {
		const flowQueueProcessList = new FlowQueueProcessList();

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		for (const [key, item] of Object.entries(flow_queue_processes)) {
			if (!item) {
				continue;
			}
			flowQueueProcessList.push(this.createFlowQueueProcessByResponse(item));
		}

		return flowQueueProcessList;
	}

	/**
	 *
	 * @param item
	 */
	createFlowQueueProcessByResponse(item: Record<string, any>): AbstractFlowQueueProcess{
		const id = item.id ?? item.flowProcessId ?? null;
		const uuid = item.uuid;
		const flow_queue_id = item.flow_queue_id ? item.flow_queue_id : (item.flowQueueId ? item.flowQueueId : undefined);
		const flow_process_id = item.flow_process_id ? item.flow_process_id : (item.flowProcessId ? item.flowProcessId : undefined);
		const type = item.type;
		const subType = item.sub_type ? item.sub_type : (item.subType ? item.subType : undefined);
		const state = item.state;
		const job_id = item.job_id;
		const reference = item.reference ?? undefined;
		const source = item.source;
		const result = item.result;
		const data = item.data;
		const created_at = item.created_at ? new Date(item.created_at) : (item.createdAt ? new Date(item.createdAt) : new Date());
		const updated_at = item.updated_at ? new Date(item.updated_at) : (item.updatedAt ? new Date(item.updatedAt) : new Date());

		let flowQueueProcess;

		switch (type) {
			case FlowQueueProcessType.DEFAULT:
				switch (subType) {
					case FlowProcessType.DOWNLOAD_AND_SHOW:
						flowQueueProcess = new FlowQueueProcessDefaultDownloadAndShow(id, uuid, flow_queue_id, flow_process_id, type, state, subType, job_id, reference, source, result, data, created_at, updated_at);
						break;
					default:
						flowQueueProcess = new FlowQueueProcessDefault(id, uuid, flow_queue_id, flow_process_id, type, state, subType, job_id, reference, source, result, data, created_at, updated_at);
						break;
				}
				break;
			default:
				throw new Error('Invalid Flow Queue Process Item: ' + JSON.stringify(item));
		}

		return flowQueueProcess;
	}

	createEmptyFlowProcessList(): FlowQueueProcessList {
		return new FlowQueueProcessList([]);
	}
}