interface DateDiffInterface {
	milliseconds: number,
	seconds: number,
	minutes: number,
	hours: number,
	days: number,
	weeks: number,
}

type DateTimeStyleOptions = 'full' | 'long' | 'medium' | 'short';

const getDateDiff = function (date1: Date, date2: Date, withWeeks = false): DateDiffInterface {
	const result: DateDiffInterface = {
		milliseconds: 0,
		seconds: 0,
		minutes: 0,
		hours: 0,
		days: 0,
		weeks: 0,
	}

	result.milliseconds = date1.getTime() - date2.getTime();
	if (Math.abs(result.milliseconds) >= 1000) {
		result.seconds = Math.floor(result.milliseconds / 1000);
		result.milliseconds = result.milliseconds % 1000;
	}
	if (Math.abs(result.seconds) >= 60) {
		result.minutes = Math.floor(result.seconds / 60);
		result.seconds = result.seconds % 60;
	}
	if (Math.abs(result.minutes) >= 60) {
		result.hours = Math.floor(result.minutes / 60);
		result.minutes = result.minutes % 60;
	}
	if (Math.abs(result.hours) >= 24) {
		result.days = Math.floor(result.hours / 24);
		result.hours = result.hours % 24;
	}
	if (withWeeks && Math.abs(result.days) >= 7) {
		result.weeks = Math.floor(result.days / 7);
		result.days = result.days % 7;
	}

	return result;
}

const subSeconds = function (date  = new Date(), seconds: number): Date {
	return new Date(date.getTime() - (seconds*1000));
}

const subMinutes = function (minutes: number, date  = new Date()): Date {
	return new Date(date.getTime() - (minutes * 60 *1000));
}

const subHours = function (hours: number, date  = new Date()): Date {
	return new Date(date.getTime() - (hours * 60 * 60 *1000));
}

const addSeconds = function (date  = new Date(), seconds: number): Date {
	return new Date(date.getTime() + (seconds*1000));
}

const dateTimestampToMilliseconds = function (datetimestamp: number): number {
	return datetimestamp * 1000;
}

const dateTimestampToDate = function (datetimestamp: number): Date {
	return new Date(datetimestamp);
}

const getDateDiffInSeconds = function (newTimestamp: number, oldTimestamp: number, convertToMilliseconds = true): number {
	if (convertToMilliseconds) {
		newTimestamp = newTimestamp * 1000;
		oldTimestamp = oldTimestamp * 1000;
	}

	return Math.abs((new Date(newTimestamp).getTime() - new Date(oldTimestamp).getTime())) / 1000;
}

const getLocale = function (): string {
	return 'de-AT';
}

const convertTimeStampToSeconds = function (timestamp: number): number {
	return Math.floor(timestamp / 1000)
}

const isTimestampInMilliSeconds = function (timestamp: number): boolean {
	const date = new Date(timestamp);

	if (Math.abs(Date.now() - date.getTime()) < Math.abs(Date.now() - date.getTime() * 1000)) {
		return true;
	} else {
		return false;
	}
}

const getDateDiffAsText = function (date1: Date, date2: Date, short = false): string {
	let result;

	if (date1.getTime() > date2.getTime()) {
		const dateDiff = getDateDiff(date1, date2, true);
		if (isDateTomorrow(date1)) {
			result = 'Morgen um ' + new Intl.DateTimeFormat(getLocale(), {timeStyle: 'short'}).format(date1);
		} else if (dateDiff.weeks > 2) {
			result = 'am ' + new Intl.DateTimeFormat(getLocale(), {
				dateStyle: 'long'
			}).format(date1);
		} else if (dateDiff.weeks > 0) {
			result = 'in 1 Woche';
			if (!short && dateDiff.days > 0) {
				result += ` ${dateDiff.days} ${dateDiff.days === 1 ? 'Tag' : 'Tage'}`;
			}
		} else if (dateDiff.days >= 1) {
			result = `in ${dateDiff.days} ${dateDiff.days === 1 ? 'Tag' : 'Tagen'}`;
			if (!short && dateDiff.hours > 0) {
				result += ` ${dateDiff.hours} ${dateDiff.hours === 1 ? 'Stunde' : 'Stunden'}`;
			}
		} else if (isDateToday(date1) && dateDiff.hours > 8) {
			result = 'Heute um ' + new Intl.DateTimeFormat(getLocale(), {timeStyle: 'short'}).format(date1);
		} else if (dateDiff.hours > 0) {
			result = `in ${dateDiff.hours} ${dateDiff.hours === 1 ? 'Stunde' : 'Stunden'}`;
			if (!short && dateDiff.minutes > 0) {
				result+= ` ${dateDiff.minutes} ${dateDiff.minutes === 1 ? 'Minute' : 'Minuten'}`;
			}
		} else if (dateDiff.minutes > 0) {
			result = `in ${dateDiff.minutes} ${dateDiff.minutes === 1 ? 'Minute' : 'Minuten'}`;
		} else {
			result = 'jetzt';
		}
	} else {
		const dateDiff = getDateDiff(date2, date1, true);
		result = '-';
		if (isDateYesterday(date1)) {
			result = 'Gestern um ' + new Intl.DateTimeFormat(getLocale(), {timeStyle: 'short'}).format(date1);
		} else if (dateDiff.weeks > 2) {
			result = 'am ' + new Intl.DateTimeFormat(getLocale(), {
				dateStyle: 'long'
			}).format(date1);
		} else if (dateDiff.weeks > 0) {
			result = 'vor 1 Woche';
			if (!short && dateDiff.days > 0) {
				result += ` ${dateDiff.days} ${dateDiff.days === 1 ? 'Tag' : 'Tage'}`;
			}
		} else if (dateDiff.days >= 1) {
			result = `vor ${dateDiff.days} ${dateDiff.days === 1 ? 'Tag' : 'Tagen'}`;
			if (!short && dateDiff.hours > 0) {
				result += ` ${dateDiff.hours} ${dateDiff.hours === 1 ? 'Stunde' : 'Stunden'}`;
			}
		} else if (isDateToday(date1) && dateDiff.hours > 8) {
			result = 'Heute um ' + new Intl.DateTimeFormat(getLocale(), {timeStyle: 'short'}).format(date1);
		} else if (dateDiff.hours > 0) {
			result = `vor ${dateDiff.hours} ${dateDiff.hours === 1 ? 'Stunde' : 'Stunden'}`;
			if (!short && dateDiff.minutes > 0) {
				result+= ` ${dateDiff.minutes} ${dateDiff.minutes === 1 ? 'Minute' : 'Minuten'}`;
			}
		} else if (dateDiff.minutes > 0) {
			result = `vor ${dateDiff.minutes} ${dateDiff.minutes === 1 ? 'Minute' : 'Minuten'}`;
		} else {
			result = 'jetzt';
		}

	}

	return result;
}

const getDateDiffAsDatePartString = function(date1: Date, date2: Date, delimiter = ', ', lastDelimiter: string | null = ' und '): string {
	let result = '';
	const dateDiff = getDateDiff(date1, date2, true);

	const resultParts: string[] = [];
	if (dateDiff.weeks > 0) {
		resultParts.push(`${dateDiff.weeks} ${dateDiff.weeks === 1 ? 'Woche' : 'Wochen'}`);

		if (dateDiff.days > 0) {
			resultParts.push(`${dateDiff.days} ${dateDiff.days === 1 ? 'Tag' : 'Tage'}`);
		}
	} else if (dateDiff.days > 0) {
		resultParts.push(`${dateDiff.days} ${dateDiff.days === 1 ? 'Tag' : 'Tage'}`);
		if (dateDiff.hours > 0) {
			resultParts.push(`${dateDiff.hours} ${dateDiff.hours === 1 ? 'Stunde' : 'Stunden'}`);
		}
	} else if (dateDiff.hours > 0) {
		resultParts.push(`${dateDiff.hours} ${dateDiff.hours === 1 ? 'Stunde' : 'Stunden'}`);
		if (dateDiff.minutes > 0) {
			resultParts.push(`${dateDiff.minutes} ${dateDiff.minutes === 1 ? 'Minute' : 'Minuten'}`);
		}
	} else if (dateDiff.minutes > 0) {
		resultParts.push(`${dateDiff.minutes} ${dateDiff.minutes === 1 ? 'Minute' : 'Minuten'}`);
	}

	if (resultParts.length > 0) {
		if (resultParts.length > 1) {
			const lastPart = resultParts.pop();
			result = resultParts.join(delimiter) + (lastPart ? lastDelimiter + lastPart : '');
		} else {
			result = resultParts.pop() || '';
		}
	}

	return result;
}

const formatDate = function (date: Date, dateStyle: DateTimeStyleOptions = 'long', timeStyle: DateTimeStyleOptions = 'medium'): string {
	return (new Intl.DateTimeFormat(getLocale(), {dateStyle: dateStyle, timeStyle: timeStyle})).format(date);
}

const formatDateIso = function (date: Date): string {
	return date.toISOString();
}

const formatTimestamp = function (timestamp: number, dateStyle: DateTimeStyleOptions = 'long', timeStyle: DateTimeStyleOptions = 'medium'): string {
	return formatDate(new Date(timestamp), dateStyle, timeStyle);
}


const isDateYesterday = function (date: Date | null = null): boolean {
	date = date || new Date();
	const yesterday = new Date(Date.now() - 1000 * 60 * 60 * 24);
	yesterday.setHours(0, 0, 0);

	return date.getTime() >= yesterday.getTime() && date.getTime() < yesterday.getTime() + 1000 * 60 * 60 * 24;
}


const isDateToday = function (date: Date | null = null): boolean {
	date = date || new Date();
	const today = new Date(Date.now());
	today.setHours(0, 0, 0);

	return date.getTime() >= today.getTime() && date.getTime() < today.getTime() + 1000 * 60 * 60 * 24;
}


const isDateTomorrow = function (date: Date | null = null): boolean {
	date = date || new Date();
	const tomorrow = new Date(Date.now() + 1000 * 60 * 60 * 24);
	tomorrow.setHours(0, 0, 0);

	return date.getTime() >= tomorrow.getTime() && date.getTime() < tomorrow.getTime() + 1000 * 60 * 60 * 24;
}

const formatSecondsNice = function (seconds: number): string {
	let result = "";
	const currentDate = new Date();
	const date = subSeconds(new Date(), seconds);
	const dateDiff = getDateDiff(currentDate, date);
	if (dateDiff.days > 0) {
		result += dateDiff.days + ' d ';
	}
	if (dateDiff.hours > 0) {
		result += dateDiff.hours + ' h ';
	}
	if (dateDiff.minutes > 0) {
		result += dateDiff.minutes + ' m ';
	}
	if (dateDiff.seconds > 0) {
		result += dateDiff.seconds + ' s ';
	}
	return result;
}

const getCurrentDate = function (): Date {
	return new Date();
}

function getCurrentDateTime(): string {
	const date = new Date();
	return date.toISOString();
}

const createCurrentTimeStamp = function (): number {
	return Math.floor(Date.now() / 1000)
}

export {
	getDateDiff,
	getDateDiffAsText,
	DateDiffInterface,
	isDateYesterday,
	isDateToday,
	isDateTomorrow,
	getLocale,
	getDateDiffInSeconds,
	subSeconds,
	subMinutes,
	subHours,
	formatDate,
	formatTimestamp,
	formatSecondsNice,
	formatDateIso,
	getDateDiffAsDatePartString,
	getCurrentDate,
	dateTimestampToMilliseconds,
	dateTimestampToDate,
	isTimestampInMilliSeconds,
	convertTimeStampToSeconds,
	addSeconds,
	createCurrentTimeStamp,
	getCurrentDateTime
}
