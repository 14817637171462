<!--suppress AllyHtmlVueInspection, AllyHtmlVueInspection -->
<template>
			<section id="GreevePricingPlans" v-if="recurringProductList" class="">
				<div class="max-w-7xl lg:mx-auto">
					<div class="max-w-4xl mx-4 sm:mx-12 my-4 font-comfortaa text-stroke text-4xl font-bold">
						<span class="">{{ $t('be') }} </span><span class="text-gr-primary">{{ $t('pro') }}</span>{{ $t('doPro') }}
					</div>
					<div style="-webkit-transform: translate3d(0px, 0px, 0px);">
						<div class="text-white max-w-7xl mx-4 mx-auto">
							<figure class="relative">
								<div class="isolate overflow-hidden">
									<div class="flow-root">
										<div class="mx-auto max-w-7xl px-0 lg:px-8">
											<div class="relative z-10">
												<div class="flex justify-center">

													<RadioGroup v-model="frequency"
																class="grid grid-cols-2 gap-x-1 rounded-xl border-2 border-gray-100 nav p-1 text-center text-xs font-medium text-gray-500 dark:text-gray-400">
														<RadioGroupLabel class="sr-only">{{ $t('paymentFrequency') }}
														</RadioGroupLabel>
														<RadioGroupOption as="template" v-for="option in frequencies"
																		  :key="option.value" :value="option"
																		  v-slot="{ checked }">
															<div :class="[checked ? 'bg-gray-100 dark:bg-gr-darker text-gray-500 dark:text-gray-400 ' : '', 'cursor-pointer rounded-lg py-1 px-2.5']">
																<span>{{ option.label }}</span>
															</div>
														</RadioGroupOption>
													</RadioGroup>
												</div>
											</div>
											<div class="relative mx-auto mt-4 mb-4 grid max-w-md grid-cols-1 gap-y-4 sm:gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
												<div class="hidden lg:absolute lg:inset-x-px lg:bottom-0 lg:top-4 lg:block rounded-2xl lg:bg-black lg:ring-1 lg:ring-white/10"
													 aria-hidden="true"/>
												<div v-for="recurringProduct in getRecurringProducts()"
													 :key="recurringProduct.id"
													 :class="[isProductFeatured(recurringProduct) ? 'z-10 bg-gray-200 shadow-xl ring-1 ring-gray-900/10' : 'bg-gray-800/80 ring-1 ring-white/10 dark:ring-black/10 lg:bg-transparent lg:ring-0', 'relative rounded-2xl']">
													<div class="p-4 lg:pt-12 xl:p-10 xl:pt-14">
														<h3 :id="recurringProduct.id"
															:class="[isProductFeatured(recurringProduct) ? 'text-gray-900' : 'text-white', 'text-sm font-semibold leading-6']">
															{{ recurringProduct.getProductName() }}</h3>
														<div class="flex flex-col gap-2 sm:gap-6 sm:flex-row sm:items-end sm:justify-between lg:flex-col lg:items-stretch">
															<div class="mt-2 flex items-center gap-x-4">
																<p :class="[isProductFeatured(recurringProduct) ? 'text-gray-900' : 'text-white', 'text-4xl font-bold tracking-tight']">
																	{{
																																		recurringProduct.price?.getFormattedAmount(false,
																																				false)
																	}}</p>
																<div class="text-sm leading-5">
																	<p :class="isProductFeatured(recurringProduct) ? 'text-gray-900' : 'text-white'">
																		{{ $t('eur') }}</p>
																	<p :class="isProductFeatured(recurringProduct) ? 'text-gray-500' : 'text-gray-400'">
																		{{ $t('shop.product.'+frequency.value) }}</p>
																</div>
															</div>
															<button @click="buyThisPlan(recurringProduct)"
																	:aria-describedby="recurringProduct.id"
																	:class="[isProductFeatured(recurringProduct) ? 'bg-gr-primary hover:bg-gr-primary2 shadow-sm focus-visible:outline-black' : 'bg-white/10 hover:bg-white/20 focus-visible:outline-white', 'rounded-xl py-2 px-3 text-center text-sm font-semibold leading-6 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2']">
																{{ $t('buyThisPlan') }}
															</button>
														</div>
														<Disclosure v-slot="{ open }">
															<DisclosureButton
																	:class="[isProductFeatured(recurringProduct) ? 'flex w-full rounded-2xl border-2 border-black text-black justify-between mt-4 px-4 py-2 text-left text-sm font-medium' : 'flex w-full rounded-2xl border-1-2px-white justify-between mt-4 px-4 py-2 text-left text-sm font-medium', 'flex w-full rounded-2xl border-1-2px-white justify-between mt-4 px-4 py-2 text-left text-sm font-medium']">
																<span>show Features</span>
																<ChevronDownIcon
																		:class="open ? 'rotate-180 transform' : ''"
																		class="h-5 w-5"
																/>
															</DisclosureButton>
															<DisclosurePanel class="text-gray-500">
																<div class="mt-4 flow-root">
																	<ul role="list"
																		:class="[isProductFeatured(recurringProduct) ? 'divide-gray-900/5 border-gray-900/5 text-gray-600' : 'divide-white/5 border-white/5 text-white', '-my-2 divide-y border-t text-sm leading-6 lg:border-t-0']">
																		<li v-for="mainFeature in recurringProduct.getProductFeatures()"
																			:key="mainFeature" class="flex gap-x-2 py-1">
																			<CheckIcon
																					:class="[isProductFeatured(recurringProduct) ? 'text-black' : 'text-gray-500', 'h-6 w-5 flex-none']"
																					aria-hidden="true"/>
																			{{ typeof mainFeature === 'string' ? mainFeature : (mainFeature.source ? mainFeature.source : $t('shop.product.'+recurringProduct.price.type+'.'+recurringProduct.product_reference+'.features.'+mainFeature.name)) }}
																		</li>
																	</ul>
																</div>
															</DisclosurePanel>
														</Disclosure>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</figure>
						</div>
					</div>
				</div>
			</section>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref, watch} from 'vue';
import {
	Disclosure, DisclosureButton, DisclosurePanel,
	RadioGroup,
	RadioGroupLabel,
	RadioGroupOption,
} from '@headlessui/vue';
import {CheckIcon, ChevronDownIcon} from '@heroicons/vue/20/solid';
import useShop from '@/composable/greeve/useShop';
import {GreeveProductPriceSubType, GreeveProductPriceType} from '../../greeve/shop/product/price.interface';
import {GreeveProductReference} from '@/greeve/shop/product/product.interface';
import {ProductList} from '@/greeve/shop/product/product_list.type';
import {Product} from '@/greeve/shop/product/product.type';
import {useRouter} from 'vue-router';
import useTranslation from '@/composable/core/useTranslation';
import {useHeaderHeight} from "@/composable/global/useHeaderHeight";

export default defineComponent({
	name: 'PricingSectionSmall',
	components: {
		DisclosurePanel,
		DisclosureButton,
		Disclosure,
		RadioGroup, RadioGroupLabel, RadioGroupOption, CheckIcon, ChevronDownIcon,
	},
	setup() {
		const { headerHeight } = useHeaderHeight();
		const router = useRouter();
		const {products, initProducts, addProductToCart, addRecurringProductToCart} = useShop();
		const {t} = useTranslation();
		const oneTimeProductList: ProductList | any = ref();
		const recurringProductList: ProductList | any = ref();

		const selectedFrequency: GreeveProductPriceSubType | any = ref(GreeveProductPriceSubType.RECURRING_MONTHLY);
		const frequencies = [
			{value: GreeveProductPriceSubType.RECURRING_MONTHLY, label: t('shop.product.'+GreeveProductPriceSubType.RECURRING_MONTHLY)},
			{value: GreeveProductPriceSubType.RECURRING_YEARLY, label: t('shop.product.'+GreeveProductPriceSubType.RECURRING_YEARLY)},
		];
		const frequency = ref(frequencies[0]);

		const sections = [
			{
				name: t('shop.product.feature_name'),
				features: [
					{
						reference: 'credits',
						name: t('shop.product.features.credits'),
						tiers: {
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_STARTER_PLAN]: '30',
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_BUSINESS_PLAN]: '500',
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN]: '200',
						},
					},
					{
						reference: 'flows',
						name: t('shop.product.features.flows'),
						tiers: {
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_STARTER_PLAN]: true,
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_BUSINESS_PLAN]: true,
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN]: true,
						},
					},
					{
						reference: 'extendable_credits',
						name: t('shop.product.features.extendable_credits'),
						tiers: {
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_STARTER_PLAN]: true,
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_BUSINESS_PLAN]: true,
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN]: true,
						},
					},
					{
						reference: 'multi_accounts',
						name: t('shop.product.features.multi_accounts'),
						tiers: {
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_STARTER_PLAN]: false,
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_BUSINESS_PLAN]: 'up to 7 accounts',
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN]: '3 accounts',
						},
					},
					{
						reference: 'api_access',
						name: t('shop.product.features.api_access'),
						tiers: {
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_STARTER_PLAN]: false,
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_BUSINESS_PLAN]: true,
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN]: true,
						},
					},
					{
						reference: 'api_calls',
						name: t('shop.product.features.api_calls'),
						tiers: {
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_STARTER_PLAN]: '0',
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_BUSINESS_PLAN]: 'unlimited',
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN]: '20.000',
						},
					},
					{
						reference: 'multi_access_token',
						name: t('shop.product.features.multi_access_token'),
						tiers: {
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_STARTER_PLAN]: false,
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_BUSINESS_PLAN]: 'up to 2 Tokens',
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN]: false,
						},
					},
					{
						reference: 'support_standard',
						name: t('shop.product.features.support_standard'),
						tiers: {
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_STARTER_PLAN]: true,
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_BUSINESS_PLAN]: true,
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN]: true,
						},
					},
					{
						reference: 'support_premium',
						name: t('shop.product.features.support_premium'),
						tiers: {
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_STARTER_PLAN]: false,
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_BUSINESS_PLAN]: true,
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN]: false,
						},
					},
					{
						reference: 'employee_training',
						name: t('shop.product.features.employee_training'),
						tiers: {
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_STARTER_PLAN]: false,
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_BUSINESS_PLAN]: 'up to 10 Employees',
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN]: false,
						},
					},
					{
						reference: 'secure_plus',
						name: t('shop.product.features.secure_plus'),
						tiers: {
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_STARTER_PLAN]: false,
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_BUSINESS_PLAN]: true,
							[GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN]: false,
						},
					},
				],
			},
		];

		const selected: Product | any = ref();

		function addSelectedOneTimeToCart() {
			if (selected.value) {
				addProductToCart(selected.value.product_id);
			}
			router.push('/shop');
		}

		function isProductFeatured(product: Product): boolean {
			return product.product_reference === GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN;
		}

		function buyThisPlan(product: Product) {
			addRecurringProductToCart(product.product_id, product.price.sub_type);
			router.push('/shop');
		}

		function getOneTimeProducts(force = false) {
			if (!oneTimeProductList.value || force) {
				oneTimeProductList.value = products.value.getOneTimeProductList();
				selected.value = oneTimeProductList.value[1];
			}
			return oneTimeProductList.value;
		}

		watch(frequency, (frequence, oldFrequence) => {
			if (frequence.value !== oldFrequence.value) {
				getRecurringProducts(true);
			}
		});

		function sortRecurringProductList() {
			recurringProductList.value = recurringProductList.value.sort((a: Product, b: Product) => {
				if (a.product_reference === GreeveProductReference.STRIPE_PRODUCT_RECURRING_STARTER_PLAN) return -1;
				if (b.product_reference === GreeveProductReference.STRIPE_PRODUCT_RECURRING_STARTER_PLAN) return 1;
				if (a.product_reference === GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN) return -1;
				if (b.product_reference === GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN) return 1;

				return 0;
			});
		}

		function getRecurringProducts(force = false): ProductList {
			if (!recurringProductList.value || force) {
				initProducts(true).then(() => {
					if (!recurringProductList.value || force) {
						const subType = frequency.value.value;
						recurringProductList.value = products.value.getProductListByPriceTypeReferenceList(
								GreeveProductPriceType.RECURRING_PRICE, subType, [
									GreeveProductReference.STRIPE_PRODUCT_RECURRING_STARTER_PLAN,
									GreeveProductReference.STRIPE_PRODUCT_RECURRING_GROWTH_PLAN,
									GreeveProductReference.STRIPE_PRODUCT_RECURRING_BUSINESS_PLAN]);
						sortRecurringProductList();
					}
				});
			}

			return recurringProductList.value;
		}


		async function initProductsForView() {
			getOneTimeProducts(true);
			getRecurringProducts(true);
		}

		onMounted(() => {
			try {
				//TODO performance!! if products older than 8 hours or dev
				if (!products.value || products.value.length === 0) {
					initProducts(true).then(() => {
						initProductsForView();
					})
				} else {
					initProductsForView();
				}
			} catch (e) {
				console.error(e);
			}
		});

		return {
			selected,
			frequency,
			frequencies,
			sections,
			products,
			getRecurringProducts,
			getOneTimeProducts,
			selectedFrequency,
			addSelectedOneTimeToCart,
			isProductFeatured,
			buyThisPlan,
			recurringProductList,
			oneTimeProductList, headerHeight
		};
	},
});

</script>
<style lang="scss">
</style>
