<template>
	<div class="px-1 mb-2 lg:p-2 lg:px-4 shadowSearchbar margin-auto">
		<Popover v-slot="{ open }" :class="['relative rounded-xl fixed inset-x-0 flex flex-col-reverse', open ? 'z-40' : 'z-20']">
			<div class="relative z-10 rounded-3xl bg-gray-200 dark:bg-gr-dark overflow-hidden">
				<div class="flex items-center">
					<PopoverButton v-if="mode === 'default' && uploadModeActive"
							class="focus:outline-none font-semibold items-center pl-2 sm:pl-4 py-2 font-medium">
						<PaperClipIcon class="h-5 w-5 font-extrabold text-gray-500 dark:text-gray-400 mr-6" aria-hidden="true"/>
					</PopoverButton>
					<div v-else class="pl-5 sm:pl-8 py-2"></div>
					<div class="w-full">
						<div class="absolute">
							<!--					TODO show only when input field is filled-->
							<ion-icon v-show="false"
												class="icon-btn absolute cursor-pointer mt-[0.95em] md:mt-[0.75em] right-0 mr-0 text-xl z-10"
												:icon="icons.close" @click="clearSearch"></ion-icon>
						</div>
						<ion-textarea ref="textareaRef" :disabled="isDisabled" :rows="computedMaxRows" :wrap="wrap" type="search"
													enterkeyhint="enter"
													:placeholder="placeholder" :spellcheck="!isMobileDevice"
													class="bg-transparent customSearch mt-0 w-full z-0"
													@keydown.enter="searchOnEnter" :animated="false" @ionClear="clearSearch"
													@keyup.esc="clearSearch"
													v-model="textInputState" :auto-grow="autoGrow" :autofocus="true" inputmode="text">
						</ion-textarea>
					</div>
					<voice-action @sendVoiceText="updateTextAreaValueByVoice" @endVoiceRecognition="updateTextAreaValueByVoice"
												v-show="activateWebSpeech" class="icon-btn text-xl md:text-2xl cursor-pointer mt-1.5 pr-4 px-0 sm:px-2"></voice-action>
					<ion-icon @click="search" class="icon-btn text-3xl md:text-2xl cursor-pointer pr-4 sm:pr-6" :icon="icons.send"></ion-icon>
				</div>
			</div>
			<ProAdDialog :title="$t('becomeAMemberTitle')" :description="$t('becomeAMemberDescription')"
									 :cancel-button-name="$t('becomeAMemberCancel')" :confirm-button-name="$t('becomeAMemberConfirm')"
									 type="success" :visible="showProAdDialog" @cancel-confirm="showProAdDialog = false"></ProAdDialog>
			<TransitionRoot v-if="uploadModeActive" as="template" :show="open">
				<div :class="['absolute w-full mb-16', getComputedMaxRows() >= 2 && getComputedMaxRows() < 5? 'mb-18' : '', getComputedMaxRows() >= 5 && getComputedMaxRows() <= 8? 'mb-[12.5em]' : '',  getComputedMaxRows() > 8 ? 'mb-44' : '']">
					<TransitionChild as="template" enter="transition-opacity ease-linear duration-300"
													 enter-from="opacity-0" enter-to="opacity-100"
													 leave="transition-opacity ease-linear duration-300" leave-from="opacity-100"
													 leave-to="opacity-0">
						<PopoverOverlay class="fixed inset-0"/>
					</TransitionChild>
					<TransitionChild as="template" enter="transition ease-in-out duration-300 transform"
													 enter-from="translate-y-full" enter-to="translate-y-0"
													 leave="transition ease-in-out duration-300 transform"
													 leave-from="translate-y-0" leave-to="translate-y-full">
						<PopoverPanel v-slot="{ close }"
								class="relative mb-4 rounded-3xl bg-gray-200 dark:bg-gr-dark text-gray-500 dark:text-gray-40 px-4 py-6 z-40">
							<ul role="list" class="divide-y" v-for="item in inputMethode"
									:key="item.name">
								<li :class="['p-4 flex items-center font-mono', item.pro === true ? '' : 'text-gray-500 dark:text-gray-400', item.active ? 'font-bold cursor-pointer' : 'opacity-65 cursor-not-allowed']" @click="item.active ? createUploadPrompt(item.type, close) : undefined">
									<component :is="item.icon"
														 class="h-5 w-5 mr-4 "
														 aria-hidden="true"/>
									<p class="">{{ item.name }}</p>
									<span v-if="item.coming_soon" class="ml-6 self-center text-xs px-4 py-1 cursor-default rounded-3xl py-0 bg-black dark:bg-white text-white dark:text-black">
										Coming soon
									</span>
									<button @click="showProAdDialog = true" v-if="item.pro === true"
													class="cursor-pointer ml-6 self-center text-xs px-4 py-1 rounded-3xl py-0 bg-black dark:bg-white text-white dark:text-black"
													style="font-family: Nunito; font-weight: 700;">pro
									</button>
								</li>
							</ul>
						</PopoverPanel>
					</TransitionChild>
				</div>
			</TransitionRoot>
		</Popover>
	</div>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, ref, watch} from 'vue';
import * as icons from 'ionicons/icons';
import useSystem from '@/composable/core/useSystem';
import useTranslation from '@/composable/translation/useTranslation';
import VoiceAction from '@/components/actions/VoiceAction.vue';
import {IonIcon, IonTextarea} from '@ionic/vue';
import {Popover, PopoverButton, PopoverPanel, PopoverOverlay, TransitionChild, TransitionRoot} from '@headlessui/vue';
import {PhotoIcon, DocumentIcon, MicrophoneIcon, DocumentTextIcon, PaperClipIcon} from '@heroicons/vue/20/solid';
import ProAdDialog from '@/components/modal/ProAdDialog.vue';
import ConfirmDialog from '@/components/modal/ConfirmDialog.vue';
import {useVModel} from '@/composable/input/useVModel';
import useCustomStore from '@/composable/custom/useCustomStore';
import {GreeveSearchItemSubTypeInterface} from '@/greeve/search/item/search_item.interface';

interface WrapProp {
	wrap: 'hard' | 'soft' | 'off';
}

export const enum InputMode {
	DEFAULT = 'default',
	UPLOAD = 'upload',
}

export default defineComponent({
	name: 'TextInput',
	components: {
		ConfirmDialog,
		ProAdDialog,
		TransitionRoot,
		TransitionChild,
		Popover,
		PaperClipIcon,
		VoiceAction,
		IonIcon,
		IonTextarea,
		PopoverOverlay,
		PopoverButton,
		PopoverPanel,
		PhotoIcon,
		DocumentIcon,
		MicrophoneIcon,
		DocumentTextIcon,
	},
	emits: ['onEnter', 'onClear', 'onChangeInput', 'update:searchDefaultValue', 'createUploadPrompt'],
	props: {
		searchDefaultValue: {
			type: String,
			default: '',
		},
		proFeature: {
			type: Boolean,
			default: false,
		},
		uploadModeActive: {
			type: Boolean,
			default: true,
		},
		enableOnBlurAction: {
			type: Boolean,
			default: false,
		},
		isDisabled: {
			type: Boolean,
			default: false,
		},
		activateWebSpeech: {
			type: Boolean,
			default: true,
		},
		autoGrow: {
			type: Boolean,
			default: true,
		},
		maxRows: {
			type: Number,
			default: 10,
		},
		wrap: {
			type: String as () => WrapProp['wrap'],
			default: 'soft',
		},
		mode: {
			type: Object as () => InputMode,
			default: InputMode.DEFAULT,
		},
	},
	setup(props, {emit}) {
		const showProAdDialog = ref(false);
		const textInputState = useVModel(props, 'searchDefaultValue');
		// const textInputState = ref(props.searchDefaultValue);
		const {t} = useTranslation();
		const {setIsFooterVisible} = useCustomStore();
		const {isIosOrAndroid} = useSystem();
		let timerId: number | null = null;

		const textareaRef = ref<any>(null);

		const isMobileDevice = computed(() => {
			return useSystem().isMobileDevice();
		})

		// const computedMaxRows = computed(() => {
		// 	const lineCount = textInputState.value.split('\n').length;
		// 	return lineCount > 1 ? lineCount + 1 : 1;
		// });

		// const autoGrowArea = computed(() => {
		// 	if (!props.autoGrow) {
		// 		return false;
		// 	}
		//
		// 	return computedMaxRows.value >= props
		// })

		const computedMaxRows = computed(() => {
			if (!textareaRef.value) return 1;

			const lineCount = textInputState.value.split('\n').length;
			const maxRows = 8;

			// Setzen Sie maxRows auf lineCount + 1, jedoch maximal auf 8
			return Math.min(lineCount + 1, maxRows);
		});

		// const computedMaxRows = computed(() => {
		// 	if (!textareaRef.value) return 1;
		//
		// 	const lineHeight = parseInt(getComputedStyle(textareaRef.value).lineHeight, 10) || 20;
		// 	const currentHeight = textareaRef.value.scrollHeight;
		//
		// 	return Math.max(Math.ceil(currentHeight / lineHeight), 1);
		// });

		const inputMethode = ref([
			{name: t('inputType1'), type: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE, icon: PhotoIcon, active: true, pro: false, coming_soon: false},
			{name: t('inputType4'), type: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT, icon: MicrophoneIcon, active: true, pro: false, coming_soon: false},
			{name: t('inputType3'), type: GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_DOCUMENT, icon: DocumentIcon, active: false, pro: true, coming_soon: true},
		]);

		function getComputedMaxRows(): number
		{
			// return 10;
			return computedMaxRows.value;
		}

		function updateTextAreaValueByVoice(value: string) {
			if (value) {
				emit('onChangeInput', value);
			}
		}

		function searchOnEnter(event: Event | any) {
			event.preventDefault();
			const textarea: HTMLTextAreaElement = event.target;
			if (event.shiftKey) {
				addNewLine(textarea);
			} else {
				if (isIosOrAndroid()) {
					if (event.key === 'Enter') {
						addNewLine(textarea);
						return;
					}
				}
				search(event);
			}
		}

		function addNewLine(textarea: HTMLTextAreaElement) {
			const startPos = textarea.selectionStart;
			const endPos = textarea.selectionEnd;
			const value = textarea.value;
			const beforeCursor = value.substring(0, startPos);
			const afterCursor = value.substring(endPos, value.length);
			textarea.value = beforeCursor + '\n' + afterCursor;
			textarea.selectionStart = textarea.selectionEnd = startPos + 1;
		}

		function search(event: Event | undefined = undefined) {
			if (event && isIosOrAndroid()) {
				const element: HTMLInputElement | any = event.target;
				if (element) {
					element.blur();
				}
			}
			emit('onEnter', props.searchDefaultValue);
		}

		function blurSearchInput(event: Event) {
			if (props.enableOnBlurAction) {
				const element: HTMLInputElement | any = event.target;
				if (element) {
					element.blur();
				}
				search(event);
			}
		}

		function createUploadPrompt(subType: GreeveSearchItemSubTypeInterface, close: any) {
			emit('createUploadPrompt', subType);
			close();
		}

		function clearSearch(event: any) {
			event.target.focus();
			emit('onClear');
		}

		const placeholder = ref('');

		//TODO: change Suggestions based on selected Output Type
		const displayTextArray = [
			t('suggestions.text.Type1'),
			t('suggestions.text.Type2'),
			t('suggestions.text.Type3'),
			t('suggestions.text.Type4'),
			t('suggestions.text.Type5'),
			t('suggestions.text.Type6'),
			t('suggestions.text.Type7'),
			t('suggestions.text.Type8'),
		];
		// displayTextArray.value = [
		//	t("suggestions.image.Type1"),
		//	t("suggestions.image.Type2"),
		//	t("suggestions.image.Type3"),
		//	t("suggestions.image.Type4"),
		//	t("suggestions.image.Type5"),
		//	t("suggestions.image.Type6"),
		//	t("suggestions.image.Type7"),
		//	t("suggestions.image.Type8"),
		// ];
		// displayTextArray.value = [
		//	t("suggestions.audio.Type1"),
		//	t("suggestions.audio.Type2"),
		//	t("suggestions.audio.Type3"),
		//	t("suggestions.audio.Type4"),
		//	t("suggestions.audio.Type5"),
		//	t("suggestions.audio.Type6"),
		//	t("suggestions.audio.Type7"),
		//	t("suggestions.audio.Type8"),
		// ];
		const typingSpeed = 100;
		const erasingSpeed = 100;
		const newTextDelay = 200;
		const charIndex = ref(0);
		const displayTextArrayIndex = ref(0);

		watch(
				() => props.searchDefaultValue,
				(newVal) => {
					if (newVal) {
						timerId && clearTimeout(timerId);
						timerId = null;
					} else if (timerId === null) {
						timerId = setTimeout(typeText, newTextDelay + 200);
					}
				},
		);

		const typeText = () => {
			if (charIndex.value < displayTextArray[displayTextArrayIndex.value].length) {
				placeholder.value += displayTextArray[displayTextArrayIndex.value].charAt(charIndex.value);
				charIndex.value += 1;
				timerId = setTimeout(typeText, typingSpeed);
			} else {
				timerId = setTimeout(eraseText, newTextDelay);
			}
		};

		const eraseText = () => {
			if (charIndex.value > 0) {
				placeholder.value = displayTextArray[displayTextArrayIndex.value].substring(0, charIndex.value - 1);
				charIndex.value -= 1;
				timerId = setTimeout(eraseText, erasingSpeed);
			} else {
				displayTextArrayIndex.value += 1;
				if (displayTextArrayIndex.value >= displayTextArray.length) displayTextArrayIndex.value = 0;
				timerId = setTimeout(typeText, typingSpeed + 1000);
			}
		};

		onMounted(() => {
			timerId = setTimeout(typeText, newTextDelay + 200);
		});

		watch(() => props.searchDefaultValue, (newValue) => {
			textInputState.value = newValue;
		});

		return {
			placeholder, showProAdDialog, t, icons, inputMethode, search, clearSearch, blurSearchInput,
			updateTextAreaValueByVoice, searchOnEnter, textInputState, computedMaxRows, textareaRef, setIsFooterVisible, getComputedMaxRows,
			createUploadPrompt, isMobileDevice
		};
	},
});

</script>
<style lang="scss" scoped>

.customSearch {
	padding: 0 !important;
	border: 0;
	margin-left: -12px !important;
	@apply min-h-6 md:min-h-8  #{!important};
}

//TODO check
textarea:focus {
	--tw-ring-color: transparent !important;
	--tw-ring-offset-shadow: 0 !important;
	--tw-ring-shadow: 0 !important;
}

.icon-btn {
	color: var(--ion-color-step-600, #666666);
}

ion-textarea {
	--padding-start: 0em !important;
	padding-right: 0em !important;
	--width: -webkit-fill-available;
	--border-radius: 0rem !important;
	--line-height: auto;
	//box-shadow: inset 0.2rem 0.2rem 0.5rem var(--grayLight-2), inset -0.2rem -0.2rem 0.5rem var(--whyte);
	@apply overflow-hidden #{!important};
	//@apply rounded-2xl overflow-hidden #{!important};
	//--background-color: rgba(255, 255, 255, 0.3) !important;
}

.textarea-wrapper,
.native-textarea {
	//background: red !important;
	//margin-left: 20px !important;
	@apply overflow-hidden #{!important};
	padding: 0px !important;
	padding-top: 4px !important;
	padding-bottom: 4px !important;
	@apply py-1 md:py-2  #{!important};
	@apply my-[3px] md:my-[1px] #{!important};
	//-webkit-transform: none !important;
	//transform: none !important;
}

.native-textarea {
	@apply pr-0 #{!important};
}

.textarea-input-container.sc-ion-textarea-ios {
  @apply h-14 xl:h-12 pl-12 #{!important};
  //--background-color: rgba(255, 255, 255, 0.3) !important
}

.textarea-input-container.sc-ion-textarea-ios {
	@apply pl-2 #{!important};
}

ion-textarea .textarea-input-container {
	--width: -moz-available;
	//@apply text-gr-primary #{!important};
	--width: -webkit-fill-available;
	--border-radius: 2rem !important;
	--font-size: 1.4rem;
	--padding-left: 3.8rem;
	--padding-right: 1.5rem;
	//--box-shadow: inset 0.2rem 0.2rem 0.5rem var(--grayLight-2), inset -0.2rem -0.2rem 0.5rem var(--whyte);
	//--background: rgba(255, 255, 255, 0.3) !important;
	--color: var(--grayDark);
}

.ion-textarea native-textarea {
	--width: -moz-available;
	//@apply text-gr-primary #{!important};
	--width: -webkit-fill-available;
	--border-radius: 2rem !important;
	--font-size: 1.4rem;
	--padding-left: 3.8rem;
	--padding-right: 12rem;
	//--box-shadow: inset 0.2rem 0.2rem 0.5rem var(--grayLight-2), inset -0.2rem -0.2rem 0.5rem var(--whyte);
	//--background: rgba(255, 255, 255, 0.3) !important;
	--color: var(--grayDark);
}

@media (prefers-color-scheme: dark) {
	ion-searchbar .searchbar-input-container {
		--background: rgba(18, 18, 18, 0.5) !important;
	}
}

</style>
