import {PaginationMetaInterface} from "@/greeve/core/pagination_meta.interface";
import {SearchResult} from "@/greeve/search/search_result.type";

export class SearchResultList extends Array<SearchResult> {

	public paginationMetaData?: PaginationMetaInterface;

	constructor(items: Array<SearchResult> = []) {
		super();
		this.push(...items);
	}

	add(items: SearchResult) {
		this.push(items);
	}

	remove(index: number) {
		this.splice(index, 1);
	}

	removeAll() {
		const itemCount = this.length;
		this.splice(itemCount, 1);
	}

	getItem(index: number): SearchResult {
		return this[index];
	}

	getItemByType(searchResultType: string): SearchResult | undefined {
		return this.find(({type}) => type === searchResultType);
	}

	getItemBySubType(searchResultSubType: string): SearchResult | undefined {
		return this.find(({subType}) => subType === searchResultSubType);
	}

	sortItemsById() {
		this.sort((a:SearchResult|any, b: SearchResult|any) => {
			return a.id - b.id;
		});
	}

	getFirstId(): number|undefined {
		let result;
		try {
			const firstItem = this.at(0);

			if (firstItem) {
				result = firstItem.id;
			}
		} catch (e) {
			console.error(e);
		}

		return result;
	}

	getLastId(): number|undefined {
		let result;
		try {
			const lastItem = this.at(-1);

			if (lastItem) {
				result = lastItem.id;
			}
		} catch (e) {
			console.error(e);
		}

		return result;
	}

	getGroupReference(): string|undefined {
		const searchResultList: SearchResultList|any[] = [...this];
		const result = searchResultList.find((searchResult: SearchResult) => {
			return searchResult.group_reference && searchResult.group_reference.length > 0
		});
		if (result) {
			return result.group_reference;
		}
		return undefined;
	}

	getLastResult(): SearchResult|undefined {
		let result;
		try {
			const lastItem = this.at(-1);

			if (lastItem) {
				result = lastItem
			}
		} catch (e) {
			console.error(e);
		}

		return result;
	}

	enhanceMetaData(metaData: any) {
		this.paginationMetaData = metaData;
	}
}