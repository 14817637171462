import {Customer} from "@/greeve/shop/customer/customer.type";
import {CustomerAddress} from "@/greeve/shop/customer/customer_address.type";
import {AddressInterface} from "@/greeve/user/user.interface";
import {User} from "@/greeve/user/user.type";
import useCountry from "@/composable/core/useCountry";

export class CustomerFactory {
	/**
	 *
	 * @param item
	 */
	createCustomerByResponse(item: Record<string, any>): Customer {
		const email = item.email;
		const customerAddress = item.address ? this.createCustomerAddressByResponse(item.address) : this.createEmptyCustomerAddress();
		const stripeId = item.stripe_id;
		const data = item.data;
		const created_at = item.created_at ? new Date(item.created_at) : (item.createdAt ? new Date(item.createdAt) : new Date());
		const updated_at = item.updated_at ? new Date(item.updated_at) : (item.updatedAt ? new Date(item.updatedAt) : new Date());

		return new Customer(email, customerAddress, stripeId, created_at, updated_at, data);
	}

	createCustomerByUser(user: User): Customer {
		const customerAddress = user.profile?.address ? this.createCustomerAddressByUserAddress(user.profile.address) : this.createEmptyCustomerAddress();
		return new Customer(user.email, customerAddress, user.stripe_id);
	}

	createEmptyCustomer(): Customer {
		return new Customer('', this.createEmptyCustomerAddress());
	}


	createEmptyCustomerAddress(): CustomerAddress {
		return new CustomerAddress('', '', '', useCountry().getDefaultCountry_by_Locale());
	}

	createCustomerAddressByUserAddress(userAddress: AddressInterface): CustomerAddress {
		return new CustomerAddress(userAddress.first_name + ' ' + userAddress.last_name, userAddress.street ?? '', userAddress.city ?? '', userAddress.country_code ?? '', '', '', userAddress.zip_code);
	}

	createCustomerAddressByResponse(item: Record<string, any>): CustomerAddress {
		return new CustomerAddress(item.name, item.street, item.city, item.country_code, item.street_number, item.apartment, item.state, item.postal_code, item.created_at, item.updated_at, item.data, item.options ?? undefined);
	}

	createCustomerAddressByStripeAddress(item: Record<string, any>): CustomerAddress {
		return new CustomerAddress(item.name, item.street, item.city, item.country_code, item.street_number, item.apartment, item.state, item.postal_code, item.created_at, item.updated_at, item.data);
	}
}