<template>
	<div>
		<form id="address-form">
			<div id="address-element">
				<!-- Elements will create form elements here -->
			</div>
		</form>
	</div>
</template>

<script lang="ts">
import {ref, onMounted, defineComponent} from 'vue';
import useShop from "@/composable/greeve/useShop";
import {Stripe} from "@stripe/stripe-js";
import {UserAddress} from "@/greeve/user/address/user_address.type";
import useUser from '@/composable/greeve/useUser';

export default defineComponent({
	name: "StripeAddress",
	components: {},
	props: {},
	emits: ['onChangeAddress', 'onInitialized'],
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setup(props, {emit}) {
		const {cart, getStripe, getSetupIntent, initCartAddress} = useShop();
		const {getLatestUserAddress, initLastUserAddress} = useUser();
		const isStripeElementInitialized = ref(false);
		const stripe: Stripe | undefined | any = ref();
		const stripeElements = ref<any>();
		const addressElement = ref();
		const address: any = ref({
			name: '',
			street: '',
			street_number: '',
			line1: '',
			line2: '',
			city: '',
			state: '',
			postal_code: '',
			country: '',
			country_code: '',
		});

		const appearance = ref<any>();

		function initializeStripeAppearance() {
			const isDarkMode = () => window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

			appearance.value = {
				theme: 'flat',
				variables: {
					colorPrimary: '#ff4600',
					colorBackground: isDarkMode() ? '#222428' : '#e0e0e0',
					colorText: isDarkMode() ? '#c5c5c5' : '#222428',
					colorTextPlaceholder: isDarkMode() ? 'rgba(121,121,121,0.86)'  : '#3b3b3d',
					// colorPlaceholder: isDarkMode() ? 'rgba(107,107,107,0.58)' : '#3b3b3d',
					// colorDanger: getComputedStyle(document.documentElement).getPropertyValue('--ion-color-danger'),
				},
				rules: {
					// '.Tab': {
					// 	border: '1px solid #E0E6EB',
					// 	boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)',
					// },
					//
					// '.Tab:hover': {
					// 	color: 'var(--colorText)',
					// },
					//
					// '.Tab--selected': {
					// 	borderColor: '#E0E6EB',
					// 	boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorPrimary)',
					// },

					'.Input': {
						// boxShadow: '0.1rem 0.1rem 0.2rem rgba(94, 104, 121, 0.3), -0.2rem -0.2rem 0.5rem rgba(255, 255, 255, 0.45)',
						// boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorText)',
						boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 0.9px var(--colorText)',
						// boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px ' + isDarkMode ? 'rgba(178,178,178)' : 'rgba(34,36,40)',
					},

					// '.Input--invalid': {
					// 	boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorDanger)',
					// },
				}
			}
		}

		// eslint-disable-next-line
		function updateStripeAddress(selectedAddress: UserAddress) {
			if (!isStripeElementInitialized.value) {
				return;
			}
			// address.value.company = selectedAddress.company;
			addressElement.value.update({
				fields: {
					name: selectedAddress.first_name + ' ' + selectedAddress.last_name,
					city: selectedAddress.city,
					country_code: selectedAddress.country_code,
					street: selectedAddress.street,
					postal_code: selectedAddress.zip_code,
				},
			});
		}

		async function initializeStripePaymentComponent() {
			const clientSecret = await createClientSecret();
			stripe.value = await getStripe();
			stripeElements.value = stripe.value.elements({ clientSecret, appearance: appearance.value });
			// stripeElements.value = stripe.value.elements(getStripePaymentElementOptions(clientSecret), (appearance.value));
			let customerDefaultValues = {};

			const hasCartValidAddress = cart.value.customer?.address && cart.value.customer.address.name.length > 0;
			if (!hasCartValidAddress) {
				if (!getLatestUserAddress.value) {
					await initLastUserAddress();
				}
				await initCartAddress(getLatestUserAddress.value);
			}
			if (cart.value.customer?.address && cart.value.customer.address.name.length > 0) {
				const customerAddress = cart.value.customer.address;
				customerDefaultValues = {
					name: customerAddress.name,
					address: {
						line1: customerAddress.street,
						// line2: customerAddress.line2 ?? '',
						city: customerAddress.city,
						state: customerAddress.state,
						postal_code: customerAddress.postal_code ? customerAddress.postal_code : customerAddress.state,
						country: customerAddress.country_code,
					},
				};
			}

			const addressOptions = {
				mode: 'shipping',
				defaultValues: customerDefaultValues,
			};
			addressElement.value = stripeElements.value.create('address', addressOptions);
			addressElement.value.mount('#address-element');

			addressElement.value.on('change', (event: any) => {
				if (event.complete) {
					address.value = event.value.address;
					address.value.street = address.value.line1;
					address.value.country_code = address.value.country;
					address.value.name = event.value.name;
					emit('onChangeAddress', address.value);
				}
			})
			isStripeElementInitialized.value = true;
			emit('onInitialized', true);
		}

		async function createClientSecret(): Promise<string> {
			//TODO check how long is the setupIntent valid, maybe we can save it in the customer data with a timestamp ( performance )
			return getSetupIntent();
		}

		onMounted(() => {
			initializeStripeAppearance();
			initializeStripePaymentComponent();
		})

		return {
			address, appearance
		};
	}
})
</script>

<style scoped>

</style>
