<template>
	<div class="">
		<select v-model="$i18n.locale" class="appearance-none text-gray-500 dark:text-gray-400 bg-gray-100 dark:bg-gr-darker flex text-sm rounded-xl p-2 w-auto bg-transparent outline-none rounded-xl"
				@change="changeLanguage()">
			<option v-for="(languageName, languageCode) in arrLanguageMap" :key="languageCode" :value="languageCode">
				{{ showFullName ? $t('locales.' + languageCode) : languageName }}
			</option>
		</select>
	</div>
</template>

<script lang="ts">
import useCustomStore from '@/composable/custom/useCustomStore';
import {defineComponent} from 'vue';
import {useI18n} from "vue-i18n";

export default defineComponent({
	name: 'LanguageSelector',
	components: {},
	props: {
		showFullName: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['onChangeLanguage'],
	setup(props, {emit}) {
		const {setLanguageLocale} = useCustomStore();
		const arrLanguageMap = {"de": "DE", "en": "EN"};
		const i18n = useI18n();

		function changeLanguage() {
			setLanguageLocale(i18n.locale.value);
			emit('onChangeLanguage', i18n.locale.value);
		}

		return {arrLanguageMap, changeLanguage};
	}
})
</script>

<style scoped>

</style>
