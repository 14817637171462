import {
	AbstractWizzard,
	WizzardStepStatus,
} from '@/greeve/flow/wizzard/base/abstract_wizzard';
import {FlowSubType} from '@/greeve/flow/flow.interface';
import {
	StepSummaryDataInterface
} from '@/greeve/flow/wizzard/base/wizzard_summary';

interface WizzardWhatsAppDefaultNewsletterData {
	contacts: string[];
	message: string;
}

export class WizzardWhatsAppDefaultNewsletter extends AbstractWizzard {
	private data: WizzardWhatsAppDefaultNewsletterData | undefined;

	constructor() {
		super();
		this.currentStep = 1;
	}

	// getWizzardColor(): string {
	// 	return 'bg-gradient-to-r from-emerald-400 to-cyan-400';
	// }
	//
	// getWizzardPrimaryTextColor(): string {
	// 	return 'text-emerald-500';
	// }

	getData(): WizzardWhatsAppDefaultNewsletterData | undefined {
		return this.data;
	}

	hasData(): boolean {
		return !!this.data;
	}

	getStepTitle(step: number): string {
		switch (step) {
			case 1:
				return 'Select Contacts';
			case 2:
				return 'Create Message';
			default:
				return '';
		}
	}

	setContacts(contacts: any[]) {
		if (this.data) {
			this.data.contacts = contacts;
			return;
		}
		this.data = {contacts: contacts, message: ''};
	}

	setMessage(message: string) {
		if (this.data) {
			this.data.message = message;
			return;
		}
		this.data = {contacts: [], message: message};
	}

	getStepsPath(): string {
		return 'template/WhatsApp/DefaultWhatsAppNewsletter';
	}

	getType(): FlowSubType {
		return FlowSubType.WHATSAPP_DEFAULT_NEWSLETTER;
	}

	getStepsCount(): number {
		return 2;
	}

	getStepComponentMap(): Record<number, string> {
		return {1: 'WizzardWhatsAppNewsletterSelectContactsComponent', 2: 'WizzardWhatsAppNewsletterCreateMessageComponent'};
	}

	getStepsStatusInfo(): Record<number, WizzardStepStatus> {
		return {1: {step: 1, title: 'Select Contacts', description: 'Select Contacts', current: this.currentStep === 1}, 2: {step: 2, title: 'Create Message', description: 'Create Message', current: this.currentStep === 2}};
	}

	getWizzardSummaryDataByStep(step: number): StepSummaryDataInterface|undefined {
		switch (step) {
			case 1:
				return {
					step: 1,
					title: 'Contacts',
					description: (this.data?.contacts.length !== undefined ? this.data?.contacts.length : 0) + ' Contacts selected!',
				};
			case 2:
				return {
					step: 2,
					title: 'Message',
					description: this.data?.message ?? '',
				};
			default:
				return undefined;
		}
	}

	isSimulateAble(): boolean {
		return false;
	}
}