<template>
	<ion-page>
		<ion-content>
			<div class="relative isolate overflow-hidden pt-14">
				<section id="GreeveSignUp" class="mt-24 mb-56">
					<div class="box max-w-7xl pt-20 pb-28 text-center mx-auto">
						<div class="mx-auto max-w-2xl py-20 sm:py-48 lg:py-56">
							<div class="text-center">
								<h1 class="text-4xl font-bold tracking-tight text-gr-darkest dark:text-white sm:text-6xl">{{ $t('enterprise.hero.title') }}</h1>
								<p class="mt-6 text-lg leading-8 text-gr-dark dark:text-gray-300">{{ $t('enterprise.hero.subtitle') }}</p>
								<div class="p-4 mt-10 flex items-center justify-center gap-x-6">
									<button @click="scrollToElement('contact')" class="bg-black dark:bg-white font-bold text-lg px-7 py-2 rounded-3xl mr-2 text-white dark:text-black">
										{{ $t('enterprise.hero.button') }}
									</button>
								</div>
							</div>
						</div>
					</div>

					<div class="box bg-white bg-opacity-55 py-32">
						<div class="">
							<div class="mx-auto max-w-7xl px-6 lg:px-8">
								<div class="mx-auto max-w-2xl lg:mx-0">
									<h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{{ $t('enterprise.stats.title') }}</h2>
									<p class="mt-6 text-base leading-7 text-gray-600">{{ $t('enterprise.stats.description') }}</p>
								</div>
								<div class="mx-auto mt-16 flex max-w-2xl flex-col gap-8 lg:mx-0 lg:mt-20 lg:max-w-none lg:flex-row lg:items-end">
									<div class="flex flex-col-reverse justify-between gap-x-16 gap-y-4 rounded-2xl bg-gray-50 p-8 sm:w-3/4 sm:max-w-md sm:flex-row-reverse sm:items-end lg:w-72 lg:max-w-none lg:flex-none lg:flex-col lg:items-start">
										<p class="flex-none text-3xl font-bold tracking-tight text-gray-900">{{ $t('enterprise.stats.llm.stat') }}</p>
										<div class="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
											<p class="text-lg font-semibold tracking-tight text-gray-900">{{ $t('enterprise.stats.llm.title') }}</p>
											<p class="mt-2 text-base leading-7 text-gray-600">{{ $t('enterprise.stats.llm.description') }}</p>
										</div>
									</div>
									<div class="flex flex-col-reverse justify-between gap-x-16 gap-y-4 rounded-2xl bg-gray-900 p-8 sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-sm lg:flex-auto lg:flex-col lg:items-start lg:gap-y-16">
										<p class="flex-none text-3xl font-bold tracking-tight text-white">{{ $t('enterprise.stats.models.stat') }}</p>
										<div class="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
											<p class="text-lg font-semibold tracking-tight text-white">{{ $t('enterprise.stats.models.title') }}</p>
											<p class="mt-2 text-base leading-7 text-gray-400">{{ $t('enterprise.stats.models.description') }}</p>
											<ul class="mt-2 text-base leading-7 text-gray-300 list-disc">
												<li v-for="(mode, index) in displayedModes" :key="index">{{ mode }}</li>
												<p v-if="shouldShowMore" @click="toggleCollapse" class="cursor-pointer text-blue-500">{{ collapsed ? $t('enterprise.stats.models.show_more') : $t('enterprise.stats.models.show_less') }}</p>
											</ul>
										</div>
									</div>
									<div class="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-indigo-600 p-8 sm:w-11/12 sm:max-w-xl sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-none lg:flex-auto lg:flex-col lg:items-start lg:gap-y-28">
										<p class="flex-none text-3xl font-bold tracking-tight text-white">{{ $t('enterprise.stats.support.stat') }}</p>
										<div class="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
											<p class="text-lg font-semibold tracking-tight text-white">{{ $t('enterprise.stats.support.title') }}</p>
											<p class="mt-2 text-base leading-7 text-indigo-200">{{ $t('enterprise.stats.support.description') }}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="relative isolate overflow-hidden bg-gray-900 bg-opacity-55 box">
						<div class="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
							<div class="mx-auto max-w-2xl text-center">
								<h2 class="text-3xl font-bold tracking-tight text-white sm:text-4xl">{{ $t('enterprise.hero.eyecatcher.title') }}<br /></h2>
								<p class="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-100">{{ $t('enterprise.hero.eyecatcher.description') }}</p>
								<div class="mt-10 flex items-center justify-center gap-x-6">
									<a @click="scrollToElement('contact')" class="cursor-pointer rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">{{ $t('enterprise.hero.eyecatcher.button') }}</a>
								</div>
							</div>
						</div>
						<svg viewBox="0 0 1024 1024" class="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]" aria-hidden="true">
							<circle cx="512" cy="512" r="512" fill="url(#8d958450-c69f-4251-94bc-4e091a323369)" fill-opacity="0.7" />
							<defs>
								<radialGradient id="8d958450-c69f-4251-94bc-4e091a323369">
									<stop stop-color="#7775D6" />
									<stop offset="1" stop-color="#E935C1" />
								</radialGradient>
							</defs>
						</svg>
					</div>

					<div class="box" id="contact">
						<inquiry-component contact-type="enterprise" :contact-header="$t('inquiry.HeaderTitle')" contact-css="p-4 lg:pt-20 bg-white bg-opacity-85 lg:px-20"
															 :contact-header-info="$t('inquiry.HeaderInfo')"
															 :contact-submit-button="$t('inquiry.FormBtn')"></inquiry-component>
					</div>

				</section>
				<!--Footer-->
				<FooterBar class="mt-12"></FooterBar>
			</div>
		</ion-content>
	</ion-page>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted, ref} from 'vue';
import {IonContent, IonPage} from '@ionic/vue';
import FooterBar from '@/components/positions/FooterBar.vue';
import {useHeaderHeight} from '@/composable/global/useHeaderHeight';
import InquiryComponent from '@/components/contact/InquiryComponent.vue';
import useSystem from '@/composable/core/useSystem';
import useTranslation from '@/composable/core/useTranslation';

export default defineComponent({
	name: 'Enterprise',
	components: {
		InquiryComponent,
		FooterBar, IonContent, IonPage,
	},
	setup() {
		const {headerHeight} = useHeaderHeight();
		const {scrollToElement} = useSystem();
		const {t} = useTranslation();

		const modes = ref([
			t('enterprise.stats.models.modes.social_media_content'),
			t('enterprise.stats.models.modes.chat'),
			t('enterprise.stats.models.modes.image_generation'),
			t('enterprise.stats.models.modes.texting'),
			t('enterprise.stats.models.modes.image_editing'),
			t('enterprise.stats.models.modes.image_vision'),
			t('enterprise.stats.models.modes.text_to_speech'),
			t('enterprise.stats.models.modes.speech_to_text'),
			t('enterprise.stats.models.modes.video_to_text'),
			t('enterprise.stats.models.modes.text_to_pdf'),
		]);

		const collapsed = ref(true);

		const displayedModes = computed(() => {
			return collapsed.value ? modes.value.slice(0, 3) : modes.value;
		});

		const shouldShowMore = computed(() => modes.value.length > 3);

		const toggleCollapse = () => {
			collapsed.value = !collapsed.value;
		};

		onMounted(() => {
			//
		});

		return {headerHeight, scrollToElement, displayedModes, shouldShowMore, toggleCollapse, collapsed};
	},
});

</script>
<style lang="scss">

</style>
