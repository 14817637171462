<template>
	<ion-page>
		<ion-content>
			<main :style="{ paddingTop: headerHeight + 'px' }">
				<div class="box mt-[3.4em] lg:mt-[12.4em] max-w-4xl mx-auto">
					<form class=" mx-2 box bg-white dark:bg-gr-dark justify-center rounded-xl mb-60 items-center sm:max-w-xl lg:w-4/5 sm:mx-auto">
						<div class="justify-center items-center sm:max-w-xl py-1 md:py-4 md:py-6 px-4  md:px-10">
							<div v-if="!isLogoutInProcess">
								<default-button button-type="button" :button-text="$t('shop.checkout.logout.title')" @click-button="logout(true, true)"></default-button>
							</div>
							<div v-else>
								<default-button button-type="button" class="disabled:opacity-75" disabled :button-text="$t('shop.checkout.logout.in_progress')"></default-button>
							</div>
						</div>
					</form>
				</div>
			</main>
			<FooterBar class="mt-12"></FooterBar>
		</ion-content>
	</ion-page>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from 'vue';
import {IonPage, IonContent} from '@ionic/vue';
import FooterBar from "@/components/positions/FooterBar.vue";
import DefaultButton from '@/components/core/DefaultButton.vue';
import useAuth from '@/composable/auth/useAuth';
import useToastMessage from '@/composable/core/useToastMessage';
import i18n from '@/i18n';
import {useHeaderHeight} from "@/composable/global/useHeaderHeight";

export default defineComponent({
	name: 'LogoutPage',
	components: {
		DefaultButton,
		FooterBar,
		IonPage,
		IonContent
	},
	setup() {
		const { headerHeight } = useHeaderHeight();
		const {logout} = useAuth();
		const {openToast} = useToastMessage();

		const isLogoutInProcess = ref(true);

		onMounted(() => {
			isLogoutInProcess.value = true;
			logout(true, true);
			openToast(i18n.global.t('shop.checkout.logout.successfully'), 'success', 'bottom', true, 5000, undefined, true);
			isLogoutInProcess.value = false;
		})


		return {logout, isLogoutInProcess,
			headerHeight}
	}
});

</script>
<style lang="scss">
</style>
