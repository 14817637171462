<template>
	<h1>Instagram Page</h1>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
	name: 'InstagramPage',
	components: {
	},
	setup() {
		return {
		};
	},
});
</script>

<style lang="scss" >
.slide-enter-active {
	transition: transform 0.3s;
}

.slide-leave-active {
	transition: transform 0.3s;
}

.slide-enter,
.slide-leave-to {
	transform: translateX(-100%);
}

ion-content {
	--padding-top: var(--offset-top, 0);
}

</style>
