import {PaginationMetaInterface} from '@/greeve/core/pagination_meta.interface';

export abstract class AbstractPaginationFactory {
	hasPaginationMetaData(data: Record<string, any>) {
		return data?.current_page && data?.from && data?.last_page;
	}

	getMetaDataByResponse(data: Record<string, any>): PaginationMetaInterface {
		return {
			current_page: data.current_page,
			from: data.from,
			last_page: data.last_page,
			per_page: data.per_page,
			to: data.to,
			total: data.total,
			links: data.links,
			path: data.path
		};
	}
}