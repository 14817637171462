import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "sr-only" }
const _hoisted_2 = {
  "aria-labelledby": "summary-heading",
  class: "bg-gray-50 dark:bg-gr-dark px-4 sm:px-6 lg:col-start-2 lg:row-start-1 lg:bg-transparent lg:px-0"
}
const _hoisted_3 = {
  key: 0,
  class: "mx-auto w-full lg:max-w-none"
}
const _hoisted_4 = {
  id: "summary-heading",
  class: "text-lg font-semibold text-gray-900 dark:text-white"
}
const _hoisted_5 = { class: "py-6 grid grid-cols-2 gap-x-4 text-sm text-gray-600" }
const _hoisted_6 = { class: "font-medium text-gray-400" }
const _hoisted_7 = { class: "mt-2" }
const _hoisted_8 = { class: "not-italic" }
const _hoisted_9 = { class: "block" }
const _hoisted_10 = { class: "block" }
const _hoisted_11 = { class: "block" }
const _hoisted_12 = { class: "space-y-1 border-t border-gray-400 py-6 text-sm font-medium text-gray-500" }
const _hoisted_13 = { class: "font-medium text-gray-400 mb-2" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { key: 0 }
const _hoisted_18 = {
  key: 0,
  class: "space-y-1 border-t border-gray-400 pt-6 pb-2 text-sm font-medium text-gray-500"
}
const _hoisted_19 = { class: "font-medium text-gray-400 mb-2" }
const _hoisted_20 = {
  key: 0,
  class: "flex justify-between"
}
const _hoisted_21 = { class: "text-gray-600" }
const _hoisted_22 = {
  key: 1,
  class: "flex justify-between"
}
const _hoisted_23 = { class: "text-gray-600" }
const _hoisted_24 = {
  key: 1,
  class: "space-y-1 border-t border-gray-400 pt-6 pb-2 text-sm font-medium text-gray-500"
}
const _hoisted_25 = { class: "font-medium text-gray-400 mb-2" }
const _hoisted_26 = { class: "flex justify-between" }
const _hoisted_27 = { class: "text-gray-600" }
const _hoisted_28 = { class: "border-gray-500 py-2 text-sm font-medium text-gray-500" }
const _hoisted_29 = {
  key: 0,
  class: "flex justify-between"
}
const _hoisted_30 = { class: "text-gray-600" }
const _hoisted_31 = { class: "flex items-center justify-between font-semibold mt-2 border-t border-gray-500 py-2 text-gray-400" }
const _hoisted_32 = { class: "text-base" }
const _hoisted_33 = { class: "text-base" }
const _hoisted_34 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_checkout_pay_button = _resolveComponent("checkout-pay-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.$t('shop.checkout.order_information.title')), 1),
    _createElementVNode("section", _hoisted_2, [
      (_ctx.cart.items && _ctx.cart.items.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.$t('shop.checkout.order_information.title')), 1),
            _createElementVNode("dl", _hoisted_5, [
              _createElementVNode("div", null, [
                _createElementVNode("dt", _hoisted_6, _toDisplayString(_ctx.$t('billingAddress')), 1),
                _createElementVNode("dd", _hoisted_7, [
                  _createElementVNode("address", _hoisted_8, [
                    _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.cart.customer.address.name), 1),
                    _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.cart.customer.address.street) + " " + _toDisplayString(_ctx.cart.customer.address.street_number), 1),
                    _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.cart.customer.address.city) + ", " + _toDisplayString(_ctx.cart.customer.address.getCountryName()), 1)
                  ])
                ])
              ])
            ]),
            _createElementVNode("dl", _hoisted_12, [
              _createElementVNode("dt", _hoisted_13, _toDisplayString(_ctx.$t('servicesOverview')), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cart.items, (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: item.id,
                  class: "font-medium grid grid-cols-3 text-gray-500 dark:text-gray-400 bg-gray-200 dark:bg-gr-darker shadow-sm focus-visible:outline-black rounded-xl py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                }, [
                  _createElementVNode("p", null, [
                    _createTextVNode(_toDisplayString(item.getProduct()?.getProductName()), 1),
                    (item.quantity > 1)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_14, " (x " + _toDisplayString(item.getQuantity()) + ")", 1))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("p", null, [
                    _createElementVNode("span", null, [
                      _createTextVNode(_toDisplayString(item.getPrice()?.getFormattedAmount(true)), 1),
                      (item.getProduct()?.price?.sub_type === _ctx.getRecurringMonthlySubType())
                        ? (_openBlock(), _createElementBlock("span", _hoisted_15, "/mo"))
                        : (item.getProduct()?.price?.sub_type === _ctx.getRecurringYearlySubType())
                          ? (_openBlock(), _createElementBlock("span", _hoisted_16, "/yr"))
                          : _createCommentVNode("", true)
                    ])
                  ]),
                  (item.getProduct()?.price?.type !== _ctx.getOneTimePriceType())
                    ? (_openBlock(), _createElementBlock("p", _hoisted_17, _toDisplayString(item.getProduct()?.getProductCreditAmount()) + " " + _toDisplayString(_ctx.$t('shop.product.features.credits')), 1))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ]),
            (_ctx.cart.hasRecurringProducts())
              ? (_openBlock(), _createElementBlock("dl", _hoisted_18, [
                  _createElementVNode("dt", _hoisted_19, _toDisplayString(_ctx.$t('paymentDuration')), 1),
                  (_ctx.cart.hasRecurringProducts(_ctx.getRecurringMonthlySubType()))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                        _createElementVNode("dt", null, _toDisplayString(_ctx.$t('monthlyPayment')), 1),
                        _createElementVNode("dd", _hoisted_21, _toDisplayString(_ctx.cart.getTotalMonthlyAmount(true)), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.cart.hasRecurringProducts(_ctx.getRecurringYearlySubType()))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                        _createElementVNode("dt", null, _toDisplayString(_ctx.$t('annualPayment')), 1),
                        _createElementVNode("dd", _hoisted_23, _toDisplayString(_ctx.cart.getTotalYearlyAmount(true)), 1)
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (_ctx.cart.hasOneTimeProducts())
              ? (_openBlock(), _createElementBlock("dl", _hoisted_24, [
                  _createElementVNode("dt", _hoisted_25, _toDisplayString(_ctx.$t('oneTimePayments')), 1),
                  _createElementVNode("div", _hoisted_26, [
                    _cache[0] || (_cache[0] = _createElementVNode("dt", null, null, -1)),
                    _createElementVNode("dd", _hoisted_27, _toDisplayString(_ctx.cart.getTotalOneTimePaymentAmount(true)), 1)
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("dl", _hoisted_28, [
              (_ctx.cart.hasTaxAmount())
                ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                    _createElementVNode("dt", null, _toDisplayString(_ctx.$t('taxes')), 1),
                    _createElementVNode("dd", _hoisted_30, _toDisplayString(_ctx.cart.getTotalTaxAmountCalculated(true)), 1)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_31, [
                _createElementVNode("dt", _hoisted_32, _toDisplayString(_ctx.$t('total')), 1),
                _createElementVNode("dd", _hoisted_33, _toDisplayString(_ctx.cart.getTotalAmountCalculated(true)), 1)
              ])
            ]),
            _createVNode(_component_checkout_pay_button)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_34))
    ])
  ], 64))
}