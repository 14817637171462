import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Transition as _Transition, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mx-1 text-sm mb-0 font-semibold leading-6 dark:text-gray-500"
}
const _hoisted_2 = {
  key: 1,
  class: "mx-1 text-sm mb-0 font-semibold leading-6 dark:text-gray-500"
}
const _hoisted_3 = { class: "flex flex-col gap-4 sm:flex-row sm:items-end sm:justify-between lg:flex-col lg:items-stretch" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EllipsisVerticalIcon = _resolveComponent("EllipsisVerticalIcon")!
  const _component_PopoverButton = _resolveComponent("PopoverButton")!
  const _component_ListSelectDropdown = _resolveComponent("ListSelectDropdown")!
  const _component_PopoverPanel = _resolveComponent("PopoverPanel")!
  const _component_Popover = _resolveComponent("Popover")!

  return (_openBlock(), _createBlock(_component_Popover, { class: "flow-root text-sm lg:relative" }, {
    default: _withCtx(() => [
      _createVNode(_component_PopoverButton, { class: "outline-none items-center" }, {
        default: _withCtx(() => [
          _createVNode(_component_EllipsisVerticalIcon, { class: "h-5 w-5 -mb-[7px]" })
        ]),
        _: 1
      }),
      _createVNode(_Transition, {
        "enter-active-class": "transition ease-out duration-200",
        "enter-from-class": "opacity-0",
        "enter-to-class": "opacity-100",
        "leave-active-class": "transition ease-in duration-150",
        "leave-from-class": "opacity-100",
        "leave-to-class": "opacity-0"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_PopoverPanel, { class: "absolute inset-x-0 top-16 mt-px bg-white dark:bg-gr-dark shadow-lg p-5 lg:top-full lg:left-auto lg:right-0 lg:mt-3 lg:w-52 rounded-xl" }, {
            default: _withCtx(() => [
              (_ctx.SettingsAvailable === true)
                ? (_openBlock(), _createElementBlock("h3", _hoisted_1, _toDisplayString(_ctx.$t('nodeSettings')), 1))
                : (_openBlock(), _createElementBlock("h3", _hoisted_2, _toDisplayString(_ctx.$t('noSettingsAvailable')), 1)),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_ListSelectDropdown)
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}