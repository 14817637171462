import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = {
  class: "mt-1 relative rounded-2xl bg-gray-200 dark:bg-gray-700 h-12 sm:h-14",
  style: {"display":"-webkit-flex!important"}
}
const _hoisted_3 = ["id", "name", "placeholder", "required", "type", "min", "max"]
const _hoisted_4 = { class: "absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExclamationCircleIcon = _resolveComponent("ExclamationCircleIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['rounded-lg', _ctx.iWidth, _ctx.textPosition, _ctx.iRounded, _ctx.iHeight, _ctx.cssClass])
  }, [
    (_ctx.inputPlaceholder.length > 0)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.inputIdValue,
          class: "ml-3 bg-transparent h-7 block text-left text-lg font-medium text-gray-300"
        }, _toDisplayString(_ctx.inputPlaceholder), 9, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        style: {"display":"-webkit-flex!important","-webkit-appearance":"none"},
        id: _ctx.inputIdValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.valueState) = $event)),
        class: _normalizeClass(['bg-gray-200 dark:text-gray-400 text-bp-dark dark:bg-gray-700 min-h-full block w-full text-lg rounded-2xl focus:outline-none', _ctx.errorMessage.length > 0 ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500' : '', _ctx.iPadding]),
        name: _ctx.importFieldName,
        placeholder: _ctx.inputPlaceholder,
        required: _ctx.requiredField,
        type: _ctx.inputType,
        min: _ctx.min,
        max: _ctx.max,
        "aria-describedby": "value-error",
        "aria-invalid": "true"
      }, null, 10, _hoisted_3), [
        [_vModelDynamic, _ctx.valueState]
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_4, [
        _createVNode(_component_ExclamationCircleIcon, {
          "aria-hidden": "true",
          class: "h-5 w-5 text-red-500"
        })
      ], 512), [
        [_vShow, _ctx.errorMessage.length > 0]
      ])
    ]),
    _withDirectives(_createElementVNode("p", {
      id: "value-error",
      class: "mt-2 text-sm text-red-600"
    }, _toDisplayString(_ctx.errorMessage), 513), [
      [_vShow, _ctx.errorMessage.length > 0]
    ])
  ], 2))
}