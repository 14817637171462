import { toastController} from '@ionic/vue';
import * as icons from "ionicons/icons";
import i18n from '@/i18n';

export default function useToastMessage() {
	async function openToast(message= i18n.global.t(""), color = 'primary', positionToast: 'top' | 'bottom' | 'middle' = 'top', animated = true, duration = 2000000, icon: string | undefined = undefined, showCloseButton = false, customCssClass = 'toast-custom-class') {
		let buttons: any  = [];
		if (showCloseButton) {
			buttons = [
				{
					side: 'end',
					text: i18n.global.t(""),
					// text: t("toast.close"),
					role: 'cancel',
					icon: icons.close,
				}
			]
		}
		const toast = await toastController
			.create({
				message: message,
				color: color,
				position: positionToast,
				animated: animated,
				duration: duration,
				buttons: buttons,
				cssClass: customCssClass,
				icon: icon
			})
		return toast.present();
	}

	return {
		openToast
	}
}
