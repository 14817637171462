import {CartFactory} from '@/greeve/shop/cart/cart_factory.type';
import {CartItemFactory} from '@/greeve/shop/cart/item/cart_item_factory.type';
import {CustomerFactory} from '@/greeve/shop/customer/customer_factory.type';
import {ProductFactory} from '@/greeve/shop/product/product_factory.type';
import {
	CheckoutSessionFactory,
} from '@/greeve/shop/checkout/session/checkout_session_factory.type';
import {
	DiscountItemFactory
} from '@/greeve/shop/cart/discount/discount_item_factory.type';

export default function useCartFactory() {

	let cartFactory: CartFactory | null = null;
	const getCartFactory = (): CartFactory => {
		if (!cartFactory) {
			cartFactory = new CartFactory();
		}
		return cartFactory;
	};

	let cartItemFactory: CartItemFactory | null = null;

	const getCartItemFactory = (): CartItemFactory => {
		if (!cartItemFactory) {
			cartItemFactory = new CartItemFactory();
		}
		return cartItemFactory;
	};

	let discountItemFactory: DiscountItemFactory | null = null;

	const getDiscountItemFactory = (): DiscountItemFactory => {
		if (!discountItemFactory) {
			discountItemFactory = new DiscountItemFactory();
		}
		return discountItemFactory;
	};

	let customerFactory: CustomerFactory | null = null;

	const getCustomerFactory = (): CustomerFactory => {
		if (!customerFactory) {
			customerFactory = new CustomerFactory();
		}
		return customerFactory;
	};

	let productFactory: ProductFactory | null = null;

	const getProductFactory = (): ProductFactory => {
		if (!productFactory) {
			productFactory = new ProductFactory();
		}
		return productFactory;
	};

	let checkoutSessionFactory: CheckoutSessionFactory | null = null;

	const getCheckoutSessionFactory = (): CheckoutSessionFactory => {
		if (!checkoutSessionFactory) {
			checkoutSessionFactory = new CheckoutSessionFactory();
		}
		return checkoutSessionFactory;
	};

	return {
		getCartFactory,
		getCartItemFactory,
		getDiscountItemFactory,
		getCustomerFactory,
		getProductFactory,
		getCheckoutSessionFactory,
	};
}