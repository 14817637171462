import {PaginationMetaInterface} from "@/greeve/core/pagination_meta.interface";
import {JournalLine} from "@/greeve/shop/order/journal/journalLine/journal_line.type";

export class JournalLineList extends Array<JournalLine> {

	public paginationMetaData?: PaginationMetaInterface;

	constructor(items: Array<JournalLine> = []) {
		super();
		this.push(...items);
	}

	add(items: JournalLine) {
		this.push(items);
	}

	remove(index: number) {
		this.splice(index, 1);
	}

	removeAll() {
		const itemCount = this.length;
		this.splice(itemCount, 1);
	}

	getItem(index: number): JournalLine {
		return this[index];
	}

	getItemById(item_id: number): JournalLine | undefined {
		return this.find(({id}) => id === item_id);
	}

	enhanceMetaData(metaData: any) {
		this.paginationMetaData = metaData;
	}
}