<template>
	<ion-page>
		<ion-content>
			<main  :style="{ paddingTop: headerHeight + 'px' }" >
				<div>
					<BreadcrumbsHeader from="0" till="2"></BreadcrumbsHeader>
					<div class="mx-auto py-16 px-4 sm:py-4 sm:px-6 lg:max-w-7xl lg:px-4">
						<!-- Product -->
						<div class="lg:grid lg:grid-cols-7 lg:grid-rows-1 lg:gap-x-8 lg:gap-y-10 xl:gap-x-16">
							<!-- Product image -->
							<div class="lg:col-span-4 lg:row-end-1">
								<div class="aspect-w-4 h-96 aspect-h-3 overflow-hidden rounded-xl bg-gray-50">
									<img loading="lazy" :src="product.imageSrc" :alt="product.imageAlt" class="bg-gradient object-cover object-center" />
								</div>
							</div>

							<!-- Product details -->
							<div class="mx-auto mt-8 max-w-2xl md:mt-16 lg:col-span-3 lg:row-span-2 lg:row-end-2 lg:mt-0 lg:max-w-none">
								<div class="flex flex-col-reverse">
									<div class="mt-4">
										<h1 class="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{{ product.name }}</h1>
										<h2 id="information-heading" class="sr-only">{{ $t('productInformation') }}</h2>
										<p class="mt-2 text-sm text-gray-500">
											{{ $t('versionUpdated', {name: product.version.name}) }} <time :datetime="product.version.datetime">{{ product.version.date }}</time
										>)
										</p>
										<router-link to="/workflow/demo">
										<button class="flex px-4 py-1.5 my-4 mb-0 items-center text-center bg-gray-100 text-gray-500 rounded-xl" type="submit">
											<PlayIcon class="mx-auto text-gray-400 mr-1 h-6 w-6 "></PlayIcon>
											<div class="text-md">{{ $t('watchDemo') }}</div>
										</button>
										</router-link>
									</div>

									<div>
										<h3 class="sr-only">{{ $t('reviews') }}</h3>
										<div class="flex items-center">
											<StarIcon v-for="rating in [0, 1, 2, 3, 4]" :key="rating" :class="[reviews.average > rating ? 'text-yellow-400' : 'text-gray-300', 'h-5 w-5 flex-shrink-0']" aria-hidden="true" />
										</div>
										<p class="sr-only">{{ $t('outOf5Stars2', {average: reviews.average}) }}</p>
									</div>
								</div>

								<p class="mt-6 text-gray-500">{{ product.description }}</p>

								<div class="mt-8">
									<div class="flex items-center justify-between">
										<h2 class="text-sm font-medium text-gray-900 dark:text-gray-200">{{ $t('chooseYourNeededMinutes') }}</h2>
									</div>

									<RadioGroup v-model="selectedSize" class="mt-4">
										<RadioGroupLabel class="sr-only"> {{ $t('chooseATime') }} </RadioGroupLabel>
										<div class="grid grid-cols-3 gap-3 sm:grid-cols-6">
											<RadioGroupOption as="template" v-for="size in product.sizes" :key="size.name" :value="size" :disabled="!size.inStock" v-slot="{ active, checked }">
												<div :class="[size.inStock ? 'cursor-pointer focus:outline-none' : 'opacity-25 cursor-not-allowed', active ? 'outline-none' : '', checked ? 'bg-gray-100 ring-black ring-2 border-transparent font-semibold hover:bg-gray-100' : 'bg-white border-gray-200 text-gray-400 hover:bg-gray-50', 'border rounded-xl py-3 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1']">
													<RadioGroupLabel as="span">{{ size.name }}</RadioGroupLabel>
												</div>
											</RadioGroupOption>
										</div>
									</RadioGroup>
								</div>

								<div class="text-sm font-medium">
									<div class=" flex-auto mt-8 md:flex md:space-x-4 space-y-4 md:space-y-0">
										<button class="h-12 w-full px-6 font-semibold rounded-xl bg-black text-white" type="submit">
											{{ $t('buyNow') }}
										</button>
										<button @click="addProductToCart_by_Product(product)"  :aria-describedby="product.product_id" class="h-12 w-full px-6 font-semibold rounded-xl bg-gray-100" type="button">
											<span v-if="addToCartLoading" class=" mx-auto flex align-center justify-center content-center">
													<svg class="animate-spin mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
													<circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
													<path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
													</svg>
												<!--													Product added-->
												</span>
											<span v-else>{{ $t('addToCart') }}</span>
										</button>
									</div>
								</div>

								<div class="mt-10 border-t border-gray-200 pt-10">
									<h3 class="text-sm font-medium text-gray-900 dark:text-gray-200">{{ $t('highlights') }}</h3>
									<div class="prose prose-sm mt-4 text-gray-500">
										<ul role="list">
											<li v-for="highlight in product.highlights" :key="highlight">{{ highlight }}</li>
										</ul>
									</div>
								</div>

								<div class="mt-10 border-t border-gray-200 pt-10">
									<h3 class="text-sm font-medium text-gray-900 dark:text-gray-200">{{ $t('license') }}</h3>
									<p class="mt-4 text-sm text-gray-500">{{ license.summary }} <a :href="license.href" class="font-medium text-gr-primary hover:text-gr-primary2">{{ $t('readFullLicense') }}</a></p>
								</div>

								<div class="mt-10 border-t border-gray-200 pt-10">
									<h3 class="text-sm font-medium text-gray-900 dark:text-gray-200">{{ $t('share') }}</h3>
									<ul role="list" class="mt-4 flex items-center space-x-6">
										<li>
											<a href="#" class="flex h-6 w-6 items-center justify-center text-gray-400 hover:text-gray-500">
												<span class="sr-only">{{ $t('shareOnFacebook') }}</span>
												<svg class="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
													<path fill-rule="evenodd" d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z" clip-rule="evenodd" />
												</svg>
											</a>
										</li>
										<li>
											<a href="#" class="flex h-6 w-6 items-center justify-center text-gray-400 hover:text-gray-500">
												<span class="sr-only">{{ $t('shareOnInstagram') }}</span>
												<svg class="h-6 w-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
													<path fill-rule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clip-rule="evenodd" />
												</svg>
											</a>
										</li>
										<li>
											<a href="#" class="flex h-6 w-6 items-center justify-center text-gray-400 hover:text-gray-500">
												<span class="sr-only">{{ $t('shareOnTwitter') }}</span>
												<svg class="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
													<path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
												</svg>
											</a>
										</li>
									</ul>
								</div>
							</div>

							<div class="mx-auto mt-16 w-full max-w-2xl lg:col-span-4 lg:mt-0 lg:max-w-none">
								<TabGroup as="div">
									<div class="border-b border-gray-200">
										<TabList class="-mb-px flex sm:space-x-8">
											<Tab as="template" v-slot="{ selected }">
												<button :class="[selected ? 'rounded-xl bg-gray-100 text-gray-500 outline-none' : 'border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300', 'whitespace-nowrap border-b-2 py-2 px-4 sm:px-6 mb-6 text-sm font-medium']">{{ $t('customerReviews') }}</button>
											</Tab>
											<Tab as="template" v-slot="{ selected }">
												<button :class="[selected ? 'rounded-xl bg-gray-100 text-gray-500 outline-none' : 'border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300', 'whitespace-nowrap border-b-2 py-2 px-4 sm:px-6 mb-6 text-sm font-medium']">{{ $t('faq') }}</button>
											</Tab>
											<Tab as="template" v-slot="{ selected }">
												<button :class="[selected ? 'rounded-xl bg-gray-100 text-gray-500 outline-none' : 'border-transparent text-gray-700 hover:text-gray-800 hover:border-gray-300', 'whitespace-nowrap border-b-2 py-2 px-4 sm:px-6 mb-6 text-sm font-medium']">{{ $t('license') }}</button>
											</Tab>
										</TabList>
									</div>
									<TabPanels as="template">
										<TabPanel class="lg:mx-6 -mb-10">
											<h3 class="sr-only">{{ $t('customerReviews') }}</h3>

											<div v-for="(review, reviewIdx) in reviews.featured" :key="review.id" class="flex space-x-4 text-sm text-gray-500">

												<div :class="[reviewIdx === 0 ? '' : 'border-t border-gray-200', 'py-10']">
													<div class="flex"><div class="flex-none mt-1.5 mr-3">
														<img loading="lazy" :src="review.avatarSrc" alt="" class="h-10 w-10 rounded-full" />
													</div>
													<div><h3 class="font-medium text-gray-900 dark:text-gray-200">{{ review.author }}</h3>
													<p>
														<time :datetime="review.datetime">{{ review.date }}</time>
													</p>
													</div>
													</div>
													<div class="mt-4 flex items-center">
														<StarIcon v-for="rating in [0, 1, 2, 3, 4]" :key="rating" :class="[review.rating > rating ? 'text-yellow-400' : 'text-gray-300', 'h-5 w-5 flex-shrink-0']" aria-hidden="true" />
													</div>
													<p class="sr-only">{{ $t('outOf5Stars', {rating: review.rating}) }}</p>

													<div class="prose prose-sm mt-4 max-w-none text-gray-500" v-html="review.content" />
												</div>
											</div>
										</TabPanel>

										<TabPanel class="text-sm lg:mx-6 text-gray-500">
											<h3 class="sr-only">{{ $t('frequentlyAskedQuestions') }}</h3>

											<dl>
												<template v-for="faq in faqs" :key="faq.question">
													<dt class="mt-10 font-medium text-gray-900 dark:text-gray-200">{{ faq.question }}</dt>
													<dd class="prose prose-sm mt-2 max-w-none text-gray-500">
														<p>{{ faq.answer }}</p>
													</dd>
												</template>
											</dl>
										</TabPanel>

										<TabPanel class="pt-10 lg:mx-6 ">
											<h3 class="sr-only">{{ $t('license') }}</h3>

											<div class="prose prose-sm max-w-none text-gray-500" v-html="license.content" />
										</TabPanel>
									</TabPanels>
								</TabGroup>
							</div>
						</div>
					</div>
				</div>
			<div>
					<div class="md:mx-4 max-w-2xl py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
						<h2 class="text-2xl font-bold tracking-tight text-gray-900">{{ $t('customersAlsoPurchased') }}</h2>
						<div class="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
							<div class="group relative">
								<GreeveShopProduct></GreeveShopProduct>
							</div>
						</div>
					</div>
				</div>
			<FooterBar class="mt-12"></FooterBar>
			</main>
		</ion-content>
	</ion-page>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue';
import {IonPage, IonContent} from '@ionic/vue';
import FooterBar from "@/components/positions/FooterBar.vue";
import { StarIcon, PlayIcon } from '@heroicons/vue/20/solid'
import { Tab, TabGroup, TabList, TabPanel, TabPanels, RadioGroup, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'
import BreadcrumbsHeader from "@/components/navigations/BreadcrumbsHeader.vue";
import GreeveShopProduct from "@/components/shop/product/GreeveShopProduct.vue";
import {useHeaderHeight} from "@/composable/global/useHeaderHeight";

export default defineComponent({
	name: 'ShopProductDetail',
	components: {
		GreeveShopProduct,
		BreadcrumbsHeader,
		FooterBar, IonContent, PlayIcon, IonPage, StarIcon, Tab, TabGroup, TabList, TabPanel, TabPanels, RadioGroup, RadioGroupLabel, RadioGroupOption
	},
	setup() {
		const { headerHeight } = useHeaderHeight();
		const products = [
			{
				id: 1,
				name: 'Basic Tee',
				href: '#',
				imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
				imageAlt: "Front of men's Basic Tee in black.",
				price: '$35',
				color: 'Black',
			},
			// More products...
		]
		const product = {
			name: 'Greeve Whisper Sync',
			version: { name: '1.0', date: 'June 5, 2021', datetime: '2021-06-05' },
			price: '1.15',
			description:
				'The Application UI Icon Pack comes with over 200 icons in 3 styles: outline, filled, and branded. This playful icon pack is tailored for complex application user interfaces with a friendly and legible look.',
			highlights: [
				'200+ SVG icons in 3 unique styles',
				'Compatible with Figma, Sketch, and Adobe XD',
				'Drawn on 24 x 24 pixel grid',
			],
			imageSrc: '',
			imageAlt: '',
			sizes: [
				{ name: '5', inStock: true },
				{ name: '10', inStock: true },
				{ name: '20', inStock: true },
				{ name: '60', inStock: true },
				{ name: '120', inStock: true },
				{ name: '240', inStock: true },
			],
		}

		const selectedSize = ref(product.sizes[2])

		const reviews = {
			average: 4,
			featured: [
				{
					id: 1,
					rating: 5,
					content: `
        <p>This icon pack is just what I need for my latest project. There's an icon for just about anything I could ever need. Love the playful look!</p>
      `,
					date: 'July 16, 2021',
					datetime: '2021-07-16',
					author: 'Emily Selman',
					avatarSrc:
						'https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80',
				},
				{
					id: 2,
					rating: 5,
					content: `
        <p>Blown away by how polished this icon pack is. Everything looks so consistent and each SVG is optimized out of the box so I can use it directly with confidence. It would take me several hours to create a single icon this good, so it's a steal at this price.</p>
      `,
					date: 'July 12, 2021',
					datetime: '2021-07-12',
					author: 'Hector Gibbons',
					avatarSrc:
						'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80',
				},
				// More reviews...
			],
		}
		const faqs = [
			{
				question: 'What format are these icons?',
				answer:
					'The icons are in SVG (Scalable Vector Graphic) format. They can be imported into your design tool of choice and used directly in code.',
			},
			{
				question: 'Can I use the icons at different sizes?',
				answer:
					"Yes. The icons are drawn on a 24 x 24 pixel grid, but the icons can be scaled to different sizes as needed. We don't recommend going smaller than 20 x 20 or larger than 64 x 64 to retain legibility and visual balance.",
			},
			// More FAQs...
		]

		const license = {
			href: '#',
			summary:
				'For personal and professional use. You cannot resell or redistribute these icons in their original or modified state.',
			content: `
    <h4>Overview</h4>

    <p>For personal and professional use. You cannot resell or redistribute these icons in their original or modified state.</p>

    <ul role="list">
    <li>You're allowed to use the icons in unlimited projects.</li>
    <li>Attribution is not required to use the icons.</li>
    </ul>

    <h4>What you can do with it</h4>

    <ul role="list">
    <li>Use them freely in your personal and professional work.</li>
    <li>Make them your own. Change the colors to suit your project or brand.</li>
    </ul>

    <h4>What you can't do with it</h4>

    <ul role="list">
    <li>Don't be greedy. Selling or distributing these icons in their original or modified state is prohibited.</li>
    <li>Don't be evil. These icons cannot be used on websites or applications that promote illegal or immoral beliefs or activities.</li>
    </ul>
  `,
		}

		return {product, reviews, faqs, license, products, selectedSize, headerHeight
		}
	}
});

</script>
<style lang="scss">
.bg-gradient {
  background: linear-gradient(90deg, rgba(246,0,0,1) 0%, rgba(255,211,0,1) 34.31%, rgba(255,118,0,1) 100%);
}
</style>

