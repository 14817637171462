import {
	FlowQueueInterface,
	FlowQueueState,
	FlowQueueType,
} from '@/greeve/flow/queue/flow_queue.interface';
import {
	FlowQueueJournalList,
} from '@/greeve/flow/queue/journal/flow_queue_journal_list.type';
import {
	FlowQueueProcessList,
} from '@/greeve/flow/queue/process/flow_queue_process_list.type';
import {AbstractFlow} from '@/greeve/flow/abstract_flow.type';
import useFlow from '@/composable/greeve/useFlow';
import useUser from '@/composable/greeve/useUser';
import {
	QuotaTransactionType,
} from '@/greeve/user/quota/transaction/quota_transaction.interface';
import i18n from '@/i18n';

export abstract class AbstractFlowQueue implements FlowQueueInterface {

	id: number;
	uuid: string;
	flowId: number;
	type: FlowQueueType;
	state: FlowQueueState;
	user_id?: number|null;
	next_execution?: Date;
	flow_queue_processes?: FlowQueueProcessList|null;
	flow_queue_journals?: FlowQueueJournalList|null;
	flow_quota_amount?: number|null;
	parent_id?: number|null;
	reference?: string|null;
	name?: string|null;
	description?: string|null;
	result?: any;
	data?: any;
	created_at?: Date;
	updated_at?: Date;

	private flow?: AbstractFlow;

	constructor(
			id: number, uuid: string, flowId: number, type: FlowQueueType, state: FlowQueueState, user_id?: number|null, next_execution?: Date,
			flow_queue_processes?: FlowQueueProcessList|null, flow_queue_journals?: FlowQueueJournalList|null, flow_quota_amount?: number|null, parent_id?: number|null, reference?: string | undefined,
			name?: string | undefined, description?: string|null, result: any = null,  data: any = null, created_at?: Date, updated_at?: Date) {
		this.id = id;
		this.uuid = uuid;
		this.flowId = flowId;
		this.user_id = user_id;
		this.next_execution = next_execution;
		this.flow_queue_processes = flow_queue_processes;
		this.flow_queue_journals = flow_queue_journals;
		this.flow_quota_amount = flow_quota_amount;
		this.parent_id = parent_id;
		this.type = type;
		this.state = state;
		this.reference = reference;
		this.name = name;
		this.description = description;
		this.result = result;
		this.data = data;
		this.created_at = created_at;
		this.updated_at = updated_at;
	}

	getFlow(): AbstractFlow|undefined
	{
		if (this.flow) {
			return this.flow;
		}
		if (this.flowId) {
			const flow = useFlow().getFlowById(this.flowId);
			if (flow) {
				return flow;
				// this.order = order; //TODO CHECK vuex mutation error
			}
			return this.flow;
		}

		return;
	}

	getDescriptionSummary(asHtml = false): string
	{
		let summary = '';

		if (this.name) {
			summary += this.name;
		}

		if (summary && this.description) {
			if (asHtml) {
				summary += '<br>';
			} else {
				summary += ', ';
			}
		}

		if (this.description) {
			summary += this.description;
		}

		return summary;
	}

	getCreditAmount(): number
	{
		if (this.flow_quota_amount) {
			return this.flow_quota_amount;
		} else {
			const quotaList = useUser().getQuotas.value;
			let amount = 0;

			if (!this.reference) {
				return amount;
			}

			quotaList.forEach((quota) => {
				if (quota.quota_transactions) {
					quota.quota_transactions.filter((quotaTransaction) => {
						return quotaTransaction.reference === this.reference
					});

					quota.quota_transactions.forEach((quotaTransaction) => {
						if (quotaTransaction.type !== QuotaTransactionType.QUOTA_TRANSACTION_TYPE_CANCELED && quotaTransaction.reference === this.reference) {
							amount += quotaTransaction.amount;
						}
					})
				}
			})
			return amount;
		}
	}

	getCreateDateFormatted(withTime = true): string
	{
		let date = '';
		if (!this.created_at) {
			return date;
		}

		date = this.created_at.toLocaleDateString();

		if (withTime) {
			date = date + ' ' + this.created_at.toLocaleTimeString([], {timeStyle: 'short'})
		}
		return date;
	}

	getUpdateDateFormatted(withTime = true): string
	{
		let date = '';
		if (!this.updated_at) {
			return date;
		}

		date = this.updated_at.toLocaleDateString();

		if (withTime) {
			date = date + ' ' + this.updated_at.toLocaleTimeString([], {timeStyle: 'short'})
		}
		return date;
	}


	getStateLabel(): string
	{
		if (this.state) {
			return i18n.global.t('flow.queue.state.'+this.state+'.label');
		}
		return i18n.global.t('flow.queue.state.undefined.label');
	}

	getStateLabelColor(): string
	{
		switch (this.state)
		{
			case FlowQueueState.DONE:
				return 'green';
			case FlowQueueState.ERROR:
			case FlowQueueState.DELETED:
				return 'red';
			case FlowQueueState.IN_PROGRESS:
				return 'yellow';
			case FlowQueueState.NEW:
			default:
				return 'gray';
		}
	}

	isQueueFinished(): boolean {
		return this.state === FlowQueueState.DONE;
	}

	isQueueRunning(): boolean {
		return this.state === FlowQueueState.IN_PROGRESS;
	}

	isQueueInErrorState(): boolean {
		return this.state === FlowQueueState.ERROR;
	}

	isQueueNew(): boolean {
		return this.state === FlowQueueState.NEW;
	}
}