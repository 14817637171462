<template>
	<div class="sm:overflow-hidden rounded-xl nav backdrop-filter backdrop-blur-3xl">
		<div class="nav rounded-t-xl space-y-6 py-6 px-4 sm:p-6">
			<div class="space-y-2 sm:space-y-4">
				<section aria-labelledby="payment-details-heading">

					<div v-if="tabs">
						<div class="flex md:space-x-32 flex-wrap">
							<div class="flex-1 sm:flex-auto">
								<div role="tablist" class="tabs tabs-boxed">
									<a v-for="tab in tabs" :key="tab.name" @click="changeSelectedTab(tab)"
										 :class="[tab.current ? 'tab tab-active' : 'tab']" role="tab">{{ tab.name }}</a>
								</div>

								<div v-if="selectedQuota">
									<h3 class="text-base font-semibold leading-6 text-gray-900 dark:text-gray-500 pt-2">{{
											selectedQuota.getQuotaName()
										}}</h3>
									<dl class="my-5 grid grid-cols-1 gap-5 sm:grid-cols-1">
										<div class="overflow-hidden rounded-xl bg-gray-100 dark:bg-gr-darker px-4 py-5 sm:p-6">
											<dt class="truncate text-sm font-medium text-gray-500">{{ $t('availableCredits') }}</dt>
											<dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900 dark:text-gray-500">
												{{ selectedQuota.available_quota }}
											</dd>
										</div>
									</dl>
								</div>
								<div v-else>{{ $t('shop.quota.empty_credits') }}</div>
							</div>
							<div class="flex-1 sm:flex-auto">
								<greeve-one-time-products :hide-bottom-padding="true"></greeve-one-time-products>
							</div>
						</div>

						<div>
							<List v-if="selectedQuota?.quota_transactions"
										@paginatePage="fetchMoreTransactions"
										:list-data="selectedQuota.quota_transactions?.getQuotaTransaction_ListItems(false)"
										:pagination-data="selectedQuota.quota_transactions?.paginationMetaData"></List>
							<div v-else><h1 class="text-lg font-medium leading-6 text-gray-900 dark:text-gray-300">{{
									$t('shop.quota.empty_credits')
								}}</h1>
								<p class="mt-1 text-sm text-gray-500 mb-4">{{ $t('addPlan') }}</p>

								<router-link to="/pricing">
									<button aria-describedby="tier-scale"
													class="inline-flex justify-center rounded-xl bg-gr-primary hover:bg-gr-primary2 py-1.5 px-10 text-sm font-semibold text-white shadow-sm focus:outline-none">
										{{ $t('viewPlans') }}
									</button>
								</router-link>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	</div>


</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from 'vue';
import useUser from '@/composable/greeve/useUser';
import List from '@/components/core/List.vue';
import GreeveOneTimeProducts from '@/components/shop/product/GreeveOneTimeProducts.vue';
import {AbstractQuota} from '@/greeve/user/quota/abstract_quota.type';
import {useRoute} from 'vue-router';
import useCustomStore from '@/composable/custom/useCustomStore';

export default defineComponent({
	name: 'CreditManagement',
	components: {
		GreeveOneTimeProducts,
		List,
	},
	props: {},
	emits: [],
	setup() {
		const { setIsGlobalLoading } = useCustomStore();
		const {getQuotas, initQuotas, getQuotaTransactions} = useUser();
		const selectedQuota = ref<AbstractQuota>();
		const tabs = ref<any[]>([]);
		const route = useRoute();

		async function changeSelectedTab(selectedTab: any) {
			tabs.value.forEach((tab) => {
				if (tab.type === selectedTab.type) {
					tab.current = true;
					const searchURL = new URL(window.location.href);
					searchURL.searchParams.set('tab', selectedTab.type);
					window.history.pushState({}, '', searchURL);
				} else {
					tab.current = false;
				}
			});
			selectedQuota.value = getQuotas.value.getAvailableQuotaByType(selectedTab.type);
			if (selectedQuota.value?.uuid) {
				getQuotaTransactions(selectedQuota.value?.uuid)
			}
		}

		function initTabs() {
			let activeTabInitialized = false;
			let current = false;
			const tabParam = route?.query.tab ?? null;
			getQuotas.value.forEach((quota) => {
				if ((tabParam === null && !activeTabInitialized) || (tabParam === quota.type && !activeTabInitialized)) {
					current = true;
					activeTabInitialized = true;
					selectedQuota.value = quota;
				}
				const tab = {type: quota.type, name: quota.getQuotaName(), current: current};
				current = false;
				tabs.value.push(tab);
			});
		}

		async function fetchMoreTransactions(page: number) {
			if (selectedQuota.value?.uuid) {
				setIsGlobalLoading(true);
				setTimeout(() => {
					setIsGlobalLoading(false);
				}, 10000)
				getQuotaTransactions(selectedQuota.value?.uuid, page).then(() => {
					setIsGlobalLoading(false);
				})
			}
		}

		onMounted(() => {
			setIsGlobalLoading(true);
			setTimeout(() => {
				setIsGlobalLoading(false);
			}, 10000)
			initQuotas(true).then(() => {
				initTabs();
				if (selectedQuota.value?.uuid) {
					getQuotaTransactions(selectedQuota.value?.uuid).then(() => {
						setIsGlobalLoading(false);
					})
				} else {
					setIsGlobalLoading(false);
				}
			});
		});

		return {
			getQuotas,
			fetchMoreTransactions,
			tabs,
			changeSelectedTab,
			selectedQuota,
		};
	},
});
</script>

<style scoped>
.tabs-boxed .tab-active:not(.tab-disabled):not([disabled]), .tabs-boxed :is(input:checked) {
	--tw-bg-opacity: 1;
	background-color: var(--primary, oklch(var(--p)/var(--tw-bg-opacity)));
	--tw-text-opacity: 1;
	color: var(--fallback-pc, oklch(var(--pc)/var(--tw-text-opacity)));
}
</style>
