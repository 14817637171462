import {
	SearchItemDefault,
} from '@/greeve/search/item/type/search_item_default.type';
import useSystem from '@/composable/core/useSystem';

export class SearchItemTranscribeAudio extends SearchItemDefault {

	public hasCustomOutput(): boolean {
		return true;
	}

	public getRawText(maxChars: number | undefined = undefined): string {
		if (maxChars) {
			return this.response?.text?.substring(0, maxChars) || '';
		}
		return this.response?.text || '';
	}

	public getContent() {
		let content: string | object = '';
		if (this.response && this.response.text) {
			content = this.response.text;
		} else if (useSystem().isJson(this.response)) {
			content = JSON.parse(this.response)?.text || '';
		} else {
			content = this.response;
		}
		if (typeof content !== 'string') {
			content = content.toString();
		}
		if (content) {
			const sanitizedContent = this.sanitizeExceptCodeTags(content);
			if (!sanitizedContent) {
				return sanitizedContent;
			}
			this.getMarkdownParser().cssNamespace = 'chat-result whitespace-pre-wrap';
			return this.getMarkdownParser().markdownToHtml(sanitizedContent);
		}
		return super.getContent();
	}

	addContent(content: any) {
		if (typeof content !== 'string') {
			content = content.toString();
		}
		if (content) {
			const sanitizedContent = this.sanitizeExceptCodeTags(content);
			if (!sanitizedContent) {
				super.addContent(content);
				return;
			}
			this.getMarkdownParser().cssNamespace = 'transcribe-audio-result whitespace-pre-wrap';
			this.response = this.getMarkdownParser().markdownToHtml(sanitizedContent);
			return;
		}
		super.addContent(content);
	}

	getDuration(): number {
		if (this.response?.duration) {
			return this.response?.duration;
		}
		return 0;
	}

	getLanguage(): string {
		if (this.response?.language) {
			return this.response?.language;
		}
		return '';
	}

	// getStartTime(): number {
	//
	// }

	public getSourceAudioFile(): string | undefined {
		return this.getRequest()?.file;
	}
}