import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full relative z-10 sticky -mt-2 md:mt-0 my-4 mb-2 mb-2 sm:mb-4 md:mb-4 p-0 rounded-xl nav backdrop-filter backdrop-blur-3xl"
}
const _hoisted_2 = { class: "align-middle nav rounded-xl rounded-2xl p-2 sm:p-4 pl-3 sm:pl-5 text-md text-gray-900 dark:text-gray-400" }
const _hoisted_3 = {
  key: 0,
  class: "float-right ml-2 mt-0.5"
}
const _hoisted_4 = { class: "flex select-none" }
const _hoisted_5 = { class: "flex items-center" }
const _hoisted_6 = ["src", "alt"]
const _hoisted_7 = { class: "mr-2" }
const _hoisted_8 = { class: "text-lg font-extrabold leading-6" }
const _hoisted_9 = { class: "mt-1 text-sm md:text-base line-clamp-3" }

import {onMounted, ref, computed} from 'vue';
import {XMarkIcon} from '@heroicons/vue/24/outline';
import useCustomStore from '@/composable/custom/useCustomStore';
import useUser from '@/composable/greeve/useUser';


export default /*@__PURE__*/_defineComponent({
  __name: 'InfoCard',
  props: {
	img: {
		type: String,
		default: '',
	},
	title: {
		type: String,
		default: 'title',
	},
	info: {
		type: String,
		default: 'info',
	},
	allowToClose: {
		type: Boolean,
		default: true,
	},
	infoKey: {
		type: String,
		required: true,
	},
	allowedToHidePermanently: {
		type: Boolean,
		default: false,
	},
},
  setup(__props) {

const props = __props;

const {getInfoBanner_by_Id, addInfoBanner, updateInfoBannerState} = useCustomStore();
const {user, pushProfileOptions} = useUser();

const isOpen = ref(false);

function closeModal() {
	updateInfoBannerState(props.infoKey, false);
	isOpen.value = false;
	if (props.allowedToHidePermanently) {
		pushBannerVisibleState();
	}
}

const getOptionKey = () => {
	return 'banner-'+props.infoKey;
}

const isCardVisible = () => computed(() => {
	if (!user.value.profile?.getOptions()) {
		return true;
	}
	return user.value.profile?.hasOption(getOptionKey(), true);
});

const pushBannerVisibleState = () => {
	let options = user.value.profile?.getOptions();
	if (!options) {
		options = [];
	}

	options[(getOptionKey())] = isOpen.value;
	pushProfileOptions(options);
}

onMounted(() => {
	const infoBanner = getInfoBanner_by_Id(props.infoKey);
	let showBanner;
	if (!infoBanner) {
		addInfoBanner(props.infoKey);
		showBanner = true;
	} else {
		showBanner = infoBanner.active ?? true;
	}

	if (!showBanner) {
		isOpen.value = false;
		return;
	}

	isOpen.value = showBanner && isCardVisible().value;
});

return (_ctx: any,_cache: any) => {
  return (isOpen.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (__props.allowToClose)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_unref(XMarkIcon), {
                  class: "w-5 cursor-pointer",
                  onClick: closeModal
                })
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("img", {
                loading: "lazy",
                src: __props.img,
                class: "ml-0 mr-2 h-20 w-20 lg:h-16 lg:w-16 xl:h-15 xl:w-15",
                alt: __props.title
              }, null, 8, _hoisted_6),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("h3", _hoisted_8, _toDisplayString(__props.title), 1),
                _createElementVNode("p", _hoisted_9, _toDisplayString(__props.info), 1)
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})