import {CountryInterface} from "@/greeve/core/country/country.interface";
import {CurrencyInterface} from "@/greeve/core/country/currency.interface";
import {Currency} from "@/greeve/core/country/currency.type";

export class Country implements CountryInterface {
	code: string;
	name: string;
	native: string;
	continent: string;
	continent_code: string;
	phone: string;
	capital: string;
	currency: CurrencyInterface;
	languages: Array<string>;
	active: boolean;
	data?: any;
	created_at?: Date;
	updated_at?: Date;


	constructor(code: string, name: string, native: string, continent: string, continent_code: string, phone: string, capital: string, currency: Currency, languages: Array<string>, active: boolean, created_at: Date, updated_at: Date, data: any = null) {
		this.code = code;
		this.name = name;
		this.native = native;
		this.continent = continent;
		this.continent_code = continent_code;
		this.phone = phone;
		this.capital = capital;
		this.currency = currency;
		this.languages = languages;
		this.active = active;
		this.data = data;
		this.created_at = created_at;
		this.updated_at = updated_at;
	}

	getCountryImagePath(): string {
		return 'https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/images/landing/flags/1x1/'+this.code+'.svg';
	}
}