<template>
	<div class="space-y-2 sm:space-y-4">
		<!--	<form action="#" method="POST">-->
		<!--		<div class=" sm:overflow-hidden rounded-xl ">-->
		<!--			<div class="space-y-6 nav backdrop-filter backdrop-blur-3xl py-6 px-4 sm:p-6">-->
		<!--				<div>-->
		<!--					<h3 class="text-lg font-medium leading-6 text-gray-900">Profile</h3>-->
		<!--					<p class="mt-1 text-sm text-gray-500">This information will be displayed-->
		<!--						publicly so be careful what you share.</p>-->
		<!--				</div>-->
		<!--				<div class="grid grid-cols-3 gap-6">-->
		<!--					<div class="col-span-3 sm:col-span-2">-->
		<!--						<label for="Username"-->
		<!--							   class="block text-sm font-medium text-gray-700">Username</label>-->
		<!--						<div class="mt-1 mb-2 flex rounded-xl shadow-sm">-->
		<!--							<span class="inline-flex items-center rounded-l-xl py-2 border-2 border-r-0 border-gray-200 bg-gray-50 px-3 text-gray-500 sm:text-sm">greeve.ai/</span>-->
		<!--							<input type="text" name="username" id="username" autocomplete="username"-->
		<!--								   class="border-gray-200 border-2 block w-full min-w-0 flex-grow rounded-none rounded-r-xl border-gray-200 sm:text-sm"/>-->
		<!--						</div>-->
		<!--					</div>-->

		<!--					<div class="col-span-3">-->
		<!--						<label for="about"-->
		<!--							   class="block text-sm font-medium text-gray-700">About</label>-->
		<!--						<div class="mt-1 mb-2" id="about">-->
		<!--												<textarea name="about" rows="3"-->
		<!--														  class="mt-1 p-2 block border-gray-200 border-2 w-full rounded-xl border-gray-200 shadow-sm sm:text-sm"-->
		<!--														  placeholder="Brief description for your profile."/>-->
		<!--						</div>-->
		<!--					</div>-->

		<!--					<div class="col-span-3">-->
		<!--						<label class="block text-sm font-medium text-gray-700">Photo</label>-->
		<!--						<div class="mt-1 flex items-center">-->
		<!--                  <span class="inline-block h-12 w-12 overflow-hidden rounded-full bg-gray-100">-->
		<!--                    <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">-->
		<!--                      <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"/>-->
		<!--                    </svg>-->
		<!--                  </span>-->
		<!--							<button type="button"-->
		<!--									class="ml-5 rounded-xl border-2 border-gray-200 bg-gray-50 py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2">-->
		<!--								Change-->
		<!--							</button>-->
		<!--						</div>-->
		<!--					</div>-->

		<!--					<div class="col-span-3">-->
		<!--						<label class="block text-sm font-medium text-gray-700">Cover photo</label>-->
		<!--						<div class="mt-1 flex justify-center rounded-xl border-2 border-dashed border-gray-200 px-6 pt-5 pb-6">-->
		<!--							<div class="space-y-1 text-center">-->
		<!--								<svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor"-->
		<!--									 fill="none" viewBox="0 0 48 48" aria-hidden="true">-->
		<!--									<path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"-->
		<!--										  stroke-width="2" stroke-linecap="round"-->
		<!--										  stroke-linejoin="round"/>-->
		<!--								</svg>-->
		<!--								<div class="flex text-sm text-gray-600">-->
		<!--									<label for="file-upload"-->
		<!--										   class="relative cursor-pointer rounded-xl font-medium text-gr-primary focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500">-->
		<!--										<span>Upload a file</span>-->
		<!--										<input id="file-upload" name="file-upload" type="file"-->
		<!--											   class="sr-only"/>-->
		<!--									</label>-->
		<!--									<p class="pl-1">or drag and drop</p>-->
		<!--								</div>-->
		<!--								<p class="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>-->
		<!--							</div>-->
		<!--						</div>-->
		<!--					</div>-->
		<!--				</div>-->
		<!--			</div>-->
		<!--			<div class="nav  backdrop-filter backdrop-blur-3xl px-4 py-3 text-right sm:px-6">-->
		<!--				<button type="submit"-->
		<!--						class="inline-flex justify-center rounded-xl bg-black py-1.5 px-10 text-sm font-semibold text-white shadow-sm focus:outline-none">-->
		<!--					Save-->
		<!--				</button>-->
		<!--			</div>-->

		<!--		</div>-->
		<!--	</form>-->

		<form @submit.prevent="updateUserInformation" id="profile">
			<div class=" sm:overflow-hidden rounded-xl nav backdrop-filter backdrop-blur-3xl">
				<div class="space-y-6 py-6 px-4 sm:p-6 rounded-t-xl nav">
					<div>
						<h3 class="text-lg font-medium leading-6 text-gray-900 dark:text-gray-500">{{
								$t('personalInformation')
							}}</h3>
						<p class="mt-1 text-sm text-gray-600">{{ $t('useAPermanentAddressWhereYouCanRecieveMail') }}</p>
					</div>

					<div class="grid grid-cols-6 gap-6">
						<div class="col-span-6 sm:col-span-3">
							<label for="first-name" class="block text-sm font-medium text-gray-700 dark:text-gray-600">{{
									$t('firstName')
								}}</label>
							<input type="text" name="first-name" id="first-name" v-model="userInformation.firstName"
										 autocomplete="given-name"
										 class="mt-1 block w-full rounded-xl border-2 border-gray-200 dark:border-gr-darker py-2 px-3 shadow-sm  focus:outline-none sm:text-sm"/>
						</div>
						<div class="col-span-6 sm:col-span-3">
							<label for="last-name" class="block text-sm font-medium text-gray-700 dark:text-gray-600">{{
									$t('lastName')
								}}</label>
							<input type="text" name="last-name" id="last-name" v-model="userInformation.lastName"
										 autocomplete="family-name"
										 class="mt-1 block w-full rounded-xl border-2 border-gray-200 dark:border-gr-darker py-2 px-3 shadow-sm  focus:outline-none sm:text-sm"/>
						</div>
						<!--					<div class="col-span-6 sm:col-span-4">-->
						<!--						<label for="email-address" class="block text-sm font-medium text-gray-700 dark:text-gray-600">Email-->
						<!--							address</label>-->
						<!--						<input type="text" name="email-address" id="email-address"-->
						<!--							   autocomplete="email"-->
						<!--							   class="mt-1 block w-full rounded-xl border-2 border-gray-200 dark:border-gr-darker py-2 px-3 shadow-sm  focus:outline-none sm:text-sm"/>-->
						<!--					</div>-->
					</div>
					<div class="grid grid-cols-6 gap-6">
						<div class="col-span-6 sm:col-span-3">
							<label for="email" class="block text-sm font-medium text-gray-700 dark:text-gray-600">{{
									$t('emailTs')
								}}</label>
							<input type="email" name="email" id="email" v-model="userInformation.email"
										 autocomplete="email" readonly disabled
										 class="mt-1 block w-full rounded-xl border-2 border-gray-200 dark:border-gr-darker py-2 px-3 shadow-sm  focus:outline-none sm:text-sm disabled:opacity-50"/>
						</div>
					</div>
				</div>
				<div class="px-4 py-3 text-right rounded-b-xl nav sm:px-6">
					<button type="submit"
									class="inline-flex justify-center rounded-xl bg-black dark:bg-gr-darker py-1.5 px-10 text-sm font-semibold text-white shadow-sm focus:outline-none">
						{{ $t('save') }}
					</button>
				</div>
			</div>
		</form>

		<div class=" sm:overflow-hidden rounded-xl nav backdrop-filter backdrop-blur-3xl" id="language">
			<div class="space-y-6 py-6 px-4 sm:p-6 rounded-t-xl nav">
				<div>
					<h3 class="text-lg font-medium leading-6 text-gray-900 dark:text-gray-500">{{
							$t('language.title')
						}}</h3>
					<p class="mt-1 text-sm text-gray-600">{{ $t('language.description') }}</p>
				</div>
				<div class="grid grid-cols-12">
					<div class="col-span-12">
						<LanguageSelector :show-full-name="true" @on-change-language="updateUserLanguage"></LanguageSelector>
					</div>
				</div>
			</div>
		</div>

		<!--	<form action="#" method="POST">-->
		<!--		<div class=" sm:overflow-hidden rounded-xl ">-->
		<!--			<div class="space-y-6 nav  backdrop-filter backdrop-blur-3xl  py-6 px-4 sm:p-6">-->
		<!--				<div>-->
		<!--					<h3 class="text-lg font-medium leading-6 text-gray-900">Notifications</h3>-->
		<!--					<p class="mt-1 text-sm text-gray-500">Provide basic informtion about the job. Be-->
		<!--						specific with the job title.</p>-->
		<!--				</div>-->

		<!--				<fieldset>-->
		<!--					<legend class="text-base font-medium text-gray-900">By Email</legend>-->
		<!--					<div class="mt-4 space-y-4">-->
		<!--						<div class="flex items-start">-->
		<!--							<div class="flex h-5 items-center">-->
		<!--								<input id="comments" name="comments" type="checkbox"-->
		<!--									   class="h-4 w-4 rounded border-gray-200 text-indigo-600"/>-->
		<!--							</div>-->
		<!--							<div class="ml-3 text-sm">-->
		<!--								<label for="comments"-->
		<!--									   class="font-medium text-gray-700">Comments</label>-->
		<!--								<p class="text-gray-500">Get notified when someones posts a comment-->
		<!--									on a posting.</p>-->
		<!--							</div>-->
		<!--						</div>-->
		<!--						<div>-->
		<!--							<div class="flex items-start">-->
		<!--								<div class="flex h-5 items-center">-->
		<!--									<input id="candidates" name="candidates" type="checkbox"-->
		<!--										   class="h-4 w-4 rounded border-gray-200 text-indigo-600"/>-->
		<!--								</div>-->
		<!--								<div class="ml-3 text-sm">-->
		<!--									<label for="candidates" class="font-medium text-gray-700">Candidates</label>-->
		<!--									<p class="text-gray-500">Get notified when a candidate applies-->
		<!--										for a job.</p>-->
		<!--								</div>-->
		<!--							</div>-->
		<!--						</div>-->
		<!--						<div>-->
		<!--							<div class="flex items-start">-->
		<!--								<div class="flex h-5 items-center">-->
		<!--									<input id="offers" name="offers" type="checkbox"-->
		<!--										   class="h-4 w-4 rounded border-gray-200 text-indigo-600"/>-->
		<!--								</div>-->
		<!--								<div class="ml-3 text-sm">-->
		<!--									<label for="offers"-->
		<!--										   class="font-medium text-gray-700">Offers</label>-->
		<!--									<p class="text-gray-500">Get notified when a candidate accepts-->
		<!--										or rejects an offer.</p>-->
		<!--								</div>-->
		<!--							</div>-->
		<!--						</div>-->
		<!--					</div>-->
		<!--				</fieldset>-->
		<!--				<fieldset class="mt-6">-->
		<!--					<legend class="text-base font-medium text-gray-900">Push Notifications</legend>-->
		<!--					<p class="text-sm text-gray-500">These are delivered via SMS to your mobile-->
		<!--						phone.</p>-->
		<!--					<div class="mt-4 space-y-4">-->
		<!--						<div class="flex items-center">-->
		<!--							<input id="push-everything" name="push-notifications" type="radio"-->
		<!--								   class="h-4 w-4 border-gray-200 text-indigo-600"/>-->
		<!--							<label for="push-everything" class="ml-3">-->
		<!--								<span class="block text-sm font-medium text-gray-700">Everything</span>-->
		<!--							</label>-->
		<!--						</div>-->
		<!--						<div class="flex items-center">-->
		<!--							<input id="push-email" name="push-notifications" type="radio"-->
		<!--								   class="h-4 w-4 border-gray-200 text-indigo-600"/>-->
		<!--							<label for="push-email" class="ml-3">-->
		<!--								<span class="block text-sm font-medium text-gray-700">Same as email</span>-->
		<!--							</label>-->
		<!--						</div>-->
		<!--						<div class="flex items-center">-->
		<!--							<input id="push-nothing" name="push-notifications" type="radio"-->
		<!--								   class="h-4 w-4 border-gray-200 text-indigo-600"/>-->
		<!--							<label for="push-nothing" class="ml-3">-->
		<!--								<span class="block text-sm font-medium text-gray-700">No push notifications</span>-->
		<!--							</label>-->
		<!--						</div>-->
		<!--					</div>-->
		<!--				</fieldset>-->
		<!--			</div>-->
		<!--			<div class="nav  backdrop-filter backdrop-blur-3xl px-4 py-3 text-right sm:px-6">-->
		<!--				<button type="submit"-->
		<!--						class="inline-flex justify-center rounded-xl bg-black py-1.5 px-10 text-sm font-semibold text-white shadow-sm focus:outline-none">-->
		<!--					Save-->
		<!--				</button>-->
		<!--			</div>-->
		<!--		</div>-->
		<!--	</form>-->

		<div>
			<div class=" sm:overflow-hidden rounded-xl nav backdrop-filter backdrop-blur-3xl">
				<div class="space-y-6 py-6 px-4 sm:p-6">
					<div>
						<h3 class="text-lg font-medium leading-6 text-gray-900 dark:text-gray-500">{{
								$t('shop.checkout.login.title')
							}}</h3>
						<p class="mt-1 text-sm text-gray-500">{{
								$t('downloadYourLinkedDataDeleteYourAccountOrCancelYou')
							}}</p>
					</div>

					<fieldset>
						<div class="mt-4 space-y-4">
							<div class="lg:flex justify-between">
								<div class="items-start text-sm">
									<label for="downloadData"
												 class="font-medium text-gray-700 dark:text-gray-600">{{
											$t('downloadData2')
										}}</label>
									<p class="text-gray-500">{{ $t('downloadAllYourLinkedDataAsJsonFile') }}</p>
								</div>
								<div class="items-end mt-4 lg:mt-0 w-64 self-center">


									<button id="downloadData" @click="downloadUserData()"
													:class="['w-full inline-flex justify-center rounded-xl bg-gray-200 dark:bg-gr-darker py-1.5 px-10 text-sm font-semibold text-gray-500 shadow-sm focus:outline-none']"
													:disabled="isDownloadingUserData">
										<div v-if="isDownloadingUserData" class="text-gr-primary text-center flex">
													<span>													<svg class="animate-spin mr-2 h-5 w-5"
																															 xmlns="http://www.w3.org/2000/svg"
																															 fill="none" viewBox="0 0 24 24">
						<circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
						<path class="opacity-75" fill="currentColor"
									d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
					</svg></span>
											<span>{{ $t('processing') }}</span>
										</div>
										<span v-else>{{ $t('downloadData') }}</span>
									</button>
								</div>
							</div>
							<div class="lg:flex justify-between" v-if="getSubscriptions?.getActiveSubscription()">
								<ConfirmDialog :title="$t('subscription.cancel.title')"
															 :description="$t('subscription.cancel.description')"
															 :cancel-button-name="$t('subscription.cancel.cancel_button')"
															 :confirm-button-name="$t('subscription.cancel.confirm_button')"
															 @confirm="cancelActiveSubscription(getSubscriptions.getActiveSubscription()?.name)"
															 @cancel-confirm="showCancelSubscriptionModal = false"
															 :visible="showCancelSubscriptionModal"></ConfirmDialog>
								<div class="items-start text-sm">
									<label for="downloadData"
												 class="font-medium text-gray-700 dark:text-gray-600">{{
											$t('subscription.cancel.confirm_button')
										}}</label>
									<p class="text-gray-500">{{
											$t('cancelYourActiveSubscriptionOneTimePurchasesOfCour', {
												getProductName: getSubscriptions?.getActiveSubscription().
												getProduct()?.
												getProductName(),
											})
										}}</p>
								</div>
								<div class="items-end mt-4 lg:mt-0 w-64 self-center"
										 v-if="getSubscriptions?.getActiveSubscription()">
									<button id="cancelSubscription" @click="showCancelSubscriptionModal = true"
													class="w-full inline-flex justify-center rounded-xl bg-gray-200 dark:bg-gr-darker py-1.5 px-10 text-sm font-semibold text-gray-500 shadow-sm focus:outline-none">
										{{ $t('subscription.cancel.confirm_button') }}
									</button>
								</div>
								<!--									TODO show resume button-->
							</div>
							<div class="lg:flex justify-between" v-else>
								<div class="items-start text-sm">
									<label for="downloadData"
												 class="font-medium text-gray-700 dark:text-gray-600">{{
											$t('noActiveSubscription')
										}}</label>
									<p class="text-gray-500">{{ $t('youAreOneTheFreePlan') }}</p>
								</div>
							</div>

<!--							TODO show Business toggle and all oauth accounts ( list ) -->
							<div id="oauth_profiles" class="min-h-48">
								<OAuthList></OAuthList>
							</div>

							<!--							<div class="lg:flex justify-between">-->
							<!--								<div class="items-start text-sm">-->
							<!--									<label for="downloadData"-->
							<!--										   class="font-medium text-gray-700 dark:text-gray-600">Delete Account </label>-->
							<!--									<p class="text-gray-500">delete your account, cancel All active Subscriptions and-->
							<!--										remove one Time Purchases.</p>-->
							<!--								</div>-->
							<!--								<div class="items-end mt-4 lg:mt-0 w-64 self-center">-->
							<!--									<button id="deleteAccount" type="submit"-->
							<!--											class=" w-full inline-flex justify-center rounded-xl bg-gray-200 dark:bg-gr-darker py-1.5 px-10 text-sm font-semibold text-gray-500 shadow-sm focus:outline-none">-->
							<!--										delete Account-->
							<!--									</button>-->
							<!--								</div>-->
							<!--							</div>-->
						</div>
					</fieldset>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import {ref, defineComponent, onMounted} from 'vue';
import useUser from '@/composable/greeve/useUser';
import {GreeveApiProfile, UpdateUserDataName} from '@/greeve/api/profile';
import useAlert from '@/composable/core/useAlert';
import useTranslation from '@/composable/translation/useTranslation';
import useSystem from '@/composable/core/useSystem';
import ConfirmDialog from '@/components/modal/ConfirmDialog.vue';
import OAuthList from '@/components/shop/profile/OAuth/OAuthList.vue';
import LanguageSelector from '@/components/settings/LanguageSelector.vue';

export default defineComponent({
	name: 'AccountManagement',
	components: {LanguageSelector, OAuthList, ConfirmDialog},
	props: {},
	emits: [],
	setup() {
		const {t} = useTranslation();
		const {getProfile, getSubscriptions, initSubscriptions, getEmail} = useUser();
		const {addSuccess, addError, resetAllAlerts} = useAlert();
		const {downloadJsonFile, isFeatureFlagActive} = useSystem();

		const isDownloadingUserData = ref(false);
		const isLoading = ref(false);

		const showCancelSubscriptionModal = ref(false);

		const userInformation = ref({
			firstName: getProfile.value?.first_name ?? '',
			lastName: getProfile.value?.last_name ?? '',
			email: getEmail.value ?? '',
		});

		async function updateUserInformation() {
			const userInformationData: UpdateUserDataName = {
				first_name: String(userInformation.value.firstName),
				last_name: String(userInformation.value.lastName),
			};
			try {
				resetAllAlerts();
				if (getProfile.value.first_name !== userInformationData.first_name || getProfile.value.last_name !==
						userInformationData.last_name) {
					await GreeveApiProfile.updateName(userInformationData);
				}
				const success = {
					id: '',
					title: 'Personal Information updated!',
					message: 'Your First and Lastname successfully updated',
					active: true,
				};
				addSuccess(success);
			} catch (e) {
				console.log(e);
				addError({title: t('auth.auth_error_failed_title'), message: t('auth.failed'), active: true});
			}
		}

		async function updateUserLanguage(language: string) {
			try {
				resetAllAlerts();
				if (language.length === 0) {
					addError({title: t('account.profile.language.updateErrorMessage.general'), message: '', active: true});
					return;
				}
				GreeveApiProfile.updateLanguage(language).then(() => {
					const success = {
						id: '',
						title: t('account.profile.language.update.title'),
						message: '',
						active: true,
					};
					addSuccess(success);
				});
			} catch (e) {
				console.log(e);
				addError({title: t('auth.auth_error_failed_title'), message: t('auth.failed'), active: true});
			}
		}

		async function downloadUserData() {
			//TODO loading message
			try {
				isDownloadingUserData.value = true;
				const userData = await GreeveApiProfile.downloadUserData();
				downloadJsonFile(userData, 'greeve_data_' + getProfile.value.last_name);
				const success = {
					id: '',
					title: 'Personal Information ready to download!',
					message: 'You can download your personal informations!',
					active: true,
				};
				addSuccess(success);
			} catch (e) {
				//TODO define error message
				addError({title: t('auth.auth_error_failed_title'), message: t('auth.failed'), active: true});
			} finally {
				isDownloadingUserData.value = false;
			}
		}

		async function cancelActiveSubscription(subscriptionName: string) {
			//TODO loading message
			try {
				isLoading.value = true;
				await GreeveApiProfile.cancelSubscriptions(subscriptionName);
				const success = {
					id: '',
					title: 'Your Subscription has been canceled!',
					message: 'TODO info it ends with date xyz', //TODO
					active: true,
				};
				addSuccess(success);
			} catch (e) {
				//TODO define error message
				addError({title: t('auth.auth_error_failed_title'), message: t('auth.failed'), active: true});
			} finally {
				showCancelSubscriptionModal.value = false;
				isLoading.value = false;
			}
		}

		onMounted(() => {
			resetAllAlerts();
			initSubscriptions();
			userInformation.value.firstName = getProfile.value?.first_name ?? '';
			userInformation.value.lastName = getProfile.value?.last_name ?? '';
		});

		return {
			userInformation,
			getProfile,
			getSubscriptions,
			updateUserInformation,
			updateUserLanguage,
			downloadUserData,
			isDownloadingUserData,
			cancelActiveSubscription,
			showCancelSubscriptionModal,
			isFeatureFlagActive,
		};
	},
});
</script>

<style scoped>

</style>
