import {computed, ComputedRef, onBeforeMount, ref} from "vue";
import store from "@/store";
import {OrderList} from "@/greeve/shop/order/order_list.type";
import {Order} from "@/greeve/shop/order/order.type";
import {GreeveApiOrder} from "@/greeve/api/order";

export default function useOrder() {
	const orders: ComputedRef<OrderList> = computed(() => store.getters['order/getOrders']);
	const lastOrder: ComputedRef<Order> = computed(() => store.getters['order/getLastOrder']);
	const isOrdersLoading = ref(false);

	async function resetOrders() {
		await store.dispatch('order/resetOrders');
		await initOrders(true);
	}

	function setOrders(orderList: OrderList) {
		store.commit('order/setOrders', orderList);
	}

	function setLastOrder(order: Order) {
		store.commit('order/setLastOrder', order);
	}

	function getOrderById(order_id: number): Order|undefined {
		return store.getters['order/getOrder_by_Id'](order_id);
	}

	async function initOrders(forceRefresh = false) {
		if ( (!orders.value || orders.value.length < 1 || forceRefresh) && !isOrdersLoading.value) {
			isOrdersLoading.value = true;
			const orderList = await GreeveApiOrder.getOrders();
			store.commit('order/setOrders', orderList);
			isOrdersLoading.value = false;
		}
	}

	onBeforeMount(() => {
		//
	});

	return {
		orders,
		lastOrder,
		initOrders,
		resetOrders,
		setOrders,
		setLastOrder,
		getOrderById,
	}
}
