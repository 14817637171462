import {PaginationMetaInterface} from "@/greeve/core/pagination_meta.interface";
import {Order} from "@/greeve/shop/order/order.type";
import {JournalList} from '@/greeve/shop/order/journal/journal_list.type';

export class OrderList extends Array<Order> {

	public paginationMetaData?: PaginationMetaInterface;

	constructor(items: Array<Order> = []) {
		super();
		this.push(...items);
	}

	add(items: Order) {
		this.push(items);
	}

	remove(index: number) {
		this.splice(index, 1);
	}

	removeAll() {
		const itemCount = this.length;
		this.splice(itemCount, 1);
	}

	getItem(index: number): Order {
		return this[index];
	}

	getOrder_by_Id(item_id: number, clone = true): Order | undefined {
		if (clone) {
			const orderList: OrderList|Order[] = [...this];
			return orderList.find((order) => {
				let found = true;
				if (item_id) {
					found = order.id === item_id;
				}
				if (!found) {
					return found;
				}
				return order;
			});
		} else {
			return this.find((order) => {
				let found = true;
				if (item_id) {
					found = order.id === item_id;
				}
				if (!found) {
					return found;
				}
				return order;
			});
		}
	}

	enhanceMetaData(metaData: any) {
		this.paginationMetaData = metaData;
	}

	hasJournalEntries(): boolean {
			const orderList: OrderList|Order[] = [...this];
			return orderList.some((order) => {
				return order.journals !== undefined;
			});
	}

	getJournalList(): JournalList {
		const journalList: JournalList = new JournalList();
		const orderList: OrderList|Order[] = [...this];
		orderList.forEach((order) => {
			if (order.journals) {
				journalList?.push(...order.journals);
			}
		})

		return journalList;
	}

	getProductDescriptionSummary(): string {
		let result = '';

		this.forEach((order) => {
			if (order.order_items) {
				order.order_items.forEach((orderItem) => {
					if (orderItem.getProductName()) {
						if (result.length > 0) {
							result += '<br>';
						}
						result += orderItem.getProductName();
					}
				})
			}
		});
		return result;
	}
}