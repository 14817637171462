import {FlowList} from '@/greeve/flow/flow_list.type';
import {AbstractFlow} from '@/greeve/flow/abstract_flow.type';
import { FlowProcessList } from './process/flow_process_list.type';
import {FlowTemplate} from '@/greeve/flow/type/template/flow_template.type';
import {FlowDefault} from '@/greeve/flow/type/default/flow_default.type';
import {FlowSubType, FlowType} from '@/greeve/flow/flow.interface';
import {
	FlowTemplateWhisper
} from '@/greeve/flow/type/template/type/flow_template_whisper.type';
import {FlowWhisper} from '@/greeve/flow/type/default/type/flow_whisper.type';
import {
	FlowProcessDependencyList
} from '@/greeve/flow/process/dependency/flow_process_dependency_list.type';
import useFlowFactory from '@/composable/greeve/useFlowFactory';
import {FlowQueueList} from '@/greeve/flow/queue/flow_queue_list.type';

export class FlowFactory {
	/**
	 *
	 * @param flows
	 */
	createFlowListByResponse(flows: Record<string, any>): FlowList {
		const flowList = new FlowList();

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		for (const [key, item] of Object.entries(flows)) {
			flowList.push(this.createFlowByResponse(item));
		}

		return flowList;
	}

	/**
	 *
	 * @param item
	 */
	createFlowByResponse(item: Record<string, any>): AbstractFlow {
		const id = item.id;
		const uuid = item.uuid;
		const user_id = item.user_id ? item.user_id : (item.userId ? item.userId : undefined);
		const type = item.type;
		const sub_type = item.sub_type ? item.sub_type : (item.subType ? item.subType : undefined);
		const state = item.state;
		const flow_config_id = item.flow_config_id ? item.flow_config_id : (item.flowConfigId ? item.flowConfigId : undefined);
		//TODO Create Flow CONFIG
		// const flow_config = item.flow_config;
		const parent_id = item.parent_id ? item.parent_id : (item.parentId ? item.parentId : undefined) ?? undefined;
		const reference = item.reference ?? undefined;
		const name = item.name ?? undefined;
		const description = item.description ?? undefined;
		let flowProcessList: FlowProcessList | undefined;
		if (item.flow_processes && item.flow_processes.length > 0) {
			flowProcessList = useFlowFactory().getFlowProcessFactory().createFlowProcessListByResponse(item.flow_processes);
		}
		let flowProcessDependencyList: FlowProcessDependencyList | undefined;
		if (item.flow_process_dependencies) {
			flowProcessDependencyList = useFlowFactory().getFlowProcessDependencyFactory().createFlowProcessDependencyListByResponse(item.flow_process_dependencies);
		} else if (item.flowProcessDependencies) {
			flowProcessDependencyList = useFlowFactory().getFlowProcessDependencyFactory().createFlowProcessDependencyListByResponse(item.flowProcessDependencies);
		}
		let flowQueueList: FlowQueueList | undefined;
		if (item.flow_queues && item.flow_queues.length > 0) {
			flowQueueList = useFlowFactory().getFlowQueueFactory().createFlowQueueListByResponse(item.flow_queues);
		}
		const data = item.data;
		const created_at = item.created_at ? new Date(item.created_at) : (item.createdAt ? new Date(item.createdAt) : new Date());
		const updated_at = item.updated_at ? new Date(item.updated_at) : (item.updatedAt ? new Date(item.updatedAt) : new Date());

		let flow;

		switch (type) {
			case FlowType.TEMPLATE:
				switch (sub_type) {
					case FlowSubType.WHISPER_TEMPLATE:
					case FlowSubType.WHISPER_VIDEO_TEMPLATE:
						flow = new FlowTemplateWhisper(id, uuid, user_id, type, state, flow_config_id, flowProcessList, flowProcessDependencyList, flowQueueList, parent_id, sub_type, reference, name, description, data, created_at, updated_at);
						break;
					default:
						flow = new FlowTemplate(id, uuid, user_id, type, state, flow_config_id, flowProcessList, flowProcessDependencyList, flowQueueList, parent_id, sub_type, reference, name, description, data, created_at, updated_at);
						break;
				}
				break;
			case FlowType.DEFAULT:
				switch (sub_type) {
					case FlowSubType.WHISPER_TEMPLATE:
					case FlowSubType.WHISPER_VIDEO_TEMPLATE:
						flow = new FlowWhisper(id, uuid, user_id, type, state, flow_config_id, flowProcessList, flowProcessDependencyList, flowQueueList, parent_id, sub_type, reference, name, description, data, created_at, updated_at);
						break;
					default:
						flow = new FlowDefault(id, uuid, user_id, type, state, flow_config_id, flowProcessList, flowProcessDependencyList, flowQueueList, parent_id, sub_type, reference, name, description, data, created_at, updated_at);
						break;
				}
				break;
			default:
				throw new Error('Invalid Flow: ' + JSON.stringify(item));
		}

		return flow;
	}

	createEmptyFlowList(): FlowList {
		return new FlowList([]);
	}
}