import {FlowProcessType} from '@/greeve/flow/process/flow_process.interface';

export const enum FlowQueueProcessState {
	CANCELLED = 'cancelled',
	DELETED = 'deleted',
	DONE = 'done',
	NEW = 'new',
	ERROR = 'error',
	IN_PROGRESS = 'in_progress',
}

export const enum FlowQueueProcessType {
	DEFAULT = 'default',
}


export interface FlowQueueProcessInterface {
	id: number,
	uuid: string,
	flow_queue_id: number,
	flow_process_id: number,
	job_id?: number|null,
	type: FlowQueueProcessType,
	subType?: FlowProcessType|null,
	state: FlowQueueProcessState,
	reference?: string|null,
	source?: any,
	result?: any,
	data?: any,
	created_at?: Date,
	updated_at?: Date,
}