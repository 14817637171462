<template>
	<div :class="['rounded-lg', iWidth, textPosition, iRounded, iHeight, cssClass]">
		<div class="relative mt-2 rounded-md shadow-sm">
			<input :id="inputIdValue" v-model="valueState"
				   :class="['block w-full rounded-md border-0 py-1.5 pr-10 sm:text-sm sm:leading-6', errorMessage.length > 0 ? 'ring-1 ring-inset ring-red-300 border-0 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-inset focus:ring-red-500' : '', iPadding + ' ' + iCss, inputDisabled ? 'disabled:opacity-75' : '']"
				   :name="importFieldName"
				   :placeholder="inputPlaceholder"
				   :required="requiredField"
				   :type="inputType"
				   aria-describedby="email-error"
				   :autocomplete="autocompleteInput ? inputType : ''"
						 :disabled="inputDisabled"
				   aria-invalid="true"/>
			<div v-show="errorMessage.length > 0"
				 class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
				<ExclamationCircleIcon aria-hidden="true" class="h-5 w-5 text-red-500"/>
			</div>
		</div>
		<p v-show="errorMessage.length > 0" id="voucher-error" class="mt-2 text-sm text-red-600">{{ errorMessage }}</p>
	</div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from 'vue';
import {useVModel} from '@/composable/input/useVModel';
import {nanoid} from 'nanoid'
import {ExclamationCircleIcon} from '@heroicons/vue/20/solid'

export default defineComponent({
	name: 'InputVoucherField',
	components: {ExclamationCircleIcon},
	emits: ['update:email', 'update:password'],
	props: {
		value: String,
		inputType: {
			type: String,
			default: "input"
		},
		inputId: {
			type: String,
			default: ""
		},
		inputPlaceholder: {
			type: String,
			default: "Input"
		},
		requiredField: {
			type: Boolean,
			default: true
		},
		iWidth: {
			type: String,
			default: 'w-full sm:w-auto',
		},
		iHeight: {
			type: String,
			default: 'h-auto',
		},
		iRounded: {
			type: String,
			default: 'rounded-3xl',
		},
		textPosition: {
			type: String,
			default: 'text-center',
		},
		iPadding: {
			type: String,
			default: 'px-4 py-2',
		},
		iCss: {
			type: String,
			default: '',
		},
		cssClass: {
			type: String,
			default: 'my-2 sm:m-2',
		},
		errorMessage: {
			type: String,
			default: ""
		},
		autocompleteInput: {
			type: Boolean,
			default: false,
		},
		inputDisabled: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		const importFieldName = ref();
		const inputIdValue = ref(props.inputId ?? nanoid());

		onMounted(() => {
			importFieldName.value = "input_" + nanoid();
		})

		return {
			valueState: useVModel(props, 'value'),
			importFieldName,
			inputIdValue,
		};
	}
})
</script>

<style scoped>
</style>