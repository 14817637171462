import {AddressInterface} from "@/greeve/user/user.interface";

export class UserAddress implements AddressInterface {
	id: number;
	user_id: number;
	primary: boolean;
	first_name: string;
	last_name: string;
	country_code: string;
	company?: string | null;
	is_company: boolean;
	uid?: string;
	street: string;
	zip_code: string;
	city: string;
	state?: string;
	lat?: string;
	lng?: string;
	phone?: string;
	description?: string;
	data?: any;
	created_at?: Date;
	updated_at?: Date;


	constructor(id: number, userId: number, primary: boolean, firstName: string, lastName: string, street: string, city: string, countryCode: string, postal_code: string, state?: string, company: string | null = null, created_at?: Date, updated_at?: Date, data: any = null) {
		this.id = id;
		this.user_id = id;
		this.primary = primary;
		this.first_name = firstName;
		this.last_name = lastName;
		this.street = street;
		this.city = city;
		this.country_code = countryCode;
		this.zip_code = postal_code;
		this.state = state;
		this.company = company;
		this.is_company = !!(company && company.length > 0);
		this.data = data;
		this.created_at = created_at;
		this.updated_at = updated_at;
	}

	getAddressName(): string
	{
		return this.first_name + ' ' + this.last_name + '<br>' + this.street + ' ' + this.zip_code + '<br>' + this.city + ', ' + this.country_code;
	}
}