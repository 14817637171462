import {BlogList} from '@/greeve/blog/blog_list.type';
import {Blog} from '@/greeve/blog/blog.type';

export class BlogFactory {
	/**
	 *
	 * @param blogResponse
	 */
	createBlogListByResponse(blogResponse: Record<string, any>): BlogList {
		const blogList = new BlogList();

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		for (const [key, item] of Object.entries(blogResponse)) {
			blogList.push(this.createBlogByResponse(item));
		}

		return blogList;
	}

	/**
	 *
	 * @param item
	 */
	createBlogByResponse(item: Record<string, any>): Blog {
		const id = item.id;
		const title = item.title ?? '';
		const slug = item.slug ?? '';
		const content_markdown = item.content_markdown ?? '';
		const published = item.published;
		const language = item.language ? item.language : (item.language ?? 'de');
		const header_banner = item.header_banner;
		const user_id = item.user_id;
		const keywords = item.keywords;
		const categories = item.categories;
		const tags = item.tags;
		const created_at = item.created_at ? new Date(item.created_at) : (item.createdAt ? new Date(item.createdAt) : new Date());
		const updated_at = item.updated_at ? new Date(item.updated_at) : (item.updatedAt ? new Date(item.updatedAt) : new Date());

		return new Blog(id, title, slug, content_markdown, published, language, header_banner, user_id, categories, keywords, tags, created_at, updated_at)
	}
}