import {SearchItemList} from '@/greeve/search/item/search_item_list.type';
import {
	AbstractSearchItem
} from '@/greeve/search/item/abstract_search_item.type';
import {
	GreeveSearchItemStateInterface,
	GreeveSearchItemSubTypeInterface,
	GreeveSearchItemTypeInterface,
} from '@/greeve/search/item/search_item.interface';
import {
	SearchItemChat
} from '@/greeve/search/item/type/subtype/search_item_chat.type';
import {
	SearchItemCompletion
} from '@/greeve/search/item/type/subtype/search_item_completion.type';
import {
	SearchItemImage
} from '@/greeve/search/item/type/subtype/search_item_image.type';
import {
	SearchItemTranscribeAudio
} from '@/greeve/search/item/type/subtype/search_item_transcribe_audio.type';
import {
	SearchItemDefault
} from '@/greeve/search/item/type/search_item_default.type';
import useAssistantFactory from '@/composable/greeve/useAssistantFactory';
import {
	SearchItemNewAuthorization
} from '@/greeve/search/item/type/search_item_authorization.type';
import useSystem from '@/composable/core/useSystem';
import {
	SearchItemTextToSpeech
} from '@/greeve/search/item/type/subtype/search_item_text_to_speech.type';
import {
	SearchItemAuthorization
} from '@/greeve/search/authorization/search_item_authorization.type';
import {
	SearchItemInteraction
} from '@/greeve/search/item/type/search_item_interaction.type';
import {
	SearchItemUploadInteraction
} from '@/greeve/search/item/type/search_item_upload_interaction.type';
import {
	SearchItemImageVariation
} from '@/greeve/search/item/type/subtype/search_item_image_variation.type';
import {
	SearchItemImageEdit
} from '@/greeve/search/item/type/subtype/search_item_image_edit.type';
import {
	SearchItemImageVision
} from '@/greeve/search/item/type/subtype/search_item_image_vision.type';
import {
	AbstractPaginationFactory
} from '@/greeve/core/abstract_pagination_factory.type';

export class SearchItemFactory extends AbstractPaginationFactory{
	/**
	 *
	 * @param searchItems
	 */
	createSearchItemListByResponse(searchItems: Record<string, any>): SearchItemList {
		const searchItemList = new SearchItemList();

		if (this.hasPaginationMetaData(searchItems)) {
			searchItemList.enhanceMetaData(this.getMetaDataByResponse(searchItems));
			searchItems = searchItems.data;
		}

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		for (const [key, item] of Object.entries(searchItems)) {
			if (!item) {
				continue;
			}
			searchItemList.push(this.createSearchItemByResponse(item));
		}

		return searchItemList;
	}

	/**
	 *
	 * @param item
	 */
	createSearchItemByResponse(item: Record<string, any>): AbstractSearchItem {
		const id = item.id ? item.id : (item.searchItem_id ? item.searchItem_id : undefined);
		const uuid = item.uuid ? item.uuid : (item.searchItem_uuid ? item.searchItem_uuid : undefined);
		const search_item_group_id = item.search_item_group_id ? item.search_item_group_id : undefined;
		const search_group = item.search_group ? (useAssistantFactory().getSearchGroupFactory().createSearchGroupByResponse(item.search_group)) : undefined;
		const type = item.type ? item.type : GreeveSearchItemTypeInterface.SEARCH_ITEM_TYPE_DEFAULT;
		const state = item.state ? item.state : GreeveSearchItemStateInterface.SEARCH_ITEM_STATE_NEW;
		const sub_type = item.sub_type ? item.sub_type : (item.subType ? item.subType : GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_DEFAULT);
		const reference = item.reference ? item.reference : undefined;
		const groupReference = item.group_reference ? item.group_reference : undefined;
		const prompt = item.prompt ? item.prompt : undefined;
		const is_pinned = Boolean(item.is_pinned ?? false);
		let request = item.request ?? undefined;
		if (request && useSystem().isJson(request)) {
			request = JSON.parse(request);
		}
		let response = item.response ?? undefined;
		if (response && useSystem().isJson(response)) {
			response = JSON.parse(response);
		}
		const data = item.data ?? undefined;
		const created_at = item.created_at ? new Date(item.created_at) : (item.createdAt ? new Date(item.createdAt) : new Date());
		const updated_at = item.updated_at ? new Date(item.updated_at) : (item.updatedAt ? new Date(item.updatedAt) : new Date());

		let searchItem: AbstractSearchItem|undefined;
		switch (type) {
			case GreeveSearchItemTypeInterface.SEARCH_ITEM_TYPE_DEFAULT:
				switch (sub_type) {
					case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_CHAT:
						searchItem = new SearchItemChat(id, uuid, sub_type, state,
								search_item_group_id, search_group, reference, groupReference, prompt,
								is_pinned, request, response, data, created_at, updated_at);
						break;
					case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_COMPLETION:
						searchItem = new SearchItemCompletion(id, uuid, sub_type, state,
								search_item_group_id, search_group, reference, groupReference, prompt,
								is_pinned, request, response, data, created_at, updated_at);
						break;
					case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE:
						searchItem = new SearchItemImage(id, uuid, sub_type, state,
								search_item_group_id, search_group, reference, groupReference, prompt,
								is_pinned, request, response, data, created_at, updated_at);
						break;
					case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_VARIATION:
						searchItem = new SearchItemImageVariation(id, uuid, sub_type, state,
								search_item_group_id, search_group, reference, groupReference, prompt,
								is_pinned, request, response, data, created_at, updated_at);
						break;
					case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_IMAGE_EDIT:
						searchItem = new SearchItemImageEdit(id, uuid, sub_type, state,
								search_item_group_id, search_group, reference, groupReference, prompt,
								is_pinned, request, response, data, created_at, updated_at);
						break;
					case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_VISION:
						searchItem = new SearchItemImageVision(id, uuid, sub_type, state,
								search_item_group_id, search_group, reference, groupReference, prompt,
								is_pinned, request, response, data, created_at, updated_at);
						break;
					case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_SPEECH_TO_TEXT:
						searchItem = new SearchItemTranscribeAudio(id, uuid, sub_type,
								state, search_item_group_id, search_group, reference, groupReference, prompt,
								is_pinned, request, response, data, created_at, updated_at);
						break;
					case GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_TEXT_TO_SPEECH:
						searchItem = new SearchItemTextToSpeech(id, uuid, sub_type,
								state, search_item_group_id, search_group, reference, groupReference, prompt,
								is_pinned, request, response, data, created_at, updated_at);
						break;
					default:
						searchItem = new SearchItemDefault(id, uuid, sub_type, state,
								search_item_group_id, search_group, reference, groupReference, prompt,
								is_pinned, request, response, data, created_at, updated_at);
						break;
				}
				break;
			case GreeveSearchItemTypeInterface.SEARCH_ITEM_TYPE_AUTHORIZATION:
				searchItem = new SearchItemNewAuthorization(id, uuid, sub_type, state,
						search_item_group_id, search_group, reference, groupReference,
						prompt,
						is_pinned, request, response, data, created_at, updated_at);
				break;
			case GreeveSearchItemTypeInterface.SEARCH_ITEM_TYPE_INTERACTION:
				searchItem = new SearchItemInteraction(id, uuid, sub_type, state,
						search_item_group_id, search_group, reference, groupReference,
						prompt,
						is_pinned, request, response, data, created_at, updated_at);
				break;
			case GreeveSearchItemTypeInterface.SEARCH_ITEM_TYPE_UPLOAD_INTERACTION:
				searchItem = new SearchItemUploadInteraction(id, uuid, sub_type, state,
						search_item_group_id, search_group, reference, groupReference,
						prompt,
						is_pinned, request, response, data, created_at, updated_at);
				break;
			default:
				throw new Error('Type ' + type + ' not allowed!');
		}

		return searchItem;
	}


	createEmptySearchItemDefault(id: number, uuid: string, search_item_group_id: number|null, reference: string, prompt: string, sub_type?: GreeveSearchItemSubTypeInterface) {
			const item = {
				id: id,
				uuid: uuid,
				search_item_group_id: search_item_group_id,
				sub_type: sub_type,
				reference: reference,
				prompt: prompt,
			}

			return this.createSearchItemByResponse(item);
	}


	createSearchItemByAuthorizationItem(id: number, authorizationItem: SearchItemAuthorization, subType: GreeveSearchItemSubTypeInterface = GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_DEFAULT) {
			const item = {
				id: id,
				uuid: authorizationItem.uuid,
				search_group: authorizationItem.search_group,
				search_item_group_id: authorizationItem.search_item_group_id,
				search_item_group_uuid: authorizationItem.search_item_group_uuid,
				sub_type: subType,
				reference: authorizationItem.reference,
				prompt: authorizationItem.prompt,
			}

			return this.createSearchItemByResponse(item);
	}


	createSearchItemByUploadInteractionItem(id: number, searchItemUploadInteraction: SearchItemUploadInteraction, subType: GreeveSearchItemSubTypeInterface = GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_DEFAULT) {
			const item = {
				id: id,
				uuid: searchItemUploadInteraction.uuid,
				search_group: searchItemUploadInteraction.search_group,
				search_item_group_id: searchItemUploadInteraction.search_item_group_id,
				search_item_group_uuid: searchItemUploadInteraction.search_group?.uuid,
				sub_type: subType,
				reference: searchItemUploadInteraction.reference,
				prompt: searchItemUploadInteraction.prompt,
			}

			return this.createSearchItemByResponse(item);
	}

	createEmptySearchItemUploadInteraction(id: number, uuid: string, search_item_group_id: number|null, reference: string, prompt: string, sub_type?: GreeveSearchItemSubTypeInterface): SearchItemUploadInteraction | undefined {
		const item = {
			id: id,
			type: GreeveSearchItemTypeInterface.SEARCH_ITEM_TYPE_UPLOAD_INTERACTION,
			state: GreeveSearchItemStateInterface.SEARCH_ITEM_STATE_NEW,
			uuid: uuid,
			search_item_group_id: search_item_group_id,
			sub_type: sub_type,
			reference: reference,
			prompt: prompt,
			created_at: new Date(),
			updated_at: new Date(),
		}

		const result  = this.createSearchItemByResponse(item)
		if (result instanceof SearchItemUploadInteraction) {
			return result;
		}
		return undefined;
	}

	createEmptySearchItemList() {
		return new SearchItemList();
	}
}