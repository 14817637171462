<template>
	<ion-page>
		<ion-content :fullscreen="true">
			<!--      <HeaderBar></HeaderBar>-->
			<HeaderLogo>
				<div  class="rounded-3xl px-3 font-comfortaa py-0 bg-black dark:bg-gray-400 font-bold text-white dark:text-black mt-0 float-right text-tc-bg text-xs sm:text-base sm:mr-1.5 -mt-1 sm:-mt-2 ">{{ $t('beta') }}
				</div>
			</HeaderLogo>  			<div class="">

				<div class="mx-4 md:mx-7">

					<div class="py-32 xl:py-36 px-4 sm:px-6 lg:px-8 bg-tc-bg overflow-hidden">
						<div class="max-w-max lg:max-w-7xl mx-auto">
							<div class="relative mb-8 md:mb-2 md:px-6">
								<div class="text-base max-w-prose lg:max-w-none">
									<p class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-tc-blue sm:text-4xl">
										{{ t('terms.TermsTitle') }}</p>
								</div>
							</div>
							<div class="relative">
								<div class="relative md:bg-tc-bg md:p-6">
									<div class="lg:grid lg:gap-6">

										<div class="break-words w-full text-lg lg:text-xl py-0 sm:py-0 lg:py-0 ">
											<br><ol>
												<li><strong>{{ $t('1AllgemeineBestimmungen') }}</strong></li>
											</ol>
											<p class="pt-3"></p>
											<p>{{ $t('11Geltungsbereich') }}</p>
											<p>{{ $t('dieseAllgemeinenGeschftsbedingungenAgbRegelnDieNut') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('12ZustimmungUndAnnahmeDerAgb') }}</p>
											<p>{{ $t('durchDieNutzungUnsererWebsiteUndServicesErklrenSie') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('12KitechnologieUndNutzung') }}</p>
											<p>{{ $t('unsereDienstleistungVerwendetModernsteKnstlicheInt') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('14Nutzungsberechtigung') }}</p>
											<p>{{ $t('dieWebsiteIstFrBenutzerBestimmtDieMindestens13Jahr') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('15RegistrierungUndBenutzerkonto') }}</p>
											<p>{{ $t('umBestimmteFunktionenUnsererWebsiteUndServicesNutz') }}</p>
											<p></p><br>
											<ol>
												<li><strong>{{ $t('2NutzungsrechteUndEinschrnkungen') }}</strong></li>
											</ol>
											<p class="pt-3"></p>
											<p>{{ $t('21GeistigesEigentum') }}</p>
											<p>{{ $t('dieWebsiteUndServicesVonTheconceptTechnologiesLlcS') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('derInhaltUndDieMarkenWerdenAufDerWebsiteAsIsNurZuI') }}<br/> {{ $t('sofernSieZurNutzungDerWebsiteBerechtigtSindErhalte') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('22ErlaubteNutzung') }}</p>
											<p>{{ $t('sieDrfenUnsereWebsiteUndServicesNurFrRechtmigeZwec') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('23VerboteneAktivitten') }}</p>
											<p>{{ $t('beiDerNutzungUnsererWebsiteUndServicesSindIhnenFol') }}</p>
											<p>{{ $t('dieVerletzungVonGeistigenEigentumsrechtenDritter') }}</p>
											<p>{{ $t('dieVerbreitungVonIllegalenBedrohlichenDiffamierend') }}</p>
											<p>{{ $t('dieNutzungUnsererWebsiteUndServicesInEinerWeiseDie') }}</p>
											<p>{{ $t('derVersuchUnbefugtenZugriffAufUnsereWebsiteUndServ') }}</p>
											<p>{{ $t('dasSammelnOderSpeichernPersonenbezogenerDatenAnder') }}</p>
											<p>{{ $t('dieVerwendungVonAutomatisiertenSystemenEinschlieli') }}</p>
											<p>{{ $t('dieVerbreitungVonVirenMalwareOderAnderenSchdlichen') }}</p>
											<p>{{ $t('dieNutzungDerFreiZugnglichenTeileUnsererWebsiteUnd') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('24Benutzerinformation') }}</p>
											<p>{{ $t('durchDieNutzungDerWebsiteErklrenUndGarantierenSieD') }}</p>
											<ul>
												<li>{{ $t('alleRegistrierungsinformationenDieSieBermittelnWah') }}</li>
												<li>{{ $t('sieWerdenDieRichtigkeitDieserInformationenAufrecht') }}</li>
												<li>{{ $t('sieSindGeschftsfhigUndStimmenZuDieseNutzungsbeding') }}</li>
												<li>{{ $t('sieSindNichtUnter13JahreAlt') }}</li>
												<li>{{ $t('sieSindInDemLandInDemSieWohnenNichtMinderjhrigOder') }}</li>
												<li>{{ $t('sieGreifenNichtBerAutomatisierteOderNichtmenschlic') }}</li>
												<li>{{ $t('sieWerdenDieWebsiteNichtFrIllegaleOderUnbefugteZwe') }}</li>
												<li>{{ $t('ihreNutzungDerWebsiteVersttNichtGegenGeltendeGeset') }}</li>
											</ul>
											<p></p>
											<p>{{ $t('wennSieUnwahreUngenaueNichtAktuelleOderUnvollstndi') }}</p>
											<p></p>
											<p>{{ $t('esKannErforderlichSeinDassSieSichAufDerWebsiteRegi') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('25Benutzerinhalte') }}</p>
											<p></p>
											<p>{{ $t('wirSindNichtFrBenutzerinhalteVerantwortlichUnabhng') }}</p>
											<p></p>
											<p class="pt-3"></p>
											<p>{{ $t('25VerpflichtungenZurNutzungenDesServices') }}<br/> {{ $t('alsNutzerDerSaasAnwendungVerpflichtenSieSichFolgen') }}</p>
											<p></p>
											<ul>
												<li>{{ $t('jeglicheUnbefugteNutzungDerWebsiteEinschlielichDes') }}</li>
												<li>{{ $t('dieWebsiteZuNutzenUmFrWarenUndDienstleistungenZuWe') }}</li>
												<li>{{ $t('einenEinkuferOderEinkaufsagentenBenutzenUmAufDerWe') }}</li>
												<li>{{ $t('sicherheitsrelevanteFunktionenDerWebsiteZuUmgehenZ') }}</li>
												<li>{{ $t('unsUndAndereBenutzerAuszutricksenZuBetrgenOderInDi') }}</li>
												<li>{{ $t('sichAnEinerAutomatisiertenNutzungDesSystemsZuBetei') }}</li>
												<li>{{ $t('lschenSieDenHinweisAufDasUrheberrechtOderAndereEig') }}</li>
												<li>{{ $t('kopierenOderAnpassenDerSoftwareDerWebsiteEinschlie') }}</li>
												<li>{{ $t('sieDrfenDieWebsiteNichtVerkaufenVermietenVerleasen') }}</li>
												<li>{{ $t('esIstIhnenNichtGestattetDieWebsiteZuVerndernDavonA') }}</li>
												<li>{{ $t('sieDrfenNichtAufDieWebsiteZugreifenUmEineHnlicheOd') }}</li>
												<li>{{ $t('sofernNichtAusdrcklichAngegebenDarfKeinTeilDerWebs') }}</li>
											</ul>
											<p></p>
											<p>{{ $t('jeglicheNutzungDerWebsiteDieGegenDieVorstehendenBe') }}</p>
											<p></p>
											<p>{{ $t('wirBehaltenUnsDasRechtVorAlleBenutzerinhalteZuBerp') }}</p>
											<p></p>
											<p class="pt-3"></p>
											<p>{{ $t('27Bewertungen') }}</p>
											<ul>
												<li>{{ $t('wirKnnenIhnenAufDerWebsiteBereicheZurVerfgungStell') }}</li>
												<li>{{ $t('dieErstellungDerVertriebDieBertragungDieFfentliche') }}</li>
												<li>{{ $t('sieSindDerUrheberUndEigentmerOderVerfgenBerDieErfo') }}</li>
												<li>{{ $t('sieHabenDieSchriftlicheZustimmungFreigabeUndoderEr') }}</li>
												<li>{{ $t('ihreBeitrgeSindNichtFalschUngenauOderIrrefhrend') }}</li>
												<li>{{ $t('ihreBeitrgeSindKeineUnaufgeforderteOderUnerlaubteW') }}</li>
												<li>{{ $t('ihreBeitrgeSindNichtObsznUnzchtigLaszivSchmutzigGe') }}</li>
												<li>{{ $t('ihreBeitrgeMachenNiemandenLcherlichVerhhnenVerungl') }}</li>
												<li>{{ $t('ihreBeitrgeWerdenNichtVerwendetUmEineAnderePersonZ') }}</li>
											</ul>
											<p></p>
											<p>{{ $t('wirKnnenNachEigenemErmessenBewertungenAnnehmenAble') }}<br/> {{ $t('alsTeilDerFunktionalittDerWebsiteKnnenSieIhrKontoM') }}</p>
											<ul>
												<li>{{ $t('dieErstellungDerVertriebDieBertragungDieFfentliche') }}</li>
												<li>{{ $t('sieSindDerUrheberUndEigentmerOderVerfgenBerDieErfo') }}</li>
											</ul>
											<p></p>
											<p>{{ $t('indemSieUnsZugangZuKontenVonDrittanbieternGewhrenV') }}</p>
											<ul>
												<li>{{ $t('dieErstellungDerVertriebDieBertragungDieFfentliche') }}</li>
												<li>{{ $t('sieSindDerUrheberUndEigentmerOderVerfgenBerDieErfo') }}</li>
											</ul>
											<p></p>
											<p class="pt-3"></p>
											<p>{{ $t('28DrittanbieterKonten') }}</p>
											<p>{{ $t('jeNachDenVonIhnenGewhltenDrittanbieterkontenUndVor') }}<br/> {{ $t('sieErkennenAnUndErklrenSichDamitEinverstandenDassA') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('29ElektronischeKommunikationTransaktionenUndUnters') }}</p>
											<p>{{ $t('derBesuchDerWebsiteDasSendenVonEmailsAnUnsUndDasAu') }}</p>
											<p></p><br>
											<ol>
												<li><strong>{{ $t('3datenschutzUndSicherheit') }}</strong></li>
											</ol>
											<p class="pt-3"></p>
											<p>{{ $t('31Datenschutz') }}</p>
											<p>{{ $t('wirNehmenDenSchutzIhrerPersnlichenDatenErnstUndVer') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('32Sicherheitsmanahmen') }}</p>
											<p>{{ $t('wirErgreifenAngemesseneTechnischeUndOrganisatorisc') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('33Datenverarbeitung') }}</p>
											<p>{{ $t('wirNehmenDenSchutzIhrerDatenSehrErnstAlleVonIhnenZ') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('34Datenspeicherung') }}</p>
											<p>{{ $t('wirSpeichernBestimmteDatenDieSieAnDieWebsiteBertra') }}</p>
											<p>{{ $t('kalifornischeBenutzerUndEinwohner') }}</p>
											<p>{{ $t('wennEineBeschwerdeBeiUnsNichtZufriedenstellendGels') }}</p>
											<p></p>
											<p></p><br>
											<ol>
												<li><strong>{{ $t('4nderungenBeendigungOderUnterbrechungenDerVerfgbar') }}</strong></li>
											</ol>
											<p class="pt-3"></p>
											<p>{{ $t('41NderungenDerAgb') }}</p>
											<p>{{ $t('wirBehaltenUnsDasRechtVorDieseAgbJederzeitNachEige') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('42Beendigung') }}</p>
											<p>{{ $t('wirBehaltenUnsDasRechtVorIhreNutzungUnsererWebsite') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('43ModifikationenUndUnterbrechungen') }}</p>
											<p>{{ $t('wirBehaltenUnsDasRechtVorDenInhaltDerWebsiteJederz') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('44VerfgbarkeitUndUnterbrechungen') }}</p>
											<p>{{ $t('wirKnnenNichtGarantierenDassUnsereWebsiteJederzeit') }}</p>
											<p></p>
											<p></p><br>
											<ol>
												<li><strong>{{ $t('5Creditssystem') }} </strong></li>
											</ol>
											<p class="pt-3"></p>
											<p>{{ $t('unsereDiensteKnnenEinCreditssystemEnthaltenBeiDemS') }}</p>
											<p>{{ $t('dieKostenEinesWorkflowsInCreditsKnnenJeNachArtUndU') }}</p>
											<p>{{ $t('erworbeneCreditsSindNichtBertragbarUndKnnenNichtIn') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('wirBehaltenUnsDasRechtVorDiePreiseUnsererCreditsUn') }}</p>
											<p>{{ $t('esLiegtInIhrerVerantwortungIhreCreditsZuVerwaltenV') }}</p>
											<p>{{ $t('wirBehaltenUnsDasRechtVorDasCreditssystemJederzeit') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('51Creditssystem') }}</p>
											<p>{{ $t('wirBietenIhnenDieMglichkeitEinCreditssystemZuNutze') }}</p>
											<p></p>
											<p>{{ $t('aAufUnsererWebsiteBietenWirEinCreditssystemAnDasEs') }}</p>
											<p></p>
											<p>{{ $t('bDiePreiseFrCreditsWerdenAufUnsererWebsiteAngegebe') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('52ZahlungenUndAbrechnungVonCredits') }}</p>
											<p>{{ $t('aDerKaufVonCreditsErfolgtEntwederBerEinAbonnementO') }}</p>
											<p></p>
											<p>{{ $t('bBeiAbonnementsErfolgtDieZahlungRegelmigGemDenAnge') }}</p>
											<p></p>
											<p>{{ $t('cAlleZahlungenWerdenBerSichereZahlungsplattformenA') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('53VerwendungVonCredits') }}</p>
											<p>{{ $t('aSieKnnenIhreErworbenenCreditsVerwendenUmWorkflows') }}</p>
											<p></p>
											<p>{{ $t('bCreditsSindNichtBertragbarUndKnnenNichtGegenBarge') }}</p>
											<p></p>
											<p>{{ $t('cWirBehaltenUnsDasRechtVorDiePreiseFrCreditsUndDie') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('54Creditsgltigkeit') }}</p>
											<p>{{ $t('gekaufteCreditsHabenEineBegrenzteGltigkeitsdauerWi') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('55NderungenAmCreditssystem') }}</p>
											<p>{{ $t('wirBehaltenUnsDasRechtVorDasCreditssystemEinschlie') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('56KndigungUndRckerstattung') }}</p>
											<p></p>
											<p>{{ $t('aSieKnnenIhrAbonnementFrCreditsJederzeitBerIhrBenu') }}</p>
											<p></p>
											<p>{{ $t('bBeiEinzelkufenVonCreditsIstEineRckerstattungNurUn') }}</p>
											<p></p>
											<p>{{ $t('cWirBehaltenUnsDasRechtVorIhrCreditskontoZuSperren') }}</p>
											<p></p>
											<p>{{ $t('creditsKnnenFrDenErwerbVonWorkflowsVerwendetWerden') }}</p>
											<p></p>
											<p>{{ $t('bitteBeachtenSieDassDasCreditssystemBestimmtenEins') }}</p>
											<p></p><br>
											<ol>
												<li><strong>{{ $t('6Haftungsbeschrnkungen') }}</strong></li>
											</ol>
											<p class="pt-3"></p>
											<p>{{ $t('61Haftungsausschluss') }}</p>
											<p>{{ $t('dieNutzungUnsererWebsiteUndServicesErfolgtAufIhrEi') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('62Haftungsbeschrnkung') }}</p>
											<p>{{ $t('inKeinemFallHaftenWirIhnenOderDrittenGegenberFrDir') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('63Freistellung') }}</p>
											<p>{{ $t('sieErklrenSichDamitEinverstandenUnsUnsereGeschftsf') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('64AusnahmenVonDerHaftungsbeschrnkung') }}</p>
											<p>{{ $t('dieInDenVorstehendenAbstzenGenanntenHaftungsbeschr') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('65Haftungsklauseln') }}</p>
											<p>{{ $t('sieEntbindenHiermitDasUnternehmenUndUnsereLeitende') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('66WebsitesUndInhalteVonDritten') }}</p>
											<p>{{ $t('dieWebsiteKannLinksZuAnderenWebsitesWebsitesDritte') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('67Werbung') }}<br/> {{ $t('wirGestattenWerbetreibendenIhreWerbungUndAndereInf') }}<br/> {{ $t('wirGestattenWerbetreibendenIhreWerbungUndAndereInf') }}<br/> {{ $t('beiUnserenDienstleistungenHandeltEsSichUmCommercia') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('68FehlerhafteOderUnerwarteteWorkflowausfhrung') }}</p>
											<p>{{ $t('wirBernehmenKeineHaftungFrProblemeDieSichAusDerNut') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('69Entschdigung') }}</p>
											<p>{{ $t('sieErklrenSichDamitEinverstandenUnsZuVerteidigenZu') }}</p>
											<ul>
												<li>{{ $t('ihreBeitrge') }}</li>
												<li>{{ $t('derNutzungDerWebsite') }}</li>
												<li>{{ $t('verstoGegenDieseNutzungsbedingungen') }}</li>
												<li>{{ $t('jeglicheVerletzungIhrerZusicherungenUndGewhrleistu') }}</li>
												<li>{{ $t('ihreVerletzungDerRechteDritterEinschlielichAberNic') }}</li>
												<li>{{ $t('jedeOffenkundigSchdigendeHandlungGegenberEinemAnde') }}</li>
											</ul>
											<p></p>
											<p>{{ $t('ungeachtetDesVorstehendenBehaltenWirUnsDasRechtVor') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('610Disclaimer') }}</p>
											<p>{{ $t('dieSeiteWirdAufEinerAsisUndAsavailableBasisBereitg') }}</p>
											<ul>
												<li>{{ $t('terms.DisclaimerListItem1') }}</li>
												<li>{{ $t('personenOderSachschdenJeglicherArtDieSichAusIhremZ') }}</li>
												<li>{{ $t('jeglicherUnbefugterZugriffAufOderDieNutzungUnserer') }}</li>
												<li>{{ $t('jeglicheUnterbrechungOderBeendigungDerBertragungZu') }}</li>
												<li>{{ $t('jeglicheBugsVirenTrojanischePferdeOderHnlichesDieV') }}</li>
												<li>{{ $t('frJeglicheFehlerOderAuslassungenInInhaltenUndMater') }}</li>
											</ul>
											<p></p>
											<p>{{ $t('wirBernehmenKeineGewhrleistungUntersttzungGarantie') }}</p>
											<p></p>
											<p>{{ $t('ungeachtetDesVorstehendenBehaltenWirUnsDasRechtVor') }}</p>
											<p></p><br>
											<ol>
												<li><strong>{{ $t('7ZahlungenUndAbrechnung') }}</strong></li>
											</ol>
											<p class="pt-3"></p>
											<p>{{ $t('esKannSeinDassSieFrDenZugriffAufEinigeUnsererDiens') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('aDerKaufVonCreditsErfolgtEntwederBerEinAbonnementO') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('bBeiAbonnementsErfolgtDieZahlungRegelmigGemDenAnge') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('cAlleZahlungenWerdenBerSichereZahlungsplattformenA') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('dSieStimmenZuDassWirIhreZahlungsinformationenErfas') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('eAbonnementsVerlngernSichAutomatischZuDenJeweilsAn') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('zurBezahlungWerdenServicesVonStripeVerwendetEsGelt') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('wirAkzeptierenUnterAnderemFolgendenZahlungsarten') }}</p>
											<ul>
												<li>{{ $t('terms.IntellectualList2Item1') }}</li>
												<li>{{ $t('terms.IntellectualList2Item2') }}</li>
												<li>{{ $t('terms.IntellectualList2Item3') }}</li>
												<li>{{ $t('terms.IntellectualList2Item4') }}</li>
											</ul>
											<p class="pt-3"></p>
											<p>{{ $t('sieErklrenSichDamitEinverstandenAlleKostenOderGebh') }}</p>
											<p></p>
											<p>{{ $t('wirBehaltenUnsDasRechtVorFehlerOderIrrtmerBeiDerPr') }}</p>
											<p></p>
											<p>{{ $t('alleKufeSindNichtErstattungsfhigSieKnnenIhrAbonnem') }}</p>
											<p></p><br>
											<ol>
												<li><strong>{{ $t('8SonstigeBestimmungen') }}</strong></li>
											</ol>
											<p class="pt-3"></p>
											<p>{{ $t('81SalvatorischeKlausel') }}</p>
											<p>{{ $t('sollteEineBestimmungDieserAgbGanzOderTeilweiseUnwi') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('82AnwendbaresRechtUndGerichtsstand') }}</p>
											<p>{{ $t('dieseAgbUnterliegenDemSterreichischenRechtFrStreit') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('83NderungenDerAgb') }}</p>
											<p>{{ $t('wirBehaltenUnsDasRechtVorDieseAgbJederzeitUndNachE') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('84GesamteVereinbarung') }}</p>
											<p>{{ $t('dieseAgbStellenDieGesamteVereinbarungZwischenIhnen') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('85BertragungDerRechteUndPflichten') }}</p>
											<p>{{ $t('sieDrfenIhreRechteUndPflichtenAusDiesenAgbNichtOhn') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('86KeineVerzichtserklrung') }}</p>
											<p>{{ $t('dasVersumnisUnsererseitsEinRechtOderEineBestimmung') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('87SprachlicheAuslegung') }}</p>
											<p>{{ $t('dieseAgbWurdenInMehrerenSprachenBereitgestelltImFa') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('88AnwendbaresRechtUndGerichtsstand') }}</p>
											<p>{{ $t('dieseAgbUnterliegenDemAmerikanischenRechtBeiStreit') }}</p>
											<p></p><br>
											<ol>
												<li><strong>{{ $t('9Kontakt') }}</strong></li>
											</ol>
											<p class="pt-3"></p>
											<p>{{ $t('wirDankenIhnenFrDieNutzungUnsererWebsiteUndStehenI') }}</p>
											<p class="pt-3"></p>
											<p>{{ $t('terms.ContactDetail2') }}</p>
											<p>{{ $t('serviceGreeve') }}</p>
											<p>{{ $t('447Broadway2StockPmb963') }}</p>
											<p>{{ $t('terms.ContactDetail5') }}</p>
											<p>E-Mail: <a href="mailto:service@theconcept.app">{{ $t('mailto') }}</a></p>
											<p class="pt-3"></p>
											<p>{{ $t('bitteGebenSieBeiKontaktaufnahmeIhreVollstndigenKon') }}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>


			</div>
			<FooterBar></FooterBar>
		</ion-content>
	</ion-page>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {IonContent, IonPage} from '@ionic/vue';
import FooterBar from "@/components/positions/FooterBar.vue";
import HeaderLogo from "@/components/branding/HeaderLogo.vue";
import useTranslation from "@/composable/translation/useTranslation";

export default defineComponent({
	name: 'TermsPage',
	components: {
		HeaderLogo,
		FooterBar, IonContent, IonPage
	},
	setup() {
		const {t} = useTranslation();
		return {t,}
	}
});

</script>
