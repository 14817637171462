/* eslint-disable @typescript-eslint/no-unused-vars */

import {FaqList} from "@/greeve/faq/faq_list.type";
import useFaqFactory from "@/composable/faq/useFaqFactory";
const {getFaqFactory} = useFaqFactory();

const faq = {
	namespaced: true,
	state: () => ({
		faqs: FaqList,
	}),
	mutations: {
		setFaqs(state: any, faqList: FaqList) {
			state.faqs = faqList;
		},
	},
	actions: {
	},
	getters: {
		getFaqs: (state: any): FaqList|undefined => {
			if (state.faqs instanceof FaqList) {
				return state.faqs;
			} else if (!(state.faqs instanceof FaqList) && state.faqs) {
				return getFaqFactory().createFaqListByResponse(state.faqs);
			} else {
				return undefined;
			}
		},
	}
}

export default faq;