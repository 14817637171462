<template>
	<div v-if="getCurrentActiveItem().type === 'image'" class="p-1 sm:p-3 mx-2 lg:mx-4 mb-2 sm:mb-1 flex gap-3 items-center" >
		<div class="inline-flex h-6">
			<span class="pr-2">{{ $t('hd') }}</span>
			<Switch v-model="hd_image" @update:model-value="updateImage"
							:class="[hd_image ? 'bg-gr-primary' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-2 focus:ring-offset-2']">
			<span
					:class="[hd_image ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out mt-[2px]']">
				<span
								:class="[hd_image ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']"
								aria-hidden="true">
							<svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
								<path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round"
											stroke-linejoin="round"/>
							</svg>
						</span>
						<span
								:class="[hd_image ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']"
								aria-hidden="true">
							<svg class="h-3 w-3 text-gr-primary" fill="currentColor" viewBox="0 0 12 12">
								<path
										d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"/>
							</svg>
						</span>
					</span>
			</Switch>
		</div>
		<div class="flex ml-1">
			<ListSelectDropdown :list="getImageSizes()" :show-above="true" min-width="min-w-32"
													:model="imageSize" :top-margin="false" @change-selected-value="updateImageSize"
													:default-value="getImageSizes()[2]"></ListSelectDropdown>
		</div>
	</div>
	<div v-if="getCurrentActiveItem().type === 'text_to_speech'" class="p-1 sm:p-3 mx-2 lg:mx-4 mb-2 sm:mb-1 flex gap-3 items-center" >
		<div class="flex">
			<span class="self-center mr-2">{{ $t('voices') }}</span>
			<ListSelectDropdown :list="getAudioVoices()" :show-above="true" min-width="min-w-32"
													:model="selectedAudio" :top-margin="false" @change-selected-value="updateAudioVoice"
													:default-value="getAudioVoices()[0]"></ListSelectDropdown>
			<div class="self-center h-6 w-6 ml-2 cursor-pointer">
				<PlayIcon v-if="!isAudioVoiceDemoPlaying" @click="playVoiceDemo"></PlayIcon>
				<StopIcon v-else @click="pauseVoiceDemo"></StopIcon>
			</div>
		</div>
	</div>
<!--	<div v-else-if="getCurrentActiveItem().type === 'text_to_speech'">TEXT TO SPEECH</div>-->
	<div class="mx-2 lg:mx-4 mb-2 sm:mb-1 flex gap-3">
		<ul role="list" v-for="item in outputMethode" :key="item.name">
			<li @click="selectType(item)" class="cursor-pointer px-4 flex py-1 w-auto items-center rounded-2xl"
					:class="[!item.active ? 'bg-gray-100 dark:bg-gr-darkest text-gray-400 dark:text-gr-darker' : 'bg-gray-200 dark:bg-gr-darker text-gray-700 dark:text-gray-400', isDisabled ? ' disabled:bg-slate-50' : '']">
				<component :is="item.icon"
									 class="h-5 w-5 lg:mr-2"
									 aria-hidden="true"/>
				<p class="hidden lg:block">{{ item.name }}</p>
			</li>
		</ul>
	</div>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue';
import useTranslation from '@/composable/translation/useTranslation';
import {ChatBubbleBottomCenterTextIcon, MicrophoneIcon, PhotoIcon, SignalIcon} from '@heroicons/vue/20/solid';
import {ChatType} from '@/views/assistant/Chat/ChatPage.vue';
import {Switch} from '@headlessui/vue';
import {AudioVoice} from '@/greeve/search/item/type/subtype/search_item_text_to_speech.type';
import {ImageSize} from '@/greeve/search/item/type/subtype/search_item_image.type';
import useAssistant from '@/composable/greeve/useAssistant';
import ListSelectDropdown from '@/components/core/ListSelectDropdown.vue';
import { StopIcon, PlayIcon } from '@heroicons/vue/20/solid'

interface OutputMethod {
	name: string;
	type: ChatType;
	icon: any; // Need further details to provide the exact type;
	active: boolean;
}

export default defineComponent({
	name: 'OutputTag',
	components: {ListSelectDropdown, PhotoIcon, MicrophoneIcon, ChatBubbleBottomCenterTextIcon, Switch, StopIcon, PlayIcon},
	props: {
		selectedType: {
			type: String as () => ChatType,
			default: () => ChatType.CHAT,
		},
		isDisabled: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['updateSelectedType',],
	setup(props, {emit}) {

		const {t} = useTranslation();
		const {getAudioVoices, getAudioVoiceDemo, getImageSizes} = useAssistant();
		const hd_image = ref(false);
		const selectedAudio = ref<AudioVoice|undefined>()
		const imageSize = ref<ImageSize|undefined>()
		const isAudioVoiceDemoPlaying = ref(false);
		const audio = ref<HTMLAudioElement | null>(null);

		const outputMethode = ref<OutputMethod[]>([
			{name: t('chat.modes.chat'), type: ChatType.CHAT, icon: ChatBubbleBottomCenterTextIcon, active: true},
			{name: t('chat.modes.image'), type: ChatType.IMAGE, icon: PhotoIcon, active: false},
			{name: t('chat.modes.text_to_speech'), type: ChatType.TEXT_TO_SPEECH, icon: SignalIcon, active: false},
		]);

		function getCurrentActiveItem() {
			return outputMethode.value.find(item => item.active);
		}

		function updateImage() {
			const activeItem = getCurrentActiveItem();
			if (activeItem) {
				emit('updateSelectedType', activeItem.type, imageSize.value, hd_image.value, selectedAudio.value);
			}
		}

		function updateImageSize(model: any, key: any, value: ImageSize) {
			const activeItem = getCurrentActiveItem();
			imageSize.value = value;
			if (activeItem) {
				emit('updateSelectedType', activeItem.type, imageSize.value, hd_image.value, selectedAudio.value);
			}
		}

		function updateAudioVoice(model: any, key: any, value: AudioVoice) {
			const activeItem = getCurrentActiveItem();
			if (value !== selectedAudio.value && isAudioVoiceDemoPlaying) {
				pauseVoiceDemo();
			}
			selectedAudio.value = value;
			if (activeItem) {
				emit('updateSelectedType', activeItem.type, imageSize.value, hd_image.value, selectedAudio.value);
			}
		}

		function playVoiceDemo() {
			if (selectedAudio.value) {
				isAudioVoiceDemoPlaying.value = true;
				const audioVoiceDemoUrl = getAudioVoiceDemo(selectedAudio.value);
				audio.value = new Audio(audioVoiceDemoUrl);
				audio.value.addEventListener('ended', () => {
					isAudioVoiceDemoPlaying.value = false;
				});
				audio.value.play()
				.then(() => {
					console.log(`Audio playback started: ${audioVoiceDemoUrl}`);
				})
				.catch((error) => {
					console.error(`Error playing audio: ${error}`);
				});
			}
		}

		function pauseVoiceDemo() {
			if (selectedAudio.value && isAudioVoiceDemoPlaying.value && audio.value) {
				isAudioVoiceDemoPlaying.value = false;
				audio.value.pause();
			}
		}

		function selectType(selectedItem: OutputMethod) {
			outputMethode.value.forEach((method) => {
				if (method.type === selectedItem.type) {
					method.active = true;
					emit('updateSelectedType', selectedItem.type, imageSize.value, hd_image.value, selectedAudio.value);
				} else {
					method.active = false;
				}
			});
		}

		return {
			outputMethode, t, selectType, hd_image, getCurrentActiveItem, updateImage, getImageSizes, imageSize, updateImageSize, getAudioVoices, selectedAudio, updateAudioVoice, getAudioVoiceDemo, pauseVoiceDemo, playVoiceDemo, isAudioVoiceDemoPlaying
		};
	},
});

</script>
<style lang="scss">
</style>
