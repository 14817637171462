import {CustomerInterface} from "@/greeve/shop/customer/customer.interface";
import {CartItemList} from "@/greeve/shop/cart/item/cart_item_list.type";
import {CheckoutSessionInterface} from "@/greeve/shop/checkout/session/checkout_session.interface";
import {
	DiscountItemList
} from '@/greeve/shop/cart/discount/discount_item_list.type';

export const enum CartState {
	NEW = 'new',
	IN_PROGRESS = 'in_progress',
	PENDING = 'pending',
	DONE = 'done',
	ERROR = 'error',
}

export interface CartInterface {
	id: string,
	state: CartState,
	items: CartItemList,
	discounts: DiscountItemList,
	customer?: CustomerInterface,
	checkoutSession?: CheckoutSessionInterface,
	currency: string,
	total_amount: number,
	tax_amount: number,
	shipping_amount: number,
	data?: any,
	created_at?: Date,
	updated_at?: Date,
}

export interface CartItemInterface {
	id: string;
	product_id: string,
	price_id: string,
	name: string,
	amount: number,
	quantity: number,
	description?: string
	description_2?: string
	labels?: Array<LabelInterface>|null
	product_reference?: string|undefined;
}

export interface LabelInterface {
	key: string,
	vale: string,
}