import {computed, ComputedRef, onBeforeMount, ref} from "vue";
import store from "@/store";
import {FlowList} from '@/greeve/flow/flow_list.type';
import {FlowTemplate} from '@/greeve/flow/type/template/flow_template.type';
import {GreeveApiFlow} from '@/greeve/api/flow';
import {AbstractFlow} from '@/greeve/flow/abstract_flow.type';
import {
	FlowProcessDependencyList
} from '@/greeve/flow/process/dependency/flow_process_dependency_list.type';
import {
	AbstractFlowProcess
} from '@/greeve/flow/process/abstract_flow_process.type';
import {AbstractFlowQueue} from '@/greeve/flow/queue/abstract_flow_queue.type';
import {FlowQueueList} from '@/greeve/flow/queue/flow_queue_list.type';

export default function useFlow() {
	const flows: ComputedRef<FlowList> = computed(() => store.getters['flow/getFlows']);
	const flowTemplates: ComputedRef<FlowList> = computed(() => store.getters['flow/getFlowTemplates']);
	const lastFlowUuid: ComputedRef<string> = computed(() => store.getters['flow/getLastFlowUuid']);
	const isFlowsLoading = ref(false);
	const isFlowQueueLoading = ref(false);
	const isFlowTemplatesLoading = ref(false);

	function getFlowById(flow_id: number): AbstractFlow|undefined {
		return store.getters['flow/getFlow_by_Id'](flow_id);
	}

	function getFlowByUuid(flow_uuid: string): AbstractFlow|undefined {
		return store.getters['flow/getFlow_by_Uuid'](flow_uuid);
	}

	function getFlowQueueById(flow_queue_id: number): AbstractFlowQueue|undefined {
		return store.getters['flow/getFlowQueue_by_Id'](flow_queue_id);
	}

	async function resetFlows() {
		await store.dispatch('flow/resetFlows');
		await store.dispatch('flow/resetFlowTemplates');
		await initFlows(true);
		await initFlowTemplates(true);
	}

	function setFlows(flowList: FlowList) {
		store.commit('flow/setFlows', flowList);
	}

	function setFlow(flow: AbstractFlow) {
		store.commit('flow/setFlow', flow);
	}

	function setLastFlowUuid(flowUuid?: string|null) {
		store.commit('flow/setLastFlowUuid', flowUuid);
	}

	function setFlowProcessDependencies(flowUuid: string, flowProcessDependencyList: FlowProcessDependencyList) {
		store.commit('flow/setFlowProcessDependencies', {flowUuid: flowUuid, flowProcessDependencyList: flowProcessDependencyList});
	}

	function setFlowQueues(flowUuid: string, flowQueueList: FlowQueueList) {
		store.commit('flow/setFlowQueues', {flowUuid: flowUuid, flowQueueList: flowQueueList});
	}

	function setFlowQueue(flowUuid: string, flowQueue: AbstractFlowQueue) {
		store.commit('flow/setFlowQueue', {flowUuid: flowUuid, flowQueue: flowQueue});
	}

	function setFlowQueueState(flowUuid: string, flowQueue: AbstractFlowQueue) {
		store.commit('flow/setFlowQueueState', {flowUuid: flowUuid, flowQueue: flowQueue});
	}

	function setFlowTemplates(flowList: FlowList) {
		store.commit('flow/setFlowTemplates', flowList);
	}

	async function convertFlowTemplate(flowTemplate: FlowTemplate) {
		if (flowTemplate) {
			const result = await GreeveApiFlow.convertFlowTemplate_to_Flow(flowTemplate.uuid);
			if (result) {
				await initFlows(true);
				return result;
			} else {
				throw Error('Cannot convert Flow!');
			}
		}
		return false;
	}

	//TODO async
	async function runFlow(flow: AbstractFlow) {
		if (flow) {
			const result = await GreeveApiFlow.runFlow(flow.uuid);
			if (result) {
				if (result.flow_uuid) {
					initFlow(result.flow_uuid);
					return result.uuid;
				} else {
					await initFlows(true);
				}
				return result.uuid;
			} else {
				throw Error('Cannot run Flow!');
			}
		}
		return false;
	}

	async function updateFlowInfo(flowUuid: string, flowName: string, flowDescription: string) {
		if (flowUuid) {
			const result = await GreeveApiFlow.updateFlowInfo(flowUuid, flowName, flowDescription);
			if (result) {
				//TODO CHECK if ncecessary or change only selected FLOW from result
				// await initFlow(flowUuid);
				await initFlows(true);
				return result;
			} else {
				throw Error('Cannot update Flow Info!');
			}
		}
		return false;
	}

	async function deleteFlow(flowUuid: string) {
		if (flowUuid) {
			const result = await GreeveApiFlow.deleteUserFlow_by_Uuid(flowUuid);
			if (result) {
				//TODO CHECK if ncecessary or remove only selected flow by uuid
				await initFlows(true);
				return result;
			} else {
				throw Error('Cannot delete Flow!');
			}
		}
		return false;
	}

	async function updateFlowProcess(flowProcess: AbstractFlowProcess, formData?: FormData) {
		if (!formData) {
			formData = new FormData();
		}
		const result = await GreeveApiFlow.updateFlowProcess(flowProcess, formData);
		if (result) {
			//TODO CHECK if ncecessary or change only selected FLOW from result
			await initFlows(true);
			return result;
		} else {
			throw Error('Cannot update Flow Info!');
		}
		return false;
	}


	async function uploadAudioFileToFlowProcess(flowProcess: AbstractFlowProcess, files: File[]) {
		const result = await GreeveApiFlow.uploadAudioFiles(flowProcess.uuid, files);
		if (result) {
			//TODO CHECK if neeccessary or change only selected FLOW from result
			await initFlows(true);
			return result;
		} else {
			throw Error('Cannot update Flow Info!');
		}
	}

	async function getEstimatedCreditsForFlow(flowUuid: string) {
		if (flowUuid) {
			return GreeveApiFlow.getEstimatedFlowQuotaAmount(flowUuid);
		}
	}

	async function getFlowProcessDependency_List(flowUuid: string) {
		if (flowUuid) {
			return GreeveApiFlow.getFlowProcessDependency_List(flowUuid);
		}
	}

	async function initFlowProcessDependencies(flowUuid: string) {
		if (isFlowsLoading.value) {
			return;
		}
		isFlowsLoading.value = true;

		await new Promise(resolve => setTimeout(resolve, 5000));

		const flowProcessDependencyList = await getFlowProcessDependency_List(flowUuid);

		if (flowProcessDependencyList) {
			console.log("DEPENDENCIES ", flowProcessDependencyList)
			setFlowProcessDependencies(flowUuid, flowProcessDependencyList);
		}

		isFlowsLoading.value = false;
	}

	async function isFlowQueueRunning(flowQueueUuid: string) {
		if (flowQueueUuid) {
			return GreeveApiFlow.isFlowQueueRunning(flowQueueUuid);
		}
	}

	async function getFlowQueue(flowQueueUuid: string) {
		if (flowQueueUuid) {
			return GreeveApiFlow.getFlowQueue_by_QueueUuid(flowQueueUuid);
		}
	}

	async function getFlowQueue_List(flowUuid: string) {
		if (flowUuid) {
			return GreeveApiFlow.getFlowQueues_by_FlowUuid(flowUuid);
		}
	}

	async function refreshFlowQueues_by_FlowUuid(flowUuid: string) {
		if (isFlowQueueLoading.value) {
			return;
		}

		isFlowQueueLoading.value = true;

		await new Promise(resolve => setTimeout(resolve, 5000));

		const flowQueues = await getFlowQueue_List(flowUuid);

		if (flowQueues) {
			setFlowQueues(flowUuid, flowQueues);
		}

		isFlowQueueLoading.value = false;
	}

	async function refreshFlowQueue_by_QueueUuid(flowUuid: string, flowQueueUuid: string) {
		if (isFlowQueueLoading.value) {
			return;
		}

		isFlowQueueLoading.value = true;

		await new Promise(resolve => setTimeout(resolve, 5000));

		const flowQueue = await getFlowQueue(flowQueueUuid);

		if (flowQueue) {
			setFlowQueue(flowUuid, flowQueue);
		}

		isFlowQueueLoading.value = false;
	}


	async function initFlow(flowUuid: string) {
		isFlowsLoading.value = true;
		setTimeout(() => {
			isFlowsLoading.value = false;
		}, 5000);
		const flow = await GreeveApiFlow.getUserFlow_by_Uuid(flowUuid);
		if (flow) {
			setFlow(flow);
			isFlowsLoading.value = false;
			return flow;
		} else {
			throw new Error('Error loading Flow!');
		}
	}

	async function initFlows(forceRefresh = false) {
		if ( (!flows.value || flows.value.length < 1 || forceRefresh) && !isFlowsLoading.value) {
			isFlowsLoading.value = true;
			//TODO check and show unauthorized overlay or error message
			const flowList = await GreeveApiFlow.getUserFlows();
			if (flowList) {
				setFlows(flowList);
			}
			isFlowsLoading.value = false;
		}
	}

	async function initFlowTemplates(forceRefresh = false) {
		if ( (!flowTemplates.value || flowTemplates.value.length < 1 || forceRefresh) && !isFlowTemplatesLoading.value) {
			isFlowTemplatesLoading.value = true;
			const flowList = await GreeveApiFlow.getFlowTemplates();
			if (flowList) {
				setFlowTemplates(flowList);
			}
			isFlowTemplatesLoading.value = false;
		}
	}

	//TODO addFlow, modifyFlow, updateFlow, updateFlowProcess, updateFlowProcessConfig...

	onBeforeMount(() => {
		// initFlowTemplates();
	});

	return {
		flows,
		flowTemplates,
		initFlows,
		initFlowTemplates,
		resetFlows,
		setFlows,
		setFlowTemplates,
		convertFlowTemplate,
		runFlow,
		getEstimatedCreditsForFlow,
		updateFlowInfo,
		getFlowProcessDependency_List,
		setFlow,
		setFlowProcessDependencies,
		initFlow,
		initFlowProcessDependencies,
		deleteFlow,
		uploadAudioFileToFlowProcess,
		updateFlowProcess,
		getFlowById,
		getFlowByUuid,
		getFlowQueueById,
		isFlowQueueRunning,
		getFlowQueue,
		getFlowQueue_List,
		refreshFlowQueues_by_FlowUuid,
		refreshFlowQueue_by_QueueUuid,
		lastFlowUuid,
		setLastFlowUuid,
		setFlowQueueState,
	}
}
