import {WizzardInterface} from '@/greeve/flow/wizzard/base/wizzard.interface';
import {
	FlowSubType,
	FlowWizzardState,
} from '@/greeve/flow/flow.interface';
import i18n from '@/i18n';
import {AbstractFlow} from '@/greeve/flow/abstract_flow.type';
import {
	StepSummaryDataInterface,
	WizzardSummary,
} from '@/greeve/flow/wizzard/base/wizzard_summary';

export interface WizzardStepStatus {
	step: number,
	current: boolean,
	title: string,
	description: string,
}

export abstract class AbstractWizzard implements WizzardInterface {
	name: string | undefined;
	wizzardSummary: WizzardSummary | undefined;
	_state: FlowWizzardState;
	_flow: AbstractFlow | undefined;
	currentStep: number;
	_estimatedCosts: number | undefined;
	_isNextStepEnabled = true;

	abstract getStepsPath(): string;

	abstract getType(): FlowSubType;

	protected constructor() {
		this._state = FlowWizzardState.NEW;
		this.currentStep = 0;
	}

	getWizzardSummaryBasePath(): string {
		return 'base/summary';
	}

	get state(): FlowWizzardState {
		return this._state;
	}

	set state(value: FlowWizzardState) {
		this._state = value;
	}

	get flow(): AbstractFlow | undefined {
		return this._flow;
	}

	set flow(value: AbstractFlow | undefined) {
		this._flow = value;
	}

	get estimatedCosts(): number | undefined {
		return this._estimatedCosts;
	}

	set estimatedCosts(value: number | undefined) {
		this._estimatedCosts = value;
	}

	get isNextStepEnabled(): boolean {
		return this._isNextStepEnabled;
	}

	set isNextStepEnabled(value: boolean) {
		this._isNextStepEnabled = value;
	}

	hasFlow(): boolean {
		return this._flow !== undefined;
	}

	getWizzardName(): string {
		if (this.name && this.name.length > 0) {
			return this.name;
		}

		return i18n.global.t('flow.wizzard.' + this.getType() + '.title');
	}

	getWizzardSummaryComponent(): string {
		return 'WizzardSummaryComponent';
	}

	getStepsCount(): number {
		return 0;
	}

	getCurrentStep(): number {
		return this.currentStep;
	}

	//https://tailwindcomponents.com/gradient-generator/
	getWizzardColor(): string {
		return 'bg-gr-primary';
	}

	getWizzardPrimaryTextColor(): string {
		return 'text-gr-primary2';
	}

	//TODO check last step is always summary
	isLastStepSummary(): boolean {
		return this.getCurrentStep() === this.getStepsCount();
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	getWizzardSummaryDataByStep(step: number): StepSummaryDataInterface|undefined {
		return;
	}

	goToNextStep(): number | undefined {
		if (this.getCurrentStep() < this.getStepsCount()) {
			this.addSummaryByCurrentStep();
			this.currentStep = this.getCurrentStep() + 1;
			return this.getCurrentStep();
		}
		return;
	}

	goToStep(step: number): number | undefined {
		if (step < this.getStepsCount()) {
			this.addSummaryByCurrentStep();
			this.currentStep = step;
			return this.getCurrentStep();
		}
		return;
	}

	goToSummaryStep(): undefined {
		if (this.isLastStep()) {
			this.addSummaryByCurrentStep();
		}
		return;
	}

	private addSummaryByCurrentStep() {
		const summaryStepData = this.getWizzardSummaryDataByStep(this.getCurrentStep());
		if (summaryStepData) {
			this.getWizzardSummary().addSummaryData(summaryStepData);
		}
	}

	goToPreviousStep(): number | undefined {
		if (this.getCurrentStep() > 0) {
			this.currentStep = this.getCurrentStep() - 1;
			return this.getCurrentStep();
		}
		return;
	}

	getStepComponentMap(): Record<number, string> {
		return {};
	}

	getCurrentStepComponent(): string | undefined {
		if (this.getCurrentStep() < 1) {
			return undefined;
		}
		const stepMap = this.getStepComponentMap();
		const stepKeys = Object.keys(stepMap);
		if (stepKeys.length < 1) {
			return undefined;
		}
		return stepMap[this.getCurrentStep()];
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	getStepTitle(step: number): string {
		return '';
	}

	getStepsStatusInfo(): Record<number, WizzardStepStatus> {
		return {};
	}

	getStepProgressInPercent(): number {
		const currentStep = this.getCurrentStep();
		const maxSteps = this.getStepsCount();
		return Math.round((currentStep / maxSteps) * 100);
	}

	getStepStatusInfo(step: number): WizzardStepStatus | undefined {
		if (this.getStepsStatusInfo()[step]) {
			return this.getStepsStatusInfo()[step];
		}
		return undefined;
	}

	isPreviousStepAllowed(): boolean {
		if (this.getCurrentStep() > 1) {
			return true;
		}
		return false;
	}

	isNextStepAllowed(): boolean {
		if (!this.isNextStepEnabled) {
			return false;
		}
		return this.getCurrentStep() < this.getStepsCount();

	}

	isStepAllowed(step: number): boolean {
		return step <= this.getStepsCount();
	}

	isFirstStep(): boolean {
		return this.getCurrentStep() === 1;
	}

	isLastStep(): boolean {
		return this.getCurrentStep() === this.getStepsCount();
	}

	isSimulateAble(): boolean {
		return false;
	}

	isWizzardComplete(): boolean {
		return this._state === FlowWizzardState.READY;
	}

	hasSummary(): boolean {
		return true;
	}

	getWizzardSummary(): WizzardSummary {
		if (!this.wizzardSummary) {
			this.wizzardSummary = new WizzardSummary();
		}
		return this.wizzardSummary;
	}

	serialize(): string {
		return JSON.stringify(this);
	}
}