import {FlowProcessList} from '@/greeve/flow/process/flow_process_list.type';
import {
	FlowInterface,
	FlowState,
	FlowSubType,
	FlowType,
} from './flow.interface';
import i18n from '@/i18n';
import {
	FlowProcessDependencyList,
} from '@/greeve/flow/process/dependency/flow_process_dependency_list.type';
import {
	FlowProcessDependency,
} from '@/greeve/flow/process/dependency/flow_process_dependency.type';
import {
	AbstractFlowProcess,
	FlowProcessVueFlowPositionInterface,
} from '@/greeve/flow/process/abstract_flow_process.type';
import {FlowQueueList} from '@/greeve/flow/queue/flow_queue_list.type';
import useTranslation from '@/composable/core/useTranslation';

export interface FlowFeatureInterface {
	key: any,
	value: string,
}

export interface FlowProcessVueFlowInterface {
	id: number|string,
	type?: string,
	template?: any,
	label?: string,
	position?: FlowProcessVueFlowPositionInterface,
	source?: string,
	target?: string,
	initialized?: boolean,
	animated?: boolean
	process?: AbstractFlowProcess
}

export abstract class AbstractFlow implements FlowInterface{
	id: number;
	uuid: string;
	user_id: number;
	flow_config_id?: number|null;
	// flow_config?: FlowConfig|null,
	flow_processes?: FlowProcessList|null;
	flow_process_dependencies?: FlowProcessDependencyList|null;
	flow_queues?: FlowQueueList|null;
	parent_id?: number|null;
	type: FlowType;
	sub_type?: FlowSubType|null;
	state: FlowState;
	reference?: string|null;
	name?: string|null;
	description?: string|null;
	data?: any;
	created_at?: Date;
	updated_at?: Date;


	constructor(
			id: number, uuid: string, user_id: number, type: FlowType, state: FlowState,
			flow_config_id?: number|null, flow_processes?: FlowProcessList|null, flow_process_dependencies?: FlowProcessDependencyList|null, flow_queues?: FlowQueueList|null, parent_id?: number|null, sub_type?: FlowSubType | undefined, reference?: string | undefined,
			name?: string | undefined, description?: string|null, data: any = null, created_at?: Date, updated_at?: Date) {
		this.id = id;
		this.uuid = uuid;
		this.user_id = user_id;
		this.flow_config_id = flow_config_id;
		this.flow_processes = flow_processes;
		this.flow_process_dependencies = flow_process_dependencies;
		this.flow_queues = flow_queues;
		this.parent_id = parent_id;
		this.type = type;
		this.sub_type = sub_type;
		this.state = state;
		this.reference = reference;
		this.name = name;
		this.description = description;
		this.data = data;
		this.created_at = created_at;
		this.updated_at = updated_at;
	}

	getTranslatedName(): string
	{
		if (this.name && this.name.length > 0) {
			return this.name;
		}

		return i18n.global.t('flow.'+this.type+'.'+this.sub_type+'.title');
	}

	getTranslatedDescription(): string
	{
		if (this.description && this.description.length > 0) {
			return this.description;
		}

		return i18n.global.t('flow.'+this.type+'.'+this.sub_type+'.description');
	}

	getTranslatedFeatureList(): Record<string, any>
	{
		if (!this.type || !this.sub_type) {
			return [];
		}
		const locale: string|any = useTranslation().locale.value;
		const messages: any = useTranslation().messages.value;

		return messages[locale]['flow'][this.type][this.sub_type ?? '']['features'];
	}

	getUrlName(): string
	{
		const name = this.getTranslatedName();
		if (!name) {
			return this.uuid;
		}
		return name.toLowerCase().
		replace(/\s+/g, '-').
		replace(/[^\w-]+/g, '').
		replace(/--+/g, '-');
	}

	isFlowRunning(): boolean {
		return this.state === FlowState.RUNNING;
	}

	isFlowInErrorState(): boolean {
		return this.state === FlowState.ERROR;
	}

	isFlowNew(): boolean {
		return this.state === FlowState.NEW;
	}

	isFlowExecutionFinished(): boolean {
		return this.state === FlowState.DONE;
	}

	getFlowIcon(): any
	{
		return;
	}

	getFlowLink(): any
	{
		return '/flow?uuid=' + this.uuid;
	}

	getFlowPriceInformation(): string|undefined
	{
		return;
	}

	getFlowProcessDependencyList_for_VueFlow(): Array<FlowProcessVueFlowInterface>|undefined
	{
		if (this.flow_process_dependencies) {
			const flowProcessDependencyList: FlowProcessDependencyList|any[] = [...this.flow_process_dependencies];
			if (!flowProcessDependencyList) {
				return;
			}
			const result: Array<FlowProcessVueFlowInterface> = [];
			flowProcessDependencyList.forEach((flowProcessDependency: FlowProcessDependency) => {
				let dependency_link: FlowProcessVueFlowInterface|undefined = undefined;
				let fromId = null;
				let toId = null;
				if (flowProcessDependency.from) {
					const fromFlowProcessDependency = flowProcessDependency.from;
					fromId = fromFlowProcessDependency.id ? fromFlowProcessDependency.id : (fromFlowProcessDependency.uuid ?? undefined);
					//TODO use type default, input, output or custom
					const fromFlowProcess: FlowProcessVueFlowInterface = {id: fromId, type: 'custom', template: fromFlowProcessDependency.getVueFlowTemplate(), initialized: undefined, label: fromFlowProcessDependency.getVueFlowLabel(), position: fromFlowProcessDependency.getVueFlowPosition(), process: fromFlowProcessDependency};
					result.push(fromFlowProcess);
				}
				if (flowProcessDependency.to) {
					const toFlowProcessDependency = flowProcessDependency.to;
					toId = toFlowProcessDependency.id ? toFlowProcessDependency.id : (toFlowProcessDependency.uuid ?? undefined);
					const toFlowProcess: FlowProcessVueFlowInterface = {id: toId, type: 'custom', template: toFlowProcessDependency.getVueFlowTemplate(), initialized: undefined, label: toFlowProcessDependency.getVueFlowLabel(), position: toFlowProcessDependency.getVueFlowPosition(), process: toFlowProcessDependency};
					result.push(toFlowProcess);
				}
				if (fromId && toId) {
					dependency_link = {
						id: 'e'+fromId+'-'+'e'+toId,
						type: 'custom',
						source: String(fromId),
						target: String(toId),
						initialized: undefined,
						animated: true,
					}
				} else if (fromId && !toId) {
					dependency_link = {
						id: 'e'+fromId,
						type: 'custom',
						source: String(fromId),
						initialized: undefined,
						animated: true,
					}
				} else if (!fromId && toId) {
					dependency_link = {
						id: 'e'+toId,
						type: 'custom',
						target: String(toId),
						initialized: undefined,
						animated: true,
					}
				}
				if (dependency_link) {
					result.push(dependency_link);
				}
			});

			return result;
		}

		return;
	}

	getCreateDateFormatted(withTime = true): string
	{
		let date = '';
		if (!this.created_at) {
			return date;
		}

		date = this.created_at.toLocaleDateString();

		if (withTime) {
			date = date + ' ' + this.created_at.toLocaleTimeString([], {timeStyle: 'short'})
		}
		return date;
	}

	getUpdateDateFormatted(withTime = true): string
	{
		let date = '';
		if (!this.updated_at) {
			return date;
		}

		date = this.updated_at.toLocaleDateString();

		if (withTime) {
			date = date + ' ' + this.updated_at.toLocaleTimeString([], {timeStyle: 'short'})
		}
		return date;
	}
}