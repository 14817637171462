<template>
	<div>
		<div v-if="cart && cart.items && cart.items.length > 0">
			<div class="hidden lg:block dark:bg-gr-darkest bg-gray-200 rounded-xl pt-12">
				<h1 class="sr-only">{{ $t('shoppingCart') }}</h1>
				<section aria-labelledby="summary-heading"
						 class="bg-gray-50 dark:bg-gr-dark px-4 pb-10 sm:px-6 lg:col-start-2 lg:row-start-1 lg:bg-transparent lg:px-0 lg:pb-16">
					<div class="justify-center items-center sm:max-w-xl mt-6 m-4 lg:w-4/5 sm:m-auto">
						<div class="">
							<h2 id="summary-heading" class="text-lg font-semibold text-gray-900 dark:text-white">
								{{ $t('shoppingCart') }}</h2>
							<ul role="list" class="text-sm font-medium text-gray-500">
								<li v-for="cartItem in cart.items" :key="cartItem.id" class="flex items-center py-6">
									<img loading="lazy" :src="cartItem.getProduct()?.getDefaultImage()"
										 :alt="cartItem.getProduct()?.getDefaultImage()"
										 class="h-10 w-10 flex-none rounded-md border bg-white border-gray-200 dark:border-gr-dark"/>
									<div class="ml-2 sm:ml-4 flex-auto">
										<h2 class="text-left text-xs text-gray-900 dark:text-gray-400 font-bold truncate">
											<a :href="cartItem.getProduct()?.detailLink">{{
																								cartItem.getProduct()?.getProductName()
												}}</a>
										</h2>
										<h3 v-if="cartItem.getProduct()?.getProductDescription()"
											class="text-left font-medium text-xs text-gray-900 dark:text-gray-400">
											<a :href="cartItem.getProduct()?.getDetailLink()">{{
																								cartItem.getProduct()?.getProductDescription()
												}}</a>
										</h3>
									</div>
									<div class="mx-4 flex">
										<div class="ion-activatable flex bg-gray-200 dark:bg-gr-dark py-1 rounded-lg mr-0 sm:mr-2">
											<button class="mx-2 grid content-center"
													@click="removeCartItem_by_Id(cartItem.id)">
												<ion-icon v-if="true" class="w-4" slot="icon-only"
														  :icon="icons.trashOutline"></ion-icon>
												<ion-icon v-else slot="icon-only"
														  :icon="icons.removeOutline"></ion-icon>
												<ion-ripple-effect></ion-ripple-effect>
											</button>
											<!--														TODO use input instead-->
											<!--(														<input class="bg-gray-50 dark:bg-gr-darker py-2 h-5 w-7 text-center outline-none text-gray-900 dark:text-gray-400">-->
											<label :for="`quantity-${cartItem.id}`" class="sr-only">{{
																								$t('quantity', {description: cartItem.getProduct()?.description})
												}}</label>
											<div class="flex"
												 v-if="cartItem.getPrice()?.type !== getRecurringPriceType()">
												<ListSelectDropdown :list="cartItem.getMaxItemCountList()"
																	:model="cartItem" :top-margin="false"
																	:default-value="cartItem.quantity"
																	@change-selected-value="updateCartItemCount"></ListSelectDropdown>
												<button class="px-2 py-0 grid content-center"
														v-if="cartItem.quantity < cartItem.getMaxItemCount()"
														@click="addProductToCart(cartItem.product_id)">
													<ion-icon class="w-4" slot="icon-only"
															  :icon="icons.addOutline"></ion-icon>
												</button>
											</div>
										</div>
									</div>
									<p class="flex-none text-xs font-medium  text-gray-900 dark:text-gray-400">€
										{{ cartItem.getAmount() }}
									</p>
								</li>
							</ul>
							<div class="hidden lg:block">
								<dl class="space-y-1 border-t border-gray-400 pt-6 pb-2 text-sm font-medium text-gray-500">
									<div class="flex justify-between">
										<dt>{{ $t('subtotal') }}</dt>
										<dd class="text-gray-600">{{ cart.getTotalNettoAmountCalculated(true) }}</dd>
									</div>
								</dl>
								<dl class="py-2 text-sm font-medium text-gray-500" v-if="cart.hasTaxAmount()">
									<div class="flex justify-between">
										<dt>{{ $t('taxes') }}</dt>
										<dd class="text-gray-600">{{ cart.getTotalTaxAmountCalculated(true) }}</dd>
									</div>
									<div class="flex items-center justify-between font-semibold mt-2 border-t border-gray-500 py-2 text-gray-500 dark:text-gray-400">
										<dt class="text-base">{{ $t('total') }}</dt>
										<dd class="text-base">{{ cart.getTotalAmountCalculated(true) }}</dd>
									</div>
								</dl>
								<div v-if="cart.hasDiscounts()">
									<dl class="py-2 text-sm font-medium text-gray-500">
										<div class="flex justify-between">
											<dt>{{ $t('discounts') }}</dt>
											<dd v-for="discount in cart.discounts" :key="discount.id"
												class="text-gray-600">
										<span class="inline-flex items-baseline text-right align-middle">
												{{ discount.getDiscountName() }} / -{{ discount.getDiscountPercent() }}%
										</span>
												<span class="inline-flex center justify-end w-5 float-right cursor-pointer h-full"
													  @click="removeDiscountFromCart(discount)"><XMarkIcon/></span>
											</dd>
										</div>
									</dl>
									<dl class="space-y-1 border-t border-gray-400 pt-6 pb-2 text-sm font-medium text-gray-500">
										<div class="flex justify-between">
											<dt>{{ $t('total') }}</dt>
											<dd class="text-gray-600">{{ cart.getTotalAmountCalculated(true) }}</dd>
										</div>
									</dl>
								</div>
								<div v-else>
									<div class="container text-center mt-1 mb-2">
										<form @submit.prevent="addDiscount">

											<div class="mt-3 grid grid-flow-row-dense grid-cols-5 grid-rows-1">
												<div class="col-span-4">
													<input-voucher-field v-model:value="voucherCode"
																		 :input-placeholder="$t('shop.checkout.discount.input_add_discount')"
																		 input-id="addDiscount"
																		 :input-disabled="isCheckingDiscount"
																		 input-width=""
																		 :error-message="discountErrorMessage"></input-voucher-field>
												</div>
												<div class="col-span-1 justify-center flex align-middle ion-align-items-center">
													<button type="submit"
															:class="['rounded-md bg-white px-1.5 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50', isCheckingDiscount ? 'disabled:opacity-75 px-2.5' : '']"
															:disabled="isCheckingDiscount">
																				<span v-if="isCheckingDiscount" class="flex"><span>													<svg
												class="animate-spin mr-2 h-5 w-5"
												xmlns="http://www.w3.org/2000/svg"
												fill="none" viewBox="0 0 24 24">
						<circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
						<path class="opacity-75" fill="currentColor"
				  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
					</svg></span>
			  <span>{{ $t('processing') }}</span></span>
														<span v-else>							{{
																														$t('shop.checkout.discount.redeem_discount_btn')
															}}</span>

													</button>

												</div>
											</div>

											<input type="submit" hidden/>


										</form>
									</div>
								</div>
							</div>
							<div class="mt-6 text-center text-sm text-gray-500">
								<p class="mt-4">
									<a @click="cancelCheckout()"
									   class="cursor-pointer font-medium text-gray-500 hover:text-gray-400">
										{{ $t('cancelCheckout') }}
									</a>
								</p>
							</div>
						</div>
					</div>
				</section>

			</div>
			<Popover
					class="overflow-hidden z-10 rounded-xl fixed inset-x-0 bottom-0 mx-2 sm:mx-4 flex flex-col-reverse text-sm font-medium text-gray-500 dark:text-gray-400 lg:hidden"
					v-slot="{ open }">
				<div class="relative z-10 rounded-xl bg-gray-100 dark:bg-gr-darker">
					<div class="px-4 sm:px-6">
						<PopoverButton
								class="focus:outline-none font-semibold flex w-full items-center py-4 font-medium">
							<div class="block sm:hidden flex justify-end items-end items-center mr-4 ">
								<checkout-nav-country
										popover-position="fixed inset-x-0 right-auto bottom-32 sm:bottom-36 left-12 sm:left-16 -ml-1.5"
										popover-size="w-64"></checkout-nav-country>
								<checkout-nav-cart
										popover-position="fixed inset-x-0 right-auto bottom-32 sm:bottom-36 left-10 sm:left-28 -ml-1.5"
										popover-size="w-[19.7em]"></checkout-nav-cart>
							</div>
							<span class="mr-auto text-base">{{ $t('total') }}</span>
							<dd class="mr-2 text-base">{{ cart.getTotalAmountCalculated(true) }}</dd>
							<ChevronUpIcon class="h-5 w-5 text-gray-500 dark:text-gray-400" aria-hidden="true"/>
						</PopoverButton>
					</div>
				</div>

				<TransitionRoot as="template" :show="open">
					<div>
						<TransitionChild as="template" enter="transition-opacity ease-linear duration-300"
										 enter-from="opacity-0" enter-to="opacity-100"
										 leave="transition-opacity ease-linear duration-300" leave-from="opacity-100"
										 leave-to="opacity-0">
							<PopoverOverlay class="fixed inset-0"/>
						</TransitionChild>
						<TransitionChild as="template" enter="transition ease-in-out duration-300 transform"
										 enter-from="translate-y-full" enter-to="translate-y-0"
										 leave="transition ease-in-out duration-300 transform"
										 leave-from="translate-y-0" leave-to="translate-y-full">
							<PopoverPanel
									class="relative -mb-4 rounded-xl bg-gray-100 dark:bg-gr-darker px-4 py-6 sm:px-6">
								<div class="">
									<h2 id="summary-heading"
										class="text-lg font-semibold text-gray-500 dark:text-white">{{
																				$t('shoppingCart')
										}}</h2>
									<ul role="list" class="text-sm font-medium text-gray-500">
										<li v-for="cartItem in cart.items" :key="cartItem.id"
											class="flex items-center py-6">
											<img loading="lazy" :src="cartItem.getProduct()?.getDefaultImage()"
												 :alt="cartItem.getProduct()?.getDefaultImage()"
												 class="h-10 w-10 flex-none rounded-md border bg-white border-gray-200 dark:border-gr-dark"/>
											<div class="ml-2 sm:ml-4 flex-auto">
												<h2 class="text-left text-xs text-gray-900 dark:text-gray-400 font-bold truncate">
													<a :href="cartItem.getProduct()?.getDetailLink()">{{
																												cartItem.getProduct()?.getProductName()
														}}</a>
												</h2>
												<h3 v-if="cartItem.getProduct()?.getProductDescription()"
													class="text-left font-medium text-xs text-gray-900 dark:text-gray-400">
													<a :href="cartItem.getProduct()?.getDetailLink()">{{
																												cartItem.getProduct()?.getProductDescription()
														}}</a>
												</h3>
											</div>
											<div class="mx-4 flex">
												<div class="ion-activatable flex bg-gray-200 dark:bg-gr-dark py-1 rounded-lg mr-0 sm:mr-2">
													<button class="mx-2 grid content-center"
															@click="removeCartItem_by_Id(cartItem.id)">
														<ion-icon v-if="true" class="w-4" slot="icon-only"
																  :icon="icons.trashOutline"></ion-icon>
														<ion-icon v-else slot="icon-only"
																  :icon="icons.removeOutline"></ion-icon>
														<ion-ripple-effect></ion-ripple-effect>
													</button>
													<!--														TODO use input instead-->
													<!--(														<input class="bg-gray-50 dark:bg-gr-darker py-2 h-5 w-7 text-center outline-none text-gray-900 dark:text-gray-400">-->
													<label :for="`quantity-${cartItem.id}`" class="sr-only">{{
																												$t('quantity',
																														{description: cartItem.getProduct()?.description})
														}}</label>
													<div class="flex"
														 v-if="cartItem.getPrice()?.type !== getRecurringPriceType()">
														<ListSelectDropdown :list="cartItem.getMaxItemCountList()"
																			:model="cartItem" :top-margin="false"
																			:default-value="cartItem.quantity"
																			@change-selected-value="updateCartItemCount"></ListSelectDropdown>

														<button class="px-2 py-0 grid content-center"
																v-if="cartItem.quantity < cartItem.getMaxItemCount()"
																@click="addProductToCart(cartItem.product_id)">
															<ion-icon class="w-4" slot="icon-only"
																	  :icon="icons.addOutline"></ion-icon>
														</button>
													</div>
												</div>
											</div>
											<p class="flex-none text-xs font-medium  text-gray-900 dark:text-gray-400">€
												{{ cartItem.getAmount() }}
											</p>
										</li>
									</ul>
									<div class="-mb-4">
										<dl class="space-y-1 pt-6 pb-2 text-sm font-medium text-gray-500 dark:text-gray-400">
											<div class="flex justify-between">
												<dt>{{ $t('subtotal') }}</dt>
												<dd class="text-gray-500 dark:text-gray-400">
													{{ cart.getTotalNettoAmountCalculated(true) }}
												</dd>
											</div>
										</dl>
										<dl class="py-2 text-sm font-medium text-gray-500 dark:text-gray-400"
											v-if="cart.hasTaxAmount()">
											<div class="flex justify-between pb-2 border-b border-gray-500 ">
												<dt>{{ $t('taxes') }}</dt>
												<dd class="text-gray-500 dark:text-gray-400">
													{{ cart.getTotalTaxAmountCalculated(true) }}
												</dd>
											</div>
										</dl>
										<div v-if="cart.hasDiscounts()">
											<dl class="py-2 text-sm font-medium text-gray-500 dark:text-gray-400">
												<div class="flex justify-between pb-2 border-b border-gray-500 ">
													<dt>{{ $t('discounts') }}</dt>
													<dd v-for="discount in cart.discounts" :key="discount.id"
														class="text-gray-500 dark:text-gray-400">
																								<span class="inline-flex items-baseline text-right align-middle">
												{{ discount.promotionCode }} -{{ discount.getDiscountPercent() }}%
										</span>
														<span class="inline-flex center justify-end w-5 float-right cursor-pointer h-full"
															  @click="removeDiscountFromCart(discount)"><XMarkIcon/></span>
													</dd>
												</div>
											</dl>
										</div>
									</div>
								</div>
							</PopoverPanel>
						</TransitionChild>
					</div>
				</TransitionRoot>
			</Popover>
		</div>
		<div v-else class="text-center">
			<!--			<h2 id="summary-heading" class="text-lg pt-4 font-semibold leading-5">Order summary</h2>-->
			<error-component error-image="assets/mascot/heidee-empty.webp" error-title="Your Cart is empty"
							 error-padding="" error-message="find the right Service"></error-component>
		</div>
	</div>
</template>

<script lang="ts">
import {ref, onMounted, defineComponent} from 'vue';
import useTranslation from '@/composable/translation/useTranslation';
import {ChevronUpIcon, XMarkIcon} from '@heroicons/vue/20/solid';
import {Popover, PopoverButton, PopoverOverlay, PopoverPanel, TransitionChild, TransitionRoot} from '@headlessui/vue';
import useShop from '@/composable/greeve/useShop';
import ErrorComponent from '@/components/errors/ErrorComponent.vue';
import * as icons from 'ionicons/icons';
import {IonIcon} from '@ionic/vue';
import CheckoutNavCart from '@/components/shop/checkout/nav/CheckoutNavCart.vue';
import CheckoutNavCountry from '@/components/shop/checkout/nav/CheckoutNavCountry.vue';
import ListSelectDropdown from '@/components/core/ListSelectDropdown.vue';
import {CartItem} from '@/greeve/shop/cart/item/cart_item.type';
import InputVoucherField from '@/components/inputs/InputVoucherField.vue';

export default defineComponent({
	name: 'OrderInformation',
	components: {
		InputVoucherField,
		ListSelectDropdown,
		CheckoutNavCountry,
		CheckoutNavCart,
		IonIcon,
		ErrorComponent,
		ChevronUpIcon,
		Popover,
		PopoverButton,
		PopoverOverlay,
		PopoverPanel,
		TransitionChild,
		TransitionRoot,
		XMarkIcon,
	},
	props: {},
	emits: [],
	setup() {
		const {t} = useTranslation();
		const {
			cancelCheckout,
			cart,
			removeCartItem_by_Id,
			addDiscountToCart,
			isDiscountValid,
			removeDiscountFromCart,
			changeCartItemCount,
			addProductToCart,
			getRecurringPriceType,
		} = useShop();

		const voucherCode = ref('');
		const isCheckingDiscount = ref(false);
		const discountErrorMessage = ref('');

		function updateCartItemCount(cartItem: CartItem, key: any, value: any) {
			changeCartItemCount(cartItem, value);
		}

		async function addDiscount() {
			//TODO
			discountErrorMessage.value = '';
			isCheckingDiscount.value = true;
			let isValid = false;
			if (voucherCode.value.length > 0) {
				try {
					if (await isDiscountValid(voucherCode.value)) {
						isValid = true;
						await addDiscountToCart(voucherCode.value);
					}
				} catch (e) {
					isValid = false;
				}
			}
			if (!isValid) {
				discountErrorMessage.value = t('error.invalid_discount');
				//Todo throw
			}
			isCheckingDiscount.value = false;
		}

		onMounted(() => {
			//
		});

		return {
			cancelCheckout,
			cart,
			removeCartItem_by_Id,
			icons,
			updateCartItemCount,
			addProductToCart,
			getRecurringPriceType,
			removeDiscountFromCart,
			addDiscount,
			voucherCode,
			discountErrorMessage,
			isCheckingDiscount,
		};
	},
});
</script>

<style scoped>

</style>
