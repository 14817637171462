import {
	createRouter,
	createWebHistory,
} from '@ionic/vue-router';
import {RouteRecordRaw} from 'vue-router';
import store from '@/store';
import middlewarePipeline from '@/router/middlewarePipeline';
import AuthenticationPage from '@/views/profile/AuthenticationPage.vue';
import TermsPage from '@/views/legal/TermsPage.vue';
import PrivacyPage from '@/views/legal/PrivacyPage.vue';
import ImprintPage from '@/views/legal/ImprintPage.vue';
import PressPage from '@/views/legal/PressPage.vue';
import DonatePage from '@/views/legal/DonatePage.vue';
import ErrorPage from '@/views/error/ErrorPage.vue';
import ShopPage from '@/views/shop/ShopPage.vue';
import ShopPlans from '@/views/shop/ShopPlans.vue';
import ShopProducts from '@/views/shop/ShopProducts.vue';
import ShopProductDetail from '@/views/shop/ShopProductDetail.vue';
import CheckoutSuccessPage from '@/views/shop/Checkout/CheckoutSuccessPage.vue';
import CheckoutCancelPage from '@/views/shop/Checkout/CheckoutCancelPage.vue';
import authMiddleware from '@/router/middleware/auth';
import FaqPage from '@/views/faq/FaqPage.vue';
import Enterprise from '@/views/enterprise/Enterprise.vue';
import LogoutPage from '@/views/profile/LogoutPage.vue';
import TeamPage from '@/views/profile/Team/TeamPage.vue';
import PlanPage from '@/views/profile/Plan/PlanPage.vue';
import AccountPage from '@/views/profile/Account/AccountPage.vue';
import SecurityPage from '@/views/profile/Security/SecurityPage.vue';
import CreditsPage from '@/views/profile/Credits/CreditsPage.vue';
import AssistantPage from '@/views/assistant/AssistantPage.vue';
import ChatPage from '@/views/assistant/Chat/ChatPage.vue';
import i18n from '@/i18n';
import redirectToAssistantMiddleware
	from '@/router/middleware/redirectToAssistant';
import FlowNewPage from '@/views/flow/FlowNewPage.vue';
import AssistantPageNew from '@/views/assistant/AssistantPageNew.vue';
import WhatsAppPage
	from '@/views/assistant/Mode/Messenger/WhatsApp/WhatsAppPage.vue';
import SocialPage from '@/views/assistant/Mode/Social/SocialPage.vue';
import devMiddleware from '@/router/middleware/dev';
import InstagramPage
	from '@/views/assistant/Mode/Social/Instagram/InstagramPage.vue';
import BlogPage from '@/views/blog/BlogPage.vue';
import PostPage from '@/views/blog/detail/PostPage.vue';
import BlogListPage from '@/views/blog/list/BlogListPage.vue';

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		redirect: '/assistant',
		meta: {
			title: i18n.global.t('assistantTitle'),
			metaTags: [
				{
					name: i18n.global.t('assistantTitle'),
					content: i18n.global.t('assistantDescription'),
				},
				{
					property: 'og:description',
					content: i18n.global.t('assistantDescription'),
				},
				{
					property: 'description',
					content: i18n.global.t('assistantDescription'),
				},
				{
					name: 'keywords',
					content: i18n.global.t('assistantKeywords'),
				},
				{
					name: 'robots',
					content: 'index, follow',
				},
			],
		},
	},
	{
		path: '/index.html',
		name: 'Greeve Assistant',
		component: AssistantPage,
		children: [
			{
				path: '/',
				name: 'Greeve Assistant',
				component: ChatPage,
			},
			{
				path: 'chat',
				name: 'Greeve Assistant',
				component: ChatPage,
			},
			{
				path: 'chat/:name?/:uuid?/:reference?',
				name: 'Greeve Assistant',
				component: ChatPage,
			},
		],
		meta: {
			title: i18n.global.t('assistantTitle'),
			metaTags: [
				{
					name: i18n.global.t('assistantTitle'),
					content: i18n.global.t('assistantDescription'),
				},
				{
					property: 'og:description',
					content: i18n.global.t('assistantDescription'),
				},
				{
					property: 'description',
					content: i18n.global.t('assistantDescription'),
				},
				{
					name: 'keywords',
					content: i18n.global.t('assistantKeywords'),
				},
				{
					name: 'robots',
					content: 'index, follow',
				},
			],
		},
	},
	{
		path: '/newassistant',
		name: 'Greeve Assistant New',
		component: AssistantPageNew,
		children: [
			{
				path: 'whatsapp/:uuid?',
				name: 'Greeve WhatsApp Assistant',
				component: WhatsAppPage,
			},
			{
				path: 'social',
				name: 'Greeve Social Assistant',
				component: SocialPage,
			},
			{
				path: 'social/instagram',
				name: 'Greeve Social Instagram Assistant',
				component: InstagramPage,
			}
		],
		meta: {
			title: i18n.global.t('assistantTitle'),
			metaTags: [
				{
					name: i18n.global.t('assistantTitle'),
					content: i18n.global.t('assistantDescription'),
				},
				{
					property: 'og:description',
					content: i18n.global.t('assistantDescription'),
				},
				{
					property: 'description',
					content: i18n.global.t('assistantDescription'),
				},
				{
					name: 'keywords',
					content: i18n.global.t('assistantKeywords'),
				},
				{
					name: 'robots',
					content: 'index, follow',
				},
			],
			middleware: [
				devMiddleware
			],
		},
	},
	{
		path: '/assistant',
		name: 'Greeve Assistant',
		component: AssistantPage,
		children: [
			{
				path: '/',
				name: 'Greeve Assistant Chat',
				component: ChatPage,
			},
			{
				path: 'chat',
				name: 'Greeve Assistant Chat',
				component: ChatPage,
			},
			{
				path: 'chat/:name?/:uuid?/:reference?',
				name: 'Greeve Assistant Chat',
				component: ChatPage,
			},
		],
		meta: {
			title: i18n.global.t('assistantTitle'),
			metaTags: [
				{
					name: i18n.global.t('assistantTitle'),
					content: i18n.global.t('assistantDescription'),
				},
				{
					property: 'og:description',
					content: i18n.global.t('assistantDescription'),
				},
				{
					property: 'description',
					content: i18n.global.t('assistantDescription'),
				},
				{
					name: 'keywords',
					content: i18n.global.t('assistantKeywords'),
				},
				{
					name: 'robots',
					content: 'index, follow',
				},
			],
		},
	},
	{
		path: '/search',
		name: 'greeve search',
		component: () => import(/* webpackChunkName: "profile" */ '../views/assistant/AssistantPage.vue'),
		meta: {
			title: i18n.global.t('homeTitle'),
			metaTags: [
				{
					name: i18n.global.t('routes.assistant.name'),
					content: i18n.global.t('homeDescription'),
				},
				{
					property: 'og:description',
					content: i18n.global.t('homeDescription'),
				},
				{
					property: 'description',
					content: i18n.global.t('homeDescription'),
				},
				{
					name: 'keywords',
					content: i18n.global.t('homeKeywords'),
				},
				{
					name: 'robots',
					content: 'index, follow',
				},
			],
			middleware: [
				redirectToAssistantMiddleware,
			],
		},
	},
	{
		path: '/terms',
		name: 'Greeve Terms',
		component: TermsPage,
		meta: {
			title: i18n.global.t('termsTitle'),
			metaTags: [
				{
					name: i18n.global.t('termsTitle'),
					content: i18n.global.t('termsDescription'),
				},
				{
					property: 'og:description',
					content: i18n.global.t('termsDescription'),
				},
				{
					property: 'description',
					content: i18n.global.t('termsDescription'),
				},
				{
					name: 'keywords',
					content: i18n.global.t('termsKeywords'),
				},
				{
					name: 'robots',
					content: 'index, follow',
				},
			],
			middleware: [],
		},
	},
	{
		path: '/privacy',
		name: 'Greeve Privacy',
		component: PrivacyPage,
		meta: {
			title: i18n.global.t('privacyTitle'),
			metaTags: [
				{
					name: i18n.global.t('privacyName'),
					content: i18n.global.t('privacyDescription'),
				},
				{
					property: 'og:description',
					content: i18n.global.t('privacyDescription'),
				},
				{
					property: 'description',
					content: i18n.global.t('privacyDescription'),
				},
				{
					name: 'keywords',
					content: i18n.global.t('privacyKeywords'),
				},
				{
					name: 'robots',
					content: 'index, follow',
				},
			],
			middleware: [],
		},
	},
	{
		path: '/imprint',
		name: 'Greeve Imprint',
		component: ImprintPage,
		meta: {
			title: i18n.global.t('imprintTitle'),
			metaTags: [
				{
					name: i18n.global.t('imprintName'),
					content: i18n.global.t('imprintDescription'),
				},
				{
					property: 'og:description',
					content: i18n.global.t('imprintDescription'),
				},
				{
					property: 'description',
					content: i18n.global.t('imprintDescription'),
				},
				{
					name: 'keywords',
					content: i18n.global.t('imprintKeywords'),
				},
				{
					name: 'robots',
					content: 'index, follow',
				},
			],
			middleware: [],
		},
	},
	{
		path: '/press',
		name: 'Greeve Press',
		component: PressPage,
		meta: {
			title: i18n.global.t('pressTitle'),
			metaTags: [
				{
					name: i18n.global.t('pressName'),
					content: i18n.global.t('pressDescription'),
				},
				{
					property: 'og:description',
					content: i18n.global.t('pressDescription'),
				},
				{
					property: 'description',
					content: i18n.global.t('pressDescription'),
				},
				{
					name: 'keywords',
					content: i18n.global.t('pressKeywords'),
				},
				{
					name: 'robots',
					content: 'index, follow',
				},
			],
			middleware: [],
		},
	},
	{
		path: '/donate',
		name: 'Greeve Donation',
		component: DonatePage,
		meta: {
			title: i18n.global.t('donateTitle'),
			metaTags: [
				{
					name: i18n.global.t('donateName'),
					content: i18n.global.t('donateDescription'),
				},
				{
					property: 'og:description',
					content: i18n.global.t('donateDescription'),
				},
				{
					property: 'description',
					content: i18n.global.t('donateDescription'),
				},
				{
					name: 'keywords',
					content: i18n.global.t('donateKeywords'),
				},
				{
					name: 'robots',
					content: 'index, follow',
				},
			],
			middleware: [],
		},
	},
	{
		path: '/faq',
		name: 'Greeve FAQ',
		component: FaqPage,
		meta: {
			title: i18n.global.t('faqTitle'),
			metaTags: [
				{
					name: i18n.global.t('faqName'),
					content: i18n.global.t('faqDescription'),
				},
				{
					property: 'og:description',
					content: i18n.global.t('faqDescription'),
				},
				{
					property: 'description',
					content: i18n.global.t('faqDescription'),
				},
				{
					name: 'keywords',
					content: i18n.global.t('faqKeywords'),
				},
				{
					name: 'robots',
					content: 'index, follow',
				},
			],
			middleware: [],
		},
	},
	{
		path: '/blog',
		name: 'Greeve Blog',
		component: BlogPage,
		children: [
			{
				path: '',
				name: 'Greeve Blog List',
				component: BlogListPage,
			},
			{
				path: ':slug',
				name: 'Greeve Blog Detail',
				component: PostPage,
				props: true, // Props für die dynamische Route aktivieren
			},
		],
		meta: {
			title: i18n.global.t('faqTitle'),
			metaTags: [
				{
					name: i18n.global.t('faqName'),
					content: i18n.global.t('faqDescription'),
				},
				{
					property: 'og:description',
					content: i18n.global.t('faqDescription'),
				},
				{
					property: 'description',
					content: i18n.global.t('faqDescription'),
				},
				{
					name: 'keywords',
					content: i18n.global.t('faqKeywords'),
				},
				{
					name: 'robots',
					content: 'index, follow',
				},
			],
			middleware: [
					devMiddleware
			],
		},
	},
	{
		path: '/error',
		name: 'Greeve Error',
		component: ErrorPage,
		meta: {
			title: i18n.global.t('errorTitle'),
			metaTags: [
				{
					name: i18n.global.t('errorName'),
					content: i18n.global.t('errorDescription'),
				},
				{
					property: 'og:description',
					content: i18n.global.t('errorDescription'),
				},
				{
					property: 'description',
					content: i18n.global.t('errorDescription'),
				},
				{
					name: 'keywords',
					content: i18n.global.t('errorKeywords'),
				},
			],
			middleware: [],
		},
	},
	{
		path: '/enterprise',
		name: 'Greeve Pro Enterprise',
		component: Enterprise,
		meta: {
			title: i18n.global.t('individualTitle'),
			metaTags: [
				{
					name: i18n.global.t('individualName'),
					content: i18n.global.t('individualDescription'),
				},
				{
					property: 'og:description',
					content: i18n.global.t('individualDescription'),
				},
				{
					property: 'description',
					content: i18n.global.t('individualDescription'),
				},
				{
					name: 'keywords',
					content: i18n.global.t('individualKeywords'),
				},
				{
					name: 'robots',
					content: 'index, follow',
				},
			],
		},
	},
	{
		path: '/services',
		name: 'Greeve Pro Services',
		component: ShopProducts,
		meta: {
			title: i18n.global.t('servicesTitle'),
			metaTags: [
				{
					name: i18n.global.t('servicesName'),
					content: i18n.global.t('servicesDescription'),
				},
				{
					property: 'og:description',
					content: i18n.global.t('servicesDescription'),
				},
				{
					property: 'description',
					content: i18n.global.t('servicesDescription'),
				},
				{
					name: 'keywords',
					content: i18n.global.t('servicesKeywords'),
				},
				{
					name: 'robots',
					content: 'index, follow',
				},
			],
		},
	},
	{
		path: '/services/detail',
		name: 'Greeve Pro Service Detail',
		component: ShopProductDetail,
		meta: {
			title: i18n.global.t('servicesDetailTitle'),
			metaTags: [
				{
					name: i18n.global.t('servicesDetailName'),
					content: i18n.global.t('servicesDetailDescription'),
				},
				{
					property: 'og:description',
					content: i18n.global.t('servicesDetailDescription'),
				},
				{
					property: 'description',
					content: i18n.global.t('servicesDetailDescription'),
				},
				{
					name: 'keywords',
					content: i18n.global.t('servicesDetailKeywords'),
				},
				{
					name: 'robots',
					content: 'index, follow',
				},
			],
		},
	},
	{
		path: '/pricing',
		name: 'Greeve Pro Plans',
		component: ShopPlans,
		meta: {
			title: i18n.global.t('pricingTitle'),
			metaTags: [
				{
					name: i18n.global.t('pricingName'),
					content: i18n.global.t('pricingDescription'),
				},
				{
					property: 'og:description',
					content: i18n.global.t('pricingDescription'),
				},
				{
					property: 'description',
					content: i18n.global.t('pricingDescription'),
				},
				{
					name: 'keywords',
					content: i18n.global.t('pricingKeywords'),
				},
				{
					name: 'robots',
					content: 'index, follow',
				},
			],
		},
	},
	{
		path: '/shop',
		name: 'Greeve Shop',
		component: ShopPage,
		meta: {
			title: i18n.global.t('shopTitle'),
			metaTags: [
				{
					name: i18n.global.t('shopTitle'),
					content: i18n.global.t('shopDescription'),
				},
				{
					property: 'og:description',
					content: i18n.global.t('shopDescription'),
				},
				{
					property: 'description',
					content: i18n.global.t('shopDescription'),
				},
				{
					name: 'keywords',
					content: i18n.global.t('shopKeywords'),
				},
				{
					name: 'robots',
					content: 'index, follow',
				},
			],
		},
	},
	{
		path: '/shop/success',
		name: 'Greeve Shop Success',
		component: CheckoutSuccessPage,
		meta: {
			title: i18n.global.t('shopSuccessTitle'),
			metaTags: [
				{
					name: i18n.global.t('shopSuccessTitle'),
					content: i18n.global.t('shopSuccessDescription'),
				},
				{
					property: 'og:description',
					content: i18n.global.t('shopSuccessDescription'),
				},
				{
					property: 'description',
					content: i18n.global.t('shopSuccessDescription'),
				},
				{
					name: 'keywords',
					content: i18n.global.t('shopSuccessKeywords'),
				},
			],
			middleware: [
				authMiddleware,
			],
		},
	},
	{
		path: '/shop/cancel',
		name: 'Greeve Shop Cancel',
		component: CheckoutCancelPage,
		meta: {
			title: i18n.global.t('shopCancelTitle'),
			metaTags: [
				{
					name: i18n.global.t('shopCancelTitle'),
					content: i18n.global.t('shopCancelDescription'),
				},
				{
					property: 'og:description',
					content: i18n.global.t('shopCancelDescription'),
				},
				{
					property: 'description',
					content: i18n.global.t('shopCancelDescription'),
				},
				{
					name: 'keywords',
					content: i18n.global.t('shopCancelKeywords'),
				},
			],
			middleware: [
				authMiddleware,
			],
		},
	},
	{
		path: '/flow',
		name: 'Greeve Flows',
		component: FlowNewPage,
		// children: [
		// 	{
		// 		path: '/',
		// 		name: 'Greeve Flows',
		// 		component: FlowNewPage,
		// 	},
		// 	{
		// 		path: 'templates',
		// 		name: 'templates',
		// 		component: TemplatePage,
		// 	},
		// 	{
		// 		path: 'templates/detail/:name',
		// 		name: 'template detail',
		// 		component: TemplateDetail,
		// 	},
		// 	{
		// 		path: 'history',
		// 		name: 'history',
		// 		component: HistoryPage,
		// 	},
		// 	{
		// 		path: 'planning',
		// 		name: 'planning',
		// 		component: PlanningPage,
		// 	},
		// ],
		meta: {
			title: i18n.global.t('workflowTitle'),
			metaTags: [
				{
					name: i18n.global.t('workflowTitle'),
					content: i18n.global.t('workflowDescription'),
				},
				{
					property: 'og:description',
					content: i18n.global.t('workflowDescription'),
				},
				{
					property: 'description',
					content: i18n.global.t('workflowDescription'),
				},
				{
					name: 'keywords',
					content: i18n.global.t('workflowKeywords'),
				},
				{
					name: 'robots',
					content: 'index, follow',
				},
			],
		},
	},
	// {
	// 	path: '/workflow',
	// 	name: 'Greeve Workflow',
	// 	component: WorkFlow,
	// 	children: [
	// 		{
	// 			path: '/',
	// 			name: 'flow',
	// 			component: FlowPage,
	// 		},
	// 		{
	// 			path: 'flow',
	// 			name: 'flow',
	// 			component: FlowPage,
	// 		},
	// 		{
	// 			path: 'templates',
	// 			name: 'templates',
	// 			component: TemplatePage,
	// 		},
	// 		{
	// 			path: 'templates/detail/:name',
	// 			name: 'template detail',
	// 			component: TemplateDetail,
	// 		},
	// 		{
	// 			path: 'history',
	// 			name: 'history',
	// 			component: HistoryPage,
	// 		},
	// 		{
	// 			path: 'planning',
	// 			name: 'planning',
	// 			component: PlanningPage,
	// 		},
	// 	],
	// 	meta: {
	// 		title: i18n.global.t('workflowTitle'),
	// 		metaTags: [
	// 			{
	// 				name: i18n.global.t('workflowTitle'),
	// 				content: i18n.global.t('workflowDescription'),
	// 			},
	// 			{
	// 				property: 'og:description',
	// 				content: i18n.global.t('workflowDescription'),
	// 			},
	// 			{
	// 				property: 'description',
	// 				content: i18n.global.t('workflowDescription'),
	// 			},
	// 			{
	// 				name: 'keywords',
	// 				content: i18n.global.t('workflowKeywords'),
	// 			},
	// 			{
	// 				name: 'robots',
	// 				content: 'index, follow',
	// 			},
	// 		],
	// 	},
	// },
	{
		path: '/profile',
		name: 'Greeve Profile',
		component: () => import(/* webpackChunkName: "profile" */ '../views/profile/ProfilePage.vue'),
		children: [
			{
				path: '',
				name: 'Account',
				component: AccountPage,
			},
			{
				path: 'account',
				name: 'Account',
				component: AccountPage,
			},
			{
				path: 'security',
				name: 'Security',
				component: SecurityPage,
			},
			{
				path: 'billing',
				name: 'Billing',
				component: PlanPage,
			},
			{
				path: 'credits',
				name: 'Credits',
				component: CreditsPage,
			},
			{
				path: 'team',
				name: 'Team',
				component: TeamPage,
			},
		],
		meta: {
			title: 'Greeve | Profile',
			metaTags: [
				{
					name: 'robots',
					content: 'noindex',
				},
			],
			middleware: [
				authMiddleware,
			],
		},
	},
	{
		path: '/auth',
		name: 'Greeve Login',
		component: AuthenticationPage,
		meta: {
			title: i18n.global.t('authTitle'),
			metaTags: [
				{
					name: i18n.global.t('authTitle'),
					content: i18n.global.t('authDescription'),
				},
				{
					property: 'og:description',
					content: i18n.global.t('authDescription'),
				},
				{
					property: 'description',
					content: i18n.global.t('authDescription'),
				},
				{
					name: 'keywords',
					content: i18n.global.t('authKeywords'),
				},
				{
					name: 'robots',
					content: 'index, follow',
				},
			],
			middleware: [
				// authMiddleware,
				// devMiddleware
			],
		},
	},
	{
		path: '/register',
		name: 'Greeve Register',
		component: AuthenticationPage,
		meta: {
			title: i18n.global.t('registerTitle'),
			metaTags: [
				{
					name: i18n.global.t('registerTitle'),
					content: i18n.global.t('registerDescription'),
				},
				{
					property: 'og:description',
					content: i18n.global.t('registerDescription'),
				},
				{
					property: 'description',
					content: i18n.global.t('registerDescription'),
				},
				{
					name: 'keywords',
					content: i18n.global.t('registerKeywords'),
				},
				{
					name: 'robots',
					content: 'index, follow',
				},
			],
			middleware: [
				// authMiddleware,
				// devMiddleware
			],
		},
	},
	{
		path: '/forgot-password',
		name: 'Forgot Password',
		component: () => import(/* webpackChunkName: "auth" */ '../views/profile/ForgotPasswordPage.vue'),
		meta: {
			title: 'Greeve | Forgot Password?',
			metaTags: [
				{
					name: 'description',
					content: 'Change your Password.',
				},
				{
					property: 'og:description',
					content: 'Change your Password.',
				},
				{
					property: 'description',
					content: 'Change your Password.',
				},
				{
					name: 'keywords',
					content: 'how can i change my password at greeve, i forgot my password at greeve,',
				},
			],
		},
	},
	{
		path: '/password-reset',
		name: 'Reset Password',
		component: () => import(/* webpackChunkName: "auth" */ '../views/profile/ResetPasswordPage.vue'),
		meta: {
			title: 'Greeve | Forgot Password?',
			metaTags: [
				{
					name: 'description',
					content: 'Change your Password.',
				},
				{
					property: 'og:description',
					content: 'Change your Password.',
				},
				{
					property: 'description',
					content: 'Change your Password.',
				},
				{
					name: 'keywords',
					content: 'reset password greeve, forgot password greeve, link to change password greeve,',
				},
			],
		},
	},
	{
		path: '/logout',
		name: 'Greeve Logout',
		component: LogoutPage,
		meta: {
			title: i18n.global.t('logoutTitle'),
			metaTags: [
				{
					name: i18n.global.t('logoutTitle'),
					content: i18n.global.t('logoutDescription'),
				},
				{
					property: 'og:description',
					content: i18n.global.t('logoutDescription'),
				},
				{
					property: 'description',
					content: i18n.global.t('logoutDescription'),
				},
				{
					name: 'keywords',
					content: i18n.global.t('logoutKeywords'),
				},
			],
			middleware: [
				authMiddleware,
			],
		},
	},
	{
		path: '/error/404',
		name: 'Not Found',
		component: ErrorPage,
		meta: {
			title: i18n.global.t('notFoundTitle'),
			metaTags: [
				{
					name: i18n.global.t('notFoundName'),
					content: i18n.global.t('notFoundDescription'),
				},
				{
					property: 'og:description',
					content: i18n.global.t('notFoundDescription'),
				},
				{
					property: 'description',
					content: i18n.global.t('notFoundDescription'),
				},
				{
					name: 'keywords',
					content: i18n.global.t('notFoundKeywords'),
				},
				{
					name: 'robots',
					content: 'noindex',
				},
			],
		},
	},
	// {
	// 	path: '/',
	// 	name: 'Home',
	// 	component: AssistantPage,
	// 	children: [ //TODO check 404 after login
	// 		{
	// 			path: '/',
	// 			name: 'Greeve Assistant',
	// 			component: ChatPage,
	// 		},
	// 		{
	// 			path: 'chat',
	// 			name: 'Greeve Assistant',
	// 			component: ChatPage,
	// 		},
	// 		{
	// 			path: 'chat/:name/:uuid/:reference',
	// 			name: 'Greeve Assistant',
	// 			component: ChatPage,
	// 		},
	// 	],
	// 	meta: {
	// 		title: i18n.global.t('servicesTitle'),
	// 		metaTags: [
	// 			{
	// 				name: i18n.global.t('servicesName'),
	// 				content: i18n.global.t('servicesDescription'),
	// 			},
	// 			{
	// 				property: 'og:description',
	// 				content: i18n.global.t('servicesDescription'),
	// 			},
	// 			{
	// 				property: 'description',
	// 				content: i18n.global.t('servicesDescription'),
	// 			},
	// 			{
	// 				name: 'keywords',
	// 				content: i18n.global.t('servicesKeywords'),
	// 			},
	// 			{
	// 				name: 'robots',
	// 				content: 'index, follow',
	// 			},
	// 		],
	// 	},
	// },
	{
		path: '/:pathMatch(.*)*',
		name: 'Not Found',
		component: ErrorPage,
		meta: {
			title: i18n.global.t('notFoundTitle'),
			metaTags: [
				{
					name: i18n.global.t('notFoundTitle'),
					content: i18n.global.t('notFoundDescription'),
				},
				{
					property: 'og:description',
					content: i18n.global.t('notFoundDescription'),
				},
				{
					property: 'description',
					content: i18n.global.t('notFoundDescription'),
				},
				{
					name: 'keywords',
					content: i18n.global.t('notFoundKeywords'),
				},
				{
					name: 'robots',
					content: 'noindex',
				},
			],
		},
	},
	// {
	// 	path: '/:pathMatch(.*)*',
	// 	redirect: '/',
	// },
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior (to, from, savedPosition) {
		return new Promise((resolve) => {
			setTimeout(() => {
				if (to.hash) {
					resolve({
						el: to.hash,
						behavior: 'smooth',
						top: 10,
					})
				} else if (savedPosition) {
					resolve(savedPosition);
				} else {
					resolve({ left: 0, top: 0 });
				}
			}, 500)
		})
	}
});

function addOrReplaceMetaTag(name: string, content: string) {
	let meta: HTMLMetaElement | any = document.querySelector(
			'meta[name="' + name + '"]');
	if (meta) {
		meta.setAttribute('content', content);
	} else {
		meta = document.createElement('meta');
		meta.name = name;
		meta.content = content;
		document.head.appendChild(meta);
	}
}

function addOrReplaceMetaProperty(property: string, content: string) {
	let meta: HTMLMetaElement | any = document.querySelector(
			'meta[property="' + property + '"]');
	if (meta) {
		meta.setAttribute('content', content);
	} else {
		meta = document.createElement('meta');
		meta.setAttribute('property', property);
		meta.setAttribute('content', content);
		document.head.appendChild(meta);
	}
}

router.beforeEach((to, from, next) => {
	let title = `${to.meta.title}`;
	if (title === 'undefined') {
		title = 'Greeve AI';
	}
	document.title = title;

	//TODO check performance
	const metaTags: Array<Record<any, any>> | any = to.meta['metaTags'];
	if (metaTags && from.path !== to.path) {
		metaTags.forEach((tag: any) => {
			if (tag.name) {
				addOrReplaceMetaTag(tag.name, tag.content);
			} else if (tag.property) {
				addOrReplaceMetaProperty(tag.property, tag.content);
			}
		});
	}

	if (!to.meta.middleware || !(to.meta.middleware instanceof Array) ||
			to.meta.middleware.length < 1) {
		return next();
	}

	const context = {
		to,
		from,
		next,
		store,
	};

	return middlewarePipeline(context, to.meta.middleware, 0)();
});

export default router;
