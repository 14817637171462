import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "mx-auto max-w-4xl divide-y divide-gray-900/10 dark:divide-gray-200/10" }
const _hoisted_2 = { class: "text-2xl font-bold leading-10 tracking-tight text-gray-900 dark:text-gray-200" }
const _hoisted_3 = {
  key: 0,
  class: "mt-10 space-y-6 divide-y divide-gray-900/10 dark:divide-gray-200/10"
}
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "ml-6 flex h-7 items-center" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MinusSmallIcon = _resolveComponent("MinusSmallIcon")!
  const _component_PlusSmallIcon = _resolveComponent("PlusSmallIcon")!
  const _component_DisclosureButton = _resolveComponent("DisclosureButton")!
  const _component_DisclosurePanel = _resolveComponent("DisclosurePanel")!
  const _component_Disclosure = _resolveComponent("Disclosure")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('faq.title')), 1),
    (_ctx.faqs)
      ? (_openBlock(), _createElementBlock("dl", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.faqs.getFaqsByLanguage(), (faq) => {
            return (_openBlock(), _createBlock(_component_Disclosure, {
              as: "div",
              key: faq.question_title,
              class: "pt-6"
            }, {
              default: _withCtx(({ open }) => [
                _createElementVNode("dt", null, [
                  _createVNode(_component_DisclosureButton, { class: "flex w-full items-start justify-between text-left text-gray-900 dark:text-gray-200" }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", {
                        class: "text-base font-semibold leading-7",
                        innerHTML: faq.question_title
                      }, null, 8, _hoisted_4),
                      _withDirectives(_createElementVNode("span", {
                        class: "text-base leading-7",
                        innerHTML: faq.question_description
                      }, null, 8, _hoisted_5), [
                        [_vShow, faq.question_description]
                      ]),
                      _createElementVNode("span", _hoisted_6, [
                        (!open)
                          ? (_openBlock(), _createBlock(_component_MinusSmallIcon, {
                              key: 0,
                              class: "h-6 w-6",
                              "aria-hidden": "true"
                            }))
                          : (_openBlock(), _createBlock(_component_PlusSmallIcon, {
                              key: 1,
                              class: "h-6 w-6",
                              "aria-hidden": "true"
                            }))
                      ])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _createVNode(_component_DisclosurePanel, {
                  as: "dd",
                  class: "text-left mt-2 pr-12"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("p", {
                      class: "text-base leading-7 text-gray-600 dark:text-gray-400",
                      innerHTML: faq.answer_title
                    }, null, 8, _hoisted_7),
                    _withDirectives(_createElementVNode("p", {
                      class: "text-base leading-7 text-gray-600 dark:text-gray-400",
                      innerHTML: faq.answer_description
                    }, null, 8, _hoisted_8), [
                      [_vShow, faq.answer_description]
                    ]),
                    _withDirectives(_createElementVNode("img", {
                      loading: "lazy",
                      src: faq.image,
                      alt: faq.answer_title,
                      class: "h-16 w-16 flex-none rounded-2xl"
                    }, null, 8, _hoisted_9), [
                      [_vShow, faq.image]
                    ])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}