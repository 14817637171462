import {OAuthList} from '@/greeve/user/oauth/oauth_list.type';
import {AbstractOAuth} from '@/greeve/user/oauth/abstract_oauth.type';
import {OAuthType} from '@/greeve/user/oauth/oauth.interface';
import {OAuthMeta} from '@/greeve/user/oauth/meta/oauth_meta.type';
import {
	OAuthMetaInstagram
} from '@/greeve/user/oauth/meta/oauth_meta_instagram.type';
import {
	OAuthMetaWhatsApp
} from '@/greeve/user/oauth/meta/oauth_meta_whatsapp.type';
import {OAuthGoogle} from '@/greeve/user/oauth/google/oauth_google.type';

export class OAuthFactory {
	/**
	 *
	 * @param oAuths
	 */
	createOAuthListByResponse(oAuths: Record<string, any>): OAuthList {
		const oAuthList = new OAuthList();

		if (oAuths) {
			if (Array.isArray(oAuths)) {
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				for (const [key, item] of Object.entries(oAuths)) {
					oAuthList.push(this.createOAuthByResponse(item));
				}
			} else {
				oAuthList.push(this.createOAuthByResponse(oAuths));
			}
		}

		return oAuthList;
	}

	createEmptyOAuthList(): OAuthList {
		return new OAuthList();
	}

	/**
	 *
	 * @param item
	 */
	createOAuthByResponse(item: Record<string, any>): AbstractOAuth {
		return this.createOAuthByType(item);
	}

	createOAuthByType(item: Record<string, any>): AbstractOAuth
	{
		switch (item.type) {
			case OAuthType.OAUTH_TYPE_META_INSTAGRAM:
			case OAuthType.OAUTH_TYPE_META_WHATSAPP:
			case OAuthType.OAUTH_TYPE_META:
			case OAuthType.OAUTH_TYPE_GOOGLE:
				return this.createOAuthEntity(item);
			default:
				throw new Error('Type ' + item.type + ' not allowed!');
		}
	}

	createOAuthEntity(item: Record<string, any>): OAuthMeta|OAuthMetaInstagram|OAuthMetaWhatsApp
	{
		const id = item.id;
		const user_id = item.uuid;
		const uuid = item.uuid;
		const type = item.type;
		const state = item.state;
		const reference = item.reference ?? null;
		const name = item.reference ?? null;
		const token = item.reference ?? null;
		const refresh_token = item.reference ?? null;
		const expiry_date = item.expiry_date ? new Date(item.expiry_date) : (item.expiryDate ? new Date(item.expiryDate) : undefined);
		const scope = item.scope;
		const data = item.data;
		const created_at = item.created_at ? new Date(item.created_at) : (item.createdAt ? new Date(item.createdAt) : new Date());
		const updated_at = item.updated_at ? new Date(item.updated_at) : (item.updatedAt ? new Date(item.updatedAt) : new Date());

		switch (type) {
			case OAuthType.OAUTH_TYPE_META_INSTAGRAM:
				return new OAuthMetaInstagram(id, user_id, uuid, type, state, reference, name, token, refresh_token, expiry_date, scope, data, created_at, updated_at);
			case OAuthType.OAUTH_TYPE_META_WHATSAPP:
				return new OAuthMetaWhatsApp(id, user_id, uuid, type, state, reference, name, token, refresh_token, expiry_date, scope, data, created_at, updated_at);
			case OAuthType.OAUTH_TYPE_META:
				return new OAuthMeta(id, user_id, uuid, type, state, reference, name, token, refresh_token, expiry_date, scope, data, created_at, updated_at);
			case OAuthType.OAUTH_TYPE_GOOGLE:
				return new OAuthGoogle(id, user_id, uuid, type, state, reference, name, token, refresh_token, expiry_date, scope, data, created_at, updated_at);
			default:
				throw new Error('Invalid Data for OAuth! Response: ' + type);
		}
	}
}