import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_error_component = _resolveComponent("error-component")!
  const _component_FooterBar = _resolveComponent("FooterBar")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("main", {
            style: _normalizeStyle({ paddingTop: _ctx.headerHeight + 'px' })
          }, [
            _createVNode(_component_error_component, {
              "error-number": _ctx.$t('error.NotFoundComponent.404Number'),
              "error-message": _ctx.$t('error.NotFoundComponent.404Message'),
              "error-title": _ctx.$t('error.NotFoundComponent.404Title')
            }, null, 8, ["error-number", "error-message", "error-title"]),
            _createVNode(_component_FooterBar, { class: "mt-12" })
          ], 4)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}