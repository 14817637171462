import {PaginationMetaInterface} from "@/greeve/core/pagination_meta.interface";
import {QuotaJournal} from '@/greeve/user/quota/journal/quota_journal.type';

export class QuotaJournalList extends Array<QuotaJournal> {

	public paginationMetaData?: PaginationMetaInterface;

	constructor(items: Array<QuotaJournal> = []) {
		super();
		this.push(...items);
	}

	add(items: QuotaJournal) {
		this.push(items);
	}

	remove(index: number) {
		this.splice(index, 1);
	}

	removeAll() {
		const itemCount = this.length;
		this.splice(itemCount, 1);
	}

	hasData(): boolean {
		return this.length > 0;
	}

	getQuotaJournal(index: number, clone = true): QuotaJournal {
		if (clone) {
			const QuotaJournalList: QuotaJournalList|any[] = [...this];
			return QuotaJournalList[index];
		} else {
			return this[index];
		}
	}

	getQuotaJournal_by_Id(QuotaJournalId: number, clone = true): QuotaJournal | undefined {
		if (clone) {
			const QuotaJournalList: QuotaJournalList|any[] = [...this];
			return QuotaJournalList.find(({id}) => id === QuotaJournalId);
		} else {
			return this.find(({id}) => id === QuotaJournalId);
		}
	}

	enhanceMetaData(metaData: any) {
		this.paginationMetaData = metaData;
	}

	getQuotaJournalListByName(QuotaJournalName: string): QuotaJournalList|QuotaJournal[]
	{
		const QuotaJournalList: QuotaJournalList|any[] = [...this];
		return QuotaJournalList.filter((QuotaJournal) => {
			return QuotaJournal.name === QuotaJournalName;
		});
	}
}