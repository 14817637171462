import {
	GreeveSearchGroupStateInterface,
	GreeveSearchGroupTypeInterface,
	SearchGroupInterface,
} from '@/greeve/search/group/search_group.interface';
import {SearchItemList} from '@/greeve/search/item/search_item_list.type';
import {Base64} from '@/greeve/core/encoding';

export class SearchGroup implements SearchGroupInterface {
	id: number;
	uuid: string;
	parent_id?: number | null;
	user_id?: number | null;
	type: GreeveSearchGroupTypeInterface;
	state: GreeveSearchGroupStateInterface;
	search_items?: SearchItemList|null;
	reference?: string;
	name?: string;
	is_pinned?: boolean;
	data?: any;
	created_at?: Date;
	updated_at?: Date;


	constructor(id: number, uuid: string, type: GreeveSearchGroupTypeInterface, state: GreeveSearchGroupStateInterface, search_items?: SearchItemList|null, reference: string|undefined = undefined, user_id: number|undefined = undefined, parent_id: number|undefined = undefined, group_name: string|undefined = undefined, is_pinned: boolean|undefined = undefined, data: any = null, created_at?: Date, updated_at?: Date) {
		this.id = id;
		this.uuid = uuid;
		this.type = type;
		this.parent_id = parent_id;
		this.user_id = user_id;
		this.state = state;
		this.search_items = search_items;
		this.reference = reference;
		this.name = group_name;
		this.is_pinned = is_pinned;
		this.data = data;
		this.created_at = created_at;
		this.updated_at = updated_at;
	}

	public getName(length: number|null = null): string
	{
		if (!this.name?.length) {
			return "New";
		}
		if (length) {
			return this.name.substring(0, length);
		}
		return this.name;
	}

	getUrlName(length = 30) {
		const truncatedName = this.getName().substring(0, length);
		return encodeURIComponent(truncatedName);
	}

	getUuidEncoded() {
		return Base64.encode(this.uuid);
	}
}