import {FaqList} from "@/greeve/faq/faq_list.type";
import {Faq} from "@/greeve/faq/faq.type";

export class FaqFactory {
	/**
	 *
	 * @param faqResponse
	 */
	createFaqListByResponse(faqResponse: Record<string, any>): FaqList {
		const faqList = new FaqList();

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		for (const [key, item] of Object.entries(faqResponse)) {
			faqList.push(this.createFaqByResponse(item));
		}

		return faqList;
	}

	/**
	 *
	 * @param item
	 */
	createFaqByResponse(item: Record<string, any>): Faq {
		const questionTitle = item.question_title;
		const answerTitle = item.answer_title;
		const questionDescription = item.question_description ?? undefined;
		const answerDescription = item.answer_description ?? undefined;
		const language = item.lang ? item.lang : (item.language ?? 'de');
		const image = item.image ?? undefined;
		const order = item.order ?? undefined;
		const data = item.data;
		const created_at = item.created_at ? new Date(item.created_at) : (item.createdAt ? new Date(item.createdAt) : new Date());
		const updated_at = item.updated_at ? new Date(item.updated_at) : (item.updatedAt ? new Date(item.updatedAt) : new Date());

		return new Faq(questionTitle, questionDescription, answerTitle, answerDescription, language, image, order, data, created_at, updated_at);
	}
}