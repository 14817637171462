import {PaginationMetaInterface} from "@/greeve/core/pagination_meta.interface";
import {Subscription} from "@/greeve/shop/subscription/subscription.type";
import {
	SubscriptionState
} from '@/greeve/shop/subscription/subscription.interface';

export class SubscriptionList extends Array<Subscription> {

	public paginationMetaData?: PaginationMetaInterface;

	constructor(items: Array<Subscription> = []) {
		super();
		this.push(...items);
	}

	add(items: Subscription) {
		this.push(items);
	}

	remove(index: number) {
		this.splice(index, 1);
	}

	removeAll() {
		const itemCount = this.length;
		this.splice(itemCount, 1);
	}

	hasData(): boolean {
		return this.length > 0;
	}

	getActiveSubscription(clone = true): Subscription|undefined {
		if (clone) {
			const subscriptionList: SubscriptionList|any[] = [...this];
			return subscriptionList.find(({stripe_status}) => stripe_status === SubscriptionState.ACTIVE);
		} else {
			return this.find(({stripe_status}) => stripe_status === SubscriptionState.ACTIVE);
		}
	}

	getSubscription(index: number, clone = true): Subscription {
		if (clone) {
			const subscriptionList: SubscriptionList|any[] = [...this];
			return subscriptionList[index];
		} else {
			return this[index];
		}
	}

	getSubscription_by_Id(subscriptionId: number, clone = true): Subscription | undefined {
		if (clone) {
			const subscriptionList: SubscriptionList|any[] = [...this];
			return subscriptionList.find(({id}) => id === subscriptionId);
		} else {
			return this.find(({id}) => id === subscriptionId);
		}
	}

	enhanceMetaData(metaData: any) {
		this.paginationMetaData = metaData;
	}

	getSubscriptionListByName(subscriptionName: string): SubscriptionList|Subscription[]
	{
		const subscriptionList: SubscriptionList|any[] = [...this];
		return subscriptionList.filter((subscription) => {
			return subscription.name === subscriptionName;
		});
	}
}