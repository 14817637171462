import {PaginationMetaInterface} from "@/greeve/core/pagination_meta.interface";
import {Price} from "@/greeve/shop/product/price.type";

export class PriceList extends Array<Price> {

	public paginationMetaData?: PaginationMetaInterface;

	constructor(items: Array<Price> = []) {
		super();
		this.push(...items);
	}

	add(items: Price) {
		this.push(items);
	}

	remove(index: number) {
		this.splice(index, 1);
	}

	removeAll() {
		const itemCount = this.length;
		this.splice(itemCount, 1);
	}

	getPrice(index: number): Price {
		return this[index];
	}

	getPrice_by_Id(prodId: string): Price | undefined {
		return this.find(({price_id}) => price_id === prodId);
	}

	enhanceMetaData(metaData: any) {
		this.paginationMetaData = metaData;
	}
}