// import {Product} from "@/greeve/shop/product/product.type";
// import useShop from "@/composable/greeve/useShop";
// import {Price} from "@/greeve/shop/product/price.type";
import {OrderItemInterface, OrderItemState, OrderItemType} from "@/greeve/shop/order/orderItem/order_item.interface";
// const {getProduct_by_ProductId, getPrice_by_PriceId} = useShop();

export class OrderItem implements OrderItemInterface {
	id: number;
	type: OrderItemType;
	state: OrderItemState;
	order_id: number;
	parent_id: number | null;
	reference: string | null;
	external_reference: string | null;
	quantity: number;
	grand_total: number;
	net_amount: number;
	tax_amount: number;
	total_amount: number;
	data?: any;
	created_at?: Date;
	updated_at?: Date;

	constructor(id: number, type: OrderItemType, state: OrderItemState, order_id: number, quantity: number, grand_total: number, net_amount: number, tax_amount: number, total_amount: number, reference: string | null = null, external_reference: string | null = null, parent_id: number | null = null, data: any = null, created_at?: Date, updated_at?: Date) {
		this.id = id;
		this.type = type;
		this.state = state;
		this.order_id = order_id;
		this.quantity = quantity;
		this.grand_total = grand_total;
		this.net_amount = net_amount;
		this.tax_amount = tax_amount;
		this.total_amount = total_amount;
		this.reference = reference;
		this.external_reference = external_reference;
		this.parent_id = parent_id;
		this.data = data;
		this.created_at = created_at;
		this.updated_at = updated_at;
	}

	// getProduct(): Product | undefined {
	// 	if (this.external_reference) {
	// 		return getProduct_by_ProductId(this.external_reference);
	// 	}
	// }
	//
	// getPrice(): Price | undefined {
	// 	if (this.data) {
	// 		const arrData = JSON.parse(this.data);
	// 		if (arrData['price_id']) {
	// 			return getPrice_by_PriceId(arrData['price_id']);
	// 		}
	// 	}
	// }

	getProductImage(): string
	{
		//TODO
		return '';
	}

	getProductName(): string
	{
		//TODO
		return '';
	}

	getProductLink(): string
	{
		//TODO
		return '';
	}

	getGrandTotal(): number | string {
		let value: string | number = this.grand_total;
		if (value) {
			value = (value / 100).toFixed(2);
		}
		return value;
	}
}