import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "hidden lg:block dark:bg-gr-darkest bg-gray-200 rounded-xl pt-12" }
const _hoisted_3 = { class: "sr-only" }
const _hoisted_4 = {
  "aria-labelledby": "summary-heading",
  class: "bg-gray-50 dark:bg-gr-dark px-4 pb-10 sm:px-6 lg:col-start-2 lg:row-start-1 lg:bg-transparent lg:px-0 lg:pb-16"
}
const _hoisted_5 = { class: "justify-center items-center sm:max-w-xl mt-6 m-4 lg:w-4/5 sm:m-auto" }
const _hoisted_6 = { class: "" }
const _hoisted_7 = {
  id: "summary-heading",
  class: "text-lg font-semibold text-gray-900 dark:text-white"
}
const _hoisted_8 = {
  role: "list",
  class: "text-sm font-medium text-gray-500"
}
const _hoisted_9 = ["src", "alt"]
const _hoisted_10 = { class: "ml-2 sm:ml-4 flex-auto" }
const _hoisted_11 = { class: "text-left text-xs text-gray-900 dark:text-gray-400 font-bold truncate" }
const _hoisted_12 = ["href"]
const _hoisted_13 = {
  key: 0,
  class: "text-left font-medium text-xs text-gray-900 dark:text-gray-400"
}
const _hoisted_14 = ["href"]
const _hoisted_15 = { class: "mx-4 flex" }
const _hoisted_16 = { class: "ion-activatable flex bg-gray-200 dark:bg-gr-dark py-1 rounded-lg mr-0 sm:mr-2" }
const _hoisted_17 = ["onClick"]
const _hoisted_18 = ["for"]
const _hoisted_19 = {
  key: 0,
  class: "flex"
}
const _hoisted_20 = ["onClick"]
const _hoisted_21 = { class: "flex-none text-xs font-medium text-gray-900 dark:text-gray-400" }
const _hoisted_22 = { class: "hidden lg:block" }
const _hoisted_23 = { class: "space-y-1 border-t border-gray-400 pt-6 pb-2 text-sm font-medium text-gray-500" }
const _hoisted_24 = { class: "flex justify-between" }
const _hoisted_25 = { class: "text-gray-600" }
const _hoisted_26 = {
  key: 0,
  class: "py-2 text-sm font-medium text-gray-500"
}
const _hoisted_27 = { class: "flex justify-between" }
const _hoisted_28 = { class: "text-gray-600" }
const _hoisted_29 = { class: "flex items-center justify-between font-semibold mt-2 border-t border-gray-500 py-2 text-gray-500 dark:text-gray-400" }
const _hoisted_30 = { class: "text-base" }
const _hoisted_31 = { class: "text-base" }
const _hoisted_32 = { key: 1 }
const _hoisted_33 = { class: "py-2 text-sm font-medium text-gray-500" }
const _hoisted_34 = { class: "flex justify-between" }
const _hoisted_35 = { class: "inline-flex items-baseline text-right align-middle" }
const _hoisted_36 = ["onClick"]
const _hoisted_37 = { class: "space-y-1 border-t border-gray-400 pt-6 pb-2 text-sm font-medium text-gray-500" }
const _hoisted_38 = { class: "flex justify-between" }
const _hoisted_39 = { class: "text-gray-600" }
const _hoisted_40 = { key: 2 }
const _hoisted_41 = { class: "container text-center mt-1 mb-2" }
const _hoisted_42 = { class: "mt-3 grid grid-flow-row-dense grid-cols-5 grid-rows-1" }
const _hoisted_43 = { class: "col-span-4" }
const _hoisted_44 = { class: "col-span-1 justify-center flex align-middle ion-align-items-center" }
const _hoisted_45 = ["disabled"]
const _hoisted_46 = {
  key: 0,
  class: "flex"
}
const _hoisted_47 = { key: 1 }
const _hoisted_48 = { class: "mt-6 text-center text-sm text-gray-500" }
const _hoisted_49 = { class: "mt-4" }
const _hoisted_50 = { class: "relative z-10 rounded-xl bg-gray-100 dark:bg-gr-darker" }
const _hoisted_51 = { class: "px-4 sm:px-6" }
const _hoisted_52 = { class: "block sm:hidden flex justify-end items-end items-center mr-4" }
const _hoisted_53 = { class: "mr-auto text-base" }
const _hoisted_54 = { class: "mr-2 text-base" }
const _hoisted_55 = { class: "" }
const _hoisted_56 = {
  id: "summary-heading",
  class: "text-lg font-semibold text-gray-500 dark:text-white"
}
const _hoisted_57 = {
  role: "list",
  class: "text-sm font-medium text-gray-500"
}
const _hoisted_58 = ["src", "alt"]
const _hoisted_59 = { class: "ml-2 sm:ml-4 flex-auto" }
const _hoisted_60 = { class: "text-left text-xs text-gray-900 dark:text-gray-400 font-bold truncate" }
const _hoisted_61 = ["href"]
const _hoisted_62 = {
  key: 0,
  class: "text-left font-medium text-xs text-gray-900 dark:text-gray-400"
}
const _hoisted_63 = ["href"]
const _hoisted_64 = { class: "mx-4 flex" }
const _hoisted_65 = { class: "ion-activatable flex bg-gray-200 dark:bg-gr-dark py-1 rounded-lg mr-0 sm:mr-2" }
const _hoisted_66 = ["onClick"]
const _hoisted_67 = ["for"]
const _hoisted_68 = {
  key: 0,
  class: "flex"
}
const _hoisted_69 = ["onClick"]
const _hoisted_70 = { class: "flex-none text-xs font-medium text-gray-900 dark:text-gray-400" }
const _hoisted_71 = { class: "-mb-4" }
const _hoisted_72 = { class: "space-y-1 pt-6 pb-2 text-sm font-medium text-gray-500 dark:text-gray-400" }
const _hoisted_73 = { class: "flex justify-between" }
const _hoisted_74 = { class: "text-gray-500 dark:text-gray-400" }
const _hoisted_75 = {
  key: 0,
  class: "py-2 text-sm font-medium text-gray-500 dark:text-gray-400"
}
const _hoisted_76 = { class: "flex justify-between pb-2 border-b border-gray-500" }
const _hoisted_77 = { class: "text-gray-500 dark:text-gray-400" }
const _hoisted_78 = { key: 1 }
const _hoisted_79 = { class: "py-2 text-sm font-medium text-gray-500 dark:text-gray-400" }
const _hoisted_80 = { class: "flex justify-between pb-2 border-b border-gray-500" }
const _hoisted_81 = { class: "inline-flex items-baseline text-right align-middle" }
const _hoisted_82 = ["onClick"]
const _hoisted_83 = {
  key: 1,
  class: "text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_ripple_effect = _resolveComponent("ion-ripple-effect")!
  const _component_ListSelectDropdown = _resolveComponent("ListSelectDropdown")!
  const _component_XMarkIcon = _resolveComponent("XMarkIcon")!
  const _component_input_voucher_field = _resolveComponent("input-voucher-field")!
  const _component_checkout_nav_country = _resolveComponent("checkout-nav-country")!
  const _component_checkout_nav_cart = _resolveComponent("checkout-nav-cart")!
  const _component_ChevronUpIcon = _resolveComponent("ChevronUpIcon")!
  const _component_PopoverButton = _resolveComponent("PopoverButton")!
  const _component_PopoverOverlay = _resolveComponent("PopoverOverlay")!
  const _component_TransitionChild = _resolveComponent("TransitionChild")!
  const _component_PopoverPanel = _resolveComponent("PopoverPanel")!
  const _component_TransitionRoot = _resolveComponent("TransitionRoot")!
  const _component_Popover = _resolveComponent("Popover")!
  const _component_error_component = _resolveComponent("error-component")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.cart && _ctx.cart.items && _ctx.cart.items.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t('shoppingCart')), 1),
            _createElementVNode("section", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("h2", _hoisted_7, _toDisplayString(_ctx.$t('shoppingCart')), 1),
                  _createElementVNode("ul", _hoisted_8, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cart.items, (cartItem) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: cartItem.id,
                        class: "flex items-center py-6"
                      }, [
                        _createElementVNode("img", {
                          loading: "lazy",
                          src: cartItem.getProduct()?.getDefaultImage(),
                          alt: cartItem.getProduct()?.getDefaultImage(),
                          class: "h-10 w-10 flex-none rounded-md border bg-white border-gray-200 dark:border-gr-dark"
                        }, null, 8, _hoisted_9),
                        _createElementVNode("div", _hoisted_10, [
                          _createElementVNode("h2", _hoisted_11, [
                            _createElementVNode("a", {
                              href: cartItem.getProduct()?.detailLink
                            }, _toDisplayString(cartItem.getProduct()?.getProductName()), 9, _hoisted_12)
                          ]),
                          (cartItem.getProduct()?.getProductDescription())
                            ? (_openBlock(), _createElementBlock("h3", _hoisted_13, [
                                _createElementVNode("a", {
                                  href: cartItem.getProduct()?.getDetailLink()
                                }, _toDisplayString(cartItem.getProduct()?.getProductDescription()), 9, _hoisted_14)
                              ]))
                            : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("div", _hoisted_15, [
                          _createElementVNode("div", _hoisted_16, [
                            _createElementVNode("button", {
                              class: "mx-2 grid content-center",
                              onClick: ($event: any) => (_ctx.removeCartItem_by_Id(cartItem.id))
                            }, [
                              true
                                ? (_openBlock(), _createBlock(_component_ion_icon, {
                                    key: 0,
                                    class: "w-4",
                                    slot: "icon-only",
                                    icon: _ctx.icons.trashOutline
                                  }, null, 8, ["icon"]))
                                : (_openBlock(), _createBlock(_component_ion_icon, {
                                    key: 1,
                                    slot: "icon-only",
                                    icon: _ctx.icons.removeOutline
                                  }, null, 8, ["icon"])),
                              _createVNode(_component_ion_ripple_effect)
                            ], 8, _hoisted_17),
                            _createElementVNode("label", {
                              for: `quantity-${cartItem.id}`,
                              class: "sr-only"
                            }, _toDisplayString(_ctx.$t('quantity', {description: cartItem.getProduct()?.description})), 9, _hoisted_18),
                            (cartItem.getPrice()?.type !== _ctx.getRecurringPriceType())
                              ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                                  _createVNode(_component_ListSelectDropdown, {
                                    list: cartItem.getMaxItemCountList(),
                                    model: cartItem,
                                    "top-margin": false,
                                    "default-value": cartItem.quantity,
                                    onChangeSelectedValue: _ctx.updateCartItemCount
                                  }, null, 8, ["list", "model", "default-value", "onChangeSelectedValue"]),
                                  (cartItem.quantity < cartItem.getMaxItemCount())
                                    ? (_openBlock(), _createElementBlock("button", {
                                        key: 0,
                                        class: "px-2 py-0 grid content-center",
                                        onClick: ($event: any) => (_ctx.addProductToCart(cartItem.product_id))
                                      }, [
                                        _createVNode(_component_ion_icon, {
                                          class: "w-4",
                                          slot: "icon-only",
                                          icon: _ctx.icons.addOutline
                                        }, null, 8, ["icon"])
                                      ], 8, _hoisted_20))
                                    : _createCommentVNode("", true)
                                ]))
                              : _createCommentVNode("", true)
                          ])
                        ]),
                        _createElementVNode("p", _hoisted_21, "€ " + _toDisplayString(cartItem.getAmount()), 1)
                      ]))
                    }), 128))
                  ]),
                  _createElementVNode("div", _hoisted_22, [
                    _createElementVNode("dl", _hoisted_23, [
                      _createElementVNode("div", _hoisted_24, [
                        _createElementVNode("dt", null, _toDisplayString(_ctx.$t('subtotal')), 1),
                        _createElementVNode("dd", _hoisted_25, _toDisplayString(_ctx.cart.getTotalNettoAmountCalculated(true)), 1)
                      ])
                    ]),
                    (_ctx.cart.hasTaxAmount())
                      ? (_openBlock(), _createElementBlock("dl", _hoisted_26, [
                          _createElementVNode("div", _hoisted_27, [
                            _createElementVNode("dt", null, _toDisplayString(_ctx.$t('taxes')), 1),
                            _createElementVNode("dd", _hoisted_28, _toDisplayString(_ctx.cart.getTotalTaxAmountCalculated(true)), 1)
                          ]),
                          _createElementVNode("div", _hoisted_29, [
                            _createElementVNode("dt", _hoisted_30, _toDisplayString(_ctx.$t('total')), 1),
                            _createElementVNode("dd", _hoisted_31, _toDisplayString(_ctx.cart.getTotalAmountCalculated(true)), 1)
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.cart.hasDiscounts())
                      ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                          _createElementVNode("dl", _hoisted_33, [
                            _createElementVNode("div", _hoisted_34, [
                              _createElementVNode("dt", null, _toDisplayString(_ctx.$t('discounts')), 1),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cart.discounts, (discount) => {
                                return (_openBlock(), _createElementBlock("dd", {
                                  key: discount.id,
                                  class: "text-gray-600"
                                }, [
                                  _createElementVNode("span", _hoisted_35, _toDisplayString(discount.getDiscountName()) + " / -" + _toDisplayString(discount.getDiscountPercent()) + "% ", 1),
                                  _createElementVNode("span", {
                                    class: "inline-flex center justify-end w-5 float-right cursor-pointer h-full",
                                    onClick: ($event: any) => (_ctx.removeDiscountFromCart(discount))
                                  }, [
                                    _createVNode(_component_XMarkIcon)
                                  ], 8, _hoisted_36)
                                ]))
                              }), 128))
                            ])
                          ]),
                          _createElementVNode("dl", _hoisted_37, [
                            _createElementVNode("div", _hoisted_38, [
                              _createElementVNode("dt", null, _toDisplayString(_ctx.$t('total')), 1),
                              _createElementVNode("dd", _hoisted_39, _toDisplayString(_ctx.cart.getTotalAmountCalculated(true)), 1)
                            ])
                          ])
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_40, [
                          _createElementVNode("div", _hoisted_41, [
                            _createElementVNode("form", {
                              onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.addDiscount && _ctx.addDiscount(...args)), ["prevent"]))
                            }, [
                              _createElementVNode("div", _hoisted_42, [
                                _createElementVNode("div", _hoisted_43, [
                                  _createVNode(_component_input_voucher_field, {
                                    value: _ctx.voucherCode,
                                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.voucherCode) = $event)),
                                    "input-placeholder": _ctx.$t('shop.checkout.discount.input_add_discount'),
                                    "input-id": "addDiscount",
                                    "input-disabled": _ctx.isCheckingDiscount,
                                    "input-width": "",
                                    "error-message": _ctx.discountErrorMessage
                                  }, null, 8, ["value", "input-placeholder", "input-disabled", "error-message"])
                                ]),
                                _createElementVNode("div", _hoisted_44, [
                                  _createElementVNode("button", {
                                    type: "submit",
                                    class: _normalizeClass(['rounded-md bg-white px-1.5 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50', _ctx.isCheckingDiscount ? 'disabled:opacity-75 px-2.5' : '']),
                                    disabled: _ctx.isCheckingDiscount
                                  }, [
                                    (_ctx.isCheckingDiscount)
                                      ? (_openBlock(), _createElementBlock("span", _hoisted_46, [
                                          _cache[3] || (_cache[3] = _createElementVNode("span", null, [
                                            _createElementVNode("svg", {
                                              class: "animate-spin mr-2 h-5 w-5",
                                              xmlns: "http://www.w3.org/2000/svg",
                                              fill: "none",
                                              viewBox: "0 0 24 24"
                                            }, [
                                              _createElementVNode("circle", {
                                                class: "opacity-25",
                                                cx: "12",
                                                cy: "12",
                                                r: "10",
                                                stroke: "currentColor",
                                                "stroke-width": "4"
                                              }),
                                              _createElementVNode("path", {
                                                class: "opacity-75",
                                                fill: "currentColor",
                                                d: "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                              })
                                            ])
                                          ], -1)),
                                          _createElementVNode("span", null, _toDisplayString(_ctx.$t('processing')), 1)
                                        ]))
                                      : (_openBlock(), _createElementBlock("span", _hoisted_47, _toDisplayString(_ctx.$t('shop.checkout.discount.redeem_discount_btn')), 1))
                                  ], 10, _hoisted_45)
                                ])
                              ]),
                              _cache[4] || (_cache[4] = _createElementVNode("input", {
                                type: "submit",
                                hidden: ""
                              }, null, -1))
                            ], 32)
                          ])
                        ]))
                  ]),
                  _createElementVNode("div", _hoisted_48, [
                    _createElementVNode("p", _hoisted_49, [
                      _createElementVNode("a", {
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.cancelCheckout())),
                        class: "cursor-pointer font-medium text-gray-500 hover:text-gray-400"
                      }, _toDisplayString(_ctx.$t('cancelCheckout')), 1)
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _createVNode(_component_Popover, { class: "overflow-hidden z-10 rounded-xl fixed inset-x-0 bottom-0 mx-2 sm:mx-4 flex flex-col-reverse text-sm font-medium text-gray-500 dark:text-gray-400 lg:hidden" }, {
            default: _withCtx(({ open }) => [
              _createElementVNode("div", _hoisted_50, [
                _createElementVNode("div", _hoisted_51, [
                  _createVNode(_component_PopoverButton, { class: "focus:outline-none font-semibold flex w-full items-center py-4 font-medium" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_52, [
                        _createVNode(_component_checkout_nav_country, {
                          "popover-position": "fixed inset-x-0 right-auto bottom-32 sm:bottom-36 left-12 sm:left-16 -ml-1.5",
                          "popover-size": "w-64"
                        }),
                        _createVNode(_component_checkout_nav_cart, {
                          "popover-position": "fixed inset-x-0 right-auto bottom-32 sm:bottom-36 left-10 sm:left-28 -ml-1.5",
                          "popover-size": "w-[19.7em]"
                        })
                      ]),
                      _createElementVNode("span", _hoisted_53, _toDisplayString(_ctx.$t('total')), 1),
                      _createElementVNode("dd", _hoisted_54, _toDisplayString(_ctx.cart.getTotalAmountCalculated(true)), 1),
                      _createVNode(_component_ChevronUpIcon, {
                        class: "h-5 w-5 text-gray-500 dark:text-gray-400",
                        "aria-hidden": "true"
                      })
                    ]),
                    _: 1
                  })
                ])
              ]),
              _createVNode(_component_TransitionRoot, {
                as: "template",
                show: open
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", null, [
                    _createVNode(_component_TransitionChild, {
                      as: "template",
                      enter: "transition-opacity ease-linear duration-300",
                      "enter-from": "opacity-0",
                      "enter-to": "opacity-100",
                      leave: "transition-opacity ease-linear duration-300",
                      "leave-from": "opacity-100",
                      "leave-to": "opacity-0"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_PopoverOverlay, { class: "fixed inset-0" })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_TransitionChild, {
                      as: "template",
                      enter: "transition ease-in-out duration-300 transform",
                      "enter-from": "translate-y-full",
                      "enter-to": "translate-y-0",
                      leave: "transition ease-in-out duration-300 transform",
                      "leave-from": "translate-y-0",
                      "leave-to": "translate-y-full"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_PopoverPanel, { class: "relative -mb-4 rounded-xl bg-gray-100 dark:bg-gr-darker px-4 py-6 sm:px-6" }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_55, [
                              _createElementVNode("h2", _hoisted_56, _toDisplayString(_ctx.$t('shoppingCart')), 1),
                              _createElementVNode("ul", _hoisted_57, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cart.items, (cartItem) => {
                                  return (_openBlock(), _createElementBlock("li", {
                                    key: cartItem.id,
                                    class: "flex items-center py-6"
                                  }, [
                                    _createElementVNode("img", {
                                      loading: "lazy",
                                      src: cartItem.getProduct()?.getDefaultImage(),
                                      alt: cartItem.getProduct()?.getDefaultImage(),
                                      class: "h-10 w-10 flex-none rounded-md border bg-white border-gray-200 dark:border-gr-dark"
                                    }, null, 8, _hoisted_58),
                                    _createElementVNode("div", _hoisted_59, [
                                      _createElementVNode("h2", _hoisted_60, [
                                        _createElementVNode("a", {
                                          href: cartItem.getProduct()?.getDetailLink()
                                        }, _toDisplayString(cartItem.getProduct()?.getProductName()), 9, _hoisted_61)
                                      ]),
                                      (cartItem.getProduct()?.getProductDescription())
                                        ? (_openBlock(), _createElementBlock("h3", _hoisted_62, [
                                            _createElementVNode("a", {
                                              href: cartItem.getProduct()?.getDetailLink()
                                            }, _toDisplayString(cartItem.getProduct()?.getProductDescription()), 9, _hoisted_63)
                                          ]))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _createElementVNode("div", _hoisted_64, [
                                      _createElementVNode("div", _hoisted_65, [
                                        _createElementVNode("button", {
                                          class: "mx-2 grid content-center",
                                          onClick: ($event: any) => (_ctx.removeCartItem_by_Id(cartItem.id))
                                        }, [
                                          true
                                            ? (_openBlock(), _createBlock(_component_ion_icon, {
                                                key: 0,
                                                class: "w-4",
                                                slot: "icon-only",
                                                icon: _ctx.icons.trashOutline
                                              }, null, 8, ["icon"]))
                                            : (_openBlock(), _createBlock(_component_ion_icon, {
                                                key: 1,
                                                slot: "icon-only",
                                                icon: _ctx.icons.removeOutline
                                              }, null, 8, ["icon"])),
                                          _createVNode(_component_ion_ripple_effect)
                                        ], 8, _hoisted_66),
                                        _createElementVNode("label", {
                                          for: `quantity-${cartItem.id}`,
                                          class: "sr-only"
                                        }, _toDisplayString(_ctx.$t('quantity',
																														{description: cartItem.getProduct()?.description})), 9, _hoisted_67),
                                        (cartItem.getPrice()?.type !== _ctx.getRecurringPriceType())
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_68, [
                                              _createVNode(_component_ListSelectDropdown, {
                                                list: cartItem.getMaxItemCountList(),
                                                model: cartItem,
                                                "top-margin": false,
                                                "default-value": cartItem.quantity,
                                                onChangeSelectedValue: _ctx.updateCartItemCount
                                              }, null, 8, ["list", "model", "default-value", "onChangeSelectedValue"]),
                                              (cartItem.quantity < cartItem.getMaxItemCount())
                                                ? (_openBlock(), _createElementBlock("button", {
                                                    key: 0,
                                                    class: "px-2 py-0 grid content-center",
                                                    onClick: ($event: any) => (_ctx.addProductToCart(cartItem.product_id))
                                                  }, [
                                                    _createVNode(_component_ion_icon, {
                                                      class: "w-4",
                                                      slot: "icon-only",
                                                      icon: _ctx.icons.addOutline
                                                    }, null, 8, ["icon"])
                                                  ], 8, _hoisted_69))
                                                : _createCommentVNode("", true)
                                            ]))
                                          : _createCommentVNode("", true)
                                      ])
                                    ]),
                                    _createElementVNode("p", _hoisted_70, "€ " + _toDisplayString(cartItem.getAmount()), 1)
                                  ]))
                                }), 128))
                              ]),
                              _createElementVNode("div", _hoisted_71, [
                                _createElementVNode("dl", _hoisted_72, [
                                  _createElementVNode("div", _hoisted_73, [
                                    _createElementVNode("dt", null, _toDisplayString(_ctx.$t('subtotal')), 1),
                                    _createElementVNode("dd", _hoisted_74, _toDisplayString(_ctx.cart.getTotalNettoAmountCalculated(true)), 1)
                                  ])
                                ]),
                                (_ctx.cart.hasTaxAmount())
                                  ? (_openBlock(), _createElementBlock("dl", _hoisted_75, [
                                      _createElementVNode("div", _hoisted_76, [
                                        _createElementVNode("dt", null, _toDisplayString(_ctx.$t('taxes')), 1),
                                        _createElementVNode("dd", _hoisted_77, _toDisplayString(_ctx.cart.getTotalTaxAmountCalculated(true)), 1)
                                      ])
                                    ]))
                                  : _createCommentVNode("", true),
                                (_ctx.cart.hasDiscounts())
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_78, [
                                      _createElementVNode("dl", _hoisted_79, [
                                        _createElementVNode("div", _hoisted_80, [
                                          _createElementVNode("dt", null, _toDisplayString(_ctx.$t('discounts')), 1),
                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cart.discounts, (discount) => {
                                            return (_openBlock(), _createElementBlock("dd", {
                                              key: discount.id,
                                              class: "text-gray-500 dark:text-gray-400"
                                            }, [
                                              _createElementVNode("span", _hoisted_81, _toDisplayString(discount.promotionCode) + " -" + _toDisplayString(discount.getDiscountPercent()) + "% ", 1),
                                              _createElementVNode("span", {
                                                class: "inline-flex center justify-end w-5 float-right cursor-pointer h-full",
                                                onClick: ($event: any) => (_ctx.removeDiscountFromCart(discount))
                                              }, [
                                                _createVNode(_component_XMarkIcon)
                                              ], 8, _hoisted_82)
                                            ]))
                                          }), 128))
                                        ])
                                      ])
                                    ]))
                                  : _createCommentVNode("", true)
                              ])
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 2
              }, 1032, ["show"])
            ]),
            _: 1
          })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_83, [
          _createVNode(_component_error_component, {
            "error-image": "assets/mascot/heidee-empty.webp",
            "error-title": "Your Cart is empty",
            "error-padding": "",
            "error-message": "find the right Service"
          })
        ]))
  ]))
}