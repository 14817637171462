import {GreeveApiAssistant} from '@/greeve/api/assistant';
import {computed, ComputedRef, ref} from 'vue';
import store from '@/store';
import {SearchGroupList} from '@/greeve/search/group/search_group_list.type';
import {SearchGroup} from '@/greeve/search/group/search_group.type';
import {SearchItemList} from '@/greeve/search/item/search_item_list.type';
import useAlert from '@/composable/core/useAlert';
import {GreeveApiAssistantStream} from '@/greeve/api/assistant_stream';
import {
	AbstractSearchItem,
} from '@/greeve/search/item/abstract_search_item.type';
import useAssistantFactory from '@/composable/greeve/useAssistantFactory';
import {
	SearchItemAuthorization,
} from '@/greeve/search/authorization/search_item_authorization.type';
import {
	GreeveSearchItemStateInterface,
	GreeveSearchItemSubTypeInterface,
} from '@/greeve/search/item/search_item.interface';
import router from '@/router';
import useSystem from '@/composable/core/useSystem';
import {
	ImageModel,
	ImageSize,
} from '@/greeve/search/item/type/subtype/search_item_image.type';
import {
	AudioVoice,
} from '@/greeve/search/item/type/subtype/search_item_text_to_speech.type';
import {
	SearchItemUploadInteraction
} from '@/greeve/search/item/type/search_item_upload_interaction.type';
import {ProgressEventCallback} from '@/greeve/core/progressEventCallback';
import {MediaFileInfo} from '@/greeve/media/media_file_info.type';
import {AssistantProvider} from '@/greeve/assistant/assistant_provider';

const {addError} = useAlert();

export default function useAssistant() {
	//TODO getter for grouplist, itemlist
	//TODO addItem to group
	//TODO updateItem in group
	//TODO deleteItem from group
	//TODO deleteGroup

	const searchGroups: ComputedRef<SearchGroupList|undefined> = computed(() => store.getters['assistant/getGroups']);
	const lastGroupUuid: ComputedRef<string|undefined> = computed(() => store.getters['assistant/getLastGroupUuid']);
	const isSearchGroupLoading = ref(false);
	const isSearchGroupsLoading = ref(false);
	const isSearchItemLoading = ref(false);

	let assistantStreamApi: GreeveApiAssistantStream | null = null;
	const getAssistantStreamApi = (): GreeveApiAssistantStream => {
		if (!assistantStreamApi) {
			assistantStreamApi = new GreeveApiAssistantStream();
		}
		return assistantStreamApi;
	}

	function getImageSizes(): Array<ImageSize>
	{
		return [ImageSize.SIZE_256_256, ImageSize.SIZE_512_512, ImageSize.SIZE_1024_1024, ImageSize.SIZE_1024_1792, ImageSize.SIZE_1792_1024];
	}

	function getAudioVoices(): Array<AudioVoice>
	{
		return [AudioVoice.ALLOY, AudioVoice.ECHO, AudioVoice.FABLE, AudioVoice.ONYX, AudioVoice.NOVA, AudioVoice.SHIMMER];
	}

	function getAudioVoiceDemo(voice: AudioVoice): string
	{
		switch (voice)
		{
			case AudioVoice.ALLOY:
				return 'https://cdn.openai.com/API/docs/audio/alloy.wav';
			case AudioVoice.ECHO:
				return 'https://cdn.openai.com/API/docs/audio/echo.wav';
			case AudioVoice.FABLE:
				return 'https://cdn.openai.com/API/docs/audio/fable.wav';
			case AudioVoice.ONYX:
				return 'https://cdn.openai.com/API/docs/audio/onyx.wav';
			case AudioVoice.NOVA:
				return 'https://cdn.openai.com/API/docs/audio/nova.wav';
			case AudioVoice.SHIMMER:
				return 'https://cdn.openai.com/API/docs/audio/shimmer.wav';
			default:
				throw Error('Did not find demo for voice('+voice+')');
		}
	}

	function getGroupById(group_id: number): SearchGroup|undefined {
		return store.getters['assistant/getGroup_by_Id'](group_id);
	}

	function getGroupByUuidComputed(group_uuid: string) {
		return computed(() => store.getters['assistant/getGroup_by_Uuid'](group_uuid));
	}

	function getGroupByUuid(group_uuid: string): SearchGroup|undefined {
		return store.getters['assistant/getGroup_by_Uuid'](group_uuid);
	}

	function getItemsByGroupUuid(group_uuid: string): SearchItemList|undefined {
		return store.getters['assistant/getItems_by_GroupUuid'](group_uuid);
	}

	function getItemByUuid(uuid: string): AbstractSearchItem|undefined {
		return store.getters['assistant/getItem_by_Uuid'](uuid);
	}

	function getItemBySearchItem(searchItem: AbstractSearchItem): AbstractSearchItem|undefined {
		return store.getters['assistant/getItem_by_SearchItem'](searchItem);
	}

	async function resetGroups(forceinitGroups = false) {
		await store.dispatch('assistant/resetGroups');
		if (forceinitGroups) {
			await initSearchGroups(true, false);
		}
	}

	function setGroups(groupList: SearchGroupList) {
		store.commit('assistant/setSearchGroups', groupList);
	}

	function setGroup(group: SearchGroup) {
		store.commit('assistant/setSearchGroup', group);
	}

	function addGroup(group: SearchGroup) {
		store.dispatch('assistant/addSearchGroup', {searchGroup: group});
	}

	function updateGroupName(searchGroupUuid: string, name: string) {
		store.commit('assistant/updateSearchGroupName', {searchGroupUuid: searchGroupUuid, name: name});
	}

	function setLastGroupUuid(groupUuid?: string|null) {
		store.commit('assistant/setLastGroupUuid', groupUuid);
	}

	function setSearchItems(searchGroupId: number, searchItemList: SearchItemList) {
		store.commit('assistant/setSearchItems', {searchGroupId: searchGroupId, searchItemList: searchItemList});
	}

	function setSearchItem(searchGroupId: number, searchItem: AbstractSearchItem) {
		store.commit('assistant/setSearchItem', {searchGroupId: searchGroupId, searchItem: searchItem});
	}

	function updateSearchItems(searchItemList: SearchItemList) {
		searchItemList.forEach((searchItem) => {
			updateSearchItemDefaultByItem(searchItem, searchItem);
		})
	}

	async function updateSearchItemResponse(searchItem: AbstractSearchItem, content: string) {
		store.commit('assistant/updateSearchItemResponse', {searchItem: searchItem, content: content});
		return getItemBySearchItem(searchItem)
	}

	function updateSearchItemState(searchItem: AbstractSearchItem, state: GreeveSearchItemStateInterface) {
		store.commit('assistant/updateSearchItemState', {searchItem: searchItem, itemState: state});
	}

	function updateSearchItemUploadInteractionMediaFileInfo(searchItem: SearchItemUploadInteraction, fileInfo: MediaFileInfo) {
		store.commit('assistant/updateSearchItemUploadInteractionMediaFileInfo', {searchItem: searchItem, fileInfo: fileInfo});
	}

	function updateSearchItemSubType(searchItem: SearchItemUploadInteraction, subType: GreeveSearchItemSubTypeInterface) {
		store.commit('assistant/updateSearchItemSubType', {searchItem: searchItem, subType: subType});
	}

	function updateSearchItemDefaultByItem(defaultSearchItem: AbstractSearchItem, newSearchItem: AbstractSearchItem) {
		store.commit('assistant/updateSearchItemDefaultByItem', {defaultSearchItem: defaultSearchItem, newSearchItem: newSearchItem});
	}

	function addSearchItem(searchItem: AbstractSearchItem) {
		store.commit('assistant/addSearchItem', searchItem);
	}

	function deleteSearchItem(searchItem: AbstractSearchItem) {
		store.commit('assistant/removeSearchItemFromList', searchItem);
	}

	function createEmptySearchItem(searchItemGroup_Id: number|null = null, prompt = '', subType: GreeveSearchItemSubTypeInterface = GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_DEFAULT) {
		return useAssistantFactory().getSearchItemFactory().createEmptySearchItemDefault(0, Math.random().toString(36), searchItemGroup_Id, '', prompt, subType);
	}

	function createEmptyUploadInteractionItem(searchItemGroup_Id: number|null = null, prompt = '', subType: GreeveSearchItemSubTypeInterface = GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_DEFAULT): SearchItemUploadInteraction|undefined {
		return useAssistantFactory().getSearchItemFactory().createEmptySearchItemUploadInteraction(0, Math.random().toString(36), searchItemGroup_Id, '', prompt, subType);
	}

	function createEmptySearchItem_by_UploadInteractionItem(searchItemUploadInteraction: SearchItemUploadInteraction, subType: GreeveSearchItemSubTypeInterface = GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_DEFAULT) {
		return useAssistantFactory().getSearchItemFactory().createSearchItemByUploadInteractionItem(useSystem().getRandomNumber(100000), searchItemUploadInteraction, subType);
	}

	function createEmptySearchItem_by_AuthorizationItem(authorizationItem: SearchItemAuthorization, subType: GreeveSearchItemSubTypeInterface = GreeveSearchItemSubTypeInterface.SEARCH_ITEM_SUBTYPE_DEFAULT) {
		return useAssistantFactory().getSearchItemFactory().createSearchItemByAuthorizationItem(useSystem().getRandomNumber(100000), authorizationItem, subType);
	}

	//TODO update item in list (id, item)

	async function hasEnoughQuota(type: GreeveSearchItemSubTypeInterface, prompt: string) {
		return GreeveApiAssistant.hasEnoughQuota(type, prompt);
	}

	async function createAuthorizationItem(prompt: string, createGroup = false, search_group_uuid: string|null = null, search_group_reference: string|null = null) {
			return GreeveApiAssistant.createAuthorization(prompt, createGroup, search_group_uuid, search_group_reference);
	}

	async function addNewGroup()
	{
		try {
			return GreeveApiAssistant.createGroup().then((group) =>
					{
						if (group && group.id) {
							addGroup(group);
							return group;
						} else {
							throw new Error('Nothing found, ' + JSON.stringify(group));
						}
					},
					error => {
						console.error(error);
						throw error;
					});
		} catch (error: Error | any) {
			console.error(error);
			const errorAddGroup = {
				id: "",
				title: "Error",
				message: "Cannot add group! " + error,
				active: true,
			};
			addError(errorAddGroup);
			throw error;
		} finally {
			//
		}
	}

	async function redirectToGroup(searchGroup: SearchGroup) {
		return router.push('/assistant/chat/' + searchGroup.getUrlName() + '/' + searchGroup.getUuidEncoded() + '/' + searchGroup.reference);
	}

	async function editGroup(search_group_uuid: string, name: string, autoAddGroup = true)
	{
		try {
			return GreeveApiAssistant.editGroup(search_group_uuid, name).then((group) =>
					{
						if (group && group.id) {
							if (autoAddGroup) {
								addGroup(group);
							} else {
								updateGroupName(search_group_uuid, name);
							}
							return group;
						} else {
							throw new Error('Nothing found, ' + JSON.stringify(group));
						}
					},
					error => {
						console.error(error);
						throw error;
					});
		} catch (error: Error | any) {
			console.error(error);
			const errorAddGroup = {
				id: "",
				title: "Error",
				message: "Cannot add group! " + error,
				active: true,
			};
			addError(errorAddGroup);
			throw error;
		} finally {
			//
		}
	}

	async function generateGroupName(search_group_uuid: string, prompt: string|undefined, autoAddGroup = true, subType: GreeveSearchItemSubTypeInterface|undefined = undefined)
	{
		try {
			return GreeveApiAssistant.generateGroupName(search_group_uuid, prompt, subType).then((group) =>
					{
						if (group && group.id) {
							if (autoAddGroup) {
								addGroup(group);
							} else {
								updateGroupName(search_group_uuid, group.getName());
							}
							return group;
						} else {
							throw new Error('Nothing found, ' + JSON.stringify(group));
						}
					},
					error => {
						console.error(error);
						throw error;
					});
		} catch (error: Error | any) {
			console.error(error);
			const errorAddGroup = {
				id: "",
				title: "Error",
				message: "Cannot generate group name! " + error,
				active: true,
			};
			addError(errorAddGroup);
			throw error;
		} finally {
			//
		}
	}


	async function deleteGroupByReference(search_group_uuid: string, search_group_reference: string)
	{
		try {
			return GreeveApiAssistant.deleteGroup_by_Reference(search_group_uuid, search_group_reference).then((result) =>
					{
						if (result) {
							store.commit('assistant/removeSearchGroupByUuidFromList', search_group_uuid);
						} else {
							throw new Error('Cannot delete Group ' + search_group_uuid);
						}
					},
					error => {
						console.error(error);
						throw error;
					});
		} catch (error: Error | any) {
			console.error(error);
			const errorAddGroup = {
				id: "",
				title: "Error",
				message: "Cannot add group! " + error,
				active: true,
			};
			addError(errorAddGroup);
			throw error;
		} finally {
			//
		}
	}


	async function deleteGroupByUuid(search_group_uuid: string)
	{
		try {
			return GreeveApiAssistant.deleteGroup_by_Uuid(search_group_uuid).then((result) =>
					{
						if (result) {
							return store.commit('assistant/removeSearchGroupByUuidFromList', search_group_uuid);
						} else {
							throw new Error('Cannot delete Group ' + search_group_uuid);
						}
					},
					error => {
						console.error(error);
						throw error;
					});
		} catch (error: Error | any) {
			console.error(error);
			const errorAddGroup = {
				id: "",
				title: "Error",
				message: "Cannot add group! " + error,
				active: true,
			};
			addError(errorAddGroup);
			throw error;
		} finally {
			//
		}
	}

	async function pinItem(search_item_uuid: string, isPinned = true, updateStorage = true)
	{
		try {
			return GreeveApiAssistant.pinItem(search_item_uuid, isPinned).then((item) =>
					{
						if (item) {
							if (updateStorage) {
								updateSearchItemDefaultByItem(item, item);
							}
							return item;
						} else {
							throw new Error('Nothing found, ' + JSON.stringify(item));
						}
					},
					error => {
						console.error(error);
						throw error;
					});
		} catch (error: Error | any) {
			console.error(error);
			const errorAddGroup = {
				id: "",
				title: "Error",
				message: "Cannot add group! " + error,
				active: true,
			};
			addError(errorAddGroup);
			throw error;
		} finally {
			//
		}
	}

	async function createSimplePrompt(prompt: string, searchGroupUuid: string|null = null, searchGroupReference: string|null = null, provider: AssistantProvider|null = null)
	{
		try {
			return GreeveApiAssistant.createSimplePrompt(prompt, searchGroupUuid, searchGroupReference, provider).then((response) =>
					{
						if (response) {
							return response;
						} else {
							throw new Error('Nothing found, ' + JSON.stringify(response));
						}
					},
					error => {
						console.error(error);
						throw error;
					});
		} catch (error: Error | any) {
			console.error(error);
			throw error;
		} finally {
			//
		}
	}

	async function uploadFile(file: Blob, onUploadProgress?: ProgressEventCallback)
	{
		try {
			return GreeveApiAssistant.uploadFile(file, onUploadProgress).then((fileInfo) =>
					{
						return fileInfo
					},
					error => {
						console.error(error);
						throw error;
					});
		} catch (error: Error | any) {
			console.error(error);
			const errorAddGroup = {
				id: "",
				title: "Error",
				message: "Cannot upload file! " + error,
				active: true,
			};
			addError(errorAddGroup);
			throw error;
		} finally {
			//
		}
	}

	async function createImage(prompt: string, searchGroupUuid: string|null = null, searchGroupReference: string|null = null, autoAddSearchItem = false, size: ImageSize|null = null, hd = false, model: ImageModel|null = null)
	{
		try {
			return GreeveApiAssistant.createImage(prompt, searchGroupUuid, searchGroupReference, size, hd, model).then((searchItemImage) =>
					{
						if (searchItemImage) {
							if (autoAddSearchItem) {
								addSearchItem(searchItemImage);
							}
							return searchItemImage;
						} else {
							throw new Error('Nothing found, ' + JSON.stringify(searchItemImage));
						}
					},
					error => {
						console.error(error);
						throw error;
					});
		} catch (error: Error | any) {
			console.error(error);
			const errorAddGroup = {
				id: "",
				title: "Error",
				message: "Cannot add group! " + error,
				active: true,
			};
			addError(errorAddGroup);
			throw error;
		} finally {
			//
		}
	}

	async function createImageVariation(mediaFileInfo: MediaFileInfo, searchGroupUuid: string|null = null, searchGroupReference: string|null = null, autoAddSearchItem = false, size: ImageSize|null = null, hd = false, model: ImageModel|null = null)
	{
		try {
			return GreeveApiAssistant.createImageVariation(mediaFileInfo, searchGroupUuid, searchGroupReference, size, hd, model).then((searchItemImage) =>
					{
						if (searchItemImage) {
							if (autoAddSearchItem) {
								addSearchItem(searchItemImage);
							}
							return searchItemImage;
						} else {
							throw new Error('Nothing found, ' + JSON.stringify(searchItemImage));
						}
					},
					error => {
						console.error(error);
						throw error;
					});
		} catch (error: Error | any) {
			console.error(error);
			const errorAddGroup = {
				id: "",
				title: "Error",
				message: "Cannot add group! " + error,
				active: true,
			};
			addError(errorAddGroup);
			throw error;
		} finally {
			//
		}
	}

	async function editImage(mediaFileInfo: MediaFileInfo, prompt: string, negativeText: string|null = null, searchGroupUuid: string|null = null, searchGroupReference: string|null = null, autoAddSearchItem = false, size: ImageSize|null = null, hd = false, model: ImageModel|null = null)
	{
		try {
			return GreeveApiAssistant.editImage(mediaFileInfo, prompt, negativeText, searchGroupUuid, searchGroupReference, size, hd, model).then((searchItemImage) =>
					{
						if (searchItemImage) {
							if (autoAddSearchItem) {
								addSearchItem(searchItemImage);
							}
							return searchItemImage;
						} else {
							throw new Error('Nothing found, ' + JSON.stringify(searchItemImage));
						}
					},
					error => {
						console.error(error);
						throw error;
					});
		} catch (error: Error | any) {
			console.error(error);
			const errorAddGroup = {
				id: "",
				title: "Error",
				message: "Cannot add group! " + error,
				active: true,
			};
			addError(errorAddGroup);
			throw error;
		} finally {
			//
		}
	}

	async function createSpeechToText(mediaFileInfo: MediaFileInfo, searchGroupUuid: string|null = null, searchGroupReference: string|null = null, autoAddSearchItem = false, async = false)
	{
		try {
			return GreeveApiAssistant.transcribeAudio(mediaFileInfo, searchGroupUuid, searchGroupReference, async).then((searchItemTranscribeAudio) =>
					{
						if (searchItemTranscribeAudio) {
							if (autoAddSearchItem) {
								addSearchItem(searchItemTranscribeAudio);
							}
							return searchItemTranscribeAudio;
						} else {
							throw new Error('Nothing found, ' + JSON.stringify(searchItemTranscribeAudio));
						}
					},
					error => {
						console.error(error);
						throw error;
					});
		} catch (error: Error | any) {
			console.error(error);
			const errorAddGroup = {
				id: "",
				title: "Error",
				message: "Cannot add group! " + error,
				active: true,
			};
			addError(errorAddGroup);
			throw error;
		} finally {
			//
		}
	}

	async function createSpeechByText(prompt: string, searchGroupUuid: string|null = null, searchGroupReference: string|null = null, autoAddSearchItem = false, voice: AudioVoice|null = null)
	{
		try {
			return GreeveApiAssistant.createSpeechByText(prompt, searchGroupUuid, searchGroupReference, voice).then((searchItemTextToSpeech) =>
					{
						if (searchItemTextToSpeech) {
							if (autoAddSearchItem) {
								addSearchItem(searchItemTextToSpeech);
							}
							return searchItemTextToSpeech;
						} else {
							throw new Error('Nothing found, ' + JSON.stringify(searchItemTextToSpeech));
						}
					},
					error => {
						console.error(error);
						throw error;
					});
		} catch (error: Error | any) {
			console.error(error);
			const errorAddGroup = {
				id: "",
				title: "Error",
				message: "Cannot add group! " + error,
				active: true,
			};
			addError(errorAddGroup);
			throw error;
		} finally {
			//
		}
	}

	async function retryItem(searchItemUuid: string, autoAddItemToStorage = true)
	{
		try {
			return GreeveApiAssistant.retryItem(searchItemUuid).then((searchItem) =>
					{
						if (searchItem) {
							//TODO remove old item
							if (autoAddItemToStorage) {
								deleteSearchItem(searchItem);
								addSearchItem(searchItem);
							}
							return searchItem;
						} else {
							throw new Error('Nothing found, ' + JSON.stringify(searchItem));
						}
					},
					error => {
						console.error(error);
						throw error;
					});
		} catch (error: Error | any) {
			console.error(error);
			const errorAddGroup = {
				id: "",
				title: "Error",
				message: "Cannot add group! " + error,
				active: true,
			};
			addError(errorAddGroup);
			throw error;
		} finally {
			//
		}
	}

	//TODO add searchItem and updateSearchItem

	async function initSearchGroup(searchGroupUuid: string) {
		isSearchGroupLoading.value = true;
		setTimeout(() => {
			isSearchGroupLoading.value = false;
		}, 5000);
		const group = await GreeveApiAssistant.getGroup_by_Reference(searchGroupUuid);
		if (group) {
			addGroup(group);
			isSearchGroupLoading.value = false;
			return group;
		} else {
			throw new Error('Error loading Flow!');
		}
	}

	async function initSearchGroup_Uuid_Reference(searchGroupUuid: string, reference: string) {
		isSearchGroupLoading.value = true;
		setTimeout(() => {
			isSearchGroupLoading.value = false;
		}, 5000);
		const group = await GreeveApiAssistant.getGroup_by_Uuid_Reference(searchGroupUuid, reference);
		if (group) {
			addGroup(group);
			isSearchGroupLoading.value = false;
			return group;
		} else {
			throw new Error('Error loading Flow!');
		}
	}

	async function initSearchGroups(forceRefresh = false, loadItems = true, pageCount = 0) {
		if ( (!searchGroups.value || searchGroups.value.length < 1 || forceRefresh) && !isSearchGroupsLoading.value) {
			isSearchGroupsLoading.value = true;
			const searchGroupList = await GreeveApiAssistant.getGroups(loadItems, pageCount);
			if (searchGroupList) {
				setGroups(searchGroupList);
			}
			isSearchGroupsLoading.value = false;
		}
	}


	async function initSearchItem(searchItemUuid: string) {
		isSearchItemLoading.value = true;
		setTimeout(() => {
			isSearchItemLoading.value = false;
		}, 5000);
		const searchItem = await GreeveApiAssistant.getItem_by_Uuid(searchItemUuid);
		if (searchItem && searchItem.search_item_group_id) {
			setSearchItem(searchItem.search_item_group_id, searchItem);
			isSearchItemLoading.value = false;
			return searchItem;
		} else {
			throw new Error('Error loading SearchItem!');
		}
	}


	async function initSearchItemByUuids(searchItemUuids: string[], searchGroupId: number|undefined, autoSetNewList = true) {
		isSearchItemLoading.value = true;
		setTimeout(() => {
			isSearchItemLoading.value = false;
		}, 5000);
		return GreeveApiAssistant.getItemsByUuids(searchItemUuids).then(searchItemList => {
			if (searchItemList) {
				if (autoSetNewList && searchGroupId) {
					updateSearchItems(searchItemList);
				}
				isSearchItemLoading.value = false;
				return searchItemList;
			} else {
				throw new Error('Error loading SearchItem!');
			}
		});
	}


	async function initSearchItems_by_Group(searchGroup: SearchGroup, autoSetNewList = true) {
		isSearchItemLoading.value = true;
		setTimeout(() => {
			isSearchItemLoading.value = false;
		}, 5000);
		if (!searchGroup.reference) {
			throw new Error('Error Group Reference not found!');
		}
		return GreeveApiAssistant.getSearchItems_by_Group_Uuid_Reference(searchGroup.uuid, searchGroup.reference).then(searchItemList => {
			if (searchItemList) {
				if (autoSetNewList) {
					setSearchItems(searchGroup.id, searchItemList);
				}
				isSearchItemLoading.value = false;
				return searchItemList;
			} else {
				throw new Error('Error loading SearchItem!');
			}
		});
	}

	async function initSearchItem_by_Authorization(authorizationUuid: string): Promise<AbstractSearchItem> {
		isSearchItemLoading.value = true;
		setTimeout(() => {
			isSearchItemLoading.value = false;
		}, 5000);
		return GreeveApiAssistant.getItem_by_AuthorizationUuid(authorizationUuid).then(searchItem => {
			if (searchItem && searchItem.search_item_group_id) {
				setSearchItem(searchItem.search_item_group_id, searchItem);
				isSearchItemLoading.value = false;
				return searchItem;
			} else {
				throw new Error('Error loading SearchItem!');
			}
		});
	}

	return {
		hasEnoughQuota,
		createAuthorizationItem,
		createEmptySearchItem,
		createEmptySearchItem_by_AuthorizationItem,
		createImage,
		createSpeechByText,
		initSearchGroups,
		initSearchGroup,
		initSearchItem,
		initSearchItem_by_Authorization,
		addNewGroup,
		addGroup,
		addSearchItem,
		resetGroups,
		deleteSearchItem,
		deleteGroupByReference,
		deleteGroupByUuid,
		editGroup,
		generateGroupName,
		retryItem,

		searchGroups,
		lastGroupUuid,
		getAssistantStreamApi,
		getGroupById,
		getGroupByUuid,
		getItemsByGroupUuid,
		setLastGroupUuid,
		setSearchItems,
		setSearchItem,
		setGroups,
		setGroup,
		updateSearchItemResponse,
		updateSearchItemDefaultByItem,
		updateSearchItemState,
		pinItem,
		getGroupByUuidComputed,
		initSearchItems_by_Group,
		initSearchGroup_Uuid_Reference,
		redirectToGroup,
		getImageSizes,
		getAudioVoices,
		getAudioVoiceDemo,
		getItemByUuid,
		createEmptyUploadInteractionItem,
		createEmptySearchItem_by_UploadInteractionItem,
		uploadFile,
		updateSearchItemUploadInteractionMediaFileInfo,
		updateSearchItemSubType,
		createImageVariation,
		editImage,
		createSimplePrompt,
		createSpeechToText,
		initSearchItemByUuids,
	}
}
