import {CartItem} from "@/greeve/shop/cart/item/cart_item.type";
import {CartItemList} from "@/greeve/shop/cart/item/cart_item_list.type";
import {Product} from "@/greeve/shop/product/product.type";
import {nanoid} from "nanoid";
import useShop from '@/composable/greeve/useShop';

export class CartItemFactory {
	/**
	 *
	 * @param carItems
	 */
	createCartItemListListByResponse(carItems: Record<string, any>): CartItemList {
		const cartItemList = new CartItemList();

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		for (const [key, item] of Object.entries(carItems)) {
			cartItemList.push(this.createCartItemByResponse(item));
		}

		return cartItemList;
	}

	/**
	 *
	 * @param item
	 */
	createCartItemByResponse(item: Record<string, any>): CartItem {
		const product = useShop().products.value.getProduct_by_Id(item.product_id);
		const id = item.id;
		const amount = item.amount;
		const quantity = item.quantity;
		const name = item.name;
		const priceId = item.price_id;
		const productId = item.product_id;
		const description = product && product.product_reference?.length ? product.getProductDescription() : '';
		const description2 = item.product_description_2 ?? '';
		const labels = item.labels;
		const productReference = item.product_reference;

		return new CartItem(id, amount, quantity, name, priceId, productId, description, description2, labels, productReference);
	}

	/**
	 *
	 * @param product
	 * @param productQuantity
	 */
	createCartItemByProduct(product: Product, productQuantity = 1): CartItem {
		const id = nanoid();
		const amount = product.price.unit_amount;
		const quantity = productQuantity;
		const name = product.getProductName();
		let priceId;
		if (product.price_id) {
			priceId = product.price_id;
		} else {
			priceId = product.price.price_id;
		}
		const productId = product.product_id;
		const productReference = product.product_reference;
		const description = product.getProductDescription();
		const description2 = '';
		const labels = undefined;

		return new CartItem(id, amount, quantity, name, priceId, productId, description, description2, labels, productReference);
	}

	createEmptyCartItem(): CartItem {
		return new CartItem(nanoid(), 0, 1, '', '', '',  '');
	}

	createEmptyCartItemList(): CartItemList {
		return new CartItemList([]);
	}
}