import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { class: "wizard-container divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow" }
const _hoisted_2 = { class: "px-4 py-5 sm:px-6" }
const _hoisted_3 = { class: "block sm:hidden" }
const _hoisted_4 = { class: "text-sm font-medium text-gray-900" }
const _hoisted_5 = { class: "inline-flex items-center gap-x-1.5 rounded-full bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-800" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 0,
  class: "hidden sm:block mt-6",
  "aria-hidden": "true"
}
const _hoisted_8 = { class: "overflow-hidden rounded-full bg-gray-200" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "px-4 py-5 sm:p-6" }
const _hoisted_11 = { class: "px-4 py-4 sm:px-6 wizard-actions flex justify-between" }
const _hoisted_12 = ["disabled"]
const _hoisted_13 = ["disabled"]
const _hoisted_14 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfettiBombAnimation = _resolveComponent("ConfettiBombAnimation")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showFinishedAnimation)
      ? (_openBlock(), _createBlock(_component_ConfettiBombAnimation, {
          key: 0,
          speed: "0.7",
          "sound-enabled": false
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(['absolute w-full opacity-70 flex justify-center rounded-t-lg', _ctx.wizzardColor])
    }, _toDisplayString(_ctx.wizzardName), 3),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[5] || (_cache[5] = _createElementVNode("h4", { class: "sr-only" }, "Status", -1)),
        _createElementVNode("p", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, [
            _cache[4] || (_cache[4] = _createElementVNode("svg", {
              class: "h-1.5 w-1.5 fill-yellow-500",
              viewBox: "0 0 6 6",
              "aria-hidden": "true"
            }, [
              _createElementVNode("circle", {
                cx: "3",
                cy: "3",
                r: "3"
              })
            ], -1)),
            _createElementVNode("span", null, _toDisplayString(_ctx.currentStep) + " / " + _toDisplayString(_ctx.stepCount), 1),
            (_ctx.currentStepStatusInfo)
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, " - " + _toDisplayString(_ctx.currentStepStatusInfo.title), 1))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      (_ctx.stepStatusInfo)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_Transition, { name: "progress-bar" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: _normalizeClass(['h-2 rounded-full', _ctx.wizzardColor]),
                    style: _normalizeStyle({ width: _ctx.progressBarWidth })
                  }, null, 6)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", {
              class: _normalizeClass(['mt-6 hidden text-sm font-medium text-gray-600 sm:grid', 'grid-cols-'+_ctx.stepCount])
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stepStatusInfo, (stepInfo) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: stepInfo.step,
                  class: _normalizeClass([stepInfo.step > 1 && stepInfo.step < _ctx.stepCount ? 'text-center' : '', stepInfo.step === _ctx.stepCount ? 'text-right' : '', stepInfo.current ? _ctx.wizzardPrimaryTextColor : ''])
                }, [
                  _createElementVNode("a", {
                    class: "cursor-pointer no-underline",
                    onClick: ($event: any) => (_ctx.goToStep(stepInfo.step))
                  }, _toDisplayString(stepInfo.title), 9, _hoisted_9)
                ], 2))
              }), 128))
            ], 2)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createVNode(_Transition, {
        name: "fade",
        mode: "out-in"
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createElementBlock("div", {
            class: "wizard-content mb-4",
            key: _ctx.currentStep
          }, [
            (_ctx.isStepComponentLoaded && _ctx.currentStepComponent)
              ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentStepComponent), {
                  key: "step",
                  wizzard: _ctx.wizzardObject
                }, null, 8, ["wizzard"]))
              : _createCommentVNode("", true),
            (_ctx.isSummaryComponentLoaded && _ctx.summaryComponent)
              ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.summaryComponent), {
                  key: "summary",
                  wizzard: _ctx.wizzardObject
                }, null, 8, ["wizzard"]))
              : _createCommentVNode("", true)
          ]))
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.prevStep && _ctx.prevStep(...args))),
        disabled: (!_ctx.isPrevStepAllowed),
        class: _normalizeClass(['bg-gray-500 text-white px-4 py-2 rounded', !_ctx.isPrevStepAllowed ? 'disabled:opacity-40' : ''])
      }, " Zurück ", 10, _hoisted_12),
      (!_ctx.isLastStep && !_ctx.isSummaryComponentLoaded)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.nextStep && _ctx.nextStep(...args))),
            disabled: (!_ctx.isNextStepAllowed),
            class: _normalizeClass(['text-white px-4 py-2 rounded', !_ctx.isNextStepAllowed ? 'disabled:opacity-40' : 'font-semibold', _ctx.wizzardColor])
          }, " Weiter ", 10, _hoisted_13))
        : (_ctx.isLastStep && !_ctx.isSummaryComponentLoaded)
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.showSummary && _ctx.showSummary(...args))),
              disabled: (!_ctx.isNextStepEnabled),
              class: _normalizeClass(['text-white px-4 py-2 rounded', !_ctx.isNextStepEnabled ? 'disabled:opacity-40' : 'font-semibold', _ctx.wizzardColor])
            }, " Eingabe kontrollieren ", 10, _hoisted_14))
          : (_ctx.isLastStep && _ctx.isSummaryComponentLoaded)
            ? (_openBlock(), _createElementBlock("button", {
                key: 2,
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.finishWizzard && _ctx.finishWizzard(...args))),
                class: _normalizeClass(['text-white px-4 py-2 font-semibold rounded', _ctx.wizzardColor])
              }, " Speichern 🎉 ", 2))
            : _createCommentVNode("", true)
    ])
  ]))
}