import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InfoCard = _resolveComponent("InfoCard")!
  const _component_TeamManagement = _resolveComponent("TeamManagement")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_InfoCard, {
      "info-key": "team-info",
      img: "https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/images/landing/3d/chair.png",
      info: _ctx.$t('teamDescription'),
      title: _ctx.$t('team2'),
      class: "mt-2 sm:mt-4"
    }, null, 8, ["info", "title"]),
    _createVNode(_component_TeamManagement)
  ], 64))
}