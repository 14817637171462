<template>
	<TransitionRoot as="template" :show="open">
		<Dialog as="div" class="relative z-10" @close="cancelDialogOutsideClick()">
			<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
				<div class="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block" />
			</TransitionChild>

			<div class="fixed inset-0 z-10 w-screen overflow-y-auto">
				<div class="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
					<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 md:translate-y-0 md:scale-95" enter-to="opacity-100 translate-y-0 md:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 md:scale-100" leave-to="opacity-0 translate-y-4 md:translate-y-0 md:scale-95">
						<DialogPanel class="flex w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl">
							<div class="relative flex w-full items-center overflow-hidden bg-white px-4 pb-8 pt-14 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
								<button type="button" class="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-6 sm:top-8 md:right-6 md:top-6 lg:right-8 lg:top-8" @click="cancelDialog()">
									<span class="sr-only">{{ $t('close') }}</span>
									<XMarkIcon class="h-6 w-6" aria-hidden="true" />
								</button>

								<div :class="['grid w-full grid-cols-1 items-start', image.length > 0 ? 'gap-x-6 gap-y-8 sm:grid-cols-12 lg:gap-x-8' : 'gap-y-8 grid-cols-1']">
									<div v-if="image.length > 0" class="sm:col-span-4 lg:col-span-5">
										<div class="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-100">
											<img :src="image" :alt="image" class="object-cover object-center" />
										</div>
									</div>
									<div :class="['', image.length > 0 ? 'sm:col-span-8 lg:col-span-7' : '']">
										<h2 class="text-2xl font-bold text-gray-900 sm:pr-12" v-html="dialogTitle"/>

										<section aria-labelledby="information-heading" class="mt-3">
											<p v-if="dialogSubTitle" class="text-lg text-gray-700" v-html="dialogSubTitle"></p>

											<div class="mt-6" v-if="dialogDescription">
												<p class="text-sm text-gray-700" v-html="dialogDescription"></p>
											</div>
										</section>
									</div>
								</div>
							</div>
						</DialogPanel>
					</TransitionChild>
				</div>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script lang="ts">
import {defineComponent, ref, toRef, watch} from 'vue';
import useTranslation from '@/composable/translation/useTranslation';
import {Dialog, DialogPanel, TransitionChild, TransitionRoot} from '@headlessui/vue';
import {XMarkIcon} from '@heroicons/vue/24/outline';

export default defineComponent({
	name: 'InfoQuickViewDialog',
	components: {
		XMarkIcon,
		Dialog, DialogPanel, TransitionChild, TransitionRoot,
	},
	props: {
		title: {type: String},
		subTitle: {type: String},
		description: {type: String},
		visible: {type: Boolean, default: false},
		image: {type: String, default: ''},
		allowBodyDismiss: {
			type: Boolean,
			default: true,
		},
		showDismissButton: {
			type: Boolean,
			default: true,
		},
	},
	emits: ['close'],
	setup(props, {emit}) {
		const dialogTitle = toRef(props, 'title');
		const dialogSubTitle = toRef(props, 'subTitle');
		const dialogDescription = toRef(props, 'description');
		const visible = toRef(props, 'visible');
		const open = ref(visible.value);
		const {t} = useTranslation();

		function toggleDialog() {
			open.value = !open.value;
		}

		const cancelDialogOutsideClick = () => {
			if (props.allowBodyDismiss) {
				open.value = false;
				emit('close');
			}
		};

		const cancelDialog = () => {
			open.value = false;
			emit('close');
		};

		watch(() => props.visible, (newValue: boolean) => {
			open.value = newValue;
		});

		return {
			dialogTitle,
			dialogSubTitle,
			dialogDescription,
			open,
			toggleDialog,
			cancelDialog,
			cancelDialogOutsideClick,
			t,
		};
	},
});
</script>

<style lang="scss">
</style>
