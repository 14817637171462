
/* eslint-disable @typescript-eslint/no-unused-vars */

const custom = {
	namespaced: true,
	state: () => ({
		tutorialCompleted: false,
		userApplicationRating: false,
		mainContent: undefined,
		pageReload: false,
		adBlockEnabled: true,
		adBlockerCheckRunning: false,
		adBlockerCheckUrl: "",
		cookiesAccepted: false,
		cookieScriptsDeleted: false,
		languageLocale: '',
		// languageLocale: process.env.VUE_APP_I18N_LOCALE || 'en',
		cookieDetails: {
			all: false,
			minimal: false,
		},
		infoBannerList: [],
		isPageInitialized: false,
		isGlobalLoading: false,
		isFooterVisible: true,
		isAssistantGroupMenuOpen: false,
		mobileMenuSidebar: false,
		loadAllGroups: false,
	}),
	mutations: {
		// eslint-disable-next-line
		setTutorialCompleted(state: any, value: boolean) {
			state.tutorialCompleted = value;
		},
		// eslint-disable-next-line
		setUserApplicationRating(state: any, value: boolean) {
			state.userApplicationRating = value;
		},
		// eslint-disable-next-line
		setPageReload(state: any, value: boolean) {
			state.pageReload = value;
		},
		// eslint-disable-next-line
		setAdBlocker(state: any, value: boolean) {
			state.adBlockEnabled = value;
		},
		// eslint-disable-next-line
		setAdBlockerCheckRunning(state: any, value: boolean) {
			state.adBlockerCheckRunning = value;
		},
		// eslint-disable-next-line
		setAdBlockerCheckUrl(state: any, value: string) {
			state.adBlockerCheckUrl = value;
		},
		// eslint-disable-next-line
		setCookies(state: any, value: boolean) {
			state.cookiesAccepted = value;
		},
		// eslint-disable-next-line
		setCookieDetails(state: any, value: {}) {
			state.cookieDetails = value;
		},
		// eslint-disable-next-line
		setMainContent(state: any, value: any) {
			state.mainContent = value;
		},
		// eslint-disable-next-line
		setLanguageLocale(state: any, value: {}) {
			state.languageLocale = value;
		},
		// eslint-disable-next-line
		setIsPageInitialized(state: any, value: boolean) {
			state.isPageInitialized = value;
		},
		// eslint-disable-next-line
		setIsGlobalLoading(state: any, value: boolean) {
			state.isGlobalLoading = value;
		},
		// eslint-disable-next-line
		setIsFooterVisible(state: any, value: boolean) {
			state.isFooterVisible = value;
		},
		// eslint-disable-next-line
		setIsAssistantGroupMenuOpen(state: any, value: boolean) {
			state.isAssistantGroupMenuOpen = value;
		},
		setLoadAllGroups(state: any, loadAllGroups: boolean) {
			state.loadAllGroups = loadAllGroups;
		},
		// eslint-disable-next-line
		setMobileMenuSidebar(state: any, value: boolean) {
			state.mobileMenuSidebar = value;
		},
		addInfoBanner(state: any, {id, active}: any) {
			if (state.infoBannerList === undefined || !state.infoBannerList) {
				state.infoBannerList = [];
			}
			const banner = {id: id, active: active};
			state.infoBannerList.push(banner);
		},
		changeInfoBannerActiveState(state: any, {id, active}: any) {
			const index = state.infoBannerList.findIndex((banner: any) => {
				return banner.id === id;
			});

			if (index !== -1) {
				state.infoBannerList[index].active = active;
			}
		},
		// eslint-disable-next-line
		setCookieScriptsDeleted(state: any, value: boolean) {
			state.cookieScriptsDeleted = value;
		},
	},
	actions: {
		addInfoBanner({ commit, state, getters }: any, {id, active}: any) { //TODO define interface
			try {
				commit('addInfoBanner', {id, active});
			} catch (error: any) {
				console.error(error);
			}
		},
		changeInfoBannerActiveState({ commit, state, getters }: any, {id, active = false}: any) {
			try {
				if (id) {
					commit('changeInfoBannerActiveState', {id, active});
				}
			} catch (error: any) {
				console.error(error);
			}
		},
	},
	getters: {
		// eslint-disable-next-line
		getTutorialCompleted: (state: any) => {
			return state.tutorialCompleted;
		},
		// eslint-disable-next-line
		hasTutorialCompleted: (state: any) => {
			return state.tutorialCompleted;
		},
		// eslint-disable-next-line
		getMainContent: (state: any) => {
			return state.mainContent;
		},
		// eslint-disable-next-line
		getPageReload: (state: any) => {
			return state.pageReload;
		},
		// eslint-disable-next-line
		getAdBlocker: (state: any) => {
			return state.adBlockEnabled;
		},
		// eslint-disable-next-line
		getAdBlockerCheckRunning: (state: any) => {
			return state.adBlockerCheckRunning;
		},
		// eslint-disable-next-line
		getAdBlockerCheckUrl: (state: any) => {
			return state.adBlockerCheckUrl;
		},
		// eslint-disable-next-line
		getCookies: (state: any) => {
			return state.cookiesAccepted;
		},
		// eslint-disable-next-line
		getInfoBanners: (state: any) => {
			return state.infoBannerList;
		},
		getInfoBanner_by_Id: (state: any, getters: any) => (id: string): any | undefined => {
			if (!state.infoBannerList) {
				return undefined;
			}
			const index = state.infoBannerList.findIndex((banner: any) => {
				return banner.id === id;
			});

			if (index !== -1) {
				return state.infoBannerList[index];
			}
			return undefined;
		},
		// eslint-disable-next-line
		getCookieDetails: (state: any) => {
			return state.cookieDetails;
		},
		// eslint-disable-next-line
		getLanguageLocale: (state: any) => {
			return state.languageLocale;
		},
		// eslint-disable-next-line
		hasUserRatedApplication: (state: any) => {
			return state.userApplicationRating;
		},
		// eslint-disable-next-line
		isPageInitialized: (state: any) => {
			return state.isPageInitialized;
		},
		// eslint-disable-next-line
		isGlobalLoading: (state: any) => {
			return state.isGlobalLoading;
		},
		// eslint-disable-next-line
		isFooterVisible: (state: any) => {
			return state.isFooterVisible;
		},
		// eslint-disable-next-line
		isAssistantGroupMenuOpen: (state: any) => {
			return state.isAssistantGroupMenuOpen;
		},
		// eslint-disable-next-line
		getMobileMenuSidebar: (state: any) => {
			return state.mobileMenuSidebar;
		},
		isLoadAllGroups:  (state: any): boolean => {
			return state.loadAllGroups;
		},
		// eslint-disable-next-line
		isCookieScriptsDeleted: (state: any) => {
			return state.cookieScriptsDeleted;
		},
	}
}

export default custom;