import {PaginationMetaInterface} from "@/greeve/core/pagination_meta.interface";
import {
	FlowProcessDependency
} from '@/greeve/flow/process/dependency/flow_process_dependency.type';

export class FlowProcessDependencyList extends Array<FlowProcessDependency> {

	public paginationMetaData?: PaginationMetaInterface;

	constructor(items: Array<FlowProcessDependency> = []) {
		super();
		this.push(...items);
	}

	add(items: FlowProcessDependency) {
		this.push(items);
	}

	remove(index: number) {
		this.splice(index, 1);
	}

	removeAll() {
		const itemCount = this.length;
		this.splice(itemCount, 1);
	}

	getItem(index: number): FlowProcessDependency {
		return this[index];
	}

	getItemById(item_id: number): FlowProcessDependency | undefined {
		return this.find(({id}) => id === item_id);
	}

	enhanceMetaData(metaData: any) {
		this.paginationMetaData = metaData;
	}
}