import {
	FlowProcessDependencyInterface,
} from '@/greeve/flow/process/dependency/flow_process_dependency.interface';
import {
	AbstractFlowProcess,
} from '@/greeve/flow/process/abstract_flow_process.type';

export class FlowProcessDependency implements FlowProcessDependencyInterface {
	id: number;
	from?: AbstractFlowProcess;
	to?: AbstractFlowProcess;
	data?: any;
	created_at?: Date;
	updated_at?: Date;

	constructor(
			id: number, from?: AbstractFlowProcess | undefined,
			to?: AbstractFlowProcess | undefined, data: any = null, created_at?: Date,
			updated_at?: Date) {
		this.id = id;
		this.from = from;
		this.to = to;
		this.data = data;
		this.created_at = created_at;
		this.updated_at = updated_at;
	}
}