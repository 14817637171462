<template>
	<div :class="['rounded-lg', iWidth, textPosition, iRounded, iHeight, cssClass]">
		<label :for="inputIdValue" v-if="inputPlaceholder.length > 0"
			   class="ml-3 bg-transparent h-7 block text-left text-lg font-medium text-gray-300">{{ inputPlaceholder }}</label>
		<div class="mt-1 relative rounded-2xl bg-gray-200 dark:bg-gray-700 h-12 sm:h-14" style="display: -webkit-flex!important;" >
			<input style="display: -webkit-flex!important; -webkit-appearance: none;" :id="inputIdValue" v-model="valueState" :class="['bg-gray-200 dark:text-gray-400 text-bp-dark dark:bg-gray-700 min-h-full block w-full text-lg rounded-2xl focus:outline-none', errorMessage.length > 0 ? 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500' : '', iPadding]"
				   :name="importFieldName"
				   :placeholder="inputPlaceholder"
				   :required="requiredField"
				   :type="inputType"
				   :min="min"
				   :max="max"
				   aria-describedby="value-error"
				   aria-invalid="true"/>
			<div v-show="errorMessage.length > 0"
				 class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
				<ExclamationCircleIcon aria-hidden="true" class="h-5 w-5 text-red-500"/>
			</div>
		</div>
		<p v-show="errorMessage.length > 0" id="value-error" class="mt-2 text-sm text-red-600">{{ errorMessage }}</p>
	</div>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref} from 'vue';
import {useVModel} from '@/composable/input/useVModel';
import {nanoid} from 'nanoid'
import {ExclamationCircleIcon} from '@heroicons/vue/20/solid'

export default defineComponent({
	name: 'InputField',
	components: {ExclamationCircleIcon},
	emits: ['update:value'],
	props: {
		value: String,
		inputType: {
			type: String,
			default: "input"
		},
		min: {
			type: Number,
			default: null
		},
		max: {
			type: Number,
			default: null
		},
		inputId: {
			type: String,
			default: ""
		},
		inputPlaceholder: {
			type: String,
			default: ""
		},
		requiredField: {
			type: Boolean,
			default: true
		},
		iWidth: {
			type: String,
			default: 'w-full sm:w-auto',
		},
		iHeight: {
			type: String,
			default: 'h-auto',
		},
		iRounded: {
			type: String,
			default: 'rounded-3xl',
		},
		textPosition: {
			type: String,
			default: 'text-center',
		},
		iPadding: {
			type: String,
			default: 'px-4 py-2',
		},
		cssClass: {
			type: String,
			default: 'my-2 sm:m-2',
		},
		errorMessage: {
			type: String,
			default: ""
		},
	},
	setup(props) {
		const importFieldName = ref();
		const inputIdValue = ref(props.inputId ?? nanoid());

		onMounted(() => {
			importFieldName.value = "input_" + nanoid();
		})

		return {
			valueState: useVModel(props, 'value'),
			importFieldName,
			inputIdValue
		};
	}
})
</script>

<style scoped>
</style>
