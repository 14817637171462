import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = {
  key: 1,
  class: "flex"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!_ctx.isLoginButton)
    ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.signIn && _ctx.signIn(...args))),
        class: "cursor-pointer hover:bg-gray-100 hover:text-gray-900 text-gray-700 block px-4 py-2 text-sm flex"
      }, [
        _createElementVNode("img", {
          src: _ctx.getImageUrlByType(),
          alt: 'Login with ' + _ctx.getNameByType(),
          class: "h-5 pr-2"
        }, null, 8, _hoisted_1),
        _createElementVNode("span", null, _toDisplayString(_ctx.getNameByType()), 1)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("a", {
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.signIn && _ctx.signIn(...args))),
          class: "w-full px-6 py-2 lg:py-1.5 cursor-pointer font-semibold text-gray-900 bg-white border-2 border-gray-500 rounded-xl shadow-2xl outline-none focus:outline-none"
        }, [
          _cache[2] || (_cache[2] = _createStaticVNode("<svg xmlns=\"http://www.w3.org/2000/svg\" class=\"inline w-4 h-4 mr-3 text-gray-900 fill-current\" viewBox=\"0 0 48 48\" width=\"48px\" height=\"48px\" data-v-ee040378><path fill=\"#fbc02d\" d=\"M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12 s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20 s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z\" data-v-ee040378></path><path fill=\"#e53935\" d=\"M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039 l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z\" data-v-ee040378></path><path fill=\"#4caf50\" d=\"M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36 c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z\" data-v-ee040378></path><path fill=\"#1565c0\" d=\"M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571 c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z\" data-v-ee040378></path></svg>", 1)),
          _createTextVNode(_toDisplayString(_ctx.t("login.google")), 1)
        ])
      ]))
}