import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col mt-20 md:mt-0 md:space-x-4 md:flex-row" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "mt-2" }
const _hoisted_4 = { class: "mt-2" }
const _hoisted_5 = { class: "flex items-center" }

import { computed, ref, onMounted, onBeforeUnmount } from 'vue';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/24/outline';

// Define the properties for the component
// eslint-disable-next-line

export default /*@__PURE__*/_defineComponent({
  __name: 'ThreeColumnLayout',
  props: {
    isFirstCollapsed: { type: Boolean },
    isLastCollapsed: { type: Boolean },
    firstWidth: {},
    lastWidth: {},
    firstColBg: {},
    middleColBg: {},
    lastColBg: {},
    colRounded: {}
  },
  setup(__props: any) {

const props = __props;

// Reactive state for column visibility
const isFirstColumnVisible = ref(!props.isFirstCollapsed);
const isLastColumnVisible = ref(!props.isLastCollapsed);
const isMobile = ref(window.innerWidth < 768);

// Methods to toggle the visibility of columns
const toggleCollapse = (column: 'first' | 'last') => {
	if (column === 'first') {
		isFirstColumnVisible.value = !isFirstColumnVisible.value;
		if (isFirstColumnVisible.value && isMobile.value) {
			isLastColumnVisible.value = false;
		} else if (!isFirstColumnVisible.value && !isLastColumnVisible.value) {
			isLastColumnVisible.value = true;
		}
	} else if (column === 'last') {
		isLastColumnVisible.value = !isLastColumnVisible.value;
		if (isLastColumnVisible.value && isMobile.value) {
			isFirstColumnVisible.value = false;
		} else if (!isFirstColumnVisible.value && !isLastColumnVisible.value) {
			isFirstColumnVisible.value = true;
		}
	}
};

// Computed properties to determine the icon to show for toggling
const ArrowFirstIcon = computed(() => {
	return isFirstColumnVisible.value ? ChevronLeftIcon : ChevronRightIcon;
});

const ArrowLastIcon = computed(() => {
	return isLastColumnVisible.value ? ChevronRightIcon : ChevronLeftIcon;
});

// Computed properties to determine the width class for the columns
const firstColumnWidthClass = computed(() => {
	return isFirstColumnVisible.value ? `w-${props.firstWidth}` : 'w-auto';
});

const lastColumnWidthClass = computed(() => {
	return isLastColumnVisible.value ? `w-${props.lastWidth}` : 'w-auto';
});

const mdMiddleColumnWidthClass = computed(() => {
	if (!isFirstColumnVisible.value && isLastColumnVisible.value) {
		return 'md:w-3/4';
	} else if (isFirstColumnVisible.value && !isLastColumnVisible.value) {
		return 'md:w-3/4';
	} else if (!isFirstColumnVisible.value && !isLastColumnVisible.value) {
		return 'md:w-full';
	} else {
		return 'md:w-1/2';
	}
});

// Method to check if the screen size is mobile
const checkIsMobile = () => {
	isMobile.value = window.innerWidth < 768;
};

// Add event listener to handle screen resize
onMounted(() => {
	window.addEventListener('resize', checkIsMobile);
	checkIsMobile();
});

// Remove event listener when component is unmounted
onBeforeUnmount(() => {
	window.removeEventListener('resize', checkIsMobile);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(['transition', isFirstColumnVisible.value ? firstColumnWidthClass.value : 'w-auto', props.firstColBg ? props.firstColBg : '', props.colRounded ? props.colRounded : ''])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (toggleCollapse('first'))),
          class: "p-2"
        }, [
          _createVNode(_unref(ArrowFirstIcon), { class: "w-6 h-6 text-gray-500" })
        ]),
        (isFirstColumnVisible.value || !isMobile.value)
          ? _renderSlot(_ctx.$slots, "first-title", { key: 0 })
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass({'mt-2': isFirstColumnVisible.value || !isMobile.value, 'hidden': isMobile.value && !isFirstColumnVisible.value})
      }, [
        _renderSlot(_ctx.$slots, "first-content")
      ], 2)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(['flex-auto', props.middleColBg ? props.middleColBg : '', props.colRounded ? props.colRounded : '', isMobile.value && (isFirstColumnVisible.value || isLastColumnVisible.value) ? 'hidden' : 'block', mdMiddleColumnWidthClass.value])
    }, [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "middle-title")
      ]),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "middle-content")
      ])
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(['transition', isLastColumnVisible.value ? lastColumnWidthClass.value : 'w-auto', props.lastColBg ? props.lastColBg : '', props.colRounded ? props.colRounded : ''])
    }, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("button", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (toggleCollapse('last'))),
          class: "p-2"
        }, [
          _createVNode(_unref(ArrowLastIcon), { class: "w-6 h-6 text-gray-500" })
        ]),
        (isLastColumnVisible.value || !isMobile.value)
          ? _renderSlot(_ctx.$slots, "last-title", { key: 0 })
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass({'mt-2': isLastColumnVisible.value || !isMobile.value, 'hidden': isMobile.value && !isLastColumnVisible.value})
      }, [
        _renderSlot(_ctx.$slots, "last-content")
      ], 2)
    ], 2)
  ]))
}
}

})