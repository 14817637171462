import { vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative mt-2 rounded-md shadow-sm" }
const _hoisted_2 = ["id", "name", "placeholder", "required", "type", "autocomplete", "disabled"]
const _hoisted_3 = { class: "pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExclamationCircleIcon = _resolveComponent("ExclamationCircleIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['rounded-lg', _ctx.iWidth, _ctx.textPosition, _ctx.iRounded, _ctx.iHeight, _ctx.cssClass])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("input", {
        id: _ctx.inputIdValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.valueState) = $event)),
        class: _normalizeClass(['block w-full rounded-md border-0 py-1.5 pr-10 sm:text-sm sm:leading-6', _ctx.errorMessage.length > 0 ? 'ring-1 ring-inset ring-red-300 border-0 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-inset focus:ring-red-500' : '', _ctx.iPadding + ' ' + _ctx.iCss, _ctx.inputDisabled ? 'disabled:opacity-75' : '']),
        name: _ctx.importFieldName,
        placeholder: _ctx.inputPlaceholder,
        required: _ctx.requiredField,
        type: _ctx.inputType,
        "aria-describedby": "email-error",
        autocomplete: _ctx.autocompleteInput ? _ctx.inputType : '',
        disabled: _ctx.inputDisabled,
        "aria-invalid": "true"
      }, null, 10, _hoisted_2), [
        [_vModelDynamic, _ctx.valueState]
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_3, [
        _createVNode(_component_ExclamationCircleIcon, {
          "aria-hidden": "true",
          class: "h-5 w-5 text-red-500"
        })
      ], 512), [
        [_vShow, _ctx.errorMessage.length > 0]
      ])
    ]),
    _withDirectives(_createElementVNode("p", {
      id: "voucher-error",
      class: "mt-2 text-sm text-red-600"
    }, _toDisplayString(_ctx.errorMessage), 513), [
      [_vShow, _ctx.errorMessage.length > 0]
    ])
  ], 2))
}