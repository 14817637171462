import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "align-middle rounded-2xl p-4 sm:p-4 pl-3 sm:pl-5 text-md text-black" }
const _hoisted_3 = { class: "float-right ml-2 mt-0.5 cursor-pointer" }
const _hoisted_4 = { class: "flex" }
const _hoisted_5 = { class: "md:pl-2 lg:pl-4" }
const _hoisted_6 = { class: "select-none" }
const _hoisted_7 = { class: "mx-1 bg-white dark:bg-black rounded-lg text-black dark:text-white px-4 tracking-widest" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_XMarkIcon = _resolveComponent("XMarkIcon")!
  const _component_TransitionChild = _resolveComponent("TransitionChild")!
  const _component_TransitionRoot = _resolveComponent("TransitionRoot")!

  return (_openBlock(), _createBlock(_component_TransitionRoot, {
    as: "template",
    show: _ctx.isOpen
  }, {
    default: _withCtx(() => [
      _createVNode(_component_TransitionChild, {
        as: "template",
        enter: "ease-out duration-200",
        "enter-from": "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95",
        "enter-to": "opacity-100 translate-y-0 sm:scale-100",
        leave: "ease-in duration-200",
        "leave-from": "opacity-100 translate-y-0 sm:scale-100",
        "leave-to": "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            id: _ctx.id,
            class: "relative shadow-xl z-10 -mt-4 -mx-2 md:mx-0 rounded-2xl backdrop-filter backdrop-blur-xl p-0 bg-gradient-to-r from-gr-yellow via-gr-primary to-gr-primaryDark"
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_XMarkIcon, {
                  class: "w-5",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
                })
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("p", _hoisted_5, [
                  _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('greeveioIsCurrentlyBetaButWeAreSoProudOfItWeAlread')), 1),
                  _createElementVNode("span", {
                    class: "font-bold ml-2 cursor-pointer",
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleAction && _ctx.handleAction(...args)))
                  }, [
                    _createTextVNode(_toDisplayString(_ctx.$t('saveOnYourSubscription')) + " ", 1),
                    _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.promoCode), 1)
                  ])
                ])
              ])
            ])
          ], 8, _hoisted_1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["show"]))
}