import {CustomerInterface} from "@/greeve/shop/customer/customer.interface";
import {Currency} from "@/greeve/core/country/currency.type";
import useCurrencyFactory from "@/composable/core/useCurrencyFactory";
import {OrderInterface, OrderState, OrderType} from "@/greeve/shop/order/order.interface";
import {OrderItem} from "@/greeve/shop/order/orderItem/order_item.type";
import {OrderItemList} from "@/greeve/shop/order/orderItem/order_item_list.type";
import {AddressInterface} from "@/greeve/user/user.interface";
import {PaymentItemList} from "@/greeve/shop/order/paymentItem/payment_item_list.type";
import {JournalList} from "@/greeve/shop/order/journal/journal_list.type";
import {Customer} from "@/greeve/shop/customer/customer.type";

export class Order implements OrderInterface {
	id: number;
	uuid: string;
	type: OrderType;
	state: OrderState;
	order_items?: OrderItemList|null;
	payment_items?: PaymentItemList|null;
	journals?: JournalList|null;
	user_address?: AddressInterface|null;
	customer?: CustomerInterface|null;
	tax_code: string;
	country_code: string;
	currency_code: string;
	order_number: string;
	transaction?: string|null;
	reference?: string|null;
	external_reference?: string|null;
	net_amount: number;
	total_amount: number;
	tax_amount: number;
	item_quantity: number;
	grand_total: number;
	exchange_rate: number;
	data?: any;
	created_at?: Date;
	updated_at?: Date;


	constructor(id: number, uuid: string, type: OrderType, state: OrderState, tax_code: string, country_code: string, currency_code: string, order_number: string, net_amount: number, total_amount: number, tax_amount: number, item_quantity: number, grand_total: number,  exchange_rate: number, transaction?: string|null, reference?: string|null, external_reference?: string|null, order_items?: OrderItemList|null, payment_items?: PaymentItemList|null, customer?: Customer|null, journals?: JournalList|null, user_address?: AddressInterface|null, data: any = null, created_at?: Date, updated_at?: Date) {
		this.id = id;
		this.uuid = uuid;
		this.type = type;
		this.state = state;
		this.tax_code = tax_code;
		this.country_code = country_code;
		this.currency_code = currency_code;
		this.order_number = order_number;
		this.net_amount = net_amount;
		this.tax_amount = tax_amount;
		this.total_amount = total_amount;
		this.item_quantity = item_quantity;
		this.grand_total = grand_total;
		this.exchange_rate = exchange_rate;
		this.transaction = transaction;
		this.reference = reference;
		this.external_reference = external_reference;
		this.order_items = order_items;
		this.payment_items = payment_items;
		this.journals = journals;
		this.customer = customer;
		this.user_address = user_address;
		this.data = data;
		this.created_at = created_at;
		this.updated_at = updated_at;
	}

	getCurrencyObject(): Currency
	{
		return useCurrencyFactory().getCurrencyFactory().createCurrencyByCode(this.currency_code);
	}

	isOrderSuccessfully(): boolean
	{
		return [OrderState.NEW, OrderState.DONE].includes(this.state);
	}

	getInvoiceLink(): string|any
	{
		let invoiceLink :any = '';
		this.journals?.forEach((journal) => {
			if (journal.getInvoiceLink()) {
				invoiceLink = journal.getInvoiceLink();
				return;
			}
		});
		return invoiceLink;
	}

	private getAmountByOrderItems(brutto = true, toDecimal= true): number
	{
		let value = 0;
		this.order_items?.forEach((item: OrderItem) => {
			if (brutto) {
				value += (item.total_amount);
			} else {
				value += (item.net_amount*item.quantity);
			}
		});
		//TODO check if brutto or netto
		if (toDecimal) {
			value = (value/100);
		}
		return value;
	}

	getProductDescriptionSummary(): string {
		let result = '';

		if (this.order_items) {
			this.order_items.forEach((orderItem) => {
					if (orderItem.getProductName()) {
						if (result.length > 0) {
							result += '<br>';
						}
						result += orderItem.getProductName();
					}
			});
		}

		if (result.length === 0) {
			result = 'Unknown Product';
		}

		return result;
	}
}