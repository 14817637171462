import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, vModelText as _vModelText, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "feature-notify-container" }
const _hoisted_2 = { class: "py-24" }
const _hoisted_3 = { class: "mx-auto max-w-7xl sm:px-6 lg:px-8" }
const _hoisted_4 = { class: "relative isolate overflow-hidden bg-gray-900 px-6 py-16 md:py-24 shadow-2xl rounded-3xl sm:px-24 xl:py-32" }
const _hoisted_5 = {
  id: "feature-notify-alert-message",
  class: "pb-10"
}
const _hoisted_6 = { class: "rounded-xl bg-green-50 p-4" }
const _hoisted_7 = { class: "flex" }
const _hoisted_8 = { class: "flex-shrink-0" }
const _hoisted_9 = { class: "ml-3" }
const _hoisted_10 = { class: "text-sm font-medium text-green-800" }
const _hoisted_11 = { class: "ml-auto pl-3 hidden" }
const _hoisted_12 = { class: "-mx-1.5 -my-1.5" }
const _hoisted_13 = {
  type: "button",
  class: "inline-flex bg-green-50 rounded-xl p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
}
const _hoisted_14 = { class: "sr-only" }
const _hoisted_15 = { class: "rounded-md bg-red-50 p-4" }
const _hoisted_16 = { class: "flex" }
const _hoisted_17 = { class: "flex-shrink-0 hidden" }
const _hoisted_18 = { class: "ml-3" }
const _hoisted_19 = { class: "text-sm font-medium text-red-800" }
const _hoisted_20 = { class: "mx-auto max-w-2xl text-center text-3xl font-bold tracking-tight text-white sm:text-4xl" }
const _hoisted_21 = { class: "mx-auto mt-2 max-w-xl text-center text-lg leading-8 text-gray-300" }
const _hoisted_22 = ["placeholder"]
const _hoisted_23 = {
  type: "submit",
  class: "flex-none rounded-xl bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CheckCircleIcon = _resolveComponent("CheckCircleIcon")!
  const _component_XMarkIcon = _resolveComponent("XMarkIcon")!
  const _component_XCircleIcon = _resolveComponent("XCircleIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _withDirectives(_createElementVNode("div", _hoisted_5, [
            _withDirectives(_createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_CheckCircleIcon, {
                      class: "h-5 w-5 text-green-400",
                      "aria-hidden": "true"
                    })
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.t('contact.FormSuccess')), 1)
                  ]),
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("button", _hoisted_13, [
                        _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.t('contact.FormClose')), 1),
                        _createVNode(_component_XMarkIcon, {
                          class: "h-5 w-5",
                          "aria-hidden": "true"
                        })
                      ])
                    ])
                  ])
                ])
              ])
            ], 512), [
              [_vShow, _ctx.successMessage]
            ]),
            _withDirectives(_createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("div", _hoisted_17, [
                    _createVNode(_component_XCircleIcon, {
                      class: "h-5 w-5 text-red-400",
                      "aria-hidden": "true"
                    })
                  ]),
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("h3", _hoisted_19, _toDisplayString(_ctx.errorMessage), 1)
                  ])
                ])
              ])
            ], 512), [
              [_vShow, _ctx.errorMessage]
            ])
          ], 512), [
            [_vShow, _ctx.successMessage || _ctx.errorMessage]
          ]),
          _createElementVNode("h2", _hoisted_20, _toDisplayString(_ctx.title), 1),
          _createElementVNode("p", _hoisted_21, _toDisplayString(_ctx.description), 1),
          _createElementVNode("form", {
            class: "mx-auto grid gap-y-4 grid-cols-1 mt-10 md:flex max-w-md gap-x-4",
            onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.sendNotifyForm && _ctx.sendNotifyForm(...args)), ["prevent"]))
          }, [
            _cache[2] || (_cache[2] = _createElementVNode("label", {
              for: "email-address",
              class: "sr-only"
            }, "$t('emailTs')", -1)),
            _withDirectives(_createElementVNode("input", {
              id: "email-address",
              name: "email",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.notifyFormData.email) = $event)),
              type: "email",
              autocomplete: "email",
              required: "",
              class: "min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6",
              placeholder: _ctx.$t('feature.notify.email_placeholder')
            }, null, 8, _hoisted_22), [
              [_vModelText, _ctx.notifyFormData.email]
            ]),
            _createElementVNode("button", _hoisted_23, _toDisplayString(_ctx.$t('feature.notify.notify_button')), 1)
          ], 32),
          _cache[3] || (_cache[3] = _createStaticVNode("<svg viewBox=\"0 0 1024 1024\" class=\"absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2\" aria-hidden=\"true\"><circle cx=\"512\" cy=\"512\" r=\"512\" fill=\"url(#759c1415-0410-454c-8f7c-9a820de03641)\" fill-opacity=\"0.7\"></circle><defs><radialGradient id=\"759c1415-0410-454c-8f7c-9a820de03641\" cx=\"0\" cy=\"0\" r=\"1\" gradientUnits=\"userSpaceOnUse\" gradientTransform=\"translate(512 512) rotate(90) scale(512)\"><stop stop-color=\"#7775D6\"></stop><stop offset=\"1\" stop-color=\"#E935C1\" stop-opacity=\"0\"></stop></radialGradient></defs></svg>", 1))
        ])
      ])
    ])
  ]))
}