<template>
	<ion-page>
		<ion-content>
			<section :style="{ paddingTop: headerHeight + 'px' }" class="h-full pb-1 px-2 lg:px-0">
				<div class="px-2 sm:px-4 lg:gap-x-5 flex-none lg:flex relative h-full">
					<transition name="slide">
						<aside :class="asideClass">
							<div class="nav rounded-2xl hidden lg:block backdrop-filter backdrop-blur-xl lg:sticky lg:top-[6.5rem]">
								<div class="nav pb-4 rounded-2xl h-auto ">
									<div class="p-2 sm:p-4 pb-2 sm:pb-2 lg:pb-2 hidden lg:block text-right">
										<button @click="toggleMenu" class="px-2 py-2" data-tip="ausblenden"
														:class="[toggleButtonClass, groupMenuToggleState ? 'tooltip tooltip-right' : '' ]">
											<bars-3-icon v-if="groupMenuToggleState === false"
																	 class="text-gray-400 dark:text-gray-500 group-hover:text-gray-500 flex-shrink-0 h-6 w-6"/>
											<span v-else class="flex text-gray-400 dark:text-gray-500 group-hover:text-gray-500 ">
												<!--											<div class="rounded-2xl px-4 ml-0 mr-6 py-0 border-2 border-gr-dark">create</div>-->
												<bars3-bottom-right-icon class="h-6 w-6"></bars3-bottom-right-icon>
											</span>
										</button>
									</div>
									<group-list v-if="(loadingFinished && groupUuidByUrl.length > 0)  || hasEmptyUrl" :update-timestamp="updateTimeStamp" :group_uuid="groupUuidByUrl" :group_reference="groupReferenceByUrl"
															:is-menu-open="groupMenuToggleState" @group-selected="focusInputField"></group-list>
								</div>
							</div>
						</aside>
					</transition>
					<main class="space-y-6 px-0 w-full">
						<ion-router-outlet :key="$route.fullPath"
															 class="h-auto w-full absolute lg:relative"></ion-router-outlet>
					</main>
				</div>
			</section>
			<FooterBar v-show="false" class="mt-12"></FooterBar>
		</ion-content>
	</ion-page>
</template>

<script lang="ts">
import {IonPage, IonContent, IonRouterOutlet} from '@ionic/vue';
import {defineComponent, onMounted, ref, computed, Transition, watch} from 'vue';
import FooterBar from '@/components/positions/FooterBar.vue';
import {
	Bars3BottomRightIcon, Bars3Icon,
} from '@heroicons/vue/20/solid';
import useSystem from '@/composable/core/useSystem';
import useTranslation from '@/composable/translation/useTranslation';
import {RouteLocationNormalizedLoaded, useRoute} from 'vue-router';
import {useHeaderHeight} from '@/composable/global/useHeaderHeight';
import GroupList from '@/components/assistant/Group/GroupList.vue';
import {Base64} from '@/greeve/core/encoding';
import useCustomStore from '@/composable/custom/useCustomStore';
import router from '@/router';

export default defineComponent({
	name: 'AssistantPage',
	components: {
		GroupList,
		IonRouterOutlet,
		Bars3Icon,
		Bars3BottomRightIcon,
		IonPage,
		IonContent,
		Transition,
		FooterBar,
	},
	setup() {
		const {headerHeight} = useHeaderHeight();
		const {isFooterVisible, isAssistantGroupMenuOpen, setIsAssistantGroupMenuOpen,} = useCustomStore();
		const route = useRoute();
		const groupUuidByUrl = ref('');
		const groupReferenceByUrl = ref('');
		const loadingFinished = ref(false);
		const hasEmptyUrl = ref(false);
		const updateTimeStamp = ref<number|null>(null);
		const groupChanged = ref<number|undefined>();
		const groupMenuToggleState = ref(false);

		const {t} = useTranslation();

		const toggleMenu = async () => {
			groupMenuToggleState.value = !groupMenuToggleState.value;
			setTimeout(() => {
				setIsAssistantGroupMenuOpen(groupMenuToggleState.value);
			}, 10)
		};

		const {scrollToTop} = useSystem();

		function selectGroupByRoute(route: RouteLocationNormalizedLoaded) {
			const params = route.params;
			const hasEmptyParams = !params || !params.name || !params.uuid || !params.reference;
			const isGroupReferenceEmptyAndGroupUuidEmpty = groupReferenceByUrl.value.length === 0 && groupUuidByUrl.value.length === 0;
			if (hasEmptyParams && isGroupReferenceEmptyAndGroupUuidEmpty) {
				hasEmptyUrl.value = true;
				loadingFinished.value = true;
			} else if (hasEmptyParams && !isGroupReferenceEmptyAndGroupUuidEmpty) {
				hasEmptyUrl.value = false;
				loadingFinished.value = true;
			} else {
				hasEmptyUrl.value = false;
				groupUuidByUrl.value = Base64.decode(params.uuid.toString());
				groupReferenceByUrl.value = params.reference.toString();
				loadingFinished.value = true;
			}
			updateTimeStamp.value = new Date().getTime();
			if (hasEmptyUrl.value) {
				router.push('/assistant/chat');
			}
		}

		function focusInputField() {
			groupChanged.value = new Date().getTime();
		}

		watch(route, (route) => {
			if (route.path.includes('/assistant')) {
				loadingFinished.value = false;
				selectGroupByRoute(route);
			}
		});

		onMounted(() => {
			groupMenuToggleState.value = isAssistantGroupMenuOpen.value;
			selectGroupByRoute(route);
			scrollToTop();
		});

		const asideClass = computed(() => (groupMenuToggleState.value
				? 'z-50 pb-0 lg:pb-4 lg:col-span-auto lg:sticky lg:top-0'
				: 'z-50 pb-0 lg:pb-4 lg:col-span-auto lg:sticky lg:top-0'));

		const toggleButtonClass = computed(() => (groupMenuToggleState.value ? '' : ''));

		const menuClass = computed(() => (groupMenuToggleState.value ? 'menu-open' : 'menu-close'));

		return {
			t,
			toggleMenu,
			asideClass,
			toggleButtonClass,
			menuClass,
			headerHeight,
			groupUuidByUrl,
			groupReferenceByUrl,
			loadingFinished,
			hasEmptyUrl,
			updateTimeStamp,
			isFooterVisible,
			focusInputField,
			groupChanged,
			groupMenuToggleState
		};
	},
});
</script>

<style lang="scss" >
.slide-enter-active {
	transition: transform 0.3s;
}

.slide-leave-active {
	transition: transform 0.3s;
}

.slide-enter,
.slide-leave-to {
	transform: translateX(-100%);
}

ion-content {
  --padding-top: var(--offset-top, 0);
}

</style>
