import {Cart} from "@/greeve/shop/cart/cart.type";
import useCartFactory from "@/composable/greeve/useCartFactory";
import {CheckoutSteps} from "@/greeve/shop/checkout/steps/steps.type";
import {CURRENT_STEP} from "@/composable/greeve/useShop";
import {StepInterface} from "@/greeve/shop/checkout/steps/step.interface";
import {ProductList} from "@/greeve/shop/product/product_list.type";
import {Product} from "@/greeve/shop/product/product.type";
import {Price} from "@/greeve/shop/product/price.type";
import {PriceList} from "@/greeve/shop/product/price_list.type";
import {CartItem} from "@/greeve/shop/cart/item/cart_item.type";
import {Customer} from "@/greeve/shop/customer/customer.type";
import {CustomerAddress} from "@/greeve/shop/customer/customer_address.type";
import {CheckoutSession} from "@/greeve/shop/checkout/session/checkout_session.type";
import {
	GreeveProductPriceSubType,
	GreeveProductPriceType,
} from '@/greeve/shop/product/price.interface';
import {
	AbstractDiscountItem
} from '@/greeve/shop/cart/discount/abstract_discount_item.type';

const {getCartFactory, getCartItemFactory, getProductFactory} = useCartFactory();

/* eslint-disable @typescript-eslint/no-unused-vars */


const shop = {
	namespaced: true,
	state: () => ({
		cart: Cart,
		checkoutSteps: CheckoutSteps,
		products: ProductList,
		prices: PriceList,
		lastProductRefreshDate: Date,
	}),
	mutations: {
		setCart(state: any, cart: Cart) {
			state.cart = cart;
		},
		setCheckoutSteps(state: any, checkoutSteps: CheckoutSteps) {
			state.checkoutSteps = checkoutSteps;
		},
		setProducts(state: any, products: ProductList) {
			state.products = products;
		},
		setPrices(state: any, prices: PriceList) {
			state.prices = prices;
		},
		setCustomer(state: any, customer: Customer) {
			state.cart.customer = customer;
		},
		setLastProductRefreshDate(state: any, date: Date) {
			state.lastProductRefreshDate = date;
		},
		setCurrency(state: any, currencyCode: string) {
			state.cart.currency = currencyCode;
		},
		setCustomerAddress(state: any, customerAddress: CustomerAddress) {
			state.cart.customer.address = customerAddress;
		},
		setCheckoutSession(state: any, checkoutSession: CheckoutSession) {
			state.cart.checkoutSession = checkoutSession;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		updateCheckoutStepStatusByStep(state, stepToupdated_ata) {
			const step = stepToupdated_ata.step;
			const stepState = stepToupdated_ata.state;
			const index = state.checkoutSteps._steps.findIndex((currentStep: StepInterface) => {
				return currentStep.id === step.id;
			});

			if (index !== -1) {
				state.checkoutSteps._steps[index].status = stepState;
			}
		},
		removeCartItemFromCart(state: any, cartItem_Id: string) {
			const index = state.cart.items.findIndex((currentCartItem: CartItem) => {
				return currentCartItem.id === cartItem_Id;
			});

			if (index !== -1) {
				state.cart.items.splice(index, 1);
				state.cart.recalculateCart = true;
			}
		},
		changeCartItemCount(state: any, {cartItem_id, count}: any) {
			const index = state.cart.items.findIndex((currentCartItem: CartItem) => {
				return currentCartItem.id === cartItem_id;
			});

			if (index !== -1) {
				state.cart.items[index].quantity = count;
			}
		},
		addCartItemCount(state: any, {cartItem_id, count}: any) {
			const index = state.cart.items.findIndex((currentCartItem: CartItem) => {
				return currentCartItem.id === cartItem_id;
			});

			if (index !== -1) {
				state.cart.items[index].quantity += count;
			}
		},
		addCartItemToCart(state: any, cartItem: CartItem) {
			if (!state.cart.items) {
				state.cart.items = useCartFactory().getCartItemFactory().createEmptyCartItemList();
			}
			state.cart.items.push(cartItem);
			state.cart.recalculateCart = true;
		},
		addDiscountItemToCart(state: any, discountItem: AbstractDiscountItem) {
			if (!state.cart.discounts) {
				state.cart.discounts = useCartFactory().getDiscountItemFactory().createEmptyDiscountItemList();
			}
			if (!discountItem || !discountItem.id) {
				throw new Error('Invalid Discount!');
			}
			state.cart.discounts.push(discountItem);
			state.cart.recalculateCart = true;
		},
		removeDiscountItemFromCart(state: any, discountItem_Id: string) {
			const index = state.cart.discounts.findIndex((currentDiscountItem: AbstractDiscountItem) => {
				return currentDiscountItem.id === discountItem_Id;
			});

			if (index !== -1) {
				state.cart.discounts.splice(index, 1);
				state.cart.recalculateCart = true;
			}
		},
		recalculateCart(state: any, forceRecalculate = false) {
			if (state.cart instanceof Cart && (state.cart.recalculateCart === true || !forceRecalculate) ) {
				//TODO check discounts
				state.cart.total_amount = state.cart.getTotalAmountCalculated();
				state.cart.tax_amount = state.cart.getTotalTaxAmountCalculated();
				state.cart.recalculateCart = false;
			}
		},
	},
	actions: {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		updateCheckoutStepState({ commit, state, getters }, udpateCheckoutData) {
			const stepId = udpateCheckoutData.stepId;
			const stepState = udpateCheckoutData.state;
			if (!stepState) {
				console.log("invalid state " + stepState);
				return;
			}
			const stepFound = getters.getSteps.steps.find((step: StepInterface) => step.id === stepId);
			if (stepFound) {
				const stepToupdated_ata = {
					step: stepFound,
					state: stepState
				};
				commit('updateCheckoutStepStatusByStep', stepToupdated_ata);
			}
		},
		removeCartItem_by_Id({ commit }: any, cartItem_Id: string) {
			try {
				commit('removeCartItemFromCart', cartItem_Id);
				commit('recalculateCart');
			} catch (error: any) {
				console.error(error);
				// return `Response fails with error: ${error.code || 'unknown'}!`;
			}
		},
		changeCartItemCount({ commit, state, getters }: any, {cartItem_id, count = 1}: any) {
			try {
				if (cartItem_id) {
					commit('changeCartItemCount', {cartItem_id, count});
				}
			} catch (error: any) {
				console.error(error);
			}
		},
		addProductToCart({ commit, state, getters }: any, {product_id, priceType, priceSubType}: any) { //TODO define interface
			try {
				let product;
				if (priceType || priceSubType) {
					product = getters.getProduct_by_Id_And_PriceType(product_id, priceType, priceSubType);
				} else {
					product = getters.getProduct_by_Id(product_id);
				}
				if (product !== undefined) {
					const cartItem = state.cart.items.find((currentCartItem: CartItem) => {
						return currentCartItem.product_id === product_id;
					});

					if (cartItem) {
						const cartItem_id = cartItem.id;
						const count = 1;
						commit('addCartItemCount', {cartItem_id, count});
					} else {
						commit('addCartItemToCart', getCartItemFactory().createCartItemByProduct(product));
					}
					commit('recalculateCart');
				}
			} catch (error: any) {
				console.error(error);
			}
		},
		addExistingProductToCartItem({ commit, state, getters }: any,  {cartItemId, product}: any) { //TODO define interface
			try {
				const cartItem = state.cart.items.find((currentCartItem: CartItem) => {
					return currentCartItem.id === cartItemId;
				});

				if (cartItem) {
					const cartItem_id = cartItem.id;
					const count = 1;
					commit('addCartItemCount', {cartItem_id, count});
				} else {
					commit('addCartItemToCart', getCartItemFactory().createCartItemByProduct(product));
				}
				commit('recalculateCart');
			} catch (error: any) {
				console.error(error);
			}
		},
		addDiscountToCart({ commit, state, getters }: any, {discountItem}: any|AbstractDiscountItem) { //TODO define interface
			try {
				let foundDiscountItem;
				if (state.cart.discounts) {
					foundDiscountItem = state.cart.discounts.find((discountCartItem: AbstractDiscountItem) => {
						if (discountItem.promotionCode && discountCartItem.promotionCode) {
							return discountCartItem.promotionCode === discountItem.promotionCode;
						} else {
							return discountCartItem.id === discountItem.id;
						}
					});
				}

				if (foundDiscountItem) {
					console.log("EXISTS")
					//TODO add warning alert only one is allowed!
				} else {
					commit('addDiscountItemToCart', discountItem);
				}
				commit('recalculateCart');
			} catch (error: any) {
				console.error(error);
			}
		},
		removeDiscountItem_by_Id({ commit }: any, discountItem_Id: string) {
			try {
				commit('removeDiscountItemFromCart', discountItem_Id);
				commit('recalculateCart');
			} catch (error: any) {
				console.error(error);
			}
		},
		resetCheckoutSteps({ commit, state, getters }: any) {
			try {
				commit('setCheckoutSteps', new CheckoutSteps());
			} catch (error: any) {
				console.error(error);
			}
		},
	},
	getters: {
		isCartInitialized: (state: any): boolean => {
			return !(!state.cart || !state.cart.id);
		},
		getCart: (state: any): Cart => {
			if (!state.cart || !state.cart.id) {
				return getCartFactory().createEmptyCart();
			} else if (!(state.cart instanceof Cart) && state.cart) {
				return getCartFactory().createCartByResponse(state.cart);
			} else {
				return state.cart;
			}
		},
		getSteps: (state: any): CheckoutSteps => {
			if (!state.checkoutSteps) {
				return new CheckoutSteps();
			} else if (!(state.checkoutSteps instanceof CheckoutSteps)) {
				const checkoutSteps = new CheckoutSteps();
				if (state.checkoutSteps !== undefined && state.checkoutSteps._steps) {
					checkoutSteps.steps = state.checkoutSteps._steps;
				}

				return checkoutSteps;
			} else {
				return state.checkoutSteps;
			}
		},
		getActiveStepId: (state: any, getters: any): number => {
			const step = getters.getSteps.getStepByStatus(CURRENT_STEP);
			if (step) {
				return step.id;
			}
			return 1;
		},
		getLastProductRefreshDate: (state: any, getters: any): Date|undefined => {
			if (!state.lastProductRefreshDate) {
				return undefined;
			} else if (!(state.lastProductRefreshDate instanceof Date)) {
				return new Date(state.lastProductRefreshDate);
			} else {
				return state.lastProductRefreshDate;
			}
		},
		getProducts: (state: any): ProductList|undefined => {
			if (state.products instanceof ProductList) {
				return state.products;
			} else if (!(state.products instanceof ProductList)) {
				return getProductFactory().createProductListByResponse(state.products);
			} else {
				return undefined;
			}
		},
		getProduct_by_Id: (state: any, getters: any) => (product_id: string): Product | undefined => {
			const product = getters.getProducts.getProduct_by_Id(product_id);
			if (product) {
				return product;
			}
			return undefined;
		},
		getProduct_by_ProductId_And_PriceId: (state: any, getters: any) => (product_id: string, price_id: string): Product | undefined => {
			try {
				const product = getters.getProducts.getProduct_by_ProductId_And_PriceId(product_id, price_id);
				if (product) {
					return product;
				}
			} catch (e) {
				console.error(e);
			}

			return undefined;
		},
		getProduct_by_PriceId: (state: any, getters: any) => (price_id: string): Product | undefined => {
			try {
				// const products: any = [...getters.getProducts];
				// const product = products?.getProduct_by_PriceId(price_id);
				const product = getters.getProducts.getProduct_by_PriceId(price_id);
				if (product) {
					return product;
				}
			} catch (e) {
				console.error(e);
			}

			return undefined;
		},
		getProduct_by_Id_And_PriceType: (state: any, getters: any) => (product_id: string, priceType: GreeveProductPriceType|undefined = undefined, priceSubType: GreeveProductPriceSubType|undefined = undefined): Product | undefined => {
			const product = getters.getProducts.getProduct_by_Id_And_PriceType(product_id, priceType, priceSubType);
			if (product) {
				return product;
			}
			return undefined;
		},
		getPrices: (state: any): PriceList|undefined => {
			if (state.prices instanceof PriceList) {
				return state.prices;
			} else if (!(state.prices instanceof PriceList)) {
				return getProductFactory().createPriceListByResponse(state.prices);
			} else {
				return undefined;
			}
		},
		getPrice_by_Id: (state: any, getters: any) => (price_id: string): Price | undefined => {
			const price = getters.getPrices.getPrice_by_Id(price_id);
			if (price) {
				return price;
			}
			return undefined;
		},
	}
}

export default shop;