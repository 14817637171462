import {CartItemInterface, LabelInterface} from "@/greeve/shop/cart/cart.interface";
import {Product} from "@/greeve/shop/product/product.type";
import {Price} from "@/greeve/shop/product/price.type";
import useShop from "@/composable/greeve/useShop";

export class CartItem implements CartItemInterface {
	id: string;
	amount: number;
	quantity: number;
	description?: string;
	description_2?: string;
	labels?: Array<LabelInterface>|null;
	name: string;
	price_id: string;
	product_id: string;
	product_reference?: string|undefined;


	constructor(id: string, amount: number, quantity = 1, name: string, price_id: string, product_id: string, description: string, description_2 = '', labels: Array<LabelInterface>|null = null, productReference: string|undefined = undefined) {
		this.id = id;
		this.quantity = quantity;
		this.amount = amount;
		this.name = name;
		this.price_id = price_id;
		this.product_id = product_id;
		this.description = description;
		this.description_2 = description_2;
		this.labels = labels;
		this.product_reference = productReference;
	}

	getProduct(): Product|undefined
	{
		if (this.price_id) {
			return useShop().getProduct_by_ProductId_And_PriceId(this.product_id, this.price_id)
		}
		return useShop().getProduct_by_ProductId(this.product_id);
	}

	getPrice(): Price|undefined
	{
		const price = this.getProduct()?.price;
		if (!price) {
			return useShop().getPrice_by_PriceId(this.price_id);
		}
		return price;
	}

	getQuantity(): number|string
	{
		return this.quantity;
	}

	getAmount(): number|string
	{
		let value: string|number = this.amount;
		if (value) {
			value = (value / 100).toFixed(2);
		}
		return value;
	}

	getMaxItemCount(): number
	{
		return 5;
	}

	getMaxItemCountList(): number[]
	{
		const result: number[] = [];
		for (let i = 1; i <= this.getMaxItemCount(); i++) {
			result.push(i);
		}
		return result;
	}
}