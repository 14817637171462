import {Cart} from "@/greeve/shop/cart/cart.type";
import {CartState} from "@/greeve/shop/cart/cart.interface";
import useCartFactory from "@/composable/greeve/useCartFactory";
import {CurrencyCode} from "@/greeve/core/country/currency.type";

export class CartFactory {
	/**
	 *
	 * @param item
	 */
	createCartByResponse(item: Record<string, any>): Cart {
		const state = item.state ?? CartState.NEW;
		const currency = item.currency ?? CurrencyCode.EUR;
		let customer;
		if (item.customer) {
			customer = useCartFactory().getCustomerFactory().createCustomerByResponse(item.customer);
		}
		let checkoutSession;
		if (item.checkoutSession) {
			checkoutSession = useCartFactory().getCheckoutSessionFactory().createCheckoutSessionByResponse(item.checkoutSession);
		}
		let items;
		if (item.items) {
			items = useCartFactory().getCartItemFactory().createCartItemListListByResponse(item.items);
		} else {
			items = useCartFactory().getCartItemFactory().createEmptyCartItemList();
		}
		let discounts;
		if (item.discounts) {
			discounts = useCartFactory().getDiscountItemFactory().createDiscountItemListListByResponse(item.discounts);
		} else {
			discounts = useCartFactory().getDiscountItemFactory().createEmptyDiscountItemList();
		}
		const shippingAmount = item.shipping_amount;
		const taxAmount = item.tax_amount;
		const totalAmount = item.total_amount;
		const data = item.data;
		const created_at = item.created_at ? new Date(item.created_at) : (item.createdAt ? new Date(item.createdAt) : new Date());
		const updated_at = item.updated_at ? new Date(item.updated_at) : (item.updatedAt ? new Date(item.updatedAt) : new Date());

		return new Cart(currency, state, items, discounts, customer, checkoutSession, shippingAmount, taxAmount, totalAmount, created_at, updated_at, data);
	}

	createEmptyCart(): Cart {
		const items = useCartFactory().getCartItemFactory().createEmptyCartItemList();
		const discounts = useCartFactory().getDiscountItemFactory().createEmptyDiscountItemList();
		return new Cart(CurrencyCode.EUR, CartState.NEW, items, discounts);
	}
}