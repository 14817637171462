import {
	QuotaTransactionList,
} from '@/greeve/user/quota/transaction/quota_transaction_list.type';
import {
	QuotaTransaction,
} from '@/greeve/user/quota/transaction/quota_transaction.type';
import {
	QuotaTransactionState,
	QuotaTransactionType,
} from '@/greeve/user/quota/transaction/quota_transaction.interface';
import {
	AbstractPaginationFactory
} from '@/greeve/core/abstract_pagination_factory.type';

export class QuotaTransactionFactory extends AbstractPaginationFactory {
	/**
	 *
	 * @param quotaTransactions
	 */
	createQuotaTransactionListByResponse(quotaTransactions: Record<string, any>): QuotaTransactionList {
		const quotaTransactionList = new QuotaTransactionList();

		if (this.hasPaginationMetaData(quotaTransactions)) {
			quotaTransactionList.enhanceMetaData(this.getMetaDataByResponse(quotaTransactions));
			quotaTransactions = quotaTransactions.data;
		}

		if (quotaTransactions) {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			for (const [key, item] of Object.entries(quotaTransactions)) {
				quotaTransactionList.push(this.createQuotaTransactionByResponse(item));
			}
		}

		return quotaTransactionList;
	}

	/**
	 *
	 * @param item
	 */
	createQuotaTransactionByResponse(item: Record<string, any>): QuotaTransaction {
		const id = item.id;
		const uuid = item.uuid;
		const quota_id = item.quota_id;
		const parent_id = item.parent_id ? item.parent_id : (item.parentId ? item.parentId : undefined) ?? undefined;
		const type = item.type;
		const sub_type = item.sub_type;
		const state = item.state;
		const reference = item.reference;
		const amount = item.amount;
		const delta = item.delta;
		const subject = item.subject;
		const description = item.description;
		const data = item.data;
		const created_at = item.created_at ? new Date(item.created_at) : (item.createdAt ? new Date(item.createdAt) : new Date());
		const updated_at = item.updated_at ? new Date(item.updated_at) : (item.updatedAt ? new Date(item.updatedAt) : new Date());

		return new QuotaTransaction(id, uuid, quota_id, type, state, amount, delta, sub_type, parent_id, reference, subject, description, data, created_at, updated_at);
	}

	createEmptyQuotaTransaction(): QuotaTransaction {
		return new QuotaTransaction(0, '', 0, QuotaTransactionType.QUOTA_TRANSACTION_TYPE_CONSUMED, QuotaTransactionState.QUOTA_TRANSACTION_STATE_DONE, 0);
	}
}