export const enum QuotaTransactionType {
	QUOTA_TRANSACTION_TYPE_DEPOSIT = 'deposit',
	QUOTA_TRANSACTION_TYPE_CONSUMED = 'consumed',
	QUOTA_TRANSACTION_TYPE_CANCELED = 'canceled',
}

export const enum QuotaTransactionState {
	QUOTA_TRANSACTION_STATE_DONE = 'done',
	QUOTA_TRANSACTION_STATE_ERROR = 'error',
	QUOTA_TRANSACTION_STATE_PENDING = 'pending',
}

export interface QuotaTransactionInterface {
	id: number;
	uuid: string;
	quota_id: number;
	parent_id?: number;
	type: QuotaTransactionType,
	sub_type?: any,
	state: QuotaTransactionState,
	reference?: string,
	amount: number,
	delta?: number,
	subject?: string,
	description?: string,
	data?: any,
	created_at?: Date,
	updated_at?: Date,
}