import {PaymentItemList} from "@/greeve/shop/order/paymentItem/payment_item_list.type";
import {PaymentItem} from "@/greeve/shop/order/paymentItem/payment_item.type";

export class PaymentItemFactory {
	/**
	 *
	 * @param paymentItemResponse
	 */
	createPaymentItemListByResponse(paymentItemResponse: Record<string, any>): PaymentItemList {
		const paymentItemList = new PaymentItemList();

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		for (const [key, item] of Object.entries(paymentItemResponse)) {
			paymentItemList.push(this.createPaymentItemByResponse(item));
		}

		return paymentItemList;
	}

	/**
	 *
	 * @param item
	 */
	createPaymentItemByResponse(item: Record<string, any>): PaymentItem {
		const id = item.id;
		const parent_id = item.parent_id ? item.parent_id : (item.parentId ? item.parentId : undefined) ?? undefined;
		const order_id = item.order_id;
		const type = item.type;
		const state = item.state;
		const tax_code = item.tax_code;
		const currency_code = item.currency_code;
		const reference = item.reference;
		const external_reference = item.external_reference;
		const net_amount = item.net_amount;
		const total_amount = item.total_amount;
		const tax_amount = item.tax_amount;
		const item_quantity = item.item_quantity;
		const grand_total = item.grand_total;
		const subject = item.subject;
		const request = item.request;
		const response = item.response;
		// const exchange_rate = item.exchange_rate;
		const data = item.data;
		const created_at = item.created_at ? new Date(item.created_at) : (item.createdAt ? new Date(item.createdAt) : new Date());
		const updated_at = item.updated_at ? new Date(item.updated_at) : (item.updatedAt ? new Date(item.updatedAt) : new Date());

		return new PaymentItem(id, order_id, type, state, currency_code, tax_code, net_amount, grand_total, total_amount, tax_amount, item_quantity, parent_id, reference, external_reference, subject, request, response, data, created_at, updated_at);
	}
}