import {Price} from '@/greeve/shop/product/price.type';

export const enum GreeveProductTypeInterface {
	SERVICE = 'service',
	DEFAULT = 'default',
}
export const enum GreeveProductReference {
	STRIPE_PRODUCT_ONE_TIME_CREDIT_90 = 'one_time_credit_90',
	STRIPE_PRODUCT_ONE_TIME_CREDIT_40 = 'one_time_credit_40',
	STRIPE_PRODUCT_ONE_TIME_CREDIT_10 = 'one_time_credit_10',
	STRIPE_PRODUCT_ONE_TIME_CREDIT_5 = 'one_time_credit_5',
	STRIPE_PRODUCT_RECURRING_STARTER_PLAN = 'recurring_starter_plan',
	STRIPE_PRODUCT_RECURRING_GROWTH_PLAN = 'recurring_growth_plan',
	STRIPE_PRODUCT_RECURRING_BUSINESS_PLAN = 'recurring_business_plan',
}
export const enum GreeveProductAttribute {
	STRIPE_PRODUCT_CREDIT_AMOUNT = 'product_credit_amount',
}


export interface ProductInterface {
	id: string,
	type: GreeveProductTypeInterface,
	product_reference?: string,
	product_group?: string,
	product_id: string,
	price_id?: string,
	price: Price,
	description: string,
	tax_code: string,
	attributes?: Record<string, any>,
	images?: Array<string>,
	unit_label?: string|null,
	data?: any,
	created_at?: Date,
	updated_at?: Date,
}