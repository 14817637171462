import {
	QuotaJournalInterface, QuotaJournalState, QuotaJournalType,
} from '@/greeve/user/quota/journal/quota_journal.interface';

export class QuotaJournal implements QuotaJournalInterface {
	id: number;
	uuid: string;
	quota_id: number;
	flow_id: number;
	type: QuotaJournalType;
	state: QuotaJournalState;
	reference?: string;
	delta?: number;
	subject?: string;
	description?: string;
	data?: any;
	created_at?: Date;
	updated_at?: Date;

	constructor(
			id: number, uuid: string, quota_id: number, flow_id: number, type: QuotaJournalType,
			state: QuotaJournalState, delta?: number, reference?: string,
			subject?: string, description?: string,	data: any = null, created_at?: Date, updated_at?: Date) {
		this.id = id;
		this.uuid = uuid;
		this.quota_id = quota_id;
		this.flow_id = flow_id;
		this.type = type;
		this.state = state;
		this.reference = reference;
		this.delta = delta;
		this.data = data;
		this.created_at = created_at;
		this.updated_at = updated_at;
	}
}