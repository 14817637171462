import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mx-auto w-full md:w-[34em]" }
const _hoisted_2 = { class: "mx-auto h-[254px] p-4 outer-card bg-white dark:bg-gr-darkest lg:gap-8" }
const _hoisted_3 = { class: "grid grid-cols-3 text-gray-500 dark:text-gray-200 mb-2" }
const _hoisted_4 = { class: "col-span-2" }
const _hoisted_5 = { class: "flex flex-row-reverse gap-3 text-right items-center" }
const _hoisted_6 = {
  class: "tooltip disabled:opacity-75",
  disabled: "",
  "data-tip": "change Language - available soon"
}
const _hoisted_7 = ["placeholder", "value", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SettingsNodePopover = _resolveComponent("SettingsNodePopover")!
  const _component_LanguageIcon = _resolveComponent("LanguageIcon")!
  const _component_DocumentDuplicateIcon = _resolveComponent("DocumentDuplicateIcon")!
  const _component_ArrowDownTrayIcon = _resolveComponent("ArrowDownTrayIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('yourTranscription')), 1),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_SettingsNodePopover),
          _withDirectives(_createElementVNode("button", _hoisted_6, [
            _createVNode(_component_LanguageIcon, { class: "h-5 w-5" })
          ], 512), [
            [_vShow, _ctx.allowChangeLanguage]
          ]),
          _withDirectives(_createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.copyContent(_ctx.lastRunResult ?? ''))),
            class: "tooltip",
            "data-tip": "copy Text"
          }, [
            _createVNode(_component_DocumentDuplicateIcon, { class: "h-5 w-5" })
          ], 512), [
            [_vShow, _ctx.allowCopyText && _ctx.lastRunResult]
          ]),
          _withDirectives(_createElementVNode("button", {
            class: "tooltip disabled:opacity-75",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.downloadPdf && _ctx.downloadPdf(...args))),
            "data-tip": "download PDF - available soon"
          }, [
            _createVNode(_component_ArrowDownTrayIcon, { class: "h-5 w-5" })
          ], 512), [
            [_vShow, _ctx.allowDownloadPdf]
          ])
        ])
      ]),
      _createElementVNode("textarea", {
        placeholder: _ctx.placeholderText,
        readonly: "",
        value: _ctx.selectedFlowProcess ? _ctx.lastRunResult : '',
        disabled: !_ctx.isTextAreaVisible(),
        class: _normalizeClass(['h-[85%] mb-2 bg-transparent w-full rounded-md outline-none dark:text-gray-400', !_ctx.isTextAreaVisible() ? 'disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none' : ''])
      }, null, 10, _hoisted_7)
    ])
  ]))
}