import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "py-2" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_line_animation = _resolveComponent("loading-line-animation")!
  const _component_LoadingAnimation = _resolveComponent("LoadingAnimation")!
  const _component_default_button = _resolveComponent("default-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createVNode(_component_loading_line_animation, { "fixed-loading-animation": true }, null, 512), [
      [_vShow, _ctx.isLoading]
    ]),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_LoadingAnimation, {
          key: 0,
          "fixed-loading-animation": true
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_default_button, {
        "button-color": "bg-gr-primary",
        "button-text": _ctx.$t('shop.checkout.payment_confirm.step'),
        "button-type": "button",
        onClick: _ctx.payOrder_via_Button
      }, null, 8, ["button-text", "onClick"])
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("p", {
        class: "text-xs text-gray-500",
        innerHTML: _ctx.$t('terms.ContractInfoPayButton')
      }, null, 8, _hoisted_2)
    ])
  ]))
}