<template>
  <ion-page>
    <ion-content :fullscreen="true">
<!--      <HeaderBar></HeaderBar>-->
<HeaderLogo>
	<div  class="rounded-3xl px-3 font-comfortaa py-0 bg-black dark:bg-gray-400 font-bold text-white dark:text-black mt-0 float-right text-tc-bg text-xs sm:text-base sm:mr-1.5 -mt-1 sm:-mt-2 ">{{ $t('beta') }}
	</div>
</HeaderLogo>
      <div class="mx-4 md:mx-7">
        <div class="py-32 xl:py-36 px-4 sm:px-6 lg:px-8 bg-tc-bg overflow-hidden">
          <div class="max-w-max lg:max-w-7xl mx-auto">
            <div class="relative mb-8 md:mb-2 md:px-6">
              <div class="text-base max-w-prose lg:max-w-none">
                <p class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-tc-blue sm:text-4xl">
                  {{ t('donate.header') }}</p>
              </div>
            </div>
			  <div class="relative">
				  <div class=" relative md:bg-tc-bg md:p-6">
					  <div class="lg:grid lg:gap-6">

						  <div class="break-words w-full text-lg lg:text-xl py-0 sm:py-0 lg:py-0 ">
							  <div class="mb-5">
								  <p><strong>{{ t('donate.detailHeader') }}</strong></p><br>
								  <p class="mb-4">{{ t('donate.detail1') }}</p>
								  <p class="mb-4">{{ t('donate.detail2') }}</p>
								  <p>{{ t('donate.detail3') }}</p>
							  </div>
							  <div class="mb-5">
<!--								  TODO add real Links for Donations-->
								  <div class="md:flex space-x-4">
									  <PayPalDonation></PayPalDonation>
									  <KoFiDonation></KoFiDonation>
								  </div>
							  </div>
							  <div class="mb-0">
								  <p>{{ t('donate.thanks') }}</p>
							  </div>
						  </div>


					  </div>
				  </div>
			  </div>

			  <div class="md:px-6">
            <SocialMedia></SocialMedia>
            </div>
          </div>
        </div>
      </div>
      <FooterBar></FooterBar>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {IonPage, IonContent} from '@ionic/vue';
import FooterBar from "@/components/positions/FooterBar.vue";
import HeaderLogo from "@/components/branding/HeaderLogo.vue";
import useTranslation from "@/composable/translation/useTranslation";
import SocialMedia from "@/components/branding/SocialMedia.vue";
import PayPalDonation from "@/components/payments/donations/PayPalDonation.vue";
import KoFiDonation from "@/components/payments/donations/KoFiDonation.vue";

export default defineComponent({
  name: 'PressPage',
  components: {
	  KoFiDonation,
	  PayPalDonation,
    SocialMedia,
    HeaderLogo,
    FooterBar, IonContent, IonPage},

  setup() {

    const {t} = useTranslation();
    return {t}  }
});

</script>
<style lang="scss">
</style>
