<template>
	<div class="container mx-auto ">
		<ThreeColumnLayout col-rounded="rounded-xl" first-col-bg="bg-white" middle-col-bg="bg-white" last-col-bg="bg-white" :isFirstCollapsed="true" :isLastCollapsed="true" first-width="2/4" last-width="1/3">
			<template #first-title>
				<div class="py-4 px-2">
					<input
							type="text"
							placeholder="search chatting"
							class="py-2 px-2 border-2 border-gray-200 rounded-2xl w-full"
					/>
				</div>
			</template>
			<template #first-content>

				<!-- user list -->
				<div
						v-for="user in users"
						:key="user.id"
						:class="['flex flex-row py-2 px-2 items-center border-b-[1px]', user.active ? 'border-l-4 border-l-[#25D366]' : '']"
				>
					<div class="w-2/4">
						<img :src="user.image" class="object-cover h-7 w-7 rounded-full" alt="" />
					</div>
					<div class="ml-2 w-full leading-[0.01em]">
						<div class="text-xs font-semibold line-clamp-1">{{ user.name }}</div>
						<span class="text-xs text-gray-500 line-clamp-1">{{ user.message }}</span>
					</div>
				</div>
				<!-- end user list -->
			</template>

			<template #middle-title>

<!--/ TODO maybe add here the menu when changing column -->
			</template>
			<template #middle-content>
				<ChatPage></ChatPage>
				<div class="flex flex-col justify-between h-full p-4">
					<div class="flex flex-col mt-5 overflow-y-auto h-96">
						<div
								v-for="(message, index) in messages"
								:key="index"
								:class="['flex mb-4', message.sent ? 'justify-end' : 'justify-start']"
						>
							<img
									v-if="!message.sent"
									src="https://source.unsplash.com/vpOeXr5wmR4/600x600"
									class="object-cover h-8 w-8 rounded-full"
									alt=""
							/>
							<div
									:class="[
                  'py-3 px-4 text-white',
                  message.sent ? 'mr-2 bg-gray-300 rounded-bl-xl rounded-tl-xl rounded-br-xl' : 'ml-2 bg-gray-200 rounded-bl-xl rounded-br-xl rounded-tr-xl'
                ]"
							>
								{{ message.text }}
							</div>
							<img
									v-if="message.sent"
									src="https://source.unsplash.com/vpOeXr5wmR4/600x600"
									class="object-cover h-8 w-8 rounded-full"
									alt=""
							/>
						</div>
					</div>
					<div class="py-5">
						<input
								class="w-full bg-gray-300 py-5 px-3 rounded-xl"
								type="text"
								placeholder="type your message here..."
								v-model="newMessage"
								@keyup.enter="sendMessage"
						/>
					</div>
				</div>
			</template>

			<template #last-title>
			</template>
			<template #last-content>
				<div class="flex flex-col">
					<div class="font-semibold text-xl py-4">Mern Stack Group</div>
					<img
							src="https://source.unsplash.com/L2cxSuKWbpo/600x600"
							class="object-cover rounded-xl h-64"
							alt=""
					/>
					<div class="font-semibold py-4">Created 22 Sep 2021</div>
					<div class="font-light">
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt,
						perspiciatis!
					</div>
				</div>
			</template>
		</ThreeColumnLayout>
	</div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import ThreeColumnLayout from '@/components/core/ThreeColumnLayout.vue';
import ChatPage from "@/views/assistant/Chat/ChatPage.vue";

export default defineComponent({
	name: 'WhatsAppPage',
	components: {
		ChatPage,
		ThreeColumnLayout,
	},
	setup() {
		const users = ref([
			{
				id: 1,
				name: 'Luis1994',
				message: 'Pick me at 9:00 Am',
				image: 'https://source.unsplash.com/_7LbC5J-jw4/600x600',
				active: false,
			},
			{
				id: 2,
				name: 'Everest Trip 2021',
				message: 'Hi Sam, Welcome',
				image: 'https://source.unsplash.com/otT2199XwI8/600x600',
				active: false,
			},
			{
				id: 3,
				name: 'MERN Stack',
				message: 'Lusi : Thanks Everyone',
				image: 'https://source.unsplash.com/L2cxSuKWbpo/600x600',
				active: true,
			},
			{
				id: 4,
				name: 'Javascript Indonesia',
				message: 'Evan : some one can fix this',
				image: 'https://source.unsplash.com/vpOeXr5wmR4/600x600',
				active: false,
			},
		]);

		const messages = ref([
			{
				text: 'Welcome to group everyone !',
				sent: true,
			},
			{
				text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat at praesentium, aut ullam delectus odio error sit rem. Architecto nulla doloribus laborum illo rem enim dolor odio saepe, consequatur quas?',
				sent: false,
			},
			{
				text: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magnam, repudiandae.',
				sent: true,
			},
			{
				text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis, reiciendis!',
				sent: true,
			},
			{
				text: 'happy holiday guys!',
				sent: false,
			},
		]);

		const newMessage = ref('');

		const sendMessage = () => {
			if (newMessage.value.trim() !== '') {
				messages.value.push({
					text: newMessage.value,
					sent: true,
				});
				newMessage.value = '';
			}
		};

		return {
			users,
			messages,
			newMessage,
			sendMessage,
		};
	},
});
</script>

<style lang="scss">
.slide-enter-active {
  transition: transform 0.3s;
}

.slide-leave-active {
  transition: transform 0.3s;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
}

ion-content {
  --padding-top: var(--offset-top, 0);
}
</style>
