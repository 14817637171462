<template>
	<ion-page>
		<ion-content :scroll-y="$route.fullPath==='/blog'">
				<main :style="{ paddingTop: headerHeight + 'px' }" class="h-full px-2 lg:px-0">
					<ion-router-outlet :key="$route.fullPath" class="relative mx-auto max-w-7xl px-4"></ion-router-outlet>
				</main>
			<FooterBar></FooterBar>
		</ion-content>
	</ion-page>
</template>
<script lang="ts">
import {IonPage, IonContent, IonRouterOutlet} from '@ionic/vue';
import {defineComponent} from 'vue';
import FooterBar from '@/components/positions/FooterBar.vue';
import {useHeaderHeight} from '@/composable/global/useHeaderHeight';

export default defineComponent({
	name: 'BlogPage',
	components: {
		IonRouterOutlet,
		IonPage,
		IonContent,
		FooterBar,
	},
	setup() {
		const {headerHeight} = useHeaderHeight();

		return {headerHeight};
	},
});

</script>
<style lang="scss">

.bg-gradient {
	background: linear-gradient(90deg, rgba(246, 0, 0, 1) 0%, rgba(255, 211, 0, 1) 34.31%, rgba(255, 118, 0, 1) 100%);
}
</style>

