<template>
	<ion-page>
		<ion-content>
			<main :style="{ paddingTop: headerHeight + 'px' }" >
		<loading-line-animation v-show="isLoading"></loading-line-animation>
			<main class="relative mx-auto grid lg:px-8 justify-center">

				<div class="mt-6 py-16">

					<div class="mt-6 py-16 sm:px-6 lg:row-start-1 lg:px-0">
						<loadingAnimation v-show="isLoading"></loadingAnimation>
						<div class="relative lg:min-h-full">
							<div class="rounded-md bg-red-50 p-4">
								<div class="flex">
									<div class="flex-shrink-0">
										<!--									<CheckCircleIcon class="h-5 w-5 text-green-400" aria-hidden="true"/>-->
									</div>
									<div class="ml-3">
										<p class="text-sm font-medium text-green-800">{{ $t('login.error') }}</p>
										<p class="text-sm font-medium text-green-800">{{ $t('itWillTakeAFewMinutesUntilYourProductIsReady') }}</p>
									</div>
								</div>
							</div>
							<div>
								<div class="mx-auto py-16 px-4 sm:px-6 sm:py-24 lg:px-8 lg:py-32">
									<default-button @click-button="redirectToCheckout"
													:button-text="'Zurück zum Checkout'"></default-button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
			<FooterBar class="mt-12"></FooterBar>
			</main>
		</ion-content>
	</ion-page>
</template>

<script lang="ts">
import {defineComponent, ref, onMounted, onBeforeMount} from 'vue';
import {IonPage, IonContent} from '@ionic/vue';
import FooterBar from "@/components/positions/FooterBar.vue";
import useSystem from "@/composable/core/useSystem";
import LoadingLineAnimation from "@/components/animations/LoadingLineAnimation.vue";
import DefaultButton from "@/components/core/DefaultButton.vue";
import router from "@/router";
import LoadingAnimation from "@/components/animations/LoadingAnimation.vue";
import {useRoute} from "vue-router";
import useAlert from "@/composable/core/useAlert";
import useTranslation from "@/composable/translation/useTranslation";
import {useHeaderHeight} from "@/composable/global/useHeaderHeight";

export default defineComponent({
	name: 'CheckoutCancelPage',
	components: {
		LoadingAnimation,
		DefaultButton,
		LoadingLineAnimation,
		FooterBar, IonContent, IonPage
	},
	setup() {
		const { headerHeight } = useHeaderHeight();
		const {scrollToTop} = useSystem();
		const {clearErrorAlert, addError} = useAlert();
		const isLoading = ref(true);
		const isOrderSuccessfully = ref(false);
		const isCancelPage = ref(false);
		const currentRoute = ref();
		const {t} = useTranslation();

		function redirectToCheckout() {
			router.push('/shop');
		}

		function initShopParams() {
			isCancelPage.value = false;
			currentRoute.value = useRoute();
			const currentRoutePath = currentRoute.value.path || null;
			const cancelRoute: boolean = currentRoutePath === '/shop/cancel';
			if (cancelRoute) {
				clearErrorAlert();

				const errorCancel = {
					id: "",
					title: t('shop.checkout.order_cancel.title'),
					message: t('shop.checkout.order_cancel.general_error'),
					active: true,
				};

				addError(errorCancel);
				isOrderSuccessfully.value = false;
				router.push('/shop');
			}
		}

		onBeforeMount(() => {
			initShopParams();
		});

		onMounted(() => {
			setTimeout(() => {
				isLoading.value = false;
			}, 5000)
			scrollToTop();
		})

		return {
			isOrderSuccessfully,
			isLoading,
			redirectToCheckout, headerHeight
		}
	}
});

</script>
<style lang="scss">
</style>
