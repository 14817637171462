import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  "aria-labelledby": "oneTimePurchase-heading",
  id: "GreeveOneTime"
}
const _hoisted_2 = { class: "rounded-t-xl space-y-6 py-6 px-4 sm:p-6" }
const _hoisted_3 = {
  id: "oneTimeHeading-heading",
  class: "text-lg font-medium leading-6 text-gray-900 dark:text-gray-400"
}
const _hoisted_4 = { class: "mt-1 text-sm text-gray-500" }
const _hoisted_5 = { class: "mt-2 flex items-center gap-x-4" }
const _hoisted_6 = { class: "text-gray-900 dark:text-gray-400 text-4xl font-bold tracking-tight" }
const _hoisted_7 = { class: "text-sm leading-5" }
const _hoisted_8 = { class: "text-gray-900 dark:text-gray-400" }
const _hoisted_9 = { class: "text-gray-500" }
const _hoisted_10 = { class: "relative mt-2" }
const _hoisted_11 = { class: "inline-flex w-full truncate" }
const _hoisted_12 = { class: "truncate" }
const _hoisted_13 = { class: "pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2" }
const _hoisted_14 = { class: "flex" }
const _hoisted_15 = { class: "rounded-b-xl py-3 text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChevronDownIcon = _resolveComponent("ChevronDownIcon")!
  const _component_ListboxButton = _resolveComponent("ListboxButton")!
  const _component_CheckIcon = _resolveComponent("CheckIcon")!
  const _component_ListboxOption = _resolveComponent("ListboxOption")!
  const _component_ListboxOptions = _resolveComponent("ListboxOptions")!
  const _component_Listbox = _resolveComponent("Listbox")!

  return (_ctx.oneTimeProductList)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(['sm:overflow-hidden rounded-xl nav backdrop-filter backdrop-blur-3xl', !_ctx.hideBottomPadding ? 'pb-28' : ''])
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", null, [
              _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t('oneTimePurchase')), 1),
              _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('youAlwaysCanPayMoreCreditsForYourPlan')), 1)
            ]),
            _createVNode(_component_Listbox, {
              class: "z-10 w-full",
              as: "div",
              modelValue: _ctx.selected,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event))
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.selected.price?.getFormattedAmount(false, false)), 1),
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('eur')), 1),
                    _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t('oneTimePurchase')), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_ListboxButton, { class: "relative w-full bg-gray-200 dark:bg-gr-dark cursor-default rounded-xl py-1.5 pl-3 pr-10 text-left text-gray-900 dark:text-gray-400 font-semibold shadow-sm ring-2 ring-inset ring-gray-200 dark:ring-gr-dark focus:outline-none focus:ring-2 sm:text-sm sm:leading-6" }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_11, [
                        _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.selected.getProductName()), 1)
                      ]),
                      _cache[2] || (_cache[2] = _createTextVNode()),
                      _createElementVNode("span", _hoisted_13, [
                        _createVNode(_component_ChevronDownIcon, {
                          class: "h-5 w-5 text-gray-400",
                          "aria-hidden": "true"
                        })
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ListboxOptions, { class: "relative z-10 mt-1.5 max-h-80 w-full overflow-auto rounded-xl bg-white dark:text-gray-400 dark:bg-gr-dark text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm" }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getOneTimeProducts(), (product) => {
                        return (_openBlock(), _createBlock(_component_ListboxOption, {
                          as: "template",
                          key: product.id,
                          value: product
                        }, {
                          default: _withCtx(({ active, selected }) => [
                            _createElementVNode("li", {
                              class: _normalizeClass([active ? 'dark:bg-gr-darker bg-gray-200' : 'dark:text-gray-400 text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9'])
                            }, [
                              _createElementVNode("div", _hoisted_14, [
                                _createElementVNode("span", {
                                  class: _normalizeClass([selected ? 'font-semibold' : 'font-normal', 'truncate'])
                                }, _toDisplayString(product?.getProductName()), 3),
                                _createElementVNode("span", {
                                  class: _normalizeClass([active ? 'dark:text-gray-400 text-gray-700' : 'text-gray-500', 'ml-2 truncate'])
                                }, "€ " + _toDisplayString(product?.price?.getFormattedAmount(false, false)), 3)
                              ]),
                              selected
                                ? (_openBlock(), _createElementBlock("span", {
                                    key: 0,
                                    class: _normalizeClass([active ? 'dark:text-gray-400 text-gray-900' : 'dark:text-gray-400 text-gray-900', 'absolute inset-y-0 right-0 flex items-center pr-4'])
                                  }, [
                                    _createVNode(_component_CheckIcon, {
                                      class: "h-5 w-5",
                                      "aria-hidden": "true"
                                    })
                                  ], 2))
                                : _createCommentVNode("", true)
                            ], 2)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("button", {
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.addSelectedOneTimeToCart && _ctx.addSelectedOneTimeToCart(...args))),
                "aria-describedby": "tier-scale",
                class: "inline-flex justify-center rounded-xl bg-gr-primary hover:bg-gr-primary2 py-1.5 px-10 text-sm font-semibold text-white shadow-sm focus:outline-none"
              }, _toDisplayString(_ctx.$t('buyNow')), 1)
            ])
          ])
        ], 2)
      ]))
    : _createCommentVNode("", true)
}