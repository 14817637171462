import {CountryFactory} from "@/greeve/core/country/country_factory.type";

export default function useCountryFactory() {

	let countryFactory: CountryFactory | null = null;
	const getCountryFactory = (): CountryFactory => {
		if (!countryFactory) {
			countryFactory = new CountryFactory();
		}
		return countryFactory;
	}

	return {
		getCountryFactory,
	}
}