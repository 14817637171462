<template>
	<div id="feature-notify-container">

		<div class="py-24">
			<div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
				<div class="relative isolate overflow-hidden bg-gray-900 px-6 py-16 md:py-24 shadow-2xl rounded-3xl sm:px-24 xl:py-32">
					<div id="feature-notify-alert-message" v-show="successMessage || errorMessage" class="pb-10">
						<div v-show="successMessage">
							<div class="rounded-xl bg-green-50 p-4">
								<div class="flex">
									<div class="flex-shrink-0">
										<CheckCircleIcon class="h-5 w-5 text-green-400" aria-hidden="true"/>
									</div>
									<div class="ml-3">
										<p class="text-sm font-medium text-green-800">{{ t('contact.FormSuccess') }}</p>
									</div>
									<div class="ml-auto pl-3 hidden">
										<div class="-mx-1.5 -my-1.5">
											<button type="button"
															class="inline-flex bg-green-50 rounded-xl p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600">
												<span class="sr-only">{{ t('contact.FormClose') }}</span>
												<XMarkIcon class="h-5 w-5" aria-hidden="true"/>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div v-show="errorMessage">
							<div class="rounded-md bg-red-50 p-4">
								<div class="flex">
									<div class="flex-shrink-0 hidden">
										<XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true"/>
									</div>
									<div class="ml-3">
										<h3 class="text-sm font-medium text-red-800">{{ errorMessage }}</h3>
									</div>
								</div>
							</div>
						</div>
					</div>

					<h2 class="mx-auto max-w-2xl text-center text-3xl font-bold tracking-tight text-white sm:text-4xl">{{ title }}</h2>
					<p class="mx-auto mt-2 max-w-xl text-center text-lg leading-8 text-gray-300">{{ description }}</p>
					<form class="mx-auto  grid gap-y-4 grid-cols-1 mt-10 md:flex max-w-md gap-x-4" @submit.prevent="sendNotifyForm">
						<label for="email-address" class="sr-only">$t('emailTs')</label>
						<input id="email-address" name="email" v-model="notifyFormData.email" type="email" autocomplete="email" required="" class="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6" :placeholder="$t('feature.notify.email_placeholder')" />
						<button type="submit" class="flex-none rounded-xl bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">{{ $t('feature.notify.notify_button') }}</button>
					</form>
					<svg viewBox="0 0 1024 1024" class="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2" aria-hidden="true">
						<circle cx="512" cy="512" r="512" fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fill-opacity="0.7" />
						<defs>
							<radialGradient id="759c1415-0410-454c-8f7c-9a820de03641" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(512 512) rotate(90) scale(512)">
								<stop stop-color="#7775D6" />
								<stop offset="1" stop-color="#E935C1" stop-opacity="0" />
							</radialGradient>
						</defs>
					</svg>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue';
import 'aos/dist/aos.css';
import useTranslation from '@/composable/translation/useTranslation';
import {CheckCircleIcon, XMarkIcon, XCircleIcon} from '@heroicons/vue/20/solid';
import useCustomStore from '@/composable/custom/useCustomStore';
import useContact from '@/composable/greeve/useContact';

export const enum FeatureTypes {
	WORKFLOW_FEATURE = 'feature_workflow',
}

export interface FEATURE_NOTIFY_FORM_INTERFACE {
	type: FeatureTypes;
	email: string;
	language: string;
}

export default defineComponent({
	name: 'NotifyFeatureComponent',
	components: {CheckCircleIcon, XMarkIcon, XCircleIcon},
	props: {
		featureType: {
			type: Object as () => FeatureTypes,
			default: FeatureTypes.WORKFLOW_FEATURE,
		},
		title: {
			type: String,
			default: '',
		},
		description: {
			type: String,
			default: '',
		},
		showEmailNotifyButton: {
			type: Boolean,
			default: true,
		},
	},
	methods: {},
	setup(props) {
		const {t} = useTranslation();
		const {getLanguageLocale} = useCustomStore();
		const {sentNotifyFeature} = useContact();

		const notifyFormData = ref<FEATURE_NOTIFY_FORM_INTERFACE>({
			'type': props.featureType,
			'email': '',
			'language': '',
		});
		const successMessage = ref('');
		const errorMessage = ref('');

		function scrollToAlertMessage() {
			const id = 'contact-container';
			const yOffset = -200;
			const element = document.getElementById(id);
			if (element) {
				const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
				window.scrollTo({top: y, behavior: 'smooth'});
			}
		}

		function resetNotifyFormData() {
			notifyFormData.value = {
				'type': props.featureType,
				'email': '',
				'language': '',
			};
		}

		function sendNotifyForm() {
			successMessage.value = '';
			errorMessage.value = '';
			notifyFormData.value.language = getLanguageLocale.value;
			sentNotifyFeature(notifyFormData.value).then(() => {
						successMessage.value = t("contact.FormSuccess");
						resetNotifyFormData();
						scrollToAlertMessage();
					},
					error => {
						console.log(error);
						errorMessage.value = t("contact.FormError");
						scrollToAlertMessage();
					});
		}

		return {t, notifyFormData, successMessage, errorMessage, sendNotifyForm};
	},
});

</script>
<style>
</style>
