<template>
	<ion-page>
		<ion-content>
			<section :style="{ paddingTop: headerHeight + 'px' }" class="h-full pb-1 px-2 lg:px-0">
				<div class="px-2 sm:px-4 lg:gap-x-5 flex-none lg:flex  h-full">
					<transition name="slide">
						<aside :class="asideClass">

							<transition name="slide">
								<aside :class="asideClass">
									<div class="nav rounded-2xl hidden lg:block backdrop-filter backdrop-blur-xl lg:sticky lg:top-[6.5rem]">
										<div class="nav pb-4 rounded-2xl h-auto ">

											<div class="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-4 nav rounded-2xl lg:block backdrop-filter backdrop-blur-xl lg:sticky lg:top-[6.5rem]">
												<div class="right-0 nav pb-4 rounded-2xl h-auto ">
<!--													<div class="flex flex-start h-16 shrink-0 items-center">-->
<!--														<img class="h-8 w-auto"  src="https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/images/logo/icon.png" alt="Foreground Image">-->
<!--													</div>-->
													<div class="px-0 p-2 right-0 sm:p-4 sm:px-0 pb-2 sm:pb-2 lg:pb-2 hidden lg:block text-right">
														<button @click="toggleMenu" class="px-2 py-2" data-tip="ausblenden"
																:class="[toggleButtonClass, groupMenuToggleState ? 'tooltip tooltip-right' : '' ]">
															<bars-3-icon v-if="groupMenuToggleState === false"
																		 class="text-gray-400 dark:text-gray-500 group-hover:text-gray-500 flex-shrink-0 h-6 w-6"/>
															<span v-else class="flex text-gray-400 dark:text-gray-500 group-hover:text-gray-500 ">
												<bars3-bottom-right-icon class="h-6 w-6"></bars3-bottom-right-icon>
											</span>
														</button>
													</div>
												</div>


												<Listbox as="div" v-model="selected">
													<div class="relative mb-12">
														<div class="inline-flex divide-x w-full rounded-xl text-white">

															<ListboxButton :class="'inline-flex flex-1 items-center rounded-xl px-2 py-1' + ' ' + selected.color">
																<img :src="selected.logo"  :class="[selected.current ? '' : '', 'h-4 w-4 shrink-0']" aria-hidden="true"  />
<!--																<component :is="selected.icon" :class="[selected.current ? '' : '', 'h-6 w-6 shrink-0']" aria-hidden="true" />-->
																<p :is-menu-open="groupMenuToggleState" class="text-xs font-semibold ml-2 block truncate">{{ selected.name }}</p>
																<ChevronDownIcon
																		class="group pointer-events-none h-6 w-6"
																		aria-hidden="true"
																/>
															</ListboxButton>
														</div>
														<transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
															<ListboxOptions class="relative z-10 mt-2 w-72 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-xl bg-white  ring-1 ring-black ring-opacity-5 focus:outline-none">
																<ListboxOption as="template" v-for="item in navigation" :key="item.name" :value="item" v-slot="{ active, selected }">

																	<!--															<group-list v-if="(loadingFinished && groupUuidByUrl.length > 0)  || hasEmptyUrl" :update-timestamp="updateTimeStamp" :group_uuid="groupUuidByUrl" :group_reference="groupReferenceByUrl"-->
																	<!--																		:is-menu-open="groupMenuToggleState" @group-selected="focusInputField"></group-list><group-list v-if="(loadingFinished && groupUuidByUrl.length > 0)  || hasEmptyUrl" :update-timestamp="updateTimeStamp" :group_uuid="groupUuidByUrl" :group_reference="groupReferenceByUrl"-->
																	<!--																																										:is-menu-open="groupMenuToggleState" @group-selected="focusInputField"></group-list>-->
																	<!--															-->
																	<li :class="[active ? 'bg-gr-primary text-white' : 'text-gray-900', 'cursor-default select-none p-3 px-4 text-sm']">
																		<div class="flex flex-col">
																			<div class="flex ">
																				<component :is="item.icon" :class="[item.current ? '' : '', 'h-6 w-6 shrink-0 mr-2']" aria-hidden="true" />
																				<p :is-menu-open="groupMenuToggleState" :class="selected ? 'font-semibold' : 'font-normal'">{{ item.name }}</p>
																			</div>
																			<p :class="[active ? 'text-indigo-200' : 'text-gray-500', 'mt-0']">{{ item.description }}</p>
																		</div>
																	</li>
																</ListboxOption>
															</ListboxOptions>
														</transition>
													</div>
												</Listbox>
												<nav class="flex flex-1 flex-col">
													<ul role="list" class="flex flex-1 flex-col gap-y-7">
														<li>
															<div class="text-xs font-semibold leading-6 text-gray-400">Actions</div>
<!--															<group-list v-if="(loadingFinished && groupUuidByUrl.length > 0)  || hasEmptyUrl" :update-timestamp="updateTimeStamp" :group_uuid="groupUuidByUrl" :group_reference="groupReferenceByUrl"-->
<!--																		:is-menu-open="groupMenuToggleState" @group-selected="focusInputField"></group-list><group-list v-if="(loadingFinished && groupUuidByUrl.length > 0)  || hasEmptyUrl" :update-timestamp="updateTimeStamp" :group_uuid="groupUuidByUrl" :group_reference="groupReferenceByUrl"-->
<!--																																										:is-menu-open="groupMenuToggleState" @group-selected="focusInputField"></group-list>-->
<!--															-->
															<ul role="list" class="-mx-2 mt-2 space-y-1">
																<li v-for="action in actions" :key="action.name">
																	<a :href="action.href" :class="[action.current ? 'bg-gray-50 text-gray-900' : 'text-gray-700 hover:text-gray-900 hover:bg-gray-50', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
																		<component :is="action.icon" :class="[action.current ? 'text-gray-900' : 'text-gray-400 group-hover:text-gray-900', 'h-6 w-6 shrink-0']" aria-hidden="true" />
																		<!--															<span :class="[action.current ? 'text-indigo-600 border-indigo-600' : 'text-gray-400 border-gray-200 group-hover:border-indigo-600 group-hover:text-indigo-600', 'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white']">{{ action.initial }}</span>-->
																		<span class="truncate">{{ action.name }}</span>
																	</a>
																</li>
															</ul>
														</li>
														<li>
															<div class="text-xs font-semibold leading-6 text-gray-400">Workflows</div>
															<ul role="list" class="-mx-2 mt-2 space-y-1">
																<!--															<group-list v-if="(loadingFinished && groupUuidByUrl.length > 0)  || hasEmptyUrl" :update-timestamp="updateTimeStamp" :group_uuid="groupUuidByUrl" :group_reference="groupReferenceByUrl"-->
																<!--																		:is-menu-open="groupMenuToggleState" @group-selected="focusInputField"></group-list><group-list v-if="(loadingFinished && groupUuidByUrl.length > 0)  || hasEmptyUrl" :update-timestamp="updateTimeStamp" :group_uuid="groupUuidByUrl" :group_reference="groupReferenceByUrl"-->
																<!--																																										:is-menu-open="groupMenuToggleState" @group-selected="focusInputField"></group-list>-->
																<!--															-->
																<li v-for="workflow in workflows" :key="workflow.name">
																	<a :href="workflow.href" :class="[workflow.current ? 'bg-gray-50 text-gray-900' : 'text-gray-700 hover:text-gray-900 hover:bg-gray-50', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
																		<component :is="workflow.icon" :class="[workflow.current ? 'text-gray-900' : 'text-gray-400 group-hover:text-gray-900', 'h-6 w-6 shrink-0']" aria-hidden="true" />
																		<!--															<span :class="[workflow.current ? 'text-indigo-600 border-indigo-600' : 'text-gray-400 border-gray-200 group-hover:border-indigo-600 group-hover:text-indigo-600', 'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white']">{{ workflow.initial }}</span>-->
																		<span class="truncate" :is-menu-open="groupMenuToggleState">{{ workflow.name }}</span>
																	</a>
																</li>
															</ul>
														</li>
														<!--											<li class="-mx-6 mt-auto">-->
														<!--												<a href="#" class="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50">-->
														<!--													<img class="h-8 w-8 rounded-full bg-gray-50" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />-->
														<!--													<span class="sr-only">Your profile</span>-->
														<!--													<span aria-hidden="true">Tom Cook</span>-->
														<!--												</a>-->
														<!--											</li>-->
													</ul>
												</nav>
											</div>

										</div>
									</div>
								</aside>
							</transition>

						</aside>
					</transition>
					<main class="space-y-6 px-0 w-full">
						<WizzardComponent v-if="currentWizzard" :wizzard="currentWizzard"></WizzardComponent>

						<ion-router-outlet :key="$route.fullPath" class="h-auto w-full absolute lg:relative"></ion-router-outlet>
					</main>
				</div>
			</section>
			<FooterBar v-show="false" class="mt-12"></FooterBar>
		</ion-content>
	</ion-page>
</template>

<script lang="ts">
import {IonPage, IonContent, IonRouterOutlet} from '@ionic/vue';
import {defineComponent, onMounted, ref, computed, Transition, watch} from 'vue';
import FooterBar from '@/components/positions/FooterBar.vue';
import {
	Bars3BottomRightIcon, Bars3Icon,
	CalendarIcon,
	ChartPieIcon,
	DocumentDuplicateIcon,
	FolderIcon,
	HomeIcon, ChatBubbleLeftIcon,
	UsersIcon, CheckIcon, ChevronDownIcon, ClockIcon
} from '@heroicons/vue/20/solid';
import useSystem from '@/composable/core/useSystem';
import useTranslation from '@/composable/translation/useTranslation';
import {useRoute} from 'vue-router';
import {useHeaderHeight} from '@/composable/global/useHeaderHeight';
import useCustomStore from '@/composable/custom/useCustomStore';
import GroupList from "@/components/assistant/Group/GroupList.vue";
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import {FlowSubType} from '@/greeve/flow/flow.interface';
import useFlowFactory from '@/composable/greeve/useFlowFactory';
import WizzardComponent from '@/components/wizzard/WizzardComponent.vue';

export default defineComponent({
	name: 'AssistantPageNew',
	components: {
		WizzardComponent,
		Listbox,
		ListboxButton,
		ListboxLabel,
		ListboxOption,
		ListboxOptions,
		CheckIcon,
		ChevronDownIcon,
		GroupList,
		IonRouterOutlet,
		Bars3Icon,
		Bars3BottomRightIcon,
		IonPage,
		IonContent,
		Transition,
		FooterBar,
		CalendarIcon,
		ChartPieIcon,
		HomeIcon, ChatBubbleLeftIcon,
		DocumentDuplicateIcon,
		FolderIcon,
		UsersIcon,
		ClockIcon
	},
	setup() {
		const {getFlowWizzardFactory} = useFlowFactory();
		const currentWizzard = ref();

		const navigation = [
			{ name: 'Whatsapp', href: '#', icon: CalendarIcon, color:'bg-[#25D366]', logo:'/assets/img/services/whatsapp.png', count: '5', current: true, description: 'This job posting can be viewed by anyone who has the link.' },
			{ name: 'Instagram', href: '#', icon: UsersIcon, color:'bg-[]', logo:'/assets/img/services/instagram.png', current: false, description: 'This job posting can be viewed by anyone who has the link.'  },
			{ name: 'Messenger', href: '#', icon: FolderIcon, color:'bg-[]', logo:'/assets/img/services/messenger.png', count: '12', current: false, description: 'This job posting can be viewed by anyone who has the link.'  },
			{ name: 'Telegram', href: '#', icon: HomeIcon, color:'bg-[]', logo:'/assets/img/services/telegram.png', count: '20+', current: false, description: 'This job posting can be viewed by anyone who has the link.'  },
		]

		const selected = ref(navigation[0])

		const actions = [
			{ id: 1, name: 'Calendar', href: '#', initial: 'H', current: false, icon: CalendarIcon, },
			{ id: 2, name: 'Abos', href: '#', initial: 'T', current: true, icon: UsersIcon, },
			{ id: 3, name: 'Analytics', href: '#', initial: 'W', current: false, icon: ChartPieIcon, },
			{ id: 4, name: 'History', href: '#', initial: 'W', current: false, icon: ClockIcon, },
		]
		const workflows = [
			{ id: 1, name: 'Workflow 1', href: '#', initial: 'H', current: false, icon: ChatBubbleLeftIcon, },
			{ id: 2, name: 'Workflow 2', href: '#', initial: 'T', current: false, icon: ChatBubbleLeftIcon, },
		]


		const {headerHeight} = useHeaderHeight();
		const {isFooterVisible, isAssistantGroupMenuOpen, setIsAssistantGroupMenuOpen,} = useCustomStore();
		const route = useRoute();
		const groupUuidByUrl = ref('');
		const groupReferenceByUrl = ref('');
		const loadingFinished = ref(false);
		const hasEmptyUrl = ref(false);
		const updateTimeStamp = ref<number|null>(null);
		const groupChanged = ref<number|undefined>();
		const groupMenuToggleState = ref(false);

		const {t} = useTranslation();

		const toggleMenu = async () => {
			groupMenuToggleState.value = !groupMenuToggleState.value;
			setTimeout(() => {
				setIsAssistantGroupMenuOpen(groupMenuToggleState.value);
			}, 10)
		};

		const {scrollToTop} = useSystem();

		function focusInputField() {
			groupChanged.value = new Date().getTime();
		}

		watch(route, (route) => {
			if (route.path.includes('/assistant')) {
				loadingFinished.value = false;
				// selectGroupByRoute(route);
			}
		});

		onMounted(() => {
			currentWizzard.value = getFlowWizzardFactory().createWizzardByType(FlowSubType.WHATSAPP_DEFAULT_NEWSLETTER);
			groupMenuToggleState.value = isAssistantGroupMenuOpen.value;
			scrollToTop();
		});

		const asideClass = computed(() => (groupMenuToggleState.value
				? 'z-50 pb-0 lg:pb-4 lg:col-span-auto lg:sticky lg:top-0'
				: 'z-50 pb-0 lg:pb-4 lg:col-span-auto lg:sticky lg:top-0'));

		const toggleButtonClass = computed(() => (groupMenuToggleState.value ? '' : ''));

		const menuClass = computed(() => (groupMenuToggleState.value ? 'menu-open' : 'menu-close'));

		return {
			selected,
			navigation,
			actions,
			workflows,
			t,
			toggleMenu,
			asideClass,
			toggleButtonClass,
			menuClass,
			headerHeight,
			groupUuidByUrl,
			groupReferenceByUrl,
			loadingFinished,
			hasEmptyUrl,
			updateTimeStamp,
			isFooterVisible,
			focusInputField,
			groupChanged,
			groupMenuToggleState,
			currentWizzard
		};
	},
});
</script>

<style lang="scss" >
.slide-enter-active {
	transition: transform 0.3s;
}

.slide-leave-active {
	transition: transform 0.3s;
}

.slide-enter,
.slide-leave-to {
	transform: translateX(-100%);
}

ion-content {
  --padding-top: var(--offset-top, 0);
}

</style>
