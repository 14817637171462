import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "p-1 sm:p-3 mx-2 lg:mx-4 mb-2 sm:mb-1 flex gap-3 items-center"
}
const _hoisted_2 = { class: "inline-flex h-6" }
const _hoisted_3 = { class: "pr-2" }
const _hoisted_4 = { class: "flex ml-1" }
const _hoisted_5 = {
  key: 1,
  class: "p-1 sm:p-3 mx-2 lg:mx-4 mb-2 sm:mb-1 flex gap-3 items-center"
}
const _hoisted_6 = { class: "flex" }
const _hoisted_7 = { class: "self-center mr-2" }
const _hoisted_8 = { class: "self-center h-6 w-6 ml-2 cursor-pointer" }
const _hoisted_9 = { class: "mx-2 lg:mx-4 mb-2 sm:mb-1 flex gap-3" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "hidden lg:block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Switch = _resolveComponent("Switch")!
  const _component_ListSelectDropdown = _resolveComponent("ListSelectDropdown")!
  const _component_PlayIcon = _resolveComponent("PlayIcon")!
  const _component_StopIcon = _resolveComponent("StopIcon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.getCurrentActiveItem().type === 'image')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('hd')), 1),
            _createVNode(_component_Switch, {
              modelValue: _ctx.hd_image,
              "onUpdate:modelValue": [
                _cache[0] || (_cache[0] = ($event: any) => ((_ctx.hd_image) = $event)),
                _ctx.updateImage
              ],
              class: _normalizeClass([_ctx.hd_image ? 'bg-gr-primary' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-2 focus:ring-offset-2'])
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", {
                  class: _normalizeClass([_ctx.hd_image ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out mt-[2px]'])
                }, [
                  _createElementVNode("span", {
                    class: _normalizeClass([_ctx.hd_image ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']),
                    "aria-hidden": "true"
                  }, _cache[1] || (_cache[1] = [
                    _createElementVNode("svg", {
                      class: "h-3 w-3 text-gray-400",
                      fill: "none",
                      viewBox: "0 0 12 12"
                    }, [
                      _createElementVNode("path", {
                        d: "M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2",
                        stroke: "currentColor",
                        "stroke-width": "2",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round"
                      })
                    ], -1)
                  ]), 2),
                  _createElementVNode("span", {
                    class: _normalizeClass([_ctx.hd_image ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']),
                    "aria-hidden": "true"
                  }, _cache[2] || (_cache[2] = [
                    _createElementVNode("svg", {
                      class: "h-3 w-3 text-gr-primary",
                      fill: "currentColor",
                      viewBox: "0 0 12 12"
                    }, [
                      _createElementVNode("path", { d: "M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" })
                    ], -1)
                  ]), 2)
                ], 2)
              ]),
              _: 1
            }, 8, ["modelValue", "onUpdate:modelValue", "class"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_ListSelectDropdown, {
              list: _ctx.getImageSizes(),
              "show-above": true,
              "min-width": "min-w-32",
              model: _ctx.imageSize,
              "top-margin": false,
              onChangeSelectedValue: _ctx.updateImageSize,
              "default-value": _ctx.getImageSizes()[2]
            }, null, 8, ["list", "model", "onChangeSelectedValue", "default-value"])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.getCurrentActiveItem().type === 'text_to_speech')
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('voices')), 1),
            _createVNode(_component_ListSelectDropdown, {
              list: _ctx.getAudioVoices(),
              "show-above": true,
              "min-width": "min-w-32",
              model: _ctx.selectedAudio,
              "top-margin": false,
              onChangeSelectedValue: _ctx.updateAudioVoice,
              "default-value": _ctx.getAudioVoices()[0]
            }, null, 8, ["list", "model", "onChangeSelectedValue", "default-value"]),
            _createElementVNode("div", _hoisted_8, [
              (!_ctx.isAudioVoiceDemoPlaying)
                ? (_openBlock(), _createBlock(_component_PlayIcon, {
                    key: 0,
                    onClick: _ctx.playVoiceDemo
                  }, null, 8, ["onClick"]))
                : (_openBlock(), _createBlock(_component_StopIcon, {
                    key: 1,
                    onClick: _ctx.pauseVoiceDemo
                  }, null, 8, ["onClick"]))
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_9, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.outputMethode, (item) => {
        return (_openBlock(), _createElementBlock("ul", {
          role: "list",
          key: item.name
        }, [
          _createElementVNode("li", {
            onClick: ($event: any) => (_ctx.selectType(item)),
            class: _normalizeClass(["cursor-pointer px-4 flex py-1 w-auto items-center rounded-2xl", [!item.active ? 'bg-gray-100 dark:bg-gr-darkest text-gray-400 dark:text-gr-darker' : 'bg-gray-200 dark:bg-gr-darker text-gray-700 dark:text-gray-400', _ctx.isDisabled ? ' disabled:bg-slate-50' : '']])
          }, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), {
              class: "h-5 w-5 lg:mr-2",
              "aria-hidden": "true"
            })),
            _createElementVNode("p", _hoisted_11, _toDisplayString(item.name), 1)
          ], 10, _hoisted_10)
        ]))
      }), 128))
    ])
  ], 64))
}