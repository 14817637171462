import {
	SearchItemImage,
} from '@/greeve/search/item/type/subtype/search_item_image.type';

export class SearchItemImageVariation extends SearchItemImage {
	public getContent() {
		return this.response;
	}

	public hasCustomOutput(): boolean {
		return true;
	}

	public getOriginalImage(): string | undefined {
		return this.getRequest()?.originalImage;
	}
}
