import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "h-full pb-1 px-2 lg:px-4" }
const _hoisted_2 = { class: "px-2 sm:px-4 lg:gap-x-5 flex-none nav rounded-2xl backdrop-filter backdrop-blur-xl sticky top-[6.5rem]" }

import {computed, defineComponent, onMounted, ref} from 'vue';
import {IonContent, IonPage, IonRouterOutlet} from '@ionic/vue';
import {Bars3BottomRightIcon, Bars3Icon} from '@heroicons/vue/20/solid';
import WizzardComponent from '@/components/wizzard/WizzardComponent.vue';
import NotifyFeatureComponent from '@/components/contact/NotifyFeatureComponent.vue';
import FooterBar from '@/components/positions/FooterBar.vue';
import useFlowFactory from '@/composable/greeve/useFlowFactory';
import {FlowSubType} from '@/greeve/flow/flow.interface';

		
export default /*@__PURE__*/_defineComponent({
  __name: 'FlowNewPage',
  setup(__props) {

const {getFlowWizzardFactory} = useFlowFactory();
		const isMenuOpen = ref(false);
		const asideClass = computed(() => (isMenuOpen.value
				? 'z-50 pb-0 lg:pb-4 lg:col-span-auto lg:sticky lg:top-0'
				: 'sticky top-8 hidden w-44 shrink-0 lg:block'));
		// : 'z-50 pb-0 lg:pb-4 lg:col-span-auto lg:sticky lg:top-0'));
		const toggleButtonClass = computed(() => (isMenuOpen.value ? '' : ''));
		const menuClass = computed(() => (isMenuOpen.value ? 'menu-open' : 'menu-close'));

		const currentWizzard = ref();

		const toggleMenu = () => {
			isMenuOpen.value = !isMenuOpen.value;
		};

		onMounted(() => {
			currentWizzard.value = getFlowWizzardFactory().createWizzardByType(FlowSubType.WHATSAPP_DEFAULT_NEWSLETTER);
		})
		//TODO if wizzard selected by url load wizzard or show infos and history
		//TODO getAllWizzardTemplates on + Click
		//TODO show my Templates

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonContent), null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(NotifyFeatureComponent, {
                "feature-type": "feature_workflow",
                title: _ctx.$t('feature.workflow.title'),
                description: _ctx.$t('feature.workflow.description')
              }, null, 8, ["title", "description"])
            ])
          ]),
          _createVNode(FooterBar, { class: "mt-12" })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})