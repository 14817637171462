import {OrderItemList} from "@/greeve/shop/order/orderItem/order_item_list.type";
import {OrderItem} from "@/greeve/shop/order/orderItem/order_item.type";

export class OrderItemFactory {

	/* eslint-disable */

	/**
	 *
	 * @param orderItems
	 */
	createOrderItemListListByResponse(orderItems: Record<string, any>): OrderItemList {
		const orderItemList = new OrderItemList();

		for (const [key, item] of Object.entries(orderItems)) {
			orderItemList.push(this.createOrderItemByResponse(item));
		}

		return orderItemList;
	}

	/**
	 *
	 * @param item
	 */
	createOrderItemByResponse(item: Record<string, any>): OrderItem {
		const id = item.id;
		const type = item.type;
		const state = item.state;
		const order_id = item.order_id;
		const quantity = item.quantity;
		const grand_total = item.grand_total;
		const net_amount = item.net_amount;
		const tax_amount = item.tax_amount;
		const total_amount = item.total_amount;
		const reference = item.reference;
		const external_reference = item.external_reference;
		const parent_id = item.parent_id ? item.parent_id : (item.parentId ? item.parentId : undefined) ?? undefined;
		const data = item.data;
		const created_at = item.created_at ? new Date(item.created_at) : (item.createdAt ? new Date(item.createdAt) : new Date());
		const updated_at = item.updated_at ? new Date(item.updated_at) : (item.updatedAt ? new Date(item.updatedAt) : new Date());

		return new OrderItem(id, type, state, order_id, quantity, grand_total, net_amount, tax_amount, total_amount, reference, external_reference, parent_id, data, created_at, updated_at);
	}
}