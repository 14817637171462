import {MediaFileInfo} from '@/greeve/media/media_file_info.type';

export class MediaFactory {
	/**
	 *
	 * @param item
	 */
	createMediaFileInfoByResponse(item: Record<string, any>): MediaFileInfo {
		const filePath = item.filePath ? item.filePath : (item.file_path ? item.file_path : undefined);
		const externalFileUrl = item.externalFileUrl ? item.externalFileUrl : (item.external_file_url ? item.external_file_url : undefined);

		return new MediaFileInfo(filePath, externalFileUrl);
	}
}