import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "container mx-auto" }
const _hoisted_2 = { class: "w-2/4" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "ml-2 w-full leading-[0.01em]" }
const _hoisted_5 = { class: "text-xs font-semibold line-clamp-1" }
const _hoisted_6 = { class: "text-xs text-gray-500 line-clamp-1" }
const _hoisted_7 = { class: "flex flex-col justify-between h-full p-4" }
const _hoisted_8 = { class: "flex flex-col mt-5 overflow-y-auto h-96" }
const _hoisted_9 = {
  key: 0,
  src: "https://source.unsplash.com/vpOeXr5wmR4/600x600",
  class: "object-cover h-8 w-8 rounded-full",
  alt: ""
}
const _hoisted_10 = {
  key: 1,
  src: "https://source.unsplash.com/vpOeXr5wmR4/600x600",
  class: "object-cover h-8 w-8 rounded-full",
  alt: ""
}
const _hoisted_11 = { class: "py-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChatPage = _resolveComponent("ChatPage")!
  const _component_ThreeColumnLayout = _resolveComponent("ThreeColumnLayout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ThreeColumnLayout, {
      "col-rounded": "rounded-xl",
      "first-col-bg": "bg-white",
      "middle-col-bg": "bg-white",
      "last-col-bg": "bg-white",
      isFirstCollapsed: true,
      isLastCollapsed: true,
      "first-width": "2/4",
      "last-width": "1/3"
    }, {
      "first-title": _withCtx(() => _cache[2] || (_cache[2] = [
        _createElementVNode("div", { class: "py-4 px-2" }, [
          _createElementVNode("input", {
            type: "text",
            placeholder: "search chatting",
            class: "py-2 px-2 border-2 border-gray-200 rounded-2xl w-full"
          })
        ], -1)
      ])),
      "first-content": _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.users, (user) => {
          return (_openBlock(), _createElementBlock("div", {
            key: user.id,
            class: _normalizeClass(['flex flex-row py-2 px-2 items-center border-b-[1px]', user.active ? 'border-l-4 border-l-[#25D366]' : ''])
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("img", {
                src: user.image,
                class: "object-cover h-7 w-7 rounded-full",
                alt: ""
              }, null, 8, _hoisted_3)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(user.name), 1),
              _createElementVNode("span", _hoisted_6, _toDisplayString(user.message), 1)
            ])
          ], 2))
        }), 128))
      ]),
      "middle-title": _withCtx(() => _cache[3] || (_cache[3] = [])),
      "middle-content": _withCtx(() => [
        _createVNode(_component_ChatPage),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (message, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: _normalizeClass(['flex mb-4', message.sent ? 'justify-end' : 'justify-start'])
              }, [
                (!message.sent)
                  ? (_openBlock(), _createElementBlock("img", _hoisted_9))
                  : _createCommentVNode("", true),
                _createElementVNode("div", {
                  class: _normalizeClass([
                  'py-3 px-4 text-white',
                  message.sent ? 'mr-2 bg-gray-300 rounded-bl-xl rounded-tl-xl rounded-br-xl' : 'ml-2 bg-gray-200 rounded-bl-xl rounded-br-xl rounded-tr-xl'
                ])
                }, _toDisplayString(message.text), 3),
                (message.sent)
                  ? (_openBlock(), _createElementBlock("img", _hoisted_10))
                  : _createCommentVNode("", true)
              ], 2))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_11, [
            _withDirectives(_createElementVNode("input", {
              class: "w-full bg-gray-300 py-5 px-3 rounded-xl",
              type: "text",
              placeholder: "type your message here...",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newMessage) = $event)),
              onKeyup: _cache[1] || (_cache[1] = _withKeys(
//@ts-ignore
(...args) => (_ctx.sendMessage && _ctx.sendMessage(...args)), ["enter"]))
            }, null, 544), [
              [_vModelText, _ctx.newMessage]
            ])
          ])
        ])
      ]),
      "last-title": _withCtx(() => _cache[4] || (_cache[4] = [])),
      "last-content": _withCtx(() => _cache[5] || (_cache[5] = [
        _createElementVNode("div", { class: "flex flex-col" }, [
          _createElementVNode("div", { class: "font-semibold text-xl py-4" }, "Mern Stack Group"),
          _createElementVNode("img", {
            src: "https://source.unsplash.com/L2cxSuKWbpo/600x600",
            class: "object-cover rounded-xl h-64",
            alt: ""
          }),
          _createElementVNode("div", { class: "font-semibold py-4" }, "Created 22 Sep 2021"),
          _createElementVNode("div", { class: "font-light" }, " Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt, perspiciatis! ")
        ], -1)
      ])),
      _: 1
    })
  ]))
}