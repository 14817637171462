export class MediaFileInfo {
	filePath: string;
	externalFileUrl: string;

	constructor(filePath: string, externalFileUrl: string) {
		this.filePath = filePath;
		this.externalFileUrl = externalFileUrl;
	}

	public toJson(): string {
		return JSON.stringify(this);
	}
}
