import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "space-y-2 sm:space-y-4" }
const _hoisted_2 = { "aria-labelledby": "payment-billing-portal-heading" }
const _hoisted_3 = { class: "sm:overflow-hidden rounded-xl nav backdrop-filter backdrop-blur-3xl" }
const _hoisted_4 = { class: "nav rounded-t-xl py-6 px-4 sm:p-6" }
const _hoisted_5 = {
  id: "payment-details-heading",
  class: "text-lg font-medium leading-6 text-gray-900 dark:text-gray-500"
}
const _hoisted_6 = { class: "mt-1 text-sm text-gray-600" }
const _hoisted_7 = { class: "nav rounded-b-xl px-4 py-3 text-right sm:px-6" }
const _hoisted_8 = { "aria-labelledby": "payment-details-heading" }
const _hoisted_9 = { class: "sm:overflow-hidden rounded-xl nav backdrop-filter backdrop-blur-3xl" }
const _hoisted_10 = { class: "nav rounded-t-xl py-6 px-4 sm:p-6" }
const _hoisted_11 = {
  id: "payment-details-heading",
  class: "text-lg font-medium leading-6 text-gray-900 dark:text-gray-500"
}
const _hoisted_12 = { class: "mt-1 text-sm text-gray-600" }
const _hoisted_13 = {
  key: 0,
  class: "mt-6 grid grid-cols-4 gap-6"
}
const _hoisted_14 = { class: "col-span-4 sm:col-span-2" }
const _hoisted_15 = {
  for: "first-name",
  class: "block text-sm font-medium leading-6 text-gray-900 dark:text-gray-600"
}
const _hoisted_16 = { class: "col-span-4 sm:col-span-2" }
const _hoisted_17 = {
  for: "last-name",
  class: "block text-sm font-medium leading-6 text-gray-900 dark:text-gray-600"
}
const _hoisted_18 = { class: "col-span-4 sm:col-span-2" }
const _hoisted_19 = {
  for: "Street",
  class: "block text-sm font-medium leading-6 text-gray-900 dark:text-gray-600"
}
const _hoisted_20 = { class: "col-span-4 sm:col-span-2" }
const _hoisted_21 = {
  for: "town",
  class: "block text-sm font-medium leading-6 text-gray-900 dark:text-gray-600"
}
const _hoisted_22 = { class: "col-span-4 sm:col-span-2" }
const _hoisted_23 = { class: "block text-sm font-medium leading-6 text-gray-900 dark:text-gray-600" }
const _hoisted_24 = ["value"]
const _hoisted_25 = { class: "col-span-4 sm:col-span-2" }
const _hoisted_26 = {
  for: "postal-code",
  class: "block text-sm font-medium leading-6 text-gray-900 dark:text-gray-600"
}
const _hoisted_27 = {
  key: 1,
  class: "bg-gray-100 dark:bg-gr-dark rounded-2xl p-4 mt-4 w-full mx-auto"
}
const _hoisted_28 = { class: "nav rounded-b-xl px-4 py-3 text-right sm:px-6" }
const _hoisted_29 = {
  type: "submit",
  class: "inline-flex justify-center rounded-xl bg-black dark:bg-gr-darker py-1.5 px-10 text-sm font-semibold text-white shadow-sm focus:outline-none"
}
const _hoisted_30 = { "aria-labelledby": "billing-history-heading" }
const _hoisted_31 = { class: "nav backdrop-filter backdrop-blur-3xl pt-6 sm:overflow-hidden rounded-xl" }
const _hoisted_32 = { class: "px-4 sm:px-6" }
const _hoisted_33 = {
  id: "billing-history-heading",
  class: "text-lg font-medium leading-6 text-gray-900 dark:text-gray-500"
}
const _hoisted_34 = { class: "mt-1 text-sm text-gray-500" }
const _hoisted_35 = { class: "mt-6 flex flex-col" }
const _hoisted_36 = { class: "-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8" }
const _hoisted_37 = { class: "inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8" }
const _hoisted_38 = { class: "m-6 mt-0 rounded-xl overflow-hidden border-2 border-gray-200 dark:border-gr-darker" }
const _hoisted_39 = { class: "min-w-full divide-y divide-gray-200 dark:divide-gr-dark" }
const _hoisted_40 = { class: "bg-gray-50 dark:bg-gr-dark" }
const _hoisted_41 = {
  scope: "col",
  class: "px-6 py-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-400"
}
const _hoisted_42 = {
  scope: "col",
  class: "px-6 py-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-400"
}
const _hoisted_43 = {
  scope: "col",
  class: "px-6 py-3 text-left text-sm font-semibold text-gray-900 dark:text-gray-400"
}
const _hoisted_44 = {
  scope: "col",
  class: "relative px-6 py-3 text-left text-sm font-medium text-gray-500"
}
const _hoisted_45 = { class: "sr-only" }
const _hoisted_46 = {
  key: 0,
  class: "divide-y divide-gray-200 dark:divide-gr-dark bg-white dark:bg-gr-darker"
}
const _hoisted_47 = { class: "whitespace-nowrap px-6 py-4 text-sm text-gray-500 dark:text-gray-400" }
const _hoisted_48 = ["datetime"]
const _hoisted_49 = { class: "whitespace-nowrap px-6 py-4 text-sm text-gray-500 dark:text-gray-400" }
const _hoisted_50 = { class: "whitespace-nowrap px-6 py-4 text-sm text-gray-500 dark:text-gray-400" }
const _hoisted_51 = { class: "whitespace-nowrap px-6 py-4 text-right text-sm font-medium" }
const _hoisted_52 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StripeBillingPortalButton = _resolveComponent("StripeBillingPortalButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, [
            _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.$t('manage_billing.title')), 1),
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('manage_billing.description')), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_StripeBillingPortalButton)
        ])
      ])
    ]),
    _createElementVNode("section", _hoisted_8, [
      _createElementVNode("form", {
        onSubmit: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.updateBillingAddress && _ctx.updateBillingAddress(...args)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", null, [
              _createElementVNode("h2", _hoisted_11, _toDisplayString(_ctx.$t('paymentDetails')), 1),
              _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t('updateYourBillingInformationPleaseNoteThatUpdating')), 1)
            ]),
            (_ctx.billingAddress)
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("label", _hoisted_15, _toDisplayString(_ctx.$t('firstName')), 1),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      name: "first-name",
                      id: "first-name",
                      autocomplete: "cc-given-name",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.billingAddress.first_name) = $event)),
                      class: "mt-2 block w-full rounded-xl border-2 border-gray-200 dark:border-gr-darker px-3 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 sm:text-sm sm:leading-6"
                    }, null, 512), [
                      [_vModelText, _ctx.billingAddress.first_name]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t('lastName')), 1),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      name: "last-name",
                      id: "last-name",
                      autocomplete: "cc-family-name",
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.billingAddress.last_name) = $event)),
                      class: "mt-2 block w-full rounded-xl border-2 border-gray-200 dark:border-gr-darker py-1.5 px-3 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 sm:text-sm sm:leading-6"
                    }, null, 512), [
                      [_vModelText, _ctx.billingAddress.last_name]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.$t('auth.SetUpAccountInformationComponent.inputStreet')), 1),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      name: "last-name",
                      id: "street",
                      autocomplete: "cc-family-name",
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.billingAddress.street) = $event)),
                      class: "mt-2 block w-full rounded-xl border-2 border-gray-200 dark:border-gr-darker py-1.5 px-3 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 sm:text-sm sm:leading-6"
                    }, null, 512), [
                      [_vModelText, _ctx.billingAddress.street]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("label", _hoisted_21, _toDisplayString(_ctx.$t('city')), 1),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      name: "last-name",
                      id: "town",
                      autocomplete: "cc-family-name",
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.billingAddress.city) = $event)),
                      class: "mt-2 block w-full rounded-xl border-2 border-gray-200 dark:border-gr-darker py-1.5 px-3 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 sm:text-sm sm:leading-6"
                    }, null, 512), [
                      [_vModelText, _ctx.billingAddress.city]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_22, [
                    _createElementVNode("label", _hoisted_23, _toDisplayString(_ctx.$t('auth.SetUpAccountInformationComponent.inputCountry')), 1),
                    _withDirectives(_createElementVNode("select", {
                      id: "country",
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.billingAddress.country_code) = $event)),
                      class: "mt-2 block w-full rounded-xl border-2 border-gray-200 dark:border-gr-darker py-1.5 px-3 text-gray-900 shadow-sm focus:ring-2 focus:ring-inset focus:ring-gray-900 sm:text-sm sm:leading-6"
                    }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countries, (country) => {
                        return (_openBlock(), _createElementBlock("option", {
                          key: country.code,
                          value: country.code
                        }, _toDisplayString(country.native), 9, _hoisted_24))
                      }), 128))
                    ], 512), [
                      [_vModelSelect, _ctx.billingAddress.country_code]
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_25, [
                    _createElementVNode("label", _hoisted_26, _toDisplayString(_ctx.$t('zipPostalCode')), 1),
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      name: "postal-code",
                      id: "postal-code",
                      autocomplete: "postal-code",
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.billingAddress.postal_code) = $event)),
                      class: "mt-2 block w-full rounded-xl border-2 border-gray-200 dark:border-gr-darker py-1.5 px-3 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 sm:text-sm sm:leading-6"
                    }, null, 512), [
                      [_vModelText, _ctx.billingAddress.postal_code]
                    ])
                  ])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_27, _cache[7] || (_cache[7] = [
                  _createStaticVNode("<div class=\"animate-pulse flex space-x-4\"><div class=\"rounded-full bg-gray-200 dark:bg-gr-darker h-10 w-10\"></div><div class=\"flex-1 space-y-6 py-1\"><div class=\"h-2 bg-gray-200 dark:bg-gr-darker rounded\"></div><div class=\"space-y-3\"><div class=\"grid grid-cols-3 gap-4\"><div class=\"h-2 bg-gray-200 dark:bg-gr-darker rounded col-span-2\"></div><div class=\"h-2 bg-gray-200 dark:bg-gr-darker rounded col-span-1\"></div></div><div class=\"h-2 bg-gray-200 dark:bg-gr-darker rounded\"></div></div></div></div>", 1)
                ])))
          ]),
          _createElementVNode("div", _hoisted_28, [
            _createElementVNode("button", _hoisted_29, _toDisplayString(_ctx.$t('save')), 1)
          ])
        ])
      ], 32)
    ]),
    _createElementVNode("section", _hoisted_30, [
      _createElementVNode("div", _hoisted_31, [
        _createElementVNode("div", _hoisted_32, [
          _createElementVNode("h2", _hoisted_33, _toDisplayString(_ctx.$t('billingHistory')), 1),
          _createElementVNode("p", _hoisted_34, _toDisplayString(_ctx.$t('downloadYourBillingsPleaseNoteThatUpdatingYourLoca')), 1)
        ]),
        _createElementVNode("div", _hoisted_35, [
          _createElementVNode("div", _hoisted_36, [
            _createElementVNode("div", _hoisted_37, [
              _createElementVNode("div", _hoisted_38, [
                _createElementVNode("table", _hoisted_39, [
                  _createElementVNode("thead", _hoisted_40, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("th", _hoisted_41, _toDisplayString(_ctx.$t('date')), 1),
                      _createElementVNode("th", _hoisted_42, _toDisplayString(_ctx.$t('description')), 1),
                      _createElementVNode("th", _hoisted_43, _toDisplayString(_ctx.$t('amount')), 1),
                      _createElementVNode("th", _hoisted_44, [
                        _createElementVNode("span", _hoisted_45, _toDisplayString(_ctx.$t('viewReceipt')), 1)
                      ])
                    ])
                  ]),
                  (_ctx.orders.hasJournalEntries())
                    ? (_openBlock(), _createElementBlock("tbody", _hoisted_46, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orders.getJournalList(), (journal) => {
                          return (_openBlock(), _createElementBlock("tr", {
                            key: journal.id
                          }, [
                            _createElementVNode("td", _hoisted_47, [
                              _createElementVNode("time", {
                                datetime: journal.created_at
                              }, _toDisplayString(journal.created_at.toLocaleDateString()), 9, _hoisted_48)
                            ]),
                            _createElementVNode("td", _hoisted_49, _toDisplayString(journal.getOrder().getProductDescriptionSummary()), 1),
                            _createElementVNode("td", _hoisted_50, _toDisplayString(journal.getOrder().total_amount), 1),
                            _createElementVNode("td", _hoisted_51, [
                              _createElementVNode("a", {
                                href: journal.getInvoiceLink(),
                                class: "text-gr-primary hover:text-gr-primary"
                              }, "View receipt", 8, _hoisted_52)
                            ])
                          ]))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}