import {SearchGroup} from '@/greeve/search/group/search_group.type';

export class SearchItemAuthorization {
	uuid: string;
	reference: string;
	search_group?: SearchGroup;
	search_item_group_id?: number;
	search_item_group_uuid?: string;
	prompt?: string;

	constructor(uuid: string, reference: string, search_item_group_id?: number, search_item_group_uuid?: string, prompt?: string, search_group?: SearchGroup) {
		this.uuid = uuid;
		this.reference = reference;
		this.search_item_group_id = search_item_group_id;
		this.search_item_group_uuid = search_item_group_uuid;
		this.prompt = prompt;
		this.search_group = search_group;
	}
}