import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "px-2 sm:px-4 lg:gap-x-5 flex-none lg:flex relative h-full" }
const _hoisted_2 = { class: "nav rounded-2xl hidden lg:block backdrop-filter backdrop-blur-xl lg:sticky lg:top-[6.5rem]" }
const _hoisted_3 = { class: "nav pb-4 rounded-2xl h-auto" }
const _hoisted_4 = { class: "p-2 sm:p-4 pb-2 sm:pb-2 lg:pb-2 hidden lg:block text-right" }
const _hoisted_5 = {
  key: 1,
  class: "flex text-gray-400 dark:text-gray-500 group-hover:text-gray-500"
}
const _hoisted_6 = { class: "space-y-6 px-0 w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bars_3_icon = _resolveComponent("bars-3-icon")!
  const _component_bars3_bottom_right_icon = _resolveComponent("bars3-bottom-right-icon")!
  const _component_group_list = _resolveComponent("group-list")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_FooterBar = _resolveComponent("FooterBar")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("section", {
            style: _normalizeStyle({ paddingTop: _ctx.headerHeight + 'px' }),
            class: "h-full pb-1 px-2 lg:px-0"
          }, [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_Transition, { name: "slide" }, {
                default: _withCtx(() => [
                  _createElementVNode("aside", {
                    class: _normalizeClass(_ctx.asideClass)
                  }, [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, [
                          _createElementVNode("button", {
                            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleMenu && _ctx.toggleMenu(...args))),
                            class: _normalizeClass(["px-2 py-2", [_ctx.toggleButtonClass, _ctx.groupMenuToggleState ? 'tooltip tooltip-right' : '' ]]),
                            "data-tip": "ausblenden"
                          }, [
                            (_ctx.groupMenuToggleState === false)
                              ? (_openBlock(), _createBlock(_component_bars_3_icon, {
                                  key: 0,
                                  class: "text-gray-400 dark:text-gray-500 group-hover:text-gray-500 flex-shrink-0 h-6 w-6"
                                }))
                              : (_openBlock(), _createElementBlock("span", _hoisted_5, [
                                  _createVNode(_component_bars3_bottom_right_icon, { class: "h-6 w-6" })
                                ]))
                          ], 2)
                        ]),
                        ((_ctx.loadingFinished && _ctx.groupUuidByUrl.length > 0)  || _ctx.hasEmptyUrl)
                          ? (_openBlock(), _createBlock(_component_group_list, {
                              key: 0,
                              "update-timestamp": _ctx.updateTimeStamp,
                              group_uuid: _ctx.groupUuidByUrl,
                              group_reference: _ctx.groupReferenceByUrl,
                              "is-menu-open": _ctx.groupMenuToggleState,
                              onGroupSelected: _ctx.focusInputField
                            }, null, 8, ["update-timestamp", "group_uuid", "group_reference", "is-menu-open", "onGroupSelected"]))
                          : _createCommentVNode("", true)
                      ])
                    ])
                  ], 2)
                ]),
                _: 1
              }),
              _createElementVNode("main", _hoisted_6, [
                (_openBlock(), _createBlock(_component_ion_router_outlet, {
                  key: _ctx.$route.fullPath,
                  class: "h-auto w-full absolute lg:relative"
                }))
              ])
            ])
          ], 4),
          _withDirectives(_createVNode(_component_FooterBar, { class: "mt-12" }, null, 512), [
            [_vShow, false]
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}