import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 p-4"
}
const _hoisted_2 = { class: "bg-white rounded-lg p-8" }
const _hoisted_3 = { class: "flex items-center mb-4" }
const _hoisted_4 = { class: "text-lg font-semibold" }
const _hoisted_5 = { class: "text-lg mb-4" }
const _hoisted_6 = { class: "flex justify-end" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = { key: 1 }

import { ref } from 'vue';
import {ArrowPathIcon} from '@heroicons/vue/24/outline';


export default /*@__PURE__*/_defineComponent({
  __name: 'SimpleReloadPageConfirmDialoag',
  setup(__props) {

const isVisible = ref(true);
const isLoading = ref(false);
const reloadIntervalId = ref();

const closeDialog = () => {
	if (reloadIntervalId.value) {
		clearTimeout(reloadIntervalId.value);
		reloadIntervalId.value = undefined;
	}
	isVisible.value = false;
};

const handleConfirm = () => {
	isLoading.value = true;
	reloadIntervalId.value = setTimeout(() => {
		window.location.reload();
	}, 500);
};

return (_ctx: any,_cache: any) => {
  return (isVisible.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (!isLoading.value)
              ? (_openBlock(), _createBlock(_unref(ArrowPathIcon), {
                  key: 0,
                  class: "h-6 w-6 text-gr-primary2 mr-2"
                }))
              : (_openBlock(), _createBlock(_unref(ArrowPathIcon), {
                  key: 1,
                  class: "animate-spin h-6 w-6 text-gr-primary2 mr-2"
                })),
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('version.newVersionAvailable.title')), 1)
          ]),
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('version.newVersionAvailable.description')), 1),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("button", {
              onClick: handleConfirm,
              disabled: isLoading.value,
              class: _normalizeClass(['px-4 py-2 text-white rounded-lg focus:outline-none bg-gr-primary', '', isLoading.value ? 'disabled:opacity-75 cursor-not-allowed' : ''])
            }, [
              (!isLoading.value)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(_toDisplayString(_ctx.$t('version.newVersionAvailable.button.reload')), 1)
                  ], 64))
                : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t('version.newVersionAvailable.button.loading')), 1))
            ], 10, _hoisted_7),
            _createElementVNode("button", {
              onClick: closeDialog,
              class: "ml-4 px-4 py-2 bg-gray-300 text-gray-700 rounded-lg focus:outline-none"
            }, "Cancel")
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})