import {
	AbstractFlowProcess,
} from '@/greeve/flow/process/abstract_flow_process.type';
import UploadNode from '@/components/workflow/nodes/UploadNode.vue';
import { h } from 'vue'

export class FlowProcessGreeveWhisper extends AbstractFlowProcess {
	getVueFlowTemplate(): any {
		const maxFileSize = 100;
		return h(UploadNode, { flowProcess: this, flowProcessUuid: this.uuid, maxFileSize });
	}
}