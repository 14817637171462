<template>
	<div class="-mx-10" style="-webkit-transform: translate3d(0px, 0px, 0px);">
		<div class="text-white box max-w-7xl ">
			<figure class="relative h-[50rem] overflow-hidden">
				<div class="marquee">
					<img src="https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/images/landing/collection.png" alt="Slider Image" class="mr-4 img-size">
					<img src="https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/images/landing/collection.png" alt="Slider Image" class="mr-4 img-size">
					<img src="https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/images/landing/collection.png" alt="Slider Image" class="mr-4 img-size">
				</div>
			</figure>
		</div>
	</div>
	<div class="flex justify-center items-center absolute inset-0 shadow-3xl">
		<img src="https://theconcept-cdn.fra1.cdn.digitaloceanspaces.com/greeve/production/public/images/logo/icon-text.png" alt="Foreground Image" class="w-[16em] sm:w-[20em] h-auto bg-black rounded-[2em] px-10 py-8 sm:px-14 sm:py-10">
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'BackgroundSlider',
});
</script>

<style lang="scss">

.marquee {
  display: flex;
  position: relative;
  animation: marquee 30s linear infinite;
  height: 100%;
  width: 400%;  // Increase the width of the marquee
}

.img-size {
  height: 100%;
  object-fit: contain;  // Use contain instead of cover
  flex-shrink: 0;  // Prevent the images from shrinking
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-33.3333%);
  }
}
</style>
