import {JournalList} from "@/greeve/shop/order/journal/journal_list.type";
import {Journal} from "@/greeve/shop/order/journal/journal.type";
import useOrderFactory from "@/composable/greeve/useOrderFactory";
import {JournalLineList} from "@/greeve/shop/order/journal/journalLine/journal_line_list.type";

export class JournalFactory {
	/**
	 *
	 * @param journalResponse
	 */
	createJournalListByResponse(journalResponse: Record<string, any>): JournalList {
		const journalList = new JournalList();

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		for (const [key, item] of Object.entries(journalResponse)) {
			journalList.push(this.createJournalByResponse(item));
		}

		return journalList;
	}

	/**
	 *
	 * @param item
	 */
	createJournalByResponse(item: Record<string, any>): Journal {
		const id = item.id;
		const uuid = item.uuid;
		const type = item.type;
		const state = item.state;
		const user_id = item.user_id ? item.user_id : (item.userId ? item.userId : undefined);
		const parent_id = item.parent_id ? item.parent_id : (item.parentId ? item.parentId : undefined) ?? undefined;
		const order_id = item.order_id;
		// const tax_code = item.tax_code;
		const country_code = item.country_code;
		const currency_code = item.currency_code;
		const journal_number = item.journal_number;
		const journal_number_formatted = item.journal_number_formatted;
		const reference = item.reference;
		const external_reference = item.external_reference;
		const net_amount = item.net_amount;
		const total_amount = item.total_amount;
		const tax_amount = item.tax_amount;
		const item_quantity = item.item_quantity;
		const grand_total = item.grand_total;
		const exchange_rate = item.exchange_rate;
		const brokerage = item.brokerage;
		const first_name = item.first_name;
		const last_name = item.last_name;
		const company = item.company;
		const is_company = item.is_company;
		const uid = item.uid;
		const tax_number = item.tax_number;
		const street = item.street;
		const zip_code = item.zip_code;
		const city = item.city;
		const lat = item.lat;
		const lng = item.lng;
		const phone = item.phone;
		const description = item.description;
		let journalLines: JournalLineList | undefined;
		if (item.journal_lines && item.journal_lines.length > 0) {
			journalLines = useOrderFactory().getJournalLineFactory().createJournalLineListListByResponse(item.journal_lines);
		} else if (item.journalLines && item.journalLines.length > 0) {
			journalLines = useOrderFactory().getJournalLineFactory().createJournalLineListListByResponse(item.journalLines)
		}
		const data = item.data;
		const created_at = item.created_at ? new Date(item.created_at) : (item.createdAt ? new Date(item.createdAt) : new Date());
		const updated_at = item.updated_at ? new Date(item.updated_at) : (item.updatedAt ? new Date(item.updatedAt) : new Date());

		return new Journal(id, uuid, user_id, type, state, country_code, currency_code, journal_number, journal_number_formatted, net_amount, total_amount, tax_amount, item_quantity, grand_total, street, journalLines, order_id, parent_id, reference, external_reference, exchange_rate, brokerage, first_name, last_name, company, is_company, uid, tax_number, zip_code, city, lat, lng, phone, description, data, created_at, updated_at);
	}
}