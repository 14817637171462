import {CurrencyInterface} from "@/greeve/core/country/currency.interface";
import {CurrencyEuroIcon, CurrencyDollarIcon, CurrencyPoundIcon} from '@heroicons/vue/24/outline'

export const enum CurrencyCode {
	EUR = 'eur',
	USD = 'usd',
	GBP = 'gbp',
}

export class Currency implements CurrencyInterface {
	code: string;

	constructor(code: string) {
		this.code = code;
	}

	getCurrencySymbol(): string
	{
		let currencySymbol = '€';
		switch (this.code) {
			case CurrencyCode.EUR:
				currencySymbol = '€';
				break;
			case CurrencyCode.USD:
				currencySymbol = '$';
				break;
			case CurrencyCode.GBP:
				currencySymbol = '£';
				break;
		}

		return currencySymbol;
	}

	getCurrencyIcon(): any
	{
		let currencyIcon = CurrencyEuroIcon;
		switch (this.code) {
			case CurrencyCode.EUR:
				currencyIcon = CurrencyEuroIcon;
				break;
			case CurrencyCode.USD:
				currencyIcon = CurrencyDollarIcon;
				break;
			case CurrencyCode.GBP:
				currencyIcon = CurrencyPoundIcon;
				break;
		}

		return currencyIcon;
	}
}