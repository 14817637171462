// import {Product} from "@/greeve/shop/product/product.type";
// import useShop from "@/composable/greeve/useShop";
// import {Price} from "@/greeve/shop/product/price.type";
import {
	SubscriptionItemInterface,
} from '@/greeve/shop/subscription/subscriptionItem/subscription_item.interface';

export class SubscriptionItem implements SubscriptionItemInterface {
	id: number;
	subscription_id: number;
	stripe_id: string;
	stripe_product: string;
	stripe_price: string;
	quantity: number;
	data?: any;
	created_at?: Date;
	updated_at?: Date;

	constructor(
			id: number, subscription_id: number, stripe_id: string,
			stripe_product: string, stripe_price: string, quantity: number,
			data: any = null, created_at?: Date, updated_at?: Date) {
		this.id = id;
		this.subscription_id = subscription_id;
		this.stripe_id = stripe_id;
		this.stripe_product = stripe_product;
		this.stripe_price = stripe_price;
		this.quantity = quantity;
		this.data = data;
		this.created_at = created_at;
		this.updated_at = updated_at;
	}
}