<template>
	<div class="mx-auto max-w-7xl pb-12">
		<div
				class="flex items-baseline justify-between bg-gray-50 dark:bg-gr-dark m-4 mb-0 rounded-xl border-gray-200 px-6 py-2 lg:py-6">
			<h1 class="">
				<nav class="flex" :aria-label="$t('breadcrumb')">
					<ol role="list" class="flex items-center space-x-2 md:space-x-4">
						<li>
							<div>
								<router-link to="/" class="text-gray-400 hover:text-gray-500">
									<HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true"/>
									<span class="sr-only">{{ $t('home') }}</span>
								</router-link>
							</div>
						</li>
						<li v-for="page in pages.slice(from, till)" :key="page.name">
							<div class="flex items-center">
								<ChevronRightIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true"/>
								<router-link :to="page.href"
														 class="ml-2 md:ml-4 text-sm font-medium text-gray-500 dark:text-gray-400 hover:text-gray-700"
														 :aria-current="page.current ? 'page' : undefined">{{ page.name }}
								</router-link>
							</div>
						</li>
					</ol>
				</nav>
			</h1>
		</div>
	</div>
</template>
<script lang="ts">
import {ChevronRightIcon, HomeIcon} from '@heroicons/vue/20/solid';

export default {
	name: 'BreadcrumbsHeader',
	components: {
		ChevronRightIcon,
		HomeIcon,
	},
	props: {
		from: {
			type: Number,
			default: 0,
		},
		till: {
			type: Number,
			default: 2,
		},
	},

	setup() {
		const pages = [
			{name: 'Services', href: '/services', current: false},
			{name: 'Detail', href: 'detail', current: false},
			{name: 'Workflow', href: '/workflow', current: false},
			{name: 'Demo', href: 'demo', current: false},
			{name: 'Pricing', href: '/shop', current: false},
			{name: 'Plans', href: 'plans', current: false},
			{name: 'Blog', href: '/blog', current: false},
			{name: 'Article', href: 'article', current: false},
		];

		return {
			pages,
		};
	},
};
</script>
<style>
</style>
