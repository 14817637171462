<template>
	<div>
		<div class="cols-1 text-base">
			<div class="p-4 flex flex-wrap rounded-xl bg-gray-200 dark:bg-gr-dark justify-between gap-4">
				<!--    <div class="category-btn dark:text-gray-400 text-center w-20 p-2" @click="clickCategory('how_to')"><ion-icon class="text-2xl" :icon="icons.hammer"></ion-icon><p class="text-xs -mt-1.5 truncate">HowTo</p></div>-->
				<!--    <div class="category-btn dark:text-gray-400 text-center w-20 p-2" @click="clickCategory('video')"><ion-icon class="text-2xl" :icon="icons.videocam"></ion-icon><p class="text-xs -mt-1.5 truncate">Video</p></div>-->
				<!--    <div class="category-btn dark:text-gray-400 text-center w-20 p-2" @click="clickCategory('game')"><ion-icon class="text-2xl" :icon="icons.gameController"></ion-icon><p class="text-xs -mt-1.5 truncate">Game</p></div>-->
				<!--    <div class="category-btn dark:text-gray-400 text-center w-20 p-2" @click="clickCategory('outfit')"><ion-icon class="text-2xl" :icon="icons.shirt"></ion-icon><p class="text-xs -mt-1.5 truncate">Outfit</p></div>-->
				<!--    <div class="category-btn dark:text-gray-400 text-center w-20 p-2" @click="clickCategory(quote)"><ion-icon class="text-2xl" :icon="icons.quote"></ion-icon><p class="text-xs -mt-1.5 truncate">Quote</p></div>-->
				<!--    <div class="category-btn dark:text-gray-400 text-center w-20 p-2" @click="clickCategory('stats')"><ion-icon class="text-2xl" :icon="icons.statsChart"></ion-icon><p class="text-xs -mt-1.5 truncate">Stats</p></div>-->
				<!--    <div class="category-btn dark:text-gray-400 text-center w-20 p-2" @click="clickCategory('direction')"><ion-icon class="text-2xl" :icon="icons.compass"></ion-icon><p class="text-xs -mt-1.5 truncate">Direction</p></div>-->
				<!--    <div class="category-btn dark:text-gray-400 text-center w-20 p-2" @click="clickCategory('health')"><ion-icon class="text-2xl" :icon="icons.thermometer"></ion-icon><p class="text-xs -mt-1.5 truncate">Health</p></div>-->
				<!--    <div class="category-btn dark:text-gray-400 text-center w-20 p-2" @click="clickCategory('math')"><ion-icon class="text-2xl" :icon="icons.calculator"></ion-icon><p class="text-xs -mt-1.5 truncate">Math</p></div>-->
				<div class="category-btn dark:text-gray-400 text-center w-20 p-2"
					 @click="clickCategory(getGreeveSearchType().getRecipeCreatorType())">
					<ion-icon class="text-2xl" :icon="icons.fastFoodOutline"></ion-icon>
					<p class="text-xs -mt-1.5 truncate">{{ $t("categories.recipe") }}</p></div>
				<!--    <div class="category-btn dark:text-gray-400 text-center w-20 p-2" @click="clickCategory('shopping')"><ion-icon class="text-2xl" :icon="icons.basket"></ion-icon><p class="text-xs -mt-1.5 truncate">Shopping</p></div>-->
				<div class="category-btn dark:text-gray-400 text-center w-20 p-2"
					 @click="clickCategory(getGreeveSearchType().getImageGenerationType())">
					<ion-icon class="text-2xl" :icon="icons.imageOutline"></ion-icon>
					<p class="text-xs -mt-1.5 truncate">{{ $t("categories.image") }}</p></div>
				<!--          <div class="category-btn dark:text-gray-400 text-center w-20 p-2" @click="clickCategory('article')"><ion-icon class="text-2xl" :icon="icons.textOutline"></ion-icon><p class="text-xs -mt-1.5 truncate">Article</p></div>-->
				<!--          <div class="category-btn dark:text-gray-400 text-center w-20 p-2" @click="clickCategory('list')">-->
				<!--            <ion-icon class="text-2xl" :icon="icons.list"></ion-icon>-->
				<!--            <p class="text-xs -mt-1.5 truncate">List</p></div>-->
				<!--          <div class="category-btn dark:text-gray-400 text-center w-20 p-2" @click="clickCategory('idea')">-->
				<!--            <ion-icon class="text-2xl" :icon="icons.bulb"></ion-icon>-->
				<!--            <p class="text-xs -mt-1.5 truncate">Idea</p></div>-->
				<!--    <div class="category-btn dark:text-gray-400 text-center w-20 p-2" @click="clickCategory('question')"><ion-icon class="text-2xl" :icon="icons.search"></ion-icon><p class="text-xs -mt-1.5 truncate">Question</p></div>-->
				<!--    <div class="category-btn dark:text-gray-400 text-center w-20 p-2" @click="clickCategory('study')"><ion-icon class="text-2xl" :icon="icons.flask"></ion-icon><p class="text-xs -mt-1.5 truncate">Study</p></div>-->
				<div class="category-btn dark:text-gray-400 text-center w-20 p-2"
					 @click="clickCategory(getGreeveSearchType().getCodeType())">
					<ion-icon class="text-2xl" :icon="icons.code"></ion-icon>
					<p class="text-xs -mt-1.5 truncate">{{ $t("categories.code") }}</p></div>
				<!--              <div class="category-btn dark:text-gray-400 text-center w-20 p-2" @click="clickCategory('music')"><ion-icon class="text-2xl" :icon="icons.musicalNoteOutline"></ion-icon><p class="text-xs -mt-1.5 truncate">Music</p></div>-->
				<!--    <div class="category-btn dark:text-gray-400 text-center w-20 p-2" @click="clickCategory('plant')"><ion-icon class="text-2xl" :icon="icons.leaf"></ion-icon><p class="text-xs -mt-1.5 truncate">Plant</p></div>-->
				<!--    <div class="category-btn dark:text-gray-400 text-center w-20 p-2" @click="clickCategory('news')"><ion-icon class="text-2xl" :icon="icons.newspaper"></ion-icon><p class="text-xs -mt-1.5 truncate">News</p></div>-->
				<!--    <div class="category-btn dark:text-gray-400 text-center w-20 p-2" @click="clickCategory('book')"><ion-icon class="text-2xl" :icon="icons.library"></ion-icon><p class="text-xs -mt-1.5 truncate">Book</p></div>-->
				<!--              <div class="category-btn dark:text-gray-400 text-center w-20 p-2"><ion-icon class="text-2xl" :icon="icons.rocketOutline"></ion-icon><p class="text-xs -mt-1.5 truncate">get it</p></div>-->
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import {defineComponent, ref} from 'vue';
import {IonIcon} from '@ionic/vue';
import * as icons from 'ionicons/icons';
import useSearch from "@/composable/greeve/useSearch";
import useTranslation from "@/composable/translation/useTranslation";

export default defineComponent({
	name: 'CategoryInput',
	props: {},
	components: {IonIcon},
	emits: ['clickCategory'],
	setup(props, {emit}) {
		const {t} = useTranslation();
		const selectedType = ref('');
		const {getGreeveSearchType} = useSearch();

		function clickCategory(category: string)
		//TODO select catetgory
		{
			emit('clickCategory', category);
		}

		return {t, icons, selectedType, clickCategory, getGreeveSearchType}
	}
})
</script>

<style lang="scss">

ion-icon {
  contain: unset;
  fill: currentcolor;
}

</style>
