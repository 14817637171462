import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "relative z-10 sticky animated justify-between items-center transition-all duration-200" }
const _hoisted_2 = { class: "mx-auto max-w-7xl pb-2" }
const _hoisted_3 = { class: "flex items-baseline justify-between bg-gray-50 dark:bg-gr-dark mt-0 m-2 sm:m-4 mb-0 rounded-xl border-gray-200 px-6 py-4 lg:py-4" }
const _hoisted_4 = { class: "" }
const _hoisted_5 = {
  class: "flex",
  "aria-label": "CheckoutNavbar"
}
const _hoisted_6 = {
  role: "list",
  class: "flex items-center space-x-2 md:space-x-4"
}
const _hoisted_7 = { class: "sr-only" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "text-sm font-semibold text-gray-500 dark:text-gray-300" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "text-sm font-semibold text-gr-primary dark:text-gr-primary" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "text-sm font-medium text-gray-500 dark:text-gray-500" }
const _hoisted_14 = { class: "flex justify-end items-end items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CreditCardIcon = _resolveComponent("CreditCardIcon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ChevronRightIcon = _resolveComponent("ChevronRightIcon")!
  const _component_checkout_nav_country = _resolveComponent("checkout-nav-country")!
  const _component_checkout_nav_cart = _resolveComponent("checkout-nav-cart")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, [
          _createElementVNode("nav", _hoisted_5, [
            _createElementVNode("ol", _hoisted_6, [
              _createElementVNode("li", null, [
                _createElementVNode("div", null, [
                  _createVNode(_component_router_link, {
                    to: "/pricing",
                    class: "text-gray-400 hover:text-gray-500"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_CreditCardIcon, {
                        class: "h-5 w-5 flex-shrink-0",
                        "aria-hidden": "true"
                      }),
                      _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('pricing')), 1)
                    ]),
                    _: 1
                  })
                ])
              ]),
              _createVNode(_component_ChevronRightIcon, {
                class: "h-5 w-5 flex-shrink-0 text-gray-400",
                "aria-hidden": "true"
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (step, stepIdx) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: step.name,
                  class: "flex items-center"
                }, [
                  (step.status === 'completed')
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 0,
                        onClick: ($event: any) => (_ctx.updateStep(step)),
                        "aria-current": "page",
                        class: "flex items-center cursor-pointer text-green-600"
                      }, [
                        _createElementVNode("span", _hoisted_9, _toDisplayString(step.name), 1)
                      ], 8, _hoisted_8))
                    : (step.status === 'current')
                      ? (_openBlock(), _createElementBlock("a", {
                          key: 1,
                          onClick: ($event: any) => (_ctx.updateStep(step)),
                          "aria-current": "page",
                          class: "flex items-center cursor-pointer text-gr-primary"
                        }, [
                          _createElementVNode("span", _hoisted_11, _toDisplayString(step.name), 1)
                        ], 8, _hoisted_10))
                      : (step.status === 'upcoming')
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 2,
                            onClick: ($event: any) => (_ctx.updateStep(step, true)),
                            "aria-current": "page",
                            class: "flex items-center cursor-pointer text-gray-400"
                          }, [
                            _createElementVNode("span", _hoisted_13, _toDisplayString(step.name), 1)
                          ], 8, _hoisted_12))
                        : _createCommentVNode("", true),
                  (stepIdx !== _ctx.steps.length - 1)
                    ? (_openBlock(), _createBlock(_component_ChevronRightIcon, {
                        key: 3,
                        class: "ml-4 h-5 w-5 text-gray-400 dark:text-gray-400",
                        "aria-hidden": "true"
                      }))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createVNode(_component_checkout_nav_country, { class: "hidden sm:block" }),
          _createVNode(_component_checkout_nav_cart, { class: "hidden sm:block" })
        ])
      ])
    ])
  ]))
}