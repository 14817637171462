import {JournalLineList} from "@/greeve/shop/order/journal/journalLine/journal_line_list.type";
import {JournalLine} from "@/greeve/shop/order/journal/journalLine/journal_line.type";

export class JournalLineFactory {

	/* eslint-disable */

	/**
	 *
	 * @param journalLines
	 */
	createJournalLineListListByResponse(journalLines: Record<string, any>): JournalLineList {
		const journalLineList = new JournalLineList();

		for (const [key, item] of Object.entries(journalLines)) {
			journalLineList.push(this.createJournalLineByResponse(item));
		}

		return journalLineList;
	}

	/**
	 *
	 * @param item
	 */
	createJournalLineByResponse(item: Record<string, any>): JournalLine {
		const id = item.id;
		const amount = item.amount;
		const quantity = item.quantity;
		const name = item.name;
		const priceId = item.price_id;
		const productId = item.product_id;
		const description = item.product_id;
		const description2 = item.product_id;
		const labels = item.labels;

		return new JournalLine(id, amount, quantity, name, priceId, productId, description, description2, labels);
	}
}