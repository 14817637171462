<template>
	<div class="space-y-2 sm:space-y-4">
		<div class=" sm:overflow-hidden rounded-xl nav backdrop-filter backdrop-blur-3xl">
			<div class="nav rounded-xl py-6 px-4">
				<div>
					<h3 class="text-lg font-medium leading-6 text-gray-900 dark:text-gray-500">{{ $t('comingSoon') }}</h3>
					<p class="mt-1 text-sm text-gray-600">{{ $t('manageTeamMembers') }}</p>
				</div>
			</div>
		</div>

<!--		<form action="#" method="POST">-->
<!--		<div class=" sm:overflow-hidden rounded-xl ">-->
<!--			<div class="space-y-6 nav backdrop-filter backdrop-blur-3xl py-6 px-4 sm:p-6">-->
<!--				<div>-->
<!--					<h3 class="text-lg font-medium leading-6 text-gray-900">Team Members</h3>-->
<!--					<p class="mt-1 text-sm text-gray-500">Manage Team Members and their Roles.</p>-->
<!--				</div>-->
<!--				<fieldset>-->
<!--					<div class="mt-4 space-y-4">-->
<!--						<div class="grid grid-cols-6 gap-6">-->
<!--							<div class="col-span-5 sm:col-span-4 self-center text-sm">-->
<!--								<label for="addAdmin"-->
<!--									   class="font-medium text-gray-700">Admin Role</label>-->
<!--								<p class="text-gray-500">manage Account, create and use Flows</p>-->
<!--							</div>-->
<!--							<div class="items-end  w-64 self-center">-->
<!--								<button id="addAdmin" class="self-center col-span-1 sm:col-span-2 text-gray-500">-->
<!--									<div class="bg-gray-200 rounded-xl p-3 h-10 w-10"><plusIcon></plusIcon></div>-->
<!--								</button>-->
<!--							</div>-->
<!--						</div>-->
<!--						<div class="grid grid-cols-6 gap-6">-->
<!--							<div class="col-span-5 sm:col-span-4 self-center">-->
<!--								<div class="flex rounded-xl shadow-sm">-->
<!--									<span class="inline-flex items-center rounded-l-xl py-2 border-2 border-r-0 border-gray-200 bg-gray-50 px-3 text-gray-500 sm:text-sm">Admin</span>-->
<!--									<input type="text" name="username" id="username" placeholder="your@email.com" autocomplete="username"-->
<!--										   class="pl-2 border-gray-200 border-2 block w-full min-w-0 flex-grow rounded-none rounded-r-xl border-gray-200 sm:text-sm"/>-->
<!--								</div>-->
<!--							</div>-->
<!--							<button class="self-center col-span-1 sm:col-span-2 text-gray-500">-->
<!--								<div class="bg-gray-200 rounded-xl p-3 h-10 w-10"><trashIcon></trashIcon></div>-->
<!--							</button>-->
<!--						</div>-->
<!--						<div class="grid grid-cols-6 gap-6">-->
<!--							<div class="col-span-5 sm:col-span-4 self-center text-sm">-->
<!--								<label for="addReader"-->
<!--									   class="font-medium text-gray-700">Reader Role </label>-->
<!--								<p class="text-gray-500">use Flows</p>-->
<!--							</div>-->
<!--							<div class="items-end w-64 self-center">-->
<!--								<button id="addReader" class="self-center col-span-1 sm:col-span-2 text-gray-500">-->
<!--									<div class="bg-gray-200 rounded-xl p-3 h-10 w-10"><plusIcon></plusIcon></div>-->
<!--								</button>-->
<!--							</div>-->
<!--						</div>-->
<!--						<div class="grid grid-cols-6 gap-6">-->
<!--							<div class="col-span-5 sm:col-span-4 self-center">-->
<!--								<div class="flex rounded-xl shadow-sm">-->
<!--									<span class="inline-flex items-center rounded-l-xl py-2 border-2 border-r-0 border-gray-200 bg-gray-50 px-3 text-gray-500 sm:text-sm">Reader</span>-->
<!--									<input type="text" name="username" id="username" placeholder="your@email.com" autocomplete="username"-->
<!--										   class="pl-2 border-gray-200 border-2 block w-full min-w-0 flex-grow rounded-none rounded-r-xl border-gray-200 sm:text-sm"/>-->
<!--								</div>-->
<!--							</div>-->
<!--							<button class="self-center col-span-1 sm:col-span-2 text-gray-500">-->
<!--								<div class="bg-gray-200 rounded-xl p-3 h-10 w-10"><trashIcon></trashIcon></div>-->
<!--							</button>-->
<!--						</div>-->
<!--						<div class="grid grid-cols-6 gap-6">-->
<!--							<div class="col-span-5 sm:col-span-4 self-center text-sm">-->
<!--								<label for="addCreator"-->
<!--									   class="font-medium text-gray-700">Creator Role </label>-->
<!--								<p class="text-gray-500">create and use Flows</p>-->
<!--							</div>-->
<!--							<div class="items-end w-64 self-center">-->
<!--								<button id="addCreator" class="self-center col-span-1 sm:col-span-2 text-gray-500">-->
<!--									<div class="bg-gray-200 rounded-xl p-3 h-10 w-10"><plusIcon></plusIcon></div>-->
<!--								</button>-->
<!--							</div>-->
<!--						</div>-->
<!--						<div class="grid grid-cols-6 gap-6">-->
<!--							<div class="col-span-5 sm:col-span-4 self-center">-->
<!--								<div class="flex rounded-xl shadow-sm">-->
<!--									<span class="inline-flex items-center rounded-l-xl py-2 border-2 border-r-0 border-gray-200 bg-gray-50 px-3 text-gray-500 sm:text-sm">Creator</span>-->
<!--									<input type="text" name="username" id="username" placeholder="your@email.com" autocomplete="username"-->
<!--										   class="pl-2 border-gray-200 border-2 block w-full min-w-0 flex-grow rounded-none rounded-r-xl border-gray-200 sm:text-sm"/>-->
<!--								</div>-->
<!--							</div>-->
<!--							<button class="self-center col-span-1 sm:col-span-2 text-gray-500">-->
<!--								<div class="bg-gray-200 rounded-xl p-3 h-10 w-10"><trashIcon></trashIcon></div>-->
<!--							</button>-->
<!--						</div>-->
<!--					</div>-->
<!--				</fieldset>-->
<!--			</div>-->
<!--		</div>-->
<!--	</form>-->



	</div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
	name: "TeamManagement",
	components: {
		// TrashIcon, PlusIcon
	},
	props: {},
	emits: [],
	setup() {
		return {

		};
	}
})
</script>

<style scoped>

</style>
