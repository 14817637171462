import {PaginationMetaInterface} from "@/greeve/core/pagination_meta.interface";
import {
	SubscriptionItem
} from '@/greeve/shop/subscription/subscriptionItem/subscription_item.type';

export class SubscriptionItemList extends Array<SubscriptionItem> {

	public paginationMetaData?: PaginationMetaInterface;

	constructor(items: Array<SubscriptionItem> = []) {
		super();
		this.push(...items);
	}

	add(items: SubscriptionItem) {
		this.push(items);
	}

	remove(index: number) {
		this.splice(index, 1);
	}

	removeAll() {
		const itemCount = this.length;
		this.splice(itemCount, 1);
	}

	getItem(index: number): SubscriptionItem {
		return this[index];
	}

	getItemById(item_id: number): SubscriptionItem | undefined {
		return this.find(({id}) => id === item_id);
	}

	enhanceMetaData(metaData: any) {
		this.paginationMetaData = metaData;
	}
}